import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchQualifications } from "features/catalogs/qualificationsSlice";

export default function ManageCatalogs() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const {qualifications, isLoading} = useSelector((state) => state.qualifications);
	// Effect
	useEffect(() => {
		if (qualifications == null && !isLoading) {
			dispatch(fetchQualifications());
		}
	}, [dispatch, qualifications, isLoading]);
	if (qualifications == null) return null;
	return (
		<div id="default">
			<section id="order-requests">
				<div className="dsv-title">
					<div>
						<h1>{t("AdminCatalogs.Title")}</h1>
						<h2>{t("Qualifications.Title")}</h2>
					</div>
				</div>
				<div className="dsv-table">
            <table cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>{t("Fields.Name")}</th>
									<th>{t("Common.Description")}</th>
									<th className="center">{t("Common.Icon")}</th>
								</tr>
							</thead>
							<tbody>
								{
									qualifications.map((el) => {
										return (
								<tr>
									<td>{t(`Qualifications.${el.name}`)}</td>
									<td>{t(`Qualifications.${el.description}`)}</td>
									<td className="center"><span className={el.icon} ></span></td>
								</tr>);
								})
								}
							</tbody>
						</table>
					</div>
			</section>
		</div>
	);
}
