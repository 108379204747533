import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOperatorOrderEdit,
	fetchUpdateOrderAddress,
} from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";
import { fetchAccesorials } from "features/catalogs/accesorialsSlice";
import { fetchLocationTypes } from "features/catalogs/locationTypeSlice";
import OrderAddressForm from "components/common/order/OrderAddressForm";
import {
	fetchUpdateConsolidateAddress,
	setOperatorConsolidationTabEdit,
} from "features/operator/operatorOrderConsolidationSlice";

export default function OperatorConsolidateDetailsAddressEdit() {
	// Redux dispatch
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, isLoadingOpen, isSaving } =
		useSelector((state) => state.operatorOrders);
	const { isLoading, selectedOrder, edit, activeTab, orders } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	const isSavingConsolidate = useSelector(
		(state) => state.operatorOrderConsolidation.isSaving
	);
	const { accesorials } = useSelector((state) => state.accesorials);
	const isLoadingAccessorials = useSelector(
		(state) => state.accesorials.isLoading
	);
	const { locationTypes } = useSelector((state) => state.locationTypes);
	const isLoadinglocationTypes = useSelector(
		(state) => state.locationTypes.isLoading
	);

	// Effect
	useEffect(() => {
		if (accesorials == null && !isLoadingAccessorials) {
			dispatch(fetchAccesorials());
		}
		if (locationTypes == null && !isLoadinglocationTypes) {
			dispatch(fetchLocationTypes());
		}
	}, [
		dispatch,
		accesorials,
		isLoadingAccessorials,
		locationTypes,
		isLoadinglocationTypes,
	]);

	// Función que ejecuta.
	const handleSaveAddressData = (data) => {
		// Call Service.
		if (activeTab === 0 && openOrder != null) {
			dispatch(
				fetchUpdateOrderAddress({ orderId: openOrder.id, formData: data })
			);
		}
		if (activeTab > 0 && selectedOrder != null) {
			// remove nulls
			for (var propName in data) {
				if (
					data[propName] === null ||
					data[propName] === undefined ||
					data[propName] === ""
				) {
					delete data[propName];
				}
			}
			dispatch(
				fetchUpdateConsolidateAddress({
					orderId: selectedOrder.id,
					formData: data,
					primary: openOrder.id,
					ids: [openOrder.id, ...orders],
				})
			);
		}
	};

	const handleCancelEdit = () => {
		if (activeTab === 0) {
			dispatch(setOperatorOrderEdit({ orderEdit: null }));
		}
		else {
			dispatch(setOperatorConsolidationTabEdit({ edit: null }));
		} 
	};

	// Render
	if (
		activeTab === 0 &&
		orderEdit != null &&
		orderEdit === ORDER_EDIT.ADDRESS &&
		openOrder != null
	) {
		return (
			<OrderAddressForm
				order={openOrder}
				edit={orderEdit}
				onSave={handleSaveAddressData}
				onCancel={handleCancelEdit}
				isSaving={isSaving}
				isLoading={isLoadingOpen}
				accesorials={accesorials}
				locationTypes={locationTypes}
			/>
		);
	}
	if (
		activeTab > 0 &&
		edit != null &&
		edit === ORDER_EDIT.ADDRESS &&
		selectedOrder != null
	) {
		return (
			<OrderAddressForm
				order={selectedOrder}
				edit={edit}
				onSave={handleSaveAddressData}
				onCancel={handleCancelEdit}
				isSaving={isSavingConsolidate}
				isLoading={isLoading}
				accesorials={accesorials}
				locationTypes={locationTypes}
			/>
		);
	}

	return null;
}
