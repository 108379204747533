import { LANGUAGES, USER_ROLE } from "features/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { passRegEx, emailRegEx, userNameRegEx } from "utils/regExs";
import { toSelector } from "utils/constantsUtils";
import Form from "components/form/form";
import TextField from "components/form/textField";
import SelectorField from "components/form/selectorField";
import { getUserRole } from "utils/userUtils";

export default function UserForm({
	open,
	user,
	role,
	list,
	branches,
	onClose,
	onInvalid,
	setError,
	onSubmit,
}) {
	// Translation
	const { t } = useTranslation();
	// Form
	const reactForm = useForm();

	const { handleSubmit, reset } = reactForm;

	useEffect(() => {
		if (open) {
			const userRole = getUserRole(open);
			const operatorFields =
				userRole === USER_ROLE.OPERATOR
					? {
							branch_ids: open?.branches?.map((item) => {
								return {
									value: item.id,
									label:
										branches?.find((e) => e.id === item.id).code ?? undefined,
								};
							}),
					  }
					: {};
			reset({
				name: open?.name,
				email: open?.email,
				lang: open?.lang,
				...operatorFields,
			});
		} else {
			reset({
				lang: user?.user?.lang ?? LANGUAGES.EN,
			});
		}
	}, [open, user, branches, reset]);

	// Handlers
	const handleClose = () => {
		onClose();
	};
	const errorHandler = (data) => {
		if (onInvalid != null) {
			onInvalid(data);
		}
	};
	const handleSave = (data, event) => {
		event.preventDefault();
		if (data.branch_ids != null) {
			data.branch_ids = data.branch_ids.map((item) => item.value);
			if (data.branch_ids.length === 0) {
				data.branch_ids = null;
			}
		} else {
			delete data.branch_ids;
		}
		onSubmit(data);
	};

	// Render
	const userRole = getUserRole(open) ?? role;
	return (
		<Form reactForm={reactForm} onSubmit={handleSave} onError={errorHandler}>
			<div className="a details-subtitle">
				<div>{t("AdminUsers.UserInfo")}</div>
			</div>
			{/** If edit remove username field */}
			{open != null && (
				<div className="a field">
					<label>{t("Fields.Username")}</label>
					<div>{open.username}</div>
				</div>
			)}
			{open == null && (
				<TextField
					className="b-1"
					fieldName="username"
					autoComplete="off"
					label={t("Fields.Username")}
					placeholder={t("Fields.Username")}
					validations={{
						required: t("FieldErrors.UserNameMissing"),
						onChange: (e) => {
							setError("username");
							const { value } = e.target;
							const lowerCaseValue = value.toLowerCase();
							e.target.value = lowerCaseValue.replace(/[^a-z@._-]/g, "");
						},
						pattern: {
							value: userNameRegEx,
							message: t("FieldErrors.UserNameInvalid"),
						},
					}}
				/>
			)}
			{/** If edit remove password field */}
			{open == null && (
				<TextField
					className="b-2"
					fieldName="password"
					autoComplete="off"
					label={t("Fields.Password")}
					placeholder={t("Fields.Password")}
					type="password"
					validations={{
						required: t("FieldErrors.PasswordMissing"),
						pattern: {
							value: passRegEx,
							message: t("FieldErrors.PasswordInvalid"),
						},
					}}
				/>
			)}
			<TextField
				className="b-1"
				fieldName={"name"}
				label={t("Common.Name")}
				autoComplete="off"
				placeholder={t("Common.Name")}
				validations={{
					required: t("Common.Name"),
				}}
			/>
			<TextField
				className="b-2"
				fieldName={"email"}
				label={t("Common.Email")}
				autoComplete="off"
				placeholder={t("Common.Email")}
				validations={{
					required: t("FieldErrors.EmailMissing"),
					pattern: {
						value: emailRegEx,
						message: t("FieldErrors.EmailInvalid"),
					},
					onChange: () => setError("email"),
				}}
			/>
			<SelectorField
				className="b-1"
				fieldName={"lang"}
				label={t("Fields.Language")}
				options={toSelector(LANGUAGES.properties)}
				isClearable={false}
				required={t("FieldErrors.LanguageMissing")}
			/>
			{/** If edit remove carrier selector field */}
			{open == null && userRole === USER_ROLE.CARRIER && (
				<SelectorField
					className="b-2"
					fieldName={"carrier_id"}
					label={t("Common.Carrier")}
					required={t("FieldErrors.CarrierMissing")}
					isClearable={false}
					options={
						list != null
							? list.map((item) => {
									return {
										value: item.id,
										label: item.name,
									};
							  })
							: []
					}
				/>
			)}
			{open != null && userRole === USER_ROLE.CARRIER && (
				<div className="b-2 field">
					<label>{t("Common.Carrier")}</label>
					<div>{open.carrier?.name}</div>
				</div>
			)}
			{userRole === USER_ROLE.OPERATOR && (
				<SelectorField
					className="a"
					fieldName={"branch_ids"}
					label={t("Fields.Branch", { count: 2 })}
					placeholder={t("Common.AllBranches")}
					isMulti={true}
					isClearable={true}
					options={
						branches != null
							? branches.map((item) => {
									return {
										value: item.id,
										label: item.code,
									};
							  })
							: []
					}
				/>
			)}
			<div className="b-21 field">
				<label>&nbsp;</label>
				<div className="button" onClick={handleClose}>
					{t("Buttons.Cancel")}
				</div>
			</div>
			<div className="b-22 field">
				<label>&nbsp;</label>
				<div className="button main" onClick={handleSubmit(handleSave)}>
					{t("Buttons.Save")}
				</div>
			</div>
		</Form>
	);
}
