import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CarrierStatusForm = ({ active, onSave }) => {
	// Form
	const { register, reset } = useForm(); // Effect
	// Translation
	const { t } = useTranslation();
	useEffect(() => {
		reset({ active });
	}, [active, reset]);
	// Handlers
	const handelStatusChange = (e) => {
		e.preventDefault();
		onSave({ active: e.target.checked });
	};
	// Render
	return (
		<div className="dsv-form no-bg no-padding">
			<div className="b-11 field">
				<label>{t("AdminCarriers.CarrierStatus")}</label>
			</div>
			<div className="b-11 switch-field">
				<input
					type="checkbox"
					id="carrier-status"
					name="carrier-status"
					{...register("active", {})}
					onChange={handelStatusChange}
				></input>
				<label htmlFor="carrier-status">
					<span className="icon-switch"></span>
					{active ? t("Common.Active") : t("Common.Inactive")}
				</label>
			</div>
		</div>
	);
};

export default CarrierStatusForm;
