import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PROFILES } from "features/constants";
import { fetchUser } from "features/account/accountSlice";
import Loader from "./Loader";

export default function LoggedIn() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	// Navigation
	const navigate = useNavigate();
	// Selectors
	const { authenticated, checked, user } = useSelector(
		(state) => state.session
	);
	const { usrChecked, isLoading } = useSelector((state) => state.account);
	// Effect
	useEffect(() => {
		if (checked) {
			if (!authenticated) {
				navigate("/login");
			} else {
				if (!usrChecked && !isLoading) {
					dispatch(fetchUser());
				}
				if (user?.user != null) {
					const roles = [
						PROFILES.ADMIN,
						PROFILES.SUPERADMIN,
						PROFILES.CARRIER,
						PROFILES.OPERATOR,
					];
					const filtered = user?.user?.roles?.filter((r) => roles.includes(r));
					if (filtered.length > 0) {
						const role = PROFILES.properties[filtered[0]];
						navigate(role.url);
					}
				}
			}
		}
	}, [checked, authenticated, usrChecked, isLoading, user, dispatch, navigate]);
	return (
		<div id="app" className="main-container">
			<Loader text={t("Common.Loading")} />
		</div>
	);
}
