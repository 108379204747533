import React from "react";
import {
	FormProvider,
} from "react-hook-form";
import classNames from "classnames";

export default function Form({
	children,
	reactForm,
	onSubmit,
	onError,
	autoFocus = false,
	layout,
	collapse = false,
	background = true,
	padding = true,
	login,
	id,
}) {
	const {
		setFocus,
		handleSubmit,
		formState: { errors },
	} = reactForm;

	const onValid = async (data) => {
		onSubmit(data);
	};

	const onInvalid = (data) => {
		if (autoFocus) {
			// TODO: Improve this.
			setFocus(Object.keys(errors)[0]);
		}

		onError?.(data);
	};
	return (
		<FormProvider {...reactForm}>
			<form
				className={classNames("dsv-form", {
					[`${layout}`]: layout != null,
					collapse,
					[`no-bg`]: !background,
					[`no-padding`]: !padding,
					login,
				})}
				onSubmit={handleSubmit(onValid, onInvalid)}
				{...(id && { id })}
			>
				{children}
			</form>
		</FormProvider>
	);
}
