import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
	getOperatorOrderSimilars,
	putAddressInfo,
	putCargoInfo,
	putHeaderInfo,
} from "services/operatorServices";
import {
	fetchOperatorOrderCart,
	fetchOperatorOrderDetails,
} from "./operatorOrdersSlice";

const initialState = {
	list: null,
	orders: [],
	tabs: [],
	activeTab: 0,
	loaded: false,
	isLoading: false,
	selectedOrder: null,
	edit: null,
	isSaving: false,
};

/* Get simliar orders to consolidate */
export const fetchOperatorSimilarOrders = createAsyncThunk(
	"operator/fetchOperatorSimilarOrders",
	async (payload) => {
		const { orderId } = payload;
		const response = await getOperatorOrderSimilars(orderId);
		return response;
	}
);
/* Put update order addresses */
export const fetchUpdateConsolidateAddress = createAsyncThunk(
	"operator/fetchUpdateConsolidateAddress",
	async (payload, { dispatch }) => {
		const { orderId, primary, ids } = payload;
		const response = await putAddressInfo(orderId, payload.formData);
		dispatch(fetchOperatorSimilarOrders({ orderId: primary }));
		dispatch(fetchOperatorOrderCart({ primary, ids }));
		return response;
	}
);
/* Put update order references */
export const fetchUpdateConsolidateHeader = createAsyncThunk(
	"operator/fetchUpdateConsolidateHeader",
	async (payload, { dispatch }) => {
		const { orderId, primary, ids } = payload;
		const response = await putHeaderInfo(orderId, payload.formData);
		dispatch(fetchOperatorSimilarOrders({ orderId: primary }));
		dispatch(fetchOperatorOrderCart({ primary, ids }));
		return response;
	}
);
/* Put update order cargo */
export const fetchUpdateConsolidateCargo = createAsyncThunk(
	"operator/fetchUpdateOrderCargo",
	async (payload, { dispatch }) => {
		const { orderId, formData, primary, ids } = payload;
		const response = await putCargoInfo(orderId, formData);
		dispatch(fetchOperatorOrderDetails({ id: primary, consolidate: true }));
		dispatch(fetchOperatorSimilarOrders({ orderId: primary }));
		if (orderId !== primary) {
			dispatch(
				setOperatorConsolidationSelectedOrder({ selectedOrder: response.data.data })
			);
		}
		dispatch(fetchOperatorOrderCart({ primary, ids }));
		return response;
	}
);

/**
 * Create the reducer using `createSlice`.
 */
export const operatorOrderConsolidationSlice = createSlice({
	name: "operatorOrderConsolidation",
	initialState,
	reducers: {
		setOperatorConsolidationOrders: (state, { payload }) => {
			state.orders = payload.orders ?? null;
		},
		setOperatorConsolidationTabs: (state, { payload }) => {
			state.tabs = payload.tabs ?? [];
		},
		setOperatorConsolidationActiveTab: (state, { payload }) => {
			state.activeTab = payload.activeTab ?? 0;
		},
		setOperatorConsolidationTabEdit: (state, { payload }) => {
			state.edit = payload.edit ?? null;
		},
		setOperatorConsolidationSelectedOrder: (state, { payload }) => {
			state.selectedOrder = payload.selectedOrder ?? null;
		},
		clearOperatorConsolidationOrders: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// Get similar orders
			.addCase(fetchOperatorSimilarOrders.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchOperatorSimilarOrders.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
				state.loaded = true;
			})
			.addCase(fetchOperatorSimilarOrders.rejected, (state) => {
				state.isLoading = false;
				state.list = [];
				state.loaded = true;
			})
			// Update consolidate address
			.addCase(fetchUpdateConsolidateAddress.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateConsolidateAddress.fulfilled, (state) => {
				state.isSaving = false;
				state.edit = null;
			})
			.addCase(fetchUpdateConsolidateAddress.rejected, (state) => {
				state.isSaving = false;
				state.edit = null;
			})
			// Update consolidate header
			.addCase(fetchUpdateConsolidateHeader.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateConsolidateHeader.fulfilled, (state) => {
				state.isSaving = false;
				state.edit = null;
			})
			.addCase(fetchUpdateConsolidateHeader.rejected, (state) => {
				state.isSaving = false;
			})
			.addCase(fetchUpdateConsolidateCargo.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateConsolidateCargo.fulfilled, (state) => {
				state.isSaving = false;
				state.edit = null;
			})
			.addCase(fetchUpdateConsolidateCargo.rejected, (state) => {
				state.isSaving = false;
			});
	},
});

/**
 * Export actions.
 */
export const {
	setOperatorConsolidationOrders,
	setOperatorConsolidationTabs,
	setOperatorConsolidationActiveTab,
	setOperatorConsolidationTabEdit,
	setOperatorConsolidationSelectedOrder,
	clearOperatorConsolidationOrders,
} = operatorOrderConsolidationSlice.actions;

export default operatorOrderConsolidationSlice.reducer;
