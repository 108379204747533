import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import StatusChangeForm from "./StatusChangeForm";
import { validateEmail } from "utils/stringUtils";
import Loader from "components/Loader";
import { BOOKED_STATUS } from "features/constants";

const components = {
	DropdownIndicator: null,
};

const createOption = (label) => ({
	label,
	value: label,
});

export default function CarrierOrderActions({
	order,
	showEmail,
	showStatusChange,
	isChangingStatus,
	isSendingEmail,
	isEmailSent,
	changeStatusError,
	onShowEmail,
	onShowStatusChange,
	onCancelAction,
	onStatusChange,
	onClearEmailSent,
	onSendEmail,
}) {
	// Translation
	const { t, i18n } = useTranslation();
	// State
	const [emailSubject, setEmailSubject] = useState("");
	const [emailTo, setEmailTo] = useState("");
	const [emailToValue, setEmailToValue] = useState([]);
	const [emailBody, setEmailBody] = useState("");
	// Effect
	useEffect(() => {
		if (isEmailSent) {
			onClearEmailSent();
			setEmailSubject("");
			setEmailBody("");
		}
	}, [onClearEmailSent, isEmailSent]);
	// Handlers
	const handleShowEmail = () => {
		setEmailToValue([createOption(order.user.email)]);
		const isConsolidated = order?.order_requests?.length > 1;
		const referenceNumber = isConsolidated
			? ""
			: `/${order.order_requests[0].alternate_reference_number}`;
		const subjectDefault =
			order?.file_number != null
				? t("CarrierBookedOrders.SubjectEmail", {
						order: isConsolidated
							? order?.file_number
							: `${order?.file_number}${referenceNumber}`,
				  })
				: t("Common.Order", { count: 1 });
		setEmailSubject(subjectDefault);
		onShowEmail();
	};
	const handleShowStatusChange = () => {
		onShowStatusChange();
	};
	const handleCancelAction = () => {
		setEmailBody("");
		setEmailSubject("");
		onCancelAction();
	};
	const handleChangeStatusClick = (payload) => {
		onStatusChange(payload);
	};
	const handleEmailToKeyDown = (e) => {
		if (!emailTo) return;
		switch (e.key) {
			case "Enter":
			case "Tab":
				if (!validateEmail(emailTo)) return;
				setEmailToValue((prev) => [...prev, createOption(emailTo)]);
				setEmailTo("");
				e.preventDefault();
				break;
			default:
				return;
		}
	};
	const handleSendEmail = () => {
		let valid = true;
		if (emailSubject === "") {
			valid = false;
		}
		if (emailBody === "") {
			valid = false;
		}
		if (emailToValue.length === 0) {
			valid = false;
		}
		if (valid) {
			const data = {
				to: emailToValue.map((el) => el.value),
				public_id: order.public_id,
				subject: emailSubject,
				body: emailBody,
				lang: i18n.language,
			};
			onSendEmail({
				booked_order_id: order.id,
				data,
			});
		}
	};
	// Render
	const renderStatusChangeForm = () => {
		if (showStatusChange) {
			return (
				<StatusChangeForm
					bookedOrder={order}
					onCancelAction={handleCancelAction}
					onClick={handleChangeStatusClick}
					isLoading={isChangingStatus}
					changeStatusError={changeStatusError}
				/>
			);
		}
	};
	const renderEmailForm = () => {
		if (showEmail) {
			if (isSendingEmail) {
				return (
					<div className="order-confirmation">
						<div>{"Email Operator"}</div>
						<div>{t("OperatorOrders.EmailInstructions")}</div>
						<div className="dsv-form collapse no-padding">
							<Loader />
						</div>
					</div>
				);
			}
			return (
				<>
					<div className="order-confirmation">
						<div>{t("Buttons.EmailOperator")}</div>
						<div>{t("OperatorOrders.EmailInstructions")}</div>
						<div className="dsv-form collapse no-padding">
							<div className="a field">
								<label>{t("Fields.To")}</label>
								<CreatableSelect
									className="dsv-select"
									classNamePrefix="dsv-select"
									components={components}
									inputValue={emailTo}
									isClearable={false}
									isMulti
									menuIsOpen={false}
									onChange={(newValue) => setEmailToValue(newValue)}
									onInputChange={(newValue) => setEmailTo(newValue)}
									onKeyDown={handleEmailToKeyDown}
									placeholder={t("Fields.To")}
									value={emailToValue}
								/>
							</div>
							<div className="a field">
								<label>{t("Fields.EmailSubject")}</label>
								<input
									type="text"
									name="email_subject"
									id="email_subject"
									placeholder={t("Fields.EmailSubject")}
									value={emailSubject}
									onChange={(e) => setEmailSubject(e.target.value)}
								/>
							</div>
							<div className="a field">
								<label>{t("Fields.EmailBody")}</label>
								<textarea
									name="email_body"
									id="email_body"
									placeholder={t("Fields.EmailBody")}
									value={emailBody}
									onChange={(e) => setEmailBody(e.target.value)}
								/>
							</div>
						</div>
						<div className="action-buttons">
							<div>
								<div className="button" onClick={handleCancelAction}>
									{t("Buttons.Cancel")}
								</div>
								<div className="button main" onClick={() => handleSendEmail()}>
									{t("Buttons.Submit")}
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	};
	if (order == null) return null;
	if (
		order.book_status !== BOOKED_STATUS.PENDING &&
		order.book_status !== BOOKED_STATUS.SUBMITTED
	) {
		return (
			<div className="order-block remove-top-space">
				<div className="order-subtitle">
					<div>{t("Common.Actions")}</div>
				</div>
				<div className="action-buttons">
					<div>
						<div className="button action alternate" onClick={handleShowEmail}>
							<span className="icon-email inverted"></span>
							{t("Buttons.EmailOperator")}
						</div>
						<div
							className="button action main"
							onClick={handleShowStatusChange}
						>
							<span className="icon-change-status inverted"></span>
							{t("Buttons.ChangeStatus")}
						</div>
					</div>
				</div>
				{renderStatusChangeForm()}
				{renderEmailForm()}
			</div>
		);
	}
}
