import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USER_ROLE } from "features/constants";
import {
	getUsers,
	getUser,
	addUser,
	saveUser,
	deleteUser,
	setUserEnable,
	changeUserPassword,
	getBranches,
} from "services/usersServices";

const initialState = {
	tab: USER_ROLE.CARRIER,
	carriers: null,
	operators: null,
	admins: null,
	superAdmins: null,
	isLoading: false,
	open: null,
	isOpening: false,
	showDetails: false,
	edit: false,
	pwdChange: false,
	isSaving: false,
	showAdd: false,
	addUserErrors: null,
	editUserErrors: null,
	changePasswordErrors: null,
	isLoadingBranches: false,
	branches: null,
};

export const fetchUsers = createAsyncThunk(
	"admin/fetchUsers",
	async (payload, { rejectWithValue }) => {
		const { role, page } = payload;
		try {
			const response = await getUsers(role, page);
			return { ...response, role };
		} catch (error) {
			return rejectWithValue({ ...error.response, role });
		}
	}
);

/** Reload open user data. */
export const fetchUser = createAsyncThunk(
	"admin/fetchUser",
	async (payload) => {
		const { id } = payload;
		const response = await getUser(id);
		return response;
	}
);

/* Adds user */
export const fetchAddUser = createAsyncThunk(
	"admin/fetchAddUser",
	async (payload, { dispatch, rejectWithValue }) => {
		try {
			const { data, role, page } = payload;
			const body = data;
			const response = await addUser(role, body);
			dispatch(fetchUsers({ role, page }));
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

/* Adds user */
export const fetchSaveUser = createAsyncThunk(
	"admin/fetchSaveUser",
	async (payload, { rejectWithValue, dispatch, getState }) => {
		try {
			const { id, data } = payload;
			const state = getState();
			const response = await saveUser(id, data);
			dispatch(fetchUser({ id }));
			if (state.users.tab) {
				dispatch(fetchUsers({ role: state.users.tab }));
			}
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

/* Changes user password */
export const fetchChangeUserPassword = createAsyncThunk(
	"admin/fetchChangeUserPassword",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await changeUserPassword(payload.id, payload.data);
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

/** Fetch user branches */
export const fetchBranches = createAsyncThunk(
	"admin/fetchBranches",
	async () => {
		const response = await getBranches();
		return response;
	}
);

/**
 * Async Thunk to set user enable.
 * */
export const fetchSetUserEnable = createAsyncThunk(
	"admin/fetchSetUserEnable",
	async (payload, { rejectWithValue, dispatch, getState }) => {
		const { id, value } = payload;
		try {
			const response = await setUserEnable(id, value);
			const state = getState();
			dispatch(fetchUser({ id }));
			// Refresh list
			if (state.users.tab) {
				dispatch(fetchUsers({ role: state.users.tab }));
			}
			return response;
		} catch (error) {
			return rejectWithValue({ ...error.response });
		}
	}
);

/**
 * Async Thunk to delete user.
 */
export const fetchDeleteUser = createAsyncThunk(
	"admin/fetchDeleteUser",
	async (payload, { getState, dispatch }) => {
		const { id } = payload;
		const state = getState();
		const response = await deleteUser(id);
		// Remove open user.
		dispatch(setUserOpen({ open: null }));
		// Refresh list
		if (state.users.tab) {
			dispatch(fetchUsers({ role: state.users.tab }));
		}
		return response;
	}
);

/**
 * Users slice.
 */
const users = createSlice({
	name: "users",
	initialState,
	reducers: {
		setUserOpen: (state, { payload }) => {
			state.open = payload.open;
		},
		setUserEdit: (state, { payload }) => {
			state.edit = payload?.edit ?? false;
			state.pwdChange = false;
		},
		setUserPwdChange: (state, { payload }) => {
			state.pwdChange = payload?.pwdChange ?? false;
			state.edit = false;
		},
		setUsersTab: (state, { payload }) => {
			state.tab = payload.tab;
		},
		setUsersShowAdd: (state, { payload }) => {
			state.showAdd = payload.showAdd ?? false;
		},
		setUsersShowDetails: (state, { payload }) => {
			state.showDetails = payload.showDetails ?? false;
		},
		setErrorNull: (state, { payload }) => {
			if (payload == null) {
				state.addUserErrors = null;
			}
			if (state.addUserErrors) {
				state.addUserErrors[payload] = null;
			}
		},
		setEditErrorNull: (state, { payload }) => {
			if (state.editUserErrors) {
				state.editUserErrors[payload] = null;
			}
		},
		clearUsersData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchUsers.fulfilled, (state, { payload }) => {
				const { role } = payload;
				const { result } = payload.data;
				state.isLoading = false;
				if (role === USER_ROLE.CARRIER) state.carriers = result;
				if (role === USER_ROLE.OPERATOR) state.operators = result;
				if (role === USER_ROLE.ADMIN) state.admins = result;
				if (role === USER_ROLE.SUPERADMIN) state.superAdmins = result;
			})
			.addCase(fetchUsers.rejected, (state, { payload }) => {
				state.isLoading = false;
				const { role } = payload;
				if (role === USER_ROLE.CARRIER) state.carriers = [];
				if (role === USER_ROLE.OPERATOR) state.operators = [];
				if (role === USER_ROLE.ADMIN) state.admins = [];
				if (role === USER_ROLE.SUPERADMIN) state.superAdmins = [];
			})
			/** Fetch add user */
			.addCase(fetchAddUser.pending, (state) => {
				state.isSaving = true;
				state.addUserErrors = null;
			})
			.addCase(fetchAddUser.fulfilled, (state, { payload }) => {
				state.isSaving = false;
				state.showAdd = false;
				state.showDetails = false;
			})
			.addCase(fetchAddUser.rejected, (state, action) => {
				state.isSaving = false;
				// Display errors.
				if (action.payload?.data?.errors)
					state.addUserErrors = action.payload?.data?.errors;
			})
			/** Fetch save user */
			.addCase(fetchSaveUser.pending, (state) => {
				state.isSaving = true;
				state.editUserErrors = null;
			})
			.addCase(fetchSaveUser.fulfilled, (state) => {
				state.isSaving = false;
				state.edit = false;
			})
			.addCase(fetchSaveUser.rejected, (state, { payload }) => {
				state.isSaving = false;
				if (payload?.data?.errors) state.editUserErrors = payload?.data?.errors;
			})
			//
			/** Fetch save user */
			.addCase(fetchChangeUserPassword.pending, (state) => {
				state.isSaving = true;
				state.editUserErrors = null;
			})
			.addCase(fetchChangeUserPassword.fulfilled, (state, { payload }) => {
				state.isSaving = false;
				state.edit = false;
				state.pwdChange = false;
			})
			.addCase(fetchChangeUserPassword.rejected, (state, { payload }) => {
				state.isSaving = false;
				// TODO: Display errors.
				if (payload?.data?.errors)
					state.changePasswordErrors = payload?.data?.errors;
			})
			/** fetchSetUserEnable */
			.addCase(fetchSetUserEnable.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchSetUserEnable.fulfilled, (state) => {
				state.isSaving = false;
			})
			.addCase(fetchSetUserEnable.rejected, (state) => {
				state.isSaving = false;
			})
			// /** fetchDeleteUser */
			.addCase(fetchDeleteUser.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchDeleteUser.fulfilled, (state) => {
				state.isSaving = false;
				state.open = null;
			})
			.addCase(fetchDeleteUser.rejected, (state) => {
				state.isSaving = false;
			})
			/** Load bracnhes data */
			.addCase(fetchBranches.pending, (state) => {
				state.isLoadingBranches = true;
			})
			.addCase(fetchBranches.fulfilled, (state, { payload }) => {
				state.isLoadingBranches = false;
				state.branches = payload.data.result;
			})
			.addCase(fetchBranches.rejected, (state) => {
				state.isLoadingBranches = false;
			})
			/** */
			.addCase(fetchUser.pending, (state) => {
				state.isOpening = true;
			})
			.addCase(fetchUser.fulfilled, (state, { payload }) => {
				state.isOpening = false;
				state.showDetails = true;
				state.open = payload.data.user;
			})
			.addCase(fetchUser.rejected, (state) => {
				state.isOpening = false;
				state.open = null;
			});
	},
});
// {email: ["Emails has been already taken"], username: ["Username has been already taken"]}

export const {
	setUserOpen,
	setUserEdit,
	setUserPwdChange,
	setUsersTab,
	setUsersShowAdd,
	setUsersShowDetails,
	clearUsersData,
	setErrorNull,
	setEditErrorNull,
} = users.actions;

export default users.reducer;
