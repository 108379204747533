export const BASE_API_URL = process.env.REACT_APP_API_URL;

export const PROFILES = {
	ROOT: "Root",
	SUPERADMIN: "Super-Admin",
	ADMIN: "Admin",
	OPERATOR: "Operator",
	CARRIER: "Carrier",
	RATER_OPERATOR: "OperatorPricing",
	RATER_ADMIN: "AdminPricing",
	properties: {
		Root: { value: 1, name: "ProfileTypes.Root", url: "/admin" },
		"Super-Admin": { value: 2, name: "ProfileTypes.SuperAdmin", url: "/admin" },
		Admin: { value: 3, name: "ProfileTypes.Admin", url: "/admin" },
		Operator: { value: 4, name: "ProfileTypes.Operator", url: "/operator" },
		Carrier: { value: 5, name: "ProfileTypes.Carrier", url: "/carrier" },
		OperatorPricing: { value: 6, name: "ProfileTypes.OperatorPricing", url: "/operator" },
		AdminPricing: { value: 7, name: "ProfileTypes.AdminPricing", url: "/admin" },
	},
};

export const USER_ROLE = {
	ROOT: 1,
	SUPERADMIN: 2,
	ADMIN: 3,
	OPERATOR: 4,
	CARRIER: 5,
	properties: {
		1: { value: 1, name: "UserRoles.Root", url: "/admin/users/root" },
		2: {
			value: 2,
			name: "UserRoles.SuperAdmin",
			url: "/admin/users/superadmin",
		},
		3: { value: 3, name: "UserRoles.Admin", url: "/admin/users/admin" },
		4: { value: 4, name: "UserRoles.Operator", url: "/admin/users/operator" },
		5: { value: 5, name: "UserRoles.Carrier", url: "/admin/users/carrier" },
		// for role param from url
		root: { value: 1, name: "UserRoles.Root" },
		superadmin: { value: 2, name: "UserRoles.SuperAdmin" },
		admin: { value: 3, name: "UserRoles.Admin" },
		operator: { value: 4, name: "UserRoles.Operator" },
		carrier: { value: 5, name: "UserRoles.Carrier" },
	},
};

export const LANGUAGES = {
	EN: 1,
	ES: 2,
	properties: {
		1: { value: 1, name: "Languages.en", code: "en", header: "en-US" },
		2: { value: 2, name: "Languages.es", code: "es", header: "es-MX" },
	},
};

export const SHIPMENT_TYPE = {
	PU: 1,
	DE: 2,
	// LH: 3,
	// OT: 4,
	properties: {
		1: { value: 1, name: "ShipmentTypes.Pickup", code: "PU" },
		2: { value: 2, name: "ShipmentTypes.Delivery", code: "DE" },
		// 3: { value: 3, name: "ShipmentType.LineHaul", code: "LH" },
		// 4: { value: 4, name: "ShipmentType.Other", code: "OT" },
	},
};

export const CARGO_TYPE = {
	LOOSE: 1,
	FTL: 2,
	FCL: 3,
	properties: {
		1: { value: 1, name: "CargoTypes.Loose", code: "LOOSE" },
		2: { value: 2, name: "CargoTypes.FTL", code: "FTL" },
		3: { value: 3, name: "CargoTypes.FCL", code: "FCL" },
	},
};

export const WEIGHT_UNIT = {
	LBS: 1,
	KGS: 2,
	properties: {
		1: { value: 1, name: "WeightUnits.Lbs", code: "lb" },
		2: { value: 2, name: "WeightUnits.Kgs", code: "kg" },
	},
};

export const DIMENSION_UNIT = {
	IN: 1,
	CM: 2,
	properties: {
		1: { value: 1, name: "DimensionUnits.In", code: "in" },
		2: { value: 2, name: "DimensionUnits.Cm", code: "cm" },
	},
};

export const VOLUME_UNIT = {
	CBF: 1,
	CBM: 2,
	properties: {
		1: { value: 1, name: "VolumeUnits.CBF", code: "f³" },
		2: { value: 2, name: "VolumeUnits.CBM", code: "m³" },
	},
};

export const TEMPERATURE_UNIT = {
	F: 1,
	C: 2,
	properties: {
		1: { value: 1, name: "TemperatureUnits.F", code: "°F" },
		2: { value: 2, name: "TemperatureUnits.C", code: "°C" },
	},
};

export const SPECIAL_HANDLING = {
	HAZMAT: 1,
	TEMPCTL: 2,
	// OVERSIZED: 3,
	// PERISHABLE: 4,
	// TRIAXLE: 5,
	properties: {
		1: {
			value: 1,
			name: "SpecialHandlings.Hazmat",
			code: "SpecialHandlings.HazmatAbr",
		},
		2: {
			value: 2,
			name: "SpecialHandlings.Tempctl",
			code: "SpecialHandlings.TempctlAbr",
		},
		// 3: { value: 3, name: "SpecialHandlings.Oversized", code: "SpecialHandling.OversizedAbr" },
		// 4: { value: 4, name: "SpecialHandlings.Perishable", code: "SpecialHandling.PerishableAbr" },
		// 5: { value: 5, name: "SpecialHandlings.Triaxle", code: "SpecialHandling.TriaxleAbr" },
	},
};

export const CURRENCY = {
	USD: 1,
	MXN: 2,
	// CAD: 3,
	properties: {
		1: { value: 1, name: "Currency.USD", code: "USD", countryDefault: "US" },
		2: { value: 2, name: "Currency.MXN", code: "MXN", countryDefault: "MX" },
		// 3: { value: 3, name: "Currency.CAD", code: "CAD", countryDefault: "CA" },
	},
};

export const BOOKING_METHOD = {
	DIRECT: 1,
	SPOT: 2,
	DSVROAD: 3,
	properties: {
		1: {
			value: 1,
			name: "BookingMethods.Direct",
			code: "BookingMethods.DirectAbr",
		},
		2: {
			value: 2,
			name: "BookingMethods.Spot",
			code: "BookingMethods.SpotAbr",
		},
		3: {
			value: 3,
			name: "BookingMethods.DsvRoad",
			code: "BookingMethods.DsvRoadAbr",
		},
	},
};

export const CONTACT_TYPE = {
	ORIGIN: 1,
	DESTINATION: 2,
	CONTAINER_PICKUP: 3,
	CONTAINER_DELIVERY: 4,
	FORWARDER: 5,
	BROKER: 6,
};

export const ADDRESS_TYPE = {
	ORIGIN: 1,
	DESTINATION: 2,
	CONTAINER_PICKUP: 3,
	CONTAINER_DELIVERY: 4,
	BROKER: 5,
};

export const BOOKED_STATUS = {
	NOT_BOOKED: 0,
	PENDING: 1,
	SUBMITTED: 2,
	BOOKED: 3,
	REJECTED: 4,
	CANCELED: 5,
	PICKED_UP: 6,
	IN_TRANSIT: 7,
	DELIVERED: 8,
	ARRIVED_PICKUP: 9,
	ARRIVED_DELIVERY: 10,
	properties: {
		0: { value: 0, name: "BookedStatus.NotBooked", show: true },
		1: { value: 1, name: "BookedStatus.Pending", show: true },
		2: { value: 2, name: "BookedStatus.Submitted", show: true },
		3: { value: 3, name: "BookedStatus.Booked", show: true },
		4: { value: 4, name: "BookedStatus.Rejected", show: true },
		5: { value: 5, name: "BookedStatus.Canceled", show: false },
		6: { value: 6, name: "BookedStatus.PickedUp", show: true },
		7: { value: 7, name: "BookedStatus.InTransit", show: true },
		8: { value: 8, name: "BookedStatus.Delivered", show: true },
		9: { value: 9, name: "BookedStatus.ArrivedPickup", show: true },
		10: { value: 10, name: "BookedStatus.ArrivedDelivery", show: true },
	},
};

export const ORDER_EDIT = {
	HEADER: 1,
	ADDRESS: 2,
	CARGO: 3,
	CONTAINER: 4,
	BROKER: 5,
	EMAIL_LIST: 6,
	TRUCKER_INFO: 7,
};

export const BOOKED_ORDER_EDIT = {
	TRUCKER_INFO: 1,
	EMAIL_LIST: 2,
};

export const SPOT_PRICE_STATUS = {
	AWAITING: 1,
	TAKEN: 2,
	EXPIRED: 3,
	properties: {
		1: { value: 1, name: "SpotPriceStatus.Awaiting" },
		2: { value: 2, name: "SpotPriceStatus.Taken" },
		3: { value: 3, name: "SpotPriceStatus.Expired" },
	},
};
export const DOCUMENT_TYPE = {
	DG_DECLARATION: 1,
	BOL: 101,
	POD: 102,
	MEX_CUSTOMS: 103,
	CERTIFICATE_ORIGIN: 104,
	SUPPORTING_DOC: 105,
	OTHER: 199,
	properties: {
		1: { value: 1, name: "DocumentTypes.DgDeclaration", show: true },
		101: { value: 101, name: "DocumentTypes.Bol", show: false },
		102: { value: 102, name: "DocumentTypes.Pod", show: true },
		103: { value: 103, name: "DocumentTypes.MexicanCustoms", show: true },
		104: { value: 104, name: "DocumentTypes.CertificateOrigin", show: true },
		105: {
			value: 105,
			name: "DocumentTypes.SupportingDocumentation",
			show: true,
		},
		199: { value: 199, name: "DocumentTypes.Other", show: true },
	},
};

export const ATTACHABLE_TYPE = {
	ORDER_REQUEST: 1,
	BOOKED_ORDER: 2,
}

export const ACCESSORIALS = {
	BORDER_CROSSING: 1,
	AFTER_HOURS: 2,
	APPOINTMENT: 3,
	INSIDE: 4,
	LIFTGATE: 5,
	NOTIFICATION: 6,
	WEEKEND: 7,
	BONDED: 8,
	OVERWEIGHT: 9,
	PRE_PULL: 10,
	CHASSIS_SPLIT: 11,
	CHASSIS_RENTAL: 12,
	properties: {
		1: { value: 1, name: "Accessorials.BorderCrossing" },
		2: { value: 2, name: "Accessorials.AfterHours" },
		3: { value: 3, name: "Accessorials.Appointment" },
		4: { value: 4, name: "Accessorials.Inside" },
		5: { value: 5, name: "Accessorials.Liftgate" },
		6: { value: 6, name: "Accessorials.Notification" },
		7: { value: 7, name: "Accessorials.Weekend" },
		8: { value: 8, name: "Accessorials.Bonded" },
		9: { value: 9, name: "Accessorials.Overweight" },
		10: { value: 10, name: "Accessorials.PrePull" },
		11: { value: 11, name: "Accessorials.ChassisSplit" },
		12: { value: 12, name: "Accessorials.ChassisRental" },
	},
};
export const LOCATION_TYPES = {
	COMMERCIAL: 1,
	CONSTRUCTION: 2,
	DISTRIBUTION_CENTER: 3,
	EXHIBITION_HALL: 4,
	GOVERNMENT_BUILDING: 5,
	HOSPITAL: 6,
	HOTEL: 7,
	MILITARY: 8,
	RESIDENTIAL: 9,
	SCHOOL: 10,
	FORWARDERS_LOCATION: 11,
	AIRLINE: 12,
	CFS: 13,
	CONTAINER_TERMINAL: 14,
	properties: {
		1: { value: 1, name: "LocationTypes.CommercialLocation" },
		2: { value: 2, name: "LocationTypes.ConstructionSites" },
		3: { value: 3, name: "LocationTypes.DistributionCenter" },
		4: { value: 4, name: "LocationTypes.ExhibitionHall" },
		5: { value: 5, name: "LocationTypes.GovernmentBuilding" },
		6: { value: 6, name: "LocationTypes.Hospital" },
		7: { value: 7, name: "LocationTypes.Hotel" },
		8: { value: 8, name: "LocationTypes.Military" },
		9: { value: 9, name: "LocationTypes.Residential" },
		10: { value: 10, name: "LocationTypes.School" },
		11: { value: 11, name: "LocationTypes.ForwardersLocation" },
		12: { value: 12, name: "LocationTypes.Airline" },
		13: { value: 13, name: "LocationTypes.CFS" },
		14: { value: 14, name: "LocationTypes.ContainerTerminal" },
	},
};
export const CARRIER_FILTERS = {
	BOOKED: 1,
	HISTORY: 2,
	properties: {
		1: {
			filters: {
				book_status: [
					BOOKED_STATUS.PENDING,
					BOOKED_STATUS.SUBMITTED,
					BOOKED_STATUS.BOOKED,
					BOOKED_STATUS.ARRIVED_PICKUP,
					BOOKED_STATUS.PICKED_UP,
					BOOKED_STATUS.IN_TRANSIT,
					BOOKED_STATUS.ARRIVED_DELIVERY,
				],
			},
		},
		2: {
			filters: {
				book_status: [
					BOOKED_STATUS.DELIVERED,
					// BOOKED_STATUS.CANCELED,
					// BOOKED_STATUS.REJECTED,
				],
			},
		},
	},
};
export const SUBSTANCE_GROUP = {
	I: 1,
	II: 2,
	III: 3,
	properties: {
		1: { value: 1, name: "I", code: "I" },
		2: { value: 2, name: "II", code: "II" },
		3: { value: 3, name: "III", code: "III" },
	},
};

export const CARRIER_TAB = {
	DETAILS: 1,
	USERS: 2,
	RATES: 3,
	ZONES: 4,
	LANES: 5,
	ACCESSORIALS: 6,
	QUALIFICATIONS: 7,
	FUEL: 8,
	properties: {
		1: { value: 1, name: "AdminCarriers.Details" },
		2: { value: 2, name: "AdminCarriers.Users" },
		3: { value: 3, name: "AdminCarriers.Rates" },
		4: { value: 4, name: "AdminCarriers.Zones" },
		5: { value: 5, name: "AdminCarriers.Lanes" },
		6: { value: 6, name: "AdminCarriers.Accessorials" },
		7: { value: 7, name: "AdminCarriers.Qualifications" },
		8: { value: 8, name: "AdminCarriers.Fuel" },
	},
};

export const CARRIER_PROFILE_TAB = {
	RATES: 1,
	LANES: 2,
	ZONES: 3,
	properties: {
		1: { value: 1, name: "AdminCarriers.Rates" },
		2: { value: 2, name: "AdminCarriers.Lanes" },
		3: { value: 3, name: "AdminCarriers.Zones" },
	},
};

export const CARRIER_MODE = {
	CARTAGE: 1,
	LTL: 2,
	// PARCEL: 3,
	properties: {
		1: { value: 1, name: "CarrierModes.Cartage" },
		2: { value: 2, name: "CarrierModes.LTL" },
		// 3: { value: 3, name: "CarrierModes.Parcel" },
	},
};

export const RATE_TYPE = {
	FLAT: 1,
	PER_CWT: 2,
	PER_PIECE: 3,
	PER_WEIGHT: 4,
	PER_M3: 5,
	properties: {
		1: { value: 1, name: "RateTypes.Flat" },
		2: { value: 2, name: "RateTypes.CWT" },
		3: { value: 3, name: "RateTypes.Piece" },
		4: { value: 4, name: "RateTypes.Weight" },
		5: { value: 5, name: "RateTypes.M3" },
	},
};

export const FREIGHT_CLASS = {
	CLASS_50: 1,
	CLASS_55: 2,
	CLASS_60: 3,
	CLASS_65: 4,
	CLASS_70: 5,
	CLASS_77_5: 6,
	CLASS_85: 7,
	CLASS_92_5: 8,
	CLASS_100: 9,
	CLASS_110: 10,
	CLASS_125: 11,
	CLASS_150: 12,
	CLASS_175: 13,
	CLASS_200: 14,
	CLASS_250: 15,
	CLASS_300: 16,
	CLASS_400: 17,
	CLASS_500: 18,
	properties: {
		1: { value: 1, name: "50" },
		2: { value: 2, name: "55" },
		3: { value: 3, name: "60" },
		4: { value: 4, name: "65" },
		5: { value: 5, name: "70" },
		6: { value: 6, name: "77.5" },
		7: { value: 7, name: "85" },
		8: { value: 8, name: "92.5" },
		9: { value: 9, name: "100" },
		10: { value: 10, name: "110" },
		11: { value: 11, name: "125" },
		12: { value: 12, name: "150" },
		13: { value: 13, name: "175" },
		14: { value: 14, name: "200" },
		15: { value: 15, name: "250" },
		16: { value: 16, name: "300" },
		17: { value: 17, name: "400" },
		18: { value: 18, name: "500" },
	},
};

export const CARTAGE_PORT = {
	ORIGIN: 1,
	DESTINATION: 2,
	properties: {
		1: { value: 1, name: "Common.Origin" },
		2: { value: 2, name: "Common.Destination" },
	},
};

export const POSTAL_CODE_SOURCE = {
	QUOTE_ORIGIN: 1,
	QUOTE_DESTINATION: 2,
	ORDER_ORIGIN: 3,
	ORDER_DESTINATION: 4,
};

export const ZONE_EXCLUSION_TYPE = {
	CITY: 1,
	POSTAL_CODE: 2,
	properties: {
		1: { value: 1, name: "ZoneExclusionTypes.City" },
		2: { value: 2, name: "ZoneExclusionTypes.PostalCode" },
	}
};

export const CALC_TYPE = {
	FLAT: 1,
	PERCENTAGE: 2,
	properties: {
		1: { value: 1, name: "CalcTypes.Flat" },
		2: { value: 2, name: "CalcTypes.Percentage" },
	},
};
