import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEquipments } from "services/catalogsServices";

/**
 * Define the intial state.
 */
const initialState = { isLoadingEquipments: false, equipments: null };

/**
 * Async reducer function to fetch packagings data.
 */
export const fetchEquipments = createAsyncThunk(
  "catalogs/equipments",
  async () => {
    return await getEquipments();
  }
);

/**
 * Create and configure slice.
 */
const packagingsSlice = createSlice({
  name: "equipments",
  initialState,
  reducers: {
    clearEquipmentsData: () => initialState,
  },
  /**
   * Use extra reducers for async executions.
   * @param {*} builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchEquipments.pending, (state) => {
        state.isLoadingEquipments = true;
      })
      .addCase(fetchEquipments.fulfilled, (state, { payload }) => {
        state.isLoadingEquipments = false;
        state.equipments = payload.data.data;
      })
      .addCase(fetchEquipments.rejected, (state) => {
        state.isLoadingEquipments = false;
        state.equipments = [];
      });
  },
});

export const { clearEquipmentsData } = packagingsSlice.actions;

/**
 * Export slice.
 */
export default packagingsSlice.reducer;
