import client from "./networking";

export const postEmailToCarrier = (booked_order_id, data) => {
	return client.post(`/api/booked_orders/${booked_order_id}/send_email_carrier`, data);
};

export const postFilesToCarrier = (booked_order_id, data) => {
	return client.post(`/api/booked_orders/${booked_order_id}/send_files`, data);
};

export const postEmailToOperator = (booked_order_id, data) => {
	return client.post(`/api/booked_orders/${booked_order_id}/send_email_operator`, data);
};