import client from "./networking";

/**
 * Book an order request directly.
 * @param {*} cartId Cart id.
 * @param {*} rateId Rate id.
 * @returns Instance `AxiosResponse`.
 */
export function bookDirectly(cartId, cartPublicId, rate) {
	// Build body.
	const body = JSON.stringify({
		public_id: cartPublicId,
		rate_id: rate,
	});
	// Send request.
	return client.post(`/api/booking_carts/${cartId}/checkout/directly`, body);
}

/**
 * Spot price for order request.
 * @param {*} cartId Cart id.
 * @param {*} price Booked price as integer.
 * @param {*} rateIds List of rates.
 * @returns Instance `AxiosResponse`.
 */
export function bookSpotPrice(cartId, cartPublicId, carrierIds, price, expires, currency) {
	// Build body.
	const body = JSON.stringify({
		spot_price: price,
		spot_expiration: expires,
		carrier_ids: carrierIds,
		public_id: cartPublicId,
		currency: currency,
	});
	// Send request.
	return client.post(`/api/booking_carts/${cartId}/checkout/spot_price`, body);
}

// TODO:
/**
 * Check cart price.
 * @param {*} cartId Cart id.
 * @returns Instance `AxiosResponse`.
 */
export function bookDsvRoad(cartId, cartPublicId, carrierId) {
	// Build body.
	const body = JSON.stringify({
		public_id: cartPublicId,
		carrier_id: carrierId,
	});
	// Send request.
	return client.post(`/api/booking_carts/${cartId}/checkout/price_check`, body);
}

/**
 * Get Price Check booked order pricing list
 * @param {*} id 
 * @returns 
 */
export const getOrderPriceCheck = (id) => {
	return client.get(`/api/booked_orders/${id}/price_checks`);
}

/**
 * Get Spot Price booked order carrier list
 * @param {*} id 
 * @returns 
 */
export const getOrderSpotPrice = (id) => {
	return client.get(`/api/booked_orders/${id}/spot_prices`);
}