import React from "react";
import { useTranslation } from "react-i18next";
import { numberFormat } from "utils/numberUtils";
import Loader from "components/Loader";
import { CURRENCY } from "features/constants";

export default function OrderPriceCheck({ rates, isLoading }) {
	// Translation
	const { t } = useTranslation();
	if (isLoading) {
		return (
			<div className="order-book-block">
				<div className="order-subtitle">
					<div>{t("OperatorOrders.PricingInfo")}</div>
				</div>
				<Loader />
			</div>
		);
	}
	if (rates != null) {
		return (
			<div className="order-book-block">
				<div className="order-subtitle">
					<div>{t("OperatorOrders.PricingInfo")}</div>
				</div>
				<div className="book-rates-table">
					<table cellPadding="0" cellSpacing="0">
						<thead>
							<tr>
								<th>{t("Common.Carrier")}</th>
								<th>{t("Common.Price")}</th>
							</tr>
						</thead>
						<tbody>
							{rates.map((el, index) => {
								return (
									<tr key={index}>
										<td>{el.carrier_name}</td>
										<td>{`$${numberFormat(el.total_amount * el.exchange_rate, 2)} ${
											CURRENCY.properties[el.currency].code
										}`}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
	return null;
}
