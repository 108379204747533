import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { runUnauthorizedActions } from "features/account/loginSlice";
import {
	bookDirectly,
	bookDsvRoad,
	bookSpotPrice,
	getOrderPriceCheck,
	getOrderSpotPrice,
} from "services/bookingServices";
import {
	getCarrierOrderDetails,
	getCarrierOrderLog,
} from "services/carrierServices";
import { postChangeBookedOrderStatus } from "services/changeStatusServices";
import {
	deleteBookedOrderDocument,
	deleteOrderRequestDocument,
	getBookedOrderDocuments,
	getOrderRequestDocuments,
	uploadBookedOrderDocument,
	uploadOrderRequestDocument,
} from "services/documentServices";

import {
	getOperatorOrderCart,
	getOperatorOrderDetails,
	getOperatorOrders,
	putHeaderInfo,
	putAddressInfo,
	putCargoInfo,
	getSelfCarriersList,
	postOperatorQuoteAccept,
	postOperatorQuoteReject,
	putTruckerInfo,
	putBookedOrderNotifyParties,
	putBrokerInfo,
	putContainerInfo,
} from "services/operatorServices";
import { getBookedOrderTabs } from "utils/orderUtils";
import {
	clearOperatorConsolidationOrders,
	fetchOperatorSimilarOrders,
} from "./operatorOrderConsolidationSlice";
import { ATTACHABLE_TYPE, BOOKING_METHOD } from "features/constants";
import { postEmailToCarrier, postFilesToCarrier } from "services/emailServices";

const initialState = {
	/* Orders list */
	list: null,
	isLoading: false,
	refresh: false,
	/* Open order  */
	isLoadingOpen: false,
	isLoadingCart: false,
	openOrder: null,
	orderCart: null,
	bookedOrder: null,
	bookedTab: 0,
	bookedTabs: null,
	isLoadingBooked: false,
	/* Edit an order  */
	orderEdit: null,
	isSaving: false,
	/* Order status */
	openLog: null,
	isLoadingLog: false,
	isChangingStatus: false,
	changeStatusError: null,
	/* Trucker Info */
	isSavingTruckerInfo: false,
	/* Notify Parties */
	isSavingEmailList: false,
	/* DSV Road carriers */
	selfCarriers: null,
	/* Order documents */
	docs: null,
	isLoadingDocs: false,
	showUpload: false,
	isUploading: false,
	isDeleting: false,
	uploadPercent: 0,
	/* Order consolidation */
	consolidate: false,
	/* Price check */
	isLoadingPriceCheckRates: false,
	priceCheckRates: null,
	isLoadingAccept: false,
	/* Spor price */
	isLoadingSpotPrice: false,
	spotPrice: null,
	/* Send Email */
	isSendingEmail: false,
	isEmailSent: false,
};
/**
 * Async fetch functions
 */
/* Get operator's order list */
export const fetchOperatorOrders = createAsyncThunk(
	"operator/fetchOperatorOrders",
	async (payload, { rejectWithValue, getState }) => {
		try {
			const { page, filters } = payload;
			const activePage =
				page ?? getState().operatorOrders.list?.current_page ?? 1;
			const activeFilters = filters ?? {};
			const response = await getOperatorOrders(activePage, activeFilters);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
/* Get operator's opened order details */
export const fetchOperatorOrderDetails = createAsyncThunk(
	"operator/fetchOperatorOrderDetails",
	async (payload, { dispatch }) => {
		const { id, consolidate } = payload;
		const response = await getOperatorOrderDetails(id);
		if (response.data.data.booked_orders.length === 0) {
			dispatch(fetchOperatorOrderRequestDocs({ id }));
			dispatch(fetchOperatorSimilarOrders({ orderId: id }));
			if (!consolidate) {
				dispatch(
					fetchOperatorOrderCart({
						primary: response.data.data.id,
						ids: [response.data.data.id],
					})
				);
			}
		} else {
			const booked_order = response.data.data.booked_orders[0];
			dispatch(fetchOperatorOrderLog({ id: booked_order.id }));
			dispatch(fetchOperatorBookedOrderDocs({ id: booked_order.id }));
			dispatch(fetchOperatorBookedOrder({ id: booked_order.id }));
		}
		return response;
	}
);
/* Get operator's opened booked order details */
export const fetchOperatorBookedOrder = createAsyncThunk(
	"operator/fetchOperatorBookedOrder",
	async (payload, { dispatch }) => {
		const { id } = payload;
		const response = await getCarrierOrderDetails(id);
		const tabs = getBookedOrderTabs(response.data.data);
		dispatch(setOperatorBookedOrderTabs({ bookedTabs: tabs }));
		if (response.data.data.booked_method === BOOKING_METHOD.DSVROAD) {
			dispatch(fetchOperatorBookedOrderPriceCheckRates({ id }));
		}
		if (response.data.data.booked_method === BOOKING_METHOD.SPOT) {
			dispatch(fetchOperatorSpotPriceList({ id }));
		}
		return response;
	}
);
/* Get booked order log */
export const fetchOperatorOrderLog = createAsyncThunk(
	"operator/fetchOperatorOrderLog",
	async (payload) => {
		const response = getCarrierOrderLog(payload.id);
		return response;
	}
);
/* Get oparator's opened order's booking cart */
export const fetchOperatorOrderCart = createAsyncThunk(
	"operator/fetchOperatorOrderCart",
	async (payload) => {
		const { primary, ids } = payload;
		return await getOperatorOrderCart(primary, ids);
	}
);
/* Post book direct */
export const fetchBookDirect = createAsyncThunk(
	"operator/fetchBookDirect",
	async (payload, { dispatch }) => {
		const { orderId, id, cartId, rateId } = payload;
		const response = await bookDirectly(id, cartId, rateId);
		//dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
		dispatch(clearOperatorConsolidationOrders());
		return response;
	}
);
/* Post book sopt price */
export const fetchBookSpot = createAsyncThunk(
	"operator/fetchBookSpot",
	async (payload, { dispatch }) => {
		const { orderId, id, cartId, carrierIds, price, expires, currency } =
			payload;
		const response = await bookSpotPrice(
			id,
			cartId,
			carrierIds,
			price,
			expires,
			currency
		);
		// dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
		dispatch(clearOperatorConsolidationOrders());
		return response;
	}
);

/* Post book price check with DSV Road */
export const fetchBookDsvRoad = createAsyncThunk(
	"operator/fetchBookDsvRoad",
	async (payload, { dispatch }) => {
		const { orderId, id, cartId, carrierId } = payload;
		const response = await bookDsvRoad(id, cartId, carrierId);
		// dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
		dispatch(clearOperatorConsolidationOrders());
		return response;
	}
);
/* Put update order addresses */
export const fetchUpdateOrderAddress = createAsyncThunk(
	"operator/fetchUpdateOrderAddress",
	async (payload, { dispatch }) => {
		const { orderId } = payload;
		const response = await putAddressInfo(orderId, payload.formData);
		// dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		return response;
	}
);
/* Put update order references */
export const fetchUpdateOrderHeader = createAsyncThunk(
	"operator/fetchUpdateOrderHeader",
	async (payload, { dispatch }) => {
		const { orderId } = payload;
		const response = await putHeaderInfo(orderId, payload.formData);
		// dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		return response;
	}
);
/* Put update order cargo */
export const fetchUpdateOrderCargo = createAsyncThunk(
	"operator/fetchUpdateOrderCargo",
	async (payload, { dispatch }) => {
		const { orderId } = payload;
		const response = await putCargoInfo(orderId, payload.formData);
		// dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		return response;
	}
);
/* Put update order broker info */
export const fetchUpdateOrderBroker = createAsyncThunk(
	"operator/fetchUpdateOrderBroker",
	async (payload, { dispatch }) => {
		const { orderId, formData } = payload;
		const response = await putBrokerInfo(orderId, formData);
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		return response;
	}
);
/* Put update order container info */
export const fetchUpdateOrderContainer = createAsyncThunk(
	"operator/fetchUpdateOrderContainer",
	async (payload, { dispatch }) => {
		const { orderId, formData } = payload;
		const response = await putContainerInfo(orderId, formData);
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		return response;
	}
);
/* Get self carriers list */
export const fetchSelfCarrierList = createAsyncThunk(
	"operator/fetchSelfCarrierList",
	async () => {
		const response = await getSelfCarriersList();
		return response;
	}
);
/* Post change order status */
export const fetchChangeOperatorOrderStatus = createAsyncThunk(
	"operator/fetchChangeOperatorOrderStatus",
	async (payload, { dispatch, rejectWithValue }) => {
		const {
			request_id,
			id,
			public_id,
			status,
			date_specified,
			location,
			notes,
		} = payload;
		try {
			const response = await postChangeBookedOrderStatus(
				id,
				public_id,
				status,
				date_specified,
				location,
				notes
			);
			dispatch(fetchOperatorOrderDetails({ id: request_id }));
			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
/* Get booked order documents */
export const fetchOperatorBookedOrderDocs = createAsyncThunk(
	"operator/fetchOperatorBookedOrderDocs",
	async (payload) => {
		const { id } = payload;
		const response = await getBookedOrderDocuments(id);
		return response;
	}
);
export const fetchOperatorOrderRequestDocs = createAsyncThunk(
	"operator/fetchOperatorOrderRequestDocs",
	async (payload) => {
		const { id } = payload;
		const response = await getOrderRequestDocuments(id);
		return response;
	}
);
/* Upload document */
export const fetchOperatorOrderUploadDoc = createAsyncThunk(
	"documents/fetchOperatorOrderUploadDoc",
	async (payload, { dispatch }) => {
		const { attachableId, type, file, attachableType, file_number } = payload;
		if (attachableType === ATTACHABLE_TYPE.BOOKED_ORDER) {
			const response = await uploadBookedOrderDocument(
				attachableId,
				type,
				file,
				file_number,
				(progressEvent) => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total);
					dispatch(setOperatorOrderUploadProgress(percent));
				}
			);
			dispatch(fetchOperatorBookedOrderDocs({ id: attachableId }));
			return response;
		} else {
			const response = await uploadOrderRequestDocument(
				attachableId,
				type,
				file,
				(progressEvent) => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total);
					dispatch(setOperatorOrderUploadProgress(percent));
				}
			);
			dispatch(fetchOperatorOrderRequestDocs({ id: attachableId }));
			return response;
		}
	}
);
/* Delete document */
export const fetchOperatorOrderDeleteDoc = createAsyncThunk(
	"documents/fetchOperatorOrderDeleteDoc",
	async (payload, { dispatch }) => {
		const { id, attachableId, attachableType } = payload;
		if (attachableType === ATTACHABLE_TYPE.BOOKED_ORDER) {
			const response = await deleteBookedOrderDocument(id, attachableId);
			dispatch(fetchOperatorBookedOrderDocs({ id: attachableId }));
			return response;
		}
		if (attachableId === ATTACHABLE_TYPE.ORDER_REQUEST) {
			const response = await deleteOrderRequestDocument(id, attachableId);
			dispatch(fetchOperatorOrderRequestDocs({ id: attachableId }));
			return response;
		}
	}
);
/* Get booked order price check rates */
export const fetchOperatorBookedOrderPriceCheckRates = createAsyncThunk(
	"operator/fetchOperatorBookedOrderPriceCheckRates",
	async (payload) => {
		const { id } = payload;
		const response = await getOrderPriceCheck(id);
		return response;
	}
);
/* Accept Price Check Quote */
export const fetchOperatorPriceCheckAccept = createAsyncThunk(
	"operator/fetchOperatorPriceCheckAccept",
	async (payload, { dispatch }) => {
		const { bookedId, orderId, data } = payload;
		const response = await postOperatorQuoteAccept(bookedId, data);
		// dispatch(fetchOperatorOrders());
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		dispatch(setOperatorOrdersRefresh({ refresh: true }));
		return response;
	}
);
/* Accept Price Check Reject */
export const fetchOperatorPriceCheckReject = createAsyncThunk(
	"operator/fetchOperatorPriceCheckReject",
	async (payload, { dispatch }) => {
		const { bookedId, orderId, data } = payload;
		const response = await postOperatorQuoteReject(bookedId, data);
		dispatch(fetchOperatorOrderDetails({ id: orderId }));
		dispatch(setOperatorOrdersRefresh({ refresh: true }));
		return response;
	}
);
/* Get Booked order SpotPrice list */
export const fetchOperatorSpotPriceList = createAsyncThunk(
	"operator/fetchOperatorSpotPriceList",
	async (payload) => {
		const { id } = payload;
		const response = await getOrderSpotPrice(id);
		return response;
	}
);
/* Send Email to Carrier */
export const fetchOperatorSendEmailToCarrier = createAsyncThunk(
	"operator/fetchOperatorSendEmailToCarrier",
	async (payload) => {
		const { booked_order_id, files, data } = payload;
		const response = !files
			? await postEmailToCarrier(booked_order_id, data)
			: postFilesToCarrier(booked_order_id, data);
		return response;
	}
);

/* Update trucker info */
export const fetchOperatorOrderUpdateTruckerInfo = createAsyncThunk(
	"operator/fetchOperatorOrderUpdateTruckerInfo",
	async (payload, { dispatch }) => {
		const { bookedOrderId, id, data } = payload;
		const response = await putTruckerInfo(bookedOrderId, data);
		dispatch(fetchOperatorOrderDetails({ id }));
		return response;
	}
);

export const fetchOperatorOrderUpdateNotifyParties = createAsyncThunk(
	"operator/fetchOperatorOrderUpdateNotifyParties",
	async (payload, { dispatch }) => {
		const { bookedOrderId, id, data } = payload;
		const response = await putBookedOrderNotifyParties(bookedOrderId, data);
		dispatch(fetchOperatorOrderDetails({ id }));
		return response;
	}
);

/**
 * Create the reducer using `createSlice`.
 */
export const operatorOrdersSlice = createSlice({
	name: "operatorOrdersSlice",
	initialState,
	reducers: {
		setOperatorOrdersList: (state, { payload }) => {
			state.list = payload.list;
		},
		setOperatorOpenOrder: (state, { payload }) => {
			state.openOrder = payload.openOrder;
		},
		setOperatorBookedOrder: (state, { payload }) => {
			state.bookedOrder = payload.bookedOrder ?? null;
		},
		setOperatorBookedOrderTab: (state, { payload }) => {
			state.bookedTab = payload.bookedTab ?? 0;
		},
		setOperatorBookedOrderTabs: (state, { payload }) => {
			state.bookedTabs = payload.bookedTabs ?? null;
		},
		setOperatorOrderEdit: (state, { payload }) => {
			state.orderEdit = payload?.orderEdit ?? null;
		},
		setOperatorOrderCart: (state, { payload }) => {
			state.orderCart = payload.orderCart;
		},
		setOperatorOrderLog: (state, { payload }) => {
			state.openLog = payload?.openLog ?? null;
		},
		setOperatorOrderStatusError: (state, { payload }) => {
			state.changeStatusError = payload?.changeStatusError ?? null;
		},
		setOperatorOrderDocs: (state, { payload }) => {
			state.docs = payload?.docs ?? null;
		},
		setOperatorOrderConsolidate: (state, { payload }) => {
			state.consolidate = payload.consolidate ?? false;
		},
		setOperatorBookedOrderPriceCheckRates: (state, { payload }) => {
			state.priceCheckRates = payload.priceCheckRates ?? null;
		},
		setOperatorOrderShowUpload: (state, { payload }) => {
			state.showUpload = payload?.showUpload ?? false;
		},
		setOperatorOrderUploadProgress: (state, { payload }) => {
			state.uploadPercent = payload ?? 0;
		},
		setOperatorOrdersRefresh: (state, { payload }) => {
			state.refresh = payload?.refresh ?? false;
		},
		clearOperatorOrdersEmailSent: (state) => {
			state.isEmailSent = false;
		},
		clearOperatorOrders: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// Fetch orders
			.addCase(fetchOperatorOrders.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchOperatorOrders.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
				state.refresh = false;
			})
			.addCase(fetchOperatorOrders.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.refresh = false;

				if (
					payload != null &&
					payload?.status != null &&
					payload.status === 403
				)
					state.list = null;
				else state.list = [];
			})
			// Fetch order details
			.addCase(fetchOperatorOrderDetails.pending, (state) => {
				state.isLoadingOpen = true;
			})
			.addCase(fetchOperatorOrderDetails.fulfilled, (state, { payload }) => {
				state.isLoadingOpen = false;
				state.openOrder = payload.data.data;
			})
			.addCase(fetchOperatorOrderDetails.rejected, (state) => {
				state.isLoadingOpen = false;
				state.openOrder = null;
			})
			// Fetch booked order details
			.addCase(fetchOperatorBookedOrder.pending, (state) => {
				state.isLoadingBooked = true;
			})
			.addCase(fetchOperatorBookedOrder.fulfilled, (state, { payload }) => {
				state.isLoadingBooked = false;
				state.bookedOrder = payload.data.data;
			})
			.addCase(fetchOperatorBookedOrder.rejected, (state) => {
				state.isLoadingBooked = false;
				state.bookedOrder = null;
			})
			// Fetch order log
			.addCase(fetchOperatorOrderLog.pending, (state) => {
				state.isLoadingLog = true;
			})
			.addCase(fetchOperatorOrderLog.fulfilled, (state, { payload }) => {
				state.isLoadingLog = false;
				state.openLog = payload.data.data;
			})
			.addCase(fetchOperatorOrderLog.rejected, (state) => {
				state.isLoadingLog = false;
				state.openLog = null;
			})
			// Fetch order booking cart
			.addCase(fetchOperatorOrderCart.pending, (state) => {
				state.isLoadingCart = true;
			})
			.addCase(fetchOperatorOrderCart.fulfilled, (state, { payload }) => {
				state.isLoadingCart = false;
				state.orderCart = payload.data.data;
			})
			.addCase(fetchOperatorOrderCart.rejected, (state) => {
				state.isLoadingCart = false;
				state.orderCart = null;
			})
			// Fetch book direct
			.addCase(fetchBookDirect.pending, (state) => {
				state.isLoadingCart = true;
				state.isLoadingOpen = true;
			})
			.addCase(fetchBookDirect.fulfilled, (state) => {
				state.orderCart = null;
				state.isLoadingCart = false;
				state.isLoadingOpen = false;
				state.refresh = true;
			})
			.addCase(fetchBookDirect.rejected, (state) => {
				state.isLoadingCart = false;
				state.isLoadingOpen = false;
			})
			// Fetch Book Spot Price
			.addCase(fetchBookSpot.pending, (state) => {
				state.isLoadingCart = true;
				state.isLoadingOpen = true;
			})
			.addCase(fetchBookSpot.fulfilled, (state) => {
				state.orderCart = null;
				state.isLoadingCart = false;
				state.isLoadingOpen = false;
				state.refresh = true;
			})
			.addCase(fetchBookSpot.rejected, (state) => {
				state.isLoadingCart = false;
				state.isLoadingOpen = false;
			})
			// Fetch Book DSV Road
			.addCase(fetchBookDsvRoad.pending, (state) => {
				state.isLoadingCart = true;
				state.isLoadingOpen = true;
			})
			.addCase(fetchBookDsvRoad.fulfilled, (state) => {
				state.orderCart = null;
				state.isLoadingCart = false;
				state.isLoadingOpen = false;
				state.refresh = true;
			})
			.addCase(fetchBookDsvRoad.rejected, (state) => {
				state.isLoadingCart = false;
				state.isLoadingOpen = false;
			})
			// Fetch Update Order Header
			.addCase(fetchUpdateOrderHeader.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateOrderHeader.fulfilled, (state, { payload }) => {
				state.isSaving = false;
				state.orderEdit = null;
				state.refresh = true;
			})
			.addCase(fetchUpdateOrderHeader.rejected, (state) => {
				state.isSaving = false;
			})
			// Fetch Update Order Address
			.addCase(fetchUpdateOrderAddress.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateOrderAddress.fulfilled, (state) => {
				state.isSaving = false;
				state.orderEdit = null;
				state.refresh = true;
			})
			.addCase(fetchUpdateOrderAddress.rejected, (state) => {
				state.isSaving = false;
			})
			// Fetch Update Order Cargo
			.addCase(fetchUpdateOrderCargo.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateOrderCargo.fulfilled, (state) => {
				state.isSaving = false;
				state.orderEdit = null;
				state.refresh = true;
			})
			.addCase(fetchUpdateOrderCargo.rejected, (state) => {
				state.isSaving = false;
			})
			// Fetch Update Order Broker
			.addCase(fetchUpdateOrderBroker.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateOrderBroker.fulfilled, (state) => {
				state.isSaving = false;
				state.orderEdit = null;
				state.refresh = true;
			})
			.addCase(fetchUpdateOrderBroker.rejected, (state) => {
				state.isSaving = false;
			})
			// Fetch Update Order Container
			.addCase(fetchUpdateOrderContainer.pending, (state) => {
				state.isSaving = true;
			})
			.addCase(fetchUpdateOrderContainer.fulfilled, (state) => {
				state.isSaving = false;
				state.orderEdit = null;
				state.refresh = true;
			})
			.addCase(fetchUpdateOrderContainer.rejected, (state) => {
				state.isSaving = false;
			})
			// Fetch Get self carriers list
			.addCase(fetchSelfCarrierList.fulfilled, (state, { payload }) => {
				state.selfCarriers = payload.data.data;
			})
			.addCase(fetchSelfCarrierList.rejected, (state) => {
				state.selfCarriers = [];
			})
			// Fetch change order status
			.addCase(fetchChangeOperatorOrderStatus.pending, (state) => {
				state.isChangingStatus = true;
			})
			.addCase(fetchChangeOperatorOrderStatus.fulfilled, (state) => {
				state.isChangingStatus = false;
				state.refresh = true;
			})
			.addCase(
				fetchChangeOperatorOrderStatus.rejected,
				(state, { payload }) => {
					state.isChangingStatus = false;
					state.changeStatusError = payload?.message ?? null;
				}
			)
			// Fetch booked order documents
			.addCase(fetchOperatorBookedOrderDocs.pending, (state) => {
				state.isLoadingDocs = true;
			})
			.addCase(fetchOperatorBookedOrderDocs.fulfilled, (state, { payload }) => {
				state.isLoadingDocs = false;
				state.docs = payload.data.data;
			})
			.addCase(fetchOperatorBookedOrderDocs.rejected, (state) => {
				state.isLoadingDocs = false;
				state.docs = [];
			})
			// Fetch order request documents
			.addCase(fetchOperatorOrderRequestDocs.pending, (state) => {
				state.isLoadingDocs = true;
			})
			.addCase(
				fetchOperatorOrderRequestDocs.fulfilled,
				(state, { payload }) => {
					state.isLoadingDocs = false;
					state.docs = payload.data.data;
				}
			)
			.addCase(fetchOperatorOrderRequestDocs.rejected, (state) => {
				state.isLoadingDocs = false;
				state.docs = [];
			})
			// Fetch Price Check Rates
			.addCase(fetchOperatorBookedOrderPriceCheckRates.pending, (state) => {
				state.isLoadingPriceCheckRates = true;
			})
			.addCase(
				fetchOperatorBookedOrderPriceCheckRates.fulfilled,
				(state, { payload }) => {
					state.isLoadingPriceCheckRates = false;
					state.priceCheckRates = payload.data.data;
				}
			)
			.addCase(fetchOperatorBookedOrderPriceCheckRates.rejected, (state) => {
				state.isLoadingPriceCheckRates = false;
				state.priceCheckRates = [];
			})
			// Fetch Price Check Accept
			.addCase(fetchOperatorPriceCheckAccept.pending, (state) => {
				state.isLoadingAccept = true;
			})
			.addCase(fetchOperatorPriceCheckAccept.fulfilled, (state) => {
				state.isLoadingAccept = false;
				state.refresh = true;
			})
			.addCase(fetchOperatorPriceCheckAccept.rejected, (state) => {
				state.isLoadingAccept = false;
				state.refresh = true;
			})
			// Fetch Price Check Reject
			.addCase(fetchOperatorPriceCheckReject.pending, (state) => {
				state.isLoadingAccept = true;
			})
			.addCase(fetchOperatorPriceCheckReject.fulfilled, (state, payload) => {
				state.isLoadingAccept = false;
				state.refresh = true;
			})
			.addCase(fetchOperatorPriceCheckReject.rejected, (state) => {
				state.isLoadingAccept = false;
				state.refresh = true;
			})
			// Fetch Spot Price List
			.addCase(fetchOperatorSpotPriceList.pending, (state) => {
				state.isLoadingSpotPrice = true;
			})
			.addCase(fetchOperatorSpotPriceList.fulfilled, (state, { payload }) => {
				state.isLoadingSpotPrice = false;
				state.spotPrice = payload.data.data;
			})
			.addCase(fetchOperatorSpotPriceList.rejected, (state) => {
				state.isLoadingSpotPrice = false;
				state.spotPrice = null;
			})
			// Upload Document
			.addCase(fetchOperatorOrderUploadDoc.pending, (state) => {
				state.isUploading = true;
			})
			.addCase(fetchOperatorOrderUploadDoc.fulfilled, (state) => {
				state.isUploading = false;
				state.showUpload = false;
			})
			.addCase(fetchOperatorOrderUploadDoc.rejected, (state) => {
				state.isUploading = false;
			})
			// Delete Document
			.addCase(fetchOperatorOrderDeleteDoc.pending, (state) => {
				state.isDeleting = true;
			})
			.addCase(fetchOperatorOrderDeleteDoc.fulfilled, (state) => {
				state.isDeleting = false;
			})
			.addCase(fetchOperatorOrderDeleteDoc.rejected, (state) => {
				state.isDeleting = false;
			})
			// Fetch Send Email To Carrier
			.addCase(fetchOperatorSendEmailToCarrier.pending, (state) => {
				state.isSendingEmail = true;
			})
			.addCase(fetchOperatorSendEmailToCarrier.fulfilled, (state) => {
				state.isSendingEmail = false;
				state.isEmailSent = true;
			})
			.addCase(fetchOperatorSendEmailToCarrier.rejected, (state) => {
				state.isSendingEmail = false;
			})
			/* Update Trucker Info */
			.addCase(fetchOperatorOrderUpdateTruckerInfo.pending, (state) => {
				state.isSavingTruckerInfo = true;
			})
			.addCase(fetchOperatorOrderUpdateTruckerInfo.fulfilled, (state) => {
				state.isSavingTruckerInfo = false;
				state.orderEdit = null;
			})
			.addCase(fetchOperatorOrderUpdateTruckerInfo.rejected, (state) => {
				state.isSavingTruckerInfo = false;
			})
			/* Update Notify Parties */
			.addCase(fetchOperatorOrderUpdateNotifyParties.pending, (state) => {
				state.isSavingEmailList = true;
			})
			.addCase(fetchOperatorOrderUpdateNotifyParties.fulfilled, (state) => {
				state.isSavingEmailList = false;
				state.orderEdit = null;
			})
			.addCase(fetchOperatorOrderUpdateNotifyParties.rejected, (state) => {
				state.isSavingEmailList = false;
			});
	},
});

/**
 * Export actions.
 */
export const {
	setOperatorOrdersList,
	setOperatorOpenOrder,
	setOperatorBookedOrder,
	setOperatorBookedOrderTab,
	setOperatorBookedOrderTabs,
	setOperatorOrderEdit,
	setOperatorOrderCart,
	setOperatorOrderLog,
	setOperatorOrderStatusError,
	setOperatorOrderDocs,
	setOperatorOrderConsolidate,
	setOperatorBookedOrderPriceCheckRates,
	setOperatorOrderShowUpload,
	setOperatorOrderUploadProgress,
	setOperatorOrdersRefresh,
	clearOperatorOrdersEmailSent,
	clearOperatorOrders,
} = operatorOrdersSlice.actions;

export default operatorOrdersSlice.reducer;
