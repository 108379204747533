/**
 * Formats a number as string
 * @param {number} num number to format
 * @param {int} places decimal places, default 0
 * @returns fromatted string
 */
export const numberFormat = (num, places = 0) => {
	const number = num??0;
	return number.toFixed(places).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

/**
 * Formats a number as string with leading zeros
 * @param {number} num
 * @param {int} positions
 * @returns
 */
export const stringNumberFormat = (num, positions = 8) => {
	const leadingNumber = "0000000000000000" + num;
	return leadingNumber.substring(leadingNumber.length - positions);
};
