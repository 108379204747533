import React from "react";
import { useTranslation } from "react-i18next";

export default function StringFilter({ fieldItem, register }) {
  const { t } = useTranslation();

  // Render
  return (
    <div className="a field">
      <label>{t(fieldItem.name)}</label>
      <input
        type="text"
        placeholder={t(fieldItem.name)}
        {...register(fieldItem.field, { required: true })}
      />
    </div>
  );
}
