import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOperatorOrderEdit,
} from "features/operator/operatorOrdersSlice";
import { fetchPackagings } from "features/catalogs/packagingsSlice";

import { ORDER_EDIT } from "features/constants";
import OrderCargoForm from "components/common/order/OrderCargoForm";
import {
	fetchUpdateConsolidateCargo,
	setOperatorConsolidationTabEdit,
} from "features/operator/operatorOrderConsolidationSlice";

export default function OperatorConsolidateDetailsCargoEdit({ collapse }) {
	// Redux dispatch
	const dispatch = useDispatch();

	// Selectors
	const { openOrder, orderEdit } = useSelector((state) => state.operatorOrders);
	const { selectedOrder, edit, activeTab, tabs, isSaving} = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	const { packagings, isLoading } = useSelector((state) => state.packagings);

	// Effect
	useEffect(() => {
		if (packagings == null && !isLoading) {
			dispatch(fetchPackagings());
		}
	}, [packagings, dispatch, isLoading]);

	// Submit function
	const handleSaveCargoData = (data) => {
		const payload = activeTab === 0 ? { 
			orderId: openOrder.id, 
			formData: data,
			primary: openOrder.id,
			ids: tabs.map((el) => el.id)
		} : { 
			orderId: selectedOrder.id, 
			formData: data,
			primary: openOrder.id,
			ids: tabs.map((el) => el.id)
		};
		dispatch(fetchUpdateConsolidateCargo(payload));
	};

	const handleCancelEdit = () => {
		if (activeTab === 0) {
			dispatch(setOperatorOrderEdit({ orderEdit: null }));
		}
		if (activeTab >= 1) {
			dispatch(setOperatorConsolidationTabEdit({ edit: null }));
		}
	};

	// Render
	if (
		activeTab === 0 &&
		orderEdit != null &&
		orderEdit === ORDER_EDIT.CARGO &&
		openOrder != null && packagings != null
	) {
		return (
			<OrderCargoForm
				collapse={collapse}
				order={openOrder}
				edit={orderEdit}
				packagings={packagings}
				isSaving={isSaving}
				onSave={handleSaveCargoData}
				onCancel={handleCancelEdit}
			/>
		);
	}
	if (
		activeTab > 0 &&
		edit != null &&
		edit === ORDER_EDIT.CARGO &&
		selectedOrder != null && packagings != null
	) {
		return (
			<OrderCargoForm
				collapse={collapse}
				order={selectedOrder}
				edit={edit}
				packagings={packagings}
				isSaving={isSaving}
				onSave={handleSaveCargoData}
				onCancel={handleCancelEdit}
			/>
		);
	}
	return null;
}
