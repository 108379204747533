import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TruckerInfo from "components/common/order/TruckerInfo";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";

const OperatorOrderDetailsTruckerInfo = () => {
	const dispatch = useDispatch();
	// Selectors
	const { bookedOrder, orderEdit, isLoadingOpen, isLoadingBooked } = useSelector(
		(state) => state.operatorOrders
	);
	// Handlers
	const handleEdit = () => {
		dispatch(setOperatorOrderEdit({orderEdit: ORDER_EDIT.TRUCKER_INFO}));
	};
	// Render
	if (bookedOrder == null) return null;
	if (orderEdit === ORDER_EDIT.TRUCKER_INFO) return null;
	return (
		<TruckerInfo
			order={bookedOrder}
			edit={orderEdit}
			onEdit={handleEdit}
			isLoading={isLoadingOpen === true || isLoadingBooked === true }
		/>
	);
};

export default OperatorOrderDetailsTruckerInfo;
