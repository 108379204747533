import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CARRIER_TAB } from "features/constants";
import {
  getCarrier,
  getCarrierLanes,
  getCarrierLanesCatalog,
  getCarrierRates,
  getCarriers,
  getCarrierZones,
  addCarrier,
  saveCarrier,
  addCarrierZone,
  saveCarrierZone,
  setCarrierAccesorials,
  addCarrierLane,
  saveCarrierLane,
  setCarrierQualifications,
  addCarrierRate,
  saveCarrierRate,
  getCarrierFuel,
  addCarrierFuel,
  saveCarrierFuel,
  getCarrierZoneCatalog,
  getCarrierZoneExclusions,
  addMultipleCarrierZones,
  saveCarrierZoneExclusions,
	getCarrierUsers,
	changeCarrierStatus,
  getCarrierCustomersCatalog,
	getCarrierZone
} from "services/adminCarriersServices";

const initialState = {
  /** CARRIER */
  tab: CARRIER_TAB.RATES,
  list: null,
  isLoading: false,
  open: null,
  isOpening: false,
  edit: false,
  isSaving: false,
  showDetails: false,
  showAdd: false,
  addCarrierErrors: null,
  saveCarrierErrors: null,
  addFormState: null,
  saveFormState: null,
	/** USERS */
	users: null,
	isLoadingUsers: false,
	user: null,
	userEdit: false,
	userAdd: false,
	isSavingUser: false,
	saveCarrierUserErrors: null,
  /** RATES */
  rates: null,
  isLoadingRates: false,
  rate: null,
  rateEdit: false,
  rateAdd: false,
  isSavingRate: false,
  saveCarrierRateErrors: null,
  /** ZONES */
  zones: null,
  zonesCatalog: null,
  isLoadingZones: false,
  isLoadingZonesCatalog: false,
  zone: null,
  zoneEdit: false,
  zoneAdd: false,
  isSavingZone: false,
  saveCarrierZoneErrors: null,
  saveCarrierMultipleZoneErrors: null,
  /** ZONE EXCLUSION */
  isSavingZoneExclusions: false,
  saveCarrierZoneExclusionsErrors: null,
  // saveCarrierZoneExclusionsErrors: null,
  /** LANES */
  lanes: null,
  isLoadingLanes: false,
  lane: null,
  laneEdit: false,
  laneAdd: false,
  isLoadingAllLanes: false,
  allLanes: null,
  isSavingLane: false,
  saveCarrierLaneErrors: null,
  /** ACCESORIALS */
  accessorials: null,
  isLoadingAccessorials: false,
  isSavingAccesorials: false,
  saveCarrierAccesorialsErrors: null,
  accessorialsEdit: false,
  /** QUALIFICATIONS */
  qualifs: null,
  qualificationsEdit: false,
  isSavingQualifications: false,
  /** FUEL */
  fuels: null,
  isLoadingFuel: false,
  fuelEdit: false,
  /** CUSTOMERS CATALOG */
  customersCatalog: null,
  isLoadingCustomersCatalog: false,
};

/* Get carrier list */
export const fetchCarriers = createAsyncThunk(
  "adminCarriers/fetchCarriers",
  async (payload) => {
    const { page, filters } = payload;
    const response = await getCarriers(page, filters);
    return response;
  }
);
/* Get a carrier */
export const fetchCarrier = createAsyncThunk(
  "adminCarriers/fetchCarrier",
  async (payload, { dispatch }) => {
    const { carrierId } = payload;
    const response = await getCarrier(carrierId);
    dispatch(fetchCarrierRates({ carrierId }));
    dispatch(fetchCarrierZonesCatalog({ carrierId }));
    return response;
  }
);

/* Get carrier users */
export const fetchCarrierUsers = createAsyncThunk(
	"adminCarriers/fetchCarrierUsers",
	async (payload) => {
		const { carrierId, page, filters } = payload;
		const response = await getCarrierUsers(carrierId, page, filters);
		return response;
	}
);

/* Get carrier rates */
export const fetchCarrierRates = createAsyncThunk(
  "adminCarriers/fetchCarrierRates",
  async (payload) => {
    const { carrierId, page, filters } = payload;
    const response = await getCarrierRates(carrierId, page, filters);
    return response;
  }
);

/* Add a carrier rate */
export const fetchAddCarrierRate = createAsyncThunk(
  "adminCarriers/fetchAddCarrierRate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await addCarrierRate(carrierId, data);
      dispatch(fetchCarrierRates({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save a carrier rate */
export const fetchSaveCarrierRate = createAsyncThunk(
  "adminCarriers/fetchSaveCarrierRate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, rateId, data } = payload;
      const response = await saveCarrierRate(carrierId, rateId, data);
      dispatch(fetchCarrierRates({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Get carrier zones */
export const fetchCarrierZones = createAsyncThunk(
  "adminCarriers/fetchCarrierZones",
  async (payload) => {
    const { carrierId, filters } = payload;
    return await getCarrierZones(carrierId, filters);
  }
);

/* Get carrier zones catalog */
export const fetchCarrierZonesCatalog = createAsyncThunk(
  "adminCarriers/fetchCarrierZonesCatalog",
  async (payload) => {
    const { carrierId } = payload;
    const response = await getCarrierZoneCatalog(carrierId);
    return response;
  }
);

/* Get a carrier zone */
export const fetchCarrierZone = createAsyncThunk(
	"adminCarriers/fetchCarrierZone",
	async (payload) => {
		const { carrierId, zoneId } = payload;
		const response = await getCarrierZone(carrierId, zoneId);
		return response;
	}
);

/* Get a carrier zone exclusions */
export const fetchCarrierZoneExclusions = createAsyncThunk(
	"adminCarriers/fetchCarrierZoneExclusions",
	async (payload) => {
		const { carrierId } = payload;
		const response = await getCarrierZoneExclusions(carrierId);
		return response;
	}
);

/* Get carrier lanes */
export const fetchCarrierLanes = createAsyncThunk(
  "adminCarriers/fetchCarrierLanes",
  async (payload) => {
    const { carrierId, page } = payload;
    const response = await getCarrierLanes(carrierId, page);
    return response;
  }
);

/* Get carrier lanes catalog */
export const fetchAllCarrierLanes = createAsyncThunk(
  "adminCarriers/fetchAllCarrierLanes",
  async (payload) => {
    const { carrierId } = payload;
    const response = await getCarrierLanesCatalog(carrierId);
    return response;
  }
);

/* Add carrier */
export const fetchAddCarrier = createAsyncThunk(
  "adminCarriers/fetchAddCarrier",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await addCarrier(payload);
      dispatch(fetchCarriers({ page: 1 }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Update a carrier */
export const fetchSaveCarrier = createAsyncThunk(
  "adminCarriers/fetchSaveCarrier",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await saveCarrier(carrierId, data);
      dispatch(fetchCarrier({ carrierId }));
      dispatch(fetchCarriers({ page: 1 }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Update a carrier status */
export const fetchCarrierStatusChange = createAsyncThunk(
	"adminCarriers/fetchCarrierStatusChange",
	async (payload, { dispatch }) => {
			const { carrierId, data } = payload;
			const response = await changeCarrierStatus(carrierId, data);
			dispatch(fetchCarrier({ carrierId }));
			return response;
	}
);

/* Add carrier zone */
export const fetchAddCarrierZone = createAsyncThunk(
  "adminCarriers/fetchAddCarrierZone",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await addCarrierZone(carrierId, data);
      dispatch(fetchCarrierZones({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier zone */
export const fetchSaveCarrierZone = createAsyncThunk(
  "adminCarriers/fetchSaveCarrierZone",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, zoneId, data } = payload;
      const response = await saveCarrierZone(carrierId, zoneId, data);
      dispatch(fetchCarrierZones({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier zone exclusions */
export const fetchSaveCarrierZoneExclusions = createAsyncThunk(
  "adminCarriers/fetchSaveCarrierZoneExclusions",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await saveCarrierZoneExclusions(carrierId, data);
      dispatch(fetchCarrierZones({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Add multiple carrier zones */
export const fetchAddMultipleCarrierZones = createAsyncThunk(
  "adminCarriers/fetchAddMultipleCarrierZones",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await addMultipleCarrierZones(carrierId, data);
      dispatch(fetchCarrierZones({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier zone */
export const fetchSetCarrierAccesorials = createAsyncThunk(
  "adminCarriers/fetchSetCarrierAccesorials",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await setCarrierAccesorials(carrierId, data);
      dispatch(fetchCarrier({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Add carrier zone */
export const fetchAddCarrierLane = createAsyncThunk(
  "adminCarriers/fetchAddCarrierLane",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await addCarrierLane(carrierId, data);
      dispatch(fetchCarrierLanes({ carrierId }));
      dispatch(fetchAllCarrierLanes({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier zone */
export const fetchSaveCarrierLane = createAsyncThunk(
  "adminCarriers/fetchSaveCarrierLane",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, laneId, data } = payload;
      const response = await saveCarrierLane(carrierId, laneId, data);
      dispatch(fetchCarrierLanes({ carrierId }));
      dispatch(fetchAllCarrierLanes({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier qualifications */
export const fetchSetCarrierQualifications = createAsyncThunk(
  "adminCarriers/fetchSetCarrierQualifications",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await setCarrierQualifications(carrierId, data);
      dispatch(fetchCarrier({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Fetch carrier fuel surcharge */
export const fetchCarrierFuel = createAsyncThunk(
  "adminCarriers/fetchCarrierFuel",
  async (payload) => {
    const { carrierId } = payload;
    const response = await getCarrierFuel(carrierId);
    return response;
  }
);

/* Save carrier qualifications */
export const fetchSetCarrierFuel = createAsyncThunk(
  "adminCarriers/fetchSetCarrierFuel",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, fuelId, data } = payload;
      const response = await saveCarrierFuel(carrierId, fuelId, data);
      dispatch(fetchCarrierFuel({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Add carrier fuel surcharge */
export const fetchAddCarrierFuel = createAsyncThunk(
  "adminCarriers/fetchAddCarrierFuel",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { carrierId, data } = payload;
      const response = await addCarrierFuel(carrierId, data);
      dispatch(fetchCarrierFuel({ carrierId }));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


/* Get carrier customers catalog */
export const fetchGetCarrierCustomersCatalog = createAsyncThunk(
  "adminCarriers/fetchGetCarrierCustomersCatalog",
  async (payload, { rejectWithValue, dispatch }) => {
    
    try {
      const { carrierId } = payload;
      return await getCarrierCustomersCatalog(carrierId);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const carriers = createSlice({
  name: "carriers",
  initialState,
  reducers: {
    setCarriersList: (state, { payload }) => {
      state.list = payload?.list ?? null;
    },
    setOpenCarrier: (state, { payload }) => {
      state.open = payload?.open ?? null;
      state.fuels = null;
    },
    setCarrierEdit: (state, { payload }) => {
      state.edit = payload?.edit ?? false;
    },
    setCarrierTab: (state, { payload }) => {
      state.tab = payload?.tab ?? null;
    },
		setCarrierUser: (state, { payload }) => {
			state.user = payload?.user ?? null;
		},
		setCarrierUserAddEdit: (state, { payload }) => {
			state.userAdd = payload?.userAdd ?? false;
			state.userEdit = payload?.userEdit ?? false;
		},
    setCarrierRates: (state, { payload }) => {
      state.rates = payload?.rates ?? null;
    },
    setCarrierRate: (state, { payload }) => {
      state.rate = payload?.rate ?? null;
    },
    setCarrierRateAddEdit: (state, { payload }) => {
      state.rateAdd = payload?.rateAdd ?? false;
      state.rateEdit = payload?.rateEdit ?? false;
    },
    setCarrierZones: (state, { payload }) => {
      state.zones = payload?.zones ?? null;
    },
    setCarrierZone: (state, { payload }) => {
      state.zone = payload?.zone ?? null;
    },
    setCarrierZoneAddEdit: (state, { payload }) => {
      state.zoneAdd = payload?.zoneAdd ?? false;
      state.zoneEdit = payload?.zoneEdit ?? false;
    },
    setCarrierZoneExclusionEdit: (state, { payload }) => {
      state.zoneExclusionEdit = payload;
    },
    setCarrierLanes: (state, { payload }) => {
      state.lanes = payload.lanes ?? null;
    },
    setCarrierLane: (state, { payload }) => {
      state.lane = payload?.lane ?? null;
    },
    setCarrierLaneAddEdit: (state, { payload }) => {
      state.laneAdd = payload?.laneAdd ?? false;
      state.laneEdit = payload?.laneEdit ?? false;
    },
    setCarriersShowDetails: (state, { payload }) => {
      state.showDetails = payload ?? false;
    },
    setCarriersShowAdd: (state, { payload }) => {
      state.showAdd = payload ?? false;
    },
    setCarrierAccesorialsEdit: (state, { payload }) => {
      state.accessorialsEdit = payload;
    },
    setCarrierQualificationsEdit: (state, { payload }) => {
      state.qualificationsEdit = payload;
    },
    setCarrierFuelEdit: (state, { payload }) => {
      state.fuelEdit = payload;
    },
    clearCarrierEdit: (state) => {
      // state.showAdd = false;
      state.edit = false;
			state.userEdit = false;
			state.userAdd = false;
			state.user = null;
      state.zoneEdit = false;
      state.zoneAdd = false;
      state.rateEdit = false;
      state.rateAdd = false;
      state.laneEdit = false;
      state.laneAdd = false;
      state.accessorialsEdit = false;
      state.qualificationsEdit = false;
      state.fuelEdit = false;
    },
    clearZoneErrors: (state) => {
      state.saveCarrierZoneErrors = null;
      state.saveCarrierMultipleZoneErrors = null;
      state.saveCarzoneExclusionsErrors = null;
    },
    clearCarriersData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /* Fetch carriers */
      .addCase(fetchCarriers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCarriers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.data.data;
      })
      .addCase(fetchCarriers.rejected, (state) => {
        state.isLoading = false;
      })
      /* Ferch carrier */
      .addCase(fetchCarrier.pending, (state) => {
        state.isOpening = true;
      })
      .addCase(fetchCarrier.fulfilled, (state, { payload }) => {
        state.isOpening = false;
        state.open = payload.data.data;
        state.fuels = null;
        state.allLanes = null;
      })
      .addCase(fetchCarrier.rejected, (state) => {
        state.isOpening = false;
        state.open = null;
        state.fuels = null;
        state.allLanes = null;
      })
			/* Fetch carrier users */
			.addCase(fetchCarrierUsers.pending, (state) => {
				state.isLoadingUsers = true;
			})
			.addCase(fetchCarrierUsers.fulfilled, (state, { payload }) => {
				state.isLoadingUsers = false;
				state.users = payload.data.result;
			})
			.addCase(fetchCarrierUsers.rejected, (state) => {
				state.isLoadingUsers = false;
				state.users = null;
			})
      /* Fetch carrier rates */
      .addCase(fetchCarrierRates.pending, (state) => {
        state.isLoadingRates = true;
      })
      .addCase(fetchCarrierRates.fulfilled, (state, { payload }) => {
        state.isLoadingRates = false;
        state.rates = payload.data.data;
      })
      .addCase(fetchCarrierRates.rejected, (state) => {
        state.isLoadingRates = false;
        state.rates = [];
      })
      .addCase(fetchCarrierZones.pending, (state) => {
        state.isLoadingZones = true;
      })
      .addCase(fetchCarrierZones.fulfilled, (state, { payload }) => {
        state.isLoadingZones = false;
        state.zones = payload.data.data;
      })
      .addCase(fetchCarrierZones.rejected, (state) => {
        state.isLoadingZones = false;
        state.zones = [];
      })
			/* Get a carrier zone */
			.addCase(fetchCarrierZone.pending, (state) => {
				state.zone = null;
				state.isOpeningZone = true;
			})
			.addCase(fetchCarrierZone.fulfilled, (state, { payload }) => {
				state.isOpeningZone = false;
				state.zone = payload.data.data;
			})
			.addCase(fetchCarrierZone.rejected, (state) => {
				state.isOpeningZone = false;
				state.zone = null;
			})
			/* Get carrier lanes */
      .addCase(fetchCarrierLanes.pending, (state) => {
        state.isLoadingLanes = true;
      })
      .addCase(fetchCarrierLanes.fulfilled, (state, { payload }) => {
        state.isLoadingLanes = false;
        state.lanes = payload.data.data;
      })
      .addCase(fetchCarrierLanes.rejected, (state) => {
        state.isLoadingLanes = false;
        state.lanes = [];
      })
      /** Get carrier lanes catalog */
      .addCase(fetchAllCarrierLanes.pending, (state) => {
        state.isLoadingAllLanes = true;
      })
      .addCase(fetchAllCarrierLanes.fulfilled, (state, { payload }) => {
        state.isLoadingAllLanes = false;
        state.allLanes = payload.data.data;
      })
      .addCase(fetchAllCarrierLanes.rejected, (state) => {
        state.isLoadingAllLanes = false;
        state.allLanes = [];
      })

      /** Fetch add carrier */
      .addCase(fetchAddCarrier.pending, (state) => {
        state.addCarrierErrors = null;
        state.isSaving = true;
      })
      .addCase(fetchAddCarrier.fulfilled, (state) => {
        state.isSaving = false;
        state.showAdd = false;
        state.showDetails = false;
        state.addFormState = null;
        // Reload carriers data.
        // state.carriers = null;
      })
      .addCase(fetchAddCarrier.rejected, (state, action) => {
        state.isSaving = false;
        // Display errors.
        if (action.payload?.data?.errors)
          state.addCarrierErrors = action.payload?.data?.errors;
      })
      /** Fetch save carrier */
      .addCase(fetchSaveCarrier.pending, (state) => {
        state.saveCarrierErrors = null;
        state.isSaving = true;
      })
      .addCase(fetchSaveCarrier.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.edit = false;
        state.showAdd = false;
        state.saveFormState = null;
      })
      .addCase(fetchSaveCarrier.rejected, (state, action) => {
        state.isSaving = false;
        // Display errors.
        if (action.payload?.data?.errors)
          state.saveCarrierErrors = action.payload?.data?.errors;
      })
      /** Fetch add carrier zone */
      .addCase(fetchAddCarrierZone.pending, (state) => {
        state.saveCarrierZoneErrors = null;
        state.isSavingZone = true;
      })
      .addCase(fetchAddCarrierZone.fulfilled, (state, { payload }) => {
        state.isSavingZone = false;
        state.zoneAdd = false;
        // state.zone = null;
      })
      .addCase(fetchAddCarrierZone.rejected, (state, action) => {
        state.isSavingZone = false;
        // Display errors.
        if (action.payload?.data?.message)
          state.saveCarrierZoneErrors = action.payload?.data?.message;
      })
      /** Fetch save carrier zone */
      .addCase(fetchSaveCarrierZone.pending, (state) => {
        state.saveCarrierZoneErrors = null;
        state.isSavingZone = true;
      })
      .addCase(fetchSaveCarrierZone.fulfilled, (state, { payload }) => {
        state.isSavingZone = false;
        state.zoneEdit = false;
        state.zone = null;
      })
      .addCase(fetchSaveCarrierZone.rejected, (state, action) => {
        state.isSavingZone = false;
        // Display errors.
        if (action.payload?.data?.message)
          state.saveCarrierZoneErrors = action.payload?.data?.message;
      })
			/* Get zone exclusions */
			.addCase(fetchCarrierZoneExclusions.pending, (state) => {
				state.zone = null;
				state.isOpeningZone = true;
			})
			.addCase(fetchCarrierZoneExclusions.fulfilled, (state, { payload }) => {
				state.isOpeningZone = false;
				state.zone = payload.data.data;
			})
			.addCase(fetchCarrierZoneExclusions.rejected, (state) => {
				state.isOpeningZone = false;
				state.zone = null;
			})
      /* Save zone exclusions */
      .addCase(fetchSaveCarrierZoneExclusions.pending, (state) => {
        state.saveCarrierZoneExclusionsErrors = null;
        state.isSavingZoneExclusions = true;
      })
      .addCase(
        fetchSaveCarrierZoneExclusions.fulfilled,
        (state, { payload }) => {
          state.isSavingZoneExclusions = false;
          state.zoneExclusionEdit = false;
          state.zone = null;
        }
      )
      .addCase(fetchSaveCarrierZoneExclusions.rejected, (state, action) => {
        state.isSavingZoneExclusions = false;
        // Display errors.
        if (action.payload?.data?.message)
          state.saveCarrierZoneExclusionsErrors = action.payload?.data?.message;
      })
      /** Fetch add multiple carrier zone */
      .addCase(fetchAddMultipleCarrierZones.pending, (state) => {
        state.saveCarrierMultipleZoneErrors = null;
        state.isSavingZone = true;
      })
      .addCase(fetchAddMultipleCarrierZones.fulfilled, (state, { payload }) => {
        state.isSavingZone = false;
        state.zoneAdd = false;
        // state.zone = null;
      })
      .addCase(fetchAddMultipleCarrierZones.rejected, (state, action) => {
        state.isSavingZone = false;
        if (action.payload?.data?.errors)
          state.saveCarrierMultipleZoneErrors = action.payload?.data?.errors;
      })
      /** fetchSetCarrierAccesorials */
      .addCase(fetchSetCarrierAccesorials.pending, (state) => {
        state.saveCarrierAccesorialsErrors = null;
        state.isSavingAccesorials = true;
      })
      .addCase(fetchSetCarrierAccesorials.fulfilled, (state, { payload }) => {
        state.isSavingAccesorials = false;
        state.accessorialsEdit = false;
      })
      .addCase(fetchSetCarrierAccesorials.rejected, (state, action) => {
        state.isSavingAccesorials = false;
        // Display errors.
        if (action.payload?.data?.errors)
          state.saveCarrierAccesorialsErrors = action.payload?.data?.errors;
      })
      /** Fetch save carrier lane */
      .addCase(fetchAddCarrierLane.pending, (state) => {
        state.saveCarrierLaneErrors = null;
        state.isSavingLane = true;
      })
      .addCase(fetchAddCarrierLane.fulfilled, (state, { payload }) => {
        state.isSavingLane = false;
        state.laneAdd = false;
        state.lane = null;
      })
      .addCase(fetchAddCarrierLane.rejected, (state, { payload }) => {
        state.isSavingLane = false;
        // Display errors.
        state.saveCarrierLaneErrors = payload?.data ?? null;
      })
      .addCase(fetchSaveCarrierLane.pending, (state) => {
        state.saveCarrierLaneErrors = null;
        state.isSavingLane = true;
      })
      .addCase(fetchSaveCarrierLane.fulfilled, (state, { payload }) => {
        state.isSavingLane = false;
        state.laneEdit = false;
        state.lane = null;
      })
      .addCase(fetchSaveCarrierLane.rejected, (state, { payload }) => {
        state.isSavingLane = false;
        // Display errors.
        state.saveCarrierLaneErrors = payload?.data ?? null;
      })
      /// fetchSetCarrierAccesorials
      .addCase(fetchSetCarrierQualifications.pending, (state) => {
        state.isSavingQualifications = true;
      })
      .addCase(fetchSetCarrierQualifications.fulfilled, (state) => {
        state.isSavingQualifications = false;
        state.qualificationsEdit = false;
      })
      .addCase(fetchSetCarrierQualifications.rejected, (state, action) => {
        state.isSavingQualifications = false;
      })
      .addCase(fetchCarrierFuel.pending, (state) => {
        state.isLoadingFuel = true;
      })
      .addCase(fetchCarrierFuel.fulfilled, (state, { payload }) => {
        state.isLoadingFuel = false;
        state.fuels = payload.data.data;
      })
      .addCase(fetchCarrierFuel.rejected, (state) => {
        state.isLoadingFuel = false;
        state.fuels = null;
        state.allLanes = null;
      })
      .addCase(fetchSetCarrierFuel.pending, (state) => {
        state.isLoadingFuel = true;
      })
      .addCase(fetchSetCarrierFuel.fulfilled, (state) => {
        state.isLoadingFuel = false;
        state.fuelEdit = false;
        state.fuels = null;
        state.allLanes = null;
      })
      .addCase(fetchSetCarrierFuel.rejected, (state, action) => {
        state.isLoadingFuel = false;
      })
      // Add carrier fuel surcharge
      .addCase(fetchAddCarrierFuel.pending, (state) => {
        state.isLoadingFuel = true;
      })
      .addCase(fetchAddCarrierFuel.fulfilled, (state) => {
        state.isLoadingFuel = false;
        state.fuelEdit = false;
        state.fuels = null;
      })
      .addCase(fetchAddCarrierFuel.rejected, (state, action) => {
        state.isLoadingFuel = false;
      })
      // Add Carrier Rates
      .addCase(fetchAddCarrierRate.pending, (state) => {
        state.isSavingRate = true;
      })
      .addCase(fetchAddCarrierRate.fulfilled, (state, { payload }) => {
        state.isSavingRate = false;
        state.rateAdd = false;
      })
      .addCase(fetchAddCarrierRate.rejected, (state, { payload }) => {
        state.isSavingRate = false;
        // Display errors.
        state.saveCarrierRateErrors = payload?.data ?? null;
      })
      // Save Carrier Rates
      .addCase(fetchSaveCarrierRate.pending, (state) => {
        state.saveCarrierCarrierRate = null;
        state.isSavingRate = true;
      })
      .addCase(fetchSaveCarrierRate.fulfilled, (state, { payload }) => {
        state.isSavingRate = false;
        state.rateEdit = false;
      })
      .addCase(fetchSaveCarrierRate.rejected, (state, { payload }) => {
        state.isSavingRate = false;
        state.saveCarrierRateErrors = payload?.data ?? null;
      })
      .addCase(fetchCarrierZonesCatalog.pending, (state) => {
        state.isLoadingZonesCatalog = true;
      })
      .addCase(fetchCarrierZonesCatalog.fulfilled, (state, { payload }) => {
        state.isLoadingZonesCatalog = false;
        state.zonesCatalog = payload.data.data;
      })
      .addCase(fetchCarrierZonesCatalog.rejected, (state) => {
        state.isLoadingZonesCatalog = false;
        state.zonesCatalog = [];
      })
      .addCase(fetchGetCarrierCustomersCatalog.pending, (state) => {
        state.isLoadingCustomersCatalog = true;
      })
      .addCase(fetchGetCarrierCustomersCatalog.fulfilled, (state, { payload }) => {
        state.isLoadingCustomersCatalog = false;
        state.customersCatalog = payload.data.data;
      })
      .addCase(fetchGetCarrierCustomersCatalog.rejected, (state) => {
        state.isLoadingCustomersCatalog = false;
        state.customersCatalog = [];
      });
  },
});

export const {
  setCarriersList,
  setOpenCarrier,
  setCarrierEdit,
  setCarrierTab,
	setCarrierUser,
	setCarrierUserAddEdit,
  setCarrierRate,
  setCarrierRates,
  setCarrierRateAddEdit,
  setCarrierZones,
  setCarrierZone,
  setCarrierZoneAddEdit,
  setCarrierZoneExclusion,
  setCarrierZoneExclusionEdit,
  setCarrierLanes,
  setCarriersShowDetails,
  setCarriersShowAdd,
  setCarrierAccesorialsEdit,
  setCarrierLane,
  setCarrierLaneAddEdit,
  setCarrierQualificationsEdit,
  setCarrierFuelEdit,
  clearCarrierEdit,
  clearCarriersData,
  clearZoneErrors,
} = carriers.actions;

export default carriers.reducer;
