import { formatDateToString, stringToDateWithFormat } from "utils/dateUtils";

import i18n from "i18next";

/**
 *
 * @param {*} data
 * @param {*} fields
 * @returns
 */
export const filterTransformToData = (data, fields) => {
	let result = {};
	const dataArray = Object.entries(data);
	dataArray.forEach((entry) => {
		let key = entry[0];
		let value = entry[1];

		const fieldItem = fields.find((item) => item.field === key);

		if (fieldItem != null) {
			switch (fieldItem.filter_type) {
				case "string":
					if ((value?.length ?? 0) > 0) {
						result = { ...result, [fieldItem.field]: value };
					}
					break;
				case "list":
					if ((value?.length ?? 0) > 0) {
						result = {
							...result,
							[fieldItem.field]: value?.map((item) => item.value),
						};
					}
					break;
				case "date":
					if (value?.start != null && value.start !== "") {
						const dateStart = stringToDateWithFormat(
							value.start,
							i18n.t("Dates.FieldDate")
						);

						result = {
							...result,
							[`${fieldItem.field}_start`]: formatDateToString(dateStart),
						};
					}

					if (value?.end != null && value.end !== "") {
						const dateEnd = stringToDateWithFormat(
							value.end,
							i18n.t("Dates.FieldDate")
						);
						result = {
							...result,
							[`${fieldItem.field}_end`]: formatDateToString(dateEnd),
						};
					}
					break;
				case "range":
					if (value?.min != null && value.min !== "") {
						result = { ...result, [`${fieldItem.field}_start`]: value.min };
					}
					if (value?.max != null && value.max !== "") {
						result = { ...result, [`${fieldItem.field}_end`]: value.max };
					}
					break;
				default:
			}
		}
		return result;
	});
	return result;
};
