import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

export default function RangeFilter({ fieldItem, register, errors }) {
  const { t } = useTranslation();

  // Render
  return (
    <>
      <div
        className={classNames("b-11 field", {
          error: errors[`fieldItem.field`]?.min,
        })}
      >
        <label>{t("Common.Min")}</label>
        <input
          type="text"
          placeholder={t("Common.Min")}
          {...register(`${fieldItem.field}.min`, {
            required: true,
          })}
        />
      </div>
      <div
        className={classNames("b-12 field", {
          error: errors[`fieldItem.field`]?.max,
        })}
      >
        <label>{t("Common.Min")}</label>
        <input
          type="text"
          placeholder={t("Common.Max")}
          {...register(`${fieldItem.field}.max`, {
            required: true,
          })}
        />
      </div>
    </>
  );
}
