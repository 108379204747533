import client from "./networking";

/**
 * Submit a new template for parsing
 * @param {number} carrier_id 
 * @param {file} file 
 * @param {number} onUploadProgress 
 * @returns 
 */
export const parseTemplateUpload = (
	carrier_id,
	file,
	onUploadProgress
) => {
	var formData = new FormData();
	formData.append("carrier_id", carrier_id);
	formData.append("file", file);
	return client.post(`/api/rates-templates/parse`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress,
	});
};

/**
 * Submit a new template for insert
 * @param {number} carrier_id 
 * @param {file} file 
 * @param {number} onUploadProgress 
 * @returns 
 */
export const insertTemplateUpload = (
	carrier_id,
	file,
	public_id,
	onUploadProgress
) => {
	var formData = new FormData();
	formData.append("carrier_id", carrier_id);
	formData.append("file", file);
	formData.append("public_id", public_id);
	return client.post(`/api/rates-templates/insert`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress,
	});
};

/**
 * 
 * @param {string} public_id 
 * @returns 
 */
export const getTemplateProcessStatus = (public_id) => {
	return client.get(`/api/webjob-log/${public_id}`);
};
