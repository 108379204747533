export function findDuplicates(objectList) {
  var keys = {};
  var duplicates = [];
  var indices = [];

  if (objectList != null && objectList?.length > 0) {
    objectList.forEach((object, index) => {
      var key = JSON.stringify(object);
      if (!keys[key]) {
        keys[key] = true;
      } else {
        duplicates.push(object);
        indices.push(index);
      }
    });
  }

  return { duplicates: duplicates, indices: indices };
}
