import { DIMENSION_UNIT, VOLUME_UNIT } from "features/constants";
import { convertCmToFt, convertCmToM, convertInToFt, convertInToM } from "./dimensionUtils";
import { numberFormat } from "./numberUtils";

/**
 * Converts from cubic meters to cubic feet
 * @param {*} value number
 * @returns number
 */
export const convertCbmToCbf = (value, precision) => {
	if (precision == null) return value * 35.314666721489;
	return Number((value * 35.314666721489).toFixed(precision));
};

/**
 * Format volume string
 * @param {Number} weight 
 * @param {int} unit 
 * @returns JSX
 */
export const formatVolume = (value, unit) => {
	if (value == null) return <>&nbsp;</>;
	if (unit === VOLUME_UNIT.CBF) {
		return `${numberFormat(convertCbmToCbf(Number(value)), 1)} ${
			VOLUME_UNIT.properties[unit].code
		}`;
	}
	return `${numberFormat(Number(value), 1)} ${VOLUME_UNIT.properties[unit].code}`;
};


/**
 * Calculate volume from dimensions
 * @param {boolean} metric 
 * @param {number} length 
 * @param {number} width 
 * @param {number} height 
 * @param {int} unit 
 * @param {int} pieces 
 * @returns 
 */
export const calcVolume = (metric, length, width, height, unit, pieces) => {
	if (unit === DIMENSION_UNIT.IN) {
		if (metric) {
			return convertInToM(length) * convertInToM(width) * convertInToM(height) * pieces;
		} else {
			return convertInToFt(length) * convertInToFt(width) * convertInToFt(height) * pieces;
		}
	} else {
		if (metric) {
			return convertCmToM(length) * convertCmToM(width) * convertCmToM(height) * pieces;
		} else {
			return convertCmToFt(length) * convertCmToFt(width) * convertCmToFt(height) * pieces;
		}
	}
};
