import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import {
	ACCESSORIALS,
	ADDRESS_TYPE,
	ATTACHABLE_TYPE,
	BOOKING_METHOD,
	CURRENCY,
	LOCATION_TYPES,
	SHIPMENT_TYPE,
} from "features/constants";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import {
	fetchBookDirect,
	fetchBookDsvRoad,
	fetchBookSpot,
	fetchOperatorOrderUploadDoc,
	setOperatorOrderShowUpload,
} from "features/operator/operatorOrdersSlice";
import { isOrderReadyToBook } from "utils/orderUtils";
import {
	getCurrencyOptionByCountryCode,
	listToSelector,
} from "utils/constantsUtils";
import { numberFormat } from "utils/numberUtils";
import OperatorOrderDetailsConsolidationList from "./OperatorOrderDetailsConsolidationList";

import "react-tooltip/dist/react-tooltip.css";
import { toSelector } from "utils/constantsUtils";
import OrderDocs from "components/common/order/OrderDocs";
import OrderDocsForm from "components/common/order/OrderDocsForm";

export default function OperatorOrderDetailsBooking({
	onClose,
	onCollapse,
	collapse,
	onExpand,
	onConsolidate,
}) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const {
		openOrder,
		isLoadingOrder,
		isLoadingCart,
		orderCart,
		selfCarriers,
		consolidate,
		showUpload,
		isUploading,
		docs,
	} = useSelector((state) => state.operatorOrders);
	const { user } = useSelector((state) => state.session);
	// State
	const [selectedRates, setSelectedRates] = useState([]);
	const [bookOption, setBookOption] = useState(null);
	const [spotPrice, setSpotPrice] = useState("");
	const [spotExpires, setSpotExpires] = useState("");
	const [spotCurrency, setSpotCurrency] = useState(
		getCurrencyOptionByCountryCode(user?.user?.company?.country_code)
	);
	const [extraCarriers, setExtraCarriers] = useState([]);
	const [selectedCarriers, setSelectedCarriers] = useState([]);
	const [selfCarrier, setSelfCarrier] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [selValue, setSelValue] = useState(null);
	// Refs
	// Effect
	useEffect(() => {
		if (orderCart != null) {
			setSelectedRates(
				orderCart?.content?.rates.map((el, index) => {
					if (index !== 0) return { checked: false, rate: el };
					return { checked: true, rate: el };
				})
			);
		}
	}, [orderCart]);
	// Handlers
	const handleCloseClick = () => {
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handleBookingChange = (e) => {
		setBookOption(e.target.value);
		if (Number(e.target.value) === BOOKING_METHOD.DIRECT) {
			setSpotPrice("");
			setSpotExpires("");
			setSpotCurrency(
				getCurrencyOptionByCountryCode(user?.user?.company?.country_code)
			);
			setSelfCarrier(null);
		}
		if (Number(e.target.value) === BOOKING_METHOD.SPOT) {
			setSelfCarrier(null);
		}
		if (Number(e.target.value) === BOOKING_METHOD.DSVROAD) {
			setSpotPrice("");
			setSpotExpires("");
			setSpotCurrency(
				getCurrencyOptionByCountryCode(user?.user?.company?.country_code)
			);
		}
	};
	const handleRateSelect = (e, index) => {
		let list = [...selectedRates];
		list[index].checked = e.target.checked;
		setSelectedRates(list);
	};
	const handleSpotPriceChange = (e) => {
		//const value = e.target.value.replace(/^(\d+)(\.\d{2})?$/, "$1");
		const value = e.target.value.split(/^.*?(-?\d+(?:\.\d+)?)|\D+/).join("");
		setSpotPrice(value);
	};
	const handleSpotExipiresChange = (e) => {
		const value = e.target.value.replace(/[^\d]/, "");
		setSpotExpires(value);
	};
	const handleSelectedSelfCarrierChange = (option) => {
		setSelfCarrier(option);
	};
	const handleSpotCurrencyChange = (option) => {
		setSpotCurrency(option);
	};
	const handleCarrierSelect = (e, id) => {
		let list = [...selectedCarriers];
		list[id].checked = e.target.checked;
		setSelectedCarriers(list);
	};
	const handleSelectExtraCarrier = (option) => {
		setExtraCarriers([...extraCarriers, option]);
		let list = [...selectedCarriers, { id: option.object.id, checked: true }];
		setSelectedCarriers(list);
	};
	const handleRemoveExtraCarrier = (option) => {
		const filtered = extraCarriers.filter((el) => el !== option);
		setExtraCarriers(filtered);
		const filteredCarriers = selectedCarriers.filter(
			(el) => el.id !== option.value
		);
		setSelectedCarriers(filteredCarriers);
	};
	const handleSubmit = () => {
		switch (Number(bookOption)) {
			case BOOKING_METHOD.DIRECT:
				const rate = selectedRates.filter((el) => el.checked === true)[0].rate;
				dispatch(
					fetchBookDirect({
						orderId: openOrder.id,
						id: orderCart.id,
						cartId: orderCart.public_id,
						rateId: rate.rate_id,
					})
				);
				break;
			case BOOKING_METHOD.SPOT:
				const rateCarriers = selectedRates
					.filter((el) => el.checked === true)
					.map((item) => item.rate.carrier.id);
				const extraCarrierIds = selectedCarriers
					.filter((el) => el.checked)
					.map((el) => el.id);
				const carrierIds = [...rateCarriers, ...extraCarrierIds];
				dispatch(
					fetchBookSpot({
						orderId: openOrder.id,
						id: orderCart.id,
						cartId: orderCart.public_id,
						price: spotPrice,
						expires: spotExpires,
						carrierIds,
						currency: spotCurrency.value,
					})
				);
				break;
			case BOOKING_METHOD.DSVROAD:
				dispatch(
					fetchBookDsvRoad({
						orderId: openOrder.id,
						id: orderCart.id,
						cartId: orderCart.public_id,
						carrierId: selfCarrier.value,
					})
				);
				break;
			default:
				break;
		}
	};
	const handleShowUpload = (show) => {
		dispatch(setOperatorOrderShowUpload({ showUpload: show }));
	};
	const handleUpload = (file, type) => {
		// Upload.
		dispatch(
			fetchOperatorOrderUploadDoc({
				attachableId: openOrder.id,
				type: type,
				file: file,
				attachableType: ATTACHABLE_TYPE.ORDER_REQUEST,
			})
		);
	};
	// Functions
	const createTooltipContent = (rate) => {
		const rateLane = (
			<>
				<span style={{ fontWeight: "bold" }}>{`${t("Fields.Lane")}: `}</span>
				{rate.carrier_lane.name}
			</>
		);
		const rateFreetime =
			rate.free_time != null ? (
				<>
					<span style={{ fontWeight: "bold" }}>{`${t(
						"Fields.FreeTime"
					)}: `}</span>
					{`${t("Common.hour", { count: rate.free_time })}`}
				</>
			) : null;
		const carrierContent = (
			<div style={{ maxWidth: "280px" }}>
				{rateLane}
				<br />
				{rateFreetime}
			</div>
		);
		return ReactDOMServer.renderToStaticMarkup(carrierContent);
	};
	const createTooltipTableContent = (rate) => {
		const cellStyles = {
			borderCollapse: "collapse",
			borderBottom: "solid 1px white",
		};
		const priceStyles = {
			borderCollapse: "collapse",
			borderBottom: "solid 1px white",
			textAlign: "right",
			paddingLeft: "10px",
		};
		const customerStyles = {
			whiteSpace: "normal",
			maxWidth: "180px",
			fontWeight: "bold",
		};
		const ratesTable = (
			<table cellPadding={0} cellSpacing={0}>
				<tbody>
					<tr key="customer">
						<td colSpan={2} style={customerStyles}>
							{rate?.customer?.name ?? t("Common.GenericRate")}
						</td>
					</tr>
					<tr key="linehaul">
						<td style={cellStyles}>{t("Common.Linehaul")}</td>
						<td style={priceStyles}>{`$${numberFormat(
							rate.price * rate.exchange_rate,
							2
						)}`}</td>
					</tr>
					<tr key="fuel">
						<td style={cellStyles}>{t("Common.Fuel")}</td>
						<td style={priceStyles}>{`$${numberFormat(
							rate.fuel_price * rate.exchange_rate,
							2
						)}`}</td>
					</tr>
					{rate.non_stackable_price > 0 && (
						<tr key="non-stackable">
							<td style={cellStyles}>{t("Common.NonStackable")}</td>
							<td style={priceStyles}>{`$${numberFormat(
								rate.non_stackable_price * rate.exchange_rate,
								2
							)}`}</td>
						</tr>
					)}
					{rate.hazmat_price > 0 && (
						<tr key="hazmat">
							<td style={cellStyles}>{t("SpecialHandlings.HazmatAbr")}</td>
							<td style={priceStyles}>{`$${numberFormat(
								rate.hazmat_price * rate.exchange_rate,
								2
							)}`}</td>
						</tr>
					)}
					{rate.tmpctl_price > 0 && (
						<tr key="tmpctl">
							<td style={cellStyles}>{t("SpecialHandlings.TempctlAbr")}</td>
							<td style={priceStyles}>{`$${numberFormat(
								rate.tmpctl_price * rate.exchange_rate,
								2
							)}`}</td>
						</tr>
					)}
					{rate.accessorials.map((el, index) => {
						return (
							<tr key={`${el.type}-${index}`}>
								<td style={cellStyles}>
									{el.type === "location_type"
										? t(LOCATION_TYPES.properties[el.id].name)
										: t(ACCESSORIALS.properties[el.id].name)}
								</td>
								<td style={priceStyles}>{`$${numberFormat(
									el.rate * rate.exchange_rate,
									2
								)}`}</td>
							</tr>
						);
					})}
					{rate.additional_charges_price > 0 && (
						<tr key="additional-charges-price">
							<td style={cellStyles}>{t("Common.AdditionalCharges")}</td>
							<td style={priceStyles}>{`$${numberFormat(
								rate.additional_charges_price * rate.exchange_rate,
								2
							)}`}</td>
						</tr>
					)}
					<tr key="total">
						<th>{t("Common.Total")}</th>
						<th style={{ textAlign: "right" }}>{`$${numberFormat(
							rate.total_amount * rate.exchange_rate,
							2
						)}`}</th>
					</tr>
				</tbody>
			</table>
		);
		return ReactDOMServer.renderToStaticMarkup(ratesTable);
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };
	const validateReady = isOrderReadyToBook(
		openOrder,
		Number(bookOption),
		selectedRates,
		spotPrice,
		spotCurrency,
		spotExpires,
		selectedCarriers,
		selfCarrier
	);
	const getSpotExtraOptions = () => {
		if (orderCart?.content?.discarded_carriers != null) {
			const { discarded_carriers } = orderCart.content;
			const filtered = discarded_carriers.filter(
				({ id }) => !extraCarriers.find((x) => x.value === id)
			);
			return filtered.map((el) => {
				return { value: el.id, label: el.name, object: el };
			});
		}
		return [];
	};
	const getAddress = (type) => {
		if (openOrder.addresses == null) return {};
		const filtered = openOrder.addresses.filter(
			(el) => Number(el.address_type) === type
		);
		const item = filtered.length > 0 ? filtered[0] : {};
		return item;
	};
	const getMethodProps = (method) => {
		if (selectedRates == null) return {};
		switch (method) {
			case BOOKING_METHOD.DIRECT:
			default:
				if (selectedRates.filter((el) => el.checked === true).length !== 1) {
					if (bookOption != null && Number(bookOption) === method) {
						setBookOption(null);
					}
					return { className: "disabled" };
				}
				return {};
			case BOOKING_METHOD.SPOT:
				if (
					selectedRates.filter((el) => el.checked === true).length === 0 &&
					extraCarriers.length === 0
				) {
					if (
						orderCart?.content?.discarded_carriers != null &&
						orderCart?.content?.discarded_carriers?.length !== 0
					) {
						return {};
					}
					if (bookOption != null && Number(bookOption) === method) {
						setBookOption(null);
					}
					return { className: "disabled" };
				}
				return {};
		}
	};
	const getBookProps = () => {
		if (!validateReady.isReady) return { className: "button disabled" };
		return { className: "button main", onClick: handleSubmit };
	};
	const renderLoader = () => {
		if (isLoadingCart || isLoadingOrder) {
			return <Loader />;
		}
	};
	const renderBook = () => {
		const { rates } = orderCart != null ? orderCart?.content : [];
		if (rates != null && rates.length === selectedRates.length) {
			return (
				<>
					<div className="order-book-block">
						<div className="order-subtitle">
							<div>{t("Common.Rates")}</div>
						</div>
						<div className="dsv-table booking-rates">
							<table cellPadding="0" cellSpacing="0">
								<thead>
									<tr>
										<th>&nbsp;</th>
										<th>{t("Common.Price")}</th>
										<th>{t("Common.Carrier")}</th>
										<th>{t("Fields.EquipmentType")}</th>
										<th>{t("Fields.TransitTime")}</th>
										<th>{t("Common.Qualifications")}</th>
									</tr>
								</thead>
								<tbody>
									{rates.map((el, index) => {
										const priceTooltipContent = createTooltipTableContent(el);
										const tooltipContent = createTooltipContent(el);
										return (
											<tr key={index}>
												<td>
													<input
														type="checkbox"
														id={"price-" + index}
														value={el.rate_id}
														checked={selectedRates[index].checked}
														onChange={(e) => handleRateSelect(e, index)}
													/>
												</td>
												<td>
													<div
														className="rates-tooltip"
														data-tooltip-html={priceTooltipContent}
														data-tooltip-place={"bottom-start"}
													>{`$${numberFormat(el.price * el.exchange_rate, 2)} ${
														CURRENCY.properties[el.currency].code
													}`}</div>
												</td>
												<td>
													<div
														className="rates-tooltip"
														data-tooltip-html={tooltipContent}
														data-tooltip-place={"bottom"}
													>
														{el.carrier.name}
													</div>
												</td>
												<td className="center">
													{el.equipment_type?.name ?? "n/a"}
												</td>
												<td className="center">
													{el.carrier_lane?.transit_time != null
														? t("Common.day", {
																count: el.carrier_lane.transit_time,
														  })
														: "n/a"}
												</td>
												<td>
													{el.carrier.qualifications.map((q, idx) => {
														return (
															// eslint-disable-next-line jsx-a11y/anchor-is-valid
															<a
																key={idx}
																className="rates-tooltip"
																data-tooltip-content={t(
																	`Qualifications.${q.name}`
																)}
																data-tooltip-place={"bottom"}
															>
																<span className={q.icon}></span>
															</a>
														);
													})}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						{renderSpotExtraCarriers()}
					</div>
					<Tooltip anchorSelect=".rates-tooltip" className="dsv-tooltip" />
				</>
			);
		}
	};
	const renderRateType = () => {
		if (!isLoadingOrder && !isLoadingCart && openOrder != null) {
			const origin = getAddress(ADDRESS_TYPE.ORIGIN);
			const destination = getAddress(ADDRESS_TYPE.DESTINATION);
			return (
				<div className="order-block">
					<div className="order-subtitle">
						<div>{t("RateTypes.Title")}</div>
					</div>
					<table className="rate-info-table" cellPadding="0" cellSpacing="0">
						<thead>
							<tr>
								<th>&nbsp;</th>
								<th>{t("Common.Origin")}</th>
								<th>{t("Common.Destination")}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>{t("Common.RatingPort")}</th>
								<td>
									{openOrder.transport_shipment_type === SHIPMENT_TYPE.DE ? (
										openOrder.origin_rating_port_code ?? "n/a"
									) : (
										<br />
									)}
								</td>
								<td>
									{openOrder.transport_shipment_type === SHIPMENT_TYPE.PU ? (
										openOrder.destination_rating_port_code ?? "n/a"
									) : (
										<br />
									)}
								</td>
							</tr>
							<tr>
								<th>{t("Common.Point")}</th>
								<td>{origin.postal_code ?? <>&nbsp;</>}</td>
								<td>{destination.postal_code ?? <>&nbsp;</>}</td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		}
	};
	const renderBookErrors = () => {
		const { isReady, errors } = validateReady;
		if (!isReady && errors.length > 0) {
			return (
				<div className="book-errors error-list">
					<ul>
						{errors.map((err, index) => {
							return <li key={index}>{err}</li>;
						})}
					</ul>
				</div>
			);
		}
	};
	const renderSpotOptions = () => {
		const currencyOptions = toSelector(CURRENCY.properties, true, false);
		if (Number(bookOption) === BOOKING_METHOD.SPOT) {
			return (
				<div className="dsv-form no-bg">
					<div className="b-1 field">
						<label>{t("Common.Price")}</label>
						<input
							className="right"
							type="text"
							id="spot-price"
							placeholder="$"
							value={spotPrice}
							autoComplete="off"
							onChange={handleSpotPriceChange}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Fields.Currency")}</label>
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							value={spotCurrency}
							isClearable={false}
							options={currencyOptions}
							onChange={handleSpotCurrencyChange}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Fields.ExpiresIn")}</label>
						<input
							className="price"
							type="text"
							id="spot-price-expires"
							placeholder={t("Common.Hours")}
							value={spotExpires}
							autoComplete="off"
							onChange={handleSpotExipiresChange}
						/>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderSpotExtraCarriers = () => {
		if (Number(bookOption) === BOOKING_METHOD.SPOT) {
			const carrierOptions = getSpotExtraOptions();
			return (
				<>
					<div className="dsv-table booking-rates">
						<table cellPadding={0} cellSpacing={0}>
							<tbody>
								{extraCarriers?.map((el, index) => {
									const foundCarrier = selectedCarriers.filter(
										(f) => el.value === f.id
									);
									return (
										<tr key={index}>
											<td>
												<input
													type="checkbox"
													id={"carrier-" + el.object.id}
													value={el.object.id}
													checked={foundCarrier[0]?.checked ?? false}
													onChange={(e) => handleCarrierSelect(e, index)}
												/>
											</td>
											<td>{el.label}</td>
											<td>
												{el.object.qualifications.map((q, idx) => {
													return (
														// eslint-disable-next-line jsx-a11y/anchor-is-valid
														<a
															key={idx}
															className="rates-tooltip"
															data-tooltip-content={t(
																`Qualifications.${q.name}`
															)}
														>
															<span className={q.icon}></span>
														</a>
													);
												})}
											</td>
											<td>
												<span
													className="icon-remove"
													onClick={() => handleRemoveExtraCarrier(el)}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div>
						<Select
							className="dsv-select"
							value={selValue}
							classNamePrefix="dsv-select"
							options={carrierOptions}
							placeholder={t("Placeholders.AddCarrier")}
							isClearable={false}
							onChange={handleSelectExtraCarrier}
						/>
					</div>
				</>
			);
		}
	};
	const renderPriceCheckOptions = () => {
		if (Number(bookOption) === BOOKING_METHOD.DSVROAD) {
			return (
				<div className="dsv-form no-bg">
					<div className="a field">
						<label>{t("Common.Carrier")}</label>
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							value={selfCarrier}
							isClearable={false}
							options={listToSelector(selfCarriers)}
							onChange={handleSelectedSelfCarrierChange}
						/>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderDocuments = () => {
		return (
			<div className="order-block remove-top-space">
				<div className="order-subtitle">
					<div>{t("Common.Documents")}</div>
					<div>
						<span
							className="icon-add"
							onClick={() => handleShowUpload(true)}
						></span>
					</div>
				</div>
				{showUpload && (
					<OrderDocsForm
						onCancel={() => handleShowUpload(false)}
						onUpload={handleUpload}
						isLoading={isUploading}
					/>
				)}
				{docs != null ? (
					<OrderDocs
						docs={docs}
						bookedOrderId={null}
						orderRequestId={openOrder.id}
					/>
				) : (
					<></>
				)}
			</div>
		);
	};
	const renderActions = () => {
		if (!isLoadingCart && selectedRates != null) {
			return (
				<>
					<div className="order-actions-title">
						<div>
							<div>
								<span {...collapseProps}></span>
							</div>
							<div className="action-title">{t("OperatorOrders.Booking")}</div>
							<div>
								<span className="icon-close" onClick={handleCloseClick}></span>
							</div>
						</div>
					</div>
					{renderBook()}
					{renderRateType()}
					<div className="order-block remove-top-space">
						<div className="book-options">
							<div {...getMethodProps(BOOKING_METHOD.DIRECT)}>
								<div className="book-option">
									<div>
										<input
											type="radio"
											id="booking-method-direct"
											name="booking-method"
											value={BOOKING_METHOD.DIRECT}
											onChange={handleBookingChange}
											checked={Number(bookOption) === BOOKING_METHOD.DIRECT}
											disabled={
												selectedRates.filter((i) => i.checked === true)
													.length !== 1
											}
										/>
									</div>
									<div>
										<label htmlFor="booking-method-direct">
											{t(BOOKING_METHOD.properties[BOOKING_METHOD.DIRECT].name)}
										</label>
									</div>
								</div>
							</div>
							<div {...getMethodProps(BOOKING_METHOD.SPOT)}>
								<div className="book-option">
									<div>
										<input
											type="radio"
											id="booking-method-spot"
											name="booking-method"
											value={BOOKING_METHOD.SPOT}
											onChange={handleBookingChange}
											checked={Number(bookOption) === BOOKING_METHOD.SPOT}
											disabled={
												selectedRates.filter((i) => i.checked === true)
													.length === 0 &&
												extraCarriers.length === 0 &&
												orderCart?.content?.discarded_carriers?.length === 0
											}
										/>
									</div>
									<div>
										<label htmlFor="booking-method-spot">
											{t(BOOKING_METHOD.properties[BOOKING_METHOD.SPOT].name)}
										</label>
									</div>
								</div>
								{renderSpotOptions()}
							</div>
							<div>
								<div className="book-option">
									<div>
										<input
											type="radio"
											id="booking-method-dsvroad"
											name="booking-method"
											value={BOOKING_METHOD.DSVROAD}
											onChange={handleBookingChange}
											checked={Number(bookOption) === BOOKING_METHOD.DSVROAD}
										/>
									</div>
									<div>
										<label htmlFor="booking-method-dsvroad">
											{t(
												BOOKING_METHOD.properties[BOOKING_METHOD.DSVROAD].name
											)}
										</label>
									</div>
								</div>
								{renderPriceCheckOptions()}
							</div>
						</div>
						{renderBookErrors()}
						<div className="book-submit">
							<div {...getBookProps()}>{t("Buttons.BookNow")}</div>
						</div>
					</div>
					{renderDocuments()}
					{consolidate ? (
						<></>
					) : (
						<OperatorOrderDetailsConsolidationList
							onConsolidate={onConsolidate}
						/>
					)}
				</>
			);
		}
	};
	return (
		<>
			{renderLoader()}
			{renderActions()}
		</>
	);
}
