import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CARRIER_PROFILE_TAB } from "features/constants";
import {
  addCurrentCarrierLane,
  addCurrentCarrierRate,
  addCurrentCarrierZone,
  addMultipleCurrentCarrierZones,
  getCurrentCarrierCustomersCatalog,
  getCurrentCarrierLanes,
  getCurrentCarrierLanesCatalog,
  getCurrentCarrierRates,
  getCurrentCarrierZoneCatalog,
  getCurrentCarrierZones,
  saveCurrentCarrierLane,
  saveCurrentCarrierRate,
  saveCurrentCarrierZone,
  saveCurrentCarrierZoneExclusions,
} from "services/carrierServices";

const initialState = {
  tab: CARRIER_PROFILE_TAB.RATES,
  /* rates */
  isLoadingRates: false,
  isSavingRate: false,
  rates: null,
  rate: null,
  rateAdd: false,
  rateEdit: false,
  saveCarrierRateErrors: null,
  saveCarrierCarrierRate: null,
  /* lanes */
  isLoadingLanes: false,
  lanes: null,
  lane: null,
  laneAdd: false,
  laneEdit: false,
  isLoadingAllLanes: false,
  allLanes: null,
  saveCarrierLaneErrors: null,
  isSavingLane: false,
  /* zones */
  zones: null,
  zonesCatalog: null,
  zone: null,
  zoneAdd: false,
  zoneEdit: false,
  isSavingZone: false,
  saveCarrierZoneErrors: null,
  isSavingZoneExclusions: false,
  saveCarrierZoneExclusionsErrors: null,
  isLoadingZones: false,
  isLoadingZonesCatalog: false,
  zoneExclusionEdit: false,
  saveCarrierMultipleZoneErrors: null,
  /** CUSTOMERS CATALOG */
  customersCatalog: null,
  isLoadingCustomersCatalog: false,
};

/**
 * Async fetch functions
 */

/* Get carrier's order list */
export const fetchCurrentCarrierRates = createAsyncThunk(
  "carrierProfile/fetchCurrentCarrierRates",
  async (payload, { rejectWithValue }) => {
    const { filters, page } = payload;
    try {
      const response = await getCurrentCarrierRates(page, filters);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAddCurrentCarrierRate = createAsyncThunk(
  "carrierProfile/fetchAddCurrentCarrierRate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = payload;
      const response = await addCurrentCarrierRate(data);
      dispatch(fetchCurrentCarrierRates());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier rate */
export const fetchSaveCurrentCarrierRate = createAsyncThunk(
  "carrierProfile/fetchSaveCurrentCarrierRate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { rateId, data } = payload;
      const response = await saveCurrentCarrierRate(rateId, data);
      dispatch(fetchCurrentCarrierRates());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Fetch carrier zones */
export const fetchCurrentCarrierZones = createAsyncThunk(
  "carrierProfile/fetchCurrentCarrierZones",
  async (payload) => {
    const { filters } = payload;
    return await getCurrentCarrierZones(filters);
  }
);

/* Fetch carrier zones as catalog */
export const fetchCurrentCarrierZonesCatalog = createAsyncThunk(
  "carrierProfile/fetchCurrentCarrierZonesCatalog",
  async (payload) => {
    const response = await getCurrentCarrierZoneCatalog();
    return response;
  }
);

/* Fetch carrier lanes */
export const fetchCurrentCarrierLanes = createAsyncThunk(
  "carrierProfile/fetchCurrentCarrierLanes",
  async (payload) => {
    const { page } = payload;
    const response = await getCurrentCarrierLanes(page);
    return response;
  }
);

/**
 *
 */
export const fetchAllCurrentCarrierLanes = createAsyncThunk(
  "carrierProfile/fetchAllCurrentCarrierLanes",
  async (payload) => {
    const response = await getCurrentCarrierLanesCatalog();
    return response;
  }
);

/* Add carrier lane */
export const fetchAddCurrentCarrierLane = createAsyncThunk(
  "carrierProfile/fetchAddCurrentCarrierLane",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = payload;
      const response = await addCurrentCarrierLane(data);
      dispatch(fetchCurrentCarrierLanes({ page: 1 }));
      dispatch(fetchAllCurrentCarrierLanes());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier lane */
export const fetchSaveCurrentCarrierLane = createAsyncThunk(
  "carrierProfile/fetchSaveCurrentCarrierLane",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { laneId, data } = payload;
      const response = await saveCurrentCarrierLane(laneId, data);
      dispatch(fetchCurrentCarrierLanes({ page: 1 }));
      dispatch(fetchAllCurrentCarrierLanes());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Add carrier zone */
export const fetchAddCurrentCarrierZone = createAsyncThunk(
  "carrierProfile/fetchAddCurrentCarrierZone",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = payload;
      const response = await addCurrentCarrierZone(data);
      dispatch(fetchCurrentCarrierZones());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier zone */
export const fetchSaveCurrentCarrierZone = createAsyncThunk(
  "carrierProfile/fetchSaveCurrentCarrierZone",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { zoneId, data } = payload;
      const response = await saveCurrentCarrierZone(zoneId, data);
      dispatch(fetchCurrentCarrierZones());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Save carrier zone */
export const fetchSaveCurrentCarrierZoneExclusions = createAsyncThunk(
  "carrierProfile/fetchSaveCurrentCarrierZoneExclusions",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = payload;
      const response = await saveCurrentCarrierZoneExclusions(data);
      dispatch(fetchCurrentCarrierZones());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/* Add multiple carrier zones */
export const fetchAddMultipleCurrentCarrierZones = createAsyncThunk(
  "carrierProfile/fetchAddMultipleCurrentCarrierZones",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = payload;
      const response = await addMultipleCurrentCarrierZones(data);
      dispatch(fetchCurrentCarrierZones());
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);




/* Get carrier customers catalog */
export const fetchGetCurrentCarrierCustomersCatalog = createAsyncThunk(
  "carrierProfile/fetchGetCurrentCarrierCustomersCatalog",
  async (payload, { rejectWithValue, dispatch }) => {
    
    try {
      return await getCurrentCarrierCustomersCatalog();
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/**
 * Create redux reducer with `createSlice`
 */
export const carrierProfileSlice = createSlice({
  name: "carrierProfileSlice",
  initialState,
  reducers: {
    setCarrierProfileActiveTab: (state, { payload }) => {
      state.tab = payload.tab ?? CARRIER_PROFILE_TAB.RATES;
    },
    setCurrentCarrierRate: (state, { payload }) => {
      state.rate = payload?.rate ?? null;
    },
    setCurrentCarrierRateAddEdit: (state, { payload }) => {
      state.rateAdd = payload?.rateAdd ?? false;
      state.rateEdit = payload?.rateEdit ?? false;
    },
    setCurrentCarrierLane: (state, { payload }) => {
      state.lane = payload?.lane ?? null;
    },
    setCurrentCarrierLaneAddEdit: (state, { payload }) => {
      state.laneAdd = payload?.laneAdd ?? false;
      state.laneEdit = payload?.laneEdit ?? false;
    },
    setCurrentCarrierZones: (state, { payload }) => {
      state.zones = payload?.zones ?? null;
    },
    setCurrentCarrierZone: (state, { payload }) => {
      state.zone = payload?.zone ?? null;
    },
    setCurrentCarrierZoneAddEdit: (state, { payload }) => {
      state.zoneAdd = payload?.zoneAdd ?? false;
      state.zoneEdit = payload?.zoneEdit ?? false;
    },
    setCurrentCarrierZoneExclusionEdit: (state, { payload }) => {
      state.zoneExclusionEdit = payload;
    },
    clearZoneErrors: (state) => {
      state.saveCarrierZoneErrors = null;
      state.saveCarrierMultipleZoneErrors = null;
      state.saveCarzoneExclusionsErrors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Fetch carrier rates */
      .addCase(fetchCurrentCarrierRates.pending, (state) => {
        state.isLoadingRates = true;
      })
      .addCase(fetchCurrentCarrierRates.fulfilled, (state, { payload }) => {
        state.isLoadingRates = false;
        state.rates = payload.data.data;
      })
      .addCase(fetchCurrentCarrierRates.rejected, (state) => {
        state.isLoadingRates = false;
        state.rates = [];
      })
      // Add Carrier Rates
      .addCase(fetchAddCurrentCarrierRate.pending, (state) => {
        state.isSavingRate = true;
      })
      .addCase(fetchAddCurrentCarrierRate.fulfilled, (state, { payload }) => {
        state.isSavingRate = false;
        state.rateAdd = false;
      })
      .addCase(fetchAddCurrentCarrierRate.rejected, (state, { payload }) => {
        state.isSavingRate = false;
        // Display errors.
        state.saveCarrierRateErrors = payload?.data ?? null;
      })
      // Save Carrier Rates
      .addCase(fetchSaveCurrentCarrierRate.pending, (state) => {
        state.saveCarrierCarrierRate = null;
        state.isSavingRate = true;
      })
      .addCase(fetchSaveCurrentCarrierRate.fulfilled, (state, { payload }) => {
        state.isSavingRate = false;
        state.rateEdit = false;
      })
      .addCase(fetchSaveCurrentCarrierRate.rejected, (state, { payload }) => {
        state.isSavingRate = false;
        state.saveCarrierRateErrors = payload?.data ?? null;
      })
      /* Fetch carrier zones */
      .addCase(fetchCurrentCarrierZones.pending, (state) => {
        state.isLoadingZones = true;
      })
      .addCase(fetchCurrentCarrierZones.fulfilled, (state, { payload }) => {
        state.isLoadingZones = false;
        state.zones = payload.data.data;
      })
      .addCase(fetchCurrentCarrierZones.rejected, (state) => {
        state.isLoadingZones = false;
        state.zones = [];
      })
      .addCase(fetchCurrentCarrierZonesCatalog.pending, (state) => {
        state.isLoadingZonesCatalog = true;
      })
      .addCase(
        fetchCurrentCarrierZonesCatalog.fulfilled,
        (state, { payload }) => {
          state.isLoadingZonesCatalog = false;
          state.zonesCatalog = payload.data.data;
        }
      )
      .addCase(fetchCurrentCarrierZonesCatalog.rejected, (state) => {
        state.isLoadingZonesCatalog = false;
        state.zonesCatalog = [];
      })
      /* Fetch carrier lanes */
      .addCase(fetchCurrentCarrierLanes.pending, (state) => {
        state.isLoadingLanes = true;
      })
      .addCase(fetchCurrentCarrierLanes.fulfilled, (state, { payload }) => {
        state.isLoadingLanes = false;
        state.lanes = payload.data.data;
      })
      .addCase(fetchCurrentCarrierLanes.rejected, (state) => {
        state.isLoadingLanes = false;
        state.lanes = [];
      })
      /** Fetch all carrier lanes. */
      .addCase(fetchAllCurrentCarrierLanes.pending, (state) => {
        state.isLoadingAllLanes = true;
      })
      .addCase(fetchAllCurrentCarrierLanes.fulfilled, (state, { payload }) => {
        state.isLoadingAllLanes = false;
        state.allLanes = payload.data.data;
      })
      .addCase(fetchAllCurrentCarrierLanes.rejected, (state) => {
        state.isLoadingAllLanes = false;
        state.allLanes = [];
      })
      .addCase(fetchAddCurrentCarrierLane.pending, (state) => {
        state.saveCarrierLaneErrors = null;
        state.isSavingLane = true;
      })
      .addCase(fetchAddCurrentCarrierLane.fulfilled, (state, { payload }) => {
        state.isSavingLane = false;
        state.laneAdd = false;
        state.lane = null;
      })
      .addCase(fetchAddCurrentCarrierLane.rejected, (state, { payload }) => {
        state.isSavingLane = false;
        // Display errors.
        state.saveCarrierLaneErrors = payload?.data ?? null;
      })

      .addCase(fetchSaveCurrentCarrierLane.pending, (state) => {
        state.saveCarrierLaneErrors = null;
        state.isSavingLane = true;
      })
      .addCase(fetchSaveCurrentCarrierLane.fulfilled, (state, { payload }) => {
        state.isSavingLane = false;
        state.laneEdit = false;
        state.lane = null;
      })
      .addCase(fetchSaveCurrentCarrierLane.rejected, (state, { payload }) => {
        state.isSavingLane = false;
        // Display errors.
        state.saveCarrierLaneErrors = payload?.data ?? null;
      })
      /** Fetch add carrier zone */
      .addCase(fetchAddCurrentCarrierZone.pending, (state) => {
        state.saveCarrierZoneErrors = null;
        state.isSavingZone = true;
      })
      .addCase(fetchAddCurrentCarrierZone.fulfilled, (state, { payload }) => {
        state.isSavingZone = false;
        state.zoneAdd = false;
        // state.zone = null;
      })
      .addCase(fetchAddCurrentCarrierZone.rejected, (state, action) => {
        state.isSavingZone = false;
        if (action.payload?.data?.message)
          state.saveCarrierZoneErrors = action.payload?.data?.message;
      })
      /** Fetch save carrier zone */
      .addCase(fetchSaveCurrentCarrierZone.pending, (state) => {
        state.saveCarrierZoneErrors = null;
        state.isSavingZone = true;
      })
      .addCase(fetchSaveCurrentCarrierZone.fulfilled, (state, { payload }) => {
        state.isSavingZone = false;
        state.zoneEdit = false;
        state.zone = null;
      })
      .addCase(fetchSaveCurrentCarrierZone.rejected, (state, action) => {
        state.isSavingZone = false;
        // Display errors.
        if (action.payload?.data?.message)
          state.saveCarrierZoneErrors = action.payload?.data?.message;
      })
      /** Fetch save zone exclusion */
      .addCase(fetchSaveCurrentCarrierZoneExclusions.pending, (state) => {
        state.saveCarrierZoneExclusionsErrors = null;
        state.isSavingZoneExclusions = true;
      })
      .addCase(
        fetchSaveCurrentCarrierZoneExclusions.fulfilled,
        (state, { payload }) => {
          state.isSavingZoneExclusions = false;
          state.zoneExclusionEdit = false;
          state.zone = null;
        }
      )
      .addCase(
        fetchSaveCurrentCarrierZoneExclusions.rejected,
        (state, action) => {
          state.isSavingZoneExclusions = false;
          if (action.payload?.data?.message)
            state.saveCarrierZoneExclusionsErrors =
              action.payload?.data?.message;
        }
      )

      /** Fetch add multiple carrier zone */
      .addCase(fetchAddMultipleCurrentCarrierZones.pending, (state) => {
        state.saveCarrierMultipleZoneErrors = null;
        state.isSavingZone = true;
      })
      .addCase(
        fetchAddMultipleCurrentCarrierZones.fulfilled,
        (state, { payload }) => {
          state.isSavingZone = false;
          state.zoneAdd = false;
          // state.zone = null;
        }
      )
      .addCase(
        fetchAddMultipleCurrentCarrierZones.rejected,
        (state, action) => {
          state.isSavingZone = false;
          if (action.payload?.data?.errors)
            state.saveCarrierMultipleZoneErrors = action.payload?.data?.errors;
        }
      ).addCase(fetchGetCurrentCarrierCustomersCatalog.pending, (state) => {
        state.isLoadingCustomersCatalog = true;
      })
      .addCase(fetchGetCurrentCarrierCustomersCatalog.fulfilled, (state, { payload }) => {
        state.isLoadingCustomersCatalog = false;
        state.customersCatalog = payload.data.data;
      })
      .addCase(fetchGetCurrentCarrierCustomersCatalog.rejected, (state) => {
        state.isLoadingCustomersCatalog = false;
        state.customersCatalog = [];
      });
  },
});

/* Export reducer's actions. */
export const {
  setCarrierProfileActiveTab,
  setCurrentCarrierRate,
  setCurrentCarrierRateAddEdit,
  setCurrentCarrierLane,
  setCurrentCarrierLaneAddEdit,
  setCurrentCarrierZones,
  setCurrentCarrierZone,
  setCurrentCarrierZoneAddEdit,
  setCurrentCarrierZoneExclusionEdit,
  clearZoneErrors,
} = carrierProfileSlice.actions;

/* Export reducer */
export default carrierProfileSlice.reducer;
