import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ORDER_EDIT } from "features/constants";
import { removeEmptyProps } from "utils/formUtils";
import { toBrokerEditForm } from "utils/orderUtils";
import Loader from "components/Loader";

const OrderBrokerForm = ({
	order,
	edit,
	collapse,
	isSaving,
	onCancel,
	onSave,
}) => {
	// Translation
	const { t } = useTranslation();
	// Initialize form
	const { register, handleSubmit, reset } = useForm();
	// Effect
	useEffect(() => {
		reset(toBrokerEditForm(order));
	}, [order, reset]);
	// Handlers
	const handleSaveBrokerData = (data, event) => {
		event.preventDefault();
		return onSave(removeEmptyProps(data));
	};
	const handleCancelEdit = () => {
		onCancel();
	};
	// Render
	const renderForm = () => {
		if (!isSaving) {
			return (
				<>
					<div className="a field">
						<label>{t("Fields.BrokerName")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Fields.BrokerName")}
							{...register("broker_name")}
						/>
					</div>
					<div className="a subtitle top-separation">
						<div>{t("Common.Address")}</div>
					</div>
					<div className="b-1 field">
						<label>{t("Fields.AddressLine1")}</label>
						<input
							type="text"
							placeholder={t("Fields.AddressLine1")}
							className="form-control"
							{...register("broker_address_line_1")}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Fields.AddressLine2")}</label>
						<input
							type="text"
							placeholder={t("Fields.AddressLine2")}
							className="form-control"
							{...register("broker_address_line_2")}
						/>
					</div>
					<div className="b-1 field">
						<label>{t("Fields.City")}</label>
						<input
							type="text"
							placeholder={t("Fields.City")}
							className="form-control"
							{...register("broker_city")}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Fields.StateProvince")}</label>
						<input
							type="text"
							placeholder={t("Fields.StateProvince")}
							className="form-control"
							{...register("broker_state_province")}
						/>
					</div>
					<div className="b-11 field">
						<label>{t("Fields.PostalCode")}</label>
						<input
							type="text"
							placeholder={t("Fields.PostalCode")}
							className="form-control"
							{...register("broker_postal_code")}
						/>
					</div>
					<div className="b-12 field">
						<label>{t("Fields.CountryCode")}</label>
						<input
							type="text"
							placeholder={t("Fields.CountryCode")}
							className="form-control"
							{...register("broker_country_code")}
						/>
					</div>
					<div className="a subtitle top-separation">
						<div>{t("Common.Contact")}</div>
					</div>
					<div className="b-1 field">
						<label>{t("Common.Name")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Common.Name")}
							{...register("broker_contact_name")}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Common.Email")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Common.Email")}
							{...register("broker_contact_email")}
						/>
					</div><div className="b-1 field">
						<label>{t("Common.Phone")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Common.Phone")}
							{...register("broker_contact_phone")}
						/>
					</div>
				</>
			);
		}
	};
	if (edit == null) return null;
	if (edit !== ORDER_EDIT.BROKER ) return null;
	if (edit === ORDER_EDIT.BROKER && order != null && isSaving) {
		return (
			<div className="order-block edit top-border">
				<div
					className={classNames("dsv-form full no-padding", {
						collapse: collapse,
					})}
				>
					<div className="a order-subtitle">
						<div>{t("Common.CustomsInfo")}</div>
					</div>
					<div className="a">
						<Loader />
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="order-block edit top-border">
			<div
				className={classNames("dsv-form full no-padding", {
					collapse: collapse,
				})}
			>
				<div className="a order-subtitle">
					<div>{t("Common.CustomsInfo")}</div>
					<div>
						<span className="icon-edit-close" onClick={handleCancelEdit}></span>
					</div>
					<div>
						<span
							className="icon-edit-save"
							onClick={handleSubmit(handleSaveBrokerData)}
						></span>
					</div>
				</div>
				{renderForm()}
			</div>
		</div>
	);
};

export default OrderBrokerForm;
