import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toZoneForm } from "utils/carrierUtils";
import classNames from "classnames";
import Loader from "components/Loader";
import { removeNullProps } from "utils/formUtils";
import Select from "react-select";
import { fetchStates, fetchCities } from "features/catalogs/postalCodesSlice";
import { processZoneChanges } from "utils/zoneHelpers";
import { findDuplicates } from "utils/objectUtils";

export default function CarrierZonesAdd({
	zone,
	isSaving,
	saveZoneErrors,
	saveMultipleZoneErrors,
	onSave,
	onSaveMultiple,
	onAdd,
	onClose,
	onClearErrors,
	customers,
}) {
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	const { isLoadingStates, states, isLoadingCities, cities } = useSelector(
		(state) => state.postalCodes
	);

	// const [duplicateErrors, setDuplicateErrors] = useState({});

	const {
		fields: details,
		append: appendDetail,
		remove: removeDetail,
	} = useFieldArray({
		control,
		name: "zone_details",
		rules: {
			minLength: getValues("type") === "1" ? 1 : 0,
			validate: function (value, formValues) {
				if (getValues("type") !== "1") {
					return true;
				}
				const list = getValues(`zone_details`);
				return list?.length !== 0;
			},
		},
	});

	const {
		fields: items,
		append: appendItem,
		remove: removeItem,
	} = useFieldArray({
		control,
		name: "items",
		rules: {
			minLength: getValues("type") === "2" ? 1 : 0,
			validate: function (value, formValues) {
				if (getValues("type") !== "2") {
					return true;
				}
				const list = getValues(`items`);
				// TODO: Find duplicates and display errors.
				const result = findDuplicates(list);
				const e = {};

				if (result.duplicates.length > 0) {
					result.duplicates.forEach((indice) => {
						e[`${indice}`] = "";
					});
				}
				// setDuplicateErrors(e);
				return list?.length !== 0; //&& result.duplicates.length !== 0
			},
		},
	});

	watch("type");
	watch("zone_details");
	watch("items");

	// useWatch({
	//   name: "zone_details",
	//   control,
	// });

	// Selectors
	// const { isSavingZone, saveCarrierZoneErrors, saveCarrierMultipleZoneErrors } =
	//   useSelector((state) => state.carriers);
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleSave = (data, event) => {
		event.preventDefault();

		if (data.zone_details != null) {
			var zone_details = data.zone_details.map((detail) => {
				return {
					state_number: Number(detail.state.value),
					city_number:
						detail.city.value != null ? Number(detail.city.value) : null,
				};
			});

			if (zone != null && data.type === "1") {
				// Update
				const update = processZoneChanges(zone.zone_details, zone_details);
				const clean = removeNullProps(update);
				JSON.stringify(`clean:${clean}`);
				onSave({
					name: data.name,
					zone_details: clean,
				});
			} else if (data.type === "1") {
				// Insert
				const clean = removeNullProps(zone_details);
				JSON.stringify(`clean:${clean}`);
				onAdd({
					name: data.name,
					zone_details: {
						insert: clean,
						update: [],
						delete: [],
					},
				});
			}
		}

		if (data.items != null && data.type === "2") {
			// Insert multiple
			var items = data.items.map((detail) => {
				return {
					name: detail.name,
					state_number: Number(detail.state.value),
					city_number: Number(detail.city.value),
				};
			});
			const clean = removeNullProps(items);

			onSaveMultiple({ items: clean });
		}
	};
	const handleClose = () => {
		onClose();
	};

	const getCityName = (stateNumber, cityNumber) => {
		if (cityNumber == null) return t("Common.AllCities");
		const name =
			stateNumber !== null
				? cities[`${stateNumber}`]?.find(
						(element) => String(element.city_number) === cityNumber
				  )?.name ?? ""
				: "";
		return name;
	};

	// Effect runs once on display.
	useEffect(() => {
		if (states == null && !isLoadingStates) {
			dispatch(fetchStates());
		}
		if (cities == null && !isLoadingCities) {
			dispatch(fetchCities());
		}
		if (
			states != null &&
			!isLoadingStates &&
			cities != null &&
			!isLoadingCities
		) {
			onClearErrors();
			const values =
				zone != null
					? toZoneForm(zone, states)
					: {
							type: "1",
							zone_details: [
								{
									state: "",
									city: "",
								},
							],
							items: [
								{
									state: "",
									city: "",
									name: "",
								},
							],
					  };

			reset(values);
		}
	}, [
		zone,
		reset,
		setValue,
		states,
		isLoadingStates,
		cities,
		isLoadingCities,
		dispatch,
		onClearErrors,
	]);

	if (isSaving || isLoadingStates) {
		return (
			<div>
				<Loader msg={isSaving ? t("Common.Saving") : t("Common.Loading")} />
			</div>
		);
	}

	return (
		<div className="tab-content details">
			<div className="details-block">
				<div className="dsv-form">
					<div className="a details-subtitle">
						<div>
							{zone == null
								? t("AdminCarriers.ZoneAdd")
								: t("AdminCarriers.ZoneEdit")}
						</div>
					</div>

					{zone == null && (
						<>
							{/* https://stackoverflow.com/questions/67626696/radio-buttons-with-react-hook-form */}
							<div className="b-1 top-separation">
								<input
									{...register("type")}
									type="radio"
									// name="type-1"
									id="field-1"
									value="1"
								/>
								<label htmlFor="field-1">{t("AdminCarriers.SingleZone")}</label>
							</div>
							<div className="b-2 top-separation">
								<input
									{...register("type")}
									type="radio"
									// name="type-2"
									id="field-2"
									value="2"
								/>
								<label htmlFor="field-2">
									{t("AdminCarriers.MultipleZones")}
								</label>
							</div>
						</>
					)}

					{getValues("type") === "1" && (
						<>
							<div
								className={classNames("b-1 field", {
									error: errors.name,
								})}
							>
								<label>{t("Fields.ZoneName")}</label>
								<input
									type="text"
									placeholder={t("Fields.ZoneName")}
									{...register("name", {
										required: true,
									})}
								/>
							</div>
							<div className="a top-separation details-subtitle">
								<div className="a details-subtitle">
									{t("AdminCarriers.ZoneDetails")}
								</div>
								<div>
									<span
										className="icon-add"
										onClick={() =>
											appendDetail({
												state: "",
												city: "",
											})
										}
									></span>
								</div>
							</div>

							<div className="a field">
								<div className="field-table zone-edit">
									<div>
										<table cellPadding="0" cellSpacing="0">
											<thead>
												<tr>
													<th>{t("Common.State")}</th>
													<th>{t("Fields.City")}</th>
													<th>&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												{details.map((item, index) => {
													return (
														<>
															<tr>
																<td>
																	<Controller
																		name={`zone_details.${index}.state`}
																		control={control}
																		rules={{
																			required: true,
																		}}
																		render={({
																			field: { onChange, value },
																		}) => (
																			<Select
																				className="dsv-select no-border"
																				classNamePrefix="dsv-select"
																				isClearable={false}
																				onChange={(e) => {
																					setValue(
																						`zone_details.${index}.city`,
																						{
																							value: null,
																							label: t("Common.AllCities"),
																						}
																					);
																					onChange(e);
																				}}
																				placeholder={t("Common.Select")}
																				value={value ?? {}}
																				options={
																					states?.map((item, index) => {
																						return {
																							value: String(item?.state_number),
																							label: item?.name ?? "",
																						};
																					}) ?? []
																				}
																			/>
																		)}
																	/>
																</td>
																<td>
																	<Controller
																		name={`zone_details.${index}.city`}
																		control={control}
																		rules={{
																			required: true,
																		}}
																		render={({
																			field: { onChange, value },
																		}) => (
																			<Select
																				className="dsv-select no-border"
																				classNamePrefix="dsv-select"
																				isClearable={false}
																				onChange={onChange}
																				placeholder={t("Common.Select")}
																				isDisabled={
																					getValues(
																						`zone_details.${index}.state`
																					) === ""
																				}
																				value={
																					value != null
																						? value.label !== ""
																							? value
																							: {
																									value: value.value,
																									label: getCityName(
																										getValues(
																											`zone_details.${index}.state`
																										)?.value,
																										value.value
																									),
																							  }
																						: {}
																				}
																				options={
																					getValues(
																						`zone_details.${index}.state`
																					) !== ""
																						? [
																								{
																									value: null,
																									label: t("Common.AllCities"),
																								},
																								...cities[
																									`${
																										getValues(
																											`zone_details.${index}.state`
																										).value
																									}`
																								]?.map((item, index) => {
																									return {
																										value: String(
																											item?.city_number
																										),
																										label: item?.name ?? "",
																									};
																								}),
																						  ]
																						: []
																				}
																				// defaultOptions
																			/>
																		)}
																	/>
																</td>
																<td>
																	<span
																		className="icon-remove"
																		onClick={() => removeDetail(index)}
																	></span>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>

							{errors.zone_details ? (
								<div className="a field error">
									<label>{t("FieldErrors.Empty")}</label>
								</div>
							) : (
								<></>
							)}
							{saveZoneErrors ?? (
								<div className="a field error">
									<label>{saveZoneErrors}</label>
								</div>
							)}
						</>
					)}

					{getValues("type") === "2" && (
						<>
							<div className="a top-separation details-subtitle">
								<div className="a details-subtitle">{t("Common.Items")}</div>
								<div>
									<span
										className="icon-add"
										onClick={() =>
											appendItem({
												state: "",
												city: "",
												name: "",
											})
										}
									></span>
								</div>
							</div>

							<div className="a field">
								<div className="field-table zone-edit">
									<div>
										<table cellPadding="0" cellSpacing="0">
											<thead>
												<tr>
													<th>{t("Common.State")}</th>
													<th>{t("Fields.City")}</th>
													<th>{t("Fields.ZoneName")}</th>
													<th>&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												{items.map((item, index) => {
													return (
														<>
															<tr>
																<td
																	className={classNames({
																		error:
																			errors?.items != null
																				? errors?.items[`${index}`]?.state
																				: false,
																	})}
																>
																	<Controller
																		name={`items.${index}.state`}
																		control={control}
																		rules={{
																			required: true,
																		}}
																		render={({
																			field: { onChange, value },
																		}) => (
																			<Select
																				className="dsv-select no-border"
																				classNamePrefix="dsv-select"
																				isClearable={false}
																				onChange={(e) => {
																					setValue(`items.${index}.city`, {
																						value: null,
																						label: t("Common.AllCities"),
																					});
																					onChange(e);
																				}}
																				placeholder={t("Common.Select")}
																				value={value ?? {}}
																				options={
																					states?.map((item, index) => {
																						return {
																							value: String(item?.state_number),
																							label: item?.name ?? "",
																						};
																					}) ?? []
																				}
																			/>
																		)}
																	/>
																</td>
																<td
																	className={classNames({
																		error:
																			errors?.items != null
																				? errors?.items[`${index}`]?.city !=
																				  null
																				: false,
																	})}
																>
																	<Controller
																		name={`items.${index}.city`}
																		control={control}
																		rules={{
																			required: true,
																		}}
																		render={({
																			field: { onChange, value },
																		}) => (
																			<Select
																				className="dsv-select no-border"
																				classNamePrefix="dsv-select"
																				isClearable={false}
																				onChange={onChange}
																				placeholder={t("Common.Select")}
																				isDisabled={
																					getValues(`items.${index}.state`) ===
																					""
																				}
																				value={
																					value != null
																						? value.label !== ""
																							? value
																							: {
																									value: value.value,
																									label: getCityName(
																										getValues(
																											`items.${index}.state`
																										)?.value,
																										value.value
																									),
																							  }
																						: {}
																				}
																				options={
																					getValues(`items.${index}.state`) !==
																					""
																						? [
																								{
																									value: null,
																									label: t("Common.AllCities"),
																								},
																								...cities[
																									`${
																										getValues(
																											`items.${index}.state`
																										).value
																									}`
																								]?.map((item, index) => {
																									return {
																										value: String(
																											item?.city_number
																										),
																										label: item?.name ?? "",
																									};
																								}),
																						  ]
																						: []
																				}
																			/>
																		)}
																	/>
																</td>
																<td
																	className={classNames({
																		error:
																			errors?.items != null
																				? errors?.items[`${index}`]?.name !=
																				  null
																				: false,
																	})}
																>
																	<input
																		type="text"
																		placeholder={t("Fields.ZoneName")}
																		{...register(`items.${index}.name`, {
																			required: true,
																		})}
																	></input>
																</td>
																<td>
																	<span
																		className="icon-remove"
																		onClick={() => removeItem(index)}
																	></span>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>

							{errors.items ? (
								<div className="error">
									<label>{t("FieldErrors.Empty")}</label>
								</div>
							) : (
								<></>
							)}

							{saveMultipleZoneErrors ?? (
								<div className="error">
									<label>{saveMultipleZoneErrors}</label>
								</div>
							)}
						</>
					)}

					<div className="b-11 top-separation">
						<label>&nbsp;</label>
						<div className="button" onClick={handleClose}>
							{t("Buttons.Cancel")}
						</div>
					</div>
					<div className="b-12 top-separation">
						<label>&nbsp;</label>
						<div className="button main" onClick={handleSubmit(handleSave)}>
							{t("Buttons.Save")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
