import { createContext } from "react";

const FilterContext = createContext({
  filters: {},
  setFilters: () => {},
  showFilter: false,
  setShowFilter: () => {},
  activeTable: null,
  setActiveTable: () => {},
  options:{}
});

export default FilterContext;
