import { stringToDate } from "utils/dateUtils";
import { convertCmToIn } from "utils/dimensionUtils";
import { convertKgToLb } from "utils/weightUtils";
import { convertTempCToF } from "utils/temperatureUtils";
import { toSelectorValue } from "utils/constantsUtils";

import {
	ADDRESS_TYPE,
	CONTACT_TYPE,
	CARGO_TYPE,
	SHIPMENT_TYPE,
	BOOKING_METHOD,
	WEIGHT_UNIT,
	VOLUME_UNIT,
	DIMENSION_UNIT,
	ACCESSORIALS,
	LOCATION_TYPES,
	TEMPERATURE_UNIT,
	FREIGHT_CLASS,
	LANGUAGES,
	SUBSTANCE_GROUP,
} from "features/constants";
import { t } from "i18next";
import { portCodeRegEx } from "./regExs";
import { dateToUTC } from "utils/dateUtils";

/**
 * Get address by type.
 * @param {*} order
 * @param {*} addressType
 * @returns
 */
export const getAddress = (order, addressType) => {
	return order?.addresses?.find((e) => e.address_type === addressType) ?? null;
};

/**
 * Get contact by type.
 * @param {*} order
 * @param {*} contactType
 * @returns
 */
export const getContact = (order, contactType) => {
	return order?.contacts?.find((e) => e.contact_type === contactType) ?? null;
};
/**
 * Get contact props by type
 * @param {*} contacts array of contact
 * @param {*} type int contact type
 * @returns object with name property
 */
export const getContactProps = (contacts, type) => {
	if (contacts == null) {
		return { name: "" };
	}
	const filtered = contacts.filter((el) => type === Number(el.contact_type));
	const item = filtered.length > 0 ? filtered[0] : null;
	if (item == null) {
		return { name: "" };
	}
	return {
		name: item.name != null ? item.name : "",
	};
};
/**
 * Get address props by type
 * @param {*} addresses array of address
 * @param {*} type int address type
 * @returns object with zip and state properties
 */
export const getAddressProps = (addresses, type) => {
	if (addresses == null) {
		return { zip: "", state: "" };
	}
	const filtered = addresses.filter((el) => type === Number(el.address_type));
	const item = filtered.length > 0 ? filtered[0] : null;
	if (item == null) {
		return { zip: "", state: "" };
	}
	return {
		zip: item.postal_code ?? "",
		state: item.state_province ?? "",
	};
};

function formatDateString(dateString, locale) {
	const parts = dateString.split(" ")[0].split("-");
	if (locale === "es") {
		return `${parts[2]}/${parts[1]}/${parts[0]}`;
	}
	return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

/**
 * Converts `Order` JSON object to order-edit addresses form fields.
 * @param {*} order Order data object.
 * @returns
 */
export const toAddressEditForm = (order, locale) => {
	const origin_estimated_ready_date = order?.origin_estimated_ready_date_time
		? formatDateString(order?.origin_estimated_ready_date_time, locale)
		: undefined;

	const origin_estimated_close_date = order?.origin_estimated_close_date_time
		? formatDateString(order?.origin_estimated_close_date_time, locale)
		: undefined;

	const destination_requested_delivery_date =
		order?.destination_requested_delivery_date_time
			? formatDateString(
					order?.destination_requested_delivery_date_time,
					locale
			  )
			: undefined;

	// Get addresses by type.
	const originAddres = getAddress(order, ADDRESS_TYPE.ORIGIN);

	const originContact = getContact(order, CONTACT_TYPE.ORIGIN);

	const destinationAddres = getAddress(order, ADDRESS_TYPE.DESTINATION);

	const destinationContact = getContact(order, CONTACT_TYPE.DESTINATION);
	const accesorials = order?.accessorials ?? [];

	const origin_accessorials_list = [];

	//
	const origin_is_port =
		(order?.transport_shipment_type ?? false) === SHIPMENT_TYPE.DE;

	const destination_accessorials_list = [];
	const destination_is_port =
		(order?.transport_shipment_type ?? false) === SHIPMENT_TYPE.PU;

	accesorials.forEach((el) => {
		// get elements that match the criteria
		if (el.is_port === origin_is_port && el.cargo_type !== CARGO_TYPE.FCL) {
			origin_accessorials_list.push({
				value: ACCESSORIALS.properties[`${el.id}`].value,
				label: t(ACCESSORIALS.properties[`${el.id}`].name),
			});
		}
		if (
			el.is_port === destination_is_port &&
			el.cargo_type !== CARGO_TYPE.FCL
		) {
			destination_accessorials_list.push({
				value: ACCESSORIALS.properties[`${el.id}`].value,
				label: t(ACCESSORIALS.properties[`${el.id}`].name),
			});
		}
	});

	return {
		// Origin fields
		origin_name: order?.origin_name ?? undefined,
		origin_rating_port_code: order?.origin_rating_port_code ?? undefined,
		origin_estimated_ready_date: origin_estimated_ready_date,
		origin_estimated_ready_time: order?.origin_estimated_ready_date_time
			? stringToDate(order.origin_estimated_ready_date_time)
			: null,
		origin_estimated_close_date: origin_estimated_close_date,
		origin_estimated_close_time: order?.origin_estimated_close_date_time
			? stringToDate(order.origin_estimated_close_date_time)
			: null,
		origin_trucker_notes: order?.origin_trucker_notes ?? undefined,
		"origin_address.address_line_1": originAddres?.address_line_1 ?? undefined,
		"origin_address.address_line_2": originAddres?.address_line_2 ?? undefined,
		"origin_address.city": originAddres?.city ?? undefined,
		"origin_address.state_province": originAddres?.state_province ?? undefined,
		"origin_address.postal_code": originAddres?.postal_code ?? undefined,
		"origin_address.country_code": originAddres?.country_code ?? undefined,
		"origin_contact.name": originContact?.name ?? undefined,
		"origin_contact.phone": originContact?.phone ?? undefined,
		"origin_contact.email": originContact?.email ?? undefined,
		origin_location_type: toSelectorValue(
			LOCATION_TYPES.properties,
			order?.origin_location_type_id
		),
		origin_accessorials_list: origin_accessorials_list,
		// Destination fields
		destination_name: order?.destination_name ?? undefined,
		destination_rating_port_code:
			order?.destination_rating_port_code ?? undefined,
		destination_requested_delivery_date: destination_requested_delivery_date,
		destination_requested_delivery_time:
			order?.destination_requested_delivery_date_time
				? stringToDate(order.destination_requested_delivery_date_time)
				: null,
		destination_trucker_delivery_notes:
			order?.destination_trucker_delivery_notes ?? undefined,
		"destination_address.address_line_1":
			destinationAddres?.address_line_1 ?? undefined,
		"destination_address.address_line_2":
			destinationAddres?.address_line_2 ?? undefined,
		"destination_address.city": destinationAddres?.city ?? undefined,
		"destination_address.state_province":
			destinationAddres?.state_province ?? undefined,
		"destination_address.postal_code":
			destinationAddres?.postal_code ?? undefined,
		"destination_address.country_code":
			destinationAddres?.country_code ?? undefined,
		"destination_contact.name": destinationContact?.name ?? undefined,
		"destination_contact.phone": destinationContact?.phone ?? undefined,
		"destination_contact.email": destinationContact?.email ?? undefined,
		destination_location_type: toSelectorValue(
			LOCATION_TYPES.properties,
			order?.destination_location_type_id
		),
		destination_accessorials_list: destination_accessorials_list,
	};
};

/**
 * Converts `Order` JSON object to order-edit header form fields.
 * @param {*} order Order data object.
 * @returns
 */
export const toHeaderEditForm = (order, branches) => {
	const forwarderContact = getContact(order, CONTACT_TYPE.FORWARDER);
	const branch = branches.filter((el) => el.id === order?.branch_id)[0];
	return {
		transport_shipment_type: order?.transport_shipment_type
			? {
					value: SHIPMENT_TYPE.properties[order?.transport_shipment_type].value,
					label: t(
						SHIPMENT_TYPE.properties[order?.transport_shipment_type].name
					),
			  }
			: {},
		transport_cargo_type: order?.transport_cargo_type
			? {
					value: CARGO_TYPE.properties[order?.transport_cargo_type].value,
					label: t(CARGO_TYPE.properties[order?.transport_cargo_type].name),
			  }
			: {},
		alternate_reference_number: order?.alternate_reference_number ?? undefined,
		master_bill_number: order?.master_bill_number ?? undefined,
		house_bill_number: order?.house_bill_number ?? undefined,
		forwarder_contact_name: forwarderContact?.name ?? undefined,
		forwarder_phone: forwarderContact?.phone ?? undefined,
		forwarder_email: forwarderContact?.email ?? undefined,
		branch_id:
			order?.branch_id != null
				? { label: branch.code, value: branch.id }
				: undefined,
		forwarder_department: order?.forwarder_department ?? undefined,
		is_dedicated: order?.is_dedicated
			? { value: true, label: t("Common.Yes") }
			: { value: false, label: t("Common.No") },
	};
};

/**
 * Converts Order Request object to order-edit Broker Info form fields.
 * @param {object} order
 * @returns object with broker info form fields
 */
export const toBrokerEditForm = (order) => {
	const address = getAddress(order, ADDRESS_TYPE.BROKER);
	const contact = getContact(order, CONTACT_TYPE.BROKER);
	return {
		broker_name: order?.broker_name ?? null,
		broker_address_line_1: address?.address_line_1 ?? null,
		broker_address_line_2: address?.address_line_2 ?? null,
		broker_city: address?.city ?? null,
		broker_state_province: address?.state_province ?? null,
		broker_postal_code: address?.postal_code ?? null,
		broker_country_code: address?.country_code ?? null,
		broker_contact_name: contact?.name ?? null,
		broker_contact_phone: contact?.phone ?? null,
		broker_contact_email: contact?.email ?? null,
	};
};

/**
 * Converts Order Request object to order-edit Container Info form fields.
 * @param {object} order
 * @param {string} locale
 * @returns object with container info form fields
 */
export const toContainerEditForm = (order, locale) => {
	const address =
		order?.transport_shipment_type === SHIPMENT_TYPE.PU
			? getAddress(order, ADDRESS_TYPE.CONTAINER_PICKUP)
			: getAddress(order, ADDRESS_TYPE.CONTAINER_DELIVERY);
	const addressData =
		order?.transport_shipment_type === SHIPMENT_TYPE.PU
			? {
					container_name: order?.empty_container_pickup_location_name ?? null,
					container_rating_port_code:
						order?.empty_container_pickup_location_rating_port_code ?? null,
					container_account_number:
						order?.empty_container_pickup_location_account_number ?? null,
					container_account_trucker_notes:
						order?.empty_container_pickup_location_trucker_notes ?? null,
					container_address_line_1: address?.address_line_1 ?? null,
					container_address_line_2: address?.address_line_2 ?? null,
					container_address_city: address?.city ?? null,
					container_address_state_province: address?.state_province ?? null,
					container_address_postal_code: address?.postal_code ?? null,
					container_address_country_code: address?.country_code ?? null,
			  }
			: {
					container_name: order?.empty_container_delivery_location_name ?? null,
					container_rating_port_code:
						order?.empty_container_delivery_location_rating_port_code ?? null,
					container_account_number:
						order?.empty_container_delivery_location_account_number ?? null,
					container_account_trucker_notes:
						order?.empty_container_delivery_location_trucker_notes ?? null,
					container_address_line_1: address?.address_line_1 ?? null,
					container_address_line_2: address?.address_line_2 ?? null,
					container_address_city: address?.city ?? null,
					container_address_state_province: address?.state_province ?? null,
					container_address_postal_code: address?.postal_code ?? null,
					container_address_country_code: address?.country_code ?? null,
			  };

	return {
		container_number: order?.transport_container_number ?? null,
		container_last_free_date_value: order?.transport_container_last_free_date
			? formatDateString(order?.transport_container_last_free_date, locale)
			: null,
		...addressData,
	};
};

/**
 * Validate if order is ready to book
 * @param {*} order
 * @param {*} method
 * @param {*} selectedRates
 * @param {*} spotPrice
 * @param {*} spotCurrency
 * @param {*} spotExpires
 * @param {*} selectedCarriers
 * @param {*} selfCarrier
 * @returns
 */
export const isOrderReadyToBook = (
	order,
	method,
	selectedRates,
	spotPrice = null,
	spotCurrency = null,
	spotExpires = null,
	selectedCarriers = null,
	selfCarrier = null
) => {
	// 1. Date Validation
	let result = {
		isReady: true,
		errors: [],
		address: {
			estimated_ready: false,
			estimated_close: false,
			requested_delivery: false,
		},
	};
	const estReady =
			order.origin_estimated_ready_date_time != null
				? stringToDate(order.origin_estimated_ready_date_time)
				: null,
		estClose =
			order.origin_estimated_close_date_time != null
				? stringToDate(order.origin_estimated_close_date_time)
				: null,
		reqDelivery =
			order.destination_requested_delivery_date_time != null
				? stringToDate(order.destination_requested_delivery_date_time)
				: null;
	// 1.1. Validate null dates
	if (estReady == null || estClose == null || reqDelivery == null) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrMissingDates")],
		};
		if (estReady == null)
			result = {
				...result,
				address: { ...result.address, estimated_ready: true },
			};
		if (estClose == null)
			result = {
				...result,
				address: { ...result.address, estimated_close: true },
			};
		if (reqDelivery == null)
			result = {
				...result,
				address: { ...result.address, requested_delivery: true },
			};
	}
	// 1.2 Validate no dates are in the past
	const date = new Date();
	date.setHours(0, 0, 0, 0);
	const today = dateToUTC(date);
	if (estReady < today || estClose < today || reqDelivery < today)
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrPastDates")],
		};
	if (estReady < today)
		result = {
			...result,
			address: { ...result.address, estimated_ready: true },
		};
	if (estClose < today)
		result = {
			...result,
			address: { ...result.address, estimated_close: true },
		};
	if (reqDelivery < today)
		result = {
			...result,
			address: { ...result.address, requested_delivery: true },
		};
	// 1.3 Validate sequence of dates:
	// 		 estimiated_ready < estimated_close < requested_delivery
	if (estClose < estReady)
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrInvalidClose")],
			address: { ...result.address, estimated_close: true },
		};
	if (reqDelivery < estClose)
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrInvalidDelivery")],
			address: { ...result.address, requested_delivery: true },
		};
	// 2. Validate Addresses
	// 2.1 Validate Port Code
	if (order.transport_shipment_type === SHIPMENT_TYPE.DE) {
		// 2.1.1 Validate Origin Port Code
		if (order.origin_rating_port_code == null) {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrMissingPort")],
				address: { ...result.address, origin_rating_port_code: true },
			};
		} else {
			if (!portCodeRegEx.test(order.origin_rating_port_code)) {
				result = {
					...result,
					isReady: false,
					errors: [...result.errors, t("OperatorOrders.ErrInvalidPort")],
					address: { ...result.address, origin_rating_port_code: true },
				};
			}
		}
	} else if (order.transport_shipment_type === SHIPMENT_TYPE.PU) {
		// 2.1.2 Validate Destination Port Code
		if (order.destination_rating_port_code == null) {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrMissingPort")],
				address: { ...result.address, destination_rating_port_code: true },
			};
		} else {
			if (!portCodeRegEx.test(order.destination_rating_port_code)) {
				result = {
					...result,
					isReady: false,
					errors: [...result.errors, t("OperatorOrders.ErrInvalidPort")],
					address: { ...result.address, destination_rating_port_code: true },
				};
			}
		}
	}
	// 2.2 Validate Required Address Fields
	const originAddress = order.addresses?.find(
		(el) => el.address_type === ADDRESS_TYPE.ORIGIN
	);
	if (originAddress == null) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrMissingOrigin")],
			address: { ...result.address, origin: true },
		};
	} else {
		if (originAddress.address_line_1 == null)
			result = {
				...result,
				isReady: false,
				errors: [
					...result.errors,
					t("OperatorOrders.ErrMissingOriginAddressLine"),
				],
				address: { ...result.address, origin: true },
			};
		if (originAddress.postal_code == null)
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrMissingOriginZipCode")],
				address: { ...result.address, origin: true },
			};
	}
	const destinationAddress = order.addresses?.find(
		(el) => el.address_type === ADDRESS_TYPE.DESTINATION
	);
	if (destinationAddress == null) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrMissingDestination")],
			address: { ...result.address, destination: true },
		};
	} else {
		if (destinationAddress.address_line_1 == null)
			result = {
				...result,
				isReady: false,
				errors: [
					...result.errors,
					t("OperatorOrders.ErrMissingDestinationAddressLine"),
				],
				address: { ...result.address, destination: true },
			};
		if (destinationAddress.postal_code == null)
			result = {
				...result,
				isReady: false,
				errors: [
					...result.errors,
					t("OperatorOrders.ErrMissingDestinationZipCode"),
				],
				address: { ...result.address, destination: true },
			};
	}
	// 3. Validate Cargo
	if (order.cargo_actual_weight == null) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrCargoWeightMissing")],
			cargo: { ...result.cargo, actual_weight: true },
		};
	}
	if (order.cargo_actual_weight_unit == null) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrCargoWeightUnitMissing")],
			cargo: { ...result.cargo, actual_weight_unit: true },
		};
	}
	if (order.cargo_pieces == null) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrCargoPiecesMissing")],
			cargo: { ...result.cargo, pieces: true },
		};
	}
	// 3.1 Validate Cargo Details
	if (order.cargo_details?.length > 0) {
		// 3.1.1 Validate cargo details pieces
		const detailsPieces = order.cargo_details?.reduce((sum, el) => {
			return sum + Number(0 + el.pieces);
		}, 0);
		if (detailsPieces !== order.cargo_pieces) {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrCargoPiecesInvalid")],
				cargo: { ...result.cargo, details: true },
			};
		}
		// 3.1.2 Validate cargo details weight
		const detailsWeight = order.cargo_details?.reduce((sum, el) => {
			return sum + Number(0 + el.total_weight);
		}, 0);
		if (detailsWeight !== order.cargo_actual_weight) {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrCargoWeightInvalid")],
				cargo: { ...result.cargo, details: true },
			};
		}
	}

	// No method selected
	if (method === 0)
		result = {
			...result,
			isReady: false,
		};
	// Spot Price
	if (method !== 0 && Number(method) === BOOKING_METHOD.SPOT) {
		// No carriers selected
		const countCarriers =
			selectedRates.filter((el) => el.checked).length +
			selectedCarriers.filter((el) => el.checked).length;
		if (countCarriers === 0) {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrSpotCarriers")],
			};
		}
		// Missing Spot Price
		if (spotPrice === "") {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrSpotPrice")],
			};
		}
		// Missing Spot Currency
		if (spotCurrency == null) {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrSpotCurrency")],
			};
		}
		// Missing Spot Expires
		if (spotExpires === "") {
			result = {
				...result,
				isReady: false,
				errors: [...result.errors, t("OperatorOrders.ErrSpotExpires")],
			};
		}
	}
	// Missing Price Check self Carrier
	if (
		method !== 0 &&
		Number(method) === BOOKING_METHOD.DSVROAD &&
		selfCarrier == null
	) {
		result = {
			...result,
			isReady: false,
			errors: [...result.errors, t("OperatorOrders.ErrPriceCheckCarrier")],
		};
	}
	return result;
};
/**
 * Prepare form data for trucker info
 * @param {object} order
 * @returns trucker info form data object
 */
export const toTruckerInfoEditForm = (order) => {
	return {
		driver: order?.driver ?? "",
		license_number: order?.license_number ?? "",
		truck_number: order?.truck_number ?? "",
		plate_number: order?.plate_number ?? "",
		equipment_type: order?.equipment_type ?? "",
		trailer_number: order?.trailer_number ?? "",
	};
};
/**
 * Prepare form data for email list
 * @param {object} emailList
 * @returns email list form data object
 */
export const toEmailListEditForm = (emailList) => {
	return {
		email_send_list:
			emailList?.map((item) => {
				return {
					email: item.email,
					name: item.name,
					lang: toSelectorValue(LANGUAGES.properties, item.lang, false, true),
				};
			}) ?? [],
	};
};
/**
 * Converts `Order` JSON object to order-edit cargo form fields.
 * @param {*} order Order data object.
 * @returns
 */
export const toCargoEditForm = (order, packagings) => {
	const packaging = packagings?.find((e) => e.id === order?.cargo_packaging_id);
	return {
		cargo_pieces: order?.cargo_pieces != null ? order?.cargo_pieces : undefined,
		cargo_packaging_id: packaging
			? { value: packaging.id, label: packaging.name }
			: undefined,
		cargo_actual_weight:
			order?.cargo_actual_weight != null
				? order?.cargo_actual_weight_unit.unit === WEIGHT_UNIT.LBS
					? convertKgToLb(order?.cargo_actual_weight, 2)
					: order?.cargo_actual_weight
				: undefined,

		cargo_actual_weight_unit: toSelectorValue(
			WEIGHT_UNIT.properties,
			order?.cargo_actual_weight_unit,
			true
		),
		cargo_volume: order?.cargo_volume != null ? order?.cargo_volume : undefined,
		cargo_volume_unit: toSelectorValue(
			VOLUME_UNIT.properties,
			order?.cargo_volume_unit ?? order?.cargo_actual_weight_unit,
			true
		),
		cargo_freight_class:
			order?.cargo_freight_class != null
				? toSelectorValue(FREIGHT_CLASS.properties, order?.cargo_freight_class)
				: undefined,
		cargo_description: order?.cargo_description ?? undefined,
		special_handlings_hazmat: order?.special_handlings_hazmat ?? false,
		special_handlings_hazmat_substance:
			order?.special_handlings_hazmat_substance ?? undefined,

		special_handlings_hazmat_group: toSelectorValue(
			SUBSTANCE_GROUP.properties,
			order?.special_handlings_hazmat_class,
			true
		),
		special_handlings_hazmat_class_value:
			order?.special_handlings_hazmat_class_value != null
				? order?.special_handlings_hazmat_class_value
				: undefined,
		special_handlings_hazmat_flashpoint:
			order?.special_handlings_hazmat_flashpoint != null
				? order.special_handlings_hazmat_flashpoint_unit === TEMPERATURE_UNIT.F
					? convertTempCToF(order?.special_handlings_hazmat_flashpoint, 1)
					: order?.special_handlings_hazmat_flashpoint
				: undefined,
		special_handlings_hazmat_flashpoint_unit:
			order?.special_handlings_hazmat_flashpoint_unit
				? toSelectorValue(
						TEMPERATURE_UNIT.properties,
						order?.special_handlings_hazmat_flashpoint_unit,
						true
				  )
				: {
						value: TEMPERATURE_UNIT.properties[2].value,
						label: TEMPERATURE_UNIT.properties[2].code,
				  },
		special_handlings_tempctl: order?.special_handlings_tempctl ?? false,
		special_handlings_tempctl_min:
			order?.special_handlings_tempctl_min != null
				? order.special_handlings_tempctl_unit === TEMPERATURE_UNIT.F
					? convertTempCToF(order?.special_handlings_tempctl_min, 1)
					: order?.special_handlings_tempctl_min
				: undefined,
		special_handlings_tempctl_max:
			order?.special_handlings_tempctl_max != null
				? order.special_handlings_tempctl_unit === TEMPERATURE_UNIT.F
					? convertTempCToF(order?.special_handlings_tempctl_max, 1)
					: order?.special_handlings_tempctl_max
				: undefined,
		special_handlings_tempctl_unit: order?.special_handlings_tempctl_unit
			? toSelectorValue(
					TEMPERATURE_UNIT.properties,
					order?.special_handlings_tempctl_unit,
					true
			  )
			: {
					value: TEMPERATURE_UNIT.properties[2].value,
					label: TEMPERATURE_UNIT.properties[2].code,
			  },
		cargo_details:
			order?.cargo_details?.map((item) => {
				const pkg = packagings?.find((e) => e.id === item.packaging_id);
				return {
					pieces: item.pieces,
					description: item.description,
					packaging_id: pkg ? { value: pkg.id, label: pkg.name } : undefined,
					total_weight:
						item?.total_weight != null
							? item.weight_unit === WEIGHT_UNIT.LBS
								? convertKgToLb(item?.total_weight, 2)
								: item?.total_weight
							: 0,
					weight_unit: toSelectorValue(
						WEIGHT_UNIT.properties,
						item.weight_unit ?? order?.cargo_actual_weight_unit,
						true
					),
					length:
						item?.length != null
							? item.unit === DIMENSION_UNIT.IN
								? convertCmToIn(item?.length, 2)
								: item?.length
							: undefined,
					width:
						item?.width != null
							? item.unit === DIMENSION_UNIT.IN
								? convertCmToIn(item?.width, 2)
								: item?.width
							: undefined,
					height:
						item?.height != null
							? item.unit === DIMENSION_UNIT.IN
								? convertCmToIn(item?.height, 2)
								: item?.height
							: undefined,
					unit: toSelectorValue(DIMENSION_UNIT.properties, item.unit, true),
				};
			}) ?? [],
	};
};

export const filterAccesorials = (accesorials, isPort) => {
	let result = [];
	accesorials.forEach((el) => {
		// Obtenemos los elementos que cunplan con la condición.
		if (el.is_port === isPort && el.cargo_type !== CARGO_TYPE.FCL) {
			result.push({
				value: ACCESSORIALS.properties[`${el.id}`].value,
				label: t(ACCESSORIALS.properties[`${el.id}`].name),
			});
		}
	});
	return result;
};

export const filterLocationTypes = (locationTypes, isPort) => {
	let result = [];
	locationTypes.forEach((el) => {
		// Get elements that match the criteria
		if (el.is_port === isPort && el.cargo_type !== CARGO_TYPE.FCL) {
			result.push({
				value: LOCATION_TYPES.properties[`${el.id}`].value,
				label: t(LOCATION_TYPES.properties[`${el.id}`].name),
			});
		}
	});

	return result;
};

export const getBookedOrderTabs = (order) => {
	if (order.order_count > 1) {
		const childTabs = order.order_requests.map((el) => {
			return { id: el.id, label: el.file_number, active: false };
		});
		return [
			{
				id: order.order_requests[0].id,
				label: order.file_number,
				active: true,
			},
			...childTabs,
		];
	}
	return null;
};

export const getBookedOrderCargoSummary = (order) => {
	let pieces = 0;
	let weight = 0;
	let volume = 0;
	let details = [];
	let hazmat = false;
	let tempctl = false;
	// const cargoSummary = order.order_requests.forEach((el) => {
	// 	pieces += el.cargo_pieces ?? 0;
	// 	weight += el.cargo_actual_weight ?? 0;
	// 	volume += el.cargo_volume ?? 0;
	// 	details = [...details, ...el.cargo_details];
	// 	if (el.special_handlings_hazmat) hazmat = true;
	// 	if (el.special_handlings_tempctl) tempctl = true;
	// });
	return {
		pieces,
		weight,
		weight_unit:
			order.order_requests[0].cargo_actual_weight_unit ?? WEIGHT_UNIT.LBS,
		volume,
		volume_unit: order.order_requests[0].cargo_volume_unit ?? VOLUME_UNIT.CBF,
		hazmat,
		tempctl,
		details,
	};
};
