import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { getOrderCsvReport } from "services/reportServices";

const initialState = {
	isLoading: false,
	filters: {},
	errors: null,
};

/* Get csv order report*/
export const fetchOperatorOrdersReport = createAsyncThunk(
	"operator/fetchOperatorOrdersReport",
	async (payload, { rejectWithValue }) => {
		try {
			const { lang, data } = payload;
			const response = await getOrderCsvReport({ lang, filters: data });
			const date = new Date();
			const fileName = `${format(
				date,
				"yyyy-MM-dd_HH-mm-ss"
			)}_dsvroandlink-orders.csv`;
			const link = document.createElement("a");
			const url = `data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`;
			link.href = url;
			link.download = fileName;
			link.click();
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

export const operatorOrdersReportSlice = createSlice({
	name: "operatorOrdersReport",
	initialState,
	reducers: {
		setOperatorOrdersReportFilters: (state, { payload }) => {
			state.filters = payload;
		},
		setOperatorOrderReportErrors: (state, { payload }) => {
			state.errors = payload.errors ?? null;
		},
		clearOperatorOrdersReportData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOperatorOrdersReport.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchOperatorOrdersReport.fulfilled, (state) => {
				state.isLoading = false;
				state.errors = null;
			})
			.addCase(fetchOperatorOrdersReport.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.errors = payload?.data ?? null;
			});
	},
});

export const {
	setOperatorOrdersReportFilters,
	setOperatorOrderReportErrors,
	clearOperatorOrdersReportData,
} = operatorOrdersReportSlice.actions;

export default operatorOrdersReportSlice.reducer;
