import Loader from "components/Loader";
import {
	CARGO_TYPE,
	CARRIER_MODE,
	CARRIER_TAB,
	CURRENCY,
	RATE_TYPE,
	VOLUME_UNIT,
	WEIGHT_UNIT,
} from "features/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "utils/dateUtils";
import { numberFormat } from "utils/numberUtils";
import {
	fetchAddCarrierRate,
	fetchAllCarrierLanes,
	fetchCarrierRates,
	fetchGetCarrierCustomersCatalog,
	fetchSaveCarrierRate,
	setCarrierRate,
	setCarrierRateAddEdit,
} from "features/admin/carriersSlice";
import CarrierRatesAdd from "components/common/carrier/CarrierRatesAdd";
import Pagination from "components/common/pagination/Pagination";
import { convertKgToLb } from "utils/weightUtils";
import { convertCbmToCbf } from "utils/volumeUtils";
import Filters from "components/common/tables/filters/Filters";
import { useFilterContext } from "components/common/tables/filters/FilterContextProvider";
import FilterContext from "components/common/tables/filters/FilterContext";
import TableHead from "components/common/tables/TableHead";
import { filterTransformToData } from "utils/filtersUtils";
import { toSelector } from "utils/constantsUtils";
import admin_default from "settings/admin_default.json";
import { removeEmptyProps } from "utils/formUtils";
import {
	clearCarrierTemplateData,
	fetchTemplateInsert,
	fetchTemplateParse,
	fetchTemplateParseProcessStatus,
	showCarrierTemplateUpload,
} from "features/admin/carrierTemplateSlice";
import CarrierTemplateForm from "./CarrierTemplateForm";

const tables = admin_default.settings.carrier_rates;

export default function CarrierDetailsRates() {
	const dispatch = useDispatch();
	// Translate
	const { t } = useTranslation();
	// Selectors
	const {
		tab,
		rates,
		isLoadingRates,
		isSavingRate,
		open,
		rate,
		// rateAdd,
		rateEdit,
		isLoadingAllLanes,
		allLanes,
		customersCatalog,
		isLoadingCustomersCatalog,
	} = useSelector((state) => state.carriers);
	const {
		showTemplateForm,
		isUploading,
		parseResponse,
		insertResponse,
		processResponse,
		isProcessing,
		isReadyToLoad,
		completed,
	} = useSelector((state) => state.carrierTemplate);

	const { filterContext } = useFilterContext({
		options: {
			cargo_type: toSelector(CARGO_TYPE.properties, false, true),
			carrier_mode: toSelector(CARRIER_MODE.properties, false, true),
			rate_type: toSelector(RATE_TYPE.properties, false, true),
			volume: toSelector(VOLUME_UNIT.properties, false, true),
			weight: toSelector(WEIGHT_UNIT.properties, false, true),
			lanes: null,
		},
	});

	// Effect runs once on display.
	useEffect(() => {
		if (customersCatalog == null && !isLoadingCustomersCatalog) {
			dispatch(fetchGetCarrierCustomersCatalog({ carrierId: open.id }));
		}
		if (allLanes == null && !isLoadingAllLanes) {
			dispatch(fetchAllCarrierLanes({ carrierId: open.id }));
		}
		if (allLanes != null && filterContext.options?.carrier_lane == null) {
			filterContext.setOptions({
				carrier_lane: allLanes?.map((el) => {
					return {
						value: el.id,
						label: el.name,
					};
				}),
				...filterContext.options,
			});
		}
	}, [
		allLanes,
		dispatch,
		isLoadingAllLanes,
		customersCatalog,
		filterContext,
		open.id,
		isLoadingCustomersCatalog,
	]);

	// Handlers
	const handleRateAddClick = () => {
		// dispatch(setCarrierRateAddEdit({ rateAdd: true }));
		dispatch(showCarrierTemplateUpload(true));
	};
	// const handleCancelAddClick = () => {
	// 	dispatch(showCarrierTemplateUpload());
	// };
	const handleTemplateParseUpload = (data) => {
		dispatch(fetchTemplateParse(data));
	};
	const handleTemplateInsertUpload = (data) => {
		dispatch(fetchTemplateInsert(data));
	};
	const handleTemplateUploadParseProcess = () => {
		const public_id =
			parseResponse != null
				? parseResponse.public_id
				: insertResponse != null
				? insertResponse.public_id
				: null;
		if (public_id != null) {
			dispatch(fetchTemplateParseProcessStatus(public_id));
		}
	};
	const handleTemplateUploadCancel = () => {
		dispatch(showCarrierTemplateUpload());
		dispatch(clearCarrierTemplateData());
	};
	const handleRateClick = (rate) => {
		dispatch(setCarrierRate({ rate: rate }));
		dispatch(setCarrierRateAddEdit({ rateEdit: true }));
	};
	const handlePageClick = (page) => {
		dispatch(
			fetchCarrierRates({
				carrierId: open.id,
				page,
				filters: filterTransformToData(filterContext.filters, tables),
			})
		);
	};
	const onFiltersChanged = (data, e) => {
		e.preventDefault();
		dispatch(
			fetchCarrierRates({
				carrierId: open.id,
				page: 1,
				filters: filterTransformToData(data, tables),
			})
		);
	};
	const handleTemplateComplete = () => {
		dispatch(fetchCarrierRates({ carrierId: open.id }));
		dispatch(clearCarrierTemplateData());
	}

	// Render
	if (tab !== CARRIER_TAB.RATES) return null;
	if (isLoadingRates) {
		return <Loader msg={t("Common.Loading")} />;
	}

	if (rateEdit) {
		return (
			<div className="tab-content details">
				<CarrierRatesAdd
					onSave={(data) => {
						dispatch(
							fetchSaveCarrierRate({
								carrierId: open.id,
								rateId: rate.id,
								data: data,
							})
						);
					}}
					onAdd={(data) => {
						dispatch(
							fetchAddCarrierRate({
								carrierId: open.id,
								data: removeEmptyProps(data),
							})
						);
					}}
					onClose={() => {
						dispatch(setCarrierRateAddEdit());
						dispatch(setCarrierRate());
					}}
					currency={open.currency}
					rate={rateEdit ? rate : null}
					allLanes={allLanes}
					isSavingRate={isSavingRate}
					customers={customersCatalog}
				/>
			</div>
		);
	}
	if (showTemplateForm) {
		return (
			<div className="tab-content details">
				<CarrierTemplateForm
					onCancel={handleTemplateUploadCancel}
					onUpload={handleTemplateParseUpload}
					onLoadRates={handleTemplateInsertUpload}
					onUpdateProcessing={handleTemplateUploadParseProcess}
					onComplete={handleTemplateComplete}
					parseResponse={parseResponse}
					processResponse={processResponse}
					isProcessing={isProcessing}
					isUploading={isUploading}
					isReadyToLoad={isReadyToLoad}
					carrier_id={open.id}
					completed={completed}
				/>
			</div>
		);
	}
	const formatWeightRange = (el) => {
		if (el.per_weight) {
			if (el.weight_unit === WEIGHT_UNIT.LBS)
				return `${numberFormat(
					convertKgToLb(el.weight_min),
					0
				)} - ${numberFormat(convertKgToLb(el.weight_max), 0)} ${
					WEIGHT_UNIT.properties[el.weight_unit].code
				}`;
			return `${numberFormat(el.weight_min, 0)} - ${numberFormat(
				el.weight_max,
				0
			)} ${WEIGHT_UNIT.properties[el.weight_unit].code}`;
		} else {
			return "-";
		}
	};
	const formatVolumeRange = (el) => {
		if (el.per_volume) {
			if (el.volume_unit === VOLUME_UNIT.CBF)
				return `${numberFormat(
					convertCbmToCbf(el.volume_min),
					0
				)} - ${numberFormat(convertCbmToCbf(el.volume_max), 0)} ${
					VOLUME_UNIT.properties[el.volume_unit].code
				}`;
			return `${numberFormat(el.volume_min, 0)} - ${numberFormat(
				el.volume_max,
				0
			)} ${VOLUME_UNIT.properties[el.volume_unit].code}`;
		} else {
			return "-";
		}
	};

	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={rates?.current_page ?? 1}
				last={rates?.last_page ?? 1}
				onRefresh={() => {
					handlePageClick(rates?.current_page ?? 1);
				}}
			/>
		);
	};

	return (
		<div className="tab-content details">
			<div className="details-subtitle">
				<div>{t("AdminCarriers.Rates")}</div>
				<div onClick={handleRateAddClick}>
					<span className="icon-add"></span>
				</div>
			</div>
			{renderPagination()}
			<div className="dsv-table">
				<FilterContext.Provider value={filterContext}>
					<Filters onFiltersChanged={onFiltersChanged} />
					<table cellSpacing="0" cellPadding="0">
						<TableHead tables={tables} />
						<tbody>
							{rates?.data?.map((el, index) => {
								const activeProps =
									el?.id === filterContext.activeTable?.id
										? { className: "active" }
										: {};
								return (
									<tr
										{...activeProps}
										key={index}
										onClick={() => handleRateClick(el, index)}
									>
										<td>{t(CARGO_TYPE.properties[el.cargo_type].name)}</td>
										<td>{t(CARRIER_MODE.properties[el.carrier_mode].name)}</td>
										<td>{t(RATE_TYPE.properties[el.rate_type].name)}</td>
										<td className="right">{formatVolumeRange(el)}</td>
										<td className="right">{formatWeightRange(el)}</td>
										<td className="right">
											{el.per_piece
												? `${numberFormat(el.piece_min, 0)} - ${numberFormat(
														el.piece_max,
														0
												  )}`
												: "-"}
										</td>
										<td>{el.carrier_lane?.name}</td>
										<td>
											{el.equipment_type != null ? el.equipment_type.name : "-"}
										</td>
										<td className="right">{`$${numberFormat(
											el.rate * el.exchange_rate,
											2
										)} ${CURRENCY.properties[el.currency].code}`}</td>
										<td>{formatDate(el.created_at, t("Dates.DateTime"))}</td>
										<td>{formatDate(el.updated_at, t("Dates.DateTime"))}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</FilterContext.Provider>
			</div>
			{renderPagination()}
		</div>
	);
}
