/**
 *
 * @param {*} oldDetails
 * @param {*} newDetailsS
 * @returns
 */
export const processZoneChanges = (oldDetails, newDetails) => {

  var result = {
    insert: difference([...newDetails], [...oldDetails]),
    update: [],
    delete: difference([...oldDetails], [...newDetails]).map((x) => x.id),
  };

  return result;
};

/**
 *
 * @param {*} oldDetails
 * @param {*} newDetails
 * @returns
 */
export const processZoneExclusionsChanges = (oldDetails, newDetails) => {
  
  //
  const oldZipCodes = oldDetails.filter((item) => {
    return (
      item.postal_data?.zipcode !== null && item.postal_data?.zipcode !== ""
    );
  });

  const newZipCodes = newDetails.filter((item) => {
    return (
      item.postal_data?.zipcode !== null && item.postal_data?.zipcode !== ""
    );
  });

  const oldCities = oldDetails.filter((item) => {
    return (
      item.postal_data?.zipcode === null || item.postal_data?.zipcode === ""
    );
  });
  const newCities = newDetails.filter((item) => {
    return (
      item.postal_data?.zipcode === null || item.postal_data?.zipcode === ""
    );
  });

 
  var result = {
    insert: [
      ...zipDifference([...newZipCodes], [...oldZipCodes]),
      ...difference([...newCities], [...oldCities]),
    ],
    update: [],
    delete: [
      ...zipDifference([...oldZipCodes], [...newZipCodes]),
      ...difference([...oldCities], [...newCities]),
    ].map((x) => x.id),
  };


  return result;
};

/**
 *
 * @param {*} array1
 * @param {*} array2
 * @returns
 */
export const difference = (array1, array2) => {
  return array1.filter((object1) => {
    return !array2?.some((object2) => {
      return (
        object1.state_number === object2.state_number &&
        object1.city_number === object2.city_number
      );
    });
  });
};

/**
 *
 * @param {*} array1
 * @param {*} array2
 * @returns
 */
export const zipDifference = (array1, array2) => {
  return array1.filter((object1) => {
    return !array2?.some((object2) => {
      return object1.zipcode === object2.zipcode;
    });
  });
};
