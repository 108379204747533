import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccesorials } from "services/catalogsServices";

/**
 * Define the intial state.
 */
const initialState = { isLoading: false, accesorials: null };

/**
 * Async reducer function to fetch accesorials data.
 */
export const fetchAccesorials = createAsyncThunk(
  "catalogs/accesorials",
  async () => {
    return await getAccesorials();
  }
);

/**
 * Create and configure slice.
 */
const accesorialsSlice = createSlice({
  name: "accesorials",
  initialState,
  reducers: {
		clearAccessorialsData: () => initialState,
	},
  /**
   * Use extra reducers for async executions.
   * @param {*} builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccesorials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAccesorials.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.accesorials = payload.data.data;
      })
      .addCase(fetchAccesorials.rejected, (state) => {
        state.isLoading = false;
				state.accesorials = [];
      });
  },
});
export const {clearAccessorialsData} = accesorialsSlice.actions;
/**
 * Export slice.
 */
export default accesorialsSlice.reducer;
