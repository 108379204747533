import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleShowMobile } from "features/navigation/navigationSlice";

export default function SidebarItem({ el, icon, text, action }) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Location
	const location = useLocation();
	const navigate = useNavigate();
	// Handlers
	const handleClick = () => {
		if (action != null) {
			action();
		}
		dispatch(toggleShowMobile({ showMobile: true }));
		navigate(el);
	};
	const getProps = () => {
		if (location.pathname.substring(0, el.length) === el) {
			return { className: "active" };
		}
		return { onClick: () => handleClick() };
	};
	const itemProps = getProps();
	return (
		<div {...itemProps}>
			<div>
				<div>
					<span className={icon}></span>
				</div>
				<div>{t(text)}</div>
			</div>
		</div>
	);
}
