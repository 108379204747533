import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderNotifyPartiesForm from "components/common/order/OrderNotifyPartiesForm";
import {
	fetchOperatorOrderUpdateNotifyParties,
	setOperatorOrderEdit,
} from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";

const OperatorOrderDetailsNotifyPartiesEdit = () => {
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, bookedOrder, orderEdit, isSavingEmailList } = useSelector(
		(state) => state.operatorOrders
	);
	// Handlers
	const handleSaveEmailData = (data) => {
		dispatch(
			fetchOperatorOrderUpdateNotifyParties({
				id: openOrder.id,
				bookedOrderId: bookedOrder.id,
				data: { ...data, public_id: bookedOrder.public_id },
			})
		);
	};
	const handleCancelEdit = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: null }));
	};
	// Render
	if (orderEdit !== ORDER_EDIT.EMAIL_LIST) return null;
	return (
		<OrderNotifyPartiesForm
			order={bookedOrder}
			onSave={handleSaveEmailData}
			onCancel={handleCancelEdit}
			isSaving={isSavingEmailList}
		/>
	);
};

export default OperatorOrderDetailsNotifyPartiesEdit;
