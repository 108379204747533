import OrderCargo from "components/common/order/OrderCargo";
import { ORDER_EDIT } from "features/constants";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function OperatorOrderDetailsCargo() {
	const dispatch = useDispatch();
	// Selectors
	const {
		openOrder,
		orderEdit,
		isLoadingOpen,
		bookedOrder,
		bookedTab,
		isLoadingBooked,
	} = useSelector((state) => state.operatorOrders);
	const { packagings } = useSelector((state) => state.packagings);
	// Handlers
	const handleEditClick = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.CARGO }));
	};
	// Render
	if (openOrder == null) return null;
	if (openOrder.booked_orders?.length > 0) {
		const order =
			bookedOrder != null
				? bookedTab === 0
					? bookedOrder?.order_requests[bookedTab]
					: bookedOrder?.order_requests[bookedTab - 1] ?? null
				: null;
		return (
			<OrderCargo
				order={order}
				edit={orderEdit}
				isLoading={isLoadingOpen === true || isLoadingBooked === true}
				packagings={packagings}
				onEdit={handleEditClick}
				booked={true}
				bookedOrder={bookedOrder}
				activeTab={bookedTab}
			/>
		);
	}
	return (
		<OrderCargo
			order={openOrder}
			edit={orderEdit}
			isLoading={isLoadingOpen}
			packagings={packagings}
			onEdit={handleEditClick}
			booked={false}
			bookedOrder={null}
			activeTab={null}
		/>
	);
}
