import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import SortableList from "components/common/sortableList/SortableList";

export default function TableSettings({
  settings,
  onClose,
  onSave,
  isLoading,
	isSaving,
}) {
  // Selector
  // isSavingSettings

  const { t } = useTranslation();
	// State
	const [items, setItems] = useState(settings);
	const handleSortChange = (items) => {
		setItems(items);
	};
  // Handlers
  const handleClose = () => {
    onClose();
  };
  const handleSave = () => {
    // Prepare to save settings
    onSave(items);
  };
  // Render
  const renderLoader = () => {
    if (isLoading || isSaving) {
      return (
        <div className="order-block">
          <Loader />
        </div>
      );
    }
  };
  const renderDraggable = () => {
    if (!isLoading && !isSaving) {
      return (
        <>
          <div className="order-block">
            <SortableList data={settings} onChange={handleSortChange} />
          </div>
          <div className="order-block">
            <div className="action-buttons">
              <div>
                <div className="button" onClick={handleClose}>
                  {t("Buttons.Cancel")}
                </div>
                <div className="button main" onClick={handleSave}>
                  {t("Buttons.Save")}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div className="order-settings">
      <div className="order-actions-title">
        <div>
          <div>
            <span className="icon-expand"></span>
          </div>
          <div className="action-title">{t("Common.TableSettings")}</div>
          <div onClick={handleClose}>
            <span className="icon-close"></span>
          </div>
        </div>
      </div>
      {renderLoader()}
      {renderDraggable()}
    </div>
  );
}
