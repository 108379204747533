import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	CARGO_TYPE,
	CURRENCY,
	SHIPMENT_TYPE,
	SPOT_PRICE_STATUS,
} from "features/constants";
import { formatDate, renderDate } from "utils/dateUtils";
import {
	fetchCarrierOfferDetails,
	fetchCarrierOffers,
	setCarrierOpenedOffer,
} from "features/carrier/carrierOffersSlice";
import Loader from "components/Loader";
import CarrierOfferRequestDetails from "./CarrierOfferRequestDetails";
import { numberFormat } from "utils/numberUtils";

export default function CarrierOfferRequests() {
	// Dispatch
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { list, isLoading, opened, isOpening } = useSelector(
		(state) => state.carrierOffers
	);
	// State
	const [showDetails, setShowDetails] = useState(false);
	const [collapse, setCollapse] = useState(false);
	// Effect
	useEffect(() => {
		if (!isLoading && list == null) {
			dispatch(fetchCarrierOffers());
		}
		if (opened == null && !isOpening) {
			setShowDetails(false);
		}
	}, [isLoading, list, opened, isOpening, dispatch]);
	// Handlers
	const handleOrderClick = (id) => {
		dispatch(fetchCarrierOfferDetails({ id }));
		setShowDetails(true);
	};
	const handleOrderClose = () => {
		dispatch(setCarrierOpenedOffer({ opened: null }));
		setShowDetails(false);
		setCollapse(false);
	};
	const handleOrderCollapse = () => {
		setCollapse(true);
	};
	const handleOrderExpand = () => {
		setCollapse(false);
	};
	// Render
	const getCssClass = () => {
		if (showDetails) {
			if (collapse) {
				return "order-container show-details collapse";
			}
			return "order-container show-details";
		}
		return "order-container";
	};
	const cssClass = getCssClass();
	const renderLoader = () => {
		if (isLoading) {
			return (
				<div>
					<Loader msg={t("Common.Loading")} />
				</div>
			);
		}
	};
	const renderTable = () => {
		if (!isLoading && list != null) {
			return (
				<div>
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Tables.FileNumber")}</th>
									<th>{t("Tables.ReferenceNumber")}</th>
									<th>{t("Tables.MasterBill")}</th>
									<th>{t("Tables.HouseBill")}</th>
									<th>{t("Tables.Status")}</th>
									<th className="center">{t("ShipmentTypes.Title")}</th>
									<th>{t("CargoTypes.Title")}</th>
									<th>{t("Tables.Forwarder")}</th>
									<th className="center">{t("Common.Price")}</th>
									<th className="center">{t("Tables.CreatedAt")}</th>
									<th className="center">{t("Tables.ExpiresAt")}</th>
									<th className="right">{t("Tables.Pieces")}</th>
									<th className="right">{t("Tables.Weight")}</th>
									<th className="center">{t("Tables.EstimatedReady")}</th>
									<th className="center">{t("Tables.EstimatedClose")}</th>
									<th className="center">{t("Tables.RequestedDelivery")}</th>
								</tr>
							</thead>
							<tbody>
								{list.map((el, index) => {
									return (
										<tr onClick={() => handleOrderClick(el.id)} key={index}>
											<td>{el.file_number}</td>
											<td>{el.alternate_reference_number}</td>
											<td>{el.master_bill_number}</td>
											<td>{el.house_bill_number}</td>
											<td className="center">
												{t(
													SPOT_PRICE_STATUS.properties[el.spot_price_status]
														.name
												)}
											</td>
											<td className="center">
												{t(
													SHIPMENT_TYPE.properties[el.transport_shipment_type]
														.name
												)}
											</td>
											<td>
												{t(CARGO_TYPE.properties[el.transport_cargo_type].name)}
											</td>
											<td>{el.forwarder_name}</td>
											<td className="right">
												{el.price != null
													? `$${numberFormat(el.price * el.exchange_rate, 2)} ${CURRENCY.properties[el.currency].code}`
													: "-"}
											</td>
											<td className="center">
												{renderDate(el.created_at, t("Dates.DateTime"))}
											</td>
											<td className="center">
												{renderDate(el.expires_at, t("Dates.DateTime"))}
											</td>
											<td className="right">
												{el.cargo_pieces ?? <>&nbsp;</>}
											</td>
											<td className="right">
												{/* {numberFormat(el.cargo_actual_weight * 1, 2)}{" "}
												{
													WEIGHT_UNIT.properties[
														master.cargo_actual_weight_unit
													].code
												} */}
												&nbsp;
											</td>
											<td className="center">
												{formatDate(el.estimated_ready, t("Dates.DateTime"))}
											</td>
											<td className="center">
												{formatDate(el.estimated_close, t("Dates.DateTime"))}
											</td>
											<td className="center">
												{formatDate(
													el.requested_delivery,
													t("Dates.DateTime"),
													false
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={3}>
										{t("Pagination.Found", {count: list.length})}
									</td>
									<td colSpan={13}>&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			);
		}
	};
	return (
		<div id="default">
			<div className={cssClass}>
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("CarrierOfferRequests.Title")}</h1>
							</div>
						</div>
						{renderLoader()}
						{renderTable()}
					</section>
				</div>
				<CarrierOfferRequestDetails
					onClose={handleOrderClose}
					onCollapse={handleOrderCollapse}
					onExpand={handleOrderExpand}
					collapse={collapse}
				/>
			</div>
		</div>
	);
}
