import React from "react";
import { useTranslation } from "react-i18next";
import { CARGO_TYPE, SHIPMENT_TYPE, ORDER_EDIT } from "features/constants";
import OrderRefs from "components/common/order/OrderRefs";
import OrderTabs from "components/common/order/OrderTabs";
import Loader from "components/Loader";

export default function OrderHeader({
	order,
	edit,
	isLoading,
	consolidate,
	tabs,
	activeTab,
	onClose,
	onCollapse,
	onExpand,
	onEdit,
	onTabClick,
	collapse,
	profile,
	booked,
	bookedOrder,
	file_number,
}) {
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleCloseClick = () => {
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handleTabClick = (tab) => {
		onTabClick(tab);
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };
	if (isLoading) {
		return (
			<div>
				<div className="order-block">
					<Loader msg={t("Common.Loading")} />
				</div>
				<div className="order-refs">&nbsp;</div>
			</div>
		);
	}
	const renderEditButton = () => {
		if (!booked && edit == null && !consolidate) {
			return (
				<div>
					<span className="icon-edit" onClick={() => onEdit()}></span>
				</div>
			);
		}
		return null;
	};
	const renderOrderFile = () => {
		if (!isLoading && order != null) {
			return (
				<div className="order-file">
					<div>
						<div>
							<span {...collapseProps}></span>
						</div>
						<div className="file-number">
							<div>{consolidate ? t("Common.NewOrder") : file_number}</div>
							<div>
								<em>{t("Tables.Transport")}</em>{" "}
								{SHIPMENT_TYPE.properties[order.transport_shipment_type].code} /{" "}
								{t(CARGO_TYPE.properties[order.transport_cargo_type].name)}
							</div>
						</div>
						{renderEditButton()}
						<div>
							<span className="icon-close" onClick={handleCloseClick}></span>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderOrderRefs = () => {
		if (
			!isLoading &&
			order != null &&
			((booked && ((tabs != null && activeTab !== 0) || tabs == null)) ||
				!booked)
		) {
			return (
				<OrderRefs
					order={order}
					onEdit={onEdit}
					consolidate={consolidate}
					edit={edit}
					profile={profile}
				/>
			);
		}
		return null;
	};
	const renderTabs = () => {
		if (!isLoading && (consolidate || (booked && tabs != null))) {
			return (
				<OrderTabs tabs={tabs} activeTab={activeTab} onClick={handleTabClick} />
			);
		}
	};
	if (edit != null && edit === ORDER_EDIT.HEADER) {
		return null;
	}
	return (
		<div>
			{renderOrderFile()}
			{renderTabs()}
			{renderOrderRefs()}
		</div>
	);
}
