import { createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";
import { LANGUAGES as l } from "features/constants";

const initialState = {
	active: 1,
	lang: { value: 1, name: "Languages.en", code: "en", header: "en-US" },
	isOpenLang: false,
	showMobile: false,
	showUser: false,
};

const navigation = createSlice({
	name: "navigation",
	initialState,
	reducers: {
		setLanguage: (state, action) => {
			state.lang = action.payload.lang;
		},
		toggleShowLanguageSelector: (state, { payload }) => {
			state.isOpenLang = payload.isOpenLang;
		},
		toggleShowUser: (state, { payload }) => {
			state.showUser = !payload.showUser;
		},
		toggleShowMobile: (state, { payload }) => {
			state.showMobile = !payload.showMobile;
		},
	},
});

export const {
	setLanguage,
	toggleShowLanguageSelector,
	toggleShowUser,
	toggleShowMobile,
} = navigation.actions;

export const setActiveLanguage = (lang) => async (dispatch) => {
	if (i18n.language !== lang) {
		i18n.changeLanguage(lang);
	}
	const newLang = l.properties[l[lang.toUpperCase()]];
	dispatch(setLanguage({ lang: newLang }));
};

export const setLangFromProfile = (langId) => {
	return (dispatch) => {
		const lang = l.properties[langId];
		i18n.changeLanguage(lang.code);
		dispatch(setLanguage({ lang }));
	};
};

export default navigation.reducer;
