import React from "react";

import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

export default function OrderSpotPrice({ list, isLoading }) {
	// Translation
	const { t } = useTranslation();
	if (isLoading) {
		return (
			<div className="order-book-block">
				<div className="order-subtitle">
					<div>{t("OperatorOrders.SubmittedTo")}</div>
				</div>
				<Loader />
			</div>
		);
	}
	if (list != null) {
		return (
			<div className="order-book-block">
				<div className="order-subtitle">
					<div>{t("OperatorOrders.SubmittedTo")}</div>
				</div>
				<div className="book-rates-table">
					<table cellPadding="0" cellSpacing="0">
						<thead>
							<tr>
								<th>{t("Common.Carrier")}</th>
							</tr>
						</thead>
						<tbody>
							{list.map((el, index) => {
								return (
									<tr key={index}>
										<td>{el.carrier_name}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
	return null;
}
