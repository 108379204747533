import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_EDIT } from "features/constants";
import {
	setOperatorBookedOrderTab,
	setOperatorOrderEdit,
} from "features/operator/operatorOrdersSlice";
import { setOperatorConsolidationActiveTab } from "features/operator/operatorOrderConsolidationSlice";
import OrderHeader from "components/common/order/OrderHeader";

export default function OperatorOrderDetailsHeader({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const dispatch = useDispatch();
	// Selectors
	const {
		isLoadingOpen,
		openOrder,
		orderEdit,
		isLoadingBooked,
		bookedOrder,
		bookedTab,
		bookedTabs,
		consolidate,
	} = useSelector((state) => state.operatorOrders);
	const { tabs, activeTab } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	// Handlers
	const handleEditClick = () => {
		if (bookedOrder == null) {
			dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.HEADER }));
		}
	};
	const handleTabClick = (tab) => {
		if (bookedOrder != null) {
			dispatch(setOperatorBookedOrderTab({ bookedTab: tab }));
		} else {
			dispatch(setOperatorConsolidationActiveTab({ activeTab: tab }));
		}
	};
	// Render
	if (openOrder == null) return null;
	if (openOrder.booked_orders?.length > 0) {
		const order =
			bookedOrder != null
				? bookedTab === 0
					? bookedOrder?.order_requests[bookedTab]
					: bookedOrder?.order_requests[bookedTab - 1] ?? null
				: null;
		return (
			<OrderHeader
				order={order}
				edit={orderEdit}
				isLoading={isLoadingOpen === true || isLoadingBooked === true}
				consolidate={consolidate}
				tabs={bookedOrder?.order_count > 1 ? bookedTabs : null}
				activeTab={bookedTab}
				onClose={onClose}
				onCollapse={onCollapse}
				onExpand={onExpand}
				onEdit={handleEditClick}
				onTabClick={handleTabClick}
				collapse={collapse}
				booked={true}
				bookedOrder={bookedOrder}
				file_number={bookedOrder?.file_number}
			/>
		);
	}
	return (
		<OrderHeader
			order={openOrder}
			edit={orderEdit}
			isLoading={isLoadingOpen}
			consolidate={consolidate}
			tabs={tabs}
			activeTab={activeTab}
			onClose={onClose}
			onCollapse={onCollapse}
			onExpand={onExpand}
			onEdit={handleEditClick}
			onTabClick={handleTabClick}
			collapse={collapse}
			booked={false}
			bookedOrder={null}
			file_number={openOrder?.file_number}
		/>
	);
}
