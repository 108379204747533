import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export default function Pagination({ onClick, current, last, onRefresh }) {
	// Translation
	const { t } = useTranslation();

	// States
	const firstEnabled = (current ?? 1) !== 1;
	const prevEnabled = current > 1;
	const nextEnabled = current < last;
	const lastEnabled = current !== last;

	// Render
	return (
		<div className="pagination">
			<div>
				<span
					className={classNames("icon-page-first", {
						disabled: !firstEnabled,
					})}
					onClick={() => {
						if (firstEnabled) onClick(1);
					}}
				></span>
			</div>
			<div>
				<span
					className={classNames("icon-page-prev", {
						disabled: !prevEnabled,
					})}
					onClick={() => {
						if (prevEnabled) onClick(current - 1);
					}}
				></span>
			</div>
			<div className="pagination-info">
				{t("Common.Pages", { current: current, last: last })}
			</div>
			<div>
				<span
					className={classNames("icon-page-next", {
						disabled: !nextEnabled,
					})}
					onClick={() => {
						if (nextEnabled) onClick(current + 1);
					}}
				></span>
			</div>
			<div>
				<span
					className={classNames("icon-page-last", {
						disabled: !lastEnabled,
					})}
					onClick={() => {
						if (lastEnabled) onClick(last);
					}}
				></span>
			</div>
			{onRefresh != null && (
				<div>
					<div className="button refresh main" onClick={() => onRefresh()}>
						<span className="icon-refresh inverted"></span>
						{t("Buttons.Refresh")}
					</div>
				</div>
			)}
		</div>
	);
}
