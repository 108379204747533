import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getQualifications } from "services/catalogsServices";

/**
 * Define the intial state.
 */
const initialState = { isLoading: false, qualifications: null };

/**
 * Async reducer function to fetch qualifications data.
 */
export const fetchQualifications = createAsyncThunk(
  "catalogs/qualifications",
  async () => {
    return await getQualifications();
  }
);

/**
 * Create and configure slice.
 */
const qualificationsSlice = createSlice({
  name: "qualifications",
  initialState,
  reducers: {
		clearQualificationsData: () => initialState,
	},
  /**
   * Use extra reducers for async executions.
   * @param {*} builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchQualifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchQualifications.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.qualifications = payload.data.data;
      })
      .addCase(fetchQualifications.rejected, (state) => {
        state.isLoading = false;
        state.qualifications = [];
      });
  },
});

export const {clearQualificationsData} = qualificationsSlice.actions;

/**
 * Export slice.
 */
export default qualificationsSlice.reducer;
