import Loader from "components/Loader";
import { setCarrierQualificationsEdit } from "features/admin/carriersSlice";
import { CARRIER_TAB } from "features/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CarrierDetailsQualificationsEdit from "./CarrierDetailsQualificationsEdit";

export default function CarrierDetailsQualifications() {
  const dispatch = useDispatch();
  // Translate
  const { t } = useTranslation();
  // Selectors
  const { tab, open, qualificationsEdit, isLoadingQualifications } =
    useSelector((state) => state.carriers);

  // Handlers
  const handleEditClick = () => {
    dispatch(setCarrierQualificationsEdit(true));
  };
  // Render

  if (tab !== CARRIER_TAB.QUALIFICATIONS) return null;
  if (isLoadingQualifications) {
    return <Loader msg={t("Common.Loading")} />;
  }
  if (qualificationsEdit) {
    return <CarrierDetailsQualificationsEdit carrier={open} />;
  }
  const styles = { minWidth: "160px" };
  return (
    <div className="tab-content details cascade">
      <div className="dsv-table qualification-list">
        <div className="details-subtitle">
          <div>{t("Common.Qualifications")}</div>
          <div onClick={handleEditClick}>
            <span className="icon-edit"></span>
          </div>
        </div>

        <table cellSpacing="0" cellPadding="0" style={styles}>
          <thead>
            <tr>
              <th>{t("Common.Name")}</th>
            </tr>
          </thead>
          <tbody>
            {open?.qualifications?.map((el, index) => {
              return (
                <tr key={index}>
                  <td>{el.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
