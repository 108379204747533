import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCarriersCatalog } from "services/adminCarriersServices";

const initialState = {
	isLoading: false,
	list: null,
};

/* Fetch carrier catalog */
export const fetchCarrierCatalog = createAsyncThunk(
	"carrierCatalog/fetchCarrierCatalog",
	async () => {
		const response = await getCarriersCatalog();
		return response;
	}
);

const carrierCatalog = createSlice({
	name: "carrierCatalog",
	initialState,
	reducers: {
		clearCarrierCatalogData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCarrierCatalog.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchCarrierCatalog.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
			})
			.addCase(fetchCarrierCatalog.rejected, (state) => {
				state.isLoading = false;
				state.list = [];
			});
	},
});

export const { clearCarrierCatalogData } = carrierCatalog.actions;

export default carrierCatalog.reducer;