import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOOKED_STATUS, BOOKING_METHOD } from "features/constants";
import { numberFormat } from "utils/numberUtils";
import {
	clearCarrierHistoryEmailSent,
	fetchCarrierHistoryChangeStatus,
	fetchCarrierHistorySendEmailToOperator,
	fetchCarrierHistoryUploadDoc,
	setCarrierHistoryShowUpload,
	setCarrierHistoryStatusError,
} from "features/carrier/carrierHistorySlice";
import OrderLog from "components/common/order/OrderLog";
import OrderDocs from "components/common/order/OrderDocs";
import Loader from "components/Loader";
import CarrierOrderActions from "components/common/order/CarrierOrderActions";
import OrderDocsForm from "components/common/order/OrderDocsForm";

export default function CarrierOrderHistoryDetailsActions({
	onClose,
	onCollapse,
	collapse,
	onExpand,
}) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const {
		opened,
		isOpening,
		openedLog,
		docs,
		isUploading,
		showUpload,
		isSendingEmail,
		isEmailSent,
		isChangingStatus,
		changeStatusError,
	} = useSelector((state) => state.carrierHistory);
	// State
	const [showStatusChange, setShowStatusChange] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	// Handlers
	const handleCloseClick = () => {
		handleCancelAction();
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handleClearEmailSent = () => {
		dispatch(clearCarrierHistoryEmailSent());
		setShowEmail(false);
	};
	const handleChangeStatusClick = (payload) => {
		dispatch(setCarrierHistoryStatusError({ changeStatusError: null }));
		dispatch(fetchCarrierHistoryChangeStatus({ ...payload }));
	};
	const handleCancelAction = () => {
		setShowStatusChange(false);
		setShowEmail(false);
		dispatch(setCarrierHistoryStatusError({ changeStatusError: null }));
	};
	const handleShowEmail = () => {
		setShowEmail(!showEmail);
		setShowStatusChange(false);
	};
	const handleShowStatusChange = () => {
		setShowStatusChange(!showStatusChange);
		setShowEmail(false);
	};
	const handleShowUpload = (show) => {
		dispatch(setCarrierHistoryShowUpload({ showUpload: show }));
	};
	const handleUpload = (file, type) => {
		// Upload.
		dispatch(
			fetchCarrierHistoryUploadDoc({
				booked_order_id: opened.id,
				type: type,
				file: file,
			})
		);
	};
	const handleSendEmail = (payload) => {
		dispatch(fetchCarrierHistorySendEmailToOperator(payload));
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };
	const renderOrderInfo = () => {
		if (opened != null) {
			return (
				<div className="order-block">
					<div className="flex-table single-line remove-bottom-space">
						<div>
							<div>
								<div>{t("Common.Price")}</div>
								<div>
									{opened.price != null
										? `$${numberFormat(opened.price * opened.exchange_rate, 2)}`
										: "-"}
								</div>
							</div>
							<div>
								<div>{t("BookingMethods.Title")}</div>
								<div>
									{t(BOOKING_METHOD.properties[opened.booked_method].name)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderBookedInfo = () => {
		if (
			opened != null &&
			opened.book_status !== BOOKED_STATUS.PENDING &&
			opened.book_status !== BOOKED_STATUS.SUBMITTED
		) {
			return (
				<>
					<div className="order-actions-title">
						<div>
							<div>
								<span {...collapseProps}></span>
							</div>
							<div className="action-title">{t("OperatorOrders.Booked")}</div>
							<div>
								<span className="icon-close" onClick={handleCloseClick}></span>
							</div>
						</div>
					</div>
					{renderOrderInfo()}
				</>
			);
		}
		return null;
	};
	const renderActions = () => {
		if (opened != null) {
			return (
				<CarrierOrderActions
					order={opened}
					showEmail={showEmail}
					showStatusChange={showStatusChange}
					isChangingStatus={isChangingStatus}
					isSendingEmail={isSendingEmail}
					isEmailSent={isEmailSent}
					changeStatusError={changeStatusError}
					onShowEmail={handleShowEmail}
					onShowStatusChange={handleShowStatusChange}
					onCancelAction={handleCancelAction}
					onStatusChange={handleChangeStatusClick}
					onClearEmailSent={handleClearEmailSent}
					onSendEmail={handleSendEmail}
				/>
			);
		}
	};
	const renderLog = () => {
		if (opened != null) {
			const log = openedLog ?? [];
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("Common.StatusLog")}</div>
					</div>
					<OrderLog log={log} />
				</div>
			);
		}
	};
	const renderDocuments = () => {
		if (opened != null) {
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("Common.Documents")}</div>
						<div>
							<span
								className="icon-add"
								onClick={() => handleShowUpload(true)}
							></span>
						</div>
					</div>
					{showUpload && (
						<OrderDocsForm
							onCancel={() => handleShowUpload(false)}
							onUpload={handleUpload}
							isLoading={isUploading}
							bookedOrder={opened}
						/>
					)}
					{docs != null ? <OrderDocs docs={docs} /> : <></>}
				</div>
			);
		}
	};
	if (isOpening) {
		return (
			<div className="order-actions">
				<div className="order-block">
					<Loader msg={t("Common.Loading")} />
				</div>
			</div>
		);
	}
	if (!isOpening && opened != null) {
		return (
			<div className="order-actions">
				{renderBookedInfo()}
				{renderActions()}
				{renderLog()}
				{renderDocuments()}
			</div>
		);
	}
	return null;
}
