import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTemplateProcessStatus, insertTemplateUpload, parseTemplateUpload } from "services/adminTemplateServices";

const initialState = {
	showTemplateForm: false,
	isUploading: false,
	parseResponse: null,
	insertResponse: null,
	processResponse: null,
	isProcessing: false,
	uploadPercent: null,
	isReadyToLoad: false,
	completed: false,
};

/* Fetch template upload for parse */
export const fetchTemplateParse = createAsyncThunk(
	"fetchTemplateParse",
	async (payload, { dispatch }) => {
		const { path, carrier_id } = payload;
		const response = await parseTemplateUpload(
			carrier_id,
			path,
			(progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);
				dispatch(setTemplateUploadProgress(percent));
			}
		);
		return response.data;
	}
);

/* Fetch template upload for insert */
export const fetchTemplateInsert = createAsyncThunk(
	"fetchTemplateInsert",
	async (payload, { dispatch }) => {
		const { path, carrier_id, public_id } = payload;
		const response = await insertTemplateUpload(
			carrier_id,
			path,
			public_id,
			(progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);
				dispatch(setTemplateUploadProgress(percent));
			}
		);
		return response.data;
	}
);

/** Fetch template process status */
export const fetchTemplateParseProcessStatus = createAsyncThunk(
	"fetchTemplateParseProcessStatus",
	async (public_id, {dispatch}) => {
		const response = await getTemplateProcessStatus(public_id);
		const {status, output, job_name} = response.data.data;
		// Verify if the job is finished
		if (status!= null && status === "Finished") {
			// Clear the processing state
			dispatch(clearTemplateProcessing());
			// Verify if the job is parsed
			if (output?.status === "Parsed") {
				dispatch(setCarrierTemplateReadyToLoad(true));
			}
			if (job_name === "insert") {
				dispatch(setCarrierTemplateCompleted());
			}
		}
			return response.data;
	}
);

const carrierTemplate = createSlice({
	name: "carrierTemplate",
	initialState,
	reducers: {
		showCarrierTemplateUpload: (state, { payload }) => {
			state.showTemplateForm = payload ?? false;
		},
		setTemplateUploadProgress: (state, { payload }) => {
			state.uploadPercent = payload ?? 0;
		},
		setCarrierTemplateReadyToLoad: (state, { payload }) => {
			state.isReadyToLoad = payload ?? false;
		},
		setCarrierTemplateCompleted: (state) => {
			state.completed = true;
			state.insertResponse = null;
		},
		clearTemplateProcessing: (state) => {
			state.isProcessing = false;
		},
		clearCarrierTemplateData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// Parse Template File Upload
			.addCase(fetchTemplateParse.pending, (state) => {
				state.isUploading = true;
				state.isProcessing = false;
				state.isReadyToLoad = false;
				state.parseResponse = null;
			})
			.addCase(fetchTemplateParse.fulfilled, (state, { payload }) => {
				state.isUploading = false;
				state.parseResponse = payload.data;
				state.isProcessing = true;
			})
			.addCase(fetchTemplateParse.rejected, (state) => {
				state.isUploading = false;
			})
			// Insert Template File Upload
			.addCase(fetchTemplateInsert.pending, (state) => {
				state.isUploading = true;
				state.isProcessing = false;
				state.isReadyToLoad = false;
				state.parseResponse = null;
				state.insertResponse = null;
				state.processResponse = null;
			})
			.addCase(fetchTemplateInsert.fulfilled, (state, { payload }) => {
				state.isUploading = false;
				state.insertResponse = payload.data;
				state.isProcessing = true;
			})
			.addCase(fetchTemplateInsert.rejected, (state) => {
				state.isUploading = false;
			})
			// Template Parse / Insert Process Status
			.addCase(fetchTemplateParseProcessStatus.pending, (state) => {
				state.isProcessing = true;
			})
			.addCase(fetchTemplateParseProcessStatus.fulfilled, (state, { payload }) => {
				state.processResponse = payload.data;
			})
			.addCase(fetchTemplateParseProcessStatus.rejected, (state) => {
				state.isProcessing = false;
			});
	},
});

export const {
	showCarrierTemplateUpload,
	setTemplateUploadProgress,
	setCarrierTemplateReadyToLoad,
	setCarrierTemplateCompleted,
	clearTemplateProcessing,
	clearCarrierTemplateData,
} = carrierTemplate.actions;

export default carrierTemplate.reducer;
