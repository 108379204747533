import React from "react";

export default function Loader(props) {
	return (
		<div className="loader">
			<div>
				<svg width="64px" height="64px" viewBox="0 0 100 100">
					<path d="M 50 96 a 46 46 0 0 1 0 -92 46 46 0 0 1 0 92" />
				</svg>
			</div>
			<div>{props.msg}</div>
		</div>
	);
}
