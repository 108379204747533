import Loader from "components/Loader";
import { fetchAccesorials } from "features/catalogs/accesorialsSlice";
import {
	fetchSetCarrierAccesorials,
	setCarrierAccesorialsEdit,
} from "features/admin/carriersSlice";

import { fetchLocationTypes } from "features/catalogs/locationTypeSlice";
import {
	ACCESSORIALS,
	CARRIER_TAB,
	CURRENCY,
	LOCATION_TYPES,
	SPECIAL_HANDLING,
} from "features/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "utils/numberUtils";
import { useForm, useFieldArray } from "react-hook-form";

import { currencyRegEx } from "utils/regExs";
import { removeEmptyProps } from "utils/formUtils";

export default function CarrierDetailsAccessorials() {
	const dispatch = useDispatch();
	// Translate
	const { t } = useTranslation();
	// Selectors
	const { tab, open, isLoading, isSavingAccesorials, accessorialsEdit } =
		useSelector((state) => state.carriers);
	const { accesorials } = useSelector((state) => state.accesorials);
	const isLoadingAccessorials = useSelector(
		(state) => state.accesorials.isLoading
	);
	const { locationTypes } = useSelector((state) => state.locationTypes);
	const isLoadingLocationTypes = useSelector(
		(state) => state.locationTypes.isLoading
	);
	// State
	// const [showEdit, setShowEdit] = useState(false);

	// Forms
	const {
		control,
		register,
		// setValue,
		getValues,
		handleSubmit,
		reset,
		// formState: { errors },
	} = useForm();

	const { fields: accessorialsFields } = useFieldArray({
		control,
		name: "accessorials",
	});
	const { fields: locationTypesFields } = useFieldArray({
		control,
		name: "location_types",
	});
	const { fields: specialHandlingsFields } = useFieldArray({
		control,
		name: "special_handlings",
	});

	// Effect
	useEffect(() => {
		if (accesorials == null && !isLoadingAccessorials) {
			dispatch(fetchAccesorials());
		}
		if (locationTypes == null && !isLoadingLocationTypes) {
			dispatch(fetchLocationTypes());
		}

		// Form default values
		const _accFiltered = accesorials?.filter((el) => el.allows_rate === true);
		const _lctFiltered = locationTypes?.filter((el) => el.allows_rate === true);

		const formValues = {
			accessorials: _accFiltered?.map((el, index) => {
				const accessorial = open.accessorials.find((item) => item.id === el.id);
				return {
					id: el.id,
					rate:
						accessorial?.pivot?.rate != null
							? parseFloat(
									(
										accessorial?.pivot?.rate * accessorial?.pivot?.exchange_rate
									).toFixed(2)
							  )
							: null,
				};
			}),
			location_types: _lctFiltered?.map((el, index) => {
				const locationType = open.location_types.find(
					(item) => item.id === el.id
				);
				return {
					id: el.id,
					rate:
						locationType?.pivot?.rate != null
							? parseFloat(
									(
										locationType?.pivot?.rate *
										locationType?.pivot?.exchange_rate
									).toFixed(2)
							  )
							: null,
				};
			}),
			special_handlings: [
				{
					id: SPECIAL_HANDLING.TEMPCTL,
					rate:
						open.tmpctl_rate != null
							? parseFloat((open.tmpctl_rate * open.exchange_rate).toFixed(2))
							: null,
				},
			],
		};

		reset(formValues);
	}, [
		accesorials,
		locationTypes,
		isLoadingAccessorials,
		isLoadingLocationTypes,
		dispatch,
		reset,
		open,
	]);
	// Handlers
	const handleEditClick = () => {
		dispatch(setCarrierAccesorialsEdit(true));
	};
	const handleCancelEdit = () => {
		dispatch(setCarrierAccesorialsEdit(false));
	};
	const handleSaveAccessorialRates = (data, event) => {
		event.preventDefault();
		// Remove invalid fields.
		data.accessorials = data.accessorials.filter(function (el) {
			return el.rate && (parseFloat(el.rate) ?? 0) > 0;
		});
		// Parse float
		data.accessorials = data.accessorials.map((el, index) => {
			return { id: el.id, rate: parseFloat(el.rate) };
		});
		// Remove invalid fields.
		data.location_types = data.location_types.filter(function (el) {
			return el.rate && (parseFloat(el.rate) ?? 0) > 0;
		});
		// Parse float
		data.location_types = data.location_types.map((el, index) => {
			return { id: el.id, rate: parseFloat(el.rate) };
		});
		// Remove invalid fields.
		data.special_handlings = data.special_handlings.filter(function (el) {
			return el.rate && (parseFloat(el.rate) ?? 0) > 0;
		});
		// Parse float
		data.special_handlings = data.special_handlings.map((el, index) => {
			return { id: el.id, rate: parseFloat(el.rate) };
		});
		// Call set.
		dispatch(
			fetchSetCarrierAccesorials({
				carrierId: open.id,
				data: removeEmptyProps(data),
			})
		);
	};
	// Render
	if (tab !== CARRIER_TAB.ACCESSORIALS) return null;
	if (isLoading || isSavingAccesorials) {
		return <Loader msg={t("Common.Loading")} />;
	}
	const renderAccessorials = () => {
		const accFiltered = accesorials?.filter((el) => el.allows_rate === true);
		return (
			<>
				{accFiltered?.map((el, index) => {
					const accRate = open.accessorials.filter((item) => item.id === el.id);
					return (
						<tr key={`acc-${index}`}>
							<td>{t(ACCESSORIALS.properties[el.id].name)}</td>
							<td>
								{accRate.length > 0
									? `$${numberFormat(
											accRate[0].pivot.rate * accRate[0].pivot.exchange_rate,
											2
									  )} ${CURRENCY.properties[accRate[0].pivot.currency].code}`
									: "-"}
							</td>
						</tr>
					);
				})}
			</>
		);
	};
	const renderLocationTypes = () => {
		const lctFiltered = locationTypes?.filter((el) => el.allows_rate === true);
		return (
			<>
				{lctFiltered?.map((el, index) => {
					const lctRate = open.location_types.filter(
						(item) => item.id === el.id
					);
					return (
						<tr key={`lct-${index}`}>
							<td>{t(LOCATION_TYPES.properties[el.id].name)}</td>
							<td>
								{lctRate.length > 0
									? `$${numberFormat(
											lctRate[0].pivot.rate * lctRate[0].pivot.exchange_rate,
											2
									  )} ${CURRENCY.properties[lctRate[0].pivot.currency].code}`
									: "-"}
							</td>
						</tr>
					);
				})}
			</>
		);
	};
	const renderAccessorialFields = () => {
		return (
			<>
				{accessorialsFields.map((item, index) => {
					const value = getValues(`accessorials.${index}`);
					return (
						<>
							<tr>
								<td>{t(ACCESSORIALS.properties[value.id].name)}</td>
								<td>
									<input
										type="text"
										className="right"
										{...register(`accessorials.${index}.rate`, {
											pattern: {
												value: currencyRegEx,
												message: "Value must be a decimal.",
											},
										})}
										placeholder={t(ACCESSORIALS.properties[value.id].name)}
									/>
								</td>
							</tr>
						</>
					);
				})}
			</>
		);
	};
	const renderLocationTypeFields = () => {
		return (
			<>
				{locationTypesFields.map((item, index) => {
					const value = getValues(`location_types.${index}`);
					return (
						<>
							<tr>
								<td>{t(LOCATION_TYPES.properties[value.id].name)}</td>
								<td>
									<input
										type="text"
										className="right"
										{...register(`location_types.${index}.rate`, {
											pattern: {
												value: currencyRegEx,
												message: "Value must be a decimal.",
											},
										})}
										placeholder={t(LOCATION_TYPES.properties[value.id].name)}
									/>
								</td>
							</tr>
						</>
					);
				})}
			</>
		);
	};

	const renderSpecialHandlingFields = () => {
		return (
			<>
				{specialHandlingsFields.map((item, index) => {
					const value = getValues(`special_handlings.${index}`);
					return (
						<>
							<tr>
								<td>{t(SPECIAL_HANDLING.properties[value.id].name)}</td>
								<td>
									<input
										type="text"
										className="right"
										{...register(`special_handlings.${index}.rate`, {
											pattern: {
												value: currencyRegEx,
												message: "Value must be a decimal.",
											},
										})}
										placeholder={t(SPECIAL_HANDLING.properties[value.id].name)}
									/>
								</td>
							</tr>
						</>
					);
				})}
			</>
		);
	};

	const renderEdit = () => {
		if (accessorialsEdit) {
			return (
				<div className="tab-content details">
					<div className="dsv-form collapse" style={{ marginRight: "20px" }}>
						<div className="b-1">
							<div className="details-subtitle">
								<div>&nbsp;</div>
								<div>
									<span
										className="icon-edit-close"
										onClick={handleCancelEdit}
									></span>
								</div>
								<div style={{ marginLeft: "10px" }}>
									<span
										className="icon-edit-save"
										onClick={handleSubmit(handleSaveAccessorialRates)}
									></span>
								</div>
							</div>
						</div>
						<div className="b-1 field">
							<table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
								<thead>
									<tr>
										<th>{t("Fields.Accessorial")}</th>
										<th className="center">{t("Fields.Rate")}</th>
									</tr>
								</thead>
								<tbody>
									{renderSpecialHandlingFields()}
									{renderAccessorialFields()}
									{renderLocationTypeFields()}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderAccessorialRates = () => {
		if (!accessorialsEdit) {
			return (
				<div className="tab-content details">
					<div className="details-subtitle">
						<div onClick={handleEditClick}>
							<span className="icon-edit"></span>
						</div>
					</div>
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Fields.Accessorial")}</th>
									<th>{t("Fields.Rate")}</th>
								</tr>
							</thead>
							<tbody>
								<tr key={"tempctl"}>
									<td>{t("SpecialHandlings.Tempctl")}</td>
									<td>
										{open.tmpctl_rate != null
											? `$${numberFormat(
													open.tmpctl_rate * open.exchange_rate,
													2
											  )} ${CURRENCY.properties[open.currency].code}`
											: "-"}
									</td>
								</tr>
								{renderAccessorials()}
								{renderLocationTypes()}
							</tbody>
						</table>
					</div>
				</div>
			);
		}
	};
	return (
		<>
			{renderAccessorialRates()}
			{renderEdit()}
		</>
	);
}
