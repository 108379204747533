import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { ATTACHABLE_TYPE, BOOKED_STATUS, BOOKING_METHOD, CURRENCY } from "features/constants";
import { renderDate } from "utils/dateUtils";
import { numberFormat } from "utils/numberUtils";
import { getCurrencyByCountryCode } from "utils/constantsUtils";
import { validateEmail } from "utils/stringUtils";
import {
	clearOperatorOrdersEmailSent,
	fetchChangeOperatorOrderStatus,
	fetchOperatorSendEmailToCarrier,
	setOperatorOrderShowUpload,
	setOperatorOrderStatusError,
	fetchOperatorOrderUploadDoc,
} from "features/operator/operatorOrdersSlice";
import OrderDocs from "components/common/order/OrderDocs";
import OrderLog from "components/common/order/OrderLog";
import OperatorOrderDetailsQuote from "./OperatorOrderDetailsQuote";
import OrderPriceCheck from "components/common/order/OrderPriceCheck";
import OrderSpotPrice from "components/common/order/OrderSpotPrice";
import OrderDocsForm from "components/common/order/OrderDocsForm";
import { emailSendListToSelector } from "utils/carrierUtils";
import Loader from "components/Loader";
import StatusChangeForm from "components/common/order/StatusChangeForm";

const components = {
	DropdownIndicator: null,
};

const createOption = (label) => ({
	label,
	value: label,
});

export default function OperatorOrderDetailsBooked({
	onClose,
	onCollapse,
	collapse,
	onExpand,
}) {
	const dispatch = useDispatch();
	// Translation
	const { t, i18n } = useTranslation();
	// Selectors
	const {
		openOrder,
		isLoadingOrder,
		openLog,
		changeStatusError,
		docs,
		priceCheckRates,
		isLoadingPriceCheckRates,
		spotPrice,
		isLoadingSpotPrice,
		bookedOrder,
		showUpload,
		isUploading,
		isSendingEmail,
		isEmailSent,
		isChangingStatus,
	} = useSelector((state) => state.operatorOrders);
	const { user } = useSelector((state) => state.session);

	// State
	const [showCancelAlert, setShowCancelAlert] = useState(false);
	const [showStatusChange, setShowStatusChange] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [showEmailFiles, setShowEmailFiles] = useState(false);
	const [notes, setNotes] = useState("");
	const [emailTo, setEmailTo] = useState("");
	const [emailToValue, setEmailToValue] = useState([]);
	const [emailSubject, setEmailSubject] = useState("");
	const [emailBody, setEmailBody] = useState("");
	// Effect
	useEffect(() => {
		if (isEmailSent) {
			dispatch(clearOperatorOrdersEmailSent());
			setShowEmail(false);
			setShowEmailFiles(false);
			setEmailBody("");
		}
	}, [dispatch, isEmailSent]);

	// Handlers
	const handleCloseClick = () => {
		handleCancelAction();
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handleCancelOrder = (bookedOrder) => {
		dispatch(
			fetchChangeOperatorOrderStatus({
				request_id: openOrder.id,
				id: bookedOrder.id,
				public_id: bookedOrder.public_id,
				status: BOOKED_STATUS.CANCELED,
				notes: notes !== "" ? notes : undefined,
			})
		);
	};
	const handleShowUpload = (show) => {
		dispatch(setOperatorOrderShowUpload({ showUpload: show }));
	};
	const handleUpload = (file, type, file_number) => {
		// Upload.
		dispatch(
			fetchOperatorOrderUploadDoc({
				attachableId: bookedOrder.id,
				type: type,
				file: file,
				attachableType: ATTACHABLE_TYPE.BOOKED_ORDER,
				file_number: file_number,
			})
		);
	};

	const handleChangeStatusClick = (payload) => {
		dispatch(setOperatorOrderStatusError({ changeStatusError: null }));
		dispatch(
			fetchChangeOperatorOrderStatus({ request_id: openOrder.id, ...payload })
		);
	};

	const handleSendEmail = (files = false) => {
		let valid = true;
		if (emailSubject === "") {
			valid = false;
		}
		if (emailBody === "") {
			valid = false;
		}
		if (emailToValue.length === 0) {
			valid = false;
		}
		if (valid) {
			const data = {
				to: emailToValue.map((el) => el.value),
				public_id: bookedOrder.public_id,
				subject: emailSubject,
				body: emailBody,
				lang: i18n.language,
			};
			dispatch(
				fetchOperatorSendEmailToCarrier({
					booked_order_id: bookedOrder.id,
					files,
					data,
				})
			);
		}
	};

	const handleShowCancel = () => {
		setShowCancelAlert(!showCancelAlert);
		setShowEmail(false);
		setShowStatusChange(false);
		setShowEmailFiles(false);
		setEmailTo("");
		setEmailToValue([]);
	};
	const handleShowStatusChange = () => {
		setShowStatusChange(!showStatusChange);
		setShowCancelAlert(false);
		setShowEmail(false);
		setShowEmailFiles(false);
		setEmailTo("");
		setEmailToValue([]);
	};
	const handleShowEmail = () => {
		const isConsolidated = bookedOrder?.order_requests?.length > 1;
		const referenceNumber = isConsolidated ? "" : `/${bookedOrder.order_requests[0].alternate_reference_number}`;
		const subjectDefault =
			bookedOrder?.file_number != null
				? t("OperatorOrders.SubjectEmail", { order: isConsolidated ? bookedOrder?.file_number : `${bookedOrder?.file_number}${referenceNumber}` })
				: t("Common.Order", { count: 1 });
		setShowEmail(!showEmail);
		setEmailTo("");
		setEmailToValue(
			emailSendListToSelector(bookedOrder?.carrier?.email_send_list)
		);
		setEmailSubject(subjectDefault);
		setShowStatusChange(false);
		setShowCancelAlert(false);
		setShowEmailFiles(false);
	};
	const handleShowEmailFiles = () => {
		const isConsolidated = bookedOrder?.order_requests?.length > 1;
		const referenceNumber = isConsolidated ? "" : `/${bookedOrder.order_requests[0].alternate_reference_number}`;
		const subjectDefault =
			bookedOrder?.file_number != null
				? t("OperatorOrders.SubjectFiles", { order: isConsolidated ? bookedOrder?.file_number : `${bookedOrder?.file_number}${referenceNumber}` })
				: t("Common.Order", { count: 1 });
		setShowEmailFiles(!showEmailFiles);
		setEmailTo("");
		setEmailToValue(
			emailSendListToSelector(bookedOrder?.carrier?.email_send_list)
		);
		setEmailSubject(subjectDefault);
		setShowStatusChange(false);
		setShowCancelAlert(false);
		setShowEmail(false);
	};
	const handleCancelAction = () => {
		setShowCancelAlert(false);
		setShowStatusChange(false);
		dispatch(setOperatorOrderStatusError({ changeStatusError: null }));
		setShowEmail(false);
		setShowEmailFiles(false);
		setEmailTo("");
		setEmailToValue([]);
		setEmailBody("");
		setEmailSubject("");
	};
	const handleNotesChange = (e) => {
		setNotes(e.target.value);
	};
	const handleEmailToKeyDown = (e) => {
		if (!emailTo) return;
		switch (e.key) {
			case "Enter":
			case "Tab":
				if (!validateEmail(emailTo)) return;
				setEmailToValue((prev) => [...prev, createOption(emailTo)]);
				setEmailTo("");
				e.preventDefault();
				break;
			default:
				break;
		}
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };
	const renderBookedInfo = () => {
		const { booked_orders } = openOrder;
		if (booked_orders != null && booked_orders.length > 0) {
			const bookedOrder = booked_orders[0];
			const { carrier } = bookedOrder;
			const bookedToText =
				(bookedOrder.booked_method === BOOKING_METHOD.DSVROAD ||
					bookedOrder.booked_method === BOOKING_METHOD.SPOT) &&
				(bookedOrder.book_status === BOOKED_STATUS.PENDING ||
					bookedOrder.book_status === BOOKED_STATUS.SUBMITTED)
					? "Common.OfferedTo"
					: "Tables.BookedTo";
			return (
				<div className="order-block">
					<div className="flex-table single-line remove-bottom-space">
						<div>
							{carrier != null && (
								<div>
									<div>{t(bookedToText)}</div>
									<div>{carrier?.name ?? <>&nbsp;</>}</div>
								</div>
							)}
							<div>
								<div>{t("Common.Price")}</div>
								<div>
									{bookedOrder.price != null
										? `$${numberFormat(bookedOrder.price * bookedOrder.exchange_rate, 2)} ${
												CURRENCY.properties[
													bookedOrder.currency ??
														getCurrencyByCountryCode(
															user?.user?.company?.country_code
														)
												].code
										  }`
										: "n/a"}
								</div>
							</div>
							<div>
								<div>{t("BookingMethods.Title")}</div>
								<div>
									{t(BOOKING_METHOD.properties[bookedOrder.booked_method].name)}
								</div>
							</div>
							{spotPrice != null &&
								bookedOrder.booked_method === BOOKING_METHOD.SPOT &&
								bookedOrder.book_status === BOOKED_STATUS.SUBMITTED && (
									<>
										<div>
											<div>{t("Tables.SubmittedAt")}</div>
											<div>
												{renderDate(
													spotPrice[0].created_at,
													t("Dates.DateTime")
												)}
											</div>
										</div>
										<div>
											<div>{t("Tables.ExpiresAt")}</div>
											<div>
												{renderDate(
													spotPrice[0].expires_at,
													t("Dates.DateTime")
												)}
											</div>
										</div>
									</>
								)}
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderCancelConfirmation = () => {
		if (showCancelAlert) {
			const bookedOrder = openOrder.booked_orders[0];
			if (isChangingStatus) {
				return <Loader />;
			}
			return (
				<>
					<div className="order-confirmation">
						<div>{t("OperatorOrders.CancelConfirmation")}</div>
						<div className="dsv-form collapse no-padding">
							<div className="a field">
								<label>{t("Fields.CancelReason")}</label>
								<textarea
									name="book_status_notes"
									id="book_status_notes"
									placeholder={t("Fields.CancelReason")}
									value={notes}
									onChange={handleNotesChange}
								/>
							</div>
						</div>
						<div className="action-buttons">
							<div>
								<div className="button" onClick={handleCancelAction}>
									{t("Common.No")}
								</div>
								<div
									className="button main"
									onClick={() => handleCancelOrder(bookedOrder)}
								>
									{t("Common.Yes")}
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	};
	const renderEmailForm = () => {
		if (showEmail || showEmailFiles) {
			if (isSendingEmail) {
				return (
					<div className="order-confirmation">
						<div>{showEmail ? "Email Carrier" : "Send Order Files"}</div>
						<div>{t("OperatorOrders.EmailInstructions")}</div>
						<div className="dsv-form collapse no-padding">
							<Loader />
						</div>
					</div>
				);
			}
			return (
				<>
					<div className="order-confirmation">
						<div>
							{showEmail ? t("Buttons.EmailCarrier") : t("Buttons.EmailFiles")}
						</div>
						<div>{t("OperatorOrders.EmailInstructions")}</div>
						<div className="dsv-form collapse no-padding">
							<div className="a field">
								<label>{t("Fields.To")}</label>
								<CreatableSelect
									className="dsv-select"
									classNamePrefix="dsv-select"
									components={components}
									inputValue={emailTo}
									isClearable={false}
									isMulti
									menuIsOpen={false}
									onChange={(newValue) => setEmailToValue(newValue)}
									onInputChange={(newValue) => setEmailTo(newValue)}
									onKeyDown={handleEmailToKeyDown}
									placeholder={t("Fields.To")}
									value={emailToValue}
								/>
							</div>
							<div className="a field">
								<label>{t("Fields.EmailSubject")}</label>
								<input
									type="text"
									name="email_subject"
									id="email_subject"
									placeholder={t("Fields.EmailSubject")}
									value={emailSubject}
									onChange={(e) => setEmailSubject(e.target.value)}
								/>
							</div>
							<div className="a field">
								<label>{t("Fields.EmailBody")}</label>
								<textarea
									name="email_body"
									id="email_body"
									placeholder={t("Fields.EmailBody")}
									value={emailBody}
									onChange={(e) => setEmailBody(e.target.value)}
								/>
							</div>
						</div>
						<div className="action-buttons">
							<div>
								<div className="button" onClick={handleCancelAction}>
									{t("Buttons.Cancel")}
								</div>
								<div
									className="button main"
									onClick={() => handleSendEmail(showEmailFiles === true)}
								>
									{t("Buttons.Submit")}
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	};
	const renderStatusChangeForm = () => {
		if (showStatusChange) {
			const bookedOrder = openOrder.booked_orders[0];
			return (
				<StatusChangeForm
					bookedOrder={bookedOrder}
					onCancelAction={handleCancelAction}
					onClick={handleChangeStatusClick}
					isLoading={isChangingStatus}
					changeStatusError={changeStatusError}
				/>
			);
		}
	};
	const renderActions = () => {
		const { booked_orders } = openOrder;
		if (booked_orders != null && booked_orders.length > 0) {
			const bookedOrder = booked_orders[0];
			if (
				bookedOrder.booked_method === BOOKING_METHOD.DSVROAD &&
				bookedOrder.book_status === BOOKED_STATUS.SUBMITTED
			) {
				return null;
			}
			if (
				bookedOrder.book_status === BOOKED_STATUS.PENDING ||
				bookedOrder.book_status === BOOKED_STATUS.SUBMITTED
			) {
				return (
					<div className="order-block">
						<div className="order-subtitle">
							<div>{t("Common.Actions")}</div>
						</div>
						<div className="action-buttons">
							<div>
								<div
									className="button action alert"
									onClick={() => setShowCancelAlert(!showCancelAlert)}
								>
									<span className="icon-cancel inverted"></span>
									{t("Buttons.CancelOrder")}
								</div>
							</div>
						</div>
						{renderCancelConfirmation()}
					</div>
				);
			}
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("Common.Actions")}</div>
					</div>
					<div className="action-buttons">
						<div>
							<div className="button action alert" onClick={handleShowCancel}>
								<span className="icon-cancel inverted"></span>
								{t("Buttons.CancelOrder")}
							</div>
							<div
								className="button action alternate"
								onClick={handleShowEmail}
							>
								<span className="icon-email inverted"></span>
								{t("Buttons.EmailCarrier")}
							</div>
							<div
								className="button action main"
								onClick={handleShowStatusChange}
							>
								<span className="icon-change-status inverted"></span>
								{t("Buttons.AddStatus")}
							</div>
							<div
								className="button action other"
								onClick={handleShowEmailFiles}
							>
								<span className="icon-other inverted"></span>
								{t("Buttons.EmailFiles")}
							</div>
						</div>
					</div>
					{renderCancelConfirmation()}
					{renderStatusChangeForm()}
					{renderEmailForm()}
				</div>
			);
		}
	};
	const renderLog = () => {
		const { booked_orders } = openOrder;
		if (booked_orders != null && booked_orders.length > 0) {
			return (
				<div className="order-block">
					<div className="order-subtitle">
						<div>{t("Common.StatusLog")}</div>
					</div>
					{openLog != null ? <OrderLog log={openLog} /> : <>&nbsp;</>}
				</div>
			);
		}
	};

	const renderDocuments = () => {
		const { booked_orders } = openOrder;
		//const bookedOrder = booked_orders[0];
		if (
			bookedOrder.book_status === BOOKED_STATUS.PENDING ||
			bookedOrder.book_status === BOOKED_STATUS.SUBMITTED
		)
			return null;
		if (booked_orders != null && booked_orders.length > 0) {
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("Common.Documents")}</div>
						<div>
							<span
								className="icon-add"
								onClick={() => handleShowUpload(true)}
							></span>
						</div>
					</div>
					{showUpload && (
						<OrderDocsForm
							onCancel={() => handleShowUpload(false)}
							onUpload={handleUpload}
							isLoading={isUploading}
							bookedOrder={bookedOrder}
						/>
					)}
					{docs != null ? (
						<OrderDocs
							docs={docs}
							bookedOrderId={booked_orders[0].id}
							orderRequestId={openOrder.id}
						/>
					) : (
						<></>
					)}
				</div>
			);
		}
	};
	const renderQuote = () => {
		const { booked_orders } = openOrder;
		if (booked_orders != null && booked_orders.length > 0) {
			const bookedOrder = booked_orders[0];
			if (
				bookedOrder.booked_method === BOOKING_METHOD.DSVROAD &&
				bookedOrder.book_status === BOOKED_STATUS.SUBMITTED
			) {
				return <OperatorOrderDetailsQuote />;
			}
		}
	};
	const renderPriceCheck = () => {
		const { booked_orders } = openOrder;
		const bookedOrder = booked_orders[0];
		if (
			bookedOrder.booked_method === BOOKING_METHOD.DSVROAD &&
			(bookedOrder.book_status === BOOKED_STATUS.PENDING ||
				bookedOrder.book_status === BOOKED_STATUS.SUBMITTED)
		) {
			return (
				<OrderPriceCheck
					rates={priceCheckRates}
					isLoading={isLoadingPriceCheckRates}
				/>
			);
		}
	};
	const renderSpotPrice = () => {
		const { booked_orders } = openOrder;
		const bookedOrder = booked_orders[0];
		if (
			bookedOrder.booked_method === BOOKING_METHOD.SPOT &&
			bookedOrder.book_status === BOOKED_STATUS.SUBMITTED
		) {
			return <OrderSpotPrice list={spotPrice} isLoading={isLoadingSpotPrice} />;
		}
	};
	const renderBooked = () => {
		if (!isLoadingOrder && openOrder != null && bookedOrder != null) {
			//const { booked_orders } = openOrder;
			//const bookedOrder = booked_orders[0];
			const titleText =
				(bookedOrder.booked_method === BOOKING_METHOD.SPOT ||
					bookedOrder.booked_method === BOOKING_METHOD.DSVROAD) &&
				(bookedOrder.book_status === BOOKED_STATUS.PENDING ||
					bookedOrder.book_status === BOOKED_STATUS.SUBMITTED)
					? "Common.OfferInfo"
					: "OperatorOrders.Booked";
			return (
				<>
					<div className="order-actions-title">
						<div>
							<div>
								<span {...collapseProps}></span>
							</div>
							<div className="action-title">{t(titleText)}</div>
							<div>
								<span className="icon-close" onClick={handleCloseClick}></span>
							</div>
						</div>
					</div>
					{renderBookedInfo()}
					{renderQuote()}
					{renderPriceCheck()}
					{renderSpotPrice()}
					{renderActions()}
					{renderLog()}
					{renderDocuments()}
				</>
			);
		}
	};
	return <>{renderBooked()}</>;
}
