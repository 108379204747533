import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOperatorOrderUpdateTruckerInfo,
	setOperatorOrderEdit,
} from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";
import TruckerInfoForm from "components/common/order/TruckerInfoForm";

const OperatorOrderDetailsTruckerInfoEdit = () => {
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, bookedOrder, orderEdit, isSavingTruckerInfo } =
		useSelector((state) => state.operatorOrders);
	// Handlers
	const handleSaveTruckerData = (data) => {
		dispatch(
			fetchOperatorOrderUpdateTruckerInfo({
				id: openOrder.id,
				bookedOrderId: bookedOrder.id,
				data: { ...data, public_id: bookedOrder.public_id },
			})
		);
	};
	const handleCancelEdit = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: null }));
	};
	// Render
	if (orderEdit !== ORDER_EDIT.TRUCKER_INFO) return null;
	return (
		<TruckerInfoForm
			order={bookedOrder}
			onSave={handleSaveTruckerData}
			onCancel={handleCancelEdit}
			isSaving={isSavingTruckerInfo}
		/>
	);
};

export default OperatorOrderDetailsTruckerInfoEdit;
