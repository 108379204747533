import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import CarrierTabs from "./CarrierTabs";
import CarrierDetailsRates from "./CarrierDetailsRates";
import CarrierDetailsZones from "./CarrierDetailsZones";
import CarrierDetailsLanes from "./CarrierDetailsLanes";
import CarrierDetailsAccessorials from "./CarrierDetailsAccessorials";
import CarrierDetailsQualifications from "./CarrierDetailsQualifications";
import CarrierDetailsFuel from "./CarrierDetailsFuel";
import CarrierDetailsUsers from "./CarrierDetailsUsers";
import CarrierDetailsMain from "./CarrierDetailsMain";

export default function CarrierDetails({ onClick }) {
  // Translation
  const { t } = useTranslation();
  // Selectors
  const { open, isOpening } = useSelector((state) => state.carriers);
  // Render
  if (isOpening) {
    return (
      <div className="item-details">
        <div className="details-container">
          <Loader msg={t("Common.Loading")} />
        </div>
      </div>
    );
  }
  if (!isOpening && open == null) {
    return null;
  }
  return (
    <div className="item-details">
      <div className="details-container">
        <div className="details-title">
          <div>
            <div className="title-text">
              <span>{open.name}</span>
            </div>
            <div>
              <span className="icon-close" onClick={() => onClick()}></span>
            </div>
          </div>
          <CarrierTabs />
        </div>
        <CarrierDetailsMain />
        <CarrierDetailsUsers />
        <CarrierDetailsRates />
        <CarrierDetailsZones />
        <CarrierDetailsLanes />
        <CarrierDetailsAccessorials />
        <CarrierDetailsQualifications />
        <CarrierDetailsFuel />
      </div>
    </div>
  );
}
