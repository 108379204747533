import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	getCarrierOfferDetails,
	getCarrierOfferRequests,
	postCarrierOfferAccept,
	postCarrierOfferReject,
} from "services/carrierServices";
import { getBookedOrderTabs } from "utils/orderUtils";

const initialState = {
	list: null,
	isLoading: false,
	opened: null,
	isOpening: false,
	tabs: null,
	activeTab: 0,
	isAccepting: false,
	isRejecting: false,
};

/**
 * Async fetch functions
 */

/* Get carrier's offer list */
export const fetchCarrierOffers = createAsyncThunk(
	"carrier/fetchCarrierOffers",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await getCarrierOfferRequests();
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);
/* Get carrier's offer details */
export const fetchCarrierOfferDetails = createAsyncThunk(
	"carrier/fetchCarrierOfferDetails",
	async (payload, { dispatch }) => {
		const { id } = payload;
		const response = await getCarrierOfferDetails(id);
		const tabs = getBookedOrderTabs(response.data.data.booked_order);
		dispatch(setCarrierOfferTabs({ tabs }));
		return response;
	}
);
/* Accept spot price offer  */
export const fetchCarrierOfferAccept = createAsyncThunk(
	"carrier/fetchCarrierOfferAccept",
	async (payload, { dispatch }) => {
		const { id, notes } = payload;
		const response = await postCarrierOfferAccept(id, notes);
		dispatch(fetchCarrierOffers());
		return response;
	}
);
/* Reject spot price offer */
export const fetchCarrierOfferReject = createAsyncThunk(
	"carrier/fetchCarrierOfferReject",
	async (payload, { dispatch }) => {
		const { id, data } = payload;
		const response = await postCarrierOfferReject( id, data );
		dispatch(fetchCarrierOffers());
		return response;
	}
);

/**
 * Create redux reducer with `createSlice`
 */
export const carrierOffersSlice = createSlice({
	name: "carrierOrdersSlice",
	initialState,
	reducers: {
		setCarrierOpenedOffer: (state, action) => {
			state.opened = action.payload.opened;
		},
		setCarrierOfferTabs: (state, { payload }) => {
			state.tabs = payload.tabs ?? null;
		},
		setCarrierOfferActiveTab: (state, { payload }) => {
			state.activeTab = payload.activeTab ?? 0;
		},
		clearCarrierOffersData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/* Fetch offers */
			.addCase(fetchCarrierOffers.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchCarrierOffers.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
			})
			.addCase(fetchCarrierOffers.rejected, (state, { payload }) => {
				state.isLoading = false;
				if (payload.status === 403) state.list = null;
				else state.list = [];
			})
			/* Fetch offer details */
			.addCase(fetchCarrierOfferDetails.pending, (state) => {
				state.isOpening = true;
			})
			.addCase(fetchCarrierOfferDetails.fulfilled, (state, { payload }) => {
				state.isOpening = false;
				state.opened = payload.data.data;
			})
			.addCase(fetchCarrierOfferDetails.rejected, (state) => {
				state.isOpening = false;
				state.opened = null;
			})
			/* Fetch accept offer */
			.addCase(fetchCarrierOfferAccept.pending, (state) => {
				state.isAccepting = true;
			})
			.addCase(fetchCarrierOfferAccept.fulfilled, (state) => {
				state.isAccepting = false;
				state.opened = null;
				state.tabs = null;
			})
			.addCase(fetchCarrierOfferAccept.rejected, (state) => {
				state.isAccepting = false;
			})
			/* Fetch reject offer */
			.addCase(fetchCarrierOfferReject.pending, (state) => {
				state.isRejecting = true;
			})
			.addCase(fetchCarrierOfferReject.fulfilled, (state) => {
				state.isRejecting = false;
				state.opened = null;
				state.tabs = null;
			})
			.addCase(fetchCarrierOfferReject.rejected, (state) => {
				state.isRejecting = false;
			});
	},
});

export const {
	setCarrierOpenedOffer,
	clearCarrierOffersData,
	setCarrierOfferTabs,
	setCarrierOfferActiveTab,
} = carrierOffersSlice.actions;

export default carrierOffersSlice.reducer;
