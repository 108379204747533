import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
	ADDRESS_TYPE,
	BOOKED_STATUS,
	BOOKING_METHOD,
	CARGO_TYPE,
	CONTACT_TYPE,
	CURRENCY,
	FREIGHT_CLASS,
	SHIPMENT_TYPE,
	VOLUME_UNIT,
	WEIGHT_UNIT,
} from "features/constants";
import { clearOperatorConsolidationOrders } from "features/operator/operatorOrderConsolidationSlice";
import {
	fetchOperatorOrderDetails,
	fetchOperatorOrders,
	fetchSelfCarrierList,
	setOperatorBookedOrder,
	setOperatorBookedOrderPriceCheckRates,
	setOperatorBookedOrderTab,
	setOperatorBookedOrderTabs,
	setOperatorOpenOrder,
	setOperatorOrderCart,
	setOperatorOrderConsolidate,
	setOperatorOrderDocs,
	setOperatorOrderEdit,
	setOperatorOrderLog,
	setOperatorOrderStatusError,
} from "features/operator/operatorOrdersSlice";
import { formatDate, renderDate } from "utils/dateUtils";
import { numberFormat } from "utils/numberUtils";
import { getAddressProps, getContactProps } from "utils/orderUtils";
import OperatorOrderRequestDetails from "./OperatorOrderDetails";
import operator_default from "settings/operator_default.json";
import TableSettings from "components/common/tableSettings/TableSettings";
import {
	fetchSaveUserSettings,
	setAccountShowSettings,
} from "features/account/accountSlice";
import Pagination from "components/common/pagination/Pagination";
import Loader from "components/Loader";

import { getCurrencyByCountryCode, toSelector } from "utils/constantsUtils";
import { truncateString } from "utils/stringUtils";
import TableHead from "components/common/tables/TableHead";
import Filters from "components/common/tables/filters/Filters";
import { filterTransformToData } from "utils/filtersUtils";
import FilterContext from "components/common/tables/filters/FilterContext";
import { useFilterContext } from "components/common/tables/filters/FilterContextProvider";
export default function OperatorOrderRequests() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { list, isLoading, openOrder, isLoadingOrder, consolidate, refresh } =
		useSelector((state) => state.operatorOrders);
	const { isLoadingSettings, isSavingSettings, settings, showSettings } =
		useSelector((state) => state.account);
	const carrierList = useSelector((state) => state.carrierCatalog.list);
	const { user } = useSelector((state) => state.session);
	// State
	const [showDetails, setShowDetails] = useState(false);
	const [collapse, setCollapse] = useState(false);

	// Filter Context
	const { filterContext } = useFilterContext({
		options: {
			transport_shipment_type: toSelector(
				SHIPMENT_TYPE.properties,
				false,
				true
			),
			transport_cargo_type: toSelector(CARGO_TYPE.properties, false, true),
			booked_carrier: carrierList?.map((item) => {
				return { value: item.id, label: item.name };
			}),
			booked_status: toSelector(BOOKED_STATUS.properties, false, true),
			booked_method: toSelector(BOOKING_METHOD.properties, false, true),
		},
	});

	const handleFilterRefresh = () => {
		dispatch(
			fetchOperatorOrders({
				page: list?.current_page ?? 1,
				filters: filterTransformToData(filterContext.filters, 
					tableSettings.concat([fixedField])),
			})
		);
	};

	// Effect
	useEffect(() => {
		if (!isLoading && list == null) {
			dispatch(fetchOperatorOrders({ page: 1 }));
		}
		if (carrierList != null) {
			filterContext.setOptions({
				transport_shipment_type: toSelector(
					SHIPMENT_TYPE.properties,
					false,
					true
				),
				transport_cargo_type: toSelector(CARGO_TYPE.properties, false, true),
				booked_carrier: carrierList?.map((item) => {
					return { value: item.id, label: item.name };
				}),
				booked_status: toSelector(BOOKED_STATUS.properties, false, true),
				booked_method: toSelector(BOOKING_METHOD.properties, false, true),
			});
		}
		if (openOrder == null && !isLoadingOrder) {
			setShowDetails(false);
		}
		if (!isLoading && refresh) {
			handleFilterRefresh();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, list, openOrder, isLoadingOrder, dispatch, refresh, carrierList]);


	const fixedField = {
		id: 1,
		field: "file_number",
		show: true,
		name: "Tables.FileNumber",
		filter: true,
		filter_type: "string",
	};

	// Table Settings
	const userSettings = settings
		? settings.settings.version !== operator_default.settings.version
			? operator_default
			: settings
		: operator_default;
	const tableSettings = userSettings.settings.operator_orders;
	// const dateTypePattern = dateTypeRegex(i18n.language);
	// Handlers
	const handleOrderClick = (id, index) => {
		dispatch(setOperatorBookedOrderTab({ bookedTab: null }));
		dispatch(setOperatorBookedOrder({ bookedOrder: null }));
		dispatch(setOperatorBookedOrderTabs({ bookedTabs: null }));
		dispatch(setOperatorBookedOrderPriceCheckRates({ priceCheckRates: null }));
		dispatch(setOperatorOrderEdit({ orderEdit: null }));
		dispatch(setOperatorOrderCart({ orderCart: null }));
		dispatch(setOperatorOrderLog({ openLog: null }));
		dispatch(setOperatorOrderDocs({ docs: null }));
		dispatch(fetchOperatorOrderDetails({ id }));
		dispatch(fetchSelfCarrierList());
		setShowDetails(true);
		dispatch(setAccountShowSettings({ showSettings: false }));
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
		dispatch(clearOperatorConsolidationOrders());
		dispatch(setOperatorOrderStatusError({ changeStatusError: null }));
	};
	const handleOrderClose = () => {
		dispatch(setOperatorOpenOrder({ openOrder: null }));
		dispatch(setOperatorBookedOrder({ bookedOrder: null }));
		dispatch(setOperatorBookedOrderTabs({ bookedTabs: null }));
		dispatch(setOperatorBookedOrderTab({ bookedTab: null }));
		dispatch(setOperatorBookedOrderPriceCheckRates({ priceCheckRates: null }));
		dispatch(setOperatorOrderEdit({ orderEdit: null }));
		dispatch(setOperatorOrderCart({ orderCart: null }));
		dispatch(setOperatorOrderLog({ openLog: null }));
		dispatch(setOperatorOrderDocs({ docs: null }));
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: false }));
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
		dispatch(clearOperatorConsolidationOrders());
		dispatch(setOperatorOrderStatusError({ changeStatusError: null }));
		setCollapse(false);
	};
	const handleOrderCollapse = () => {
		setCollapse(true);
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
	};
	const handleOrderExpand = () => {
		setCollapse(false);
		dispatch(setOperatorOrderConsolidate({ consolidate: false }));
	};
	const handleOrderConsolidation = () => {
		setCollapse(false);
		dispatch(setOperatorOrderConsolidate({ consolidate: true }));
	};
	const handleShowSettings = () => {
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: true }));
	};
	const handleCloseSettings = () => {
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: false }));
	};

	const handleSaveSettings = (data) => {
		const userSettings = {
			...operator_default.settings,
			operator_orders: data,
		};
		dispatch(fetchSaveUserSettings({ data: userSettings }));
	};

	const handlePageClick = (page) => {
		dispatch(
			fetchOperatorOrders({
				page,
				filters: filterTransformToData(filterContext.filters, 
					tableSettings.concat([fixedField])),
			})
		);
	};

	const onFiltersChanged = (data, e) => {
		e.preventDefault();

		const submitFilters = filterTransformToData(
			data,
			[].concat([fixedField], tableSettings)
		);
		dispatch(fetchOperatorOrders({ page: 1, filters: submitFilters }));
	};

	// Render

	const getCssClass = () => {
		if (showDetails) {
			if (consolidate) {
				return "order-container show-details consolidate";
			}
			if (collapse) {
				return "order-container show-details collapse";
			}
			return "order-container show-details";
		}
		if (showSettings) {
			return "order-container show-settings";
		}
		return "order-container";
	};
	const cssClass = getCssClass();

	const renderLoader = () => {
		if (isLoading) {
			return (
				<div>
					<Loader />
				</div>
			);
		}
	};
	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={list.current_page}
				last={list.last_page}
				onRefresh={handleFilterRefresh}
			/>
		);
	};
	const renderSettings = () => {
		if (showSettings) {
			return (
				<TableSettings
					settings={JSON.parse(JSON.stringify(tableSettings))}
					onSave={handleSaveSettings}
					onClose={handleCloseSettings}
					isLoading={isLoadingSettings}
					isSaving={isSavingSettings}
				/>
			);
		}
		return null;
	};

	const renderTable = () => {
		if (!isLoading && list != null) {
			return (
				<div>
					{renderPagination()}
					<FilterContext.Provider value={filterContext}>
						<div className="dsv-table with-check">
							<Filters onFiltersChanged={onFiltersChanged} />
							<table cellSpacing="0" cellPadding="0">
								<TableHead
									tables={[].concat([fixedField], tableSettings)}
									checkbox={true}
								/>
								<tbody>
									{list?.data?.map((el, index) => {
										const rowProps = {
											onClick: () => handleOrderClick(el.id, index),
										};
										const forwarderContact = getContactProps(
											el.contacts,
											CONTACT_TYPE.FORWARDER
										);
										const originAddress = getAddressProps(
											el.addresses,
											ADDRESS_TYPE.ORIGIN
										);
										const destAddress = getAddressProps(
											el.addresses,
											ADDRESS_TYPE.DESTINATION
										);
										const bookingData =
											el.booked_order != null && el.booked_order.length > 0
												? el.booked_order[0]
												: {};
										const activeProps =
											el.id === openOrder?.id ? { className: "active" } : {};
										return (
											<tr {...activeProps} key={index}>
												<td key={`check_box_${index}`}>
													<input
														type="checkbox"
														id={`check-${el.file_number}`}
														name={`check-${el.file_number}`}
													/>
												</td>
												<td {...rowProps} key={`file_number_${index}`}>
													{el.file_number}
												</td>
												{tableSettings.map((field, idx) => {
													if (!field.show) {
														return null;
													}
													const fieldClass =
														field.class != null
															? { className: field.class }
															: {};
													switch (field.field) {
														case "transport_shipment_type":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{t(
																		SHIPMENT_TYPE.properties[
																			el.transport_shipment_type
																		].name
																	)}
																</td>
															);
														case "transport_cargo_type":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{t(
																		CARGO_TYPE.properties[
																			el.transport_cargo_type
																		].name
																	)}
																</td>
															);
														case "forwarder_contact_name":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{forwarderContact.name}
																</td>
															);
														case "booked_number":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.file_number ?? <>&nbsp;</>}
																</td>
															);
														case "booked_carrier":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.carrier != null ? (
																		truncateString(
																			bookingData.carrier.name
																		) ?? <>&nbsp;</>
																	) : (
																		<>&nbsp;</>
																	)}
																</td>
															);
														case "booked_at":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{renderDate(
																		bookingData.booked_at,
																		t("Dates.DateTime")
																	)}
																</td>
															);
														case "booked_rate":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.price != null
																		? `$${numberFormat(
																				bookingData.price *
																					bookingData.exchange_rate,
																				2
																		  )} ${
																				CURRENCY.properties[
																					bookingData?.currency ??
																						getCurrencyByCountryCode(
																							user?.user?.company?.country_code
																						)
																				].code
																		  }`
																		: "-"}
																</td>
															);
														case "booked_status":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.book_status != null
																		? t(
																				BOOKED_STATUS.properties[
																					bookingData.book_status
																				].name
																		  )
																		: t(BOOKED_STATUS.properties[0].name)}
																</td>
															);
														case "booked_method":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.booked_method != null ? (
																		t(
																			BOOKING_METHOD.properties[
																				bookingData.booked_method
																			].name
																		)
																	) : (
																		<>&nbsp;</>
																	)}
																</td>
															);
														case "cargo_pieces":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{numberFormat(el[field.field])}
																</td>
															);
														case "cargo_actual_weight":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{numberFormat(el.cargo_actual_weight * 1, 2)}{" "}
																	{
																		WEIGHT_UNIT.properties[
																			el.cargo_actual_weight_unit
																		].code
																	}
																</td>
															);
														case "cargo_freight_class":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{el.cargo_freight_class != null ? (
																		FREIGHT_CLASS.properties[
																			el.cargo_freight_class
																		].name
																	) : (
																		<>&nbsp;</>
																	)}
																</td>
															);
														case "cargo_volume":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{el.cargo_volume != null ? (
																		`${numberFormat(el.cargo_volume * 1, 2)} ${
																			VOLUME_UNIT.properties[
																				el.cargo_volume_unit
																			].code
																		}`
																	) : (
																		<>&nbsp;</>
																	)}
																</td>
															);
														case "origin_postal_code":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{originAddress.zip}
																</td>
															);
														case "origin_state":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{originAddress.state}
																</td>
															);
														case "origin_estimated_ready_date_time":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.estimated_ready != null
																		? formatDate(
																				bookingData.estimated_ready,
																				t("Dates.DateTime")
																		  )
																		: formatDate(
																				el[field.field],
																				t("Dates.DateTime")
																		  )}
																</td>
															);
														case "origin_estimated_close_date_time":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.estimated_close != null
																		? formatDate(
																				bookingData.estimated_close,
																				t("Dates.DateTime")
																		  )
																		: formatDate(
																				el[field.field],
																				t("Dates.DateTime")
																		  )}
																</td>
															);
														case "destination_requested_delivery_date_time":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.requested_delivery != null
																		? formatDate(
																				bookingData.requested_delivery,
																				t("Dates.DateTime")
																		  )
																		: formatDate(
																				el[field.field],
																				t("Dates.DateTime")
																		  )}
																</td>
															);
														case "created_at":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{formatDate(
																		el[field.field],
																		t("Dates.DateTime")
																	)}
																</td>
															);
														case "destination_postal_code":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{destAddress.zip}
																</td>
															);
														case "destination_state":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{destAddress.state}
																</td>
															);
														case "cargo_description":
														case "origin_name":
														case "destination_name":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{truncateString(el[field.field], 25) ?? (
																		<>&nbsp;</>
																	)}
																</td>
															);
														case "pod":
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{bookingData.attachments_count != null ? (
																		bookingData.attachments_count === 0 ? (
																			t("Common.No")
																		) : (
																			t("Common.Yes")
																		)
																	) : (
																		<>&nbsp;</>
																	)}
																</td>
															);
														default:
															return (
																<td
																	{...rowProps}
																	{...fieldClass}
																	key={`${index}-${idx}`}
																>
																	{el[field.field] ?? <>&nbsp;</>}
																</td>
															);
													}
												})}
											</tr>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan="3">
											{t("Pagination.PageInfo", {
												from: list.from,
												to: list.to,
												total: list.total,
											})}
										</td>
										<td colSpan="28">&nbsp;</td>
									</tr>
								</tfoot>
							</table>
						</div>
						{renderPagination()}
					</FilterContext.Provider>
				</div>
			);
		}
	};
	return (
		<div id="default">
			<div className={cssClass}>
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("OperatorOrders.Title")}</h1>
							</div>
							<div
								className="table-settings-button"
								onClick={() => handleShowSettings()}
							>
								<div>
									<span className="icon-settings"></span>
								</div>
								<div>{t("Common.TableSettings")}</div>
							</div>
						</div>
						{renderLoader()}
						{renderTable()}
					</section>
				</div>
				{renderSettings()}
				<OperatorOrderRequestDetails
					onClose={handleOrderClose}
					onCollapse={handleOrderCollapse}
					onExpand={handleOrderExpand}
					collapse={collapse}
					onConsolidate={handleOrderConsolidation}
				/>
			</div>
		</div>
	);
}
