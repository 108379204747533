import client from "./networking";

/**
 *  Gets accesorials catalog.
 * @returns Instance `AxiosResponse`
 */
export function getAccesorials() {
  // Make request and return.
  return client.get("/api/catalogs/accessorials");
}

/**
 *  Gets accesorials catalog.
 * @returns Instance `AxiosResponse`
 */
export function getLocationTypes() {
  // Make request and return.
  return client.get("/api/catalogs/location_types");
}

/**
 *  Gets accesorials catalog.
 * @returns Instance `AxiosResponse`
 */
export function getQualifications() {
  // Make request and return.
  return client.get("/api/catalogs/qualifications");
}

/**
 *  Gets packaging catalog.
 * @returns Instance `AxiosResponse`
 */
export function getPackagings() {
  // Make request and return.
  return client.get("/api/packaging");
}

/**
 * Gets branch catalog.
 * @param {*} data From data
 * @returns Instance `AxiosResponse`
 */
export const getBranchCatalog = (data) => {
	return client.get("/api/catalogs/branches", {params: data});
}

/**
 *  Gets packaging catalog.
 * @returns Instance `AxiosResponse`
 */
export function getEquipments() {
  // Make request and return.
  return client.get("/api/equipment_types/catalog");
}