import React from "react";
import {
	ADDRESS_TYPE,
	CONTACT_TYPE,
	LOCATION_TYPES,
	SHIPMENT_TYPE,
} from "features/constants";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/dateUtils";

/**
 * Renders origin and destination addresses
 * @param {*} order order_request
 * @param {*} type ADDRESS_TYPE
 * @returns Rendered order address
 */
export default function OrderAddressItem({
	order,
	type,
	errors = {},
	bookedDates,
}) {
	// Translation
	const { t } = useTranslation();
	if (type == null) {
		return null;
	}
	if (order == null) {
		/* Empty Origin */
		if (type === ADDRESS_TYPE.ORIGIN) {
			return (
				<div className="order-address is-port">
					<div className="order-address-title">
						<div>{t("Common.Origin")}</div>
					</div>
					<div className="origin-dates">
						<div>
							<div>{t("Tables.EstimatedReady")}:</div>
							{<>&nbsp;</>}
						</div>
						<div>
							<div>{t("Tables.EstimatedClose")}:</div>
							{<>&nbsp;</>}
						</div>
					</div>
					<hr />
					<div className="order-address-data">{<>&nbsp;</>}</div>
					<hr />
					<div className="order-address-contact"></div>
					<div className="address-notes">
						<div>
							<div>{t("Common.Notes")}</div>
							<div>{<>&nbsp;</>}</div>
						</div>
					</div>
				</div>
			);
		}
		if (type === ADDRESS_TYPE.DESTINATION) {
			return (
				<div className="order-address is-port">
					<div className="order-address-title">
						<div>{t("Common.Destination")}</div>
					</div>
					<div className="destination-date">
						<div>
							<div>{t("Tables.RequestedDelivery")}:</div>
							{<>&nbsp;</>}
						</div>
					</div>
					<hr />
					<div className="order-address-data">{<>&nbsp;</>}</div>
					<hr />
					<div className="order-address-contact">{<>&nbsp;</>}</div>
					<div className="address-notes">
						<div>
							<div>{t("Common.Notes")}</div>
							<div>{<>&nbsp;</>}</div>
						</div>
					</div>
				</div>
			);
		}
	}
	/* Set the address contact type */
	const contactType =
		type === ADDRESS_TYPE.ORIGIN
			? CONTACT_TYPE.ORIGIN
			: CONTACT_TYPE.DESTINATION;
	/* Gets address object from order_request */
	const getAddress = (type) => {
		if (order.addresses == null) return {};
		const filtered = order.addresses.filter(
			(el) => Number(el.address_type) === type
		);
		const item = filtered.length > 0 ? filtered[0] : {};
		return item;
	};
	/* Gets contact object from order_request */
	const getContact = (type) => {
		if (order.contacts == null) return {};
		const filtered = order.contacts.filter(
			(el) => Number(el.contact_type) === type
		);
		const item = filtered.length > 0 ? filtered[0] : {};
		return item;
	};
	/* Renders port code when applicable */
	const renderPortCode = (type) => {
		if (
			order.transport_shipment_type === SHIPMENT_TYPE.DE &&
			type === ADDRESS_TYPE.ORIGIN
		) {
			return (
				<div>
					<div>{t("Common.PortCode")}</div>
					<div>{order.origin_rating_port_code ?? <>&nbsp;</>}</div>
				</div>
			);
		}
		if (
			order.transport_shipment_type === SHIPMENT_TYPE.PU &&
			type === ADDRESS_TYPE.DESTINATION
		) {
			return (
				<div>
					<div>{t("Common.PortCode")}</div>
					<div>{order.destination_rating_port_code ?? <>&nbsp;</>}</div>
				</div>
			);
		}
		return null;
	};
	/* Renders formatted address */
	const renderAddress = (addressName, type) => {
		const address = getAddress(type);
		let arr = [];
		arr.push(<span>{addressName}</span>);
		arr.push(<br />);
		if (address.address_line_1 != null) {
			arr.push(address.address_line_1);
			arr.push(<br />);
		}
		if (address.address_line_2 != null) {
			arr.push(address.address_line_2);
			arr.push(<br />);
		}
		const cityLine = [
			address.city,
			address.state_province,
			address.postal_code,
			address.country_code,
		]
			.filter(Boolean)
			.join(", ");
		if (cityLine.trim().length > 0) arr.push(cityLine);
		return (
			<div>
				{arr.map((el, index) => {
					return <React.Fragment key={index}>{el}</React.Fragment>;
				})}
			</div>
		);
	};
	/* Renders formatted contact info */
	const renderContact = (type) => {
		const contact = getContact(type);
		let arr = [];
		if (contact.name != null) {
			arr.push(contact.name);
			arr.push(<br />);
		}
		if (contact.phone != null) {
			arr.push(contact.phone);
			arr.push(<br />);
		}
		if (contact.email != null) {
			arr.push(contact.email);
			arr.push(<br />);
		}
		if (arr.length > 1) {
			arr.pop();
		}
		return (
			<div>
				{arr.map((el, index) => {
					return <React.Fragment key={index}>{el}</React.Fragment>;
				})}
			</div>
		);
	};
	/* Renders formatted date */
	const renderDate = (date) => {
		const formatted =
			date != null ? formatDate(date, t("Dates.DateTime"), false) : <>&nbsp;</>;
		return <div>{formatted}</div>;
	};
	/* Renders Origin address */
	const readyProps = errors?.estimated_ready ? { className: "error" } : {};
	const closeProps = errors?.estimated_close ? { className: "error" } : {};
	if (type === ADDRESS_TYPE.ORIGIN) {
		return (
			<div className="order-address is-port">
				<div className="order-address-title">
					<div>{t("Common.Origin")}</div>
					{renderPortCode(type)}
				</div>
				<div className="origin-dates">
					<div {...readyProps}>
						<div>{t("Tables.EstimatedReady")}:</div>
						{renderDate(
							bookedDates?.estimated_ready ??
								order.origin_estimated_ready_date_time
						)}
					</div>
					<div {...closeProps}>
						<div>{t("Tables.EstimatedClose")}:</div>
						{renderDate(
							bookedDates?.estimated_close ??
								order.origin_estimated_close_date_time
						)}
					</div>
				</div>
				<hr />
				<div className="order-address-data">
					{renderAddress(order.origin_name, type)}
				</div>
				<hr />
				<div className="order-address-contact">
					{renderContact(contactType)}
				</div>
				<div className="address-notes">
					<div>
						<div>{t("Common.Notes")}</div>
						<div>{order.origin_trucker_notes ?? <>&nbsp;</>}</div>
					</div>
				</div>
				<hr />
				<div className="order-address-contact">
					{order.origin_location_type_id != null
						? t(LOCATION_TYPES.properties[order.origin_location_type_id].name)
						: ""}
				</div>
			</div>
		);
	}
	/* Renders Destination address */
	const deliveryProps = errors.requested_delivery ? { className: "error" } : {};
	if (type === ADDRESS_TYPE.DESTINATION) {
		return (
			<div className="order-address is-port">
				<div className="order-address-title">
					<div>{t("Common.Destination")}</div>
					{renderPortCode(type)}
				</div>
				<div className="destination-date">
					<div {...deliveryProps}>
						<div>{t("Tables.RequestedDelivery")}:</div>
						{renderDate(
							bookedDates?.requested_delivery ??
								order.destination_requested_delivery_date_time
						)}
					</div>
				</div>
				<hr />
				<div className="order-address-data">
					{renderAddress(order.destination_name, type)}
				</div>
				<hr />
				<div className="order-address-contact">{renderContact(type)}</div>
				<div className="address-notes">
					<div>
						<div>{t("Common.Notes")}</div>
						<div>{order.destination_trucker_delivery_notes ?? <>&nbsp;</>}</div>
					</div>
				</div>
				<hr />
				<div className="order-address-contact">
					{order.destination_location_type_id != null
						? t(
								LOCATION_TYPES.properties[order.destination_location_type_id]
									.name
						  )
						: ""}
				</div>
			</div>
		);
	}
	return null;
}
