import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import HandleClickOutside from "hooks/handleClickOutsideHook";
import TimePicker from "react-time-picker";
import { format, isValid } from "date-fns";
import { useFloating, autoUpdate } from "@floating-ui/react-dom";
import {
	formatDateToString,
	stringToDateWithFormat,
	//locale,
	locales,
} from "utils/dateUtils";
import Select from "react-select";
import {
	toAddressEditForm,
	filterLocationTypes,
	filterAccesorials,
} from "utils/orderUtils";
import { dateRegEx, timeRegEx, portCodeRegEx } from "utils/regExs";
import {
	ORDER_EDIT,
	SHIPMENT_TYPE,
	ACCESSORIALS,
	LOCATION_TYPES,
} from "features/constants";
import Loader from "components/Loader";
import "react-day-picker/dist/style.css";
import { removeEmptyProps } from "utils/formUtils";

export default function OrderAddressForm({
	order,
	edit,
	onSave,
	onCancel,
	isSaving,
	isLoading,
	accesorials,
	locationTypes,
}) {
	// Translation
	const { t, i18n } = useTranslation();
	// State
	const [showDayPicker, setShowDayPicker] = useState(false);
	const [focusedDateFieldName, setFocusedDateFieldName] = useState("");

	// Day picker positioning.
	const { x, y, refs, strategy } = useFloating({
		placement: "bottom-start",
		showDayPicker,
		onOpenChange: setShowDayPicker,
		whileElementsMounted: autoUpdate,
	});

	// Form initialization
	const {
		control,
		register,
		// watch,
		setValue,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	// Effect
	useEffect(() => {
		reset(toAddressEditForm(order, i18n.language));
	}, [order, i18n.language, reset]);

	const dateRegExPattern = dateRegEx(i18n.language);

	// Submit Form
	const handleSaveAddressData = (data, event) => {
		event.preventDefault();
		data = {...data, origin_accessorials: [], destination_accessorials: []};
		// TODO: Use react-hook-form transform.
		if (data.origin_estimated_close_date && data.origin_estimated_close_time) {
			const estimatedCloseDate = stringToDateWithFormat(
				data.origin_estimated_close_date,
				t("Dates.FieldDate")
			);
			const hours = data.origin_estimated_close_time.getHours();
			const minutes = data.origin_estimated_close_time.getMinutes();

			estimatedCloseDate.setHours(hours);
			estimatedCloseDate.setMinutes(minutes);
			data.origin_estimated_close_date_time =
				formatDateToString(estimatedCloseDate);
		}
		// TODO: Use react-hook-form transform.
		if (data.origin_estimated_ready_date && data.origin_estimated_ready_time) {
			const estimatedReadyDate = stringToDateWithFormat(
				data.origin_estimated_ready_date,
				t("Dates.FieldDate")
			);
			const hours = data.origin_estimated_ready_time.getHours();
			const minutes = data.origin_estimated_ready_time.getMinutes();

			estimatedReadyDate.setHours(hours);
			estimatedReadyDate.setMinutes(minutes);

			data.origin_estimated_ready_date_time =
				formatDateToString(estimatedReadyDate);
		}

		// TODO: Use react-hook-form transform.
		if (
			data.destination_requested_delivery_date &&
			data.destination_requested_delivery_time
		) {
			const destinationRequestedDate = stringToDateWithFormat(
				data.destination_requested_delivery_date,
				t("Dates.FieldDate")
			);
			const hours = data.destination_requested_delivery_time.getHours();
			const minutes = data.destination_requested_delivery_time.getMinutes();
			destinationRequestedDate.setHours(hours);
			destinationRequestedDate.setMinutes(minutes);
			data.destination_requested_delivery_date_time = formatDateToString(
				destinationRequestedDate
			);
		}

		const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
		data = {
			...data,
			origin_estimated_ready_date_time_timezone: timeZone,
			origin_estimated_close_date_time_timezone: timeZone,
			destination_requested_delivery_date_time_timezone: timeZone,
		};

		// Origin accessorials
		const origin_accessorials = data.origin_accessorials_list?.length > 0 ? data.origin_accessorials_list.map((el) => el.value) : undefined;
		delete data["origin_accessorials_list"];

		// Destination accessorials
		const destination_accessorials = data.destination_accessorials_list?.length > 0 ? data.destination_accessorials_list.map((el) => el.value) : undefined;
		delete data["destination_accessorials_list"];

		if (data.destination_location_type) {
			data.destination_location_type_id = data.destination_location_type.value;
			delete data["origin_location_type_id"];
		}
		delete data["destination_location_type"];

		// TODO: Transform from react-hook-form.
		if (data.origin_location_type) {
			data.origin_location_type_id = data.origin_location_type.value;
		}
		delete data["origin_location_type"];

		if(order?.transport_shipment_type === SHIPMENT_TYPE.DE) {
			delete data["destination_rating_port_code"];
		};
		if (order?.transport_shipment_type === SHIPMENT_TYPE.PU) {
			delete data["origin_rating_port_code"];
		};

		const cleanData = removeEmptyProps(data);

		if (order != null) {
			const formData = {...cleanData, origin_accessorials, destination_accessorials}
			onSave(formData);
		}
	};

	const handleCancelEdit = () => {
		// Clean form.
		reset();
		onCancel();
	};

	//   watch("origin_estimated_ready_date");

	const handleDayClick = (day, modifiers) => {
		// Uodate value.
		setValue(focusedDateFieldName, format(day, t("Dates.FieldDate")));
		// Kill focusrenderTimePicker(value, "origin_estimated_close_time")
		setShowDayPicker(false);
	};
	const handleDateFieldChange = (e) => {
		setValue(focusedDateFieldName, e.currentTarget.value);
		if (dateRegExPattern.test(e.currentTarget.value)) {
			const date = stringToDateWithFormat(
				e.currentTarget.value,
				t("Dates.FieldDate")
			);
			if (isValid(date)) {
				setValue(focusedDateFieldName, format(date, t("Dates.FieldDate")));
			}
		}
	};

	/**
	 * Function that renders DayPicker component.
	 * @returns DayPicker component wrapped with positioning and handle click outside functionality.
	 */
	const renderDayPicker = () => {
		return (
			<>
				<div
					ref={refs.setFloating}
					className="daypicker"
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
					}}
				>
					<HandleClickOutside onClickOutside={(e) => setShowDayPicker(false)}>
						<DayPicker
							mode="single"
							locale={locales[i18n.resolvedLanguage]}
							onDayClick={handleDayClick}
							selected={
								dateRegExPattern.test(getValues(focusedDateFieldName))
									? stringToDateWithFormat(
											getValues(focusedDateFieldName),
											t("Dates.FieldDate")
									  )
									: null
							}
							defaultMonth={
								dateRegExPattern.test(getValues(focusedDateFieldName))
									? stringToDateWithFormat(
											getValues(focusedDateFieldName),
											t("Dates.FieldDate")
									  )
									: null
							}
						/>
					</HandleClickOutside>
				</div>
			</>
		);
	};

	/**
	 *
	 * @param {*} value
	 * @param {*} fieldName
	 * @returns
	 */
	const renderDateField = (value, inputName) => {
		return (
			<>
				<input
					type="text"
					value={value}
					onFocus={() => setFocusedDateFieldName(inputName)}
					onClick={() => setShowDayPicker(true)}
					onChange={handleDateFieldChange}
					placeholder={t("Dates.Placeholder")}
				></input>
			</>
		);
	};

	/**
	 *
	 * @param {*} value
	 * @param {*} fieldName
	 * @returns
	 */
	const renderTimePicker = (value, inputName) => {
		return (
			<>
				<TimePicker
					format={t("Dates.Time")}
					disableClock={true}
					hourPlaceholder={"00"}
					minutePlaceholder={"00"}
					onChange={(value) => {
						// Set new date.
						const newDate = new Date();
						// Keep hours.
						if (timeRegEx.test(value) && newDate) {
							var timeValues = value.split(":");
							newDate.setHours(
								parseInt(timeValues[0]),
								parseInt(timeValues[1])
							);
						}
						setValue(inputName, newDate);
					}}
					value={value}
					clearIcon={null}
					locale={i18n.language}
				/>
			</>
		);
	};

	/**
	 *
	 * @returns
	 */
	const render = () => {
		return (
			<div className="order-block edit">
				<div className="order-subtitle">
					<div>{t("Common.AddressInfo")}</div>
					<div>
						<span className="icon-edit-close" onClick={handleCancelEdit}></span>
					</div>
					<div>
						<span
							className="icon-edit-save"
							onClick={handleSubmit(handleSaveAddressData)}
						></span>
					</div>
				</div>
				<div className="order-grid-two">
					<div>
						<div className="dsv-form half">
							<div className="a">
								<div className="title">{t("Common.Origin")}</div>
							</div>
							<div
								className={classNames("a field", {
									error: errors.origin_name,
								})}
							>
								<label>{t("Common.Name")}</label>
								<input
									type="text"
									id="origin_name"
									name="origin_name"
									placeholder={t("Common.Name")}
									{...register("origin_name", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames(
									"a field",
									{
										hidden:
											(order?.transport_shipment_type ?? false) !==
											SHIPMENT_TYPE.DE,
									},
									{
										error: errors.origin_rating_port_code,
									}
								)}
							>
								<label>{t("Fields.PortCode")}</label>
								<input
									type="text"
									id="origin_rating_port_code"
									name="origin_rating_port_code"
									placeholder={t("Placeholders.PortCode")}
									{...((order?.transport_shipment_type ?? false) ===
									SHIPMENT_TYPE.DE
										? register("origin_rating_port_code", {
												required: true,
												pattern: portCodeRegEx,
												onChange: (e) => {
													const clean = e.target.value
														.toUpperCase()
														.replace(/[^A-Z]/, "")
														.substring(0, 3);
													setValue("origin_rating_port_code", clean);
												},
										  })
										: {})}
								></input>
							</div>

							<Controller
								control={control}
								name="origin_estimated_ready_date"
								rules={{
									pattern: dateRegExPattern,
								}}
								render={({ field: { value } }) => {
									return (
										<>
											<div
												className={classNames("b-1 field", {
													error: errors.origin_estimated_ready_date,
												})}
												ref={
													focusedDateFieldName === "origin_estimated_ready_date"
														? refs.setReference
														: null
												}
											>
												<label>{t("Fields.EstimatedReady")}</label>
												{renderDateField(value, "origin_estimated_ready_date")}
												{showDayPicker &&
													focusedDateFieldName ===
														"origin_estimated_ready_date" &&
													renderDayPicker()}
											</div>
										</>
									);
								}}
							/>

							<Controller
								control={control}
								name="origin_estimated_ready_time"
								rules={{ required: true }}
								render={({ field: { value } }) => (
									<>
										<div
											className={classNames("b-2 field", {
												error: errors.origin_estimated_ready_time,
											})}
										>
											<label>&nbsp;</label>
											{renderTimePicker(value, "origin_estimated_ready_time")}
										</div>
									</>
								)}
							/>

							<Controller
								control={control}
								name="origin_estimated_close_date"
								rules={{
									pattern: dateRegExPattern,
								}}
								render={({ field: { value } }) => (
									<>
										<div
											className={classNames("b-1 field", {
												error: errors.origin_estimated_close_date,
											})}
											ref={
												focusedDateFieldName === "origin_estimated_close_date"
													? refs.setReference
													: null
											}
										>
											<label>Estimated Close</label>
											{renderDateField(value, "origin_estimated_close_date")}
											{showDayPicker &&
												focusedDateFieldName ===
													"origin_estimated_close_date" &&
												renderDayPicker()}
										</div>
									</>
								)}
							/>

							<Controller
								control={control}
								name="origin_estimated_close_time"
								render={({ field: { value } }) => (
									<>
										<div
											className={classNames("b-2 field", {
												error: errors.origin_estimated_close_time,
											})}
										>
											<label>&nbsp;</label>
											{renderTimePicker(value, "origin_estimated_close_time")}
										</div>
									</>
								)}
							/>

							<div
								className={classNames("a field top-separation", {
									error: errors.origin_address?.address_line_1,
								})}
							>
								<label>{t("Fields.AddressLine1")}</label>
								<input
									type="text"
									id="origin_address.address_line_1"
									name="origin_address.address_line_1"
									placeholder={t("Fields.AddressLine1")}
									{...register("origin_address.address_line_1", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.origin_address?.address_line_2,
								})}
							>
								<label>{t("Fields.AddressLine2")}</label>
								<input
									type="text"
									id="origin_address.address_line_2"
									name="origin_address.address_line_2"
									placeholder={t("Fields.AddressLine2")}
									{...register("origin_address.address_line_2", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.origin_address?.city,
								})}
							>
								<label>{t("Fields.City")}</label>
								<input
									type="text"
									id="origin_address.city"
									name="origin_address.city"
									placeholder={t("Fields.City")}
									{...register("origin_address.city", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-2 field", {
									error: errors.origin_address?.state_province,
								})}
							>
								<label>{t("Fields.StateProvince")}</label>
								<input
									type="text"
									id="origin_address.state_province"
									name="origin_address.state_province"
									placeholder={t("Fields.StateProvince")}
									{...register("origin_address.state_province", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.origin_address?.postal_code,
								})}
							>
								<label>{t("Fields.PostalCode")}</label>
								<input
									type="text"
									id="origin_address.postal_code"
									name="origin_address.postal_code"
									placeholder={t("Fields.PostalCode")}
									{...register("origin_address.postal_code", {
										required:
											(order?.transport_shipment_type ?? false) ===
											SHIPMENT_TYPE.PU,
									})}
								></input>
							</div>
							<div
								className={classNames("b-2 field", {
									error: errors.origin_address?.country_code,
								})}
							>
								<label>{t("Fields.CountryCode")}</label>
								<input
									type="text"
									id="origin_address.country_code"
									name="origin_address.country_code"
									placeholder={t("Fields.CountryCode")}
									{...register("origin_address.country_code", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field top-separation", {
									error: errors.origin_contact?.name,
								})}
							>
								<label>{t("Fields.ContactName")}</label>
								<input
									type="text"
									id="origin_contact.name"
									name="origin_contact.name"
									placeholder={t("Common.Name")}
									{...register("origin_contact.name", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.origin_contact?.phone,
								})}
							>
								<label>{t("Fields.ContactPhone")}</label>
								<input
									type="text"
									id="origin_contact.phone"
									name="origin_contact.phone"
									placeholder={t("Common.Phone")}
									{...register("origin_contact.phone", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.origin_contact?.email,
								})}
							>
								<label>{t("Fields.ContactEmail")}</label>
								<input
									type="text"
									id="origin_contact.email"
									name="origin_contact.email"
									placeholder={t("Common.Email")}
									{...register("origin_contact.email", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field top-separation", {
									error: errors.origin_trucker_notes,
								})}
							>
								<label>{t("Fields.TruckerNotes")}</label>
								<textarea
									rows="5"
									id="origin_trucker_notes"
									name="origin_trucker_notes"
									placeholder={t("Fields.TruckerNotes")}
									{...register("origin_trucker_notes", {
										required: false,
									})}
								></textarea>
							</div>

							<div
								className={classNames("a field", {
									hidden:
										filterLocationTypes(
											locationTypes,
											(order?.transport_shipment_type ?? false) ===
												SHIPMENT_TYPE.DE
										).length === 0,
									error: errors.origin_location_type,
								})}
							>
								<label>{t("Fields.LocationType")}</label>
								<Controller
									name="origin_location_type"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											// {...field}
											onChange={onChange}
											value={
												value
													? {
															value:
																LOCATION_TYPES.properties[`${value.value}`]
																	.value,
															label: t(
																LOCATION_TYPES.properties[`${value.value}`].name
															),
													  }
													: {}
											}
											options={filterLocationTypes(
												locationTypes,
												(order?.transport_shipment_type ?? false) ===
													SHIPMENT_TYPE.DE
											)}
										/>
									)}
								/>
							</div>
							<div
								className={classNames("a field", {
									hidden:
										filterAccesorials(
											accesorials,
											(order?.transport_shipment_type ?? false) ===
												SHIPMENT_TYPE.DE
										).length === 0,
									error: errors.origin_accessorials_list,
								})}
							>
								<label>{t("Fields.Accessorials")}</label>
								<Controller
									name="origin_accessorials_list"
									control={control}
									render={({ field }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											isClearable={false}
											closeMenuOnSelect={false}
											isMulti={true}
											{...field}
											options={filterAccesorials(
												accesorials,
												(order?.transport_shipment_type ?? false) ===
													SHIPMENT_TYPE.DE
											)}
										/>
									)}
								/>
							</div>
						</div>
					</div>
					<div>
						<div className="dsv-form half">
							<div className="order-address-title a">
								<div className="title">{t("Common.Destination")}</div>
							</div>
							<div
								className={classNames("a field", {
									error: errors.destination_name,
								})}
							>
								<label>{t("Common.Name")}</label>
								<input
									type="text"
									id="destination_name"
									name="destination_name"
									placeholder={t("Common.Name")}
									{...register("destination_name", {
										required: false,
									})}
								></input>
							</div>

							<div
								className={classNames(
									"a field",
									{
										hidden:
											(order?.transport_shipment_type ?? false) !==
											SHIPMENT_TYPE.PU,
									},
									{
										error: errors.destination_rating_port_code,
									}
								)}
							>
								<label>{t("Fields.PortCode")}</label>
								<input
									type="text"
									id="destination_rating_port_code"
									name="destination_rating_port_code"
									placeholder={t("Placeholders.PortCode")}
									{...((order?.transport_shipment_type ?? false) ===
									SHIPMENT_TYPE.PU
										? register("destination_rating_port_code", {
												required:
													(order?.transport_shipment_type ?? false) ===
													SHIPMENT_TYPE.PU,
												pattern: portCodeRegEx,
												onChange: (e) => {
													const clean = e.target.value
														.toUpperCase()
														.replace(/[^A-Z]/, "")
														.substring(0, 3);
													setValue("destination_rating_port_code", clean);
												},
										  })
										: {})}
								></input>
							</div>

							<Controller
								control={control}
								name="destination_requested_delivery_date"
								rules={{
									pattern: dateRegExPattern,
								}}
								render={({ field: { value } }) => (
									<>
										<div
											className={classNames("b-1 field", {
												error: errors.destination_requested_delivery_date,
											})}
											ref={
												focusedDateFieldName ===
												"destination_requested_delivery_date"
													? refs.setReference
													: null
											}
										>
											<label>{t("Fields.RequestedDelivery")}</label>
											{renderDateField(
												value,
												"destination_requested_delivery_date"
											)}
											{showDayPicker &&
												focusedDateFieldName ===
													"destination_requested_delivery_date" &&
												renderDayPicker()}
										</div>
									</>
								)}
							/>

							<Controller
								control={control}
								name="destination_requested_delivery_time"
								render={({ field: { value } }) => (
									<>
										<div
											className={classNames("b-2 field", {
												error: errors.destination_requested_delivery_time,
											})}
										>
											<label>&nbsp;</label>
											{renderTimePicker(
												value,
												"destination_requested_delivery_time"
											)}
										</div>
									</>
								)}
							/>

							<div className="b-1 field hidden">
								<label>{t("Fields.RequestedDelivery")}</label>
								<input
									type="text"
									id="estimated-delivery-date"
									name="estimated-delivery-date"
									placeholder={t("Dates.Placeholder")}
								></input>
							</div>

							<div
								className={classNames("a field top-separation", {
									error: errors.destination_address?.address_line_1,
								})}
							>
								<label>{t("Fields.AddressLine1")}</label>
								<input
									type="text"
									id="destination_address.address_line_1"
									name="destination_address.address_line_1"
									placeholder={t("Fields.AddressLine1")}
									{...register("destination_address.address_line_1", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.destination_address?.address_line_2,
								})}
							>
								<label>{t("Fields.AddressLine2")}</label>
								<input
									type="text"
									id="destination_address.address_line_2"
									name="destination_address.address_line_2"
									placeholder={t("Fields.AddressLine2")}
									{...register("destination_address.address_line_2", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.destination_address?.city,
								})}
							>
								<label>{t("Fields.City")}</label>
								<input
									type="text"
									id="destination_address.city"
									name="destination_address.city"
									placeholder={t("Fields.City")}
									{...register("destination_address.city", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-2 field", {
									error: errors.destination_address?.state_province,
								})}
							>
								<label>{t("Fields.StateProvince")}</label>
								<input
									type="text"
									id="destination_address.state_province"
									name="destination_address.state_province"
									placeholder={t("Fields.StateProvince")}
									{...register("destination_address.state_province", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.destination_address?.postal_code,
								})}
							>
								<label>{t("Fields.PostalCode")}</label>
								<input
									type="text"
									id="destination_address.postal_code"
									name="destination_address.postal_code"
									placeholder={t("Fields.PostalCode")}
									{...register("destination_address.postal_code", {
										required:
											(order?.transport_shipment_type ?? false) ===
											SHIPMENT_TYPE.DE,
									})}
								></input>
							</div>
							<div
								className={classNames("b-2 field", {
									error: errors.destination_address?.country_code,
								})}
							>
								<label>{t("Fields.CountryCode")}</label>
								<input
									type="text"
									id="destination_address.country_code"
									name="destination_address.country_code"
									placeholder={t("Fields.CountryCode")}
									{...register("destination_address.country_code", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field top-separation", {
									error: errors.destination_contact?.name,
								})}
							>
								<label>{t("Fields.ContactName")}</label>
								<input
									type="text"
									id="destination_contact.name"
									name="destination_contact.name"
									placeholder={t("Common.Name")}
									{...register("destination_contact.name", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.destination_contact?.phone,
								})}
							>
								<label>{t("Fields.ContactPhone")}</label>
								<input
									type="text"
									id="destination_contact.phone"
									name="destination_contact.phone"
									placeholder={t("Common.Phone")}
									{...register("destination_contact.phone", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.destination_contact?.email,
								})}
							>
								<label>{t("Fields.ContactEmail")}</label>
								<input
									type="text"
									id="destination_contact.email"
									name="destination_contact.email"
									placeholder={t("Common.Email")}
									{...register("destination_contact.email", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field top-separation", {
									error: errors.destination_trucker_delivery_notes,
								})}
							>
								<label>{t("Fields.TruckerNotes")}</label>
								<textarea
									rows="5"
									id="destination_trucker_delivery_notes"
									name="destination_trucker_delivery_notes"
									placeholder={t("Fields.TruckerNotes")}
									{...register("destination_trucker_delivery_notes", {
										required: false,
									})}
								></textarea>
							</div>
							<div
								className={classNames("a field", {
									hidden:
										filterLocationTypes(
											locationTypes,
											(order?.transport_shipment_type ?? false) ===
												SHIPMENT_TYPE.PU
										).length === 0,
									error: errors.destination_location_type,
								})}
							>
								<label>{t("Fields.LocationType")}</label>
								<Controller
									name="destination_location_type"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											isClearable={false}
											onChange={onChange}
											value={
												value
													? {
															value:
																LOCATION_TYPES.properties[`${value.value}`]
																	.value,
															label: t(
																LOCATION_TYPES.properties[`${value.value}`].name
															),
													  }
													: {}
											}
											options={filterLocationTypes(
												locationTypes,
												(order?.transport_shipment_type ?? false) ===
													SHIPMENT_TYPE.PU
											)}
										/>
									)}
								/>
							</div>
							<div
								className={classNames("a field", {
									hidden:
										filterAccesorials(
											accesorials,
											(order?.transport_shipment_type ?? false) ===
												SHIPMENT_TYPE.PU
										).length === 0,
									error: errors.destination_accessorials_list,
								})}
							>
								<label>{t("Fields.Accessorials")}</label>
								<Controller
									name="destination_accessorials_list"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											isClearable={false}
											closeMenuOnSelect={false}
											isMulti={true}
											onChange={onChange}
											value={
												value
													? value.map((item, index) => {
															return {
																value:
																	ACCESSORIALS.properties[`${item.value}`]
																		.value,
																label: t(
																	ACCESSORIALS.properties[`${item.value}`].name
																),
															};
													  })
													: value
											}
											options={filterAccesorials(
												accesorials,
												(order?.transport_shipment_type ?? false) ===
													SHIPMENT_TYPE.PU
											)}
										/>
									)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	if (
		edit != null &&
		edit === ORDER_EDIT.ADDRESS &&
		order != null &&
		!isLoading
	) {
		if (isSaving) {
			return (
				<div className="order-block edit">
					<div className="order-subtitle">
						<div>{t("Common.AddressInfo")}</div>
					</div>
					<Loader msg={t("Common.Saving")} />
				</div>
			);
		}
		return render();
	}

	return null;
}
