import React from "react";
import EmailListForm from "../emailList/EmailListForm";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

const OrderNotifyPartiesForm = ({ order, onSave, isSaving, onCancel }) => {
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleSave = (data) => {
		const formData = { ...data };
		onSave(formData);
	};
	// Render
	if (isSaving) {
		return (
			<div className="order-block edit bottom-border">
				<div className="dsv-form full no-padding">
					<div className="a order-subtitle">
						<div>{t("Common.NotifyParties")}</div>
					</div>
					<div className="a">
						<Loader />
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="order-block edit bottom-border">
			<div className="dsv-form full no-padding">
				<EmailListForm
					title="Common.NotifyParties"
					emailList={order?.email_send_list}
					onSave={handleSave}
					onCancel={() => onCancel()}
				/>
			</div>
		</div>
	);
};
export default OrderNotifyPartiesForm;
