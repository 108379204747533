import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DOCUMENT_TYPE } from "features/constants";
import {
	getCarrierOrderDetails,
	getCarrierOrderLog,
	postCarrierOrders,
} from "services/carrierServices";
import { postChangeBookedOrderStatus } from "services/changeStatusServices";
import {
	getBookedOrderDocuments,
	uploadBookedOrderDocument,
} from "services/documentServices";
import { postEmailToOperator } from "services/emailServices";
import { getBookedOrderTabs } from "utils/orderUtils";

const initialState = {
	/* Orders list */
	list: null,
	isLoading: false,
	refresh: false,
	/* Open order  */
	opened: null,
	isOpening: false,
	tabs: null,
	activeTab: 0,
	/* Fetch order status */
	openedLog: null,
	isLoadingLog: false,
	isChangingStatus: false,
	changeStatusError: null,
	/* Fetch order documents */
	docs: null,
	isLoadingDocs: false,
	showUpload: false,
	isUploading: false,
	uploadPercent: 0,
	/* Send Email */
	isSendingEmail: false,
	isEmailSent: false,
};

/**
 * Async fetch functions
 */

/* Get carrier's order list */
export const fetchCarrierHistory = createAsyncThunk(
	"carrier/fetchCarrierHistory",
	async (payload, { rejectWithValue }) => {
		const { filters, page } = payload;
		try {
			const response = await postCarrierOrders(filters, page);
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);
/* Get carrier's opened history order details */
export const fetchCarrierHistoryDetails = createAsyncThunk(
	"carrier/fetchCarrierHistoryDetails",
	async (payload, { dispatch }) => {
		const { id } = payload;
		const response = await getCarrierOrderDetails(id);
		dispatch(fetchCarrierHistoryLog({ id }));
		dispatch(fetchCarrierHistoryDocs({ id }));
		const tabs = getBookedOrderTabs(response.data.data);
		dispatch(setCarrierHistoryTabs({ tabs }));
		return response;
	}
);
/* Get carrier's opened history order log */
export const fetchCarrierHistoryLog = createAsyncThunk(
	"carrier/fetchCarrierHistoryLog",
	async (payload) => {
		const response = await getCarrierOrderLog(payload.id);
		return response;
	}
);
/* Change carrier's opened history status */
export const fetchCarrierHistoryChangeStatus = createAsyncThunk(
	"carrier/fetchCarrierHistoryChangeStatus",
	async (payload, { dispatch, rejectWithValue }) => {
		const { id, public_id, status, date_specified, location, notes } = payload;
		try {
			const response = await postChangeBookedOrderStatus(
				id,
				public_id,
				status,
				date_specified,
				location,
				notes
			);
			dispatch(fetchCarrierHistoryDetails({ id }));
			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
/* Get carrier's opened history order documents */
export const fetchCarrierHistoryDocs = createAsyncThunk(
	"carrier/fetchCarrierHistoryDocs",
	async (payload) => {
		const { id } = payload;
		const response = await getBookedOrderDocuments(id);
		return response;
	}
);
/* Upload document */
export const fetchCarrierHistoryUploadDoc = createAsyncThunk(
	"documents/fetchCarrierHistoryUploadDoc",
	async (payload, { dispatch }) => {
		const { booked_order_id, type, file } = payload;
		const response = await uploadBookedOrderDocument(
			booked_order_id,
			type,
			file,
			(progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);
				dispatch(setCarrierHistoryUploadProgress(percent));
			}
		);
		if (type === DOCUMENT_TYPE.POD) {
			dispatch(setCarrierHistoryRefresh({ refresh: true}));
		};
		dispatch(fetchCarrierHistoryDocs({ id: booked_order_id }));
		return response;
	}
);
/* Send Email to Operator */
export const fetchCarrierHistorySendEmailToOperator = createAsyncThunk(
	"carrier/fetchCarrierHistorySendEmailToOperator",
	async (payload) => {
		const { booked_order_id, data } = payload;
		const response = await postEmailToOperator(booked_order_id, data);
		return response;
	}
);

/**
 * Redux reducer for carrier history orders
 */
const carrierHistorySlice = createSlice({
	name: "carrierHistory",
	initialState,
	reducers: {
		setCarrierHistoryOpenedOrder: (state, { payload }) => {
			state.opened = payload.opened;
		},
		setCarrierHistoryOrderLog: (state, { payload }) => {
			state.openedLog = payload?.docs ?? null;
		},
		setCarrierHistoryOrderDocs: (state, { payload }) => {
			state.docs = payload?.docs ?? null;
		},
		setCarrierHistoryShowUpload: (state, { payload }) => {
			state.showUpload = payload?.showUpload ?? false;
		},
		setCarrierHistoryStatusError: (state, { payload }) => {
			state.changeStatusError = payload?.error ?? null;
		},
		setCarrierHistoryTabs: (state, { payload }) => {
			state.tabs = payload.tabs ?? null;
		},
		setCarrierHistoryActiveTab: (state, { payload }) => {
			state.activeTab = payload.activeTab ?? 0;
		},
		setCarrierHistoryRefresh: (state, { payload }) => {
			state.refresh = payload?.refresh ?? false;
		},
		setCarrierHistoryUploadProgress: (state, { payload }) => {
			state.uploadPercent = payload ?? 0;
		},
		clearCarrierHistoryEmailSent: (state) => {
			state.isEmailSent = false;
		},
		clearCarrierHistoryOrdersData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/*  Fetch history */
			.addCase(fetchCarrierHistory.pending, (state) => {
				state.isLoading = true;
				state.refresh = false;
			})
			.addCase(fetchCarrierHistory.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
				state.refresh = false;
			})
			.addCase(fetchCarrierHistory.rejected, (state) => {
				state.isLoading = false;
				state.refresh = false;
				state.list = [];
			})
			/* Fetch order details */
			.addCase(fetchCarrierHistoryDetails.pending, (state) => {
				state.isOpening = true;
			})
			.addCase(fetchCarrierHistoryDetails.fulfilled, (state, { payload }) => {
				state.isOpening = false;
				state.opened = payload.data.data;
			})
			.addCase(fetchCarrierHistoryDetails.rejected, (state, { payload }) => {
				state.isOpening = false;
				state.opened = null;
			})
			/* Fetch order log */
			.addCase(fetchCarrierHistoryLog.pending, (state) => {
				state.isLoadingLog = true;
			})
			.addCase(fetchCarrierHistoryLog.fulfilled, (state, { payload }) => {
				state.isLoadingLog = false;
				state.openedLog = payload.data.data;
			})
			.addCase(fetchCarrierHistoryLog.rejected, (state, { payload }) => {
				state.isLoadingLog = false;
				state.openedLog = null;
			})
			/* Fetch order status change */
			.addCase(fetchCarrierHistoryChangeStatus.pending, (state) => {
				state.isChangingStatus = true;
			})
			.addCase(fetchCarrierHistoryChangeStatus.fulfilled, (state) => {
				state.isChangingStatus = false;
				state.refresh = true;
			})
			.addCase(
				fetchCarrierHistoryChangeStatus.rejected,
				(state, { payload }) => {
					state.isChangingStatus = false;
					state.changeStatusError = payload?.message ?? null;
				}
			)
			/* Fetch order docs */
			.addCase(fetchCarrierHistoryDocs.pending, (state) => {
				state.isLoadingDocs = true;
			})
			.addCase(fetchCarrierHistoryDocs.fulfilled, (state, { payload }) => {
				state.isLoadingDocs = false;
				state.docs = payload.data.data;
			})
			.addCase(fetchCarrierHistoryDocs.rejected, (state) => {
				state.isLoadingDocs = false;
				state.docs = [];
			})
			/* Upload document */
			.addCase(fetchCarrierHistoryUploadDoc.pending, (state) => {
				state.isUploading = true;
			})
			.addCase(fetchCarrierHistoryUploadDoc.fulfilled, (state) => {
				state.isUploading = false;
				state.showUpload = false;
			})
			.addCase(fetchCarrierHistoryUploadDoc.rejected, (state) => {
				state.isUploading = false;
			})
			/* Send Email to Operator */
			.addCase(fetchCarrierHistorySendEmailToOperator.pending, (state) => {
				state.isSendingEmail = true;
			})
			.addCase(fetchCarrierHistorySendEmailToOperator.fulfilled, (state) => {
				state.isSendingEmail = false;
				state.isEmailSent = true;
			})
			.addCase(fetchCarrierHistorySendEmailToOperator.rejected, (state) => {
				state.isSendingEmail = false;
			});
	},
});

export const {
	setCarrierHistoryOpenedOrder,
	setCarrierHistoryOrderLog,
	setCarrierHistoryOrderDocs,
	setCarrierHistoryShowUpload,
	setCarrierHistoryStatusError,
	setCarrierHistoryTabs,
	setCarrierHistoryActiveTab,
	setCarrierHistoryRefresh,
	setCarrierHistoryUploadProgress,
	clearCarrierHistoryEmailSent,
	clearCarrierHistoryOrdersData,
} = carrierHistorySlice.actions;

export default carrierHistorySlice.reducer;
