import Loader from "components/Loader";
import {
	CARGO_TYPE,
	CARRIER_MODE,
	CURRENCY,
	RATE_TYPE,
	VOLUME_UNIT,
	WEIGHT_UNIT,
} from "features/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "utils/dateUtils";
import { numberFormat } from "utils/numberUtils";
import {
	fetchAddCurrentCarrierRate,
	fetchAllCurrentCarrierLanes,
	fetchCurrentCarrierRates,
	fetchGetCurrentCarrierCustomersCatalog,
	fetchSaveCurrentCarrierRate,
	setCurrentCarrierRate,
	setCurrentCarrierRateAddEdit,
} from "features/carrier/carrierProfileSlice";
import CarrierRatesAdd from "components/common/carrier/CarrierRatesAdd";
import Pagination from "components/common/pagination/Pagination";
import { convertKgToLb } from "utils/weightUtils";
import { convertCbmToCbf } from "utils/volumeUtils";
import Filters from "components/common/tables/filters/Filters";
import { useFilterContext } from "components/common/tables/filters/FilterContextProvider";
import FilterContext from "components/common/tables/filters/FilterContext";
import TableHead from "components/common/tables/TableHead";
import { filterTransformToData } from "utils/filtersUtils";
import { toSelector } from "utils/constantsUtils";
import admin_default from "settings/admin_default.json";
import { removeEmptyProps } from "utils/formUtils";

const tables = admin_default.settings.carrier_rates;

export default function CarrierProfileRates() {
	const dispatch = useDispatch();
	// Translate
	const { t } = useTranslation();
	// Selectors
	const {
		// tab,
		rates,
		isLoadingRates,
		isSavingRate,
		rate,
		rateAdd,
		rateEdit,
		isLoadingAllLanes,
		allLanes,
		customersCatalog,
		isLoadingCustomersCatalog,
	} = useSelector((state) => state.carrierProfile);
	const { user } = useSelector((state) => state.session);

	const { filterContext } = useFilterContext({
		options: {
			cargo_type: toSelector(CARGO_TYPE.properties, false, true),
			carrier_mode: toSelector(CARRIER_MODE.properties, false, true),
			rate_type: toSelector(RATE_TYPE.properties, false, true),
			volume: toSelector(VOLUME_UNIT.properties, false, true),
			weight: toSelector(WEIGHT_UNIT.properties, false, true),
		},
	});

	// Effect runs once on display.
	useEffect(() => {
		if (customersCatalog == null && !isLoadingCustomersCatalog) {
			dispatch(fetchGetCurrentCarrierCustomersCatalog());
		}

		if (rates == null && !isLoadingRates) {
			dispatch(fetchCurrentCarrierRates({ page: 1, filters: {} }));
		}

		if (allLanes === null && !isLoadingAllLanes) {
			dispatch(fetchAllCurrentCarrierLanes());
		} else if (allLanes != null && filterContext.options?.lanes == null) {
			filterContext.setOptions({
				carrier_lane: allLanes?.map((el) => {
					return {
						value: el.id,
						label: el.name,
					};
				}),
				...filterContext.options,
			});
		}
	}, [
		allLanes,
		dispatch,
		filterContext,
		isLoadingAllLanes,
		isLoadingRates,
		rates,
		customersCatalog,
		isLoadingCustomersCatalog,
	]);

	const handleRateAddClick = () => {
		dispatch(setCurrentCarrierRateAddEdit({ rateAdd: true }));
	};
	// Handlers
	const handleRateClick = (rate, index) => {
		dispatch(setCurrentCarrierRate({ rate: rate }));
		dispatch(setCurrentCarrierRateAddEdit({ rateEdit: true }));
	};
	const handlePageClick = (page) => {
		dispatch(
			fetchCurrentCarrierRates({
				page,
				filters: filterTransformToData(filterContext.filters, tables),
			})
		);
	};

	const onFiltersChanged = (data, e) => {
		e.preventDefault();
		dispatch(
			fetchCurrentCarrierRates({
				page: 1,
				filters: filterTransformToData(data, tables),
			})
		);
	};
	// Render
	// if (tab !== CARRIER_TAB.RATES) return null;
	if (isLoadingRates || isLoadingAllLanes) {
		return <Loader msg={t("Common.Loading")} />;
	}
	if (rateAdd || rateEdit) {
		return (
			<CarrierRatesAdd
				onSave={(data) => {
					dispatch(
						fetchSaveCurrentCarrierRate({
							rateId: rate.id,
							data: data,
						})
					);
				}}
				onAdd={(data) => {
					dispatch(
						fetchAddCurrentCarrierRate({
							data: removeEmptyProps(data),
						})
					);
				}}
				onClose={() => {
					dispatch(setCurrentCarrierRateAddEdit());
				}}
				// currency={currency}
				rate={rateEdit ? rate : null}
				rates={rates}
				allLanes={allLanes}
				isSavingRate={isSavingRate}
				customers={customersCatalog}
				currency={user?.user?.carrier?.currency}
			/>
		);
	}

	const formatWeightRange = (el) => {
		if (el.per_weight) {
			if (el.weight_unit === WEIGHT_UNIT.LBS)
				return `${numberFormat(
					convertKgToLb(el.weight_min),
					0
				)} - ${numberFormat(convertKgToLb(el.weight_max), 0)} ${
					WEIGHT_UNIT.properties[el.weight_unit].code
				}`;
			return `${numberFormat(el.weight_min, 0)} - ${numberFormat(
				el.weight_max,
				0
			)} ${WEIGHT_UNIT.properties[el.weight_unit].code}`;
		} else {
			return "-";
		}
	};
	const formatVolumeRange = (el) => {
		if (el.per_volume) {
			if (el.volume_unit === VOLUME_UNIT.CBF)
				return `${numberFormat(
					convertCbmToCbf(el.volume_min),
					0
				)} - ${numberFormat(convertCbmToCbf(el.volume_max), 0)} ${
					VOLUME_UNIT.properties[el.volume_unit].code
				}`;
			return `${numberFormat(el.volume_min, 0)} - ${numberFormat(
				el.volume_max,
				0
			)} ${VOLUME_UNIT.properties[el.volume_unit].code}`;
		} else {
			return "-";
		}
	};

	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={rates?.current_page ?? 1}
				last={rates?.last_page ?? 1}
				onRefresh={() => {
					handlePageClick(rates?.current_page ?? 1);
				}}
			/>
		);
	};

	return (
		<div className="tab-content details">
			<div className="details-subtitle">
				<div>{t("AdminCarriers.Rates")}</div>
				<div onClick={handleRateAddClick}>
					<span className="icon-add"></span>
				</div>
			</div>
			{renderPagination()}
			<div className="dsv-table">
				<FilterContext.Provider value={filterContext}>
					<Filters onFiltersChanged={onFiltersChanged} />
					<table cellSpacing="0" cellPadding="0">
						<TableHead tables={tables} />
						<tbody>
							{rates?.data?.map((el, index) => {
								const activeProps =
									el?.id === filterContext.activeTable?.id
										? { className: "active" }
										: {};
								return (
									<tr
										{...activeProps}
										key={index}
										onClick={() => handleRateClick(el, index)}
									>
										<td>{t(CARGO_TYPE.properties[el.cargo_type].name)}</td>
										<td>{t(CARRIER_MODE.properties[el.carrier_mode].name)}</td>
										<td>{t(RATE_TYPE.properties[el.rate_type].name)}</td>
										<td className="right">{formatVolumeRange(el)}</td>
										<td className="right">{formatWeightRange(el)}</td>
										<td className="right">
											{el.per_piece
												? `${numberFormat(el.piece_min, 0)} - ${numberFormat(
														el.piece_max,
														0
												  )}`
												: "-"}
										</td>
										<td>{el.carrier_lane?.name}</td>
										<td>
											{el.equipment_type != null ? el.equipment_type.name : "-"}
										</td>
										<td className="right">{`$${numberFormat(
											el.rate * el.exchange_rate,
											2
										)} ${CURRENCY.properties[el.currency].code}`}</td>
										<td>{formatDate(el.created_at, t("Dates.DateTime"))}</td>
										<td>{formatDate(el.updated_at, t("Dates.DateTime"))}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</FilterContext.Provider>
			</div>
			{renderPagination()}
		</div>
	);
}
