import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { passRegEx } from "utils/regExs";
import {
	fetchChangePassword,
	setChangePasswordSucces,
} from "features/account/accountSlice";
import Loader from "components/Loader";
import { PROFILES } from "features/constants";

export default function ChangePassword() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Navigate
	const navigate = useNavigate();
	// Selectors
	const { isChangingPassword, changePasswordErrors, changePasswordSuccess } =
		useSelector((state) => state.account);
	const { user } = useSelector((state) => state.session);

	// Form initialization
	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm();

	// Effect
	useEffect(() => {
		if (changePasswordErrors != null) {
			Object.entries(changePasswordErrors).forEach((key, value) => {
				setError(`${key[0]}`);
			});
		}
	}, [changePasswordErrors, setError]);

	// Handlers
	const handleSave = (data, e) => {
		e.preventDefault();
		if (data.new_password !== data.new_password_confirm) {
			setError("new_password_confirm");
			return;
		}
		const formData = {
			password: data.password,
			new_password: data.new_password,
		};
		dispatch(fetchChangePassword({ data: formData }));
	};
	const handleSuccessClear = () => {
		reset();
		dispatch(setChangePasswordSucces({ changePasswordSuccess: false }));
		navigate(`${PROFILES.properties[user.user.role].url}`);
	};

	// Render
	if (changePasswordSuccess) {
		return (
			<div className="order-container">
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("ChangePassword.Title")}</h1>
							</div>
						</div>
						<div className="order-filters" style={{ maxWidth: "400px" }}>
							<div className="dsv-form">
								<div className="a top-separation">
									{t("ChangePassword.SuccessMessage")}
								</div>
								<div className="a top-separation">
									<div className="button main" onClick={handleSuccessClear}>
										{t("Buttons.Close")}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
	if (isChangingPassword) {
		return (
			<div className="order-container">
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("ChangePassword.Title")}</h1>
							</div>
						</div>
						<div className="order-filters" style={{ maxWidth: "400px" }}>
							<div className="dsv-form">
								<div className="a top-separation">
									<Loader msg={t("Common.Saving")} />
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
	return (
		<div id="default">
			<div className="order-container">
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("ChangePassword.Title")}</h1>
							</div>
						</div>
						<div className="order-filters" style={{ maxWidth: "400px" }}>
							<div className="dsv-form">
								<div
									className={classNames("a field", {
										error: errors.password,
									})}
								>
									<label>{t("Fields.CurrentPassword")}</label>
									<input
										type="password"
										id="password"
										name="password"
										{...register("password", {
											required: true,
										})}
									/>
								</div>
								<div
									className={classNames("a field", {
										error: errors.new_password,
									})}
								>
									<label>{t("Fields.NewPassword")}</label>
									<input
										type="password"
										id="new_password"
										name="new_password"
										autoComplete="off"
										{...register("new_password", {
											required: true,
											pattern: {
												value: passRegEx,
												message:
													"El password debe de contener una minúscula, una maysucula, un carácter especial y minimo 8 letras.",
											},
										})}
									/>
								</div>
								<div
									className={classNames("a field", {
										error: errors.new_password_confirm,
									})}
								>
									<label>{t("Fields.NewPasswordConfirm")}</label>
									<input
										type="password"
										id="new_password_confirm"
										name="new_password_confirm"
										autoComplete="off"
										{...register("new_password_confirm", {
											required: true,
											pattern: {
												value: passRegEx,
												message:
													"El password debe de contener una minúscula, una maysucula, un carácter especial y minimo 8 letras.",
											},
										})}
									/>
								</div>
								<div className="b-1 top-separation">
									<div className="button" onClick={handleSuccessClear}>
										{t("Buttons.Cancel")}
									</div>
								</div>
								<div className="b-2 top-separation">
									<div
										className="button main"
										onClick={handleSubmit(handleSave)}
									>
										{t("Buttons.Save")}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}
