import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchAddCarrier } from "features/admin/carriersSlice";
import CarrierForm from "./CarrierForm";

export default function CarrierAdd({ onClose }) {
  // Translation
  const { t } = useTranslation();
  
  // Dispatch
  const dispatch = useDispatch();

  // Render
  return (
    <div className="item-details">
      <div className="details-container">
        <div className="details-title">
          <div>
            <div className="title-text">
              <span>{t("Add Carrier")}</span>
            </div>
            <div onClick={() => onClose()}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>
        <div className="details-block">
          <div className="tab-content details">
            <div className="a details-subtitle">
              <div>{t("Carrier Info")}</div>
            </div>
            <CarrierForm
              onSumbit={(data) => {
                dispatch(fetchAddCarrier(data));
              }}
              onCancel={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
