import { DIMENSION_UNIT } from "features/constants";

/**
 * Convert from cm to in
 * @param {number} value
 * @returns number
 */
export const convertCmToIn = (value, precision) => {
	if (precision == null) return value * 0.3937007874;
	return Number((value * 0.3937007874).toFixed(precision));
};

/**
 * Convert from in to cm
 * @param {number} value 
 * @param {int} precision 
 * @returns number
 */
export const convertInToCm = (value, precision) => {
	if (precision == null) return value / 0.3937007874;
	return Number((value / 0.3937007874).toFixed(precision));
};

/**
 * Convert from in to ft
 * @param {number} value 
 * @param {int} precision 
 * @returns number
 */
export const convertInToFt = (value, precision) => {
	if (precision == null) return value / 12;
	return Number((value / 12, precision).toFixed(precision));
};

/**
 * Convert from cm to m
 * @param {number} value 
 * @param {int} precision 
 * @returns number
 */
export const convertCmToM = (value, precision) => {
	if (precision == null) return value / 100;
	return Number((value / 100).toFixed(precision));
};

/**
 * Convert from in to m
 * @param {number} value 
 * @param {int} precision 
 * @returns number
 */
export const convertInToM = (value, precision) => {
	if (precision == null) return value / 39.3700787402;
	return Number((value / 39.3700787402).toFixed(precision));
};

/**
 * Convert from cm to ft
 * @param {number} value 
 * @param {int} precision 
 * @returns number
 */
export const convertCmToFt = (value, precision) => {
	if (precision == null) return value * 0.032808399;
	return Number((value * 0.032808399).toFixed(precision));
};

/**
 * Format dimension string
 * @param {number} el 
 * @returns JSX
 */
export 	const formatDimension = (l, w, h, unit) => {
	const codeString = DIMENSION_UNIT.properties[unit]?.code ?? null;
	if (unit === DIMENSION_UNIT.IN) {
		return `${convertCmToIn(l, 2)} x ${convertCmToIn(
			w, 2
		)} x ${convertCmToIn(h, 2)} ${codeString}`;
	}
	return `${l} x ${w} x ${h} ${codeString}`;
};

