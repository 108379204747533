import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import HandleClickOutside from "hooks/handleClickOutsideHook";
// import TimePicker from "react-time-picker";
import { format } from "date-fns";
import { useFloating, autoUpdate } from "@floating-ui/react-dom";
import {
	stringToDateWithFormat,
	formatDateToString,
} from "utils/dateUtils";
import { dateRegEx, portCodeRegEx } from "utils/regExs";
import { DayPicker } from "react-day-picker";
import { selectorToList, toSelector } from "utils/constantsUtils";
import { removeEmptyProps } from "utils/formUtils";

import {
	BOOKED_STATUS,
	BOOKING_METHOD,
	CARGO_TYPE,
	SHIPMENT_TYPE,
} from "features/constants";
import { useDispatch } from "react-redux";
import { fetchOperatorOrdersReport } from "features/operator/operatorOrdersReportSlice";
import { locales } from "utils/dateUtils";

export default function OperatorReports() {
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	// State
	const [clearForm, setClearForm] = useState(false);
	const [showDayPicker, setShowDayPicker] = useState(false);
	const [focusedDateFieldName, setFocusedDateFieldName] = useState("");

	// Effect
	useEffect(() => {
		if (clearForm) {
			const defaultValues = {
				file_number: "",
				alternate_reference_number: "",
				master_bill_number: "",
				house_bill_number: "",
				transport_shipment_type: null,
				transport_cargo_type: null,
				book_status: null,
				booked_method: null,
				origin_estimated_ready_date_time_start: null,
				origin_estimated_ready_date_time_end: null,
				origin_estimated_close_date_time_start: null,
				origin_estimated_close_date_time_end: null,
				destination_requested_delivery_date_time_start: null,
				destination_requested_delivery_date_time_end: null,
				booked_order_booked_start: null,
				booked_order_booked_end: null,
				booked_order_created_start: null,
				booked_order_created_end: null,
				origin_rating_port_code: "",
				origin_address_postal_code: "",
				origin_address_line_1: "",
				destination_rating_port_code: "",
				destination_address_postal_code: "",
				destination_address_line_1: "",
			};
			reset(defaultValues);
			setClearForm(false);
		}
	}, [clearForm, reset]);
	// Translation
	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();

	const handleDayClick = (day, modifiers) => {
		// Uodate value.
		setValue(focusedDateFieldName, format(day, t("Dates.FieldDate")));
		setShowDayPicker(false);
	};

	// Day picker positioning.
	const { x, y, refs, strategy } = useFloating({
		placement: "bottom-start",
		showDayPicker,
		onOpenChange: setShowDayPicker,
		whileElementsMounted: autoUpdate,
	});

	const lang = i18n.resolvedLanguage;
	const dateLocale = locales[lang];

	// Submit Form
	const handleRequestReport = (data, event) => {
		event.preventDefault();

		// TODO: Use react-hook-form transform.

		if (!data.origin_estimated_ready_date_time) {
			data.origin_estimated_ready_date_time = {};
		}
		if (data.origin_estimated_ready_date_time_start) {
			data.origin_estimated_ready_date_time.start = formatDateToString(
				stringToDateWithFormat(
					data.origin_estimated_ready_date_time_start,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.origin_estimated_ready_date_time_start;

		if (data.origin_estimated_ready_date_time_end) {
			data.origin_estimated_ready_date_time.end = formatDateToString(
				stringToDateWithFormat(
					data.origin_estimated_ready_date_time_end,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.origin_estimated_ready_date_time_end;

		if (!data.origin_estimated_close_date_time) {
			data.origin_estimated_close_date_time = {};
		}
		if (data.origin_estimated_close_date_time_start) {
			data.origin_estimated_close_date_time.start = formatDateToString(
				stringToDateWithFormat(
					data.origin_estimated_close_date_time_start,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.origin_estimated_close_date_time_start;

		if (data.origin_estimated_close_date_time_end) {
			data.origin_estimated_close_date_time.end = formatDateToString(
				stringToDateWithFormat(
					data.origin_estimated_close_date_time_end,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.origin_estimated_close_date_time_end;

		if (!data.destination_requested_delivery_date_time) {
			data.destination_requested_delivery_date_time = {};
		}
		if (data.destination_requested_delivery_date_time_start) {
			data.destination_requested_delivery_date_time.start = formatDateToString(
				stringToDateWithFormat(
					data.destination_requested_delivery_date_time_start,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.destination_requested_delivery_date_time_start;

		if (data.destination_requested_delivery_date_time_end) {
			data.destination_requested_delivery_date_time.end = formatDateToString(
				stringToDateWithFormat(
					data.destination_requested_delivery_date_time_end,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.destination_requested_delivery_date_time_end;

		if (!data.booked_order) {
			data.booked_order = {};
			data.booked_order.booked_at = {};
			data.booked_order.created_at = {};
		}

		//
		if (data.booked_order_booked_start) {
			data.booked_order.booked_at.start = formatDateToString(
				stringToDateWithFormat(
					data.booked_order_booked_start,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.booked_order_booked_start;

		if (data.booked_order_booked_end) {
			data.booked_order.booked_at.end = formatDateToString(
				stringToDateWithFormat(
					data.booked_order_booked_end,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.booked_order_booked_end;

		// booked_order_created_start

		if (data.booked_order_created_start) {
			data.booked_order.created_at.start = formatDateToString(
				stringToDateWithFormat(
					data.booked_order_created_start,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.booked_order_created_start;

		if (data.booked_order_created_end) {
			data.booked_order.created_at.end = formatDateToString(
				stringToDateWithFormat(
					data.booked_order_created_end,
					t("Dates.FieldDate")
				)
			);
		}
		delete data.booked_order_created_end;

		// Shipment Type
		const transport_shipment_type = selectorToList(
			data.transport_shipment_type ?? null
		);
		if (data.transport_shipment_type) {
			delete data.transport_shipment_type;
		}
		// Cargo Type
		const transport_cargo_type = selectorToList(
			data.transport_cargo_type ?? null
		);
		if (data.transport_cargo_type) {
			delete data.transport_cargo_type;
		}

		// Booked Status
		const book_status = selectorToList(data.book_status ?? null);
		if (data.book_status) {
			delete data.book_status;
		}

		// Booking Method
		const booked_method = selectorToList(data.booked_method ?? null);
		if (data.booked_method) {
			delete data.booked_method;
		}

		// Address
		if (data.origin_address_line_1) {
			data.address.origin.address_line_1 = data.origin_address_line_1;
		}
		delete data.origin_address_line_1;

		if (data.origin_address_postal_code) {
			data.address.origin.postal_code = data.origin_address_postal_code;
		}
		delete data.origin_address_postal_code;

		if (data.origin_address_line_1) {
			data.address.origin.address_line_1 = data.origin_address_line_1;
		}
		delete data.origin_address_line_1;

		if (data.destination_address_postal_code) {
			data.address.destination.postal_code =
				data.destination_address_postal_code;
		}
		delete data.destination_address_postal_code;
		const submitData = {
			transport_shipment_type,
			transport_cargo_type,
			booked_order: { ...data.booked_order, book_status, booked_method },
		};
		const cleanData = removeEmptyProps(submitData);

		dispatch(fetchOperatorOrdersReport({ lang, data: cleanData }));

	};

	const handleClear = () => {
		// Clean form.
		setClearForm(true);
	};
	const dateRegExPattern = dateRegEx(i18n.resolvedLanguage);
	/**
	 * Function that renders DayPicker component.
	 * @returns DayPicker component wrapped with positioning and handle click outside functionality.
	 */
	const renderDayPicker = () => {
		return (
			<>
				<div
					ref={refs.setFloating}
					className="daypicker"
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
					}}
				>
					<HandleClickOutside onClickOutside={(e) => setShowDayPicker(false)}>
						<DayPicker
							mode="single"
							locale={dateLocale}
							onDayClick={handleDayClick}
							selected={
								dateRegExPattern.test(getValues(focusedDateFieldName))
									? stringToDateWithFormat(
											getValues(focusedDateFieldName),
											t("Dates.FieldDate")
									  )
									: null
							}
							defaultMonth={
								dateRegExPattern.test(getValues(focusedDateFieldName))
									? stringToDateWithFormat(
											getValues(focusedDateFieldName),
											t("Dates.FieldDate")
									  )
									: null
							}
						/>
					</HandleClickOutside>
				</div>
			</>
		);
	};

	/**
	 *
	 * @param {*} value
	 * @param {*} fieldName
	 * @returns
	 */
	const renderDateField = (
		value,
		inputName,
		setReference,
		onChange,
		placeholder
	) => {
		return (
			<>
				<input
					ref={setReference ? refs.setReference : undefined}
					type="text"
					defaultValue={value}
					value={value}
					onFocus={function (e) {
						setFocusedDateFieldName(inputName);
					}}
					onClick={function (e) {
						setShowDayPicker(true);
					}}
					onChange={onChange}
					placeholder={placeholder ?? t("Dates.Placeholder")}
				></input>
			</>
		);
	};

	/**
	 *
	 * @param {*} value
	 * @param {*} fieldName
	 * @returns
	 */
	// const renderTimePicker = (value, inputName) => {
	// 	return (
	// 		<>
	// 			<TimePicker
	// 				format={t("Dates.Time")}
	// 				disableClock={true}
	// 				hourPlaceholder={"00"}
	// 				minutePlaceholder={"00"}
	// 				onChange={function (value) {
	// 					// Set new date.
	// 					const newDate = new Date();
	// 					// Keep hours.
	// 					if (timeRegEx.test(value) && newDate) {
	// 						var timeValues = value.split(":");
	// 						newDate.setHours(
	// 							parseInt(timeValues[0]),
	// 							parseInt(timeValues[1])
	// 						);
	// 					}
	// 					setValue(inputName, newDate);
	// 				}}
	// 				value={value}
	// 				clearIcon={null}
	// 				locale={dateLocale}
	// 			/>
	// 		</>
	// 	);
	// };

	return (
		<div id="default">
			<div className="order-container">
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("OperatorReports.Title")}</h1>
							</div>
						</div>
						<div className="order-filters">
							<div className="dsv-form full">
								<div className="a">
									<label>Filters</label>
								</div>
								<div
									className={classNames("b-11 field", {
										error: errors.file_number,
									})}
								>
									<label>{t("Tables.FileNumber")}</label>
									<input
										type="text"
										placeholder={t("Tables.FileNumber")}
										{...register("file_number", {})}
									></input>
								</div>
								<div
									className={classNames("b-12 field", {
										error: errors.alternate_reference_number,
									})}
								>
									<label>{t("Tables.ReferenceNumber")}</label>
									<input
										type="text"
										placeholder={t("Tables.ReferenceNumber")}
										{...register("alternate_reference_number", {})}
									></input>
								</div>
								<div
									className={classNames("b-21 field", {
										error: errors.master_bill_number,
									})}
								>
									<label>{t("Tables.MasterBill")}</label>
									<input
										type="text"
										placeholder={t("Tables.MasterBill")}
										{...register("master_bill_number", {})}
									></input>
								</div>
								<div
									className={classNames("b-22 field", {
										error: errors.house_bill_number,
									})}
								>
									<label>{t("Tables.HouseBill")}</label>
									<input
										type="text"
										placeholder={t("Tables.HouseBill")}
										{...register("house_bill_number", {})}
									></input>
								</div>

								<div
									className={classNames("b-11 field", {
										error: errors.transport_shipment_type,
									})}
								>
									<label>{t("ShipmentTypes.Title")}</label>
									<Controller
										name="transport_shipment_type"
										control={control}
										rules={{}}
										render={({ field }) => (
											<Select
												className="dsv-select"
												classNamePrefix="dsv-select"
												closeMenuOnSelect={false}
												isClearable={true}
												isMulti={true}
												{...field}
												options={toSelector(SHIPMENT_TYPE.properties)}
											/>
										)}
									/>
								</div>
								<div
									className={classNames("b-12 field", {
										error: errors.transport_cargo_type,
									})}
								>
									<label>{t("CargoTypes.Title")}</label>
									<Controller
										name="transport_cargo_type"
										control={control}
										rules={{}}
										render={({ field }) => (
											<Select
												className="dsv-select"
												classNamePrefix="dsv-select"
												closeMenuOnSelect={false}
												isClearable={true}
												isMulti={true}
												{...field}
												options={toSelector(CARGO_TYPE.properties)}
											/>
										)}
									/>
								</div>
								<div className="b-21 field">
									<label>{t("BookedStatus.Title")}</label>
									<Controller
										name="book_status"
										control={control}
										rules={{}}
										render={({ field }) => (
											<Select
												className="dsv-select"
												classNamePrefix="dsv-select"
												closeMenuOnSelect={false}
												isClearable={true}
												isMulti={true}
												{...field}
												options={toSelector(BOOKED_STATUS.properties)}
											/>
										)}
									/>
								</div>
								<div className="b-22 field">
									<label>{t("BookingMethods.Title")}</label>
									<Controller
										name="booked_method"
										control={control}
										rules={{}}
										render={({ field }) => (
											<Select
												className="dsv-select"
												classNamePrefix="dsv-select"
												closeMenuOnSelect={false}
												isClearable={true}
												isMulti={true}
												{...field}
												options={toSelector(BOOKING_METHOD.properties)}
											/>
										)}
									/>
								</div>
								<div className="a top-separation">
									<label>Dates</label>
								</div>

								{/* <div className="b-111 field">
                  <label>{t("Tables.EstimatedReady")}</label>
                  <input type="text" placeholder={t("Fields.From")}></input>
                  <input type="text" placeholder={t("Fields.To")}></input>
                </div> */}

								<div
									className={classNames("b-111 field", {
										error:
											errors.origin_estimated_ready_date_time_start ||
											errors.origin_estimated_ready_date_time_end,
									})}
								>
									<label>{t("Fields.EstimatedReady")}</label>
									<Controller
										control={control}
										name="origin_estimated_ready_date_time_start"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"origin_estimated_ready_date_time_start",
													focusedDateFieldName ===
														"origin_estimated_ready_date_time_start",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"origin_estimated_ready_date_time_start" &&
													renderDayPicker()}
											</>
										)}
									/>
									<Controller
										control={control}
										name="origin_estimated_ready_date_time_end"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"origin_estimated_ready_date_time_end",
													focusedDateFieldName ===
														"origin_estimated_ready_date_time_end",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"origin_estimated_ready_date_time_end" &&
													renderDayPicker()}
											</>
										)}
									/>
								</div>

								{/* <div className="b-112 field">
                  <label>{t("Tables.EstimatedClose")}</label>
                  <input type="text" placeholder={t("Fields.From")}></input>
                  <input type="text" placeholder={t("Fields.To")}></input>
                </div> */}

								<div
									className={classNames("b-112 field", {
										error:
											errors.origin_estimated_close_date_time_start ||
											errors.origin_estimated_close_date_time_end,
									})}
								>
									<label>{t("Tables.EstimatedClose")}</label>
									<Controller
										control={control}
										name="origin_estimated_close_date_time_start"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"origin_estimated_close_date_time_start",
													focusedDateFieldName ===
														"origin_estimated_close_date_time_start",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"origin_estimated_close_date_time_start" &&
													renderDayPicker()}
											</>
										)}
									/>
									<Controller
										control={control}
										name="origin_estimated_close_date_time_end"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"origin_estimated_close_date_time_end",
													focusedDateFieldName ===
														"origin_estimated_close_date_time_end",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"origin_estimated_close_date_time_end" &&
													renderDayPicker()}
											</>
										)}
									/>
								</div>

								{/* <div className="b-113 field">
                  <label>{t("Tables.RequestedDelivery")}</label>
                  <input type="text" placeholder={t("Fields.From")}></input>
                  <input type="text" placeholder={t("Fields.To")}></input>
                </div> */}

								<div
									className={classNames("b-113 field", {
										error:
											errors.destination_requested_delivery_date_time_start ||
											errors.destination_requested_delivery_date_time_end,
									})}
								>
									<label>{t("Tables.RequestedDelivery")}</label>
									<Controller
										control={control}
										name="destination_requested_delivery_date_time_start"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"destination_requested_delivery_date_time_start",
													focusedDateFieldName ===
														"destination_requested_delivery_date_time_start",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"destination_requested_delivery_date_time_start" &&
													renderDayPicker()}
											</>
										)}
									/>
									<Controller
										control={control}
										name="destination_requested_delivery_date_time_end"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"destination_requested_delivery_date_time_end",
													focusedDateFieldName ===
														"destination_requested_delivery_date_time_end",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"destination_requested_delivery_date_time_end" &&
													renderDayPicker()}
											</>
										)}
									/>
								</div>
								{/* booked_order_created_start */}
								{/* booked_order_created_end */}
								{/* <div className="b-21 field">
                  <label>{t("Fields.BookedAt")}</label>
                  <input type="text" placeholder={t("Fields.From")}></input>
                  <input type="text" placeholder={t("Fields.To")}></input>
                </div> */}

								<div
									className={classNames("b-21 field", {
										error:
											errors.booked_order_booked_start ||
											errors.booked_order_booked_end,
									})}
								>
									<label>{t("Fields.BookedAt")}</label>
									<Controller
										control={control}
										name="booked_order_booked_start"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"booked_order_booked_start",
													focusedDateFieldName === "booked_order_booked_start",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"booked_order_booked_start" &&
													renderDayPicker()}
											</>
										)}
									/>
									<Controller
										control={control}
										name="booked_order_booked_end"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"booked_order_booked_end",
													focusedDateFieldName === "booked_order_booked_end",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName === "booked_order_booked_end" &&
													renderDayPicker()}
											</>
										)}
									/>
								</div>

								{/* <div className="b-22 field">
                  <label>{t("Fields.BookCreation")}</label>
                  <input type="text" placeholder={t("Fields.From")}></input>
                  <input type="text" placeholder={t("Fields.To")}></input>
                </div> */}

								<div
									className={classNames("b-22 field", {
										error:
											errors.booked_order_created_start ||
											errors.booked_order_created_end,
									})}
								>
									<label>{t("Fields.BookCreation")}</label>
									<Controller
										control={control}
										name="booked_order_created_start"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"booked_order_created_start",
													focusedDateFieldName === "booked_order_created_start",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName ===
														"booked_order_created_start" &&
													renderDayPicker()}
											</>
										)}
									/>
									<Controller
										control={control}
										name="booked_order_created_end"
										rules={{
											pattern: dateRegExPattern,
										}}
										render={({ field: { value, onChange } }) => (
											<>
												{renderDateField(
													value,
													"booked_order_created_end",
													focusedDateFieldName === "booked_order_created_end",
													onChange
												)}
												{showDayPicker &&
													focusedDateFieldName === "booked_order_created_end" &&
													renderDayPicker()}
											</>
										)}
									/>
								</div>

								<div className="b-1 top-separation">
									<label>{t("Common.Origin")}</label>
								</div>
								<div className="b-2 top-separation">
									<label>{t("Common.Destination")}</label>
								</div>
								<div
									className={classNames("b-111 field", {
										error: errors.origin_rating_port_code,
									})}
								>
									<label>{t("Tables.OriginPort")}</label>
									<input
										type="text"
										placeholder={t("Tables.OriginPort")}
										{...register("origin_rating_port_code", {
											pattern: portCodeRegEx,
										})}
									></input>
								</div>
								<div
									className={classNames("b-112 field", {
										error: errors.origin_address_postal_code,
									})}
								>
									<label>{t("Fields.PostalCode")}</label>
									<input
										type="text"
										placeholder={t("Fields.PostalCode")}
										{...register("origin_address_postal_code", {})}
									></input>
								</div>
								<div
									className={classNames("b-113 field", {
										error: errors.origin_address_line_1,
									})}
								>
									<label>{t("Fields.AddressLine1")}</label>
									<input
										type="text"
										placeholder={t("Fields.AddressLine1")}
										{...register("origin_address_line_1", {})}
									></input>
								</div>
								<div
									className={classNames("b-211 field", {
										error: errors.destination_rating_port_code,
									})}
								>
									<label>{t("Tables.DestinationPort")}</label>
									<input
										type="text"
										placeholder={t("Tables.DestinationPort")}
										{...register("destination_rating_port_code", {
											pattern: portCodeRegEx,
										})}
									></input>
								</div>
								<div
									className={classNames("b-212 field", {
										error: errors.destination_address_postal_code,
									})}
								>
									<label>{t("Fields.PostalCode")}</label>
									<input
										type="text"
										placeholder={t("Tables.PostalCode")}
										{...register("destination_address_postal_code", {})}
									></input>
								</div>
								<div
									className={classNames("b-213 field", {
										error: errors.destination_address_line_1,
									})}
								>
									<label>{t("Fields.AddressLine1")}</label>
									<input
										type="text"
										placeholder={t("Fields.AddressLine1")}
										{...register("destination_address_line_1", {})}
									></input>
								</div>
								<div className="b-21 top-separation">
									<div className="button" onClick={handleClear}>
										{t("Buttons.Clear")}
									</div>
								</div>
								<div className="b-22 top-separation">
									<div
										className="button main"
										onClick={handleSubmit(handleRequestReport)}
									>
										{t("Buttons.Submit")}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}
