import React from "react";

export default function OrderTabs({ tabs, activeTab, onClick }) {
	const activeTabIndex = activeTab >= tabs.length ? 0 : activeTab;
	// Handlers
	const handleTabClick = (index) => {
		onClick(index);
	};
	return (
		<div className="order-block tabs">
			<div className="dsv-tabs">
				{tabs.map((tab, index) => {
					const tabProps =
						activeTabIndex === index
							? { className: "active" }
							: { onClick: () => handleTabClick(index) };
					return (
						<div key={index} {...tabProps}>
							{tab.label}
						</div>
					);
				})}
			</div>
		</div>
	);
}
