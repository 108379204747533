import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BOOKING_METHOD, DOCUMENT_TYPE } from "features/constants";
import { getOrderPriceCheck } from "services/bookingServices";
import {
	getCarrierOrderDetails,
	getCarrierOrderLog,
	postCarrierOrders,
	postCarrierPriceCheckReject,
	postCarrierSubmitQuote,
} from "services/carrierServices";
import { postChangeBookedOrderStatus } from "services/changeStatusServices";
import {
	getBookedOrderDocuments,
	uploadBookedOrderDocument,
} from "services/documentServices";
import { postEmailToOperator } from "services/emailServices";
import { putTruckerInfo } from "services/operatorServices";
import { getBookedOrderTabs } from "utils/orderUtils";

const initialState = {
	/* Orders list */
	list: null,
	isLoading: false,
	refresh: false,
	/* Open order  */
	opened: null,
	isOpening: false,
	tabs: null,
	activeTab: 0,
	edit: null,
	/* Trucker Info */
	isSavingTruckerInfo: false,
	/* Fetch order status */
	openedLog: null,
	isLoadingLog: false,
	isChangingStatus: false,
	changeStatusError: null,
	/* Order documents */
	docs: null,
	isLoadingDocs: false,
	showUpload: false,
	isUploading: false,
	uploadPercent: 0,
	/* Price check */
	priceCheck: null,
	isLoadingPriceCheck: false,
	/* Submit Quote */
	isSubmittingQuote: false,
	/* Send Email */
	isSendingEmail: false,
	isEmailSent: false,
};

/**
 * Async fetch functions
 */

/* Get carrier's order list */
export const fetchCarrierOrders = createAsyncThunk(
	"carrier/fetchCarrierOrders",
	async (payload, { rejectWithValue }) => {
		const { filters, page } = payload;
		try {
			const response = await postCarrierOrders(filters, page);
			return response;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);
/* Get carrier's opened order details */
export const fetchCarrierOrderDetails = createAsyncThunk(
	"carrier/fetchCarrierOrderDetails",
	async (payload, { dispatch }) => {
		const { id } = payload;
		const response = await getCarrierOrderDetails(id);
		dispatch(fetchCarrierOrderLog({ id }));
		dispatch(fetchCarrierOrderDocs({ id }));
		const tabs = getBookedOrderTabs(response.data.data);
		dispatch(setCarrierOrderTabs({ tabs }));
		if (response.data.data.booked_method === BOOKING_METHOD.DSVROAD) {
			dispatch(fetchCarrierOrderPriceCheck({ id }));
		}
		return response;
	}
);
/* Get carrier's opened order log */
export const fetchCarrierOrderLog = createAsyncThunk(
	"carrier/fetchCarrierOrderLog",
	async (payload) => {
		const response = await getCarrierOrderLog(payload.id);
		return response;
	}
);
/* Change carrier's opened order status */
export const fetchCarrierOrderChangeStatus = createAsyncThunk(
	"carrier/fetchCarrierOrderChangeStatus",
	async (payload, { dispatch, rejectWithValue }) => {
		const { id, public_id, status, date_specified, location, notes } = payload;
		try {
			const response = await postChangeBookedOrderStatus(
				id,
				public_id,
				status,
				date_specified,
				location,
				notes
			);
			dispatch(fetchCarrierOrderDetails({ id }));
			// dispatch(fetchCarrierOrders({ filters }));
			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
/* Get booked order documents */
export const fetchCarrierOrderDocs = createAsyncThunk(
	"carrier/fetchCarrierOrderDocs",
	async (payload) => {
		const { id } = payload;
		const response = await getBookedOrderDocuments(id);
		return response;
	}
);
/* Upload document */
export const fetchCarrierOrderUploadDoc = createAsyncThunk(
	"documents/fetchCarrierOrderUploadDoc",
	async (payload, { dispatch }) => {
		const { booked_order_id, type, file, file_number } = payload;
		const response = await uploadBookedOrderDocument(
			booked_order_id,
			type,
			file,
			file_number,
			(progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);
				dispatch(setCarrierOrderUploadProgress(percent));
			}
		);
		if (type === DOCUMENT_TYPE.POD) {
			dispatch(setCarrierOrdersRefresh({ refresh: true }));
		}
		dispatch(fetchCarrierOrderDocs({ id: booked_order_id }));
		return response;
	}
);
/* Get booked order price check rates */
export const fetchCarrierOrderPriceCheck = createAsyncThunk(
	"carrier/fetchCarrierOrderPriceCheck",
	async (payload) => {
		const { id } = payload;
		const response = await getOrderPriceCheck(id);
		return response;
	}
);
/* Post price check quote */
export const fetchCarrierOrderQuote = createAsyncThunk(
	"carrier/fetchCarrierOrderQuote",
	async (payload, { dispatch }) => {
		const { id, data } = payload;
		const response = await postCarrierSubmitQuote(id, data);
		dispatch(fetchCarrierOrderDetails({ id }));
		return response;
	}
);
/* Post price check reject */
export const fetchCarrierOrderReject = createAsyncThunk(
	"carrier/fetchCarrierOrderReject",
	async (payload) => {
		const { id, data } = payload;
		const response = await postCarrierPriceCheckReject(id, data);
		return response;
	}
);
/* Send Email to Operator */
export const fetchCarrierSendEmailToOperator = createAsyncThunk(
	"carrier/fetchCarrierSendEmailToOperator",
	async (payload) => {
		const { booked_order_id, data } = payload;
		const response = await postEmailToOperator(booked_order_id, data);
		return response;
	}
);

export const fetchCarrierOrderUpdateTruckerInfo = createAsyncThunk(
	"carrier/fetchCarrierOrderUpdateTruckerInfo",
	async (payload, { dispatch }) => {
		const { id, data } = payload;
		const response = await putTruckerInfo(id, data);
		dispatch(fetchCarrierOrderDetails({ id }));
		return response;
	}
);

/**
 * Create redux reducer with `createSlice`
 */
export const carrierOrdersSlice = createSlice({
	name: "carrierOrdersSlice",
	initialState,
	reducers: {
		setCarrierOpenedOrder: (state, { payload }) => {
			state.opened = payload.opened;
		},
		setCarrierOrderLog: (state, { payload }) => {
			state.openedLog = payload?.docs ?? null;
		},
		setCarrierOrderStatusError: (state, { payload }) => {
			state.changeStatusError = payload?.error ?? null;
		},
		setCarrierOrderDocs: (state, { payload }) => {
			state.docs = payload?.docs ?? null;
		},
		setCarrierOrderTabs: (state, { payload }) => {
			state.tabs = payload.tabs ?? null;
		},
		setCarrierOrderActiveTab: (state, { payload }) => {
			state.activeTab = payload.activeTab ?? 0;
		},
		setCarrierOrderPriceCheck: (state, { payload }) => {
			state.priceCheck = payload.priceCheck ?? null;
		},
		setCarrierOrderShowUpload: (state, { payload }) => {
			state.showUpload = payload?.showUpload ?? false;
		},
		setCarrierOrdersRefresh: (state, { payload }) => {
			state.refresh = payload?.refresh ?? false;
		},
		setCarrierOrderUploadProgress: (state, { payload }) => {
			state.uploadPercent = payload ?? 0;
		},
		setCarrierOrderEdit: (state, { payload }) => {
			state.edit = payload?.edit ?? null;
		},
		clearCarrierOrdersEmailSent: (state) => {
			state.isEmailSent = false;
		},
		clearCarrierOrdersData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			/* Fetch orders */
			.addCase(fetchCarrierOrders.pending, (state) => {
				state.isLoading = true;
				state.refresh = false;
			})
			.addCase(fetchCarrierOrders.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
				state.refresh = false;
			})
			.addCase(fetchCarrierOrders.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.refresh = false;
				if (payload.status === 403) state.list = null;
				else state.list = [];
			})
			/* Fetch order details */
			.addCase(fetchCarrierOrderDetails.pending, (state) => {
				state.isOpening = true;
			})
			.addCase(fetchCarrierOrderDetails.fulfilled, (state, { payload }) => {
				state.isOpening = false;
				state.opened = payload.data.data;
			})
			.addCase(fetchCarrierOrderDetails.rejected, (state, { payload }) => {
				state.isOpening = false;
				state.opened = null;
			})
			/* Fetch order log */
			.addCase(fetchCarrierOrderLog.pending, (state) => {
				state.isLoadingLog = true;
			})
			.addCase(fetchCarrierOrderLog.fulfilled, (state, { payload }) => {
				state.isLoadingLog = false;
				state.openedLog = payload.data.data;
			})
			.addCase(fetchCarrierOrderLog.rejected, (state, { payload }) => {
				state.isLoadingLog = false;
				state.openedLog = null;
			})
			/* Fetch order status change */
			.addCase(fetchCarrierOrderChangeStatus.pending, (state) => {
				state.isChangingStatus = true;
			})
			.addCase(fetchCarrierOrderChangeStatus.fulfilled, (state) => {
				state.isChangingStatus = false;
				state.refresh = true;
			})
			.addCase(fetchCarrierOrderChangeStatus.rejected, (state, { payload }) => {
				state.isChangingStatus = false;
				state.changeStatusError = payload?.message ?? null;
			})
			/* Fetch order docs */
			.addCase(fetchCarrierOrderDocs.pending, (state) => {
				state.isLoadingDocs = true;
			})
			.addCase(fetchCarrierOrderDocs.fulfilled, (state, { payload }) => {
				state.isLoadingDocs = false;
				state.docs = payload.data.data;
			})
			.addCase(fetchCarrierOrderDocs.rejected, (state) => {
				state.isLoadingDocs = false;
				state.docs = [];
			})
			/* Fetch order price check */
			.addCase(fetchCarrierOrderPriceCheck.pending, (state) => {
				state.isLoadingPriceCheck = true;
			})
			.addCase(fetchCarrierOrderPriceCheck.fulfilled, (state, { payload }) => {
				state.isLoadingPriceCheck = false;
				state.priceCheck = payload.data.data;
			})
			.addCase(fetchCarrierOrderPriceCheck.rejected, (state) => {
				state.isLoadingPriceCheck = false;
				state.priceCheck = [];
			})
			/* Post price check quote */
			.addCase(fetchCarrierOrderQuote.pending, (state) => {
				state.isSubmittingQuote = true;
			})
			.addCase(fetchCarrierOrderQuote.fulfilled, (state) => {
				state.isSubmittingQuote = false;
				state.refresh = true;
			})
			.addCase(fetchCarrierOrderQuote.rejected, (state) => {
				state.isSubmittingQuote = false;
				state.refresh = true;
			})
			/* Reject price check */
			.addCase(fetchCarrierOrderReject.pending, (state) => {
				state.isSubmittingQuote = true;
			})
			.addCase(fetchCarrierOrderReject.fulfilled, (state) => {
				state.isSubmittingQuote = false;
				state.opened = null;
				state.tabs = null;
				state.refresh = true;
			})
			.addCase(fetchCarrierOrderReject.rejected, (state) => {
				state.isSubmittingQuote = false;
				state.refresh = true;
			})
			/* Upload document */
			.addCase(fetchCarrierOrderUploadDoc.pending, (state) => {
				state.isUploading = true;
			})
			.addCase(fetchCarrierOrderUploadDoc.fulfilled, (state) => {
				state.isUploading = false;
				state.showUpload = false;
			})
			.addCase(fetchCarrierOrderUploadDoc.rejected, (state) => {
				state.isUploading = false;
			})
			/* Send Email to Operator */
			.addCase(fetchCarrierSendEmailToOperator.pending, (state) => {
				state.isSendingEmail = true;
			})
			.addCase(fetchCarrierSendEmailToOperator.fulfilled, (state) => {
				state.isSendingEmail = false;
				state.isEmailSent = true;
			})
			.addCase(fetchCarrierSendEmailToOperator.rejected, (state) => {
				state.isSendingEmail = false;
			})
			/* Update Trucker Info */
			.addCase(fetchCarrierOrderUpdateTruckerInfo.pending, (state) => {
				state.isSavingTruckerInfo = true;
			})
			.addCase(fetchCarrierOrderUpdateTruckerInfo.fulfilled, (state) => {
				state.isSavingTruckerInfo = false;
				state.edit = null;
			})
			.addCase(fetchCarrierOrderUpdateTruckerInfo.rejected, (state) => {
				state.isSavingTruckerInfo = false;
			});
	},
});

/* Export reducer's actions. */
export const {
	setCarrierOpenedOrder,
	setCarrierOrderLog,
	setCarrierOrderStatusError,
	setCarrierOrderDocs,
	setCarrierOrderTabs,
	setCarrierOrderActiveTab,
	setCarrierOrderPriceCheck,
	setCarrierOrderShowUpload,
	setCarrierOrderUploadProgress,
	setCarrierOrdersRefresh,
	setCarrierOrderEdit,
	clearCarrierOrdersEmailSent,
	clearCarrierOrdersData,
} = carrierOrdersSlice.actions;

/* Export reducer */
export default carrierOrdersSlice.reducer;
