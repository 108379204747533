import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import accesorialsSlice from "./catalogs/accesorialsSlice";
import accountSlice from "./account/accountSlice";
import branchCatalogSlice from "./catalogs/branchCatalogSlice";
import carrierCatalogSlice from "./admin/carrierCatalogSlice";
import carrierHistorySlice from "./carrier/carrierHistorySlice";
import carrierOffersSlice from "./carrier/carrierOffersSlice";
import carrierOrdersSlice from "./carrier/carrierOrdersSlice";
import carrierProfileSlice from "./carrier/carrierProfileSlice";
import carriersSlice from "./admin/carriersSlice";
import carrierTemplateSlice from "./admin/carrierTemplateSlice";
import documentsSlice from "./documents/documentsSlice";
import locationTypesSlice from "./catalogs/locationTypeSlice";
import loginSlice from "./account/loginSlice";
import navigationSlice from "./navigation/navigationSlice";
import operatorOrderConsolidationSlice from "./operator/operatorOrderConsolidationSlice";
import operatorOrdersSlice from "./operator/operatorOrdersSlice";
import operatorOrdersReportSlice from "./operator/operatorOrdersReportSlice";
import operatorQuotesSlice from "./operator/operatorQuotesSlice";
import packagingsSlice from "./catalogs/packagingsSlice";
import postalCodesSlice from "./catalogs/postalCodesSlice";
import qualificationsSlice from "./catalogs/qualificationsSlice";
import usersSlice from "./admin/usersSlice";
import equipmentsSlice from "./catalogs/equipmentsSlice";

const createRootReducer = (routerReducer) =>
  combineReducers({
    accesorials: accesorialsSlice,
    account: accountSlice,
    branchCatalog: branchCatalogSlice,
    carrierCatalog: carrierCatalogSlice,
    carrierHistory: carrierHistorySlice,
    carrierOffers: carrierOffersSlice,
    carrierOrders: carrierOrdersSlice,
    carrierProfile: carrierProfileSlice,
		carrierTemplate: carrierTemplateSlice,
    carriers: carriersSlice,
    documents: documentsSlice,
    locationTypes: locationTypesSlice,
    login: loginSlice,
    navigation: navigationSlice,
    operatorOrderConsolidation: operatorOrderConsolidationSlice,
    operatorOrders: operatorOrdersSlice,
    operatorOrdersReport: operatorOrdersReportSlice,
    operatorQuotes: operatorQuotesSlice,
    packagings: packagingsSlice,
    postalCodes: postalCodesSlice,
    qualifications: qualificationsSlice,
    router: routerReducer,
    session: sessionReducer,
    users: usersSlice,
    equipments: equipmentsSlice,
  });

export default createRootReducer;
