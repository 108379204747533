import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toHeaderEditForm } from "utils/orderUtils";
import { toSelector } from "utils/constantsUtils";
import { ORDER_EDIT, SHIPMENT_TYPE, CARGO_TYPE } from "features/constants";
import Select from "react-select";
import OrderTabs from "./OrderTabs";
import { removeEmptyProps } from "utils/formUtils";

export default function OrderHeaderForm({
	order,
	edit,
	tabs,
	activeTab,
	onSave,
	onCancel,
	onClose,
	onCollapse,
	onExpand,
	onTabClick,
	collapse,
	consolidate,
	branches,
}) {
	// Translation
	const { t } = useTranslation();

	// Initialize form
	const {
		control,
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	// Effect
	useEffect(() => {
		reset(toHeaderEditForm(order, branches));
	}, [order, branches, reset]);

	// Submit function
	const handleSaveHeaderData = (data, event) => {
		event.preventDefault();

		// TODO: Transform from react-hook-form.
		if (data.transport_cargo_type) {
			data.transport_cargo_type = data.transport_cargo_type.value;
		}

		if (data.transport_shipment_type) {
			data.transport_shipment_type = data.transport_shipment_type.value;
		}

		if (data.is_dedicated) {
			data.is_dedicated = data.is_dedicated.value;
		}

		// Call Service.
		if (order != null) {
			onSave(removeEmptyProps(data));
		}
	};

	const handleCloseClick = () => {
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};

	const handleCancelEdit = () => {
		// Clean form.
		reset();
		onCancel();
	};
	const handleTabClick = (tab) => {
		onTabClick(tab);
	};
	const renderCollapse = (collapseProps) => {
		if (!consolidate) {
			return (
				<div>
					<span {...collapseProps}></span>
				</div>
			);
		}
		return <div></div>;
	};
	const renderFileNumber = () => {
		if (!consolidate) {
			return (
				<div className="file-number">
					<div>{order.file_number}</div>
				</div>
			);
		}
		return <div className="file-number"></div>;
	};
	const renderTabs = () => {
		if (consolidate) {
			return (
				<OrderTabs tabs={tabs} activeTab={activeTab} onClick={handleTabClick} />
			);
		}
	};
	const renderConsolidateHeader = () => {
		if (consolidate) {
			return (
				<div className="order-file">
					<div>
						<div>
							<span {...collapseProps}></span>
						</div>
						<div className="file-number">
							<div>
								{consolidate ? t("Common.NewOrder") : order.file_number}
							</div>
							<div>
								<em>{t("Tables.Transport")}</em>{" "}
								{SHIPMENT_TYPE.properties[order.transport_shipment_type].code} /{" "}
								{t(CARGO_TYPE.properties[order.transport_cargo_type].name)}
							</div>
						</div>
						<div>
							<span className="icon-close" onClick={handleCloseClick}></span>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderTransport = () => {
		if (!consolidate) {
			return (
				<>
					<div className="b-1 order-subtitle">
						<div>{t("Tables.Transport")}</div>
					</div>
					<div
						className={classNames("b-21 field", {
							error: errors.transport_shipment_type,
						})}
					>
						<label>{t("ShipmentTypes.Title")}</label>

						<Controller
							name="transport_shipment_type"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									// {...field}
									onChange={onChange}
									value={
										value?.value
											? {
													value:
														SHIPMENT_TYPE.properties[`${value.value}`].value,
													label: t(
														SHIPMENT_TYPE.properties[`${value.value}`].name
													),
											  }
											: {}
									}
									options={toSelector(SHIPMENT_TYPE.properties)}
								/>
							)}
						/>
					</div>
					<div
						className={classNames("b-22 field", {
							error: errors.transport_cargo_type,
						})}
					>
						<label>{t("CargoTypes.Title")}</label>
						<Controller
							name="transport_cargo_type"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									// {...field}
									onChange={onChange}
									value={
										value?.value
											? {
													value: CARGO_TYPE.properties[`${value.value}`].value,
													label: t(
														CARGO_TYPE.properties[`${value.value}`].name
													),
											  }
											: {}
									}
									options={toSelector(CARGO_TYPE.properties, false, true, [CARGO_TYPE.FTL])}
								/>
							)}
						/>
					</div>
				</>
			);
		}
	};
	const renderFormHeader = () => {
		if (!consolidate) {
			return (
				<div className="order-file edit">
					<div>
						{renderCollapse(collapseProps)}
						{renderFileNumber()}
						<div>
							<span
								className="icon-edit-close"
								onClick={handleCancelEdit}
							></span>
							<span
								className="icon-edit-save"
								onClick={handleSubmit(handleSaveHeaderData)}
							></span>
						</div>
						<div>
							<span className="icon-close" onClick={handleCloseClick}></span>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderFormButtons = () => {
		if (consolidate) {
			return (
				<div>
					<span className="icon-edit-close" onClick={handleCancelEdit}></span>
					<span
						className="icon-edit-save"
						onClick={handleSubmit(handleSaveHeaderData)}
					></span>
				</div>
			);
		}
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };
	const formProps = collapse
		? { className: "dsv-form full no-padding collapse" }
		: { className: "dsv-form full no-padding" };
	const render = () => {
		const options = branches?.map((el) => {
			return { label: el.code, value: el.id };
		});
		const yesNoOptions = [
			{ label: t("Common.Yes"), value: true },
			{ label: t("Common.No"), value: false },
		];
		return (
			<>
				{renderConsolidateHeader()}
				{renderTabs()}
				<div className="order-block edit remove-top-space">
					{renderFormHeader()}
					<div {...formProps}>
						{renderTransport()}
						<div className="a order-subtitle">
							<div>{t("Common.References")}</div>
							{renderFormButtons()}
						</div>
						<div
							className={classNames("d-1 field", {
								error: errors.alternate_reference_number,
							})}
						>
							<label>{t("Tables.ReferenceNumber")}</label>
							<input
								type="text"
								placeholder={t("Tables.ReferenceNumber")}
								{...register("alternate_reference_number", {
									required: false,
								})}
							></input>
						</div>
						<div
							className={classNames("d-2 field tablet-only", {
								error: errors.master_bill_number,
							})}
						>
							<label>{t("Tables.MasterBill")}</label>
							<input
								type="text"
								placeholder={t("Tables.MasterBill")}
								{...register("master_bill_number", {
									required: false,
								})}
							></input>
						</div>
						<div
							className={classNames("d-3 field tablet-only", {
								error: errors.house_bill_number,
							})}
						>
							<label>{t("Tables.HouseBill")}</label>
							<input
								type="text"
								placeholder={t("Tables.HouseBill")}
								{...register("house_bill_number", {
									required: false,
								})}
							></input>
						</div>
						<div className="b-1 field">
							<label>{t("Fields.IsDedicated")}</label>
							<Controller
								name="is_dedicated"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										className="dsv-select"
										classNamePrefix="dsv-select"
										isClearable={false}
										// {...field}
										onChange={onChange}
										value={value}
										options={yesNoOptions}
									/>
								)}
							/>
						</div>
						<div className="a top-separation order-subtitle">
							<div>{t("Tables.Forwarder")}</div>
						</div>
						<div
							className={classNames("b-1 field", {
								error: errors.forwarder_contact_name,
							})}
						>
							<label>{t("Fields.OperatorName")}</label>
							<input
								type="text"
								placeholder="Operator Name"
								{...register("forwarder_contact_name", {
									required: false,
								})}
							></input>
						</div>
						<div
							className={classNames("b-2 field", {
								error: errors.forwarder_email,
							})}
						>
							<label>{t("Common.Email")}</label>
							<input
								type="text"
								placeholder={t("Common.Email")}
								{...register("forwarder_email", {
									required: false,
									// TODO: pattern.
								})}
							></input>
						</div>
						<div
							className={classNames("b-1 field", {
								error: errors.forwarder_phone,
							})}
						>
							<label>{t("Common.Phone")}</label>
							<input
								type="text"
								placeholder={t("Common.Phone")}
								{...register("forwarder_phone", {
									required: false,
								})}
							></input>
						</div>
						<div
							className={classNames("b-21 field", {
								error: errors.forwarder_branch,
							})}
						>
							<label>{t("Common.Branch")}</label>
							<Controller
								name="branch_id"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										className="dsv-select"
										classNamePrefix="dsv-select"
										isClearable={false}
										// {...field}
										onChange={onChange}
										value={value}
										options={options}
									/>
								)}
							/>
						</div>
						<div
							className={classNames("b-22 field", {
								error: errors.forwarder_department,
							})}
						>
							<label>{t("Common.Department")}</label>
							<input
								type="text"
								placeholder={t("Common.Department")}
								{...register("forwarder_department", {
									required: false,
								})}
							></input>
						</div>
					</div>
				</div>
			</>
		);
	};

	if (edit === ORDER_EDIT.HEADER && order != null) {
		return render();
	}

	return null;
}
