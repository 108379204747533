import Loader from "components/Loader";
import {
	ADDRESS_TYPE,
	CARGO_TYPE,
	CONTACT_TYPE,
	ORDER_EDIT,
	SHIPMENT_TYPE,
} from "features/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/dateUtils";

const OrderContainer = ({ order, edit, booked, isLoading, onEdit }) => {
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleEditClick = () => {
		onEdit();
	};
	// Render Functions
	const renderDate = (date) => {
		const formatted =
			date != null ? formatDate(date, t("Dates.Date"), false) : "n/a";
		return <div>{formatted}</div>;
	};
	const getAddress = (type) => {
		if (order.addresses == null) return {};
		const filtered = order.addresses.filter(
			(el) => Number(el.address_type) === type
		);
		const item = filtered.length > 0 ? filtered[0] : {};
		return item;
	};
	/* Gets contact object from order_request */
	const getContact = (type) => {
		if (order.contacts == null) return {};
		const filtered = order.contacts.filter(
			(el) => Number(el.contact_type) === type
		);
		const item = filtered.length > 0 ? filtered[0] : {};
		return item;
	};
	/* Renders formatted address */
	const renderAddress = (addressName, type) => {
		const address = getAddress(type);
		if (address == null && addressName == null) {
			return (
				<div>
					<span>n/a</span>
				</div>
			);
		}
		let arr = [];
		if (addressName != null) {
			arr.push(<span>{addressName ?? "n/a"}</span>);
			arr.push(<br />);
		}
		if (address.address_line_1 != null) {
			arr.push(address.address_line_1);
			arr.push(<br />);
		}
		if (address.address_line_2 != null) {
			arr.push(address.address_line_2);
			arr.push(<br />);
		}
		const cityLine = [
			address.city,
			address.state_province,
			address.postal_code,
			address.country_code,
		]
			.filter(Boolean)
			.join(", ");
		if (cityLine.trim().length > 0) arr.push(cityLine);
		return (
			<div>
				{arr.map((el, index) => {
					return <React.Fragment key={index}>{el}</React.Fragment>;
				})}
			</div>
		);
	};
	/* Renders formatted contact info */
	const renderContact = (type) => {
		const contact = getContact(type);
		let arr = [];
		if (contact.name != null) {
			arr.push(contact.name);
			arr.push(<br />);
		}
		if (contact.phone != null) {
			arr.push(contact.phone);
			arr.push(<br />);
		}
		if (contact.email != null) {
			arr.push(contact.email);
			arr.push(<br />);
		}
		if (arr.length > 1) {
			arr.pop();
		}
		return (
			<div>
				{arr.map((el, index) => {
					return <React.Fragment key={index}>{el}</React.Fragment>;
				})}
			</div>
		);
	};
	const renderPickup = () => {
		if (order.transport_shipment_type === SHIPMENT_TYPE.PU) {
			return (
				<div className="order-address is-port">
					<div className="order-address-title">
						<div>{t("Common.EmptyContainerPickup")}</div>
						{order?.empty_container_pickup_location_rating_port_code !=
							null && (
							<div>
								<div>{t("Common.PortCode")}</div>
								<div>
									{order?.empty_container_pickup_location_rating_port_code}
								</div>
							</div>
						)}
					</div>
					<hr />
					<div className="container-address">
						<div className="order-address-data">
							{renderAddress(
								order.empty_container_pickup_location_name,
								ADDRESS_TYPE.CONTAINER_PICKUP
							)}
						</div>
						<div className="order-address-contact">
							{renderContact(CONTACT_TYPE.CONTAINER_PICKUP)}
						</div>
					</div>
					<hr />
					<div className="address-notes">
						<div>
							<div>{t("Common.Notes")}</div>
							<div>
								{order?.empty_container_pickup_location_trucker_notes ?? (
									<>&nbsp;</>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderDelivery = () => {
		if (order?.transport_shipment_type === SHIPMENT_TYPE.DE) {
			return (
				<div className="order-address is-port">
					<div className="order-address-title">
						<div>{t("Common.EmptyContainerDelivery")}</div>
						{order?.empty_container_delivery_location_rating_port_code !=
							null && (
							<div>
								<div>{t("Common.PortCode")}</div>
								<div>
									{order?.empty_container_delivery_location_rating_port_code}
								</div>
							</div>
						)}					</div>
					<hr />
					<div className="container-address">
						<div className="order-address-data">
							{renderAddress(
								order.empty_container_delivery_location_name,
								ADDRESS_TYPE.CONTAINER_DELIVERY
							)}
						</div>
						<div className="order-address-contact">
							{renderContact(CONTACT_TYPE.CONTAINER_DELIVERY)}
						</div>
					</div>
					<hr />
					<div className="address-notes">
						<div>
							<div>{t("Common.Notes")}</div>
							<div>
								{order?.empty_container_delivery_location_trucker_notes ?? (
									<>&nbsp;</>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	if (order?.transport_cargo_type !== CARGO_TYPE.FCL) {
		return null;
	}
	if (edit === ORDER_EDIT.CONTAINER) {
		return null;
	}
	if (isLoading) {
		return <Loader />;
	}
	return (
		<div className="order-block top-border">
			<div className="order-subtitle">
				<div>{t("Common.Container")}</div>
				<div>
					<span onClick={handleEditClick} className="icon-edit"></span>
				</div>
			</div>
			<div className="flex-table">
				<div>
					<div>
						<div>{t("Fields.ContainerNumber")}</div>
						<div>{order?.transport_container_number ?? "n/a"}</div>
					</div>
					<div>
						<div>{t("Fields.LastFreeDate")}</div>
						<div>{renderDate(order?.transport_container_last_free_date)}</div>
					</div>
				</div>
			</div>
			{renderPickup()}
			{renderDelivery()}
		</div>
	);
};

export default OrderContainer;
