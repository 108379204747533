import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DayPicker } from "react-day-picker";
import { removeEmptyStrings } from "utils/formUtils";
import { format, isValid } from "date-fns";
import { useFloating, autoUpdate } from "@floating-ui/react-dom";
import { ORDER_EDIT, SHIPMENT_TYPE } from "features/constants";
import HandleClickOutside from "hooks/handleClickOutsideHook";
import Loader from "components/Loader";
import { dateRegEx } from "utils/regExs";
import {
	formatDateToString,
	locales,
	stringToDateWithFormat,
} from "utils/dateUtils";
import { toContainerEditForm } from "utils/orderUtils";

const OrderContainerForm = ({
	order,
	edit,
	isSaving,
	collapse,
	onSave,
	onCancel,
}) => {
	// Translation
	const { t, i18n } = useTranslation();
	// State
	const [showDayPicker, setShowDayPicker] = useState(false);

	// Day picker positioning.
	const { x, y, refs, strategy } = useFloating({
		placement: "bottom-start",
		showDayPicker,
		onOpenChange: setShowDayPicker,
		whileElementsMounted: autoUpdate,
	});
	// Initialize form
	const { control, register, handleSubmit, setValue, getValues, reset } =
		useForm();
	const dateRegExPattern = dateRegEx(i18n.language);
	// Effect
	useEffect(() => {
		reset(toContainerEditForm(order));
	}, [order, reset]);
	// Handlers
	const handleSaveContainerData = (data, event) => {
		event.preventDefault();
		const freeDate =
			data.container_last_free_date_value != null
				? stringToDateWithFormat(
						data.container_last_free_date_value,
						t("Dates.FieldDate")
				  )
				: null;
		const formData = {
			...data,
			container_last_free_date: formatDateToString(freeDate),
			container_last_free_date_value: undefined,
		};
		return onSave(removeEmptyStrings(formData));
	};
	const handleCancelEdit = () => {
		onCancel();
	};
	const handleDayClick = (day) => {
		// Uodate value.
		setValue(
			"container_last_free_date_value",
			format(day, t("Dates.FieldDate"))
		);
		setShowDayPicker(false);
	};
	const handleDateFieldChange = (e) => {
		setValue("container_last_free_date_value", e.currentTarget.value);
		if (dateRegExPattern.test(e.currentTarget.value)) {
			const date = stringToDateWithFormat(
				e.currentTarget.value,
				t("Dates.FieldDate")
			);
			if (isValid(date)) {
				setValue(
					"container_last_free_date_value",
					format(date, t("Dates.FieldDate"))
				);
			}
		}
	};
	const renderDayPicker = () => {
		return (
			<>
				<div
					ref={refs.setFloating}
					className="daypicker"
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
					}}
				>
					<HandleClickOutside onClickOutside={(e) => setShowDayPicker(false)}>
						<DayPicker
							mode="single"
							locale={locales[i18n.resolvedLanguage]}
							onDayClick={handleDayClick}
							selected={
								dateRegExPattern.test(
									getValues("container_last_free_date_value")
								)
									? stringToDateWithFormat(
											getValues("container_last_free_date_value"),
											t("Dates.FieldDate")
									  )
									: null
							}
							defaultMonth={
								dateRegExPattern.test(
									getValues("container_last_free_date_value")
								)
									? stringToDateWithFormat(
											getValues("container_last_free_date_value"),
											t("Dates.FieldDate")
									  )
									: null
							}
						/>
					</HandleClickOutside>
				</div>
			</>
		);
	};
	const renderForm = () => {
		if (!isSaving) {
			return (
				<>
					<div className="b-1 field">
						<label>{t("Fields.ContainerNumber")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Fields.ContainerNumber")}
							{...register("container_number")}
						/>
					</div>
					<Controller
						control={control}
						name="container_last_free_date_value"
						rules={{
							pattern: dateRegExPattern,
						}}
						render={({ field: { value } }) => {
							return (
								<div className="b-21 field">
									<label>{t("Fields.LastFreeDate")}</label>
									<input
										type="text"
										value={value}
										onClick={() => setShowDayPicker(true)}
										onChange={handleDateFieldChange}
										className="form-control"
										placeholder={t("Dates.Placeholder")}
										ref={refs.setReference}
									/>
									{showDayPicker && renderDayPicker()}
								</div>
							);
						}}
					/>
					<div className="a top-separation order-subtitle">
						<div>
							{order.transport_shipment_type === SHIPMENT_TYPE.PU
								? t("Common.EmptyContainerPickup")
								: t("Common.EmptyContainerDelivery")}
						</div>
					</div>
					<div className="b-1 field">
						<label>{t("Fields.LocationName")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Fields.LocationName")}
							{...register("container_name")}
						/>
					</div>
					<div className="b-21 field">
						<label>{t("Fields.PortCode")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Fields.PortCode")}
							{...register("container_rating_port_code")}
						/>
					</div>
					<div className="b-22 field">
						<label>{t("Fields.AccountNumber")}</label>
						<input
							type="text"
							className="form-control"
							placeholder={t("Fields.AccountNumber")}
							{...register("container_account_number")}
						/>
					</div>
					<div className="b-1 field">
						<label>{t("Fields.AddressLine1")}</label>
						<input
							type="text"
							placeholder={t("Fields.AddressLine1")}
							className="form-control"
							{...register("container_address_line_1")}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Fields.AddressLine2")}</label>
						<input
							type="text"
							placeholder={t("Fields.AddressLine2")}
							className="form-control"
							{...register("container_address_line_2")}
						/>
					</div>
					<div className="b-1 field">
						<label>{t("Fields.City")}</label>
						<input
							type="text"
							placeholder={t("Fields.City")}
							className="form-control"
							{...register("container_address_city")}
						/>
					</div>
					<div className="b-2 field">
						<label>{t("Fields.StateProvince")}</label>
						<input
							type="text"
							placeholder={t("Fields.StateProvince")}
							className="form-control"
							{...register("container_address_state_province")}
						/>
					</div>
					<div className="b-11 field">
						<label>{t("Fields.PostalCode")}</label>
						<input
							type="text"
							placeholder={t("Fields.PostalCode")}
							className="form-control"
							{...register("container_address_postal_code")}
						/>
					</div>
					<div className="b-12 field">
						<label>{t("Fields.CountryCode")}</label>
						<input
							type="text"
							placeholder={t("Fields.CountryCode")}
							className="form-control"
							{...register("container_address_country_code")}
						/>
					</div>
					<div className="a field">
						<label>{t("Fields.TruckerNotes")}</label>
						<textarea
							rows="5"
							id="container_account_trucker_notes"
							name="container_account_trucker_notes"
							placeholder={t("Fields.TruckerNotes")}
							{...register("container_account_trucker_notes", {
								required: false,
							})}
						></textarea>
					</div>
				</>
			);
		}
	};
	// Render
	if (edit == null) return null;
	if (edit !== ORDER_EDIT.CONTAINER) return null;
	if (edit === ORDER_EDIT.CONTAINER && order != null && isSaving) {
		return (
			<div className="order-block edit top-border">
				<div
					className={classNames("dsv-form full no-padding", {
						collapse: collapse,
					})}
				>
					<div className="a order-subtitle">
						<div>{t("Common.Container")}</div>
					</div>
					<div className="a">
						<Loader />
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="order-block edit top-border">
			<div
				className={classNames("dsv-form full no-padding", {
					collapse: collapse,
				})}
			>
				<div className="a order-subtitle">
					<div>{t("Common.Container")}</div>
					<div>
						<span className="icon-edit-close" onClick={handleCancelEdit}></span>
					</div>
					<div>
						<span
							className="icon-edit-save"
							onClick={handleSubmit(handleSaveContainerData)}
						></span>
					</div>
				</div>
				{renderForm()}
			</div>
		</div>
	);
};

export default OrderContainerForm;
