import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { sessionService } from "redux-react-session";
import reportWebVitals from "./reportWebVitals";
import { store, history } from "features/store";
import "react-day-picker/dist/style.css";
import "./styles.css";
import App from "components/App";
import { SessionUtils } from "utils/sessionUtils";
import CacheBuster from "components/CacheBuster";

SessionUtils.setStore(store);
sessionService.initSessionService(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<CacheBuster>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return null;
				if (!loading && !isLatestVersion) {
					// You can decide how and when you want to force reload
					refreshCacheAndReload();
				}
				return (
					<Provider store={store}>
						<Suspense fallback={<div>Loading ... </div>}>
							<Router history={history}>
								<App />
							</Router>
						</Suspense>
					</Provider>
				);
			}}
		</CacheBuster>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
