import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPackagings } from "services/catalogsServices";

/**
 * Define the intial state.
 */
const initialState = { isLoading: false, packagings: null };

/**
 * Async reducer function to fetch packagings data.
 */
export const fetchPackagings = createAsyncThunk(
  "catalogs/packagings",
  async () => {
    return await getPackagings();
  }
);

/**
 * Create and configure slice.
 */
const packagingsSlice = createSlice({
  name: "packagings",
  initialState,
  reducers: {
		clearPackagingsData: () => initialState,
	},
  /**
   * Use extra reducers for async executions.
   * @param {*} builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackagings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPackagings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.packagings = payload.data.data;
      })
      .addCase(fetchPackagings.rejected, (state) => {
        state.isLoading = false;
        state.packagings = [];
      });
  },
});

export const {clearPackagingsData} = packagingsSlice.actions;

/**
 * Export slice.
 */
export default packagingsSlice.reducer;
