import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_EDIT } from "features/constants";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import {
	setOperatorConsolidationActiveTab,
	setOperatorConsolidationSelectedOrder,
	setOperatorConsolidationTabEdit,
} from "features/operator/operatorOrderConsolidationSlice";
import OrderHeader from "components/common/order/OrderHeader";

export default function OperatorConsolidateDetailsHeader({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const dispatch = useDispatch();
	// Selectors
	const { user } = useSelector((state) => state.session);
	const { openOrder, orderEdit, isLoadingOpen, consolidate } = useSelector(
		(state) => state.operatorOrders
	);
	const { isLoading, selectedOrder, list, edit, tabs, activeTab } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	// Handlers
	const handleEditClick = () => {
		if (activeTab === 0) {
			dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.HEADER }));
		} else {
			dispatch(setOperatorConsolidationTabEdit({ edit: ORDER_EDIT.HEADER }));
		}
	};
	const profile = user?.user?.role ?? null;

	const handleTabClick = (tab) => {
		const findSelected = list.find((item) => item.id === tabs[tab].id);
		dispatch(setOperatorOrderEdit({ orderEdit: null }));
		dispatch(setOperatorConsolidationTabEdit({ edit: null }));
		dispatch(
			setOperatorConsolidationSelectedOrder({ selectedOrder: findSelected })
		);
		dispatch(setOperatorConsolidationActiveTab({ activeTab: tab }));
	};
	if (activeTab === 0) {
		return (
			<OrderHeader
				order={openOrder}
				edit={orderEdit}
				isLoading={isLoadingOpen}
				consolidate={consolidate}
				tabs={tabs}
				activeTab={activeTab}
				onClose={onClose}
				onCollapse={onCollapse}
				onExpand={onExpand}
				onEdit={handleEditClick}
				onTabClick={handleTabClick}
				collapse={collapse}
				profile={profile}
			/>
		);
	}
	return (
		<OrderHeader
			order={selectedOrder}
			edit={edit}
			isLoading={isLoading}
			consolidate={consolidate}
			tabs={tabs}
			activeTab={activeTab}
			onClose={onClose}
			onCollapse={onCollapse}
			onExpand={onExpand}
			onEdit={handleEditClick}
			onTabClick={handleTabClick}
			collapse={collapse}
			profile={profile}
			/>
	);
}
