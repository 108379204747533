import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import OrderAddress from "components/common/order/OrderAddress";

export default function CarrierOrderHistoryDetailsAddress() {
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { opened, isOpening, activeTab } = useSelector((state) => state.carrierHistory);
	/* Render loader */
	if (isOpening) {
		return (
			<div className="order-block">
				<Loader msg={t("Common.Loading")} />
			</div>
		);
	}
	/* Only render if opened order is not null */
	if (opened != null) {
		const order =
		opened != null
			? activeTab === 0
				? opened?.order_requests[activeTab] ?? null
				: opened?.order_requests[activeTab - 1] ?? null
			: null;
		return (
			<OrderAddress
				order={order}
				edit={null}
				isLoading={isOpening}
				onEdit={()=>{}}
				errors={{}}
				booked={true}
				bookedOrder={opened}
			/>
		);
	}
	return null;
}
