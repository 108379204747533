import { useRoutes } from "react-router-dom";
import routes from "features/routes";
// import useSessionHook from "hooks/sessionHook"

export default function App() {
	// Routing session validation.
	// useSessionHook();
	let element = useRoutes(routes);
	return element;
}
