import { USER_ROLE } from "features/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchAddUser,
	setErrorNull,
	fetchBranches,
} from "features/admin/usersSlice";
import Loader from "components/Loader";
import { fetchCarrierCatalog } from "features/admin/carrierCatalogSlice";
import UserForm from "./UserForm";

export default function UserAdd({ role, carrier = null, onClose }) {
	// Translation
	const { t } = useTranslation();
	// Dispatch
	const dispatch = useDispatch();
	// Selectors
	/**
	 * users
	 */
	const {
		carriers,
		branches,
		operators,
		admins,
		isSaving,
		isLoading,
		isLoadingBranches,
	} = useSelector((state) => state.users);
	/**
	 *  session
	 */
	const { user } = useSelector((state) => state.session);
	/**
	 * carrierCatalog
	 */
	const { list, isLoadingCatalog } = useSelector(
		(state) => state.carrierCatalog
	);

	// Effect
	useEffect(() => {
		/**
		 * Carriers Catalog
		 */
		if (role === USER_ROLE.CARRIER && !list && !isLoading) {
			dispatch(fetchCarrierCatalog());
		}
		/**
		 * Branches
		 */
		if (role === USER_ROLE.OPERATOR && branches == null && !isLoadingBranches) {
			dispatch(fetchBranches());
		}
	}, [dispatch, list, branches, isLoading, isLoadingBranches, role]);

	// Handlers
	const handleClose = () => {
		dispatch(setErrorNull());
		onClose();
	};
	const handleSave = (data) => {
		//
		let page = 1;
		switch (role) {
			case USER_ROLE.CARRIER:
				page = carriers?.current_page;
				break;
			case USER_ROLE.OPERATOR:
				page = operators?.current_page;
				break;
			case USER_ROLE.ADMIN:
				page = admins?.current_page;
				break;
			default:
		}
		dispatch(fetchAddUser({ data, role, page }));
	};
	const handleSetError = (error) => {
		dispatch(setErrorNull(error));
	};

	// Render
	const userRole = USER_ROLE.properties[role];
	const userRoleName = t(userRole.name, { count: 1 });
	// Loader
	if (isSaving || isLoadingBranches || isLoading || isLoadingCatalog) {
		return (
			<div className="item-details">
				<div className="details-container">
					<div className="details-title">
						<div>
							<div className="title-text">
								<span>{t("AdminUsers.AddUser", { role: userRoleName })}</span>
							</div>
							<div onClick={handleClose}>
								<span className="icon-close"></span>
							</div>
						</div>
					</div>
					<Loader msg={isSaving ? t("Common.Saving") : t("Common.Loading")} />
				</div>
			</div>
		);
	}
	const formProps = {
		user,
		role,
		list,
		carrier,
		branches,
		setError: handleSetError,
		onClose: handleClose,
		onSubmit: handleSave,
	};
	return (
		<div className="item-details">
			<div className="details-container">
				<div className="details-title">
					<div>
						<div className="title-text">
							<span>{t("AdminUsers.AddUser", { role: userRoleName })}</span>
						</div>
						<div onClick={() => onClose()}>
							<span className="icon-close"></span>
						</div>
					</div>
				</div>
				<div className="details-block">
					<div className="tab-content details">
						<UserForm {...formProps} />
					</div>
				</div>
			</div>
		</div>
	);
}
