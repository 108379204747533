import client from "./networking";

/**
 * Get postal code data
 * @param {*} postalCode string
 * @returns object
 */
export const getPostalCode = (postalCode) => {
  return client.get(`/api/postal_codes/${postalCode}`);
};

/**
 * Get postal code data
 * @param {*} postalCode string
 * @returns object
 */
export const getStates = () => {
  return client.get(`/api/postal_code_states/catalog`);
};

/**
 * Get postal code data
 * @param {*} postalCode string
 * @returns object
 */
export const getCities = (stateName) => {
  return client.get(`/api/postal_code_states/${stateName}/cities/catalog`);
};


export const getAllCities = () => {
  return client.get(`/api/postal_code_states/cities/catalog`);
};
