import React from "react";
import { useSelector } from "react-redux";
import OrderCargo from "components/common/order/OrderCargo";

export default function CarrierOrderHistoryDetailsCargo() {
	// Selectors
	const { opened, isOpening, activeTab } = useSelector(
		(state) => state.carrierHistory
	);
	const { packagings } = useSelector((state) => state.packagings);
	// Render
	if (opened != null) {
		const order =
			opened != null
				? activeTab === 0
					? opened?.order_requests[activeTab] ?? null
					: opened?.order_requests[activeTab - 1] ?? null
				: null;
		return (
			<OrderCargo
				order={order}
				edit={null}
				isLoading={isOpening}
				packagings={packagings}
				onEdit={() => {}}
				booked={true}
				bookedOrder={opened}
				activeTab={activeTab}
			/>
		);
	}
	return null;
}
