import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { passRegEx } from "utils/regExs";
import { removeEmptyProps } from "utils/formUtils";
import {
	setUserPwdChange,
	fetchChangeUserPassword,
} from "features/admin/usersSlice";
import Loader from "components/Loader";

export default function UserPwdChange() {
	const {
		// control,
		register,
		// setValue,
		// getValues,
		handleSubmit,
		// watch,
		// reset,
		formState: { errors },
	} = useForm();
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { open, pwdChange, isSaving } = useSelector((state) => state.users);
	if (!pwdChange) return null;
	//   if (open == null) return null;

	// Handlers
	const handleSave = (data, event) => {
		// TODO: Validate form & fetch to api with dispatch
		event.preventDefault();

		//
		dispatch(
			fetchChangeUserPassword({ id: open.id, data: removeEmptyProps(data) })
		);
	};

	if (isSaving) {
		return (
			<div className="details-block edit">
				<div className="order-subtitle">
					<div>{t("AdminUsers.ChangePassword", { name: open.username })}</div>
				</div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}

	return (
		<div className="dsv-form collapse no-padding">
			<div className="a item-subtitle">
				{t("AdminUsers.ChangePassword", { name: open.username })}
			</div>
			<div
				className={classNames("a field top-separation", {
					error: errors.new_password,
				})}
			>
				<label>
					{errors.new_password
						? errors.new_password.message
						: t("Fields.NewPassword")}
				</label>
				<input
					type="password"
					placeholder={t("Fields.Password")}
					{...register("new_password", {
						required: t("AdminUsers.ErrorPasswordMissing"),
						pattern: {
							value: passRegEx,
							message: t("FieldErrors.PasswordInvalid"),
						},
					})}
				/>
			</div>
			<div className="a">
				<div>&nbsp;</div>
			</div>
			<div className="b-21 top-separation">
				<div
					className="button"
					onClick={() => dispatch(setUserPwdChange({ pwdChange: false }))}
				>
					{t("Buttons.Cancel")}
				</div>
			</div>
			<div className="b-22 top-separation">
				<div className="button main" onClick={handleSubmit(handleSave)}>
					{t("Buttons.Save")}
				</div>
			</div>
		</div>
	);
}
