import { emailRegEx, passRegEx } from "utils/regExs";

/**
 * Validate password policy using regex
 * @param {*} pwd string
 * @returns bool
 */
export const validatePwd = (pwd) => {
	return passRegEx.test(pwd);
};

/**
 * Validate email address string using regex
 * @param {*} email 
 * @returns bool
 */
export const validateEmail = (email) => {
	return emailRegEx.test(email);
};

/**
 * Truncate a long string for display
 * @param {string} text 
 * @param {int} pos 
 * @returns 
 */
export const truncateString = (text, pos = 30) => {
	if (!text) {
		return null;
	}
	if (text.length <= pos) {
		return text;
	}
	return text.slice(0, pos).trim() + "...";
};
