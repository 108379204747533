import React from "react";
import { useSelector } from "react-redux";
import OrderCargo from "components/common/order/OrderCargo";

export default function CarrierOfferRequestDetailsCargo() {
	// Selectors
	const { opened, isOpening, activeTab } = useSelector(
		(state) => state.carrierOffers
	);
	const { packagings } = useSelector((state) => state.packagings);
	// Render
	const bookedOrder = opened?.booked_order;
	if (bookedOrder != null) {
		const order =
			bookedOrder != null
				? activeTab === 0
					? bookedOrder?.order_requests[activeTab]
					: bookedOrder?.order_requests[activeTab - 1] ?? null
				: null;
		return (
			<OrderCargo
				order={order}
				edit={null}
				isLoading={isOpening}
				packagings={packagings}
				onEdit={() => {}}
				booked={true}
				bookedOrder={bookedOrder}
				activeTab={activeTab}
			/>
		);
	}
	return null;
}
