import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeSession } from "utils/sessionUtils";
import { runUnauthorizedActions } from "features/account/loginSlice";
import {
	toggleShowMobile,
	toggleShowUser,
} from "features/navigation/navigationSlice";
import LanguageDropdown from "./LanguageDropdown";
import { useNavigate } from "react-router-dom";
import { PROFILES } from "features/constants";

export default function MainHeader() {
	const dispatch = useDispatch();
	// Translate
	const { t } = useTranslation();
	// Navigate
	const navigate = useNavigate();
	// Selectors
	const { showMobile, showUser } = useSelector((state) => state.navigation);
	const { user } = useSelector((state) => state.session);
	// Refs
	const userRef = useRef();
	// Effect
	useEffect(() => {
		if (showUser) {
			userRef.current.focus();
		}
	}, [showUser]);
	// Handlers
	const handleLogoutClick = () => {
		dispatch(runUnauthorizedActions(true));
		removeSession();
	};
	const handleMenuMouseDown = (e) => {
		if (showMobile) {
			e.preventDefault();
			e.stopPropagation();
		}
		return null;
	};
	const handleUserMouseDown = (e) => {
		if (showUser) {
			e.preventDefault();
			e.stopPropagation();
		}
		return null;
	};
	const handleUserBlur = () => {
		if (showUser) {
			dispatch(toggleShowUser({ showUser: true }));
		}
	};
	const handlePasswordClick = () => {
		navigate(`${PROFILES.properties[user.user.role].url}/change-password`);
	}
	// Render
	const userName = user.user != null ? user.user.name : "";
	const renderUserDropdown = () => {
		return (
			<div
				className={showUser ? "dropdown-container show" : "dropdown-container"}
				tabIndex={2}
				onBlur={handleUserBlur}
				ref={userRef}
			>
				<div className="dropdown-items">
					<div className="user-name-mobile">{userName}</div>
					<div className="active" onClick={handlePasswordClick}>
						<div>{t("ChangePassword.Title").toUpperCase()}</div>
					</div>
					<div className="inverted" onClick={handleLogoutClick}>
						<div>{t("Buttons.Logout")}</div>
					</div>
				</div>
			</div>
		);
	};
	return (
		<header className="header">
			<div>
				<div>
					<div>
						<div className="top-menu left">
							<div
								className="icon-button"
								onClick={() => dispatch(toggleShowMobile({ showMobile }))}
								onMouseDown={handleMenuMouseDown}
							>
								<span className="icon-menu-mobile"></span>
							</div>
						</div>
					</div>
					<div>
						<span className="main-logo"></span>
					</div>
					<div>
						<div className="top-menu">
							<div>
								<LanguageDropdown className="locale" />
							</div>
							<div>
								<div
									className="dropdown-button user"
									onClick={() => dispatch(toggleShowUser({ showUser }))}
									onMouseDown={handleUserMouseDown}
								>
									<span className="icon-user"></span>
									<span className="user-name">{userName}</span>
									{renderUserDropdown()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
