import React from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "features/constants";

const EmailList = ({ edit, onEdit, emailList, title, emptyKey }) => {
	// Translation
	const { t } = useTranslation();
	const renderEditButton = () => {
		if (emailList != null && edit == null) {
			return (
				<div>
					<span className="icon-edit" onClick={() => onEdit()}></span>
				</div>
			);
		}
		return null;
	};
	if (emailList == null || emailList.length === 0) {
		return (
			<>
				<div className="details-subtitle">
					<div>{t(title)}</div>
					{renderEditButton()}
				</div>
				<div className="flex-table single-line remove-bottom-space">
					<div>
						<div>
							<div>{t(emptyKey)}</div>
							<div>&nbsp;</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="details-subtitle">
				<div>{t(title)}</div>
				{renderEditButton()}
			</div>
			<div className="flex-table remove-bottom-space">
				{emailList.map((el, index) => {
					return (
						<div key={index}>
							<div>
								<div>{t("Common.Email")}</div>
								<div>{el.email}</div>
							</div>
							<div>
								<div>{t("Fields.FullName")}</div>
								<div>{el.name}</div>
							</div>
							<div>
								<div>{t("Fields.Language")}</div>
								<div>{t(LANGUAGES.properties[el.lang].name)}</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default EmailList;
