import { format, parseISO, parse } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { enUS, es } from "date-fns/locale";
import { dateTypeRegex } from "./regExs";

/**
 * List of supported locales.
 */
export const locales = { en: enUS, es };

/***
 * Get current locale from `window.__localeid__`
 */
export function locale() {
  return locales[window.__localeId__];
}

/**
 * Convert date string to date.
 * @param {*} dateString String to convert to date.
 * @param {*} utc bool option to convert to UTC, false by default.
 * @returns
 */
export function stringToDate(dateString, utc = false) {
  const parsedDate = parseISO(dateString);
  return utc === true ? zonedTimeToUtc(parsedDate, "UTC") : parsedDate;
}


/**
 *
 * @param {*} dateString
 * @param {*} format
 * @returns
 */
export function stringToDateWithFormat(dateString, formatString) {
  return parse(dateString, formatString, new Date());
}

/**
 * Format change format of a date string using `date-fns`.
 * @param {*} dateString string date
 * @param {*} formatString string desired format pattern
 * @param {*} utc bool option to convert to UTC, false by default.
 * @returns
 */
export function formatDate(dateString, formatString, utc = false) {
  if (dateString == null) {
    return "";
  }
  const date = stringToDate(dateString, utc);
  return format(date, formatString, {
    locale: locale(),
  });
}

/**
 *
 * @param {*} date
 * @returns
 */
export const dateToUTC = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

/**
 *
 * @param {*} date
 * @returns
 */
export const formatDateToString = (date) => {
  const stringDate = dateToUTC(date).toISOString();
  return stringDate.slice(0, 19);
};

export const formatDateToStringWithPattern = (date, formatString, utc = false) => {
	if (utc) {
		const utcDate = dateToUTC(date);
		return format(utcDate, formatString);
	}
	return format(date, formatString);
};


/**
 * Render a date, return jsx if null date
 * @param {*} date
 * @param {*} format
 * @param {*} utc
 * @returns jsx formatted date
 */
export const renderDate = (date, format, utc = false) => {
	if (date == null) return (<>&nbsp;</>);
  const formatted = formatDate(date, format, utc);
  return (<>{formatted}</>);
};

export const getDateFromTypePattern = (dateString, locale) => {
	if (dateTypeRegex(locale).test(dateString)) {
		const now = new Date();
		const dateValues = dateString.split(/[-/]/);
			if (dateValues.length === 3) {
				const year = (now.getFullYear()+"").substring(0,2) + dateValues[2];
				if (locale === "es")
					return new Date(year, dateValues[1] - 1, dateValues[0]);
				return new Date(year, dateValues[0] - 1, dateValues[1])
			}
			if (locale === "es") 
				return new Date(now.getFullYear(), dateValues[1] - 1, dateValues[0]);
			return new Date(now.getFullYear(), dateValues[0] - 1, dateValues[1]);
	}
};