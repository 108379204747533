import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useFloating, autoUpdate } from "@floating-ui/react-dom";
import { DayPicker } from "react-day-picker";
import TimePicker from "react-time-picker";
import classNames from "classnames";
import { format, isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import { dateRegEx, timeRegEx } from "utils/regExs";
import { BOOKED_STATUS } from "features/constants";
import {
	formatDateToString,
	locales,
	stringToDateWithFormat,
} from "utils/dateUtils";
import HandleClickOutside from "hooks/handleClickOutsideHook";
import Loader from "components/Loader";

const getErrorField = (error) => {
	switch (error) {
		case "ApiErr.bo_invalidDate":
			return "status_date";
		case "ApiErr.bo_statusExists":
		case "ApiErr.bo_statusNotAllowed":
			return "order_status";
		default:
			return "root.apiError";
	}
};

export default function StatusChangeForm({
	bookedOrder,
	isLoading,
	onClick,
	onCancelAction,
	changeStatusError,
}) {
	const { t, i18n } = useTranslation();
	// Initialize form
	const {
		control,
		register,
		setValue,
		getValues,
		reset,
		handleSubmit,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm();

	// State
	const [showDayPicker, setShowDayPicker] = useState(false);
	const [showTimePicker, setShowTimePicker] = useState(false);

	// Effect
	useEffect(() => {
		if (changeStatusError != null) {
			const errorField = getErrorField(changeStatusError);
			setError(errorField, {
				type: "api",
				message: changeStatusError,
			});
		}
	}, [setError, changeStatusError]);
	// Day picker positioning.
	const { x, y, refs, strategy } = useFloating({
		placement: "bottom-start",
		showDayPicker,
		onOpenChange: setShowDayPicker,
		whileElementsMounted: autoUpdate,
	});
	const dateRegExPattern = dateRegEx(i18n.language);
	// Handlers
	const handleChangeStatusClick = (data, e) => {
		e.preventDefault();
		const dateSpecified = stringToDateWithFormat(
			data.status_date,
			t("Dates.FieldDate")
		);
		const hours = data.status_time.getHours();
		const minutes = data.status_time.getMinutes();

		dateSpecified.setHours(hours);
		dateSpecified.setMinutes(minutes);
		if (dateSpecified > new Date()) {
			setError("status_date", {
				type: "custom",
				message: "FieldErrors.DateInvalid",
			});
			return;
		}
		onClick({
			id: bookedOrder.id,
			public_id: bookedOrder.public_id,
			status: data.order_status.value,
			date_specified: formatDateToString(dateSpecified),
			location: data.status_location !== "" ? data.status_location : undefined,
			notes: data.status_notes !== "" ? data.status_notes : undefined,
		});
	};
	const handleCancelAction = () => {
		reset();
		onCancelAction();
	};
	const handleDateFieldChange = (e) => {
		setValue("status_date", e.currentTarget.value);
		if (dateRegExPattern.test(e.currentTarget.value)) {
			const date = stringToDateWithFormat(
				e.currentTarget.value,
				t("Dates.FieldDate")
			);
			if (isValid(date)) {
				setValue("status_date", format(date, t("Dates.FieldDate")));
			}
		}
	};
	const handleDayClick = (day) => {
		// Set new date.
		setValue("status_date", format(day, t("Dates.FieldDate")));
		// Kill focus
		setShowDayPicker(false);
		setValue("status_time", new Date());
		clearErrors("status_date");
		setShowTimePicker(true);
	};
	// Status options
	const statusOptions = [
		{
			value: BOOKED_STATUS.ARRIVED_PICKUP,
			label: t(BOOKED_STATUS.properties[BOOKED_STATUS.ARRIVED_PICKUP].name),
		},
		{
			value: BOOKED_STATUS.PICKED_UP,
			label: t(BOOKED_STATUS.properties[BOOKED_STATUS.PICKED_UP].name),
		},
		{
			value: BOOKED_STATUS.IN_TRANSIT,
			label: t(BOOKED_STATUS.properties[BOOKED_STATUS.IN_TRANSIT].name),
		},
		{
			value: BOOKED_STATUS.ARRIVED_DELIVERY,
			label: t(BOOKED_STATUS.properties[BOOKED_STATUS.ARRIVED_DELIVERY].name),
		},
		{
			value: BOOKED_STATUS.DELIVERED,
			label: t(BOOKED_STATUS.properties[BOOKED_STATUS.DELIVERED].name),
		},
	];
	// Render
	const renderDayPicker = () => {
		if (showDayPicker)
			return (
				<>
					<div
						ref={refs.setFloating}
						className="daypicker"
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						}}
					>
						<HandleClickOutside onClickOutside={(e) => setShowDayPicker(false)}>
							<DayPicker
								mode="single"
								locale={locales[i18n.resolvedLanguage]}
								onDayClick={handleDayClick}
								toDate={new Date()}
								selected={
									dateRegExPattern.test(getValues("status_date"))
										? stringToDateWithFormat(
												getValues("status_date"),
												t("Dates.FieldDate")
										  )
										: null
								}
								defaultMonth={
									dateRegExPattern.test(getValues("status_date"))
										? stringToDateWithFormat(
												getValues("status_date"),
												t("Dates.FieldDate")
										  )
										: null
								}
							/>
						</HandleClickOutside>
					</div>
				</>
			);
	};
	const renderDateField = () => {
		return (
			<>
				<Controller
					control={control}
					name="status_date"
					rules={{
						required: true,
						pattern: dateRegExPattern,
					}}
					render={({ field: { value } }) => (
						<>
							<div
								className={classNames("b-1 field", {
									error: errors.status_date,
								})}
								ref={refs.setReference}
							>
								<label>{t("Common.Date")}</label>
								<input
									type="text"
									value={value}
									onClick={() => setShowDayPicker(true)}
									onChange={handleDateFieldChange}
									placeholder={t("Dates.Placeholder")}
								></input>
								{showDayPicker && renderDayPicker()}
							</div>
						</>
					)}
				/>
				{showTimePicker && (
					<Controller
						control={control}
						name="status_time"
						render={({ field: { value } }) => (
							<>
								<div
									className={classNames("b-2 field", {
										error: errors.status_time,
									})}
								>
									<label>&nbsp;</label>
									<TimePicker
										format={t("Dates.Time")}
										disableClock={true}
										hourPlaceholder={"00"}
										minutePlaceholder={"00"}
										onChange={(value) => {
											// Set new date.
											const newDate = new Date();
											// Keep hours.
											if (timeRegEx.test(value) && newDate) {
												var timeValues = value.split(":");
												newDate.setHours(
													parseInt(timeValues[0]),
													parseInt(timeValues[1])
												);
											}
											setValue("status_time", newDate);
										}}
										value={value}
										clearIcon={null}
										locale={i18n.language}
									/>
								</div>
							</>
						)}
					/>
				)}
			</>
		);
	};
	const renderError = (error) => {
		if (error == null) return;
		return <li>{error}</li>;
	};
	const renderErrorList = () => {
		if (Object.keys(errors).length === 0 && errors.constructor === Object)
			return;
		return (
			<div className="a error-list">
				<ul>
					{renderError(
						errors.order_status?.type === "required"
							? t("FieldErrors.MandatoryField", {
									fieldName: t("Tables.Status"),
							  })
							: null
					)}
					{renderError(
						errors.order_status?.type === "api"
							? t(errors.order_status?.message)
							: null
					)}
					{renderError(
						errors.status_date?.type === "required"
							? t("FieldErrors.MandatoryField", { fieldName: t("Common.Date") })
							: null
					)}
					{renderError(
						errors.status_date?.type === "custom"
							? t(errors.status_date?.message)
							: null
					)}
					{renderError(
						errors.status_date?.type === "api"
							? t(errors.status_date?.message)
							: null
					)}
					{renderError(
						errors.status_time?.type === "required"
							? t("FieldErrors.MandatoryField", { fieldName: t("Common.Time") })
							: null
					)}
					{renderError(
						errors.status_location?.type === "required"
							? t("FieldErrors.MandatoryField", {
									fieldName: t("Common.Location"),
							  })
							: null
					)}
				</ul>
			</div>
		);
	};
	if (isLoading) {
		return <Loader />;
	}
	return (
		<div className="order-confirmation">
			<div>{t("OperatorOrders.StatusChangeInstructions")}</div>
			<div className="dsv-form collapse no-padding">
				<div
					className={classNames("a field", {
						error: errors.order_status,
					})}
				>
					<label>{t("Tables.Status")}</label>
					<Controller
						name="order_status"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { onChange, value } }) => (
							<Select
								className="dsv-select"
								classNamePrefix="dsv-select"
								value={
									value?.value
										? {
												value: BOOKED_STATUS.properties[`${value.value}`].value,
												label: t(
													BOOKED_STATUS.properties[`${value.value}`].name
												),
										  }
										: null
								}
								onChange={onChange}
								options={statusOptions}
								placeholder={t("Common.Select")}
								isClearable={false}
							/>
						)}
					/>
				</div>
				{renderDateField()}
				<div
					className={classNames("a field", {
						error: errors.status_location,
					})}
				>
					<label>{t("Common.Location")}</label>
					<input
						type="text"
						id="status_location"
						name="status_location"
						{...register("status_location", {
							required: true,
						})}
						placeholder={t("Common.Location")}
					/>
				</div>
				<div className="a field">
					<label>{t("Common.Notes")}</label>
					<textarea
						name="status_notes"
						id="status_notes"
						{...register("status_notes", {
							required: false,
						})}
						placeholder={t("Common.Notes")}
					/>
				</div>
				{renderErrorList()}
			</div>
			<div className="action-buttons">
				<div>
					<div className="button" onClick={handleCancelAction}>
						{t("Buttons.Cancel")}
					</div>
					<div>
						<div
							className="button main"
							onClick={handleSubmit(handleChangeStatusClick)}
						>
							{t("Buttons.Save")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
