import Loader from "components/Loader";
import { ADDRESS_TYPE, CONTACT_TYPE, ORDER_EDIT } from "features/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { getAddress, getContact } from "utils/orderUtils";

const OrderBroker = ({ order, edit, booked, isLoading, onEdit }) => {
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleEditClick = () => {
		onEdit();
	};
	// Render Functions
	/* Renders formatted address */
	const renderAddress = () => {
		const address = getAddress(order, ADDRESS_TYPE.BROKER);
		if (
			order == null ||
			(order?.broker_name == null &&
				address?.address_line_1 == null &&
				address?.address_line_2 == null &&
				address?.city == null &&
				address?.state_province == null &&
				address?.postal_code == null &&
				address?.country_code == null)
		) {
			return (
				<div>
					<span>n/a</span>
				</div>
			);
		}
		let arr = [];
		if (order?.broker_name != null) {
			arr.push(<span>{order?.broker_name}</span>);
			arr.push(<br />);
		}
		if (address?.address_line_1 != null) {
			arr.push(address?.address_line_1);
			arr.push(<br />);
		}
		if (address?.address_line_2 != null) {
			arr.push(address?.address_line_2);
			arr.push(<br />);
		}
		const cityLine = [
			address?.city,
			address?.state_province,
			address?.postal_code,
			address?.country_code,
		]
			.filter(Boolean)
			.join(", ");
		if (cityLine.trim().length > 0) arr.push(cityLine);
		return (
			<div>
				{arr.map((el, index) => {
					return <React.Fragment key={index}>{el}</React.Fragment>;
				})}
			</div>
		);
	};
	const renderContact = () => {
		const contact = getContact(order, CONTACT_TYPE.BROKER);
		let arr = [];
		if (contact?.name != null) {
			arr.push(<span>{contact?.name}</span>);
			arr.push(<br />);
		}
		if (contact?.phone != null) {
			arr.push(contact?.phone);
			arr.push(<br />);
		}
		if (contact?.email != null) {
			arr.push(contact?.email);
			arr.push(<br />);
		}
		if (arr.length > 1) {
			arr.pop();
		}
		return (
			<div>
				{arr.map((el, index) => {
					return <React.Fragment key={index}>{el}</React.Fragment>;
				})}
			</div>
		);
	};
	const renderEdit = () => {
		if (!booked && edit == null) {
			return (
				<div>
					<span className="icon-edit" onClick={handleEditClick}></span>
				</div>
			);
		}
		return null;
	};

	if (edit === ORDER_EDIT.BROKER) {
		return null;
	}
	if (isLoading) {
		return <Loader />;
	}
	return (
		<div className="order-block top-border">
			<div className="order-subtitle">
				<div>{t("Common.CustomsInfo")}</div>
				{renderEdit()}
			</div>
			<div className="order-address">
				<div className="order-address-title">
					<div>{t("Common.BrokerDetails")}</div>
				</div>
				<hr />
				<div className="broker-address">
					<div className="order-address-data">{renderAddress()}</div>
					<div className="order-address-contact">
						{renderContact()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderBroker;
