import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBranchCatalog } from "services/catalogsServices";

const initialState = {
	isLoading: false,
	list: null,
};

/* Fetch carrier catalog */
export const fetchBranchCatalog = createAsyncThunk(
	"branchCatalog/fetchBranchCatalog",
	async (payload) => {
		const { data } = payload;
		const response = await getBranchCatalog(data);
		return response;
	}
);

const branchCatalog = createSlice({
	name: "branchCatalog",
	initialState,
	reducers: {
		clearBranchCatalogData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBranchCatalog.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchBranchCatalog.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
			})
			.addCase(fetchBranchCatalog.rejected, (state) => {
				state.isLoading = false;
				state.list = [];
			});
	},
});

export const { clearBranchCatalogData } = branchCatalog.actions;

export default branchCatalog.reducer;