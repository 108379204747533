import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderNotifyParties from "components/common/order/OrderNotifyParties";
import { ORDER_EDIT } from "features/constants";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";

const OperatorOrderDetailsNotifyParties = () => {
	const dispatch = useDispatch();
	// Selectors
	const { bookedOrder, orderEdit, isLoadingOpen, isLoadingBooked } =
		useSelector((state) => state.operatorOrders);
	// Handlers
	const handleEdit = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.EMAIL_LIST }));
	};
	// Render
	if (bookedOrder == null) return null;
	if (orderEdit === ORDER_EDIT.EMAIL_LIST) return null;
	return (
		<OrderNotifyParties
			order={bookedOrder}
			edit={orderEdit}
			onEdit={handleEdit}
			isLoading={isLoadingOpen === true || isLoadingBooked === true}
		/>
	);
};

export default OperatorOrderDetailsNotifyParties;
