import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CARGO_TYPE, CURRENCY, SHIPMENT_TYPE } from "features/constants";
import { formatDate } from "utils/dateUtils";
import { numberFormat, stringNumberFormat } from "utils/numberUtils";
import { setOperatorOpenQuote } from "features/operator/operatorQuotesSlice";
import Loader from "components/Loader";
import { formatWeight } from "utils/weightUtils";
import { formatVolume } from "utils/volumeUtils";
import { formatDimension } from "utils/dimensionUtils";

export default function QuoteDetails({ onClose }) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { openQuote, isLoadingQuote } = useSelector(
		(state) => state.operatorQuotes
	);
	const { packagings } = useSelector((state) => state.packagings);
	// Handlers
	const handleCloseClick = () => {
		onClose();
		dispatch(setOperatorOpenQuote({ openQuote: null }));
	};
	// Functions
	const formatAddress = (city, state, country) => {
		let arr = [];
		if (city != null) {
			arr.push(city);
		}
		if (state != null) {
			arr.push(state);
		}
		if (country != null) {
			arr.push(country);
		}
		return arr.join(", ");
	};
	const getPackaging = (id) => {
		if (id == null) return <>&nbsp;</>;
		const packaging = packagings?.find((el) => el.id === id);
		return packaging != null ? packaging.name : "";
	};
	// Render
	const renderHeader = () => {
		return (
			<div className="order-file">
				<div>
					<div>
						<span className="icon-collapse"></span>
					</div>
					<div className="file-number">
						<div>
							{t("Fields.QuoteNumber")} {stringNumberFormat(openQuote.id, 8)}
						</div>
						<div>
							<em>{t("Tables.Transport")}</em>{" "}
							{`${SHIPMENT_TYPE.properties[openQuote.shipment_type].code} / 
								${t(CARGO_TYPE.properties[openQuote.cargo_type].name).toUpperCase()}`}
						</div>
					</div>
					<div></div>
					<div>
						<span onClick={handleCloseClick} className="icon-close"></span>
					</div>
				</div>
			</div>
		);
	};
	const renderQuoteInfo = () => {
		const {
			customer_name,
			updated_at,
			origin_port,
			origin_zip,
			origin_city,
			origin_state,
			origin_country,
			destination_port,
			destination_zip,
			destination_city,
			destination_state,
			destination_country,
			pieces,
			packaging_id,
			weight,
			weight_unit,
			volume,
			volume_unit,
			cargo_description,
		} = openQuote;
		return (
			<div className="order-block">
				<div className="order-subtitle">
					<div>{t("OperatorQuotes.QuoteDetails")}</div>
				</div>
				<div className="flex-table">
					<div>
						<div>
							<div>{t("Tables.Customer")}</div>
							<div>{customer_name}</div>
						</div>
						<div>
							<div>{t("Common.Date")}</div>
							<div>
								{updated_at != null ? (
									formatDate(updated_at, t("Dates.DateTime"), false)
								) : (
									<>&nbsp;</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="order-subtitle">
					<div>{t("Common.AddressInfo")}</div>
				</div>
				<div className="order-grid-two">
					<div className="order-address is-port">
						<div className="order-address-title">
							<div>{t("Common.Origin")}</div>
							{origin_port != null && (
								<div>
									<div>{t("Common.PortCode")}</div>
									<div>{origin_port}</div>
								</div>
							)}
						</div>
						<hr />
						<div className="order-address-contact">
							<div>
								<span>{t("Fields.PostalCode")}:</span> {origin_zip}
								<br />
								{formatAddress(origin_city, origin_state, origin_country)}
							</div>
						</div>
					</div>
					<div className="order-address is-port">
						<div className="order-address-title">
							<div>{t("Common.Destination")}</div>
							{destination_port != null && (
								<div>
									<div>{t("Common.PortCode")}</div>
									<div>{destination_port}</div>
								</div>
							)}
						</div>
						<hr />
						<div className="order-address-contact">
							<div>
								<span>{t("Fields.PostalCode")}:</span> {destination_zip}
								<br />
								{formatAddress(
									destination_city,
									destination_state,
									destination_country
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="order-subtitle">
					<div>{t("Common.Cargo")}</div>
				</div>
				<div className="flex-table remove-bottom-space">
					<div>
						<div>
							<div>{t("Tables.Pieces")}</div>
							<div>{pieces}</div>
						</div>
						<div>
							<div>{t("Tables.Packaging")}</div>
							<div>
								{packaging_id != null ? (
									getPackaging(packaging_id)
								) : (
									<>&nbsp;</>
								)}
							</div>
						</div>
						<div>
							<div>{t("Tables.Weight")}</div>
							<div>{formatWeight(weight, weight_unit)}</div>
						</div>
						<div>
							<div>{t("Tables.Volume")}</div>
							<div>{formatVolume(volume, volume_unit)}</div>
						</div>
					</div>
				</div>
				<div className="flex-table single-line remove-bottom-space">
					<div>
						<div>
							<div>{t("Tables.CargoDescription")}</div>
							<div>{cargo_description ?? <>&nbsp;</>}</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const renderHazMat = () => {
		const { hazmat } = openQuote;
		if (hazmat) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("SpecialHandlings.Hazmat")}</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderTempCtl = () => {
		const { tempctl } = openQuote;
		if (tempctl) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("SpecialHandlings.Tempctl")}</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderEmptySpecialHandlings = () => {
		const { hazmat, tempctl } = openQuote;
		if (!hazmat && !tempctl) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("OperatorOrders.NoSpecialHandlings")}</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderCargoDetails = () => {
		const { cargo_details } = openQuote;
		if (cargo_details != null) {
			return (
				<div className="cargo-grid">
					<div className="cargo-details">
						<div className="order-subtitle no-margin">
							<div>{t("Common.Details")}</div>
						</div>
						<div className="details-table">
							<table cellSpacing="0" cellPadding="0">
								<thead>
									<tr>
										<th>{t("Tables.Qty")}</th>
										<th>{t("Tables.Description")}</th>
										<th>{t("Tables.Packaging")}</th>
										<th>{t("Tables.Weight")}</th>
										<th>{t("Tables.Dimensions")}</th>
									</tr>
								</thead>
								<tbody>
									{cargo_details.map((el, index) => {
										const {
											qty,
											description,
											packaging_id,
											weight,
											weight_unit,
											length,
											width,
											height,
											unit,
										} = el;
										return (
											<tr key={index}>
												<td>{Number(qty) ?? <>&nbsp;</>}</td>
												<td>{description ?? <>&nbsp;</>}</td>
												<td>{getPackaging(packaging_id)}</td>
												<td>{formatWeight(weight, weight_unit)}</td>
												<td>{formatDimension(length, width, height, unit)}</td>
											</tr>
										);
									})}
									{cargo_details.length === 0 && (
										<tr>
											<td colSpan="5">
												{t("Tables.EmptyCargoDetails")}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
					<div className="order-block remove-top-space">
						<div className="order-subtitle no-margin">
							<div>{t("SpecialHandlings.Title")}</div>
						</div>
						{renderHazMat()}
						{renderTempCtl()}
						{renderEmptySpecialHandlings()}
					</div>
				</div>
			);
		}
	};
	const renderRates = () => {
		const {calculations} = openQuote;
		const rates = calculations[calculations.length - 1].rates;
		if (rates != null) {
			return (
				<div className="order-book-block">
					<div className="order-subtitle">
						<div>{t("Common.Rates")}</div>
					</div>
					<div className="dsv-table booking-rates">
						<table cellPadding="0" cellSpacing="0">
							<thead>
								<tr>
									<th>{t("Common.Price")}</th>
									<th>{t("Common.Carrier")}</th>
									<th>{t("Fields.TransitTime")}</th>
									<th>{t("Common.Qualifications")}</th>
								</tr>
							</thead>
							<tbody>
								{rates?.map((el, index) => {
									return (
										<tr key={index}>
											<td>{`$${numberFormat(el.price * el.exchange_rate, 2)} ${
												CURRENCY.properties[el.currency].code
											}`}</td>
											<td>
												<div
													className="rates-tooltip"
													data-tooltip-content={el.carrier_data.short_name}
												>
													{el.carrier_data.name}
												</div>
											</td>
											<td className="center">
												{el.carrier_lane?.transit_time != null
													? t("Common.day", {
															count: el.carrier_lane.transit_time,
													  })
													: "n/a"}
											</td>
											<td>
												{el.carrier_data.qualifications.map((q, idx) => {
													return (
														// eslint-disable-next-line jsx-a11y/anchor-is-valid
														<a
															key={idx}
															className="rates-tooltip"
															data-tooltip-content={t(
																`Qualifications.${q.name}`
															)}
														>
															<span className={q.icon}></span>
														</a>
													);
												})}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			);
		}
	};
	if (isLoadingQuote) {
		return <Loader />;
	}
	if (!isLoadingQuote && openQuote == null) {
		handleCloseClick();
		return null;
	}
	return (
		<>
			<div className="order-info">
				{renderHeader()}
				{renderQuoteInfo()}
				{renderCargoDetails()}
			</div>
			<div className="order-actions">
				<div className="order-actions-title">
					<div>
						<div>
							<span className="icon-expand"></span>
						</div>
						<div className="action-title">{t("OperatorQuotes.QuoteDetails")}</div>
						<div>
							<span onClick={handleCloseClick} className="icon-close"></span>
						</div>
					</div>
				</div>
				{renderRates()}
			</div>
		</>
	);
}
