import React from "react";
import { useTranslation } from "react-i18next";
import { dateRegEx, dateTypeRegex } from "utils/regExs";
import classNames from "classnames";
import {
  formatDateToStringWithPattern,
  getDateFromTypePattern,
} from "utils/dateUtils";

export default function DateFilter({ fieldItem, register, setValue, errors }) {
  const { t, i18n } = useTranslation();
  const dateRegPattern = dateRegEx(i18n.language);
  const dateTypePattern = dateTypeRegex(i18n.language);

  const handleDateBlur = (e) => {
    const { name, value } = e.target;
    if (dateTypePattern.test(value)) {
      delete errors[name];
      const date = getDateFromTypePattern(value, i18n.language);
      const dateString = formatDateToStringWithPattern(
        date,
        t("Dates.FieldDate")
      );
      setValue(name, dateString);
    }
  };
	const handleDateKeyDown = (e) => {
		if (e.key === "Enter") {
			const { name, value } = e.target;
			if (dateTypePattern.test(value)) {
				const date = getDateFromTypePattern(value, i18n.language);
				const dateString = formatDateToStringWithPattern(
					date,
					t("Dates.FieldDate")
				);
				setValue(name, dateString);
			}
		}
	};

  // Render
  return (
    // TODO: Make controller and set value as {start:value, end:value}
    <>
      <div
        className={classNames("b-1 field", {
          error: errors[`${fieldItem.field}`]?.start,
        })}
      >
        <label>{t(fieldItem.name)}</label>
        <input
          type="text"
          placeholder={t("Dates.Placeholder")}
          {...register(`${fieldItem.field}.start`, {
            required: true,
            pattern: {
              value: dateRegPattern,
              message: t("FieldErrors.DateInvalid"),
            },
            onBlur: handleDateBlur,
          })}
					onKeyDown={handleDateKeyDown}
        />
      </div>
      <div
        className={classNames("b-2 field", {
          error: errors[`${fieldItem.field}`]?.end,
        })}
      >
        <label>&nbsp;</label>
        <input
          type="text"
          placeholder={t("Dates.Placeholder")}
          {...register(`${fieldItem.field}.end`, {
            required: true,
            pattern: {
              value: dateRegPattern,
              message: "Invalid date.",
            },
            onBlur: handleDateBlur,
          })}
					onKeyDown={handleDateKeyDown}
        />
      </div>
    </>
  );
}
