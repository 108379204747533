import Loader from "components/Loader";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OperatorOrderDetailsBooked from "./OperatorOrderDetailsBooked";
import OperatorOrderDetailsBooking from "./OperatorOrderDetailsBooking";

export default function OperatorOrderActions({
	onClose,
	onCollapse,
	collapse,
	onExpand,
	onConsolidate,
}) {
	// Translate
	const { t } = useTranslation();
	// Selectors
	const { openOrder, isLoadingOpen } = useSelector(
		(state) => state.operatorOrders
	);
	// Render
	const renderBooked = () => {
		const bookedProps = { onClose, onCollapse, collapse, onExpand };
		if (
			!isLoadingOpen &&
			openOrder != null &&
			openOrder.booked_orders.length !== 0
		) {
			return <OperatorOrderDetailsBooked {...bookedProps} />;
		}
	};
	const renderBooking = () => {
		const bookingProps = { onClose, onCollapse, collapse, onExpand, onConsolidate };
		if (
			!isLoadingOpen &&
			openOrder != null &&
			openOrder.booked_orders.length === 0
		) {
			return <OperatorOrderDetailsBooking {...bookingProps} />;
		}
	};
	const renderLoader = () => {
		if (isLoadingOpen) {
			return <Loader msg={t("Common.Loading")} />;
		}
	};
	return (
		<div className="order-actions">
			{renderLoader()}
			{renderBooked()}
			{renderBooking()}
		</div>
	);
}
