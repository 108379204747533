import { CURRENCY, SPOT_PRICE_STATUS } from "features/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils/dateUtils";
import {
	fetchCarrierOfferAccept,
	fetchCarrierOfferReject,
} from "features/carrier/carrierOffersSlice";
import Loader from "components/Loader";
import { numberFormat } from "utils/numberUtils";

export default function CarrierOfferRequestDetailsActions({
	onClose,
	onCollapse,
	collapse,
	onExpand,
}) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { opened, isOpening, isAccepting, isRejecting } = useSelector(
		(state) => state.carrierOffers
	);
	// State
	const [acceptDisclaimer, setAcceptDisclaimer] = useState(false);
	const [activeOption, setActiveOption] = useState(0);
	const [acceptNotes, setAcceptNotes] = useState("");
	const [reason, setReason] = useState("");
	// Handlers
	const handleCloseClick = () => {
		onClose();
		setAcceptDisclaimer(false);
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handleOptionClick = (option) => {
		if (activeOption === option) {
			setActiveOption(null);
		} else {
			setActiveOption(option);
		}
	};
	const handleNotesChange = (e) => {
		setAcceptNotes(e.target.value);
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };
	const renderLoader = () => {
		return (
			<div className="order-actions">
				<div className="order-block">
					<Loader msg={t("Common.Loading")} />
				</div>
			</div>
		);
	};
	const renderBookedInfo = () => {
		if (opened != null) {
			return (
				<div className="order-block">
					<div className="flex-table single-line remove-bottom-space">
						<div>
							<div>
								<div>{t("Common.OfferedPrice")}</div>
								<div>
									{opened.price != null
										? `$${numberFormat(opened.price * opened.exchange_rate, 2)} ${
												CURRENCY.properties[opened?.currency].code
										  }`
										: "-"}
								</div>
							</div>
							<div>
								<div>{t("Tables.ExpiresAt")}</div>
								<div>{formatDate(opened.expires_at, t("Dates.DateTime"))}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderAcceptForm = () => {
		if (!isAccepting) {
			const acceptProps = acceptDisclaimer
				? {
						className: "button main",
						onClick: () =>
							dispatch(
								fetchCarrierOfferAccept({
									id: opened.id,
									notes: acceptNotes !== "" ? acceptNotes : undefined,
								})
							),
				  }
				: { className: "button disabled" };
			return (
				<>
					<div className="a field">
						<label>{t("Common.Notes")}</label>
						<textarea
							name="notes"
							id="notes"
							value={acceptNotes}
							onChange={handleNotesChange}
						></textarea>
					</div>
					<div className="a check-field">
						<input
							type="checkbox"
							name="accept_disclaimer"
							id="accept_disclaimer"
							value={acceptDisclaimer}
							onChange={(e) => setAcceptDisclaimer(e.target.checked)}
						/>
						<label htmlFor="accept_disclaimer">
							<span className="icon-check"></span>
							{t("CarrierOfferRequests.AcceptDisclaimer")}
						</label>
					</div>
					<div className="a top-separation">
						<div {...acceptProps}>{t("Buttons.AcceptNow")}</div>
					</div>
				</>
			);
		} else {
			return (
				<div className="a">
					<Loader />
				</div>
			);
		}
	};
	const renderRejectForm = () => {
		if (!isRejecting) {
			const rejectProps =
				opened.rejected_at == null && reason !== "" && reason.length > 5
					? {
							className: "button main",
							onClick: () =>
								dispatch(
									fetchCarrierOfferReject({
										id: opened.id,
										data: { notes: reason },
									})
								),
					  }
					: { className: "button disabled" };
			return (
				<>
					{opened.rejected_at == null && (
						<div className="a field">
							<label>{t("Fields.RejectReason")}</label>
							<textarea
								name="reason"
								id="reason"
								value={reason}
								onChange={(e) => setReason(e.target.value)}
								placeholder={t("Fields.RejectReason")}
							></textarea>
						</div>
					)}
					{opened.rejected_at != null && (
						<div className="a">
							{t("CarrierOfferRequests.Rejected", {
								rejected_by: opened.rejected_by,
							})}
						</div>
					)}
					{opened.rejected_at == null && (
						<div className="a top-separation">
							<div {...rejectProps}>{t("Buttons.RejectNow")}</div>
						</div>
					)}
				</>
			);
		} else {
			return (
				<div className="a">
					<Loader />
				</div>
			);
		}
	};
	const renderActions = () => {
		if (
			opened != null &&
			opened.spot_price_status === SPOT_PRICE_STATUS.AWAITING
		) {
			const optionStyles = {
				accept:
					activeOption === 0
						? {
								option: { className: "accordion-option active" },
								icon: { className: "icon-chevron expanded" },
						  }
						: {
								option: { className: "accordion-option" },
								icon: { className: "icon-chevron" },
						  },
				reject:
					activeOption === 1
						? {
								option: { className: "accordion-option active" },
								icon: { className: "icon-chevron expanded" },
						  }
						: {
								option: { className: "accordion-option" },
								icon: { className: "icon-chevron" },
						  },
			};
			return (
				<div className="order-block remove-top-space">
					<div className="dsv-accordion">
						<div {...optionStyles.accept.option}>
							<div
								className="accordion-button"
								onClick={() => handleOptionClick(0)}
							>
								<div>{t("CarrierOfferRequests.ActionsTitle")}</div>
								<div>
									<span {...optionStyles.accept.icon}></span>
								</div>
							</div>
							<div className="accordion-content">
								<div className="dsv-form">{renderAcceptForm()}</div>
							</div>
						</div>
						<div {...optionStyles["reject"].option}>
							<div
								className="accordion-button secondary"
								onClick={() => handleOptionClick(1)}
							>
								<div>{t("CarrierBookedOrders.Reject")}</div>
								<div>
									<span {...optionStyles["reject"].icon}></span>
								</div>
							</div>
							<div className="accordion-content">
								<div className="dsv-form">{renderRejectForm()}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderExpired = () => {
		if (
			opened != null &&
			opened.spot_price_status === SPOT_PRICE_STATUS.EXPIRED
		) {
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("CarrierOfferRequests.Expired")}</div>
					</div>
					<div>{t("CarrierOfferRequests.ExpiredText")}</div>
				</div>
			);
		}
	};
	if (isOpening) {
		return renderLoader();
	}
	if (!isOpening && opened != null) {
		return (
			<div className="order-actions">
				<div className="order-actions-title">
					<div>
						<div>
							<span {...collapseProps}></span>
						</div>
						<div className="action-title">
							{t("CarrierOfferRequests.DetailsTitle")}
						</div>
						<div>
							<span className="icon-close" onClick={handleCloseClick}></span>
						</div>
					</div>
				</div>
				{renderBookedInfo()}
				{renderActions()}
				{renderExpired()}
			</div>
		);
	}
	return null;
}
