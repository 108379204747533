import LoggedIn from "components/LoggedIn";
import LoggedInOperator from "components/LoggedInOperator";
import Login from "components/login/Login";
import NotLogged from "components/NotLogged";
import LoggedInCarrier from "components/LoggedInCarrier";
import CarrierBookedOrders from "components/carrier/bookedOrders/CarrierBookedOrders";
import CarrierOrderHistory from "components/carrier/orderHistory/CarrierOrderHistory";
import CarrierProfile from "components/carrier/profile/CarrierProfile";
import OperatorOrders from "components/operator/orders/OperatorOrders";
import LoggedInAdmin from "components/LoggedInAdmin";
import ManageUsers from "components/admin/users/ManageUsers";
import ManageCarriers from "components/admin/carriers/ManageCarriers";
import ManageCatalogs from "components/admin/catalogs/ManageCatalogs";
import CarrierOfferRequests from "components/carrier/offerRequests/CarrierOfferRequests";
import OperatorReports from "components/operator/reports/OperatorReports";
import ChangePassword from "components/common/account/ChangePassword";
import Quotes from "components/operator/quotes/Quotes";

const routes = [
	{
		element: <NotLogged />,
		children: [
			{
				path: "/login",
				element: <Login />,
				exact: true,
			},
		],
	},
	{
		path: "/",
		element: <LoggedIn />,
		exact: true,
	},
	{
		path: "/carrier",
		element: <LoggedInCarrier />,
		children: [
			{
				path: "/carrier/orders",
				element: <CarrierBookedOrders />,
				exact: true,
			},
			{
				path: "/carrier/offers",
				element: <CarrierOfferRequests />,
				exact: true,
			},
			{
				path: "/carrier/history",
				element: <CarrierOrderHistory />,
				exact: true,
			},
			{
				path: "/carrier/profile",
				element: <CarrierProfile />,
				exact: true,
			},
			{
				path: "/carrier/change-password",
				element: <ChangePassword />,
				exact: true,
			},
		]
	},
	{
		path: "/operator",
		element: <LoggedInOperator />,
		children: [
			{
				path: "/operator/orders",
				element: <OperatorOrders />,
				exact: true,
			},
			{
				path: "/operator/quotes",
				element: <Quotes />,
				exact: true,
			},
			{
				path: "/operator/reports",
				element: <OperatorReports />,
				exact: true,
			},
			{
				path: "/operator/change-password",
				element: <ChangePassword />,
				exact: true,
			}
		],
	},
	{
		path: "/admin",
		element: <LoggedInAdmin />,
		children: [
			{
				path: "/admin",
				element: <ManageUsers />,
				exact: true,
			},
			{
				path: "/admin/users",
				element: <ManageUsers />,
				exact: true,
			},
			{
				path: "/admin/users/:role",
				element: <ManageUsers />,
				exact: true,
			},
			{
				path: "/admin/carriers",
				element: <ManageCarriers />,
				exact: true,
			},
			{
				path: "/admin/catalogs",
				element: <ManageCatalogs />,
				exact: true,
			},
			{
				path: "/admin/change-password",
				element: <ChangePassword />,
				exact: true,
			},
		],
	},
];

export default routes;
