/**
 * Regex to validate `HH:MM` pattern.
 */
export const timeRegEx = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

/**
 * Regex to validate strong passwords.
 */
export const passRegEx =
	/^(?=.*[0-9])(?=.*[-_.=()[\]?/\\:,+!¡¿@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9\-_.=()[\]?/\\:,+!¡¿@#$%^&*]{8,16}$/;

/**
 * Regex to validate a traditional date format `M/d/yyyy` pattern.
 */
export const dateRegEx = (locale) => {
	if (locale === "es")
		return /^(((0[1-9]|1\d|2\d|3[01])\/(0[13578]|1[02]))|((0[1-9]|1\d|2\d|3[0])\/(0[469]|1[1]))|((0[1-9]|1\d|2[0-9])\/(0[2])))\/([2][012]\d{2}|[1][9]\d{2})$/;
	return /^(((0[13578]|1[02])\/(0[1-9]|1\d|2\d|3[01]))|((0[469]|1[1])\/(0[1-9]|1\d|2\d|3[0]))|((0[2])\/(0[1-9]|1\d|2[0-9])))\/([2][012]\d{2}|[1][9]\d{2})$/;
};

export const dateTypeRegex = (locale) => {
	if (locale === "es") 
		return /^((0?[1-9])|([12]\d{1})|([3][01]))(-|\/)(0?[1-9]|[1][012])((-|\/)\d{2})?$/;
	return /^(0?[1-9]|[1][012])(-|\/)((0?[1-9])|([12]\d{1})|([3][01]))((-|\/)\d{2})?$/;
};
/**
 * Regex to validate emails.
 */
export const emailRegEx =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Regex to validate emails.
 */
export const userNameRegEx =
	/^(?=[a-zA-Z0-9._@-]{5,20}$)(?!.*[_.\-@]{2})[^_.\-@].*[^_.\-@]$/;

/**
 * Regex to validate zip code (MX).
 */
export const zipRegEx = /^\d{5}$/;

/**
 * Regex to validate if a string is decimal.
 */
export const currencyRegEx = /^\d+(\.\d{0,2})?$/;

/**
 * Regex to validate if a string is decimal.
 */
export const decimalRegEx = (places) => {
	const regexString = `^\\d+(\\.\\d{0,${places}})?$`;
	return new RegExp(regexString);
};

/**
 * Regex to validate temperature inputs.
 */
export const tempRegEx = /^[-]?[1-2]?\d{1,2}(\.\d{0,1})?$/;

/**
 * Regex to validate port code string (3 upper letters).
 */
export const portCodeRegEx = /^([A-Z]){3}$/;
