import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	setCurrentCarrierLane,
	setCurrentCarrierLaneAddEdit,
	fetchCurrentCarrierLanes,
	fetchCurrentCarrierZones,
	fetchCurrentCarrierZonesCatalog,
	fetchSaveCurrentCarrierLane,
	fetchAddCurrentCarrierLane,
} from "features/carrier/carrierProfileSlice";
import { numberFormat } from "utils/numberUtils";
import Pagination from "components/common/pagination/Pagination";
import CarrierLanesAdd from "components/common/carrier/CarrierLanesAdd";

export default function CarrierProfileLanes() {
	const dispatch = useDispatch();
	// Translate
	const { t } = useTranslation();
	// Selectors
	const {
		lanes,
		isLoadingLanes,
		isSavingLane,
		lane,
		laneAdd,
		laneEdit,
		zones,
		isLoadingZones,
		isLoadingZonesCatalog,
		zonesCatalog,
	} = useSelector((state) => state.carrierProfile);

	useEffect(() => {
		if (lanes == null && !isLoadingLanes) {
			dispatch(fetchCurrentCarrierLanes({ page: 1, filters: {} }));
		}
		if (zones === null && !isLoadingZones) {
			dispatch(fetchCurrentCarrierZones({ filters: {} }));
		}

		if (zonesCatalog === null && !isLoadingZonesCatalog) {
			dispatch(fetchCurrentCarrierZonesCatalog());
		}
	}, [
		dispatch,
		isLoadingZones,
		zones,
		isLoadingZonesCatalog,
		zonesCatalog,
		isLoadingLanes,
		lanes,
	]);

	// State
	const [activeRow, setActiveRow] = useState(null);
	const handleLaneAddClick = () => {
		setActiveRow(null);
		dispatch(setCurrentCarrierLane());
		dispatch(setCurrentCarrierLaneAddEdit({ laneAdd: true }));
	};
	// Handlers
	const handleLaneClick = (lane, index) => {
		dispatch(setCurrentCarrierLane({ lane: lane }));
		dispatch(setCurrentCarrierLaneAddEdit({ laneEdit: true }));
		setActiveRow(index);
	};
	const handlePageClick = (page) => {
		dispatch(fetchCurrentCarrierLanes({ page }));
	};

	if (isLoadingLanes) {
		return <Loader msg={t("Common.Loading")} />;
	}

	if (laneAdd || laneEdit) {
		return (
			<CarrierLanesAdd
				onSave={(data) => {
					dispatch(
						fetchSaveCurrentCarrierLane({
							laneId: lane.id,
							data: data,
						})
					);
				}}
				onAdd={(data) => {
					dispatch(fetchAddCurrentCarrierLane({ data: data })); //carrierId: carrier.id,
				}}
				onClose={() => {
					dispatch(setCurrentCarrierLaneAddEdit());
				}}
				lane={laneEdit ? lane : null}
				zonesCatalog={zonesCatalog}
				isSavingLane={isSavingLane}
			/>
		);
	}

	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={lanes?.current_page ?? 1}
				last={lanes?.last_page ?? 1}
				onRefresh={() => {
					handlePageClick(lanes?.current_page ?? 1);
				}}
			/>
		);
	};

	return (
		<div className="tab-content details">
			<div className="dsv-table">
				<div className="details-subtitle">
					<div>{t("AdminCarriers.Lanes")}</div>
					<div onClick={handleLaneAddClick}>
						<span className="icon-add"></span>
					</div>
				</div>
				{renderPagination()}
				<table cellSpacing="0" cellPadding="0">
					<thead>
						<tr>
							<th>{t("Common.Name")}</th>
							<th>{t("Fields.WMRate")}</th>
						</tr>
					</thead>
					<tbody>
						{lanes?.data?.map((el, index) => {
							const activeProps =
								activeRow != null
									? index === activeRow
										? { className: "active" }
										: {}
									: {};
							return (
								<tr
									{...activeProps}
									key={index}
									onClick={() => handleLaneClick(el, index)}
								>
									<td>{el.name}</td>
									<td className="right">{`$${numberFormat(
										el.w_m_rate,
										2
									)}`}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{renderPagination()}
		</div>
	);
}
