import { fetchCarrierHistory } from "features/carrier/carrierHistorySlice";
import { fetchCarrierOffers } from "features/carrier/carrierOffersSlice";
import { setCarrierOrdersRefresh } from "features/carrier/carrierOrdersSlice";
import { CARRIER_FILTERS } from "features/constants";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarrierFilters } from "utils/carrierUtils";
import SidebarItem from "./SidebarItem";
import { toggleShowMobile } from "features/navigation/navigationSlice";

export default function SidebarCarrier() {
	const dispatch = useDispatch();
	// Selectors
	const { showMobile } = useSelector((state) => state.navigation);
	// Refs
	const sideBarRef = useRef();
	// Effect
	useEffect(() => {
		if (showMobile) {
			sideBarRef.current.focus();
		}
	}, [showMobile]);
	// Handlers
	const handleBlur = () => {
		if (showMobile) dispatch(toggleShowMobile({ showMobile: true }));
	};
	const handleMouseDown = (e) => {
		if (showMobile) {
			e.preventDefault();
			e.stopPropagation();
		}
		return;
	};
	// Render
	const getSidebarClass = () => {
		if (showMobile) {
			return "sidebar show";
		}
		return "sidebar";
	};
	const sidebarClass = getSidebarClass();
	// const bookedFilters = getCarrierFilters(CARRIER_FILTERS.BOOKED);
	const historyFilters = getCarrierFilters(CARRIER_FILTERS.HISTORY);
	return (
		<div
			className={sidebarClass}
			tabIndex={1}
			onMouseDown={handleMouseDown}
			onBlur={handleBlur}
			ref={sideBarRef}
		>
			<div>
				<SidebarItem
					el="/carrier/orders"
					icon="icon-booked"
					text="Sidebar.Booked"
					action={() => {
						dispatch(setCarrierOrdersRefresh({refresh:true}));
					}}
				/>
				<SidebarItem
					el="/carrier/offers"
					icon="icon-requests"
					text="Sidebar.Offer"
					action={() => {
						dispatch(fetchCarrierOffers());
					}}
				/>
				<SidebarItem
					el="/carrier/history"
					icon="icon-history"
					text="Sidebar.History"
					action={() =>
						dispatch(fetchCarrierHistory({ filters: historyFilters }))
					}
				/>
				<SidebarItem
					el="/carrier/profile"
					icon="icon-profile"
					text="Sidebar.Profile"
				/>
			</div>
		</div>
	);
}
