import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import {
	getUser,
	getUserSettings,
	postChangePassword,
	putUserSettings,
} from "services/sessionServices";
import {
	runAdminAuthenticatedActions,
	runCarrierAuthenticatedActions,
	runOperatorAuthenticatedActions,
} from "./loginSlice";
import { PROFILES } from "features/constants";

/**
 * Define the intial state.
 */
const initialState = {
	isLoading: false,
	errorMsg: null,
	usrChecked: false,
	settings: null,
	showSettings: false,
	isLoadingSettings: false,
	isSavingSettings: false,
	isChangingPassword: false,
	changePasswordErrors: null,
	changePasswordSuccess: false,
};

/**
 * Async reducer function to the user data async.
 */
export const fetchUser = createAsyncThunk(
	"account/fetchUser",
	async (payload, { dispatch }) => {
		const response = await getUser();
		sessionService
			.saveUser({ user: response.data.user })
			.then()
			.catch((error) => console.log("error", error));
		// Operator
		if (response.data.user.roles.includes(PROFILES.OPERATOR)) {
			dispatch(runOperatorAuthenticatedActions());
		}
		// Carrier
		if (response.data.user.roles.includes(PROFILES.CARRIER)) {
			dispatch(runCarrierAuthenticatedActions());
		}
		// Admin & SuperAdmin
		if (
			response.data.user.roles.includes(PROFILES.ADMIN) ||
			response.data.user.roles.includes(PROFILES.SUPERADMIN)
		) {
			dispatch(runAdminAuthenticatedActions());
		}
		return response;
	}
);
export const fetchUserSettings = createAsyncThunk(
	"account/fetchUserSettings",
	async () => {
		const response = await getUserSettings();
		return response;
	}
);
export const fetchSaveUserSettings = createAsyncThunk(
	"account/fetchSaveUserSettings",
	async (payload, { dispatch }) => {
		const { data } = payload;
		const response = await putUserSettings({ settings: { settings: data } });
		dispatch(fetchUserSettings());
		return response;
	}
);
export const fetchChangePassword = createAsyncThunk(
	"account/fetchChangePassword",
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = payload;
			const response = await postChangePassword(data);
			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

/**
 * Create and configure slice.
 */
const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		/**
		 * Displays an error message in the UI.
		 */
		setAccountErrorMsg: (state, { payload }) => {
			state.errorMsg = payload.errorMsg;
		},
		setAccountShowSettings: (state, { payload }) => {
			state.showSettings = payload.showSettings ?? false;
		},
		setChangePasswordSucces: (state, { payload }) => {
			state.changePasswordSuccess = payload.changePasswordSuccess ?? false;
		},
		clearAccountData: () => initialState,
	},
	/**
	 * Use extra reducers for async executions.
	 * @param {*} builder
	 */
	extraReducers: (builder) => {
		builder
			.addCase(fetchUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchUser.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.usrChecked = true;
			})
			.addCase(fetchUser.rejected, (state) => {
				state.isLoading = false;
				state.usrChecked = true;
			})
			.addCase(fetchUserSettings.pending, (state) => {
				state.isLoadingSettings = true;
			})
			.addCase(fetchUserSettings.fulfilled, (state, { payload }) => {
				state.isLoadingSettings = false;
				state.settings = payload.data.display_settings;
			})
			.addCase(fetchUserSettings.rejected, (state) => {
				state.isLoadingSettings = false;
			})
			.addCase(fetchSaveUserSettings.pending, (state) => {
				state.isSavingSettings = true;
			})
			.addCase(fetchSaveUserSettings.fulfilled, (state, { payload }) => {
				state.isSavingSettings = false;
				state.settings = payload.data.display_settings;
				state.showSettings = false;
			})
			.addCase(fetchSaveUserSettings.rejected, (state) => {
				state.isSavingSettings = false;
			})
			.addCase(fetchChangePassword.pending, (state) => {
				state.isChangingPassword = true;
			})
			.addCase(fetchChangePassword.fulfilled, (state, { payload }) => {
				state.isChangingPassword = false;
				state.changePasswordErrors = null;
				state.changePasswordSuccess = true;
			})
			.addCase(fetchChangePassword.rejected, (state, { payload }) => {
				state.isChangingPassword = false;
				state.changePasswordErrors = payload?.errors ?? null;
			});
	},
});

/**
 * Export reducers.
 */
export const {
	setAccountErrorMsg,
	setAccountShowSettings,
	setChangePasswordSucces,
	clearAccountData,
} = accountSlice.actions;

/**
 * Export slice.
 */
export default accountSlice.reducer;
