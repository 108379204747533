import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import classNames from "classnames";
import { toCargoEditForm } from "utils/orderUtils";
import { toSelector, toSelectorValue } from "utils/constantsUtils";
import { decimalRegEx, tempRegEx } from "utils/regExs";
import { removeEmptyProps } from "utils/formUtils";
import { numberFormat } from "utils/numberUtils";
import { convertKgToLb, convertLbToKg } from "utils/weightUtils";
import { calcVolume } from "utils/volumeUtils";
import {
	ORDER_EDIT,
	WEIGHT_UNIT,
	VOLUME_UNIT,
	DIMENSION_UNIT,
	TEMPERATURE_UNIT,
	FREIGHT_CLASS,
	SUBSTANCE_GROUP,
} from "features/constants";
import Loader from "components/Loader";

// TODO: Styling

/**
 * Operator's booked order header edit component.
 * @returns
 */
export default function OrderCargoForm({
	collapse,
	order,
	edit,
	packagings,
	isSaving,
	onSave,
	onCancel,
}) {
	// Translation
	const { t } = useTranslation();
	// Declare form state
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "cargo_details",
	});
	const [totPcs, setTotPcs] = useState(null);
	const [totWgt, setTotWgt] = useState(null);
	const [totVol, setTotVol] = useState(null);

	// Effect
	useEffect(() => {
		reset(toCargoEditForm(order, packagings));
		if (totWgt == null) {
			if (order.cargo_actual_weight_unit === WEIGHT_UNIT.LBS) {
				setTotWgt(convertKgToLb(order.cargo_actual_weight, 2));
			} else {
				setTotWgt(order.cargo_actual_weight);
			}
			if (order.cargo_volume_unit === VOLUME_UNIT.CFT) {
				setTotVol(convertKgToLb(order.cargo_volume, 2));
			} else {
				setTotVol(order.cargo_volume);
			}
		}
		if (totPcs == null) setTotPcs(order.cargo_pieces);
	}, [reset, order, packagings, totPcs, totWgt]);

	watch("special_handlings_hazmat");
	watch("special_handlings_tempctl");

	const calcTotalPieces = () => {
		const detValues = getValues("cargo_details");
		const totalPieces = detValues.reduce((sum, el) => {
			if (Number.isNaN(el.pieces)) return sum;
			return sum + Number("0" + el.pieces);
		}, 0);
		setValue("cargo_pieces", totalPieces);
		setTotPcs(totalPieces);
		calcTotalVolume();
	};

	const calcTotalWeight = () => {
		const wUnit = getValues("cargo_actual_weight_unit");
		const detValues = getValues("cargo_details");
		const totalWeight = detValues.reduce((sum, el) => {
			if (Number.isNaN(el.total_weight)) return sum;
			if (wUnit.value !== el.weight_unit.value) {
				if (wUnit.value === WEIGHT_UNIT.KGS) {
					return sum + convertLbToKg(el.total_weight, 2);
				} else {
					return sum + convertKgToLb(el.total_weight, 2);
				}
			} else {
				return sum + Number("0" + el.total_weight);
			}
		}, 0);
		setValue("cargo_actual_weight", totalWeight);
		setTotWgt(totalWeight);
	};
	const calcTotalVolume = () => {
		const vUnit = getValues("cargo_volume_unit");
		const detValues = getValues("cargo_details");
		const totalVolume = detValues.reduce((sum, el) => {
			if (
				Number.isNaN(el.length) ||
				Number.isNaN(el.width) ||
				Number.isNaN(el.height)
			)
				return sum;
			return (
				calcVolume(
					vUnit.value === VOLUME_UNIT.CBM,
					el.length,
					el.width,
					el.height,
					el.unit.value,
					el.pieces
				) + sum
			);
		}, 0);
		const totalRounded = Number(totalVolume.toFixed(2));
		setValue("cargo_volume", totalRounded);
		setTotVol(totalRounded);
	};
	// Submit function.
	const handleSaveCargoData = (data, event) => {
		event.preventDefault();

		// TODO: Transform from react-hook-form.
		if (data.cargo_packaging_id) {
			data.cargo_packaging_id = data.cargo_packaging_id.value;
		}

		if (data.cargo_actual_weight_unit) {
			data.cargo_actual_weight_unit = data.cargo_actual_weight_unit.value;
		}

		if (data.cargo_volume_unit) {
			data.cargo_volume_unit = data.cargo_volume_unit.value;
		}

		if (data.cargo_freight_class) {
			data.cargo_freight_class = data.cargo_freight_class.value;
		}

		if (data.special_handlings_hazmat) {
			if (data.special_handlings_hazmat_group) {
				data.special_handlings_hazmat_group =
					data.special_handlings_hazmat_group.value;
			}
			if (data.special_handlings_hazmat_flashpoint_unit) {
				data.special_handlings_hazmat_flashpoint_unit =
					data.special_handlings_hazmat_flashpoint_unit.value;
			}
			// Send values as number.
			data.special_handlings_hazmat_flashpoint = Number(
				data.special_handlings_hazmat_flashpoint
			);
		} else {
			delete data["special_handlings_hazmat_substance"];
			delete data["special_handlings_hazmat_group"];
			delete data["special_handlings_hazmat_class_value"];
			delete data["special_handlings_hazmat_flashpoint"];
			delete data["special_handlings_hazmat_flashpoint_unit"];
		}
		if (data.special_handlings_tempctl && data.special_handlings_tempctl_unit) {
			data.special_handlings_tempctl_unit =
				data.special_handlings_tempctl_unit.value;
			// Send values as number.
			data.special_handlings_tempctl_min = Number(
				data.special_handlings_tempctl_min
			);
			data.special_handlings_tempctl_max = Number(
				data.special_handlings_tempctl_max
			);
		} else {
			delete data["special_handlings_tempctl_min"];
			delete data["special_handlings_tempctl_max"];
			delete data["special_handlings_tempctl_unit"];
		}

		if (data.cargo_details) {
			data.cargo_details =
				data.cargo_details?.map((item, index) => {
					return {
						pieces: item.pieces,
						description: item.description,
						packaging_id: item.packaging_id.value,
						total_weight: item?.total_weight,
						weight_unit: item.weight_unit.value,
						length: item.length,
						width: item.width,
						height: item.height,
						unit: item.unit.value,
					};
				}) ?? [];
		}
		const cleanData = removeEmptyProps(data);

		// Return data.
		if (order != null) {
			onSave(cleanData);
		}
	};

	const handleCancelEdit = () => {
		// Clear form.
		reset();
		onCancel();
	};
	const handleAddDetail = () => {
		const formValues = getValues();
		const {
			cargo_pieces,
			cargo_description,
			cargo_packaging_id,
			cargo_actual_weight,
			cargo_actual_weight_unit,
		} = formValues;
		if (fields.length === 0) {
			setTotPcs(cargo_pieces);
			setTotWgt(cargo_actual_weight);
			append({
				pieces: cargo_pieces,
				description: cargo_description,
				packaging_id: cargo_packaging_id,
				total_weight: cargo_actual_weight,
				weight_unit: cargo_actual_weight_unit,
				length: "",
				width: "",
				height: "",
				unit: toSelectorValue(
					DIMENSION_UNIT.properties,
					cargo_actual_weight_unit.value,
					true
				),
			});
		} else {
			append({
				pieces: "",
				description: cargo_description,
				packaging_id: cargo_packaging_id,
				total_weight: "",
				weight_unit: cargo_actual_weight_unit,
				length: "",
				width: "",
				height: "",
				unit: toSelectorValue(
					DIMENSION_UNIT.properties,
					cargo_actual_weight_unit.value,
					true
				),
			});
		}
	};

	const handleRemoveDetail = (index) => {
		remove(index);
		if (fields.length !== 1) {
			calcTotalPieces();
			calcTotalWeight();
			calcTotalVolume();
		}
	};
	/**
	 * Function that render edit form.
	 * @returns
	 */
	const render = () => {
		return (
			<div className="order-block edit top-border">
				<div
					className={classNames("dsv-form full no-padding", {
						collapse: collapse,
					})}
				>
					<div className="a order-subtitle">
						<div>{t("Common.Cargo")}</div>
						<div>
							<span
								className="icon-edit-close"
								onClick={handleCancelEdit}
							></span>
						</div>
						<div>
							<span
								className="icon-edit-save"
								onClick={handleSubmit(handleSaveCargoData)}
							></span>
						</div>
					</div>
					<div
						className={classNames("b-11 field", {
							error: errors.cargo_pieces,
						})}
					>
						<label>{t("Tables.Pieces")}</label>
						<input
							type={fields.length !== 0 ? "hidden" : "text"}
							placeholder={t("Tables.Pieces")}
							{...register("cargo_pieces", {
								required: true,
								onChange: (e) => {
									setValue(`cargo_pieces`, e.target.value.replace(/[^\d]/, ""));
								},
								pattern: decimalRegEx(5),
								valueAsNumber: true,
								setValueAs: (v) => parseInt(v),
							})}
						></input>
						{fields.length !== 0 ? <>{numberFormat(totPcs, 0)}</> : ""}
					</div>
					<div
						className={classNames("b-12 field", {
							error: errors.cargo_packaging_id,
						})}
					>
						<label>{t("Tables.Packaging")}</label>
						<Controller
							name="cargo_packaging_id"
							control={control}
							render={({ field }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									{...field}
									options={packagings.map((el) => {
										return {
											value: el.id,
											label: el.name,
										};
									})}
								/>
							)}
						/>
					</div>
					<div
						className={classNames("b-21 field", {
							error: errors.cargo_actual_weight,
						})}
					>
						<label>{t("Tables.Weight")}</label>
						<input
							type={fields.length !== 0 ? "hidden" : "text"}
							placeholder={t("Tables.Weight")}
							{...register("cargo_actual_weight", {
								required: true,
								onChange: (e) => {
									setValue(
										`cargo_actual_weight`,
										e.target.value.replace(/[^\d]/, "")
									);
								},
								pattern: decimalRegEx(5),
								valueAsNumber: true,
								setValueAs: (v) => parseInt(v),
							})}
						></input>
						{fields.length !== 0 ? <>{numberFormat(totWgt, 0)}</> : ""}
					</div>
					<div
						className={classNames("b-22 field", {
							error: errors.cargo_actual_weight_unit,
						})}
					>
						<label>{t("WeightUnits.Title")}</label>
						<Controller
							name="cargo_actual_weight_unit"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									onChange={(val) => {
										onChange(val);
										calcTotalWeight();
									}}
									value={
										value?.value
											? {
													value: WEIGHT_UNIT.properties[`${value.value}`].value,
													label: t(
														WEIGHT_UNIT.properties[`${value.value}`].code
													),
											  }
											: {}
									}
									options={toSelector(WEIGHT_UNIT.properties, true)}
								/>
							)}
						/>
					</div>
					<div
						className={classNames("b-11 field", {
							error: errors.cargo_volume,
						})}
					>
						<label>{t("Tables.Volume")}</label>
						<input
							type={fields.length !== 0 ? "hidden" : "text"}
							placeholder={t("Tables.Volume")}
							{...register("cargo_volume", {
								required: false,
								onChange: function (e) {
									setValue(
										`cargo_volume`,
										e.target.value.replace(/[^\d.]/, "")
									);
								},
								pattern: decimalRegEx(5),
								valueAsNumber: true,
								setValueAs: (v) => parseInt(v),
							})}
						></input>
						{fields.length !== 0 ? <>{numberFormat(totVol ?? 0, 2)}</> : ""}
					</div>
					<div
						className={classNames("b-12 field", {
							error: errors.cargo_volume_unit,
						})}
					>
						<label>{t("VolumeUnits.Title")}</label>
						<Controller
							name="cargo_volume_unit"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									onChange={(val) => {
										onChange(val);
										calcTotalVolume();
									}}
									value={
										value?.value
											? {
													value: VOLUME_UNIT.properties[`${value.value}`].value,
													label: t(
														VOLUME_UNIT.properties[`${value.value}`].code
													),
											  }
											: {}
									}
									options={toSelector(VOLUME_UNIT.properties, true)}
								/>
							)}
						/>
					</div>
					<div className="b-21 field">
						<label>{t("Tables.FreightClass")}</label>
						<Controller
							name="cargo_freight_class"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									onChange={onChange}
									value={
										value?.value
											? {
													value:
														FREIGHT_CLASS.properties[`${value.value}`].value,
													label:
														FREIGHT_CLASS.properties[`${value.value}`].name,
											  }
											: {}
									}
									options={toSelector(FREIGHT_CLASS.properties, false, false)}
								/>
							)}
						/>
					</div>
					<div className="a field">
						<label>{t("Tables.CargoDescription")}</label>
						<input
							type="text"
							placeholder={t("Tables.CargoDescription")}
							{...register("cargo_description", {
								required: true,
							})}
						></input>
					</div>
					<div className="a order-subtitle top-separation">
						<div>{t("SpecialHandlings.Title")}</div>
					</div>
					<div className="a check-field">
						<input
							type="checkbox"
							name="special_handlings_hazmat"
							id="special_handlings_hazmat"
							{...register("special_handlings_hazmat", {})}
						/>
						<label htmlFor="special_handlings_hazmat">
							<span className="icon-check"></span>
							{t("SpecialHandlings.Hazmat")}
						</label>
					</div>

					{getValues("special_handlings_hazmat") === true ? (
						<>
							<div
								className={classNames("b-11 field", {
									error: errors.special_handlings_hazmat_substance,
								})}
							>
								<label>{t("Fields.Substance")}</label>
								<input
									type="text"
									placeholder={t("Fields.Substance")}
									{...register("special_handlings_hazmat_substance", {
										required: true,
									})}
								></input>
							</div>
							<div
								className={classNames("b-12 field", {
									error: errors.special_handlings_hazmat_class,
								})}
							>
								<label>{t("Fields.SubstanceGroup")}</label>
								<Controller
									name="special_handlings_hazmat_group"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											isClearable={false}
											onChange={onChange}
											value={
												value?.value
													? {
															value:
																SUBSTANCE_GROUP.properties[`${value.value}`]
																	.value,
															label:
																SUBSTANCE_GROUP.properties[`${value.value}`]
																	.code,
													  }
													: {}
											}
											options={toSelector(SUBSTANCE_GROUP.properties, true)}
										/>
									)}
								/>
							</div>
							<div
								className={classNames("b-211 field", {
									error: errors.special_handlings_hazmat_substance,
								})}
							>
								<label>{t("Fields.SubstanceClass")}</label>
								<input
									type="text"
									placeholder={t("Fields.SubstanceClass")}
									{...register("special_handlings_hazmat_class_value", {
										required: true,
									})}
								></input>
							</div>
							<div
								className={classNames("b-212 field", {
									error: errors.special_handlings_hazmat_flashpoint,
								})}
							>
								<label>{t("Fields.FlashPoint")}</label>
								<input
									type="text"
									placeholder={t("Fields.FlashPoint")}
									{...register("special_handlings_hazmat_flashpoint", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-213 field", {
									error: errors.special_handlings_hazmat_flashpoint_unit,
								})}
							>
								<label>&nbsp;</label>
								<Controller
									name="special_handlings_hazmat_flashpoint_unit"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											isClearable={false}
											onChange={onChange}
											value={
												value?.value
													? {
															value:
																TEMPERATURE_UNIT.properties[`${value.value}`]
																	.value,
															label:
																TEMPERATURE_UNIT.properties[`${value.value}`]
																	.code,
													  }
													: {}
											}
											options={toSelector(TEMPERATURE_UNIT.properties, true)}
										/>
									)}
								/>
							</div>
						</>
					) : (
						<></>
					)}

					<div className="d-1 check-field">
						<input
							type="checkbox"
							id="special_handlings_tempctl"
							name="special_handlings_tempctl"
							{...register("special_handlings_tempctl", {})}
						/>
						<label htmlFor="special_handlings_tempctl">
							<span className="icon-check"></span>
							{t("SpecialHandlings.Tempctl")}
						</label>
					</div>

					{getValues("special_handlings_tempctl") ? (
						<>
							<div
								className={classNames("b-211 field", {
									error: errors.special_handlings_tempctl_min,
								})}
							>
								<label>{t("Common.Min")}</label>
								<input
									type="text"
									placeholder={t("Common.Min")}
									{...register("special_handlings_tempctl_min", {
										required: true,
										pattern: { value: tempRegEx, message: "Invalid value" },
									})}
								></input>
							</div>
							<div
								className={classNames("b-212 field", {
									error: errors.special_handlings_tempctl_max,
								})}
							>
								<label>{t("Common.Max")}</label>
								<input
									type="text"
									placeholder={t("Common.Max")}
									{...register("special_handlings_tempctl_max", {
										required: true,
										pattern: { value: tempRegEx, message: "Invalid value" },
									})}
								></input>
							</div>
							<div
								className={classNames("b-213 field", {
									error: errors.special_handlings_tempctl_unit,
								})}
							>
								<label>&nbsp;</label>
								<Controller
									name="special_handlings_tempctl_unit"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											isClearable={false}
											onChange={onChange}
											value={
												value?.value
													? {
															value:
																TEMPERATURE_UNIT.properties[`${value.value}`]
																	.value,
															label:
																TEMPERATURE_UNIT.properties[`${value.value}`]
																	.code,
													  }
													: {}
											}
											options={toSelector(TEMPERATURE_UNIT.properties, true)}
										/>
									)}
								/>
							</div>
						</>
					) : (
						<></>
					)}

					<div className="a order-subtitle top-separation">
						<div>{t("Common.Details")}</div>
						<div>
							<span className="icon-add" onClick={handleAddDetail}></span>
						</div>
					</div>
					<div className="a field">
						<div className="field-table cargo-details-edit">
							<div>
								<table cellPadding="0" cellSpacing="0">
									<thead>
										<tr>
											<th>{t("Tables.Qty")}</th>
											<th>{t("Tables.Description")}</th>
											<th>{t("Tables.Packaging")}</th>
											<th colSpan="2">{t("Tables.Weight")}</th>
											<th>{t("Fields.DimensionLength")}</th>
											<th>{t("Fields.DimensionHeight")}</th>
											<th>{t("Fields.DimensionWidth")}</th>
											<th>{t("Common.Unit")}</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{fields.map((item, index) => {
											return (
												<>
													<tr>
														<td>
															<input
																className="cargo-01"
																type="text"
																placeholder={t("Tables.Qty")}
																{...register(`cargo_details.${index}.pieces`, {
																	required: true,
																	onChange: (e) => {
																		const value = e.target.value.replace(
																			/[^\d]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.pieces`,
																			value
																		);
																		// const detValues = getValues("cargo_details");
																		// const totalPieces = detValues.reduce((sum, el) => {
																		// 	if (Number.isNaN(el.pieces)) return sum;
																		// 	return sum + Number("0" + el.pieces);
																		// }, 0);
																		// setValue("cargo_pieces", totalPieces);
																		// setTotPcs(totalPieces);
																		calcTotalPieces();
																	},
																	pattern: decimalRegEx(0),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td>
															<input
																type="text"
																placeholder={t("Tables.Description")}
																{...register(
																	`cargo_details.${index}.description`,
																	{
																		required: true,
																	}
																)}
															></input>
														</td>
														<td>
															<Controller
																name={`cargo_details.${index}.packaging_id`}
																control={control}
																rules={{
																	required: true,
																}}
																render={({ field }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		{...field}
																		options={packagings.map((el, index) => {
																			return {
																				value: el.id,
																				label: el.name,
																			};
																		})}
																	/>
																)}
															/>
														</td>
														<td>
															<input
																type="text"
																placeholder={t("Tables.Weight")}
																{...register(
																	`cargo_details.${index}.total_weight`,
																	{
																		required: true,
																		onChange: function (e) {
																			const value = e.target.value.replace(
																				/[^\d.]/,
																				""
																			);
																			setValue(
																				`cargo_details.${index}.total_weight`,
																				value
																			);
																			calcTotalWeight();
																		},
																		pattern: decimalRegEx(5),
																		valueAsNumber: true,
																		setValueAs: (v) => parseFloat(v),
																	}
																)}
															></input>
														</td>
														<td>
															<Controller
																name={`cargo_details.${index}.weight_unit`}
																control={control}
																rules={{ required: true }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		onChange={(val) => {
																			onChange(val);
																			calcTotalWeight();
																		}}
																		value={value}
																		options={toSelector(
																			WEIGHT_UNIT.properties,
																			true
																		)}
																	/>
																)}
															/>
														</td>
														<td>
															<input
																type="number"
																placeholder="L"
																length
																{...register(`cargo_details.${index}.length`, {
																	required: true,
																	onChange: function (e) {
																		const value = e.target.value.replace(
																			/[^\d.]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.length`,
																			value
																		);
																		calcTotalVolume();
																	},
																	pattern: decimalRegEx(5),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td>
															<input
																type="text"
																placeholder="W"
																{...register(`cargo_details.${index}.width`, {
																	required: true,
																	onChange: function (e) {
																		const value = e.target.value.replace(
																			/[^\d.]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.width`,
																			value
																		);
																		calcTotalVolume();
																	},
																	pattern: decimalRegEx(5),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td>
															<input
																type="text"
																placeholder="H"
																{...register(`cargo_details.${index}.height`, {
																	required: true,
																	onChange: function (e) {
																		const value = e.target.value.replace(
																			/[^\d.]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.height`,
																			value
																		);
																		calcTotalVolume();
																	},
																	pattern: decimalRegEx(5),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td>
															<Controller
																name={`cargo_details.${index}.unit`}
																control={control}
																rules={{ required: true }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		onChange={(val) => {
																			onChange(val);
																			calcTotalVolume();
																		}}
																		value={value}
																		options={toSelector(
																			DIMENSION_UNIT.properties,
																			true
																		)}
																	/>
																)}
															/>
														</td>
														<td>
															<span
																className="icon-remove"
																onClick={() => {
																	handleRemoveDetail(index);
																}}
															></span>
														</td>
													</tr>
												</>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	if (edit === ORDER_EDIT.CARGO && order != null) {
		if (isSaving) {
			return (
				<div className="order-block edit top-border">
					<div
						className={classNames("dsv-form full no-padding", {
							collapse: collapse,
						})}
					>
						<div className="a order-subtitle">
							<div>{t("Common.Cargo")}</div>
						</div>
						<div className="a">
							<Loader />
						</div>
					</div>
				</div>
			);
		}
		return render();
	}

	return null;
}
