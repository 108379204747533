import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOperatorOrderEdit,
	fetchUpdateOrderCargo,
} from "features/operator/operatorOrdersSlice";
import { fetchPackagings } from "features/catalogs/packagingsSlice";

import { ORDER_EDIT } from "features/constants";
import OrderCargoForm from "components/common/order/OrderCargoForm";

export default function OperatorOrderDetailsCargoEdit({ collapse }) {
	// Redux dispatch
	const dispatch = useDispatch();

	// Selectors
	const { openOrder, orderEdit, isSaving } = useSelector((state) => state.operatorOrders);
	const { packagings, isLoading } = useSelector((state) => state.packagings);

	// Effect
	useEffect(() => {
		if (packagings == null && !isLoading) {
			dispatch(fetchPackagings());
		}
	}, [packagings, dispatch, isLoading]);

	// Submit function
	const handleSaveCargoData = (data) => {

		if (openOrder != null) {
			dispatch(
				fetchUpdateOrderCargo({ orderId: openOrder.id, formData: data })
			);
		}
	};

	const handleCancelEdit = () => {
		dispatch(setOperatorOrderEdit(null));
	};

	// Render
	if (
		orderEdit != null &&
		orderEdit === ORDER_EDIT.CARGO &&
		openOrder != null && 
		packagings != null
	) {
		return (
			<OrderCargoForm
				collapse={collapse}
				order={openOrder}
				edit={orderEdit}
				packagings={packagings}
				isSaving={isSaving}
				onSave={handleSaveCargoData}
				onCancel={handleCancelEdit}
			/>
		);
	}
	return null;
}
