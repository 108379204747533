import client from "./networking";

/**
 * Post booked order new status
 * @param {*} id 
 * @param {*} public_id 
 * @param {*} status 
 * @returns Instance `AxiosResponse`.
 */
export function postChangeBookedOrderStatus(id, public_id, status, date_specified, location, notes) {
	// Build body.
	const body = JSON.stringify({
		public_id, status, date_specified, location, notes
	});
	return client.post(`/api/booked_orders/${id}/status`,body);
}
