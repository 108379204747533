import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageDropdown(props) {
	// Translation
	const { t, i18n } = useTranslation();
	// State
	const [showDropdown, setShowDropdown] = useState(false);
	// Refs
	const langRef = useRef();
	// Effect
	useEffect(() => {
		if (showDropdown) {
			langRef.current.focus();
		}
	}, [showDropdown]);
	// Handlers
	const toggleShowDropdown = () => {
		setShowDropdown(!showDropdown);
	};
	const handleMouseDown = (e) => {
		if (showDropdown) {
			e.preventDefault();
			e.stopPropagation();
		}
		return null;
	};
	const handleBlur = () => {
		if (showDropdown) {
			setShowDropdown(false);
		}
	};
	// Render
	const renderDropdown = () => {
		return (
			<div
				className={
					showDropdown ? "dropdown-container show" : "dropdown-container"
				}
				tabIndex={2}
				onBlur={handleBlur}
				ref={langRef}
			>
				<div className="dropdown-items">
					<div
						className={i18n.language === "en" ? "lang active" : "lang"}
						onClick={() => i18n.changeLanguage("en")}
					>
						{t("Languages.en")}
					</div>
					<div
						className={i18n.language === "es" ? "lang active" : "lang"}
						onClick={() => i18n.changeLanguage("es")}
					>
						{t("Languages.es")}
					</div>
				</div>
			</div>
		);
	};
	const itemClassName =
		props.className != null
			? `dropdown-button ${props.className}`
			: "dropdown-button";
	return (
		<div
			className={itemClassName}
			onClick={toggleShowDropdown}
			onMouseDown={handleMouseDown}
		>
			<span className="icon-locale"></span>
			{i18n.language ? i18n.language.toUpperCase() : ""}
			{renderDropdown()}
		</div>
	);
}
