import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DOCUMENT_TYPE } from "features/constants";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toSelector } from "utils/constantsUtils";
import Loader from "components/Loader";
import classNames from "classnames";
export default function OrderDocsForm({
	onCancel,
	onUpload,
	isLoading,
	bookedOrder,
}) {
	// Translation
	const { t } = useTranslation();
	// Forms
	const {
		control,
		setValue,
		getValues,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const {
		fields: documents,
		append,
		remove,
	} = useFieldArray({
		control,
		name: "documents",
	});
	if (bookedOrder != null) {
		watch("documents");
	}

	// State
	const [files, setFiles] = useState({});
	const fileNumberOptions = bookedOrder?.order_requests?.map((item) => {
		return {
			value: item.file_number,
			label: item.file_number,
		};
	});

	// Handlers
	const onDrop = useCallback(
		(acceptedFiles) => {
			// Do something with the files
			for (const file of acceptedFiles) {
				// Prevent duplicates uisng path as id.
				if (!files[file.path]) {
					files[file.path] = file;
					append({
						name: file.name,
						path: file.path,
						type: null,
						file_number: fileNumberOptions?.length === 1 ? fileNumberOptions[0] : null,
					});
				}
			}
			setFiles(files);
		},
		[append, files, fileNumberOptions]
	);

	const handleUpload = (data, event) => {
		event.preventDefault();

		// Call event.
		for (const document of data.documents) {
			onUpload(
				files[document.path],
				document.type.value,
				document.file_number?.value ?? undefined,
				event
			);
		}

		// Remove files.
		setFiles([]);
		// Clean form
		setValue("documents", []);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".png"],
			"image/jpeg": [".jpeg", ".jpg"],
			"application/pdf": [".pdf"],
			"application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
			"application/msword": [".doc"],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				[".docx"],
		},
	});
	const renderExtraFields = (index) => {
		const docType = getValues(`documents.${index}.type`);

		if (bookedOrder != null && docType?.value === DOCUMENT_TYPE.POD) {
			return (
				<div
					className={classNames("a field", {
						error:
							errors?.documents != null
								? errors.documents[`${index}`]?.file_number
								: null,
					})}
				>
					<label>{t("Tables.FileNumber")}</label>
					<Controller
						name={`documents.${index}.file_number`}
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { onChange, value } }) => (
							<Select
								className={classNames("dsv-select", {
									error:
										errors?.documents != null
											? errors?.documents[`${index}`]?.file_number
											: false,
								})}
								classNamePrefix="dsv-select"
								isClearable={false}
								onChange={onChange}
								value={
									value ?? fileNumberOptions.length === 1
										? fileNumberOptions[0]
										: {}
								}
								options={fileNumberOptions}
							/>
						)}
					/>
				</div>
			);
		}
	};
	// Render
	if (isLoading) return <Loader msg={t("Common.Loading")} />;
	const typeOptionsFull = toSelector(DOCUMENT_TYPE.properties);
	const typeOptions = typeOptionsFull.filter((item) => {
		if (bookedOrder == null) {
			return item.value < 100 || item.value === 199;
		}
		return item.value >= 0;
	});
	return (
		<div className="dsv-form">
			<div className="a">
				<div className="order-subtitle">{t("UploadDocuments.Title")}</div>
			</div>
			<div {...getRootProps()} className="a top-separation">
				<div className="dropzone small">
					<input {...getInputProps()} />
					<p>
						{isDragActive ? (
							<p className="center">{t("UploadDocuments.DragActive")}</p>
						) : (
							<>
								<p className="center">{t("UploadDocuments.Drag")}</p>
								<p className="center">
									<em>{t("UploadDocuments.MimeTypes")}</em>
								</p>
							</>
						)}
					</p>
				</div>
			</div>
			{documents.map((item, index) => {
				return (
					<div className="a" key={index}>
						<div className="field top-separation">
							<label>{t("Tables.FileName")}</label>
							<div className="item-name">
								<div>{item.name}</div>
								<div>
									<span
										className="icon-remove"
										onClick={() => {
											delete files[item.path];
											setFiles(files);
											remove(index);
										}}
									></span>
								</div>
							</div>
						</div>
						<div
							className={classNames("field", {
								error:
									errors?.documents != null
										? errors.documents[`${index}`]?.type
										: null,
							})}
						>
							<label>{t("Fields.DocumentType")}</label>
							<Controller
								name={`documents.${index}.type`}
								control={control}
								rules={{
									required: true,
								}}
								render={({ field: { onChange, value } }) => (
									<Select
										className={classNames("dsv-select", {
											error:
												errors?.documents != null
													? errors?.documents[`${index}`]?.type
													: false,
										})}
										classNamePrefix="dsv-select"
										isClearable={false}
										onChange={onChange}
										value={
											value?.value
												? {
														value:
															DOCUMENT_TYPE.properties[`${value.value}`].value,
														label: t(
															DOCUMENT_TYPE.properties[`${value.value}`].name
														),
												  }
												: {}
										}
										options={typeOptions}
									/>
								)}
							/>
						</div>
						{renderExtraFields(index)}
					</div>
				);
			})}

			<div className="b-1 top-separation">
				<div className="button" onClick={() => onCancel()}>
					{t("Buttons.Cancel")}
				</div>
			</div>
			<div className="b-2 top-separation">
				<div className="button main" onClick={handleSubmit(handleUpload)}>
					{t("Buttons.Upload")}
				</div>
			</div>
		</div>
	);
}
