import { WEIGHT_UNIT } from "features/constants";
import { numberFormat } from "./numberUtils";

/**
 * Converts from kg to lb
 * @param {Number} value
 * @returns Number
 */
export const convertKgToLb = (value, precision) => {
	if (precision == null) return value / 0.45359237;
	return Number((value / 0.45359237).toFixed(precision));
};


/**
 * Converts from lb to kg
 * @param {Number} value
 * @returns Number
 */
export const convertLbToKg = (value, precision) => {
	if (precision == null) return value * 0.45359237;
	return Number((value * 0.45359237).toFixed(precision));
};

/**
 * Format weight string
 * @param {Number} weight 
 * @param {int} unit 
 * @returns JSX
 */
export const formatWeight = (value, unit) => {
	if (value == null) {
		if (unit != null) {
			return `${0} ${WEIGHT_UNIT.properties[unit].code}`;
		} else {
			return `${0} ${WEIGHT_UNIT.properties[WEIGHT_UNIT.KGS].code}`;
		}
	}
	if (unit === WEIGHT_UNIT.LBS) {
		return `${numberFormat(convertKgToLb(Number(value)), 1)} ${
			WEIGHT_UNIT.properties[unit].code
		}`;
	}
	return `${numberFormat(Number(value), 1)} ${WEIGHT_UNIT.properties[unit].code}`;
};

