import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { getError } from "utils/reactHookFormUtils";

export default function TextField({
  fieldName,
  label,
  validations,
  errorMessage,
  diplayErrors = true,
  type = "text",
  placeholder,
  className,
  autoComplete = "on",
  isTableField = false,
  readOnly = false,
  onFocus,
  onBlur,
  onKeyDown,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = getError(fieldName, errors);

  const renderField = () => {
    return (
      <div
        className={classNames(["field", className].join(" "), {
          error: error?.message != null,
        })}
      >
        {(diplayErrors || label != null) && (
          <label>
            {diplayErrors
              ? (error?.message) ?? errorMessage ?? label
              : label}
          </label>
        )}

        <input
          type={type}
          placeholder={placeholder}
          {...register(fieldName, validations)}
          autoComplete={autoComplete}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  };
  if (isTableField) {
    return (
      <td key={fieldName}
        className={classNames(["field", className].join(" "), {
          error: error?.message != null,
        })}
      >
        {renderField()}
      </td>
    );
  }
  return <>{renderField()}</>;
}
