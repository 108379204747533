import React from "react";
import { useTranslation } from "react-i18next";
import ProfileTabs from "./ProfileTabs";
import { useSelector } from "react-redux";
import { CARRIER_PROFILE_TAB } from "features/constants";
import CarrierProfileRates from "./CarrierProfileRates";
import CarrierProfileZones from "./CarrierProfileZones";
import CarrierProfileLanes from "./CarrierProfileLanes";

export default function CarrierProfile() {
  // Translation
  const { t } = useTranslation();

  const { tab } = useSelector((state) => state.carrierProfile);

  const renderContent = () => {
    switch (tab) {
      case CARRIER_PROFILE_TAB.RATES:
        return <CarrierProfileRates />;
      case CARRIER_PROFILE_TAB.LANES:
        return <CarrierProfileLanes />;
      case CARRIER_PROFILE_TAB.ZONES:
        return <CarrierProfileZones />;
      default:
        return <>default</>;
    }
  };

  return (
    <div id="default">
      <div className="admin-container">
        <div className="item-list">
          <div className="content-container">
            <div className="dsv-title">
              <div>
                <h1>{t("CarrierProfile.Title")}</h1>
              </div>
            </div>
            <ProfileTabs />
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}
