import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import classNames from "classnames";

export default function CarrierTemplateForm({
	carrier_id,
	onUpload,
	onCancel,
	onUpdateProcessing,
	onLoadRates,
	onComplete,
	isProcessing,
	isUploading,
	isReadyToLoad,
	parseResponse,
	processResponse,
	completed,
}) {
	// Translation
	const { t } = useTranslation();
	// Forms
	const {
		// reset,
		setValue,
		getValues,
		handleSubmit,
	} = useForm({
		defaultValues: {
			name: null,
			path: null,
			carrier_id: carrier_id,
		},
	});

	// Effect
	useEffect(() => {
		// Fetch every 5 seconds until processing is done
		const updateProcessing = () => {
			onUpdateProcessing();
		};
		if (isProcessing) {
			const intervalId = setInterval(() => {
				updateProcessing();
			}, 1000 * 5); // in milliseconds
			return () => clearInterval(intervalId);
		}
	}, [isProcessing, onUpdateProcessing]);

	// Handlers
	const onDrop = useCallback((acceptedFiles) => {
		for (const file of acceptedFiles) {
			setValue("name", file.name);
			setValue("path", file);
		}
	}, [setValue]);

	const handleUpload = (data, event) => {
		event.preventDefault();

		onUpload(data, event);
		// reset({
		// 	name: null,
		// 	path: null,
		// 	carrier_id: carrier_id,
		// });
	};

	const handleLoadRates = (data, event) => {
		event.preventDefault();

		onLoadRates({ ...data, public_id: parseResponse?.public_id }, event);
	};
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
		},
	});
	const files = getValues();
	const submitProps =
		files.path != null ? { onClick: handleSubmit(handleUpload) } : {};
	return (
		<div className="dsv-form">
			<div className="a">
				<div className="order-subtitle">{t("UploadTemplate.Title")}</div>
			</div>
			<div className="a">
				<div className="field top-separation">
					<label>{t("Tables.FileName")}</label>
					<div className="item-name">
						<div>{files?.name ?? "No file selected"}</div>
					</div>
				</div>
			</div>
			{(isUploading || isProcessing) && (
				<div className="a">
					<Loader msg={t("Common.Processing")} />
				</div>
			)}
			{
				// Show upload dropzone
				processResponse == null && !isUploading && !isProcessing && (
					<div {...getRootProps()} className="a top-separation">
						<div className="dropzone small">
							<input {...getInputProps()} />
							<>
								{isDragActive ? (
									<p className="center">{t("UploadTemplate.DragActive")}</p>
								) : (
									<>
										<p className="center">{t("UploadTemplate.Drag")}</p>
										<p className="center">
											<em>{t("UploadTemplate.MimeTypes")}</em>
										</p>
									</>
								)}
							</>
						</div>
					</div>
				)
			}
			{
				// Show parser response
				processResponse?.job_name === "parser" && !isUploading && !isProcessing && (
					<div className="a">
						<div className="top-separation">
							<div className="order-subtitle">
								<strong>{t("UploadTemplate.ParseResponse")}</strong>
							</div>
							{processResponse.output?.ratesResult != null &&
								processResponse.output?.ratesResult.length > 0 && (
									<div className="dsv-table">
										<table
											cellSpacing="0"
											cellPadding="0"
											style={{ background: "white" }}
										>
											<thead>
												<tr>
													<th>{t("Tables.RateRow")}</th>
													<th>{t("Tables.Status")}</th>
												</tr>
											</thead>
											<tbody>
												{processResponse.output?.ratesResult?.map(
													(item, index) => {
														return (
															<tr key={index}>
																<td className="center">{item.row ?? "-"}</td>
																<td>{item.status ?? "-"}</td>
															</tr>
														);
													}
												)}
											</tbody>
										</table>
									</div>
								)}
							{
								// Show errors
								processResponse.output?.errors != null &&
									processResponse.output?.errors.length > 0 && (
										<>
											<p>{t("UploadTemplate.ErrorText1")}</p>
											<p>{t("UploadTemplate.ErrorText2")}</p>
											<div className="dsv-table">
												<table
													cellSpacing="0"
													cellPadding="0"
													style={{ background: "white" }}
												>
													<thead>
														<tr>
															<th>{t("Common.Error")}</th>
														</tr>
													</thead>
													<tbody>
														{processResponse.output?.errors?.map(
															(error, index) => {
																return (
																	<tr key={index}>
																		<td>{error}</td>
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										</>
									)
							}
						</div>
					</div>
				)
			}
			{
				// Show insert response
				processResponse?.job_name === "insert" && !isUploading && !isProcessing && (
					<div className="a">
						<div className="top-separation">
							<div className="order-subtitle">
								<strong>{t("UploadTemplate.ProcessResponse")}</strong>
							</div>
							<p>{t("UploadTemplate.LoadedText")}</p>
							<div className="dsv-table">
								<table
									cellSpacing="0"
									cellPadding="0"
									style={{ background: "white" }}
								>
									<thead>
										<tr>
											<th>{t("UploadTemplate.LoadedRates")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="right">{processResponse.output?.rates_processed ?? "-"}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)
			}
			{
				// Only show cancel button if not completed
				!completed && (
					<div className="b-21 top-separation">
						<div className="button" onClick={() => onCancel()}>
							{t("Buttons.Cancel")}
						</div>
					</div>
				)
			}

			{
				// Only show upload button if not completed and not ready to load
				!isReadyToLoad && !completed && (
					<div className="b-22 top-separation">
						<div
							className={classNames("button", {
								main: !isUploading && !isProcessing,
								disabled:
									(isUploading || isProcessing || files.path == null) &&
									!isReadyToLoad,
							})}
							{...submitProps}
						>
							{t("Buttons.Upload")}
						</div>
					</div>
				)
			}
			{
				// Only show load button if ready to load
				isReadyToLoad && (
					<div className="b-22 top-separation">
						<div
							className="button main"
							onClick={handleSubmit(handleLoadRates)}
						>
							{t("Buttons.Load")}
						</div>
					</div>
				)
			}
			{
				// Only show finish button if completed
				completed && (
					<div className="b-22 top-separation">
						<div className="button main" onClick={handleSubmit(onComplete)}>
							{t("Buttons.Finish")}
						</div>
					</div>
				)
			}
		</div>
	);
}
