import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCarrierOrderUpdateTruckerInfo,
	setCarrierOrderEdit,
} from "features/carrier/carrierOrdersSlice";
import TruckerInfoForm from "components/common/order/TruckerInfoForm";
import { BOOKED_ORDER_EDIT } from "features/constants";

const CarrierBookedOrderDetailsTruckerInfoEdit = () => {
	const dispatch = useDispatch();
	// Selectors
	const { opened, edit, isSavingTruckerInfo } = useSelector(
		(state) => state.carrierOrders
	);
	// Handlers
	const handleSaveTruckerData = (data) => {
		dispatch(
			fetchCarrierOrderUpdateTruckerInfo({
				id: opened.id,
				data: { ...data, public_id: opened.public_id },
			})
		);
	};
	const handleCancelEdit = () => {
		dispatch(setCarrierOrderEdit({ edit: null }));
	};
	// Render
	if (edit !== BOOKED_ORDER_EDIT.TRUCKER_INFO) return null;
	return (
		<TruckerInfoForm
			order={opened}
			onSave={handleSaveTruckerData}
			onCancel={handleCancelEdit}
			isSaving={isSavingTruckerInfo}
		/>
	);
};

export default CarrierBookedOrderDetailsTruckerInfoEdit;
