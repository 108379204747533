import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableListItem({ id, name, selected, onChange }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      className="sortable-list-item"
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      <button
        data-cypress="draggable-handle"
        className="drag-handle-button"
        {...listeners}
        tabIndex={0}
        ref={setActivatorNodeRef}
      >
        {" "}
        <svg viewBox="0 0 20 20" width="12">
          <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
        </svg>
      </button>

      <input
        type="checkbox"
        defaultChecked={selected}
        value={selected}
        onChange={function (e) {
          if (onChange) onChange(id);
        }}
      />

      <div>{name}</div>
    </li>
  );
}
