import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

export default function NotLogged() {
	const navigate = useNavigate();
	// Selectors
	const { authenticated, checked } = useSelector((state) => state.session);
	// Effect
	useEffect(() => {
		if (checked) {
			if (authenticated) {
				navigate("/");
			}
		}
	}, [checked, authenticated, navigate]);
	return <Outlet />;
}
