import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderBrokerForm from "components/common/order/OrderBrokerForm";
import {
	fetchUpdateOrderBroker,
	setOperatorOrderEdit,
} from "features/operator/operatorOrdersSlice";

const OperatorOrderDetailsBrokerEdit = ({ collapse }) => {
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, isSaving } = useSelector(
		(state) => state.operatorOrders
	);
	// Handlers
	const handleSaveBrokerData = (data) => {
		dispatch(
			fetchUpdateOrderBroker({ orderId: openOrder?.id, formData: data })
		);
	};
	const handleCancelEdit = () => {
		dispatch(setOperatorOrderEdit(null));
	};
	// Render
	return (
		<OrderBrokerForm
			collapse={collapse}
			order={openOrder}
			edit={orderEdit}
			isSaving={isSaving}
			onSave={handleSaveBrokerData}
			onCancel={handleCancelEdit}
		/>
	);
};

export default OperatorOrderDetailsBrokerEdit;
