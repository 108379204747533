import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import FilterContext from "./filters/FilterContext";

export default function TableHead({
  tables,
  checkbox = false,
  skipFirst = false,
}) {
  const { refs, filters, setShowFilter, activeTable, setActiveTable } =
    useContext(FilterContext);

  // Translation
  const { t } = useTranslation();
  //

  return (
    <>
      <thead>
        <tr>
          {checkbox && (
            <th key={"check_all"}>
              <input type="checkbox" id="select-all" name="select-all" />
            </th>
          )}
          {skipFirst && <th></th>}

          {/* TODO: Move to Builder isolated class */}
          {tables.map((el, index) => {
            if (!el.show) {
              return null;
            }
            const fieldClass = el.filter
              ? el.class != null
                ? { className: `${el.class} no-padding` }
                : { className: "no-padding" }
              : el.class != null
              ? { className: el.class }
              : {};
            const activeClass =
              el.filter_type === "date"
                ? filters[`${el.field}`]?.start &&
                  filters[`${el.field}`]?.end != null
                  ? "active"
                  : ""
                : filters[el.field] != null
                ? "active"
                : "";
            if (el.filter) {
              return (
                <th
                  {...fieldClass}
                  key={index}
                  ref={
                    activeTable != null && activeTable.id === el.id
                      ? refs.setReference
                      : undefined
                  }
                >
                  <div className={`table-filter ${activeClass}`}>
                    <div>{t(el.name)}</div>
                    <div
                      className="filter-button"
                      onClick={() => {
                        setShowFilter(true);
                        setActiveTable(el);
                      }}
                    >
                      <span
                        className={
                          filters[el.field] != null ||
                          filters[`${el.field}`]?.start != null
                            ? "icon-chevron expanded"
                            : "icon-chevron expanded inverted"
                        }
                      ></span>
                    </div>
                  </div>
                </th>
              );
            }
            return (
              <th {...fieldClass} key={index}>
                {t(el.name)}
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
}
