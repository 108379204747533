import React, { useState } from "react";
//
import { DndContext } from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableListItem } from "./SortableListItem";
import { t } from "i18next";

export default function SortableList({ data, onChange }) {
	// Hooks
	const [items, setItems] = useState(data);

	// Render
	return items ? (
		<DndContext
			onDragEnd={handleDragEnd}
			// modifiers={[restrictToVerticalAxis]}
		>
			<ul className="sortable-list">
				<SortableContext
					items={items?.map((e) => ({ id: e.name }))}
					strategy={verticalListSortingStrategy}
				>
					{items?.map((e) => (
						<SortableListItem
							key={e.name}
							id={e.name}
							name={t(e.name)}
							selected={e.show}
							onChange={(id) => {
								// Toggle value
								const index = items.findIndex((e) => e.name === id);
								setItems((items) => {
									items[index].show = !items[index].show;
									return items;
								});
							}}
						/>
					))}
				</SortableContext>
			</ul>
		</DndContext>
	) : (
		<></>
	);

	function handleDragEnd(event) {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.findIndex((e) => e.name === active.id);
				const newIndex = items.findIndex((e) => e.name === over.id);
				// Swap indexes form list.
				const newItems = arrayMove(items, oldIndex, newIndex);
				onChange(newItems);
				return newItems;
			});
		}
	}
}
