import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import HandleClickOutside from "hooks/handleClickOutsideHook";
import DateFilter from "./DateFilter";
import StringFilter from "./StringFilter";
import ListFilter from "./ListFilter";
import FilterContext from "./FilterContext";
import RangeFilter from "./RangeFilter";

export default function Filters({ onFiltersChanged }) {
  const {
    x,
    y,
    refs,
    strategy,
    filters,
    setFilters,
    showFilter,
    setShowFilter,
    activeTable,
    setActiveTable,
    options,
  } = useContext(FilterContext);

  // Translation
  const { t } = useTranslation();

  // Form initializer
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // Effect runs once on display.
  useEffect(() => {
    if (filters) {
      reset(filters);
    }
  }, [filters, reset]);

  const handleCloseFilter = () => {
    setShowFilter(false);
    setActiveTable(null);
  };

  const handleApply = (data, e) => {
    e.preventDefault();
    setFilters({ ...data });
    onFiltersChanged({ ...data }, e);
    handleCloseFilter();
  };

  const handleClear = (e) => {
    e.preventDefault();
    const data = { ...filters };
    if (data[activeTable.field] != null) {
      delete data[activeTable.field];
      setFilters({ ...data });
      onFiltersChanged({ ...data }, e);
    }
    handleCloseFilter();
  };

  let fieldData = <></>;
  if (activeTable == null) return fieldData;
  switch (activeTable.filter_type) {
    case "date":
      fieldData = (
        <DateFilter
          setValue={setValue}
          fieldItem={activeTable}
          register={register}
          errors={errors}
        />
      );
      break;
    case "string":
    default:
      fieldData = <StringFilter fieldItem={activeTable} register={register} />;
      break;
    case "list":
      fieldData = (
        <ListFilter
          fieldItem={activeTable}
          options={options[`${activeTable.field}`]}
          control={control}
        />
      );
      break;
    case "range":
      fieldData = (
        <RangeFilter
          fieldItem={activeTable}
          register={register}
          errors={errors}
        />
      );
      break;
  }
  if (showFilter)
    return (
      <>
        <div
          ref={refs.setFloating}
          className="table-filter-container"
          style={{
            position: strategy,
            top: y - 1 ?? 0,
            left: x ?? 0,
          }}
        >
          <HandleClickOutside onClickOutside={handleCloseFilter}>
            <div className="dsv-table-form">
              <div className="a item-name">
                <div>{t("Common.Filter", { count: 1 })}</div>
                <div onClick={handleCloseFilter}>
                  <span className="icon-edit-close"></span>
                </div>
              </div>
              {fieldData}
              <div className="b-1 top-separation">
                <div className="button" onClick={handleClear}>
                  {t("Buttons.Clear")}
                </div>
              </div>
              <div className="b-2 top-separation">
                <div
                  className="button main"
                  onClick={handleSubmit(handleApply)}
                >
                  {t("Buttons.Apply")}
                </div>
              </div>
            </div>
          </HandleClickOutside>
        </div>
      </>
    );
  else return <></>;
}
