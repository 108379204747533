import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { clearCarriersData } from "features/admin/carriersSlice";
import { clearCarrierHistoryOrdersData } from "features/carrier/carrierHistorySlice";
import { clearCarrierOffersData } from "features/carrier/carrierOffersSlice";
import { clearCarrierOrdersData } from "features/carrier/carrierOrdersSlice";
import { clearAccessorialsData, fetchAccesorials } from "features/catalogs/accesorialsSlice";
import { clearLocationTypesData, fetchLocationTypes } from "features/catalogs/locationTypeSlice";
import { clearPackagingsData, fetchPackagings } from "features/catalogs/packagingsSlice";
import { clearQualificationsData, fetchQualifications } from "features/catalogs/qualificationsSlice";
import { clearOperatorConsolidationOrders } from "features/operator/operatorOrderConsolidationSlice";
import { clearOperatorOrders } from "features/operator/operatorOrdersSlice";
import { sessionService } from "redux-react-session";
import { auth } from "services/sessionServices";
import { clearAccountData, fetchUser, fetchUserSettings } from "./accountSlice";
import {
	clearCarrierCatalogData,
	fetchCarrierCatalog,
} from "features/admin/carrierCatalogSlice";
import { clearOperatorOrdersReportData } from "features/operator/operatorOrdersReportSlice";
import { clearUsersData } from "features/admin/usersSlice";
import { fetchBranchCatalog } from "features/catalogs/branchCatalogSlice";
import { clearOperatorQuotesData } from "features/operator/operatorQuotesSlice";
import { clearCarrierTemplateData } from "features/admin/carrierTemplateSlice";

/**
 * Define the intial state.
 */
const initialState = {
	isLoading: false,
	isOpen: false,
	errorMsg: null,
};

/**
 * Async reducer function to authorize an user with credentials.
 */
export const fetchLogin = createAsyncThunk(
	"login/fetchLogin",
	async (payload, { dispatch, rejectWithValue }) => {
		try {
			const { username, password } = payload;
			const response = await auth(username, password);
			sessionService
				.saveSession({ loginData: response.data })
				.then(() => dispatch(fetchUser()))
				.catch((error) => console.log("error", error));

			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

/**
 * Create and configure slice.
 */
const login = createSlice({
	name: "login",
	initialState,
	reducers: {
		/**
		 * TODO: Doc
		 */
		setLoginErrorMessage: (state, action) => {
			state.errorMsg = action.payload.errorMsg;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLogin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchLogin.fulfilled, (state) => {
				state.isLoading = false;
				state.errorMsg = null;
			})
			.addCase(fetchLogin.rejected, (state, {payload}) => {
				state.isLoading = false;
				state.errorMsg = { error: payload.message };
				// TODO:
			});
	},
});

/** Export reducers */
export const { setLoginErrorMessage } = login.actions;

/** Export actions */
export const runUnauthorizedActions =
	(logout = false) =>
	async (dispatch) => {
		dispatch(clearOperatorOrders());
		dispatch(clearOperatorConsolidationOrders());
		dispatch(clearOperatorOrdersReportData());
		dispatch(clearOperatorQuotesData());
		dispatch(clearCarrierOrdersData());
		dispatch(clearCarrierHistoryOrdersData());
		dispatch(clearCarrierOffersData());
		dispatch(clearCarriersData());
		dispatch(clearCarrierCatalogData());
		dispatch(clearCarrierCatalogData());
		dispatch(clearCarrierTemplateData());
		dispatch(clearAccountData());
		dispatch(clearAccessorialsData());
		dispatch(clearLocationTypesData());
		dispatch(clearPackagingsData());
		dispatch(clearQualificationsData());
		dispatch(clearUsersData());
	};

export const runOperatorAuthenticatedActions = () => async (dispatch) => {
	dispatch(fetchCarrierCatalog());
	dispatch(fetchQualifications());
	dispatch(fetchPackagings());
	dispatch(fetchAccesorials());
	dispatch(fetchLocationTypes());
	dispatch(fetchUserSettings());
	dispatch(fetchBranchCatalog({}));
};
export const runCarrierAuthenticatedActions = () => async (dispatch) => {
	dispatch(fetchUserSettings());
	dispatch(fetchPackagings());
};

export const runAdminAuthenticatedActions = () => async (dispatch) => {
	dispatch(fetchCarrierCatalog());
	dispatch(fetchQualifications());
	dispatch(fetchPackagings());
	dispatch(fetchAccesorials());
	dispatch(fetchLocationTypes());
};

/** Export reducer */
export default login.reducer;
