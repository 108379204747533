import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_EDIT } from "features/constants";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import { isOrderReadyToBook } from "utils/orderUtils";
import OrderAddress from "components/common/order/OrderAddress";
import { setOperatorConsolidationTabEdit } from "features/operator/operatorOrderConsolidationSlice";

export default function OperatorConsolidateDetailsAddress() {
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, isLoadingOpen } = useSelector(
		(state) => state.operatorOrders
	);
	const { isLoading, selectedOrder, edit, activeTab } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	// Handlers
	const handleEditClick = () => {
		if (activeTab === 0) {
			dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.ADDRESS }));
		}
		if (activeTab > 0) {
			dispatch(setOperatorConsolidationTabEdit({ edit: ORDER_EDIT.ADDRESS }));
		}
	};
	// Render
	if (activeTab === 0) {
		const validateReady =
			openOrder?.booked_orders?.length === 0
				? isOrderReadyToBook(openOrder)
				: {};
		const errors = validateReady.address;
		return (
			<OrderAddress
				order={openOrder}
				edit={orderEdit}
				isLoading={isLoadingOpen}
				onEdit={handleEditClick}
				errors={errors}
			/>
		);
	}
	if (activeTab > 0) {
		const errors = {};
		return (
			<OrderAddress
				order={selectedOrder}
				edit={edit}
				isLoading={isLoading}
				onEdit={handleEditClick}
				errors={errors}
			/>
		);
	}
}
