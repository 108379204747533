import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOperatorOrderEdit,
	fetchUpdateOrderHeader,
} from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";
import OrderHeaderForm from "components/common/order/OrderHeaderForm";

export default function OperatorOrderDetailsHeaderEdit({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	// Redux dispatch
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit } = useSelector((state) => state.operatorOrders);
	const branches = useSelector((state) => state.branchCatalog.list);

	// Submit function
	const handleSaveHeaderData = (data) => {
		// Call Service.
		if (openOrder != null) {
			let formData = {...data};
			if (data.branch_id) {
				formData.branch_id = data.branch_id.value;
			}
			dispatch(
				fetchUpdateOrderHeader({ orderId: openOrder.id, formData })
			);
		}
	};

	const handleCloseClick = () => {
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};

	const handleCancelEdit = () => {
		// Udate redux's state.
		dispatch(setOperatorOrderEdit(null));
	};

	if (
		orderEdit != null &&
		orderEdit === ORDER_EDIT.HEADER &&
		openOrder != null
	) {
		return (
			<OrderHeaderForm
				order={openOrder}
				edit={orderEdit}
				onSave={handleSaveHeaderData}
				onCancel={handleCancelEdit}
				onClose={handleCloseClick}
				onCollapse={handleCollapseClick}
				onExpand={handleExpandClick}
				collapse={collapse}
				consolidate={false}
				branches={branches}
			/>
		);
	}

	return null;
}
