import { CURRENCY } from "features/constants";
import { t } from "i18next";

/**
 * Gets selector-value from given set and key.
 */
export const toSelectorValue = (properties, key, useCode = false, translate = false) => {
  // Try get element by key.
  let object = properties[key];

	if (translate) {
		return object
    ? {
        value: object.value,
        label: useCode ? t(object.code) : t(object.name),
      }
    : null;
	}
  return object
    ? {
        value: object.value,
        label: useCode ? object.code : object.name,
      }
    : null;
};

/**
 * Fuction that converts properties to list of `value` & `label` pairs to use in Selectors.
 * @param {*} properties
 * @returns Array with {value:Any, label:String} schema.
 */
export const toSelector = (properties, useCode = false, translate = true, ignore = []) => {
  let keys = Object.values(properties);
  let list = [];
  keys.forEach(function myFunction(e) {
		if (ignore.includes(e.value)) return;
		const show = e.show == null ? true : e.show;
		if (show) {
			if(translate) {
				list.push({ value: e.value, label: useCode ? t(e.code) : t(e.name) });
			}
			else {
				list.push({ value: e.value, label: useCode ? e.code : e.name });
			}
		}
  });
  return list;
};

/**
 * Convert a list into Select label/value list
 * @param {*} list
 * @returns Array with {value:Any, label:String} schema.
 */
export const listToSelector = (list) => {
  let result = [];
  list.forEach((el) => {
    result.push({ value: el.id, label: el.name });
  });
  return result;
};

/**
 * Convert a selector list into a list of values
 * @param {*} selector 
 * @returns array of values
 */
export const selectorToList = (selector) => {
	if (selector == null) return undefined;
	if (selector.length === 0) return undefined;
	const result = selector?.map((el) => {
		return el.value;
	});
	return result;
};

/**
 * Get currency value from country code
 * @param {*} countryCode 
 * @returns int
 */
export const getCurrencyByCountryCode = (countryCode) => {
	const keys = Object.values(CURRENCY.properties);
	const currency = keys.find((el) => el.countryDefault === countryCode);
	return currency.value;
};

/**
 * Get currency option from country code
 * @param {*} countryCode 
 * @returns Select option {label, value}
 */
export const getCurrencyOptionByCountryCode = (countryCode) => {
	const keys = Object.values(CURRENCY.properties);
	const currency = keys.find((el) => el.countryDefault === countryCode);
	if (currency != null){
		return {label: currency.code, value: currency.value};
	}
	return null;
}