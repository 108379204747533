import React from "react";

export default function Footer(props) {
	// Variables
	const year = String(new Date().getFullYear());
	// Render
	return (
		<footer {...props}>
			©{year === "2022" ? year : "2022-" + year} DSV Road
		</footer>
	);
}
