import { useState } from "react";
import { useFloating, autoUpdate, offset, shift } from "@floating-ui/react-dom";

/**
 *
 * @returns
 */
export const useFilterContext = (settings) => {
  
  const [filters, setFilters] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [options, setOptions] = useState(settings?.options ?? {});

  const { x, y, refs, strategy } = useFloating({
    placement: "bottom-start",
    open: showFilter,
    onOpenChange: setShowFilter,
    middleware: [offset(0), shift()],
    whileElementsMounted: autoUpdate,
  });

  return {
    filterContext: {
      x,
      y,
      refs,
      strategy,
      filters,
      setFilters,
      showFilter,
      setShowFilter,
      activeTable,
      setActiveTable,
      options,
      setOptions,
    },
  };
};
