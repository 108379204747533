import React from "react";
import OperatorConsolidateHeader from "./OperatorConsolidateDetailsHeader";
import OperatorConsolidateAddress from "./OperatorConsolidateDetailsAddress";
import OperatorConsolidateAddressEdit from "./OperatorConsolidateDetailsAddressEdit";
import OperatorConsolidateHeaderEdit from "./OperatorConsolidateDetailsHeaderEdit";
import OperatorConsolidateCargo from "./OperatorConsolidateDetailsCargo";
import OperatorConsolidateCargoEdit from "./OperatorConsolidateDetailsCargoEdit";

export default function OperatorConsolidateDetails({
	onClose,
	onCollapse,
	onExpand,
	collapse,
	onConsolidate,
}) {
	const itemProps = {
		onClose: onClose,
		onCollapse: onCollapse,
		onExpand: onExpand,
		collapse: collapse,
		onConsolidate: onConsolidate,
	};
	return (
		<div className="order-info">
			<OperatorConsolidateHeader {...itemProps} />
			<OperatorConsolidateHeaderEdit {...itemProps} />
			<OperatorConsolidateAddress />
			<OperatorConsolidateAddressEdit />
			<OperatorConsolidateCargo />
			<OperatorConsolidateCargoEdit collapse={collapse} />
		</div>
	);
}
