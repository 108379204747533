import Loader from "components/Loader";
import { CARRIER_TAB } from "features/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchAddCarrierLane,
	fetchCarrierZonesCatalog,
	fetchSaveCarrierLane,
	setCarrierLane,
	setCarrierLaneAddEdit,
} from "features/admin/carriersSlice";
import { numberFormat } from "utils/numberUtils";
import { fetchCarrierLanes } from "features/admin/carriersSlice";
import Pagination from "components/common/pagination/Pagination";
import CarrierLanesAdd from "components/common/carrier/CarrierLanesAdd";

export default function CarrierDetailsLanes() {
	const dispatch = useDispatch();
	// Translate
	const { t } = useTranslation();
	// Selectors
	const {
		tab,
		open,
		lanes,
		isLoadingLanes,
		lane,
		laneAdd,
		laneEdit,
		zonesCatalog,
		isLoadingZonesCatalog,
		isSavingLane,
	} = useSelector((state) => state.carriers);

	// State
	const [activeRow, setActiveRow] = useState(null);

	useEffect(() => {
		if (zonesCatalog === null && !isLoadingZonesCatalog) {
			dispatch(fetchCarrierZonesCatalog({ carrierId: open.id }));
		}
	}, [dispatch, isLoadingZonesCatalog, zonesCatalog, open.id]);

	const handleLaneAddClick = () => {
		setActiveRow(null);
		dispatch(setCarrierLane());
		dispatch(setCarrierLaneAddEdit({ laneAdd: true }));
	};
	// Handlers
	const handleLaneClick = (lane, index) => {
		dispatch(setCarrierLane({ lane: lane }));
		dispatch(setCarrierLaneAddEdit({ laneEdit: true }));
		setActiveRow(index);
	};
	const handlePageClick = (page) => {
		dispatch(fetchCarrierLanes({ carrierId: open.id, page }));
	};
	// Render
	if (tab !== CARRIER_TAB.LANES) return null;
	if (isLoadingLanes) {
		return <Loader msg={t("Common.Loading")} />;
	}

	// if (laneAdd) {
	// 	return <CarrierDetailsLanesAdd carrier={open} />;
	// }
	// if (laneEdit) {
	// 	return <CarrierDetailsLanesAdd carrier={open} lane={lane} />;
	// }

	if (laneAdd || laneEdit) {
		return (
			<CarrierLanesAdd
				onSave={(data) => {
					dispatch(
						fetchSaveCarrierLane({
							carrierId: open.id,
							laneId: lane.id,
							data: data,
						})
					);
				}}
				onAdd={(data) => {
					dispatch(fetchAddCarrierLane({ carrierId: open.id, data: data }));
				}}
				onClose={() => {
					dispatch(setCarrierLaneAddEdit());
				}}
				lane={laneEdit ? lane : null}
				zonesCatalog={zonesCatalog}
				isSavingLane={isSavingLane}
			/>
		);
	}

	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={lanes?.current_page ?? 1}
				last={lanes?.last_page ?? 1}
				onRefresh={() => {
					handlePageClick(lanes?.current_page ?? 1);
				}}
			/>
		);
	};

	return (
		<div className="tab-content details">
			<div className="dsv-table">
				<div className="details-subtitle">
					<div>{t("AdminCarriers.Lanes")}</div>
					<div onClick={handleLaneAddClick}>
						<span className="icon-add"></span>
					</div>
				</div>
				{renderPagination()}
				<table cellSpacing="0" cellPadding="0">
					<thead>
						<tr>
							<th>{t("Common.Name")}</th>
							<th>{t("Fields.WMRate")}</th>
						</tr>
					</thead>
					<tbody>
						{lanes?.data?.map((el, index) => {
							const activeProps =
								activeRow != null
									? index === activeRow
										? { className: "active" }
										: {}
									: {};
							return (
								<tr
									{...activeProps}
									key={index}
									onClick={() => handleLaneClick(el, index)}
								>
									<td>{el.name}</td>
									<td className="right">{`$${numberFormat(
										el.w_m_rate,
										2
									)}`}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{renderPagination()}
		</div>
	);
}
