import React from "react";
import EmailList from "../emailList/EmailList";

const OrderNotifyParties = ({ order, edit, onEdit, isLoading }) => {
	if (order == null) return null;
	if (isLoading === true) return null;
	return (
		<div className="order-block bottom-border">
			<EmailList
				edit={edit}
				onEdit={onEdit}
				emailList={order.email_send_list ?? []}
				title="Common.NotifyParties"
				emptyKey="Common.NotifyPartiesEmpty"
			/>
		</div>
	);
};

export default OrderNotifyParties;
