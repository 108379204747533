import client from "./networking";

/**
 * Gets access token.
 * @param {*} username Username
 * @param {*} password Password
 * @returns Instance `AxiosResponse`
 */
export const auth = (username, password) => {
  // Build body.
  const body = JSON.stringify({
    username,
    password,
    grant_type: "password",
  });

  // Make request and return.
  return client.post("/api/auth/login", body);
}

/**
 *  Gets user data for the authenticated user.
 * @returns Instance `AxiosResponse`
 */
export const getUser = () => {
  // Make request and return.
  return client.get("/api/user");
}

/**
 * Gets the user's settings.
 * @param {*} userId 
 * @returns 
 */
export const getUserSettings = () => {
	return client.get(`/api/user/display_settings`);
}

/**
 * Sets the user's settings.
 * @param {*} data 
 * @returns 
 */
export const putUserSettings = (data) => {
	return client.put(`/api/user/display_settings`, data);
};

/**
 * Change the user's password.
 * @param {*} data 
 * @returns 
 */
export const postChangePassword = (data) => {
	return client.post(`/api/user/change_password`, data);
};