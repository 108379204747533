import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CARRIER_TAB,
  CURRENCY,
  LANGUAGES,
  WEIGHT_UNIT,
} from "features/constants";
import {
  fetchCarrierStatusChange,
  setCarrierEdit,
} from "features/admin/carriersSlice";
import CarrierEdit from "./CarrierEdit";
import CarrierStatusForm from "./CarrierStatusForm";
import { formatWeight } from "utils/weightUtils";

export default function CarrierDetailsMain() {
  const dispatch = useDispatch();
  // Translation
  const { t } = useTranslation();
  // Selectors
  const { tab, open, isOpening, edit, isChangingStatus } = useSelector(
    (state) => state.carriers
  );
  if (tab !== CARRIER_TAB.DETAILS) return null;
  // Handlers
  const handleEditClick = () => {
    dispatch(setCarrierEdit({ edit: true }));
  };
  // Handlers
  const handleChangeStatus = (data) => {
    dispatch(fetchCarrierStatusChange({ carrierId: open.id, data }));
  };
  // Render
  const renderEdit = () => {
    return (
      <div className="tab-content details">
        <div className="details-block">
          <CarrierEdit
            onCancel={() => {
              dispatch(setCarrierEdit({ edit: false }));
            }}
            carrier={open}
          />
        </div>
      </div>
    );
  };
  if (!isOpening && open != null && edit) {
    return renderEdit();
  }
  return (
    <div className="tab-content details">
      <div className="details-subtitle">
        <div>{t("AdminCarriers.Details")}</div>
        <div>
          <span onClick={handleEditClick} className="icon-edit"></span>
        </div>
      </div>
      <CarrierStatusForm
        active={open.active}
        isSaving={isChangingStatus}
        onSave={handleChangeStatus}
      />
      <div className="flex-table">
        <div>
          <div>
            <div>{t("Common.Name")}</div>
            <div>{open.name}</div>
          </div>
          <div>
            <div>{t("Fields.ShortName")}</div>
            <div>{open.short_name}</div>
          </div>
          <div>
            <div>{t("Fields.MdgNumber")}</div>
            <div>
              {open.mdg_number != null && open.mdg_number !== ""
                ? open.mdg_number
                : "n/a"}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>{t("Fields.DimensionalFactor")}</div>
            <div>{open.dimensional_factor}</div>
          </div>
          <div>
            <div>{t("WeightUnits.Title")}</div>
            <div>{WEIGHT_UNIT.properties[open.weight_unit].code}</div>
          </div>
          <div>
            <div>{t("Fields.Currency")}</div>
            <div>{t(CURRENCY.properties[open.currency].name)}</div>
          </div>
        </div>
      </div>
      <div className="details-subtitle">
        <div>{t("Fields.EmailList")}</div>
      </div>

      <div className="flex-table">
        {open.email_send_list?.map((el, index) => {
          return (
            <div key={index}>
              <div>
                <div>{t("Common.Email")}</div>
                <div>{el.email}</div>
              </div>
              <div>
                <div>{t("Fields.FullName")}</div>
                <div>{el.name}</div>
              </div>
              <div>
                <div>{t("Fields.Language")}</div>
                <div>{t(LANGUAGES.properties[el.lang].name)}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="details-subtitle">
        <div>{t("AdminCarriers.DimFactorBrackets")}</div>
      </div>
      <div className="flex-table">
        {open.dim_factor_weight_brackets?.map((el, index) => {
          return (
            <div key={index}>
              <div>
                <div>{t("Fields.FromWeight")}</div>
                <div>{formatWeight(el.weight_limit, open.weight_unit)}</div>
              </div>
              <div>
                <div>{t("Fields.DimensionalFactor")}</div>
                <div>{el.dim_factor}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex-table">
        <div>
          <div>
            <div>{t("Fields.Disclaimer")}</div>
            <div>{open.disclaimer}</div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
