import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toZoneExclusionForm } from "utils/carrierUtils";
import classNames from "classnames";
import { zipRegEx } from "utils/regExs";
import Loader from "components/Loader";
import Select from "react-select";
import { fetchCities, fetchStates } from "features/catalogs/postalCodesSlice";
import { getPostalCode } from "services/postalCodeServices";
import { processZoneExclusionsChanges } from "utils/zoneHelpers";
import { toSelector } from "utils/constantsUtils";
import { ZONE_EXCLUSION_TYPE } from "features/constants";

export default function CarrierZoneExclusionsEdit({
	zone,
	isSaving,
	saveErrors,
	isOpeningZone,
	onSave,
	onClose,
	onClearErrors,
}) {
	// Dispatch
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();

	// Form initializer
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	// Selectors
	const { isLoadingStates, states, isLoadingCities, cities } = useSelector(
		(state) => state.postalCodes
	);

	// zone details array
	const {
		fields: zone_details,
		append,
		remove,
	} = useFieldArray({
		control,
		name: "zone_details",
		rules: {
			validate: function (value, formValues) {
				const list = getValues(`zone_details`);
				return list?.length > 0;
			},
		},
	});

	watch("zone_details");

	// Effect
	useEffect(() => {
		if (states == null && !isLoadingStates) {
			dispatch(fetchStates());
		}
		if (cities == null && !isLoadingCities) {
			dispatch(fetchCities());
		}
		if (
			states != null &&
			!isLoadingStates &&
			cities != null &&
			!isLoadingCities
		) {
			onClearErrors();
			reset(toZoneExclusionForm(zone, states, cities));
		}
	}, [
		zone,
		reset,
		setValue,
		states,
		isLoadingStates,
		cities,
		isLoadingCities,
		dispatch,
		onClearErrors,
	]);

	// Handlers
	const handleSave = (data, event) => {
		// TODO: Validate form & fetch to api with dispatch
		event.preventDefault();

		if (data.zone_details != null) {
			var zone_details = data.zone_details.map((detail) => {
				return detail.postal_data?.zipcode != null &&
					detail.postal_data?.zipcode !== ""
					? { zipcode: detail.postal_data?.zipcode }
					: {
							state_number: Number(detail.state.value),
							city_number: Number(detail.city.value),
					  };
			});

			// Process data changes.
			const old_zone_details = zone.zone_details.map((x) =>
				x.zipcode != null
					? { id: x.id, zipcode: x.zipcode }
					: {
							id: x.id,
							state_number: x.state_number,
							city_number: x.city_number,
					  }
			);
			const update = processZoneExclusionsChanges(
				old_zone_details,
				zone_details
			);

			// const clean = removeEmptyProps(update);

			onSave({
				zone_details: update,
			});
		}
	};
	const handleClose = () => {
		onClose();
	};

	if (isOpeningZone) {
		return (
			<div>
				<Loader msg={t("Common.Loading")} />
			</div>
		);
	}
	if (isSaving) {
		return (
			<div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}

	return (
		<div className="tab-content details">
			<div className="details-block">
				<div className="dsv-form">
					<div className="a details-subtitle">
						<div>{t("AdminCarriers.ExclusionList")}</div>
						<div>
							<span
								className="icon-add"
								onClick={() =>
									append({
										type: {
											value: 2,
											label: t("Fields.PostalCode"),
										},
										state: "",
										city: "",
										postal_data: null,
									})
								}
							></span>
						</div>
					</div>
					<div className="a">
						<div>{t("AdminCarriers.ExclusionDescription")}</div>
					</div>
					<div className="a field top-separation">
						<div className="field-table zone-edit">
							<div>
								<table cellPadding="0" cellSpacing="0">
									<thead>
										<tr>
											<th>{t("Common.Type")}</th>
											<th>{t("Fields.StatePostalCode")}</th>
											<th>{t("Fields.City")}</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{zone_details.map((item, index) => {
											return (
												<tr>
													<td>
														<Controller
															name={`zone_details.${index}.type`}
															control={control}
															rules={{
																required: true,
															}}
															render={({ field: { onChange, value } }) => (
																<Select
																	className="dsv-select no-border"
																	classNamePrefix="dsv-select"
																	isClearable={false}
																	onChange={(e) => {
																		if (value.value === 0) {
																			setValue(
																				`zone_details.${index}.city`,
																				""
																			);
																			setValue(
																				`zone_details.${index}.state`,
																				""
																			);
																		} else {
																			setValue(
																				`zone_details.${index}.postal_data`,
																				null
																			);
																		}

																		onChange(e);
																	}}
																	value={
																		value?.value
																			? {
																					value:
																						ZONE_EXCLUSION_TYPE.properties[
																							`${value.value}`
																						].value,
																					label: t(
																						ZONE_EXCLUSION_TYPE.properties[
																							`${value.value}`
																						].name
																					),
																			  }
																			: {}
																	}
																	options={toSelector(
																		ZONE_EXCLUSION_TYPE.properties
																	)}

																	// value={value ?? {}}
																	// options={[
																	//   {
																	//     value: 0,
																	//     label: "Postal Code",
																	//   },
																	//   {
																	//     value: 1,
																	//     label: "City",
																	//   },
																	// ]}
																/>
															)}
														/>
													</td>
													{getValues(`zone_details.${index}.type`)?.value ===
														ZONE_EXCLUSION_TYPE.POSTAL_CODE && (
														<td
															className={classNames({
																error:
																	errors.zone_details?.[`${index}`]?.postal_data
																		?.zipcode,
															})}
														>
															<input
																type="text"
																placeholder={t("Postal Code")}
																// TODO:
																{...register(
																	`zone_details.${index}.postal_data.zipcode`,
																	{
																		required: true,
																		pattern: zipRegEx,
																		onChange: (e) => {
																			setValue(
																				`zone_details.${index}.postal_data.name`,
																				null
																			);
																			if (zipRegEx.test(e.target.value)) {
																				// fetch
																				getPostalCode(e.target.value).then(
																					function (response) {
																						if (response.status === 200) {
																							setValue(
																								`zone_details.${index}.postal_data.name`,
																								`${response?.data?.zipcode} ${response?.data?.state},  ${response?.data?.city}`
																							);
																						}
																					}
																				);
																			} else {
																				setValue(
																					`zone_details.${index}.postal_data.details`,
																					null
																				);
																			}
																		},
																	}
																)}
															/>
														</td>
													)}

													{getValues(`zone_details.${index}.type`)?.value ===
														ZONE_EXCLUSION_TYPE.CITY && (
														<td
															className={classNames({
																error:
																	errors?.zone_details != null
																		? errors?.zone_details[`${index}`]?.state
																		: false,
															})}
														>
															<Controller
																name={`zone_details.${index}.state`}
																control={control}
																rules={{
																	required: true,
																}}
																render={({ field: { onChange, value } }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		onChange={onChange}
																		placeholder={t("Common.Select")}
																		value={value ?? {}}
																		options={
																			states?.map((item, index) => {
																				return {
																					value: item?.state_number,
																					label: item?.name ?? "",
																				};
																			}) ?? []
																		}
																	/>
																)}
															/>
														</td>
													)}

													{getValues(`zone_details.${index}.type`)?.value ===
														ZONE_EXCLUSION_TYPE.POSTAL_CODE && (
														<td
															className={classNames("no-field", {
																error:
																	errors.zone_details?.[`${index}`]?.postal_data
																		?.name,
															})}
														>
															<Controller
																render={({ field: { value } }) =>
																	`${value ?? ""}`
																}
																control={control}
																name={`zone_details.${index}.postal_data.name`}
																rules={{
																	required: true,
																}}
																defaultValue={""}
															/>
														</td>
													)}

													{getValues(`zone_details.${index}.type`)?.value ===
														ZONE_EXCLUSION_TYPE.CITY && (
														<td
															className={classNames({
																error:
																	errors?.zone_details != null
																		? errors?.zone_details[`${index}`]?.city !=
																		  null
																		: false,
															})}
														>
															<Controller
																name={`zone_details.${index}.city`}
																control={control}
																rules={{
																	required: true,
																}}
																render={({ field: { onChange, value } }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		onChange={onChange}
																		placeholder={t("Common.Select")}
																		value={value ?? {}}
																		options={
																			getValues(
																				`zone_details.${index}.state`
																			) !== ""
																				? cities[
																						`${
																							getValues(
																								`zone_details.${index}.state`
																							).value
																						}`
																				  ]?.map((item, index) => {
																						return {
																							value: item?.city_number,
																							label: item?.name ?? "",
																						};
																				  })
																				: []
																		}
																	/>
																)}
															/>
														</td>
													)}

													<td>
														<span
															className="icon-remove"
															onClick={() => remove(index)}
														></span>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					{errors.zone_details ? (
						<>
							<div className="error-list">
								<label>{t("FieldErrors.Empty")}</label>
							</div>
						</>
					) : (
						<></>
					)}
					{saveErrors ?? (
						<>
							<div className="error-list">
								<label>{saveErrors}</label>
							</div>
						</>
					)}
					<div className="b-11 top-separation">
						<label>&nbsp;</label>
						<div className="button" onClick={handleClose}>
							{t("Buttons.Cancel")}
						</div>
					</div>
					<div className="b-12 top-separation">
						<label>&nbsp;</label>
						<div className="button main" onClick={handleSubmit(handleSave)}>
							{t("Buttons.Save")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
