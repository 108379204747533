import { TEMPERATURE_UNIT } from "features/constants";
import { numberFormat } from "./numberUtils";

/**
 * Convert from C to F
 * @param {number} value
 * @param {int} precision 
 * @returns number
 */
export const convertTempCToF = (value, precision) => {
	if (precision == null) return value * 1.8 + 32;
	return Number((value * 1.8 + 32).toFixed(precision));
};

/**
 * Convert from F to C
 * @param {number} value 
 * @param {int} precision 
 * @returns number
 */
export const convertTempFToC = (value, precision) => {
	if (precision == null) return (value - 32) / 1.8;
	return Number(((value - 32) / 1.8).toFixed(precision));
};

/**
 * Format temperature string
 * @param {Number} weight 
 * @param {int} unit 
 * @returns JSX
 */
export const formatTemperature = (value, unit) => {
	if (value == null) return <>&nbsp;</>;
	if (unit === TEMPERATURE_UNIT.F) {
		return `${numberFormat(convertTempCToF(Number(value)), 1)} ${
			TEMPERATURE_UNIT.properties[unit].code
		}`;
	}
	return `${numberFormat(Number(value), 1)} ${TEMPERATURE_UNIT.properties[unit].code}`;
};
