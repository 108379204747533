import Loader from "components/Loader";
import {
	fetchCarrier,
	fetchCarriers,
	setCarrierEdit,
	setCarrierLanes,
	setCarrierRates,
	setCarrierTab,
	setCarrierZones,
	setOpenCarrier,
	setCarriersShowAdd,
	setCarrierZoneAddEdit,
	setCarrierLaneAddEdit,
	setCarrierRateAddEdit,
	setCarrierFuelEdit,
	setCarriersShowDetails,
} from "features/admin/carriersSlice";
import { CARRIER_TAB } from "features/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils/dateUtils";
import CarrierAdd from "./CarrierAdd";
import CarrierDetails from "./CarrierDetails";
import Pagination from "components/common/pagination/Pagination";
import admin_default from "settings/admin_default.json";
import TableHead from "components/common/tables/TableHead";
import FilterContext from "components/common/tables/filters/FilterContext";
import Filters from "components/common/tables/filters/Filters";
import { useFilterContext } from "components/common/tables/filters/FilterContextProvider";
import { filterTransformToData } from "utils/filtersUtils";
import { clearCarrierTemplateData } from "features/admin/carrierTemplateSlice";

const tables = admin_default.settings.carriers;

export default function ManageCarriers() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { list, isLoading, showAdd, showDetails } = useSelector(
		(state) => state.carriers
	);
	const { filterContext } = useFilterContext();

	const onFiltersChanged = (data, e) => {
		e.preventDefault();
		dispatch(
			fetchCarriers({
				page: 1,
				filters: filterTransformToData(data, tables),
			})
		);
	};

	// State
	const [activeRow, setActiveRow] = useState(null);
	// Effect
	useEffect(() => {
		if (list == null) {
			dispatch(fetchCarriers({ page: 1 }));
		}
	}, [dispatch, list]);
	// Handlers
	const handleCarrierClick = (carrier, index) => {
		dispatch(setCarrierEdit());
		dispatch(setCarrierTab({ tab: CARRIER_TAB.DETAILS }));
		dispatch(setCarrierRates({ rates: null }));
		dispatch(setCarrierRateAddEdit());
		dispatch(setCarrierZones({ zones: null }));
		dispatch(setCarrierZoneAddEdit());
		dispatch(setCarrierLanes({ lanes: null }));
		dispatch(setCarrierLaneAddEdit());
		dispatch(setCarrierFuelEdit());
		dispatch(fetchCarrier({ carrierId: carrier.id }));
		dispatch(setCarriersShowDetails(true));
		setActiveRow(index);
	};
	const handleCloseClick = () => {
		dispatch(setCarriersShowDetails(false));
		setActiveRow(null);
		dispatch(setCarrierEdit({ edit: false }));
		dispatch(setOpenCarrier({ open: null }));
		dispatch(clearCarrierTemplateData());
	};
	const handleAddOpen = () => {
		dispatch(setCarriersShowDetails(true));
		dispatch(setCarriersShowAdd(true));
		dispatch(setCarrierEdit({ edit: false }));
	};
	const handleAddClose = () => {
		dispatch(setCarriersShowDetails(false));
		dispatch(setCarriersShowAdd(false));
		dispatch(setCarrierEdit({ edit: false }));
	};
	const handlePageClick = (page) => {
		dispatch(
			fetchCarriers({
				page,
				filters: filterTransformToData(filterContext.filters, tables),
			})
		);
	};
	// Render
	const getCssClass = () => {
		if (showDetails) {
			return "admin-container show-details wide";
		}
		return "admin-container";
	};
	const renderLoader = () => {
		if (isLoading) {
			return <Loader msg={t("Common.Loading")} />;
		}
	};
	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={list?.current_page ?? 1}
				last={list?.last_page ?? 1}
				onRefresh={() => {
					handlePageClick(list?.current_page ?? 1);
				}}
			/>
		);
	};
	const renderList = () => {
		if (!isLoading) {
			return (
				<>
					{renderPagination()}
					<div className="dsv-table">
						<FilterContext.Provider value={filterContext}>
							<Filters onFiltersChanged={onFiltersChanged} />

							<table cellSpacing="0" cellPadding="0">
								<TableHead tables={tables} />
								<tbody>
									{list?.data?.map((el, index) => {
										const activeProps =
											activeRow != null
												? index === activeRow
													? { className: "active" }
													: {}
												: {};
										return (
											<tr
												{...activeProps}
												key={index}
												onClick={() => handleCarrierClick(el, index)}
											>
												<td>{el.name}</td>
												<td>{el.short_name}</td>
												<td>{el.company_id != null ? "Yes" : <>&nbsp;</>}</td>
												<td>{el.mdg_number}</td>
												<td>{el.dimensional_factor}</td>
												<td>
													{formatDate(el.created_at, t("Dates.DateTime"))}
												</td>
												<td>
													{formatDate(el.updated_at, t("Dates.DateTime"))}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</FilterContext.Provider>
					</div>
					{renderPagination()}
				</>
			);
		}
		return null;
	};
	const renderNew = () => {
		if (showDetails && showAdd) {
			return <CarrierAdd onClose={handleAddClose} />;
		}
	};
	const renderNewButton = () => {
		if (!showDetails && !showAdd) {
			return (
				<div onClick={() => handleAddOpen()}>
					<span className="icon-new"></span>
				</div>
			);
		}
	};

	const cssClass = getCssClass();
	return (
		<div id="default">
			<div className={cssClass}>
				<div className="item-list">
					<div className="content-container">
						<div className="dsv-title">
							<div>
								<h1>{t("AdminCarriers.Title")}</h1>
							</div>
							{renderNewButton()}
						</div>
						<div className="tab-content">
							{renderList()}
							{renderLoader()}
						</div>
					</div>
				</div>
				<CarrierDetails onClick={handleCloseClick} />
				{renderNew()}
			</div>
		</div>
	);
}
