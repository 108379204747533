import { ADDRESS_TYPE } from "features/constants";
import { setOperatorConsolidationOrders, setOperatorConsolidationTabs } from "features/operator/operatorOrderConsolidationSlice";
import { fetchOperatorOrderCart } from "features/operator/operatorOrdersSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAddress } from "utils/orderUtils";

export default function OperatorOrderDetailsConsolidation() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { openOrder, consolidate } = useSelector((state) => state.operatorOrders);
	const { list, orders, tabs } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	// Handlers
	const handleRemoveClick = (id) => {
		const includedOrders = orders.filter((el) => el !== id);
		const includedTabs = tabs.filter((el) => el.id !== id);
		dispatch(setOperatorConsolidationOrders({ orders: includedOrders }));
		dispatch(
			fetchOperatorOrderCart({
				primary: openOrder.id,
				ids: [openOrder.id, ...includedOrders],
			})
		);
		dispatch(setOperatorConsolidationTabs({ tabs: includedTabs }));
	};
	// Render
	if (!consolidate) {
		return null;
	}
	const origin = getAddress(openOrder, ADDRESS_TYPE.ORIGIN);
	const destination = getAddress(openOrder, ADDRESS_TYPE.DESTINATION);
	return (
		<div className="order-consolidation">
			<div className="order-actions-title">
				<div>
					<div>&nbsp;</div>
					<div className="action-title">{t("OperatorOrders.OrderConsolidation")}</div>
				</div>
			</div>
			<div className="order-block">
				<div className="order-subtitle">{t("OperatorOrders.OrdersIncluded")}</div>
				<div>
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Tables.FileNumber")}</th>
									<th>{t("Tables.OriginName")}</th>
									<th>{t("Tables.DestinationName")}</th>
									<th className="center">&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								<tr key={"master"}>
									<td>{openOrder.file_number}</td>
									<td className="center">
										{openOrder.origin_rating_port_code ??
											origin?.postal_code ?? <>&nbsp;</>}
									</td>
									<td className="center">
										{openOrder.destination_rating_port_code ??
											destination?.postal_code ?? <>&nbsp;</>}
									</td>
									<td className="center">&nbsp;</td>
								</tr>
								{orders?.map((el, index) => {
									const orderItem = list.find((item) => item.id === el);
									const orderOrigin = getAddress(orderItem, ADDRESS_TYPE.ORIGIN);
									const orderDestination = getAddress(orderItem, ADDRESS_TYPE.DESTINATION);
									return (
										<tr key={index}>
											<td>{orderItem.file_number}</td>
											<td className="center">
												{orderItem.origin_rating_port_code ??
													orderOrigin?.postal_code ?? <>&nbsp;</>}
											</td>
											<td className="center">
												{orderItem.destination_rating_port_code ??
													orderDestination?.postal_code ?? <>&nbsp;</>}
											</td>
											<td className="center">
												<span onClick={() => handleRemoveClick(el)} className="icon-remove"></span>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
