import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const TruckerInfo = ({ order, edit, onEdit, isLoading }) => {
	// Translation
	const { t } = useTranslation();
	const setFieldData = () => {
		let fieldArray = [];
		if (order.driver != null)
			fieldArray.push({ field: "driver", name: "Fields.DriverName" });
		if (order.license_number != null)
			fieldArray.push({
				field: "license_number",
				name: "Fields.LicenseNumber",
			});
		if (order.equipment_type != null)
			fieldArray.push({
				field: "equipment_type",
				name: "Fields.EquipmentType",
			});
		if (order.truck_number != null)
			fieldArray.push({ field: "truck_number", name: "Fields.TruckNumber" });
		if (order.plate_number != null)
			fieldArray.push({ field: "plate_number", name: "Fields.PlateNumber" });
		if (order.trailer_number != null)
			fieldArray.push({
				field: "trailer_number",
				name: "Fields.TrailerNumber",
			});
		switch (fieldArray.length) {
			case 0:
			default:
				return null;
			case 1:
			case 2:
			case 3:
				return { first: fieldArray, second: [] };
			case 4:
				return {
					first: fieldArray.slice(0, 2),
					second: fieldArray.slice(2, 4),
				};
			case 5:
				return {
					first: fieldArray.slice(0, 3),
					second: fieldArray.slice(3, 5),
				};
			case 6:
				return {
					first: fieldArray.slice(0, 3),
					second: fieldArray.slice(3, 6),
				};
		}
	};
	const renderEditButton = () => {
		if (order != null && edit == null) {
			return (
				<div>
					<span className="icon-edit" onClick={() => onEdit()}></span>
				</div>
			);
		}
		return null;
	};
	const renderData = () => {
		const fieldData = setFieldData();
		if (fieldData == null) {
			return (
				<div className="flex-table single-line remove-bottom-space">
					<div>
						<div>
							<div>{t("Common.TruckerInfoEmpty")}</div>
							<div>&nbsp;</div>
						</div>
					</div>
				</div>
			);
		}
		return (
			<>
				<div
					className={classNames("flex-table remove-bottom-space", {
						"single-line": fieldData.first.length === 1,
					})}
				>
					<div>
						{fieldData.first.map((el, index) => {
							return (
								<div key={index}>
									<div>{t(el.name)}</div>
									<div>{order[el.field]}</div>
								</div>
							);
						})}
					</div>
				</div>
				{fieldData.second.length > 0 && (
					<div
						className={classNames("flex-table remove-bottom-space", {
							"single-line": fieldData.second.length === 1,
						})}
					>
						<div>
							{fieldData.second.map((el, index) => {
								return (
									<div key={index}>
										<div>{t(el.name)}</div>
										<div>{order[el.field]}</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</>
		);
	};
	if (order == null || isLoading) {
		return null;
	}
	return (
		<div className="order-block bottom-border">
			<div className="order-subtitle">
				<div>{t("Common.TruckerInfo")}</div>
				{renderEditButton()}
			</div>
			{renderData()}
		</div>
	);
};
export default TruckerInfo;
