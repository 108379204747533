import Loader from "components/Loader";
import {
	fetchOperatorPriceCheckAccept,
	fetchOperatorPriceCheckReject,
} from "features/operator/operatorOrdersSlice";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "utils/numberUtils";

export default function OperatorOrderDetailsQuote() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { openOrder, isLoadingAccept } = useSelector(
		(state) => state.operatorOrders
	);
	// State
	const [activeOption, setActiveOption] = useState(null);
	const [notes, setNotes] = useState("");
	const handleAcceptQuote = () => {
		dispatch(
			fetchOperatorPriceCheckAccept({
				orderId: openOrder.id,
				bookedId: openOrder.booked_orders[0].id,
				data: {
					public_id: openOrder.booked_orders[0].public_id,
					notes: notes !== "" ? notes : undefined,
				},
			})
		);
	};
	const handleRejectQuote = () => {
		dispatch(
			fetchOperatorPriceCheckReject({
				orderId: openOrder.id,
				bookedId: openOrder.booked_orders[0].id,
				data: {
					public_id: openOrder.booked_orders[0].public_id,
					notes: notes !== "" ? notes : undefined,
				},
			})
		);
	};
	const handleOptionClick = (option) => {
		if (activeOption === option) {
			setActiveOption(null);
		} else {
			setActiveOption(option);
		}
	};
	const optionStyles = {
		quote:
			activeOption === 0
				? {
						option: { className: "accordion-option active" },
						icon: { className: "icon-chevron expanded" },
				  }
				: {
						option: { className: "accordion-option" },
						icon: { className: "icon-chevron" },
				  },
		reject:
			activeOption === 1
				? {
						option: { className: "accordion-option active" },
						icon: { className: "icon-chevron expanded" },
				  }
				: {
						option: { className: "accordion-option" },
						icon: { className: "icon-chevron" },
				  },
	};
	if (isLoadingAccept) {
		return <Loader />;
	}
	const bookedOrder = openOrder.booked_orders[0];
	return (
		<div className="order-block">
			<div className="dsv-accordion">
				<div {...optionStyles.quote.option}>
					<div
						className="accordion-button"
						onClick={() => handleOptionClick(0)}
					>
						<div>{t("OperatorOrders.AcceptQuote")}</div>
						<div>
							<span {...optionStyles.quote.icon}></span>
						</div>
					</div>
					<div className="accordion-content">
						<div className="order-subtitle">
							<div>{t("CarrierBookedOrders.QuoteInfo")}</div>
						</div>
						<div className="flex-table single-line">
							<div>
								<div>
									<div>{t("Common.Price")}:</div>
									<div>{`$${numberFormat(bookedOrder.price * bookedOrder.exchange_rate, 2)}`}</div>
								</div>
								<div>
									<div>{t("Fields.QuoteNumber")}:</div>
									<div>{bookedOrder.dsv_quote_number}</div>
								</div>
								<div>
									<div>{t("Common.Notes")}:</div>
									<div>{bookedOrder.dsv_notes}</div>
								</div>
							</div>
						</div>
						<div className="dsv-form">
							<div className="a field">
								<label>{t("Common.Notes")}</label>
								<textarea
									name="notes"
									id="notes"
									placeholder={t("Common.Notes")}
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
								></textarea>
							</div>
							<div className="a top-separation">
								<div onClick={handleAcceptQuote} className="button main">
									{t("Buttons.AcceptNow")}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div {...optionStyles["reject"].option}>
					<div
						className="accordion-button secondary"
						onClick={() => handleOptionClick(1)}
					>
						<div>{t("CarrierBookedOrders.Reject")}</div>
						<div>
							<span {...optionStyles["reject"].icon}></span>
						</div>
					</div>
					<div className="accordion-content">
						<div className="dsv-form">
							<div className="a field">
								<label>{t("Fields.RejectReason")}</label>
								<textarea
									name="reason"
									id="reason"
									placeholder={t("Fields.RejectReason")}
								></textarea>
							</div>
							<div className="a top-separation">
								<div onClick={handleRejectQuote} className="button main">
									{t("Buttons.RejectNow")}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
