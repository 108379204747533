import React from "react";
import { useTranslation } from "react-i18next";
import { ADDRESS_TYPE, ORDER_EDIT } from "features/constants";
import OrderAddressItem from "components/common/order/OrderAddressItem";
import Loader from "components/Loader";

export default function OrderAddress({
	order,
	edit,
	isLoading,
	onEdit,
	errors,
	booked,
	bookedOrder,
}) {
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleEditClick = () => {
		onEdit();
	};
	// Render
	const renderLoader = () => {
		if (isLoading) {
			return (
				<div className="order-block">
					<Loader msg={t("Common.Loading")} />
				</div>
			);
		}
	};
	const renderEdit = () => {
		if (!booked && edit == null) {
			return (
				<div>
					<span className="icon-edit" onClick={handleEditClick}></span>
				</div>
			);
		}
		return null;
	};
	if (edit === ORDER_EDIT.ADDRESS) {
		return null;
	}
	if (isLoading) {
		return renderLoader();
	}
	/* Only render if order is not null */
	if (order != null) {
		const bookedDates =
			booked === true
				? {
						estimated_ready: bookedOrder.estimated_ready,
						estimated_close: bookedOrder.estimated_close,
						requested_delivery: bookedOrder.requested_delivery,
				  }
				: {};
		return (
			<div className="order-block">
				<div className="order-subtitle">
					<div>{t("Common.AddressInfo")}</div>
					{renderEdit()}
				</div>
				<div className="order-grid-two">
					<OrderAddressItem
						order={order}
						type={ADDRESS_TYPE.ORIGIN}
						errors={errors}
						bookedDates={bookedDates}
					/>
					<OrderAddressItem
						order={order}
						type={ADDRESS_TYPE.DESTINATION}
						errors={errors}
						bookedDates={bookedDates}
					/>
				</div>
			</div>
		);
	}
	return null;
}
