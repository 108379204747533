import Loader from "components/Loader";
import { ATTACHABLE_TYPE, DOCUMENT_TYPE } from "features/constants";
import { fetchOrderDoc } from "features/documents/documentsSlice";
import { fetchOperatorOrderDeleteDoc } from "features/operator/operatorOrdersSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function OrderDocs({ docs, bookedOrderId, orderRequestId }) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { isLoading, isDeleting } = useSelector((state) => state.documents);
	// Handlers
	const handleDownloadClick = (el) => {
		if (el.attachable_type === "Booked_Order") {
			dispatch(
				fetchOrderDoc({
					id: el.id,
					attachableId: bookedOrderId,
					type: el.attachable_type,
				})
			);
		}
		if (el.attachable_type === "Order_Request") {
			dispatch(
				fetchOrderDoc({
					id: el.id,
					attachableId: orderRequestId,
					type: el.attachable_type,
				})
			);
		}
	};
	const handleDeleteClick = (el) => {
		if (
			window.confirm(
				t("Dialogs.ConfirmDeleteDocument", { document: el.file_name })
			)
		) {
			if (el.attachable_type === "Booked_Order") {
				dispatch(
					fetchOperatorOrderDeleteDoc({
						id: el.id,
						attachableId: bookedOrderId,
						attachableType: ATTACHABLE_TYPE.BOOKED_ORDER,
					})
				);
			}
			if (el.attachable_type === "Booked_Order") {
				dispatch(
					fetchOperatorOrderDeleteDoc({
						id: el.id,
						attachableId: orderRequestId,
						attachableType: ATTACHABLE_TYPE.ORDER_REQUEST,
					})
				);
			}
		}
	};
	// Render
	const renderLoader = () => {
		if (isLoading || isDeleting) {
			return <Loader />;
		}
	};
	const renderDocuments = () => {
		if (!isLoading) {
			return (
				<table cellPadding="0" cellSpacing="0">
					<thead>
						<tr>
							<th>{t("Tables.DocumentType")}</th>
							<th>{t("Tables.FileName")}</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{docs.map((el, index) => {
							return (
								<tr key={index} style={{ cursor: "pointer" }}>
									<td onClick={() => handleDownloadClick(el)}>
										{t(DOCUMENT_TYPE.properties[el.document_type].name)}
									</td>
									<td onClick={() => handleDownloadClick(el)}>
										{el.file_name}
										{el.file_number != null && ` - ${el.file_number}`}
									</td>
									<td>
										{el.can_delete && (
											<span
												className="icon-remove"
												onClick={() => handleDeleteClick(el)}
											></span>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			);
		}
	};
	return (
		<div className="dsv-table no-fixed">
			{renderLoader()}
			{renderDocuments()}
		</div>
	);
}
