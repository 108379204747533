import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CARRIER_PROFILE_TAB } from "features/constants";
import { setCarrierProfileActiveTab } from "features/carrier/carrierProfileSlice";

const ProfileTabs = () => {
  const dispatch = useDispatch();
  // Translations
  const { t } = useTranslation();
  // Selectors
  const { tab } = useSelector((state) => state.carrierProfile);

  // Handlers
  const handleClick = (tab) => {
    dispatch(setCarrierProfileActiveTab({ tab: tab }));
  };
  const tabsProps = {
    rates:
      tab === CARRIER_PROFILE_TAB.RATES
        ? { className: "active" }
        : { onClick: () => handleClick(CARRIER_PROFILE_TAB.RATES) },
    lanes:
      tab === CARRIER_PROFILE_TAB.LANES
        ? { className: "active" }
        : { onClick: () => handleClick(CARRIER_PROFILE_TAB.LANES) },
    zones:
      tab === CARRIER_PROFILE_TAB.ZONES
        ? { className: "active" }
        : { onClick: () => handleClick(CARRIER_PROFILE_TAB.ZONES) },
  };
  return (
    <div className="dsv-tabs">
      <div {...tabsProps.rates}>{t("AdminCarriers.Rates")}</div>
      <div {...tabsProps.lanes}>{t("AdminCarriers.Lanes")}</div>
      <div {...tabsProps.zones}>{t("AdminCarriers.Zones")}</div>
    </div>
  );
};

export default ProfileTabs;
