import client from "./networking";

/**
 * Gets csv order report with filters
 * @param {*} data object - filters data.
 * @returns
 */
export const getOrderCsvReport = (data) => {
  return client.post("/api/order_requests/csv_report", data);
}
