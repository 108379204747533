import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { CURRENCY } from "features/constants";
import { numberFormat } from "utils/numberUtils";
import {
	clearOperatorQuotesData,
	fetchQuoteCreation,
} from "features/operator/operatorQuotesSlice";
import Loader from "components/Loader";

export default function QuoteRates({ onClose }) {
	const dispatch = useDispatch();
	// Initialize Form
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { rates, isCalculating, isSaving, calculationData, refresh } =
		useSelector((state) => state.operatorQuotes);
	// Effect
	useEffect(() => {
		if (refresh) {
			reset();
		}
	}, [refresh, reset]);
	// Handlers
	const handleCloseClick = () => {
		dispatch(clearOperatorQuotesData());
		onClose();
	};
	const handleSaveQuoteClick = (data) => {
		const formData = { ...calculationData, save: true, ...data };
		dispatch(fetchQuoteCreation({ data: formData }));
	};
	// Render
	const renderLoader = () => {
		if (isCalculating) {
			return (
				<div className="order-book-block">
					<div>
						<div>
							<Loader />
						</div>
					</div>
				</div>
			);
		}
	};
	const renderEmptyState = () => {
		if (rates == null && !isCalculating) {
			return (
				<div className="order-book-block">
					<div>
						<div>
							<p>{t("OperatorQuotes.NoInfo")}</p>
							<p>{t("OperatorQuotes.NoInfoIntructions")}</p>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderRates = () => {
		if (rates != null && !isCalculating) {
			return (
				<div className="order-book-block">
					<div className="order-subtitle">
						<div>{t("Common.Rates")}</div>
					</div>
					<div className="dsv-table booking-rates">
						<table cellPadding="0" cellSpacing="0">
							<thead>
								<tr>
									<th>{t("Common.Price")}</th>
									<th>{t("Common.Carrier")}</th>
									<th>{t("Fields.TransitTime")}</th>
									<th>{t("Common.Qualifications")}</th>
								</tr>
							</thead>
							<tbody>
								{rates.rates?.map((el, index) => {
									return (
										<tr key={index}>
											<td>{`$${numberFormat(el.price * el.exchange_rate, 2)} ${
												CURRENCY.properties[el.currency].code
											}`}</td>
											<td>
												<div
													className="rates-tooltip"
													data-tooltip-content={el.carrier.short_name}
												>
													{el.carrier.name}
												</div>
											</td>
											<td className="center">
												{el.carrier_lane?.transit_time != null
													? t("Common.day", {
															count: el.carrier_lane.transit_time,
													  })
													: "n/a"}
											</td>
											<td>
												{el.carrier.qualifications.map((q, idx) => {
													return (
														// eslint-disable-next-line jsx-a11y/anchor-is-valid
														<a
															key={idx}
															className="rates-tooltip"
															data-tooltip-content={t(
																`Qualifications.${q.name}`
															)}
														>
															<span className={q.icon}></span>
														</a>
													);
												})}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			);
		}
	};
	const renderSave = () => {
		if (
			!isSaving &&
			!isCalculating &&
			calculationData != null &&
			rates != null &&
			rates.rates.length > 0
		)
			return (
				<>
					<div className="order-block remove-top-space">
						<div className="order-confirmation">
							<div>{t("OperatorQuotes.SaveInstructions")}</div>
							<div className="dsv-form collapse no-padding">
								<div
									className={classNames("a field", {
										error: errors.customer_name,
									})}
								>
									<label>{t("Fields.CustomerName")}</label>
									<input
										type="text"
										id="customer_name"
										name="customer_name"
										placeholder={t("Fields.CustomerName")}
										{...register("customer_name", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="action-buttons">
								<div>
									<div className="button" onClick={handleCloseClick}>
										{t("Buttons.Cancel")}
									</div>
									<div>
										<div
											className="button main"
											onClick={handleSubmit(handleSaveQuoteClick)}
										>
											{t("Buttons.Save")}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="order-block remove-top-space">
						<div>{t("OperatorQuotes.SaveWarning")}</div>
					</div>
				</>
			);
	};
	return (
		<div className="order-actions">
			<div className="order-actions-title">
				<div>
					<div>
						<span className="icon-expand"></span>
					</div>
					<div className="action-title">{t("OperatorQuotes.QuoteDetails")}</div>
					<div>
						<span onClick={handleCloseClick} className="icon-close"></span>
					</div>
				</div>
			</div>
			{renderEmptyState()}
			{renderLoader()}
			{renderRates()}
			{renderSave()}
		</div>
	);
}
