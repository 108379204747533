import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toSelector, toSelectorValue } from "utils/constantsUtils";
import {
	SHIPMENT_TYPE,
	CARGO_TYPE,
	LOCATION_TYPES,
	VOLUME_UNIT,
	WEIGHT_UNIT,
	DIMENSION_UNIT,
	POSTAL_CODE_SOURCE,
} from "features/constants";
import Select from "react-select";
import { removeEmptyProps } from "utils/formUtils";
import { decimalRegEx, portCodeRegEx } from "utils/regExs";
import { filterAccesorials, filterLocationTypes } from "utils/orderUtils";
import { convertKgToLb, convertLbToKg } from "utils/weightUtils";
import { calcVolume } from "utils/volumeUtils";
import { numberFormat } from "utils/numberUtils";

export default function QuoteForm({
	quote,
	onCalculate,
	onClose,
	onCollapse,
	onExpand,
	onBlurPostalCode,
	collapse,
	accesorials,
	locationTypes,
	packagings,
	postalCodeData,
	onClearPostalCode,
}) {
	// Translation
	const { t } = useTranslation();

	// Initialize form
	const {
		control,
		register,
		handleSubmit,
		getValues,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			shipment_type: {
				value: SHIPMENT_TYPE.DE,
				label: t(SHIPMENT_TYPE.properties[`${SHIPMENT_TYPE.DE}`].name),
			},
			cargo_type: {
				value: CARGO_TYPE.LOOSE,
				label: t(CARGO_TYPE.properties[`${CARGO_TYPE.LOOSE}`].name),
			},
			weight_unit: {
				value: WEIGHT_UNIT.KGS,
				label: WEIGHT_UNIT.properties[`${WEIGHT_UNIT.KGS}`].code,
			},
			volume_unit: {
				value: VOLUME_UNIT.CBM,
				label: VOLUME_UNIT.properties[`${VOLUME_UNIT.CBM}`].code,
			},
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "cargo_details",
	});

	// State
	const [totPcs, setTotPcs] = useState(null);
	const [totWgt, setTotWgt] = useState(null);
	const [totVol, setTotVol] = useState(null);

	// Watch Fields
	watch("shipment_type");

	const setPostalCodeData = (data) => {
		const { postalCode, source } = data;
		switch (source) {
			case POSTAL_CODE_SOURCE.QUOTE_ORIGIN:
				setValue("origin_city", postalCode.city);
				setValue("origin_state", postalCode.state_code);
				setValue("origin_country", postalCode.country_code);
				break;
			case POSTAL_CODE_SOURCE.QUOTE_DESTINATION:
				setValue("destination_city", postalCode.city);
				setValue("destination_state", postalCode.state_code);
				setValue("destination_country", postalCode.country_code);
				break;
			default:
				break;
		}
		onClearPostalCode();
	};

	// Effect
	useEffect(() => {
		if (postalCodeData?.postalCode != null && !postalCodeData?.isLoading) {
			setPostalCodeData(postalCodeData);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, [postalCodeData]);

	// Handlers
	const handleCalculateClick = (data, event) => {
		event.preventDefault();

		// Prepare data
		const arrOrig =
			data.origin_accessorials_select != null
				? data.origin_accessorials_select.map((item) => {
						return {id: item.value};
				  })
				: undefined;
		const arrDest =
			data.dest_accessorials_select != null
				? data.dest_accessorials_select?.map((item) => {
						return {id: item.value};
				  })
				: undefined;

		const formData = {
			shipment_type: data.shipment_type.value,
			cargo_type: data.cargo_type.value,
			origin_zip: data.origin_postal_code,
			origin_port: data.origin_port ?? null,
			origin_city: data.origin_city ?? null,
			origin_state: data.origin_state ?? null,
			origin_country: data.origin_country ?? null,
			origin_location_type: data.origin_location_type?.value ?? null,
			destination_zip: data.destination_postal_code,
			destination_port: data.destination_port ?? null,
			destination_city: data.destination_city ?? null,
			destination_state: data.destination_state ?? null,
			destination_country: data.destination_country ?? null,
			destination_location_type: data.destination_location_type?.value ?? null,
			volume: data.volume,
			volume_unit: data.volume_unit.value,
			weight: data.weight,
			weight_unit: data.weight_unit.value,
			pieces: data.pieces,
			packaging: data.packaging.value,
			cargo_description: data.cargo_description,
			hazmat: data.hazmat ?? false,
			tempctl: data.tempctl ?? false,
			cargo_details: data.cargo_details?.map((el) => {
				return {
					qty: el.qty,
					description: el.description,
					packaging: el.packaging.value,
					weight: el.weight,
					weight_unit: el.weight_unit.value,
					length: el.cargo_length,
					height: el.height,
					width: el.width,
					unit: el.unit.value,
				};
			}),
		};

		// Call Service.
		onCalculate({
			...removeEmptyProps(formData),
			accessorials: data.shipment_type.value === SHIPMENT_TYPE.PU ? arrOrig : arrDest,
			// accessorials_source: data.shipment_type.value === SHIPMENT_TYPE.PU 
			// 	? ADDR_SOURCE.ORIGIN : ADDR_SOURCE.DESTINATION,
		});
	};

	const handleCloseClick = () => {
		// Clean form.
		reset();
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handlePostalCodeBlur = (source) => {
		const postalCode =
			source === POSTAL_CODE_SOURCE.QUOTE_ORIGIN
				? getValues("origin_postal_code")
				: getValues("destination_postal_code");
		onBlurPostalCode(postalCode, source);
	};
	const handleShipmentTypeChange = (option) => {
		setValue("shipment_type", option);
		setValue("origin_location_type", null);
		setValue("destination_location_type", null);
		setValue("origin_accessorials_select", null);
		setValue("dest_accessorials_select", null);
	};
	const handleAddDetail = () => {
		const formValues = getValues();
		const { pieces, cargo_description, packaging, weight, weight_unit } =
			formValues;
		if (fields.length === 0) {
			setTotPcs(Number.isNaN(pieces) ? 0 : pieces);
			setTotWgt(Number.isNaN(weight) ? 0 : weight);
			append({
				qty: Number.isNaN(pieces) ? "" : pieces,
				description: cargo_description,
				packaging: packaging,
				weight: Number.isNaN(weight) ? "" : weight,
				weight_unit: weight_unit,
				cargo_length: "",
				width: "",
				height: "",
				unit: toSelectorValue(
					DIMENSION_UNIT.properties,
					weight_unit.value,
					true
				),
			});
		} else {
			append({
				qty: "",
				description: cargo_description,
				packaging: packaging,
				weight: "",
				weight_unit: weight_unit,
				cargo_length: "",
				width: "",
				height: "",
				unit: toSelectorValue(
					DIMENSION_UNIT.properties,
					weight_unit.value,
					true,
					false
				),
			});
		}
	};
	const handleRemoveDetail = (index) => {
		remove(index);
		if (fields.length !== 1) {
			calcTotalPieces();
			calcTotalWeight();
			calcTotalVolume();
		}
	};

	// Functions
	const calcTotalPieces = () => {
		const detValues = getValues("cargo_details");
		const totalPieces = detValues.reduce((sum, el) => {
			if (Number.isNaN(el.qty)) return sum;
			return sum + Number("0" + el.qty);
		}, 0);
		setValue("pieces", totalPieces);
		setTotPcs(totalPieces);
		calcTotalVolume();
	};

	const calcTotalWeight = () => {
		const wUnit = getValues("weight_unit");
		const detValues = getValues("cargo_details");
		const totalWeight = detValues.reduce((sum, el) => {
			if (Number.isNaN(el.weight)) return sum;
			if (wUnit.value !== el.weight_unit.value) {
				if (wUnit.value === WEIGHT_UNIT.KGS) {
					return sum + convertLbToKg(el.weight, 2);
				} else {
					return sum + convertKgToLb(el.weight, 2);
				}
			} else {
				return sum + Number("0" + el.weight);
			}
		}, 0);
		setValue("weight", totalWeight);
		setTotWgt(totalWeight);
	};
	const calcTotalVolume = () => {
		const vUnit = getValues("volume_unit");
		const detValues = getValues("cargo_details");
		const totalVolume = detValues.reduce((sum, el) => {
			if (
				Number.isNaN(el.cargo_length) ||
				Number.isNaN(el.width) ||
				Number.isNaN(el.height) ||
				Number.isNaN(el.qty)
			)
				return sum;
			return (
				calcVolume(
					vUnit.value === VOLUME_UNIT.CBM,
					el.cargo_length,
					el.width,
					el.height,
					el.unit.value,
					el.qty
				) + sum
			);
		}, 0);
		const totalRounded = Number(totalVolume.toFixed(2));
		setValue("volume", totalRounded);
		setTotVol(totalRounded);
	};

	// Render
	const renderHeader = () => {
		const collapseProps = collapse
			? { className: "icon-expand", onClick: handleExpandClick }
			: { className: "icon-collapse", onClick: handleCollapseClick };
		return (
			<>
				<div className="order-file">
					<div>
						<div>
							<span {...collapseProps}></span>
						</div>
						<div className="file-number">
							<div>{t("OperatorQuotes.NewQuote")}</div>
						</div>
						<div>
							<span className="icon-close" onClick={handleCloseClick}></span>
						</div>
					</div>
				</div>
				<div className="order-block">
					<div>{t("OperatorQuotes.NewQuoteInstructions")}</div>
				</div>
			</>
		);
	};
	const renderTransport = () => {
		const formProps = collapse
			? { className: "dsv-form full no-padding collapse" }
			: { className: "dsv-form full no-padding" };
		return (
			<>
				<div className="order-block edit top-border thin">
					<div {...formProps}>
						<div className="b-1 order-subtitle">
							<div>{t("Tables.Transport")}</div>
						</div>
						<div
							className={classNames("b-21 field", {
								error: errors.transport_shipment_type,
							})}
						>
							<label>{t("ShipmentTypes.Title")}</label>

							<Controller
								name="shipment_type"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										className="dsv-select"
										classNamePrefix="dsv-select"
										isClearable={false}
										// {...field}
										onChange={handleShipmentTypeChange}
										value={
											value?.value
												? {
														value:
															SHIPMENT_TYPE.properties[`${value.value}`].value,
														label: t(
															SHIPMENT_TYPE.properties[`${value.value}`].name
														),
												  }
												: {}
										}
										options={toSelector(SHIPMENT_TYPE.properties)}
									/>
								)}
							/>
						</div>
						<div
							className={classNames("b-22 field", {
								error: errors.transport_cargo_type,
							})}
						>
							<label>{t("CargoTypes.Title")}</label>
							<Controller
								name="cargo_type"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										className="dsv-select"
										classNamePrefix="dsv-select"
										isClearable={false}
										// {...field}
										onChange={onChange}
										value={
											value?.value
												? {
														value:
															CARGO_TYPE.properties[`${value.value}`].value,
														label: t(
															CARGO_TYPE.properties[`${value.value}`].name
														),
												  }
												: {}
										}
										options={toSelector(CARGO_TYPE.properties)}
									/>
								)}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};
	const renderAddresses = () => {
		const accesorialOptions = filterAccesorials(accesorials, false);
		const isOriginPort = getValues("shipment_type")?.value === SHIPMENT_TYPE.DE;
		const originLocTypes = filterLocationTypes(locationTypes, isOriginPort);
		const destLocTypes = filterLocationTypes(locationTypes, !isOriginPort);
		return (
			<div className="order-block edit top-border thin">
				<div className="order-grid-two">
					<div>
						<div className="dsv-form half">
							<div className="a">
								<div>{t("Common.Origin")}</div>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.origin_postal_code,
								})}
							>
								<label>{t("Fields.PostalCode")}</label>
								<input
									type="text"
									id="origin_postal_code"
									name="origin_postal_code"
									placeholder={t("Fields.PostalCode")}
									{...register("origin_postal_code", {
										required: true,
										onChange: (e) => {
											const clean = e.target.value
												.toUpperCase()
												.replace(/[^0-9]/, "")
												.substring(0, 5);
											setValue("origin_postal_code", clean);
										},
										onBlur: () => {
											handlePostalCodeBlur(POSTAL_CODE_SOURCE.QUOTE_ORIGIN);
										},
									})}
								></input>
							</div>
							{getValues("shipment_type")?.value === SHIPMENT_TYPE.DE && (
								<div
									className={classNames("b-2 field", {
										error: errors.origin_port,
									})}
								>
									<label>{t("Fields.PortCode")}</label>
									<input
										type="text"
										id="origin_port"
										name="origin_port"
										placeholder={t("Placeholders.PortCode")}
										{...register("origin_port", {
											required: true,
											pattern: portCodeRegEx,
											onChange: (e) => {
												const clean = e.target.value
													.toUpperCase()
													.replace(/[^A-Z]/, "")
													.substring(0, 3);
												setValue("origin_port", clean);
											},
										})}
									></input>
								</div>
							)}
							<div
								className={classNames("a field", {
									error: errors.origin_city,
								})}
							>
								<label>{t("Fields.City")}</label>
								<input
									type="text"
									id="origin_city"
									name="origin_city"
									placeholder={t("Fields.City")}
									{...register("origin_city", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.origin_state,
								})}
							>
								<label>{t("Fields.StateProvince")}</label>
								<input
									type="text"
									id="origin_state"
									name="origin_state"
									placeholder={t("Fields.StateProvince")}
									{...register("origin_state", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-2 field", {
									error: errors.origin_country,
								})}
							>
								<label>{t("Fields.CountryCode")}</label>
								<input
									type="text"
									id="origin_country"
									name="origin_country"
									placeholder={t("Fields.CountryCode")}
									{...register("origin_country", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.origin_location_type,
								})}
							>
								<label>{t("Fields.LocationType")}</label>
								<Controller
									name="origin_location_type"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											placeholder={t("Common.Select")}
											onChange={onChange}
											value={
												value
													? {
															value:
																LOCATION_TYPES.properties[`${value.value}`]
																	.value,
															label: t(
																LOCATION_TYPES.properties[`${value.value}`].name
															),
													  }
													: null
											}
											options={originLocTypes}
										/>
									)}
								/>
							</div>
							{getValues("shipment_type")?.value === SHIPMENT_TYPE.PU && (
								<div
									className={classNames("a field", {
										error: errors.origin_accessorials_select,
									})}
								>
									<label>{t("Fields.Accessorials")}</label>
									<Controller
										name="origin_accessorials_select"
										control={control}
										render={({ field }) => (
											<Select
												className="dsv-select"
												classNamePrefix="dsv-select"
												isClearable={false}
												closeMenuOnSelect={false}
												placeholder={t("Common.Select")}
												isMulti={true}
												getOptionValue={(option) => `${option["value"]}`}
												{...field}
												options={accesorialOptions}
											/>
										)}
									/>
								</div>
							)}
						</div>
					</div>
					<div>
						<div className="dsv-form half">
							<div className="a">
								<div>{t("Common.Destination")}</div>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.destination_postal_code,
								})}
							>
								<label>{t("Fields.PostalCode")}</label>
								<input
									type="text"
									id="destination_postal_code"
									name="destination_postal_code"
									placeholder={t("Fields.PostalCode")}
									{...register("destination_postal_code", {
										required: true,
										onBlur: () => {
											handlePostalCodeBlur(
												POSTAL_CODE_SOURCE.QUOTE_DESTINATION
											);
										},
									})}
								></input>
							</div>
							{getValues("shipment_type")?.value === SHIPMENT_TYPE.PU && (
								<div
									className={classNames("b-2 field", {
										error: errors.destination_port,
									})}
								>
									<label>{t("Fields.PortCode")}</label>
									<input
										type="text"
										id="destination_port"
										name="destination_port"
										placeholder={t("Placeholders.PortCode")}
										{...register("destination_port", {
											required: true,
											pattern: portCodeRegEx,
											onChange: (e) => {
												const clean = e.target.value
													.toUpperCase()
													.replace(/[^A-Z]/, "")
													.substring(0, 3);
												setValue("destination_port", clean);
											},
										})}
									></input>
								</div>
							)}
							<div
								className={classNames("a field", {
									error: errors.destination_city,
								})}
							>
								<label>{t("Fields.City")}</label>
								<input
									type="text"
									id="destination_city"
									name="destination_city"
									placeholder={t("Fields.City")}
									{...register("destination_city", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-1 field", {
									error: errors.destination_state,
								})}
							>
								<label>{t("Fields.StateProvince")}</label>
								<input
									type="text"
									id="destination_state"
									name="destination_state"
									placeholder={t("Fields.StateProvince")}
									{...register("destination_state", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("b-2 field", {
									error: errors.destination_country,
								})}
							>
								<label>{t("Fields.CountryCode")}</label>
								<input
									type="text"
									id="destination_country"
									name="destination_country"
									placeholder={t("Fields.CountryCode")}
									{...register("destination_country", {
										required: false,
									})}
								></input>
							</div>
							<div
								className={classNames("a field", {
									error: errors.destination_location_type,
								})}
							>
								<label>{t("Fields.LocationType")}</label>
								<Controller
									name="destination_location_type"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											className="dsv-select"
											classNamePrefix="dsv-select"
											placeholder={t("Common.Select")}
											onChange={onChange}
											value={
												value
													? {
															value:
																LOCATION_TYPES.properties[`${value.value}`]
																	.value,
															label: t(
																LOCATION_TYPES.properties[`${value.value}`].name
															),
													  }
													: null
											}
											options={destLocTypes}
										/>
									)}
								/>
							</div>
							{getValues("shipment_type")?.value === SHIPMENT_TYPE.DE && (
								<div
									className={classNames("a field", {
										error: errors.dest_accessorials_select,
									})}
								>
									<label>{t("Fields.Accessorials")}</label>
									<Controller
										name="dest_accessorials_select"
										control={control}
										render={({ field }) => (
											<Select
												className="dsv-select"
												classNamePrefix="dsv-select"
												isClearable={false}
												closeMenuOnSelect={false}
												placeholder={t("Common.Select")}
												getOptionValue={(option) => `${option["value"]}`}
												isMulti={true}
												{...field}
												options={accesorialOptions}
											/>
										)}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};
	const renderCargo = () => {
		const formProps = collapse
			? { className: "dsv-form full no-padding collapse" }
			: { className: "dsv-form full no-padding" };
		return (
			<div className="order-block edit top-border thin">
				<div {...formProps}>
					<div className="a order-subtitle">
						<div>{t("Common.Cargo")}</div>
					</div>
					<div
						className={classNames("b-11 field", {
							error: errors.pieces,
						})}
					>
						<label>{t("Tables.Pieces")}</label>
						<input
							type={fields.length !== 0 ? "hidden" : "text"}
							placeholder={t("Tables.Pieces")}
							{...register("pieces", {
								required: true,
								onChange: (e) => {
									setValue(`pieces`, e.target.value.replace(/[^\d]/, ""));
								},
								pattern: decimalRegEx(5),
								valueAsNumber: true,
								setValueAs: (v) => parseInt(v),
							})}
						></input>
						{fields.length !== 0 ? <>{numberFormat(totPcs, 0)}</> : ""}
					</div>
					<div
						className={classNames("b-12 field", {
							error: errors.packaging,
						})}
					>
						<label>{t("Tables.Packaging")}</label>
						<Controller
							name="packaging"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									{...field}
									options={packagings.map((el) => {
										return {
											value: el.id,
											label: el.name,
										};
									})}
								/>
							)}
						/>
					</div>
					<div
						className={classNames("b-21 field", {
							error: errors.weight,
						})}
					>
						<label>{t("Tables.Weight")}</label>
						<input
							type={fields.length !== 0 ? "hidden" : "text"}
							placeholder={t("Tables.Weight")}
							{...register("weight", {
								required: true,
								onChange: (e) => {
									setValue(`weight`, e.target.value.replace(/[^\d]/, ""));
								},
								pattern: decimalRegEx(5),
								valueAsNumber: true,
								setValueAs: (v) => parseInt(v),
							})}
						></input>
						{fields.length !== 0 ? <>{numberFormat(totWgt, 0)}</> : ""}
					</div>
					<div
						className={classNames("b-22 field", {
							error: errors.weight_unit,
						})}
					>
						<label>{t("WeightUnits.Title")}</label>
						<Controller
							name="weight_unit"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									onChange={(val) => {
										onChange(val);
										calcTotalWeight();
									}}
									value={
										value?.value
											? {
													value: WEIGHT_UNIT.properties[`${value.value}`].value,
													label: WEIGHT_UNIT.properties[`${value.value}`].code,
											  }
											: {
													value: WEIGHT_UNIT.KGS,
													label:
														WEIGHT_UNIT.properties[`${WEIGHT_UNIT.KGS}`].code,
											  }
									}
									options={toSelector(WEIGHT_UNIT.properties, true, false)}
								/>
							)}
						/>
					</div>
					<div
						className={classNames("b-11 field", {
							error: errors.volume,
						})}
					>
						<label>{t("Tables.Volume")}</label>
						<input
							type={fields.length !== 0 ? "hidden" : "text"}
							placeholder={t("Tables.Volume")}
							{...register("volume", {
								required: true,
								onChange: function (e) {
									setValue(`volume`, e.target.value.replace(/[^\d.]/, ""));
								},
								pattern: decimalRegEx(5),
								valueAsNumber: true,
								setValueAs: (v) => parseInt(v),
							})}
						></input>
						{fields.length !== 0 ? <>{numberFormat(totVol ?? 0, 2)}</> : ""}
					</div>
					<div
						className={classNames("b-12 field", {
							error: errors.volume_unit,
						})}
					>
						<label>{t("VolumeUnits.Title")}</label>
						<Controller
							name="volume_unit"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									onChange={(val) => {
										onChange(val);
										calcTotalVolume();
									}}
									value={
										value?.value
											? {
													value: VOLUME_UNIT.properties[`${value.value}`].value,
													label: VOLUME_UNIT.properties[`${value.value}`].code,
											  }
											: {
													value: VOLUME_UNIT.CBM,
													label:
														VOLUME_UNIT.properties[`${VOLUME_UNIT.CBM}`].code,
											  }
									}
									options={toSelector(VOLUME_UNIT.properties, true, false)}
								/>
							)}
						/>
					</div>
					<div
						className={classNames("a field", {
							error: errors.cargo_description,
						})}
					>
						<label>{t("Tables.CargoDescription")}</label>
						<input
							type="text"
							placeholder={t("Tables.CargoDescription")}
							{...register("cargo_description", {
								required: true,
							})}
						></input>
					</div>
					<div className="d-1 order-subtitle top-separation">
						<div>{t("SpecialHandlings.Title")}</div>
					</div>
					<div className="d-2 check-field top-separation">
						<input
							type="checkbox"
							name="hazmat"
							id="hazmat"
							{...register("hazmat", {})}
						/>
						<label htmlFor="hazmat">
							<span className="icon-check"></span>
							{t("SpecialHandlings.Hazmat")}
						</label>
					</div>
					<div className="d-3 check-field top-separation">
						<input
							type="checkbox"
							id="tempctl"
							name="tempctl"
							{...register("tempctl", {})}
						/>
						<label htmlFor="tempctl">
							<span className="icon-check"></span>
							{t("SpecialHandlings.Tempctl")}
						</label>
					</div>
					<div className="a order-subtitle top-separation">
						<div>{t("Common.Details")}</div>
						<div>
							<span className="icon-add" onClick={handleAddDetail}></span>
						</div>
					</div>
					<div className="a field">
						<div className="field-table cargo-details-edit quote">
							<div>
								<table cellPadding="0" cellSpacing="0">
									<thead>
										<tr>
											<th>{t("Tables.Qty")}</th>
											<th>{t("Tables.Description")}</th>
											<th>{t("Tables.Packaging")}</th>
											<th colSpan="2">{t("Tables.Weight")}</th>
											<th>{t("Fields.DimensionLength")}</th>
											<th>{t("Fields.DimensionHeight")}</th>
											<th>{t("Fields.DimensionWidth")}</th>
											<th>{t("Common.Unit")}</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{fields.map((item, index) => {
											return (
												<>
													<tr key={index}>
														<td
															className={classNames("", {
																error: errors.cargo_details?.[index].qty,
															})}
														>
															<input
																className="cargo-01"
																type="text"
																placeholder={t("Tables.Qty")}
																{...register(`cargo_details.${index}.qty`, {
																	required: true,
																	onChange: (e) => {
																		const value = e.target.value.replace(
																			/[^\d]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.qty`,
																			value
																		);
																		calcTotalPieces();
																	},
																	pattern: decimalRegEx(0),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td
															className={classNames("", {
																error:
																	errors.cargo_details?.[index].description,
															})}
														>
															<input
																type="text"
																placeholder={t("Tables.Description")}
																{...register(
																	`cargo_details.${index}.description`,
																	{
																		required: true,
																	}
																)}
															></input>
														</td>
														<td
															className={classNames("", {
																error: errors.cargo_details?.[index].packaging,
															})}
														>
															<Controller
																name={`cargo_details.${index}.packaging`}
																control={control}
																rules={{
																	required: true,
																}}
																render={({ field }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		{...field}
																		options={packagings.map((el, index) => {
																			return {
																				value: el.id,
																				label: el.name,
																			};
																		})}
																	/>
																)}
															/>
														</td>
														<td
															className={classNames("", {
																error: errors.cargo_details?.[index].weight,
															})}
														>
															<input
																type="text"
																placeholder={t("Tables.Weight")}
																{...register(`cargo_details.${index}.weight`, {
																	required: true,
																	onChange: function (e) {
																		const value = e.target.value.replace(
																			/[^\d.]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.weight`,
																			value
																		);
																		calcTotalWeight();
																	},
																	pattern: decimalRegEx(5),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td
															className={classNames("", {
																error:
																	errors.cargo_details?.[index].weight_unit,
															})}
														>
															<Controller
																name={`cargo_details.${index}.weight_unit`}
																control={control}
																rules={{ required: true }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		onChange={(val) => {
																			onChange(val);
																			calcTotalWeight();
																		}}
																		value={value}
																		options={toSelector(
																			WEIGHT_UNIT.properties,
																			true,
																			false
																		)}
																	/>
																)}
															/>
														</td>
														<td
															className={classNames("", {
																error:
																	errors.cargo_details?.[index].cargo_length,
															})}
														>
															<input
																type="number"
																placeholder="L"
																{...register(
																	`cargo_details.${index}.cargo_length`,
																	{
																		required: true,
																		onChange: function (e) {
																			const value = e.target.value.replace(
																				/[^\d.]/,
																				""
																			);
																			setValue(
																				`cargo_details.${index}.cargo_length`,
																				value
																			);
																			calcTotalVolume();
																		},
																		pattern: decimalRegEx(5),
																		valueAsNumber: true,
																		setValueAs: (v) => parseInt(v),
																	}
																)}
															></input>
														</td>
														<td
															className={classNames("", {
																error: errors.cargo_details?.[index].width,
															})}
														>
															<input
																type="text"
																placeholder="W"
																{...register(`cargo_details.${index}.width`, {
																	required: true,
																	onChange: function (e) {
																		const value = e.target.value.replace(
																			/[^\d.]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.width`,
																			value
																		);
																		calcTotalVolume();
																	},
																	pattern: decimalRegEx(5),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td
															className={classNames("", {
																error: errors.cargo_details?.[index].height,
															})}
														>
															<input
																type="text"
																placeholder="H"
																{...register(`cargo_details.${index}.height`, {
																	required: true,
																	onChange: function (e) {
																		const value = e.target.value.replace(
																			/[^\d.]/,
																			""
																		);
																		setValue(
																			`cargo_details.${index}.height`,
																			value
																		);
																		calcTotalVolume();
																	},
																	pattern: decimalRegEx(5),
																	valueAsNumber: true,
																	setValueAs: (v) => parseInt(v),
																})}
															></input>
														</td>
														<td
															className={classNames("", {
																error: errors.cargo_details?.[index].unit,
															})}
														>
															<Controller
																name={`cargo_details.${index}.unit`}
																control={control}
																rules={{ required: true }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		className="dsv-select no-border"
																		classNamePrefix="dsv-select"
																		isClearable={false}
																		onChange={(val) => {
																			onChange(val);
																			calcTotalVolume();
																		}}
																		value={value}
																		options={toSelector(
																			DIMENSION_UNIT.properties,
																			true,
																			false
																		)}
																	/>
																)}
															/>
														</td>
														<td>
															<span
																className="icon-remove"
																onClick={() => {
																	handleRemoveDetail(index);
																}}
															></span>
														</td>
													</tr>
												</>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="d-2 actions">
						<div className="button" onClick={handleCloseClick}>
							{t("Buttons.Cancel")}
						</div>
					</div>
					<div className="d-3 actions">
						<div
							className="button main"
							onClick={handleSubmit(handleCalculateClick)}
						>
							{t("Buttons.Calculate")}
						</div>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className="order-info">
			{renderHeader()}
			{renderTransport()}
			{renderAddresses()}
			{renderCargo()}
		</div>
	);
}
