import { PROFILES } from "features/constants";

export const getUserRole = (user) => {
	if (user != null) {
		const userProfile = user.roles.filter((item) => {
			return (
				item === PROFILES.CARRIER ||
				item === PROFILES.OPERATOR ||
				item === PROFILES.ADMIN ||
				item === PROFILES.SUPER_ADMIN
			);
		});
		return PROFILES.properties[userProfile[0]].value;
	}
	return null;
};