import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PROFILES } from "features/constants";
import { fetchUser } from "features/account/accountSlice";
import MainHeader from "./header/MainHeader";
import Footer from "./footer/Footer";
import SidebarCarrier from "./sidebar/SidebarCarrier";

export default function LoggedInCarrier() {
	const dispatch = useDispatch();
	// Router
	const navigate = useNavigate();
	const location = useLocation();
	// Selectors
	const { authenticated, user, checked } = useSelector(
		(state) => state.session
	);
	const { usrChecked, isLoading } = useSelector((state) => state.account);
	// Effect
	useEffect(() => {
		if (checked) {
			if (!authenticated) {
				navigate("/login");
			} else {
				if (!usrChecked && !isLoading) {
					dispatch(fetchUser());
				} else {
					if (!user?.user?.roles?.includes(PROFILES.CARRIER)) {
						navigate("/");
					}
					if (location.pathname === "/carrier") {
						navigate("/carrier/orders");
					}
				}
			}
		}
	}, [
		checked,
		authenticated,
		usrChecked,
		isLoading,
		user,
		location.pathname,
		dispatch,
		navigate,
	]);
	return (
		<div id="app" className="main-container">
			<MainHeader />
			<SidebarCarrier />
			<div className="content">
				<Outlet />
			</div>
			<Footer className="footer" />
		</div>
	);
}
