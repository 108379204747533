import axios from "axios";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "features/constants";
import { removeSession } from "utils/sessionUtils";

/**
 * Create client instance and setup.
 */
const client = axios.create({
  /** Get the base url from the .env file constants. */
  baseURL: BASE_API_URL,
  /** Put default headers for all requests. */
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * We use `response` object `interceptor` for handling errors and verify status codes.
 */
client.interceptors.response.use(
  /**
   * Response callback.
   * */
  function (response) {
    return response;
  },
  /**
   * Error callback.
   * */
  function (error) {
    let res = error.response;
    // Check for unauthorized response.
    if (res.status === 403) {
      // Remove session and redirect to login.
      removeSession();
    }
    // Reject.
    return Promise.reject(error);
  }
);

/**
 * We use `request` object `interceptor` to add access token if exists.
 */
client.interceptors.request.use(async function (request) {

  // Try get acess-token.
  try {
    // Get session object.
    const session = await sessionService.loadSession();
    // Check if session is not null.
    if (session) {
      // Session is available and not null.
      request.headers.Authorization = `Bearer ${session.loginData.access_token}`;
    }
  } catch (error) {
    // Session is unavailable.
  }

  // Finally return request object.
  return request;
});

/** Client export. */
export default client;
