import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import {
	fetchSetCarrierQualifications,
	setCarrierQualificationsEdit,
} from "features/admin/carriersSlice";
import { fetchQualifications } from "features/catalogs/qualificationsSlice";
import Loader from "components/Loader";
import Select from "react-select";

export default function CarrierDetailsQualificationsEdit({ carrier }) {
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	// Selectors
	const { isSavingQualifications } = useSelector((state) => state.carriers);

	const { qualifications, isLoading } = useSelector(
		(state) => state.qualifications
	);

	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleSave = (data, event) => {
		event.preventDefault();
		const qualificationList = data.qualifications_select?.map(
			(item) => item.value
		);
		dispatch(
			fetchSetCarrierQualifications({
				carrierId: carrier.id,
				data: { qualifications: qualificationList },
			})
		);
	};
	const handleClose = () => {
		dispatch(setCarrierQualificationsEdit());
	};

	// Effect runs once on display.
	useEffect(() => {
		if (qualifications === null && !isLoading) {
			dispatch(fetchQualifications());
		}

		reset({
			qualifications_select: carrier?.qualifications?.map((item, index) => {
				return {
					value: item.id,
					label: item.name, //branches?.find((e) => e.id === item.id).code ?? undefined,
				};
			}),
		});
	}, [reset, dispatch, isLoading, qualifications, carrier]);

	if (isSavingQualifications || isLoading) {
		return (
			<div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}

	return (
		<div className="tab-content details">
			<div className="dsv-form collapse">
				<div className="b-1">
					<div className="details-subtitle">
						<div>{t("AdminCarriers.Qualifications")}</div>
					</div>
				</div>

				<div
					className={classNames("b-1 field top-separation", {
						error: errors.qualifications_select,
					})}
				>
					<label>{t("AdminCarriers.Qualifications")}</label>
					<Controller
						name="qualifications_select"
						control={control}
						// rules={{ required: false }}
						render={({ field }) => (
							<Select
								closeMenuOnSelect={false}
								className="dsv-select"
								classNamePrefix="dsv-select"
								isClearable={true}
								isMulti={true}
								placeholder={t("Common.Select")}
								options={qualifications?.map((item, index) => {
									return {
										value: item.id,
										label: item.name,
									};
								})}
								{...field}
							/>
						)}
					/>
				</div>

				<div className="b-11 top-separation">
					<label>&nbsp;</label>
					<div className="button" onClick={handleClose}>
						Cancel
					</div>
				</div>
				<div className="b-12 top-separation">
					<label>&nbsp;</label>
					<div className="button main" onClick={handleSubmit(handleSave)}>
						Save
					</div>
				</div>
			</div>
		</div>
	);
}
