import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOperatorOrderEdit,
	fetchUpdateOrderHeader,
} from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";
import OrderHeaderForm from "components/common/order/OrderHeaderForm";
import {
	fetchUpdateConsolidateHeader,
	setOperatorConsolidationActiveTab,
	setOperatorConsolidationSelectedOrder,
	setOperatorConsolidationTabEdit,
} from "features/operator/operatorOrderConsolidationSlice";

export default function OperatorConsolidateDetailsHeaderEdit({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	// Redux dispatch
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, consolidate } =
		useSelector((state) => state.operatorOrders);
	const { list, tabs, selectedOrder, edit, activeTab, orders } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	// const isSavingConsolidate = useSelector(
	// 	(state) => state.operatorOrderConsolidation.isSaving
	// );
	const branches = useSelector((state) => state.branchCatalog.list);

	// Handlers
	const handleTabClick = (tab) => {
		const findSelected = list.find((item) => item.id === tabs[tab].id);
		dispatch(setOperatorOrderEdit({ orderEdit: null }));
		dispatch(setOperatorConsolidationTabEdit({ edit: null }));
		dispatch(
			setOperatorConsolidationSelectedOrder({ selectedOrder: findSelected })
		);
		dispatch(setOperatorConsolidationActiveTab({ activeTab: tab }));
	};

	// Submit function
	const handleSaveHeaderData = (data) => {
		// Call Service.
		if (activeTab === 0 && openOrder != null) {
			dispatch(
				fetchUpdateOrderHeader({ orderId: openOrder.id, formData: data })
			);
		}
		if (activeTab > 0 && selectedOrder != null) {
			dispatch(
				fetchUpdateConsolidateHeader({
					orderId: selectedOrder.id,
					formData: data,
					primary: openOrder.id,
					ids: [openOrder.id, ...orders],
				})
			);
		}
	};

	const handleCloseClick = () => {
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};

	const handleCancelEdit = () => {
		if (activeTab === 0) {
			dispatch(setOperatorOrderEdit({ orderEdit: null }));
		}
		else {
			dispatch(setOperatorConsolidationTabEdit({ edit: null }));
		} 
	};
	if (
		activeTab === 0 &&
		orderEdit != null &&
		orderEdit === ORDER_EDIT.HEADER &&
		openOrder != null
	) {
		return (
			<OrderHeaderForm
				order={openOrder}
				edit={orderEdit}
				tabs={tabs}
				activeTab={activeTab}
				onSave={handleSaveHeaderData}
				onCancel={handleCancelEdit}
				onClose={handleCloseClick}
				onCollapse={handleCollapseClick}
				onExpand={handleExpandClick}
				onTabClick={handleTabClick}
				collapse={collapse}
				consolidate={consolidate}
				branches={branches}
			/>
		);
	}
	if (
		activeTab > 0 &&
		edit != null &&
		edit === ORDER_EDIT.HEADER &&
		selectedOrder != null
	) {
		return (
			<OrderHeaderForm
				order={selectedOrder}
				edit={edit}
				tabs={tabs}
				activeTab={activeTab}
				onSave={handleSaveHeaderData}
				onCancel={handleCancelEdit}
				onClose={handleCloseClick}
				onCollapse={handleCollapseClick}
				onExpand={handleExpandClick}
				onTabClick={handleTabClick}
				collapse={collapse}
				consolidate={consolidate}
				branches={branches}
			/>
		);
	}

	return null;
}
