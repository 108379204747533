import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOOKED_STATUS, BOOKING_METHOD, CURRENCY } from "features/constants";
import OrderLog from "components/common/order/OrderLog";
import {
	clearCarrierOrdersEmailSent,
	fetchCarrierOrderChangeStatus,
	fetchCarrierOrderUploadDoc,
	fetchCarrierSendEmailToOperator,
	setCarrierOrderShowUpload,
	setCarrierOrderStatusError,
} from "features/carrier/carrierOrdersSlice";
import { numberFormat } from "utils/numberUtils";
import OrderDocs from "components/common/order/OrderDocs";
import CarrierBookedOrderDetailsQuote from "./CarrierBookedOrderDetailsQuote";
import Loader from "components/Loader";
import OrderDocsForm from "components/common/order/OrderDocsForm";
import CarrierOrderActions from "components/common/order/CarrierOrderActions";


export default function CarrierBookedOrderDetailsActions({
	onClose,
	onCollapse,
	collapse,
	onExpand,
}) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const {
		opened,
		isOpening,
		openedLog,
		docs,
		isUploading,
		showUpload,
		isSendingEmail,
		isEmailSent,
		isChangingStatus,
		changeStatusError,
	} = useSelector((state) => state.carrierOrders);
	// State
	const [showStatusChange, setShowStatusChange] = useState(false);
	const [showEmail, setShowEmail] = useState(false);

	// Handlers
	const handleCloseClick = () => {
		handleCancelAction();
		onClose();
	};
	const handleCollapseClick = () => {
		onCollapse();
	};
	const handleExpandClick = () => {
		onExpand();
	};
	const handleClearEmailSent = () => {
		dispatch(clearCarrierOrdersEmailSent());
		setShowEmail(false);
	};
	const handleChangeStatusClick = (payload) => {
		dispatch(setCarrierOrderStatusError({ changeStatusError: null }));
		dispatch(fetchCarrierOrderChangeStatus({ ...payload }));
	};
	const handleCancelAction = () => {
		setShowStatusChange(false);
		setShowEmail(false);
		dispatch(setCarrierOrderStatusError({ changeStatusError: null }));
	};
	const handleShowEmail = () => {
		setShowEmail(!showEmail);
		setShowStatusChange(false);
	};
	const handleShowStatusChange = () => {
		setShowStatusChange(!showStatusChange);
		setShowEmail(false);
	};
	const handleShowUpload = (show) => {
		dispatch(setCarrierOrderShowUpload({ showUpload: show }));
	};
	const handleUpload = (file, type, file_number) => {
		// Upload.
		dispatch(
			fetchCarrierOrderUploadDoc({
				booked_order_id: opened.id,
				type: type,
				file: file,
				file_number: file_number,
			})
		);
	};
	const handleSendEmail = (payload) => {
		dispatch(fetchCarrierSendEmailToOperator(payload));
	};
	// Render
	const collapseProps = collapse
		? { className: "icon-expand", onClick: handleExpandClick }
		: { className: "icon-collapse", onClick: handleCollapseClick };

	const renderOrderInfo = () => {
		if (opened != null) {
			return (
				<div className="order-block">
					<div className="flex-table single-line remove-bottom-space">
						<div>
							<div>
								<div>{t("Common.Price")}</div>
								<div>
									{opened.price != null
										? `$${numberFormat(opened.price * opened.exchange_rate, 2)} ${
												CURRENCY.properties[opened?.currency].code
										  }`
										: "-"}
								</div>
							</div>
							<div>
								<div>{t("BookingMethods.Title")}</div>
								<div>
									{t(BOOKING_METHOD.properties[opened.booked_method].name)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderBookedInfo = () => {
		if (
			opened != null &&
			opened.book_status !== BOOKED_STATUS.PENDING &&
			opened.book_status !== BOOKED_STATUS.SUBMITTED
		) {
			return (
				<>
					<div className="order-actions-title">
						<div>
							<div>
								<span {...collapseProps}></span>
							</div>
							<div className="action-title">{t("OperatorOrders.Booked")}</div>
							<div>
								<span className="icon-close" onClick={handleCloseClick}></span>
							</div>
						</div>
					</div>
					{renderOrderInfo()}
				</>
			);
		}
		return null;
	};
	const renderActions = () => {
		if (opened != null) {
			return (
				<CarrierOrderActions 
					order={opened}
					showEmail={showEmail}
					showStatusChange={showStatusChange}
					isChangingStatus={isChangingStatus}
					isSendingEmail={isSendingEmail}
					isEmailSent={isEmailSent}
					changeStatusError={changeStatusError}
					onShowEmail={handleShowEmail}
					onShowStatusChange={handleShowStatusChange}
					onCancelAction={handleCancelAction}
					onStatusChange={handleChangeStatusClick}
					onClearEmailSent={handleClearEmailSent}
					onSendEmail={handleSendEmail}
				/>
			);
		}
	};
	const renderLog = () => {
		if (opened != null) {
			const log = openedLog ?? [];
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("Common.StatusLog")}</div>
					</div>
					<OrderLog log={log} />
				</div>
			);
		}
	};
	const renderDocuments = () => {
		if (
			opened != null &&
			opened.book_status !== BOOKED_STATUS.PENDING &&
			opened.book_status !== BOOKED_STATUS.SUBMITTED
		) {
			return (
				<div className="order-block remove-top-space">
					<div className="order-subtitle">
						<div>{t("Common.Documents")}</div>
						<div>
							<span
								className="icon-add"
								onClick={() => handleShowUpload(true)}
							></span>
						</div>
					</div>
					{showUpload && (
						<OrderDocsForm
							onCancel={() => handleShowUpload(false)}
							onUpload={handleUpload}
							isLoading={isUploading}
							bookedOrder={opened}
						/>
					)}
					{docs != null ? (
						<OrderDocs docs={docs} bookedOrderId={opened.id} />
					) : (
						<></>
					)}
				</div>
			);
		}
	};
	const renderQuote = () => {
		if (
			opened != null &&
			opened.booked_method === BOOKING_METHOD.DSVROAD &&
			opened.book_status === BOOKED_STATUS.PENDING
		) {
			return (
				<>
					<div className="order-actions-title">
						<div>
							<div>
								<span className="icon-expand"></span>
							</div>
							<div className="action-title">Submit Quote</div>
							<div>
								<span className="icon-close" onClick={handleCloseClick}></span>
							</div>
						</div>
					</div>
					{renderOrderInfo()}
					<CarrierBookedOrderDetailsQuote />
				</>
			);
		}
	};
	const renderSubmitted = () => {
		if (
			opened != null &&
			opened.booked_method === BOOKING_METHOD.DSVROAD &&
			opened.book_status === BOOKED_STATUS.SUBMITTED
		) {
			return (
				<>
					<div className="order-actions-title">
						<div>
							<div>
								<span className="icon-expand"></span>
							</div>
							<div className="action-title">Quote Submitted</div>
							<div>
								<span className="icon-close" onClick={handleCloseClick}></span>
							</div>
						</div>
					</div>
					<div className="order-block">
						<div>Waiting for operators acceptance</div>
					</div>
					{renderOrderInfo()}
				</>
			);
		}
	};
	if (isOpening) {
		return (
			<div className="order-actions">
				<div className="order-block">
					<Loader msg={t("Common.Loading")} />
				</div>
			</div>
		);
	}
	if (!isOpening && opened != null) {
		return (
			<div className="order-actions">
				{renderQuote()}
				{renderSubmitted()}
				{renderBookedInfo()}
				{renderActions()}
				{renderLog()}
				{renderDocuments()}
			</div>
		);
	}
	return null;
}
