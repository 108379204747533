import { fetchCarriers } from "features/admin/carriersSlice";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarItem from "./SidebarItem";
import { toggleShowMobile } from "features/navigation/navigationSlice";

export default function SidebarAdmin() {
	const dispatch = useDispatch();
	// Selectors
	const { showMobile } = useSelector((state) => state.navigation);
	// Refs
	const sideBarRef = useRef();
	// Effect
	useEffect(() => {
		if (showMobile) {
			sideBarRef.current.focus();
		}
	}, [showMobile]);
	// Handlers
	const handleBlur = () => {
		if (showMobile) dispatch(toggleShowMobile({ showMobile: true }));
	};
	const handleMouseDown = (e) => {
		if (showMobile) {
			e.preventDefault();
			e.stopPropagation();
		}
		return;
	};
	// Render
	const getSidebarClass = () => {
		if (showMobile) {
			return "sidebar show";
		}
		return "sidebar";
	};
	const sidebarClass = getSidebarClass();
	return (
		<div
			className={sidebarClass}
			tabIndex={1}
			onMouseDown={handleMouseDown}
			onBlur={handleBlur}
			ref={sideBarRef}
		>
			<div>
				<SidebarItem el="/admin/users" icon="icon-users" text="Sidebar.Users" />
				<SidebarItem
					el="/admin/carriers"
					icon="icon-carriers"
					text="Sidebar.Carriers"
					action={() => dispatch(fetchCarriers())}
				/>
				<SidebarItem
					el="/admin/catalogs"
					icon="icon-catalogs"
					text="Sidebar.Catalogs"
				/>
			</div>
		</div>
	);
}
