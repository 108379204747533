import React from "react";
import { useTranslation } from "react-i18next";
import { CONTACT_TYPE, PROFILES } from "features/constants";

/**
 * Renders order references
 */
export default function OrderRefs({
	order,
	onEdit,
	consolidate,
	edit,
	profile,
}) {
	// Translation
	const { t } = useTranslation();
	// Render
	/* Gets order's forwarder contact */
	const getContact = () => {
		if (order?.contacts == null) return {};
		const filtered = order?.contacts.filter(
			(el) => Number(el.contact_type) === CONTACT_TYPE.FORWARDER
		);
		const item = filtered.length > 0 ? filtered[0] : {};
		return item;
	};
	const renderEditButton = () => {
		if (order != null && edit == null && consolidate) {
			return (
				<div>
					<span className="icon-edit" onClick={() => onEdit()}></span>
				</div>
			);
		}
		return null;
	};
	/* Renders formatted email */
	const renderEmail = (email) => {
		if (email != null) {
			return (
				<div>
					<a href={"mailto:" + email}>{email}</a>
				</div>
			);
		}
		return <div>n/a</div>;
	};
	/* If user is a carrier renders the forwarder's name */
	const renderForwarderName = () => {
		if (profile === PROFILES.CARRIER) {
			return (
				<div>
					<div>{t("Tables.Forwarder")}</div>
					<div>{order?.forwarder_name ?? "n/a"}</div>
				</div>
			);
		}
		return null;
	};
	const contact = getContact();
	return (
		<div className="order-refs">
			<div className="order-subtitle">
				<div>{t("Common.References")}</div>
				{renderEditButton()}
			</div>
			<div className="flex-table remove-bottom-space">
				<div>
					<div>
						<div>{t("Tables.ReferenceNumber")}</div>
						<div>{order?.alternate_reference_number ?? "n/a"}</div>
					</div>
					<div>
						<div>{t("Tables.MasterBill")}</div>
						<div>{order?.master_bill_number ?? "n/a"}</div>
					</div>
					<div>
						<div>{t("Tables.HouseBill")}</div>
						<div>{order?.house_bill_number ?? "n/a"}</div>
					</div>
				</div>
			</div>
			<div className="flex-table single-line">
				<div>
					<div>
						<div>{t("Tables.DedicatedOrder")}</div>
						<div>{order?.is_dedicated === true ? t("Common.Yes"): t("Common.No")}</div>
					</div>
				</div>
			</div>
			<div className="order-subtitle">
				<div>{t("Tables.Forwarder")}</div>
			</div>
			<div className="flex-table">
				<div>
					<div>
						<div>{t("Common.Name")}</div>
						<div>{contact?.name ?? "n/a"}</div>
					</div>
					<div>
						<div>{t("Common.Phone")}</div>
						<div>{contact?.phone ?? "n/a"}</div>
					</div>
					<div>
						<div>{t("Common.Email")}</div>
						{renderEmail(contact?.email)}
					</div>
				</div>
				<div>
					{renderForwarderName()}
					<div>
						<div>{t("Common.Branch")}</div>
						<div>{order?.forwarder_branch ?? "n/a"}</div>
					</div>
					<div>
						<div>{t("Common.Department")}</div>
						<div>{order?.forwarder_department ?? "n/a"}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
