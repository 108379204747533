import { BOOKED_STATUS } from "features/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { renderDate } from "utils/dateUtils";

export default function OrderLog({ log }) {
	// Translation
	const { t } = useTranslation();
	return (
		<div className="dsv-table no-fixed">
			<table cellPadding="0" cellSpacing="0">
				<thead>
					<tr>
						<th>{t("Tables.Status")}</th>
						<th>{t("Common.Date")}</th>
						<th>{t("Tables.SpecifiedDate")}</th>
						<th>{t("Common.Location")}</th>
						<th>{t("Common.Notes")}</th>
					</tr>
				</thead>
				<tbody>
					{log.map((el, index) => {
						return (
							<tr key={index}>
								<td>{t(BOOKED_STATUS.properties[el.book_status].name)}</td>
								<td>{renderDate(el.created_at, t("Dates.DateTime"))}</td>
								<td>{renderDate(el.date_specified, t("Dates.DateTime"))}</td>
								<td>{el.location ?? <>&nbsp;</>}</td>
								<td>{el.notes ?? <>&nbsp;</>}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
