import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCarrierHistoryActiveTab } from "features/carrier/carrierHistorySlice";
import { PROFILES } from "features/constants";
import OrderHeader from "components/common/order/OrderHeader";

export default function CarrierOrderHistoryDetailsHeader({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const dispatch = useDispatch();
	// Selectors
	const { isOpening, opened, tabs, activeTab } = useSelector(
		(state) => state.carrierHistory
	);
	// Handlers
	const handleTabClick = (tab) => {
		dispatch(setCarrierHistoryActiveTab({ activeTab: tab }));
	};
	// Render
	const order =
		opened != null
			? activeTab === 0
				? opened?.order_requests[activeTab] ?? null
				: opened?.order_requests[activeTab - 1] ?? null
			: null;
	return (
		<OrderHeader
			order={order}
			edit={null}
			isLoading={isOpening}
			consolidate={false}
			tabs={opened?.order_count > 1 ? tabs : null}
			activeTab={activeTab}
			onClose={onClose}
			onCollapse={onCollapse}
			onExpand={onExpand}
			onEdit={() => {}}
			onTabClick={handleTabClick}
			collapse={collapse}
			profile={PROFILES.CARRIER}
			booked={true}
			bookedOrder={opened}
			file_number={opened?.file_number}
		/>
	);
}
