import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	getQuote,
	postQuoteCalculation,
	postQuoteCreation,
	postQuoteList,
} from "services/quoteServices";

const initialState = {
	isLoading: false,
	list: null,
	refresh: false,
	showNewQuote: false,
	openQuote: null,
	isLoadingQuote: false,
	rates: null,
	isCalculating: false,
	calculationData: null,
	isSaving: false,
};

/* Fetch quote list with filters */
export const fetchQuotes = createAsyncThunk(
	"operatorQuotes/fetchQuotes",
	async (payload, { rejectWithValue, getState }) => {
		try {
			const { filters, page } = payload;
			const activePage =
				page ?? getState().operatorQuotes.list?.current_page ?? 1;
			const activeFilters = filters ?? {};
			const response = await postQuoteList(activeFilters, activePage);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

/* Fetch quote details */
export const fetchQuoteDetails = createAsyncThunk(
	"operatorQuotes/fetchQuoteDetails",
	async (payload) => {
		const { id } = payload;
		const response = getQuote(id);
		return response;
	}
);

/* Fetch postal code search. */
export const fetchQuoteCalculation = createAsyncThunk(
	"operatorQuotes/fetchQuoteCalculation",
	async (payload) => {
		const { data } = payload;
		const response = await postQuoteCalculation(data);
		return { ...response, calculationData: data };
	}
);

/* Fetch quote creation */
export const fetchQuoteCreation = createAsyncThunk(
	"operatorQuotes/fetchQuoteCreation",
	async (payload) => {
		const { data } = payload;
		const response = await postQuoteCreation(data);
		return response;
	}
);

const operatorQuotesSlice = createSlice({
	name: "operatorQuotes",
	initialState,
	reducers: {
		setOperatorOpenQuote: (state, {payload} ) => {
			state.openQuote = payload.openQuote ?? null;
		},
		setOperatorShowNewQuote: (state, { payload }) => {
			state.showNewQuote = payload.showNewQuote ?? false;
		},
		setOperatorQuotesRefresh: (state, { payload }) => {
			state.refresh = payload.refresh ?? false;
		},
		clearOperatorQuoteRates: (state) => {
			state.rates = null;
			state.calculationData = null;
		},
		clearOperatorQuotesData: () => initialState,
	},
	/* Use extra reducers for async executions. */
	extraReducers: (builder) => {
		builder
			/* fetchQuotes */
			.addCase(fetchQuotes.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchQuotes.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.list = payload.data.data;
				state.refresh = false;
			})
			.addCase(fetchQuotes.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.refresh = false;

				if (
					payload != null &&
					payload?.status != null &&
					payload.status === 403
				)
					state.list = null;
				else state.list = [];
			})
			/* fetchQuoteDetails */
			.addCase(fetchQuoteDetails.pending, (state) => {
				state.isLoadingQuote = true;
			})
			.addCase(fetchQuoteDetails.fulfilled, (state, { payload }) => {
				state.isLoadingQuote = false;
				state.openQuote = payload.data.data;
			})
			.addCase(fetchQuoteDetails.rejected, (state) => {
				state.isLoadingQuote = false;
				state.openQuote = null;
			})
			/* fetchQuoteCalculation */
			.addCase(fetchQuoteCalculation.pending, (state) => {
				state.isCalculating = true;
				state.rates = null;
			})
			.addCase(fetchQuoteCalculation.fulfilled, (state, { payload }) => {
				state.isCalculating = false;
				state.rates = payload.data.data;
				state.calculationData = payload.calculationData;
			})
			.addCase(fetchQuoteCalculation.rejected, (state) => {
				state.isLoading = false;
				state.isCalculating = false;
				state.rates = null;
			})
			/* fetchQuoteCreation */
			.addCase(fetchQuoteCreation.pending, (state) => {
				state.isCalculating = true;
				state.isSaving = true;
			})
			.addCase(fetchQuoteCreation.fulfilled, (state, { payload }) => {
				state.isCalculating = false;
				state.isSaving = false;
				state.refresh = true;
				state.calculationData = null;
				state.rates = null;
				state.openQuote = payload.data.data;
			})
			.addCase(fetchQuoteCreation.rejected, (state) => {
				state.isCalculating = false;
				state.isSaving = false;
			});
	},
});

export const {
	setOperatorOpenQuote,
	setOperatorShowNewQuote,
	setOperatorQuotesRefresh,
	clearOperatorQuoteRates,
	clearOperatorQuotesData,
} = operatorQuotesSlice.actions;

export default operatorQuotesSlice.reducer;
