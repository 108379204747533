import client from "./networking";

/**
 * * Operator Order services
 */

/**
 * Gets operator order list
 * @returns Instance `AxiosResponse`.
 */
export const getOperatorOrders = (page = 1, filters = {}) => {
  const body = JSON.stringify({
    filters: filters,
  });
  return client.post(`api/order_requests?page=${page}`, body);
}

/**
 * Get operator order details.
 * @param {*} id Order id.
 * @returns Instance `AxiosResponse`.
 */
export const getOperatorOrderDetails = (id) => {
  return client.get(`api/order_requests/${id}`);
}

/**
 * Get operator order similars.
 * @param {*} id Order id.
 * @returns Instance `AxiosResponse`.
 */
export const getOperatorOrderSimilars = (id) => {
  return client.get(`api/order_requests/${id}/similars`);
}

/**
 * Get operator order cart
 * @param {*} ids
 * @returns Instance `AxiosResponse`.
 */
export const getOperatorOrderCart = (primary, ids) => {
  const body = JSON.stringify({
    order_request_ids: ids,
    primary_order_request_id: primary,
  });
  return client.post("/api/booking_carts", body);
}

/**
 * Put operator order header info
 * @param {*} orderId
 * @param {*} formData
 * @returns Instance `AxiosResponse`.
 */
export const putHeaderInfo = (orderId, formData) => {
	const body = JSON.stringify(formData);
  return client.put(`/api/order_requests/${orderId}/header_info`, body);
}

/**
 * Put operator order address info
 * @param {*} orderId Id de la orden a modificar.
 * @param {*} formData Instancia `FormData` https://developer.mozilla.org/en-US/docs/Web/API/FormData
 * @returns Instance `AxiosResponse`.
 */
export const putAddressInfo = (orderId, formData) => {
	const body = JSON.stringify(formData);
  return client.put(`/api/order_requests/${orderId}/address_info`, body);
}

/**
 * Put operator order cargo info
 * @param {int} orderId
 * @param {object} formData
 * @returns Instance `AxiosResponse`.
 */
export const putCargoInfo = (orderId, formData) => {
	const body = JSON.stringify(formData);
  return client.put(`/api/order_requests/${orderId}/cargo_info`, body);
}

/**
 * Put Booked Order Trucker Info
 * @param {int} orderId 
 * @param {object} formData 
 * @returns Instance `AxiosResponse`.
 */
export const putTruckerInfo = (orderId, formData) => {
	return client.put(`/api/booked_orders/${orderId}/truck_info`, formData);
};

/**
 * Put Order Request Broker Info
 * @param {int} orderId 
 * @param {object} formData 
 * @returns Instance `AxiosResponse`.
 */
export const putBrokerInfo = (orderId, formData) => {
	return client.put(`/api/order_requests/${orderId}/broker_info`, formData);
}

/**
 * Put Order Request Container Info
 * @param {int} orderId 
 * @param {object} formData 
 * @returns 
 */
export const putContainerInfo = (orderId, formData) => {
	return client.put(`/api/order_requests/${orderId}/container_info`, formData);
}

/**
 * Put Booked Order Notify Parties
 * @param {int} orderId 
 * @param {object} formData 
 * @returns Instance `AxiosResponse`.
 */
export const putBookedOrderNotifyParties = (orderId, formData) => {
	return client.put(`/api/booked_orders/${orderId}/email_party`, formData);
};
/**
 * Get DSV Road's carrier list
 * @returns Instance `AxiosResponse`.
 */
export const getSelfCarriersList = () => {
  return client.get("/api/carriers/from_same_company");
}

/**
 * Accept price check quote
 * @param {int} orderId 
 * @param {object} formData 
 * @returns Instance `AxiosResponse`.
 */
export const postOperatorQuoteAccept = (orderId, formData) => {
	return client.post(`/api/booked_orders/${orderId}/accept_quote`, formData);
};

/**
 * Reject price check quote
 * @param {int} orderId 
 * @param {object} formData 
 * @returns 
 */
export const postOperatorQuoteReject = (orderId, formData) => {
	return client.post(`/api/booked_orders/${orderId}/reject_quote`, formData);
}