import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CURRENCY } from "features/constants";
import { toSelector } from "utils/constantsUtils";
import {
	fetchCarrierOrderQuote,
	fetchCarrierOrderReject,
} from "features/carrier/carrierOrdersSlice";
import Loader from "components/Loader";

export default function CarrierBookedOrderDetailsQuote() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { opened, isSubmittingQuote } = useSelector(
		(state) => state.carrierOrders
	);
	const { user } = useSelector((state) => state.session);
	// State
	const [activeOption, setActiveOption] = useState(null);
	const [quoteNumber, setQuoteNumber] = useState("");
	const [currency, setCurrency] = useState({
		label: CURRENCY.properties[user?.user?.carrier?.currency ?? 1].code,
		value: user?.user?.carrier?.currency ?? 1,
	});
	const [price, setPrice] = useState("");
	const [notes, setNotes] = useState("");
	const [reason, setReason] = useState("");
	const handleSubmit = () => {
		dispatch(
			fetchCarrierOrderQuote({
				id: opened.id,
				data: {
					public_id: opened.public_id,
					number: quoteNumber,
					price,
					notes: notes !== "" ? notes : undefined,
					currency: currency.value,
				},
			})
		);
	};
	const handleReject = () => {
		if (reason !== "" && reason.length > 5) {
			dispatch(
				fetchCarrierOrderReject({
					id: opened.id,
					data: {
						public_id: opened.public_id,
						notes: reason,
					},
				})
			);
		}
	};
	const handleOptionClick = (option) => {
		if (activeOption === option) {
			setActiveOption(null);
		} else {
			setActiveOption(option);
		}
	};
	const handleCurrencyChange = (option) => {
		setCurrency(option);
	};
	// Render
	const optionStyles = {
		quote:
			activeOption === 0
				? {
						option: { className: "accordion-option active" },
						icon: { className: "icon-chevron expanded" },
				  }
				: {
						option: { className: "accordion-option" },
						icon: { className: "icon-chevron" },
				  },
		reject:
			activeOption === 1
				? {
						option: { className: "accordion-option active" },
						icon: { className: "icon-chevron expanded" },
				  }
				: {
						option: { className: "accordion-option" },
						icon: { className: "icon-chevron" },
				  },
	};
	const currencyOptions = toSelector(CURRENCY.properties, true, false);
	if (isSubmittingQuote) {
		return <Loader />;
	}
	return (
		<div className="order-block">
			<div className="dsv-accordion">
				<div {...optionStyles.quote.option}>
					<div
						className="accordion-button"
						onClick={() => handleOptionClick(0)}
					>
						<div>{t("CarrierBookedOrders.QuoteTitle")}</div>
						<div>
							<span {...optionStyles.quote.icon}></span>
						</div>
					</div>
					<div className="accordion-content">
						<div className="dsv-form">
							<div className="a">
								<div className="order-subtitle">
									<div>{t("CarrierBookedOrders.QuoteInfo")}</div>
								</div>
							</div>
							<div className="a field">
								<label>{t("Fields.QuoteNumber")}</label>
								<input
									type="text"
									id="quote-number"
									name="quote-number"
									placeholder={t("Fields.QuoteNumber")}
									value={quoteNumber}
									onChange={(e) => setQuoteNumber(e.target.value)}
								/>
							</div>
							<div className="b-1 field">
								<label>{t("Common.Price")}</label>
								<input
									type="text"
									id="price"
									name="price"
									placeholder={t("Common.Price")}
									value={price}
									onChange={(e) => setPrice(e.target.value)}
								/>
							</div>
							<div className="b-2 field">
								<label>{t("Fields.Currency")}</label>
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									value={currency}
									isClearable={false}
									options={currencyOptions}
									onChange={handleCurrencyChange}
								/>
							</div>
							<div className="a field">
								<label>{t("Common.Notes")}</label>
								<textarea
									name="notes"
									id="notes"
									placeholder={t("Common.Notes")}
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
								></textarea>
							</div>
							<div className="a top-separation">
								<div onClick={handleSubmit} className="button main">
									{t("Buttons.SubmitQuote")}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div {...optionStyles["reject"].option}>
					<div
						className="accordion-button secondary"
						onClick={() => handleOptionClick(1)}
					>
						<div>{t("CarrierBookedOrders.Reject")}</div>
						<div>
							<span {...optionStyles["reject"].icon}></span>
						</div>
					</div>
					<div className="accordion-content">
						<div className="dsv-form">
							<div className="a field">
								<label>{t("Fields.RejectReason")}</label>
								<textarea
									name="reason"
									id="reason"
									value={reason}
									onChange={(e) => setReason(e.target.value)}
									placeholder={t("Fields.RejectReason")}
								></textarea>
							</div>
							<div className="a top-separation">
								<div onClick={handleReject} className="button main">
									{t("Buttons.RejectNow")}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
