import React from "react";
import CarrierDetailsActions from "./CarrierBookedOrderDetailsActions";
import CarrierDetailsAddress from "./CarrierBookedOrderDetailsAddress";
import CarrierDetailsCargo from "./CarrierBookedOrderDetailsCargo";
import CarrierDetailsHeader from "./CarrierBookedOrderDetailsHeader";
import CarrierDetailsTruckerInfo from "./CarrierBookedOrderDetailsTruckerInfo";
import CarrierDetailsTruckerInfoEdit from "./CarrierBookedOrderDetailsTruckerInfoEdit";

export default function CarrierBookedOrderDetails({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const itemProps = {
		onClose: onClose,
		onCollapse: onCollapse,
		onExpand: onExpand,
		collapse: collapse,
	};
	return (
		<div className="order-details">
			<div className="order-info">
				<CarrierDetailsHeader {...itemProps} />
				<CarrierDetailsTruckerInfo />
				<CarrierDetailsTruckerInfoEdit />
				<CarrierDetailsAddress />
				<CarrierDetailsCargo />
			</div>
			<CarrierDetailsActions {...itemProps} />
		</div>
	);
}
