import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toLaneForm } from "utils/carrierUtils";
import classNames from "classnames";
import { portCodeRegEx } from "utils/regExs";
import Loader from "components/Loader";
import { CARRIER_MODE, CARTAGE_PORT } from "features/constants";
import Select from "react-select";
import { toSelector } from "utils/constantsUtils";
import { currencyRegEx } from "utils/regExs";

export default function CarrierLanesAdd({
	lane,
	zonesCatalog,
	isSavingLane,
	onSave,
	onAdd,
	onClose,
}) {
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm();

	watch("carrier_mode");
	watch("cartage_port");

	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleSave = (data, event) => {
		// TODO: Validate form & fetch to api with dispatch
		event.preventDefault();

		// Set flags
		const carrierMode = data.carrier_mode.value;
		const cartagePort = data.cartage_port.value;

		// Build payload
		const formData =
			carrierMode === CARRIER_MODE.CARTAGE
				? {
						// Cartage
						name:
							cartagePort === CARTAGE_PORT.ORIGIN
								? `${data.origin_port}-${data.destination_zone_id.label}`
								: `${data.origin_zone_id.label}-${data.destination_port}`,
						origin_port:
							cartagePort === CARTAGE_PORT.ORIGIN
								? data.origin_port
								: undefined,
						origin_zone_id:
							cartagePort === CARTAGE_PORT.ORIGIN
								? undefined
								: data.origin_zone_id.value,
						destination_port:
							cartagePort === CARTAGE_PORT.DESTINATION
								? data.destination_port
								: undefined,
						destination_zone_id:
							cartagePort === CARTAGE_PORT.DESTINATION
								? undefined
								: data.destination_zone_id.value,
						carrier_mode: carrierMode,
						transit_time: parseFloat(data.transit_time ?? 0),
						w_m_rate: parseFloat(data.w_m_rate ?? 0),
				  }
				: {
						//LTL
						name: `${data.origin_zone_id.label}-${data.destination_zone_id.label}`,
						origin_port: undefined,
						origin_zone_id: data.origin_zone_id.value,
						destination_port: undefined,
						destination_zone_id: data.destination_zone_id.value,
						carrier_mode: carrierMode,
						transit_time: parseFloat(data.transit_time ?? 0),
						w_m_rate: parseFloat(data.w_m_rate ?? 0),
				  };

		if (lane && lane.id) {
			onSave(formData);
		} else {
			onAdd(formData);
		}
	};
	const handleClose = () => {
		onClose();
	};

	// Effect runs once on display.
	useEffect(() => {
		if (lane) {
			reset(toLaneForm(lane, zonesCatalog));
		} else {
			setValue("carrier_mode", {
				value: CARRIER_MODE.CARTAGE,
				label: t(CARRIER_MODE.properties[`${CARRIER_MODE.CARTAGE}`].name),
			});

			setValue("cartage_port", {
				value: CARTAGE_PORT.ORIGIN,
				label: t(CARTAGE_PORT.properties[`${CARTAGE_PORT.ORIGIN}`].name),
			});
		}
	}, [lane, reset, setValue, zonesCatalog, dispatch, t]);

	if (isSavingLane) {
		return (
			<div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}

	return (
		<div className="tab-content details">
			<div className="dsv-form collapse">
				<div className="b-1">
					<div className="details-subtitle">
						<div>{t("AdminCarriers.LaneAdd")}</div>
					</div>
				</div>
				<div
					className={classNames("b-11 field", {
						error: errors.carrier_mode,
					})}
				>
					<label>{t("Fields.CarrierMode")}</label>
					<Controller
						name="carrier_mode"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { onChange, value } }) => (
							<Select
								className="dsv-select"
								classNamePrefix="dsv-select"
								isClearable={false}
								onChange={onChange}
								value={
									value?.value
										? {
												value: CARRIER_MODE.properties[`${value.value}`].value,
												label: t(
													CARRIER_MODE.properties[`${value.value}`].name
												),
										  }
										: {}
								}
								options={toSelector(CARRIER_MODE.properties)}
							/>
						)}
					/>
				</div>
				{getValues("carrier_mode")?.value === CARRIER_MODE.CARTAGE ? (
					<div
						className={classNames("b-12 field", {
							error: errors.cartage_port,
						})}
					>
						<label>{t("Fields.Port")}</label>
						<Controller
							name="cartage_port"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field: { onChange, value } }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									onChange={onChange}
									value={
										value?.value
											? {
													value:
														CARTAGE_PORT.properties[`${value.value}`].value,
													label: t(
														CARTAGE_PORT.properties[`${value.value}`].name
													),
											  }
											: {}
									}
									options={toSelector(CARTAGE_PORT.properties)}
								/>
							)}
						/>
					</div>
				) : (
					<></>
				)}
				{getValues("carrier_mode")?.value === CARRIER_MODE.CARTAGE &&
				getValues("cartage_port")?.value === CARTAGE_PORT.ORIGIN ? (
					<div
						className={classNames("b-11 field", {
							error: errors.origin_port,
						})}
					>
						<label>{t("Fields.OriginPort")}</label>
						<input
							type="text"
							placeholder={t("Fields.OriginPort")}
							{...register("origin_port", {
								required: true,
								pattern: portCodeRegEx,
								onChange: (e) => {
									const clean = e.target.value
										.toUpperCase()
										.replace(/[^A-Z]/, "")
										.substring(0, 3);
									setValue("origin_port", clean);
								},
							})}
						/>
					</div>
				) : (
					<></>
				)}
				{getValues("carrier_mode")?.value === CARRIER_MODE.LTL ||
				(getValues("carrier_mode")?.value === CARRIER_MODE.CARTAGE &&
					getValues("cartage_port")?.value === CARTAGE_PORT.DESTINATION) ? (
					<div
						className={classNames("b-11 field", {
							error: errors.origin_zone_id,
						})}
					>
						<label>{t("Fields.OriginZone")}</label>
						<Controller
							name="origin_zone_id"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									{...field}
									options={zonesCatalog?.map((el, index) => {
										return {
											value: el.id,
											label: el.name,
										};
									})}
								/>
							)}
						/>
					</div>
				) : (
					<></>
				)}
				{getValues("carrier_mode")?.value === CARRIER_MODE.CARTAGE &&
				getValues("cartage_port")?.value === CARTAGE_PORT.DESTINATION ? (
					<div
						className={classNames("b-12 field", {
							error: errors.destination_port,
						})}
					>
						<label>{t("Fields.DestinationPort")}</label>
						<input
							type="text"
							placeholder={t("Fields.DestinationPort")}
							{...register("destination_port", {
								required: true,
								pattern: portCodeRegEx,
								onChange: (e) => {
									const clean = e.target.value
										.toUpperCase()
										.replace(/[^A-Z]/, "")
										.substring(0, 3);
									setValue("destination_port", clean);
								},
							})}
						/>
					</div>
				) : (
					<></>
				)}
				{getValues("carrier_mode")?.value === CARRIER_MODE.LTL ||
				(getValues("carrier_mode")?.value === CARRIER_MODE.CARTAGE &&
					getValues("cartage_port")?.value === CARTAGE_PORT.ORIGIN) ? (
					<div
						className={classNames("b-12 field", {
							error: errors.destination_zone_id,
						})}
					>
						<label>{t("Fields.DestinationZone")}</label>
						<Controller
							name="destination_zone_id"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field }) => (
								<Select
									className="dsv-select"
									classNamePrefix="dsv-select"
									isClearable={false}
									placeholder={t("Common.Select")}
									{...field}
									options={zonesCatalog?.map((el, index) => {
										return {
											value: el.id,
											label: el.name,
										};
									})}
								/>
							)}
						/>
					</div>
				) : (
					<></>
				)}
				<div
					className={classNames("b-11 field", {
						error: errors.transit_time,
					})}
				>
					<label>{t("Fields.TransitTime")}</label>
					<input
						type="text"
						placeholder={t("Fields.TransitTime")}
						{...register("transit_time", {
							required: true,
							pattern: {
								value: /^\d$/,
								message: "Value must be an integer.",
							},
						})}
					/>
				</div>{" "}
				<div
					className={classNames("b-12 field", {
						error: errors.w_m_rate,
					})}
				>
					<label>{t("Fields.WMRate")}</label>
					<input
						type="text"
						placeholder={t("Fields.WMRate")}
						{...register("w_m_rate", {
							pattern: {
								value: currencyRegEx,
								message: "Value must be a decimal.",
							},
						})}
					/>
				</div>
				<div className="b-11 top-separation">
					<label>&nbsp;</label>
					<div className="button" onClick={handleClose}>
						{t("Buttons.Cancel")}
					</div>
				</div>
				<div className="b-12 top-separation">
					<label>&nbsp;</label>
					<div className="button main" onClick={handleSubmit(handleSave)}>
						{t("Buttons.Save")}
					</div>
				</div>
				{/* {saveCarrierLaneErrors?.message !== null ? (
          <div className="a error-list">
            <ul>
              <li>{saveCarrierLaneErrors?.message}</li>;
            </ul>
          </div>
        ) : (
          <></>
        )} */}
			</div>
		</div>
	);
}
