import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import {
  fetchCarrierFuel,
  fetchAddCarrierFuel,
  fetchSetCarrierFuel,
  setCarrierFuelEdit,
} from "features/admin/carriersSlice";
import Loader from "components/Loader";
import { removeEmptyProps } from "utils/formUtils";

export default function CarrierDetailsFuelEdit({ carrier, fuel }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  // Selectors
  const { isLoadingFuel, fuels, isLoading } = useSelector(
    (state) => state.carriers
  );

  const dispatch = useDispatch();
  // Translation
  const { t } = useTranslation();
  // Handlers
  const handleSave = (data, event) => {
    // TODO: Validate form & fetch to api with dispatch
    event.preventDefault();
    if (data.fuels) {
      data.fuels = data.fuels?.map((item, index) => {
        return item.value;
      });
    }
    if (fuel == null) {
      dispatch(fetchAddCarrierFuel({ carrierId: carrier.id, data: removeEmptyProps(data) }));
    } else {
      dispatch(
        fetchSetCarrierFuel({
          carrierId: carrier.id,
          fuelId: fuel?.id,
          data: removeEmptyProps(data),
        })
      );
    }
  };
  const handleClose = () => {
    dispatch(setCarrierFuelEdit());
  };

  // Effect runs once on display.
  useEffect(() => {
    if (fuels === null && !isLoadingFuel) {
      dispatch(fetchCarrierFuel({ carrierId: carrier.id }));
    }
    reset({
      surcharge: fuel?.surcharge ?? undefined,
    });
  }, [reset, dispatch, isLoading, fuels, carrier, isLoadingFuel, fuel]);

  if (isLoadingFuel || isLoading) {
    return (
      <div>
        <Loader msg={t("Common.Saving")} />
      </div>
    );
  }

  return (
    <div className="tab-content details">
      <div className="dsv-form collapse">
        <div className="b-1">
          <div className="details-subtitle">
            <div>{t("AdminCarriers.Fuel")}</div>
          </div>
        </div>

        <div
          className={classNames("b-1 field", {
            error: errors.surcharge,
          })}
        >
          <label>{t("Fields.Surcharge")}</label>
          <input
            type="text"
            placeholder={t("Fields.Surcharge")}
            {...register("surcharge", { required: true })}
          />
        </div>

        <div className="b-11 top-separation">
          <label>&nbsp;</label>
          <div className="button" onClick={handleClose}>
            {t("Buttons.Cancel")}
          </div>
        </div>
        <div className="b-12 top-separation">
          <label>&nbsp;</label>
          <div className="button main" onClick={handleSubmit(handleSave)}>
            {t("Buttons.Save")}
          </div>
        </div>
      </div>
    </div>
  );
}
