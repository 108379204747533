import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderBroker from "components/common/order/OrderBroker";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";

const OperatorOrderDetailsBroker = () => {
	const dispatch = useDispatch();
	// Selector
	const { openOrder, orderEdit, isLoadingOpen } = useSelector(
		(state) => state.operatorOrders
	);
	// Handlers
	const handleEditClick = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.BROKER }));
	};
	if (openOrder == null) return null;
	const booked = openOrder?.booked_orders?.length > 0;
	return (
		<OrderBroker
			order={openOrder}
			edit={orderEdit}
			isLoading={isLoadingOpen}
			onEdit={handleEditClick}
			booked={booked}
		/>
	);
};

export default OperatorOrderDetailsBroker;
