import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toRateForm } from "utils/carrierUtils";
import { fetchPackagings } from "features/catalogs/packagingsSlice";
import { fetchEquipments } from "features/catalogs/equipmentsSlice";

import classNames from "classnames";
import Loader from "components/Loader";
import {
	CARRIER_MODE,
	CARGO_TYPE,
	RATE_TYPE,
	WEIGHT_UNIT,
	CURRENCY,
	VOLUME_UNIT,
	CALC_TYPE,
} from "features/constants";
import Select from "react-select";
import { toSelector } from "utils/constantsUtils";
import { currencyRegEx, decimalRegEx } from "utils/regExs";

export default function CarrierRatesAdd({
	onSave,
	onAdd,
	onClose,
	currency,
	rate,
	allLanes,
	isSavingRate,
	customers,
}) {
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm();

	watch("carrier_mode");
	watch("per_volume");
	watch("per_weight");
	watch("per_piece");
	watch("carrier_lane_id");
	watch("equipment_type_id");
	watch("hazmat_calc_type");

	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { packagings, isLoading } = useSelector((state) => state.packagings);
	const { equipments, isLoadingEquipments } = useSelector(
		(state) => state.equipments
	);

	// Effect
	useEffect(() => {
		if (packagings == null && !isLoading) {
			dispatch(fetchPackagings());
		}

		if (equipments == null && !isLoadingEquipments) {
			dispatch(fetchEquipments());
		}

		if (packagings != null && equipments != null && rate != null) {
			reset(toRateForm(rate, packagings, allLanes, equipments, customers));
		} else {
			// TODO: Move to default values function.
			setValue("carrier_mode", {
				value: CARRIER_MODE.CARTAGE,
				label: t(CARRIER_MODE.properties[`${CARRIER_MODE.CARTAGE}`].name),
			});
			setValue("per_weight", true);
		}
	}, [
		rate,
		reset,
		setValue,
		dispatch,
		packagings,
		isLoading,
		equipments,
		isLoadingEquipments,
		allLanes,
		customers,
		t,
	]);

	// Handlers
	const handleSave = (data, event) => {
		// TODO: Validate form & fetch to api with dispatch
		event.preventDefault();

		// remove nulls
		for (var propName in data) {
			if (
				data[propName] === null ||
				data[propName] === undefined ||
				data[propName] === ""
			) {
				delete data[propName];
			}
		}

		// Transform selectors data.
		data.cargo_type = data?.cargo_type?.value ?? data.cargo_type;
		data.carrier_mode = data?.carrier_mode?.value ?? data.cargo_type;
		data.rate_type = data?.rate_type?.value ?? data.rate_type;
		data.carrier_lane_id = data?.carrier_lane_id?.value ?? data.carrier_lane_id;
		data.volume_unit = data?.volume_unit?.value ?? data.volume_unit;
		data.weight_unit = data?.weight_unit?.value ?? data.weight_unit;
		data.piece_unit = data?.piece_unit?.value ?? data.piece_unit;
		data.equipment_type_id =
			data?.equipment_type_id?.value ?? data.equipment_type_id;
		data.rate = parseFloat(data.rate);

		data.customer_id = data?.customer_id?.value ?? data.customer_id;

		// data.currency = data?.currency?.value ?? data.currency;
		data.hazmat_calc_type =
			data?.hazmat_calc_type?.value ?? data.hazmat_calc_type;
		if (rate && rate.id) {
			onSave(data);
		} else {
			onAdd(data);
		}
	};
	const handleClose = () => {
		if (onClose != null) {
			onClose();
		}
	};

	if (isSavingRate || isLoading) {
		return (
			<div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}

	return (
		<div className="dsv-form collapse">
			<div className="b-1">
				<div className="details-subtitle">
					<div>Add New Rate</div>
				</div>
			</div>
			<div
				className={classNames("b-11 field", {
					error: errors.cargo_type,
				})}
			>
				<label>{t("CargoTypes.Title")}</label>
				<Controller
					name="cargo_type"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, value } }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={false}
							placeholder={t("Common.Select")}
							onChange={onChange}
							value={
								value?.value
									? {
											value: CARGO_TYPE.properties[`${value.value}`].value,
											label: t(CARGO_TYPE.properties[`${value.value}`].name),
										}
									: null
							}
							options={toSelector(CARGO_TYPE.properties)}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-12 field", {
					error: errors.carrier_mode,
				})}
			>
				<label>{t("Fields.CarrierMode")}</label>
				<Controller
					name="carrier_mode"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, value } }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={false}
							onChange={onChange}
							placeholder={t("Common.Select")}
							value={
								value?.value
									? {
											value: CARRIER_MODE.properties[`${value.value}`].value,
											label: t(
												CARRIER_MODE.properties[`${value.value}`].name
											),
										}
									: null
							}
							options={toSelector(CARRIER_MODE.properties)}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-21 field", {
					error: errors.rate_type,
				})}
			>
				<label>{t("Fields.RateType")}</label>
				<Controller
					name="rate_type"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, value } }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={false}
							onChange={onChange}
							placeholder={t("Common.Select")}
							value={
								value?.value
									? {
											value: RATE_TYPE.properties[`${value.value}`].value,
											label: t(RATE_TYPE.properties[`${value.value}`].name),
										}
									: null
							}
							options={toSelector(RATE_TYPE.properties)}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-22 field", {
					error: errors.carrier_lane_id,
				})}
			>
				<label>{t("Fields.Lane")}</label>
				<Controller
					name="carrier_lane_id"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={false}
							placeholder={t("Common.Select")}
							{...field}
							options={allLanes?.map((el) => {
								return {
									value: el.id,
									label: el.name,
								};
							})}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-11 field", {
					error: errors.volume_unit,
				})}
			>
				<label>{t("VolumeUnits.Title")}</label>
				<Controller
					name="volume_unit"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, value } }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={false}
							onChange={onChange}
							placeholder={t("Common.Select")}
							value={
								value?.value
									? {
											value: VOLUME_UNIT.properties[`${value.value}`].value,
											label: t(VOLUME_UNIT.properties[`${value.value}`].name),
										}
									: null
							}
							options={toSelector(VOLUME_UNIT.properties, false, true)}
						/>
					)}
				/>
			</div>

			<div className="b-12 check-field">
				<input
					type="checkbox"
					name="per_volume"
					id="per_volume"
					{...register("per_volume", {
						validate: function (value, formValues) {
							const per_weight = getValues(`per_weight`);
							const per_volume = getValues(`per_volume`);
							const per_piece = getValues(`per_piece`);
							return (
								per_weight === true ||
								per_volume === true ||
								per_piece === true
							);
						},
					})}
				/>
				<label htmlFor="per_volume">
					<span className="icon-check"></span>
					{t("Fields.VolumeRange")}
				</label>
			</div>

			{getValues("per_volume") === true && (
				<>
					<div
						className={classNames("b-221 field", {
							error: errors.volume_min,
						})}
					>
						<label>{t("Common.Min")}</label>
						<input
							type="text"
							placeholder={t("Common.Min")}
							{...register("volume_min", {
								required: true,
							})}
						></input>
					</div>
					<div
						className={classNames("b-222 field", {
							error: errors.volume_max,
						})}
					>
						<label>{t("Common.Max")}</label>
						<input
							type="text"
							placeholder={t("Common.Max")}
							{...register("volume_max", {
								required: true,
							})}
						></input>
					</div>
				</>
			)}
			<div
				className={classNames("b-11 field", {
					error: errors.weight_unit,
				})}
			>
				<label>{t("WeightUnits.Title")}</label>
				<Controller
					name="weight_unit"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, value } }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={false}
							onChange={onChange}
							placeholder={t("Common.Select")}
							value={
								value?.value
									? {
											value: WEIGHT_UNIT.properties[`${value.value}`].value,
											label: t(WEIGHT_UNIT.properties[`${value.value}`].name),
										}
									: null
							}
							options={toSelector(WEIGHT_UNIT.properties, false, true)}
						/>
					)}
				/>
			</div>

			<div className="b-12 check-field">
				<input
					type="checkbox"
					name="per_weight"
					id="per_weight"
					{...register("per_weight", {
						validate: function (value, formValues) {
							const per_volume = getValues(`per_volume`);
							const per_weight = getValues(`per_weight`);
							const per_piece = getValues(`per_piece`);
							return (
								per_volume === true ||
								per_weight === true ||
								per_piece === true
							);
						},
					})}
				/>
				<label htmlFor="per_weight">
					<span className="icon-check"></span>
					{t("Fields.WeightRange")}
				</label>
			</div>

			{getValues("per_weight") === true && (
				<>
					<div
						className={classNames("b-221 field", {
							error: errors.weight_min,
						})}
					>
						<label>{t("Common.Min")}</label>
						<input
							type="text"
							placeholder={t("Common.Min")}
							{...register("weight_min", {
								required: true,
							})}
						></input>
					</div>
					<div
						className={classNames("b-222 field", {
							error: errors.weight_max,
						})}
					>
						<label>{t("Common.Max")}</label>
						<input
							type="text"
							placeholder={t("Common.Max")}
							{...register("weight_max", {
								required: true,
							})}
						></input>
					</div>
				</>
			)}

			<div
				className={classNames("b-11 field", {
					error: errors.piece_unit,
				})}
			>
				<label>{t("Tables.Packaging")}</label>
				<Controller
					name="piece_unit"
					control={control}
					render={({ field }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							placeholder={t("Common.Select")}
							isClearable={false}
							{...field}
							options={packagings?.map((el) => {
								return {
									value: el.id,
									label: el.name,
								};
							})}
						/>
					)}
				/>
			</div>

			<div className="b-12 check-field">
				<input
					type="checkbox"
					name="per_piece"
					id="per_piece"
					{...register("per_piece", {})}
				/>
				<label htmlFor="per_piece">
					<span className="icon-check"></span>
					{t("Fields.PieceRange")}
				</label>
			</div>

			{getValues("per_piece") === true && (
				<>
					<div
						className={classNames("b-221 field", {
							error: errors.piece_min,
						})}
					>
						<label>{t("Common.Min")}</label>
						<input
							type="text"
							placeholder={t("Common.Min")}
							{...register("piece_min", {
								required: true,
							})}
						></input>
					</div>
					<div
						className={classNames("b-222 field", {
							error: errors.piece_min,
						})}
					>
						<label>{t("Common.Max")}</label>
						<input
							type="text"
							placeholder={t("Common.Max")}
							{...register("piece_max", {
								required: true,
							})}
						></input>
					</div>
				</>
			)}
			{/* 
			<div
				className={classNames("b-1 field", {
					error: errors.equipment_type,
				})}
			>
				<label>{t("Fields.EquipmentType")}</label>
				<input
					type="text"
					placeholder={t("Fields.EquipmentType")}
					{...register("equipment_type", {})}
				/>
			</div> */}

			<div
				className={classNames("b-1 field", {
					error: errors.equipment_type_id,
				})}
			>
				<label>{t("Fields.EquipmentType")}</label>

				<Controller
					name="equipment_type_id"
					control={control}
					render={({ field }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							placeholder={t("Common.Select")}
							isClearable={true}
							{...field}
							options={equipments?.map((el) => {
								return {
									value: el.id,
									label: el.name,
								};
							})}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-2 field", {
					error: errors.customer_id,
				})}
			>
				<label>{t("Tables.Customer")}</label>

				<Controller
					name="customer_id"
					control={control}
					render={({ field }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							placeholder={t("Common.Select")}
							isClearable={false}
							{...field}
							options={
								// []
								customers?.map((el) => {
									return {
										value: el.id,
										label: el.name,
									};
								})
							}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-11 field", {
					error: errors.fee_time,
				})}
			>
				<label>{t("Fields.FreeTime")}</label>
				<input
					type="text"
					placeholder={t("Fields.FreeTime")}
					{...register("fee_time", {
						pattern: decimalRegEx(5),
					})}
				/>
			</div>

			<div
				className={classNames("b-12 field", {
					error: errors.hazmat_calc_type,
				})}
			>
				<label>{t("Fields.HazMatCalcType")}</label>
				<Controller
					name="hazmat_calc_type"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Select
							className="dsv-select"
							classNamePrefix="dsv-select"
							isClearable={true}
							onChange={onChange}
							placeholder={t("Common.Select")}
							value={
								value?.value
									? {
											value: CALC_TYPE.properties[`${value.value}`].value,
											label: t(CALC_TYPE.properties[`${value.value}`].name),
										}
									: null
							}
							options={toSelector(CALC_TYPE.properties)}
						/>
					)}
				/>
			</div>

			<div
				className={classNames("b-21 field", {
					error: errors.hazmat_calc_value,
				})}
			>
				<label>{t("Fields.HazMatCalcValue")}</label>
				<input
					type="text"
					placeholder={t("Fields.HazMatCalcValue")}
					{...register("hazmat_calc_value", {
						pattern: {
							value: currencyRegEx,
							message: "Value must be a decimal.",
						},
					})}
				/>
			</div>

			{getValues("hazmat_calc_type")?.value === CALC_TYPE.PERCENTAGE && (
				<div
					className={classNames("b-22 field", {
						error: errors.hazmat_minium,
					})}
				>
					<label>{t("Fields.HazMatMinium")}</label>
					<input
						type="text"
						placeholder={t("Fields.HazMatMinium")}
						{...register("hazmat_minium", {
							pattern: {
								value: currencyRegEx,
								message: "Value must be a decimal.",
							},
						})}
					/>
				</div>
			)}
			<div
				className={classNames("b-11 field", {
					error: errors.rate,
				})}
			>
				<label>{t("Fields.Rate")}</label>
				<input
					type="text"
					placeholder={t("Fields.Rate")}
					{...register("rate", {
						required: true,
						pattern: {
							value: currencyRegEx,
							message: "Value must be a decimal.",
						},
					})}
				/>
			</div>

			<div
				className={classNames("b-12 field", {
					error: errors.minimum_rate,
				})}
			>
				<label>{t("Fields.MinimumRate")}</label>
				<input
					type="text"
					placeholder={t("Fields.MinimumRate")}
					{...register("minimum_rate", {
						// required: true,
						pattern: {
							value: currencyRegEx,
							message: "Value must be a decimal.",
						},
					})}
				/>
			</div>

			{currency != null && (
				<div className={classNames("b-21 field", {})}>
					<label>{t("Fields.Currency")}</label>
					<div>
						{t(
							CURRENCY.properties[rate == null ? currency : rate.currency]
								.name
						)}
					</div>
				</div>
			)}

			<div className="b-11 top-separation">
				<label>&nbsp;</label>
				<div className="button" onClick={handleClose}>
					{t("Buttons.Cancel")}
				</div>
			</div>
			<div className="b-12 top-separation">
				<label>&nbsp;</label>
				<div className="button main" onClick={handleSubmit(handleSave)}>
					{t("Buttons.Save")}
				</div>
			</div>
		</div>
	);
}
