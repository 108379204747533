import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toTruckerInfoEditForm } from "utils/orderUtils";
import Loader from "components/Loader";

const TruckerInfoForm = ({ order, onSave, isSaving, onCancel }) => {
	// Translation
	const { t } = useTranslation();
	// Form
	const { register, handleSubmit, reset } = useForm();
	// Effect
	useEffect(() => {
		reset(toTruckerInfoEditForm(order));
	}, [reset, order]);
	// Handlers
	const handleCancelEdit = () => {
		onCancel();
	};
	const handleSave = (data) => {
		const formData = {
			driver: data.driver !== "" ? data.driver : undefined,
			license_number: data.license_number !== "" ? data.license_number : undefined,
			truck_number: data.truck_number !== "" ? data.truck_number : undefined,
			plate_number: data.plate_number !== "" ? data.plate_number : undefined,
			equipment_type: data.equipment_type !== "" ? data.equipment_type : undefined,
			trailer_number: data.trailer_number !== "" ? data.trailer_number : undefined,
		};
		onSave(formData);
	};
	// Render
	if (isSaving) {
		return (
			<div className="order-block edit bottom-border">
				<div className="dsv-form full no-padding">
					<div className="a order-subtitle">
						<div>{t("Common.TruckerInfo")}</div>
					</div>
					<div className="a">
						<Loader />
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="order-block edit bottom-border">
			<div className="dsv-form full no-padding">
				<div className="a order-subtitle">
					<div>{t("Common.TruckerInfo")}</div>
					<div>
						<span className="icon-edit-close" onClick={handleCancelEdit}></span>
					</div>
					<div>
						<span
							className="icon-edit-save"
							onClick={handleSubmit(handleSave)}
						></span>
					</div>
				</div>
				<div className="b-1 field">
					<label>{t("Fields.DriverName")}</label>
					<input
						type="text"
						placeholder={t("Fields.DriverName")}
						{...register("driver")}
					></input>
				</div>
				<div className="b-2 field">
					<label>{t("Fields.LicenseNumber")}</label>
					<input
						type="text"
						placeholder={t("Fields.LicenseNumber")}
						{...register("license_number")}
					></input>
				</div>
				<div className="b-1 field">
					<label>{t("Fields.EquipmentType")}</label>
					<input
						type="text"
						placeholder={t("Fields.EquipmentType")}
						{...register("equipment_type")}
					></input>
				</div>
				<div className="b-2 field">
					<label>{t("Fields.TruckNumber")}</label>
					<input
						type="text"
						placeholder={t("Fields.TruckNumber")}
						{...register("truck_number")}
					></input>
				</div>
				<div className="b-1 field">
					<label>{t("Fields.PlateNumber")}</label>
					<input
						type="text"
						placeholder={t("Fields.PlateNumber")}
						{...register("plate_number")}
					></input>
				</div>
				<div className="b-2 field">
					<label>{t("Fields.TrailerNumber")}</label>
					<input
						type="text"
						placeholder={t("Fields.TrailerNumber")}
						{...register("trailer_number")}
					></input>
				</div>
			</div>
		</div>
	);
};

export default TruckerInfoForm;
