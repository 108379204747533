import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOOKED_STATUS as BS, CARRIER_FILTERS } from "features/constants";
import { getCarrierFilters } from "utils/carrierUtils";
import CarrierOrders from "components/common/order/CarrierOrders";
import {
  fetchCarrierHistory,
  fetchCarrierHistoryDetails,
  setCarrierHistoryActiveTab,
  setCarrierHistoryOpenedOrder,
	setCarrierHistoryOrderDocs,
	setCarrierHistoryOrderLog,
	setCarrierHistoryTabs,
} from "features/carrier/carrierHistorySlice";
import { fetchSaveUserSettings, setAccountShowSettings } from "features/account/accountSlice";
import CarrierOrderHistoryDetails from "./CarrierOrderHistoryDetails";
import carrier_default from "settings/carrier_default.json";
import TableSettings from "components/common/tableSettings/TableSettings";

export default function CarrierOrderHistory() {
  const dispatch = useDispatch();
  // Translation
  const { t } = useTranslation();
  // Selectors
  const { list, isLoading, opened, isOpening, refresh } = useSelector(
    (state) => state.carrierHistory
  );
	const { isLoadingSettings, isSavingSettings, settings, showSettings } =
	useSelector((state) => state.account);
	const {user} = useSelector((state) => state.session);

	// State
  const [showDetails, setShowDetails] = useState(false);
  const [collapse, setCollapse] = useState(false);
  // Effect
  useEffect(() => {
    if (!isLoading && list == null) {
      const filters = getCarrierFilters(CARRIER_FILTERS.HISTORY);
      dispatch(fetchCarrierHistory({ filters, page: 1 }));
    }
    if (opened == null && !isOpening) {
      setShowDetails(false);
    }
  }, [isLoading, list, opened, isOpening, dispatch]);

	// Table Settings
	const userSettings = settings
		? settings.settings.version !== carrier_default.settings.version
			? carrier_default
			: settings
		: carrier_default;
	const tableSettings = userSettings.settings.carrier_history;

		// Handlers
  const handleOrderClick = (id) => {
    dispatch(setCarrierHistoryActiveTab({ activeTab: 0 }));
		dispatch(fetchCarrierHistoryDetails({ id }));
    setShowDetails(true);
  };
  const handleOrderClose = () => {
    dispatch(setCarrierHistoryOpenedOrder({ opened: null }));
		dispatch(setCarrierHistoryTabs({ tabs: null }));
		dispatch(setCarrierHistoryActiveTab({ activeTab: 0 }));
		dispatch(setCarrierHistoryOrderDocs({ docs: null }));
		dispatch(setCarrierHistoryOrderLog({ log: null }));
    setShowDetails(false);
    setCollapse(false);
  };
  const handleOrderCollapse = () => {
    setCollapse(true);
  };
  const handleOrderExpand = () => {
    setCollapse(false);
  };
	const handleFilterRefresh = (filters) => {
		dispatch(fetchCarrierHistory({ filters, page: list?.current_page ?? 1 }));
	};
	const handleFilterSubmit = (filters) => {
		dispatch(
			fetchCarrierHistory({ page: 1, filters })
		);
	};
	const handlePageClick = (filters, page) => {
		dispatch(fetchCarrierHistory({ filters, page }));
	};
	const handleShowSettings = () => {
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: true }));
	};
	const handleCloseSettings = () => {
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: false }));
	};
	const handleSaveSettings = (data) => {
		const userSettings = {
			...carrier_default.settings,
			carrier_history: data,
		};
		dispatch(fetchSaveUserSettings({ data: userSettings }));
	};
  // Render
  const getCssClass = () => {
    if (showDetails) {
      if (collapse) {
        return "order-container show-details collapse";
      }
      return "order-container show-details";
    }
		if (showSettings) {
			return "order-container show-settings";
		}
    return "order-container";
  };

	const renderSettings = () => {
		if (showSettings) {
			return (
				<TableSettings
					settings={JSON.parse(JSON.stringify(tableSettings))}
					onSave={handleSaveSettings}
					onClose={handleCloseSettings}
					isLoading={isLoadingSettings}
					isSaving={isSavingSettings}
				/>
			);
		}
		return null;
	};
  const renderTable = () => {
			const statusOptions = [
				{ value: BS.DELIVERED, label: t(BS.properties[BS.DELIVERED].name) },
				// { value: BS.CANCELED, label: t(BS.properties[BS.CANCELED].name) },
				// { value: BS.REJECTED, label: t(BS.properties[BS.REJECTED].name) },
			];
			const defaultFilters = CARRIER_FILTERS.properties[CARRIER_FILTERS.HISTORY].filters
      return (
          <CarrierOrders
            list={list}
            onClick={handleOrderClick}
            activeRow={opened}
						settings={tableSettings}
						user={user}
						statusOptions={statusOptions}
						isLoading={isLoading}
						refresh={refresh}
						onRefresh={handleFilterRefresh}
						onFilterSubmit={handleFilterSubmit}
						onPageClick={handlePageClick}
						defaultFilters={defaultFilters}
						/>
      );
  };
  const cssClass = getCssClass();
  return (
    <div id="default">
      <div className={cssClass}>
        <div className="order-list">
          <section id="order-requests">
            <div className="dsv-title">
              <div>
                <h1>{t("CarrierOrderHistory.Title")}</h1>
              </div>
							<div
								className="table-settings-button"
								onClick={() => handleShowSettings()}
							>
								<div>
									<span className="icon-settings"></span>
								</div>
								<div>{t("Common.TableSettings")}</div>
							</div>
            </div>
            {renderTable()}
          </section>
        </div>
				{renderSettings()}
        <CarrierOrderHistoryDetails
          onClose={handleOrderClose}
          onCollapse={handleOrderCollapse}
          onExpand={handleOrderExpand}
          collapse={collapse}
        />
      </div>
    </div>
  );
}
