import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
import { getError } from "utils/reactHookFormUtils";

export default function SelectorField({
	fieldName,
	label,
	errorMessage,
	diplayErrors = true,
	placeholder,
	className = "",
	required = false,
	isClearable = true,
	isMulti = false,
	border = true,
	closeMenuOnSelect = true,
	options,
	isTableField = false,
}) {
	const {
		control,
		// getValues,
		formState: { errors },
	} = useFormContext();

	const error = getError(fieldName, errors);

	const renderField = () => {
		return (
			<div
				className={classNames(["field", className].join(" "), {
					error: error != null,
				})}
			>
				{(diplayErrors || label != null) && (
					<label>{error?.message ?? errorMessage ?? label}</label>
				)}

				<Controller
					name={fieldName}
					control={control}
					rules={{ required: required }}
					render={({ field: { onChange, value } }) => (
						<Select
							closeMenuOnSelect={closeMenuOnSelect}
							className={!border ? "dsv-select no-border" : "dsv-select"}
							classNamePrefix="dsv-select"
							isClearable={isClearable}
							isMulti={isMulti}
							placeholder={placeholder}
							options={options}
							value={
								isMulti ? value : options.find((c) => c.value === value) ?? null
							}
							onChange={(val) => {
								onChange(isMulti ? val : val?.value);
							}}
						/>
					)}
				/>
			</div>
		);
	};
	if (isTableField) {
		return (
			<td
				key={fieldName}
				className={classNames(["field", className].join(" "), {
					error: error != null,
				})}
			>
				{renderField()}
			</td>
		);
	}
	return renderField();
}
