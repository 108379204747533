import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {
	useFloating,
	autoUpdate,
	offset,
	// flip,
	shift,
} from "@floating-ui/react-dom";
import {
	ADDRESS_TYPE,
	BOOKED_STATUS,
	BOOKING_METHOD,
	CARGO_TYPE,
	// CARRIER_FILTERS,
	CURRENCY,
	FREIGHT_CLASS,
	SHIPMENT_TYPE,
	VOLUME_UNIT,
	WEIGHT_UNIT,
} from "features/constants";
import i18n from "features/i18n";
import {
	formatDate,
	formatDateToString,
	formatDateToStringWithPattern,
	getDateFromTypePattern,
	renderDate,
	stringToDateWithFormat,
} from "utils/dateUtils";
import { numberFormat } from "utils/numberUtils";
import { getAddressProps } from "utils/orderUtils";
import { toSelector } from "utils/constantsUtils";
import { dateTypeRegex } from "utils/regExs";
import HandleClickOutside from "hooks/handleClickOutsideHook";
import Pagination from "../pagination/Pagination";
import Loader from "components/Loader";
import { truncateString } from "utils/stringUtils";

export default function CarrierOrders({
	list,
	onClick,
	activeRow,
	settings,
	user,
	statusOptions,
	isLoading,
	refresh,
	onRefresh,
	onFilterSubmit,
	onPageClick,
	defaultFilters,
}) {
	// Form initializer
	const {
		control,
		register,
		setValue,
		// getValues,
		handleSubmit,
		reset,
		// formState: { errors },
	} = useForm();

	// Translation
	const { t } = useTranslation();

	const handleFilterRefresh = () => {
		const filters =
			orderFilters?.book_status != null
				? { ...orderFilters }
				: {
						...orderFilters,
						...defaultFilters,
				  };
		onRefresh(filters);
		handleCloseFilter();
	};
	// State
	const [showFilter, setShowFilter] = useState(false);
	const [activeFilter, setActiveFilter] = useState(null);
	const [orderFilters, setOrderFilters] = useState({});
	// Effect
	useEffect(() => {
		if (!isLoading && refresh) {
			handleSubmit(handleFilterRefresh());
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isLoading, refresh, handleSubmit]);

	// Date Pattern
	const dateTypePattern = dateTypeRegex(i18n.language);

	// Filter Form positioning.
	const { x, y, refs, strategy } = useFloating({
		placement: "bottom-start",
		open: showFilter,
		onOpenChange: setShowFilter,
		middleware: [offset(0), shift()],
		whileElementsMounted: autoUpdate,
	});

	// Handlers
	const handleDateChange = (e) => {
		// const { name, value } = e.target;
	};
	const handleDateKeyPress = (e) => {
		if (e.key === "Enter") {
			const { name, value } = e.target;
			if (dateTypePattern.test(value)) {
				const date = getDateFromTypePattern(value, i18n.language);
				const dateString = formatDateToStringWithPattern(
					date,
					t("Dates.FieldDate")
				);
				setValue(name, dateString);
			}
		}
	};
	const handleDateBlur = (e) => {
		const { name, value } = e.target;
		if (dateTypePattern.test(value)) {
			const date = getDateFromTypePattern(value, i18n.language);
			const dateString = formatDateToStringWithPattern(
				date,
				t("Dates.FieldDate")
			);
			setValue(name, dateString);
		}
	};
	const handleCloseFilter = () => {
		setShowFilter(false);
		setActiveFilter(null);
	};
	const getFilterField = () => {
		return activeFilter === 1
			? {
					field: "file_number",
					name: "Tables.FileNumber",
					filter_type: "string",
			  }
			: settings.find((item) => item.id === activeFilter);
	};
	const handleFilterSubmit = (data, e) => {
		e.preventDefault();
		const prepareFilters = prepareFilterData(data, activeFilter);
		const submitFilters = { ...orderFilters, ...prepareFilters };
		setOrderFilters({ ...submitFilters });
		const filters =
			submitFilters?.book_status != null
				? { ...submitFilters }
				: {
						...submitFilters,
						...defaultFilters,
				  };
		onFilterSubmit(filters);
		handleCloseFilter();
	};

	const handlePageClick = (page) => {
		const filters =
			orderFilters?.book_status != null
				? { ...orderFilters }
				: {
						...orderFilters,
						...defaultFilters,
				  };
		onPageClick(filters, page);
	};

	const prepareFilterData = (data, id) => {
		let formData = {
			...data,
		};
		// File Number
		if (id === 1) {
			if (formData.file_number.length > 0) {
				return { file_number: formData.file_number };
			}
			return { file_number: undefined };
		}
		const fieldItem = getFilterField();
		if (fieldItem)
			switch (fieldItem.filter_type) {
				case "string":
					if (
						formData[fieldItem.field] !== "" &&
						formData[fieldItem.field].length > 0
					) {
						return { [fieldItem.field]: formData[fieldItem.field] };
					}
					return { [fieldItem.field]: undefined };
				case "list":
					if (formData[fieldItem.field].length > 0) {
						return {
							[fieldItem.field]: formData[fieldItem.field]?.map(
								(item) => item.value
							),
						};
					}
					return { [fieldItem.field]: undefined };
				case "date":
					const dateStart = stringToDateWithFormat(
						formData[`${fieldItem.field}_start`],
						t("Dates.FieldDate")
					);
					const dateEnd = stringToDateWithFormat(
						formData[`${fieldItem.field}_end`],
						t("Dates.FieldDate")
					);
					return {
						[`${fieldItem.field}_start`]: formatDateToString(dateStart),
						[`${fieldItem.field}_end`]: formatDateToString(dateEnd),
					};
				default:
					return {};
			}
	};

	const handleFilterReset = (data, e) => {
		e.preventDefault();
		const fieldItem = getFilterField();
		const clearValue = fieldItem.filter_type === "list" ? null : "";
		if (fieldItem.filter_type === "date") {
			data[`${fieldItem.field}_start`] = clearValue;
			data[`${fieldItem.field}_end`] = clearValue;
			reset(data);
			if (
				orderFilters[`${fieldItem.field}_start`] == null &&
				orderFilters[`${fieldItem.field}_end`] == null
			) {
				handleCloseFilter();
				return;
			}
		} else {
			data[fieldItem.field] = clearValue;
			reset(data);
			if (orderFilters[fieldItem.field] == null) {
				handleCloseFilter();
				return;
			}
		}
		let submitFilters = {};
		if (fieldItem.filter_type === "date") {
			setOrderFilters({
				...orderFilters,
				[`${fieldItem.field}_start`]: undefined,
				[`${fieldItem.field}_end`]: undefined,
			});
			submitFilters = {
				...orderFilters,
				[`${fieldItem.field}_start`]: undefined,
				[`${fieldItem.field}_end`]: undefined,
			};
		} else {
			setOrderFilters({ ...orderFilters, [fieldItem.field]: undefined });
			submitFilters = { ...orderFilters, [fieldItem.field]: undefined };
		}
		const filters =
			submitFilters?.book_status != null
				? { ...submitFilters }
				: {
						...submitFilters,
						...defaultFilters,
				  };

		onRefresh(filters);
		handleCloseFilter();
	};

	// Render
	const getFilterOptions = (filterField) => {
		switch (filterField) {
			case "transport_shipment_type":
				return toSelector(SHIPMENT_TYPE.properties, false, true);
			case "transport_cargo_type":
				return toSelector(CARGO_TYPE.properties, false, true);
			case "book_status":
				return statusOptions;
			case "booked_method":
				if (user?.user?.company) {
					return toSelector(BOOKING_METHOD.properties, false, true);
				}
				return toSelector(BOOKING_METHOD.properties, false, true, [
					BOOKING_METHOD.DSVROAD,
				]);
			default:
				return null;
		}
	};

	const renderFilter = () => {
		if (showFilter) {
			const fieldItem = getFilterField();
			let fieldData;
			switch (fieldItem.filter_type) {
				case "date":
					fieldData = (
						<>
							<div className="b-1 field">
								<label>{t(fieldItem.name)}</label>
								<input
									type="text"
									placeholder={t("Dates.Placeholder")}
									{...register(`${fieldItem.field}_start`, {
										onChange: handleDateChange,
										onBlur: handleDateBlur,
									})}
									onKeyDown={handleDateKeyPress}
								/>
							</div>
							<div className="b-2 field">
								<label>&nbsp;</label>
								<input
									type="text"
									placeholder={t("Dates.Placeholder")}
									{...register(`${fieldItem.field}_end`, {
										onChange: handleDateChange,
										onBlur: handleDateBlur,
									})}
									onKeyDown={handleDateKeyPress}
								/>
							</div>
						</>
					);
					break;
				case "string":
				default:
					fieldData = (
						<div className="a field">
							<label>{t(fieldItem.name)}</label>
							<input
								type="text"
								placeholder={t(fieldItem.name)}
								{...register(fieldItem.field, {})}
							/>
						</div>
					);
					break;
				case "list":
					const options = getFilterOptions(fieldItem.field);
					fieldData = (
						<div className="a field">
							<label>{t(fieldItem.name)}</label>
							<Controller
								name={fieldItem.field}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										className="dsv-select"
										classNamePrefix="dsv-select"
										placeholder={t(fieldItem.name)}
										isClearable={false}
										closeMenuOnSelect={!(options.length > 2)}
										value={value}
										onChange={onChange}
										isMulti={true}
										options={options}
									/>
								)}
							/>
						</div>
					);
					break;
			}
			return (
				<>
					<div
						ref={refs.setFloating}
						className="table-filter-container"
						style={{
							position: strategy,
							top: y - 1 ?? 0,
							left: x ?? 0,
						}}
					>
						<HandleClickOutside onClickOutside={handleCloseFilter}>
							<div className="dsv-table-form">
								<div className="a item-name">
									<div>{t("Common.Filter", { count: 1 })}</div>
									<div onClick={handleCloseFilter}>
										<span className="icon-edit-close"></span>
									</div>
								</div>
								{fieldData}
								<div className="b-1 top-separation">
									<div
										className="button"
										onClick={handleSubmit(handleFilterReset)}
									>
										{t("Buttons.Clear")}
									</div>
								</div>
								<div className="b-2 top-separation">
									<div
										className="button main"
										onClick={handleSubmit(handleFilterSubmit)}
									>
										{t("Buttons.Apply")}
									</div>
								</div>
							</div>
						</HandleClickOutside>
					</div>
				</>
			);
		}
	};
	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={list?.current_page ?? 1}
				last={list?.last_page ?? 1}
				onRefresh={handleFilterRefresh}
			/>
		);
	};
	if (isLoading || list == null) {
		return (
			<div>
				<Loader />
			</div>
		);
	}

	const firstActiveClass = orderFilters.file_number != null ? " active" : "";

	return (
		<div>
			{renderPagination()}
			<div className="dsv-table with-check">
				{renderFilter()}
				<table cellSpacing="0" cellPadding="0">
					<thead>
						<tr>
							<th
								ref={activeFilter === 1 ? refs.setReference : undefined}
								className="no-padding"
								key={"file_number_header"}
							>
								<div className={`table-filter ${firstActiveClass}`}>
									<div>{t("Tables.FileNumber")}</div>
									<div
										className="filter-button"
										onClick={() => {
											setShowFilter(true);
											setActiveFilter(1);
										}}
									>
										<span
											className={
												orderFilters.file_number != null
													? "icon-chevron expanded"
													: "icon-chevron expanded inverted"
											}
										></span>
									</div>
								</div>
							</th>
							{settings.map((el, index) => {
								if (!el.show) {
									return null;
								}
								const fieldClass = el.filter
									? el.class != null
										? { className: `${el.class} no-padding` }
										: { className: "no-padding" }
									: el.class != null
									? { className: el.class }
									: {};
								const activeClass =
									el.filter_type === "date"
										? orderFilters[`${el.field}_start`] &&
										  orderFilters[`${el.field}_end`] != null
											? "active"
											: ""
										: orderFilters[el.field] != null
										? "active"
										: "";
								if (el.filter) {
									return (
										<th
											{...fieldClass}
											key={index}
											ref={
												activeFilter === el.id ? refs.setReference : undefined
											}
										>
											<div className={`table-filter ${activeClass}`}>
												<div>{t(el.name)}</div>
												<div
													className="filter-button"
													onClick={() => {
														setShowFilter(true);
														setActiveFilter(el.id);
													}}
												>
													<span
														className={
															orderFilters[el.field] != null ||
															orderFilters[`${el.field}_start`] != null
																? "icon-chevron expanded"
																: "icon-chevron expanded inverted"
														}
													></span>
												</div>
											</div>
										</th>
									);
								}
								return (
									<th {...fieldClass} key={index}>
										{t(el.name)}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{list?.data.map((el, index) => {
							const rowProps = {
								onClick: () => onClick(el.id, index),
							};
							const master = el.order_requests[0] ?? null;
							const originAddress = getAddressProps(
								master?.addresses ?? null,
								ADDRESS_TYPE.ORIGIN
							);
							const destAddress = getAddressProps(
								master?.addresses ?? null,
								ADDRESS_TYPE.DESTINATION
							);

							// if (master == null) return null;
							const activeProps =
								el.id === activeRow?.id ? { className: "active" } : {};
							return (
								<tr
									{...activeProps}
									onClick={() => onClick(el.id, index)}
									key={index}
								>
									<td key={`file_number_${index}`}>{el.file_number}</td>
									{settings.map((field, idx) => {
										if (!field.show) {
											return null;
										}
										const fieldClass =
											field.class != null ? { className: field.class } : {};
										switch (field.field) {
											case "alternate_reference_number":
											case "master_bill_number":
											case "house_bill_number":
											case "origin_rating_port_code":
											case "destination_rating_port_code":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master != null ? master[field.field] : <>&nbsp;</>}
													</td>
												);
											case "origin_name":
											case "destination_name":
											case "cargo_description":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master != null ? (
															truncateString(master[field.field], 25)
														) : (
															<>&nbsp;</>
														)}
													</td>
												);
											case "transport_shipment_type":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master != null
															? t(
																	SHIPMENT_TYPE.properties[
																		master.transport_shipment_type
																	].name
															  )
															: ""}
													</td>
												);
											case "transport_cargo_type":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master != null
															? t(
																	CARGO_TYPE.properties[
																		master.transport_cargo_type
																	].name
															  )
															: ""}
													</td>
												);
											case "forwarder_name":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master?.forwarder_name ?? ""}
													</td>
												);
											case "booked_at":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{renderDate(el.booked_at, t("Dates.DateTime"))}
													</td>
												);
											case "price":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{el.price != null
															? `$${numberFormat(
																	el.price * el.exchange_rate,
																	2
															  )} ${
																	CURRENCY.properties[
																		el?.currency ??
																			user?.user?.carrier?.currency
																	].code
															  }`
															: "-"}
													</td>
												);
											case "book_status":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{el.book_status != null
															? t(BOOKED_STATUS.properties[el.book_status].name)
															: t(BOOKED_STATUS.properties[0].name)}
													</td>
												);
											case "booked_method":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{el.booked_method != null ? (
															t(
																BOOKING_METHOD.properties[el.booked_method].name
															)
														) : (
															<>&nbsp;</>
														)}
													</td>
												);
											case "cargo_pieces":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master != null ? (
															numberFormat(master[field.field])
														) : (
															<>&nbsp;</>
														)}
													</td>
												);
											case "cargo_actual_weight":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master != null ? (
															`${numberFormat(
																master.cargo_actual_weight * 1,
																2
															)} ${
																WEIGHT_UNIT.properties[
																	master.cargo_actual_weight_unit
																].code
															}`
														) : (
															<>&nbsp;</>
														)}
													</td>
												);
											case "cargo_freight_class":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master?.cargo_freight_class != null ? (
															FREIGHT_CLASS.properties[
																master.cargo_freight_class
															].name
														) : (
															<>&nbsp;</>
														)}
													</td>
												);
											case "cargo_volume":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{master?.cargo_volume != null ? (
															`${numberFormat(master.cargo_volume * 1, 2)} ${
																VOLUME_UNIT.properties[master.cargo_volume_unit]
																	.code
															}`
														) : (
															<>&nbsp;</>
														)}
													</td>
												);
											case "origin_postal_code":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{originAddress.zip ?? <>&nbsp;</>}
													</td>
												);
											case "origin_state":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{originAddress.state ?? <>&nbsp;</>}
													</td>
												);
											case "estimated_ready":
											case "estimated_close":
											case "requested_delivery":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{formatDate(el[field.field], t("Dates.DateTime"))}
													</td>
												);
											case "destination_postal_code":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{destAddress.zip ?? <>&nbsp;</>}
													</td>
												);
											case "destination_state":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{destAddress.state ?? <>&nbsp;</>}
													</td>
												);
											case "pod":
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{el.attachments_count === 0
															? t("Common.No")
															: t("Common.Yes")}
													</td>
												);
											default:
												return (
													<td
														{...rowProps}
														{...fieldClass}
														key={`${index}-${idx}`}
													>
														{el[field.field] ?? <>&nbsp;</>}
													</td>
												);
										}
									})}
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="2">
								{t("Pagination.Found", { count: list.total })}
							</td>
							<td colSpan="27">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			</div>
			{renderPagination()}
		</div>
	);
}
