import Loader from "components/Loader";
import {
	fetchUsers,
	setUserEdit,
	setUserOpen,
	setUserPwdChange,
	setUsersTab,
	setUsersShowAdd,
	fetchSetUserEnable,
	fetchDeleteUser,
	fetchUser,
	setUsersShowDetails,
} from "features/admin/usersSlice";
import { LANGUAGES, PROFILES, USER_ROLE as R, } from "features/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import UserPwdChange from "./UserPwdChange";
import UserTabs from "./UserTabs";
import Pagination from "components/common/pagination/Pagination";
import { getUserRole } from "utils/userUtils";

export default function ManageUsers() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// User Selectors
	const {
		open,
		tab, // Role
		edit,
		pwdChange,
		carriers,
		operators,
		admins,
		superAdmins,
		isLoading,
		showAdd,
		showDetails,
		isOpening,
	} = useSelector((state) => state.users);
	// Session Selectors
	const { user } = useSelector((state) => state.session);

	// Route Url Params
	const { role } = useParams();
	// Navigate
	const navigate = useNavigate();
	// Effect
	useEffect(() => {
		if (R.properties[role] == null) {
			dispatch(setUsersTab({ tab: R.CARRIER }));
			dispatch(fetchUsers({ role: R.CARRIER }));
			navigate(R.properties[R.CARRIER].url);
		} else {
			const roleId = R.properties[role].value;
			if (
				(roleId === R.CARRIER && carriers == null && !isLoading) ||
				(roleId === R.OPERATOR && operators == null && !isLoading) ||
				(roleId === R.ADMIN && admins == null && !isLoading) ||
				(roleId === R.SUPERADMIN && superAdmins == null && !isLoading)
			) {
				dispatch(setUsersTab({ tab: roleId }));
				dispatch(fetchUsers({ role: roleId }));
			}
		}
		// // Remove show details after user add
		// if (!showAdd && open == null && showDetails) {
		// 	dispatch(setUsersShowDetails({ showDetails: false }));
		// }
	}, [
		role,
		dispatch,
		navigate,
		carriers,
		showAdd,
		admins,
		isLoading,
		operators,
		superAdmins,
	]);
	// Handlers
	const handleUserClick = (user, index) => {
		dispatch(fetchUser({ id: user.id }));
		dispatch(setUsersShowDetails({ showDetails: true }));
		dispatch(setUsersShowAdd({ showAdd: false }));
		dispatch(setUserEdit());
		dispatch(setUserPwdChange({ pwdChange: false }));
	};
	const handleCloseClick = () => {
		dispatch(setUsersShowDetails({ showDetails: false }));
		dispatch(setUsersShowAdd({ showAdd: false }));
		dispatch(setUserOpen({ open: null }));
		dispatch(setUserEdit());
		dispatch(setUserPwdChange({ pwdChange: false }));
	};
	const handleEditClick = () => {
		dispatch(setUserEdit({ edit: true }));
	};
	const handlePwdChangeClick = () => {
		dispatch(setUserPwdChange({ pwdChange: true }));
	};
	const handleAddOpen = () => {
		dispatch(setUsersShowDetails({ showDetails: true }));
		dispatch(setUsersShowAdd({ showAdd: true }));
		dispatch(setUserEdit());
		dispatch(setUserPwdChange({ pwdChange: false }));
	};
	const handleAddClose = () => {
		dispatch(setUsersShowDetails({ showDetails: false }));
		dispatch(setUsersShowAdd({ showAdd: false }));
	};
	const handleDeleteUser = () => {
		if (window.confirm(t("Dialogs.ConfirmDeleteUser", { user: open.username}))) {
			dispatch(fetchDeleteUser({ id: open.id }));
		}
	};

	const handleEnableUser = () => {
		dispatch(
			fetchSetUserEnable({
				id: open.id,
				value: open.disabled_at ? false : true,
			})
		);
	};
	const handlePageClick = (page) => {
		dispatch(fetchUsers({ role: tab, page }));
	};
	// Render
	const getCssClass = () => {
		if (showDetails) {
			return "admin-container show-details";
		}
		return "admin-container";
	};
	const renderLoader = () => {
		if (isLoading) {
			return <Loader msg={t("Common.Loading")} />;
		}
	};
	const renderPagination = (usersList) => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={usersList?.current_page ?? 1}
				last={usersList?.last_page ?? 1}
				onRefresh={() => handlePageClick(usersList?.current_page ?? 1)}
			/>
		);
	};
	const renderCarriers = () => {
		if (tab === R.CARRIER && !isLoading) {
			return (
				<>
					{renderPagination(carriers)}
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Fields.Name")}</th>
									<th>{t("Fields.Username")}</th>
									<th>{t("Common.Email")}</th>
									<th>{t("Fields.Role")}</th>
									<th>{t("Fields.Carrier")}</th>
									<th>{t("Fields.MdgNumber")}</th>
								</tr>
							</thead>
							<tbody>
								{carriers?.data?.map((el, index) => {
									const activeProps =
										open?.id === el.id ? { className: "active" } : {};
									return (
										<tr
											{...activeProps}
											key={index}
											onClick={() => handleUserClick(el, index)}
										>
											<td>{el.name}</td>
											<td>{el.username}</td>
											<td>{el.email}</td>
											<td>{t(PROFILES.properties[el.role].name)}</td>
											<td>{el.carrier.name}</td>
											<td>{el.carrier.mdg_number ?? "n/a"}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					{renderPagination(carriers)}
				</>
			);
		}
		return null;
	};
	const renderOperators = () => {
		if (tab === R.OPERATOR && !isLoading) {
			return (
				<>
					{renderPagination(operators)}
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Fields.Name")}</th>
									<th>{t("Fields.Username")}</th>
									<th>{t("Common.Email")}</th>
									<th>{t("Fields.Role")}</th>
									<th>{t("Fields.Company")}</th>
									<th>{t("Fields.CompanyCode")}</th>
								</tr>
							</thead>
							<tbody>
								{operators?.data?.map((el, index) => {
									const activeProps =
										open?.id === el.id ? { className: "active" } : {};
									return (
										<tr
											{...activeProps}
											key={index}
											onClick={() => handleUserClick(el)}
										>
											<td>{el.name}</td>
											<td>{el.username}</td>
											<td>{el.email}</td>
											<td>{t(PROFILES.properties[el.role].name)}</td>
											<td>{el.company.name}</td>
											<td>{el.company.code}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					{renderPagination(operators)}
				</>
			);
		}
		return null;
	};
	const renderAdmins = () => {
		if (tab === R.ADMIN && !isLoading) {
			return (
				<>
					{renderPagination(admins)}
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Fields.Name")}</th>
									<th>{t("Fields.Username")}</th>
									<th>{t("Common.Email")}</th>
									<th>{t("Fields.Role")}</th>
									<th>{t("Fields.Company")}</th>
									<th>{t("Fields.CompanyCode")}</th>
								</tr>
							</thead>
							<tbody>
								{admins?.data?.map((el, index) => {
									const activeProps =
										open?.id === el.id ? { className: "active" } : {};
									return (
										<tr
											{...activeProps}
											key={index}
											onClick={() => handleUserClick(el)}
										>
											<td>{el.name}</td>
											<td>{el.username}</td>
											<td>{el.email}</td>
											<td>{t(PROFILES.properties[el.role].name)}</td>
											<td>{el.company.name}</td>
											<td>{el.company.code}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					{renderPagination(admins)}
				</>
			);
		}
		return null;
	};
	const renderSuperAdmins = () => {
		if (tab === R.SUPERADMIN && !isLoading) {
			return (
				<>
					{renderPagination(superAdmins)}
					<div className="dsv-table">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>{t("Fields.Name")}</th>
									<th>{t("Fields.Username")}</th>
									<th>{t("Fields.Email")}</th>
									<th>{t("Fields.Role")}</th>
								</tr>
							</thead>
							<tbody>
								{superAdmins?.data?.map((el, index) => {
									return (
										<tr key={index} onClick={() => handleUserClick(el)}>
											<td>{el.username}</td>
											<td>{el.name}</td>
											<td>{el.email}</td>
											<td>{t(PROFILES.properties[el.role].name)}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					{renderPagination(superAdmins)}
				</>
			);
		}
		return null;
	};
	const renderEdit = () => {
		if (edit && open != null && !isLoading) {
			const userRole = getUserRole(open);
			const userRoleName = t(R.properties[userRole].name, { count: 1 });
			if (open != null && !isLoading && edit) {
				return (
					<div className="item-details">
						<div className="details-container">
							<div className="details-title">
								<div>
									<div className="title-text">
										<span>
											{t("AdminUsers.EditUser", { role: userRoleName })}
										</span>
									</div>
									<div onClick={handleCloseClick}>
										<span className="icon-close"></span>
									</div>
								</div>
							</div>
							<div className="details-block">
								<div className="tab-content details">
									<UserEdit />
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	};
	const renderPwdChange = () => {
		if (open != null && !isLoading && pwdChange) {
			return (
				<div className="item-details">
					<div className="details-container">
						<div className="details-title">
							<div>
								<div className="title-text">
									<span>{t("Buttons.ResetPassword")}</span>
								</div>
								<div onClick={handleCloseClick}>
									<span className="icon-close"></span>
								</div>
							</div>
						</div>
						<div className="details-block">
							<div className="details-action">
								<UserPwdChange />
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderNew = () => {
		if (showDetails && showAdd) {
			return <UserAdd onClose={handleAddClose} role={tab} />;
		}
	};
	const formatBranches = () => {
		const arr = open.branches?.map((el) => el.code);
		if (arr.length > 0) {
			return arr.join(", ");
		} else {
			return t("Common.AllBranches");
		}
	};
	const renderDetails = () => {
		if (isOpening) {
			return (
				<div className="item-details">
					<div className="details-container">
						<div className="details-title">
							<div>
								<div className="title-text">
									<span>{t("AdminUsers.UserInfo")}</span>
								</div>
								<div onClick={handleCloseClick}>
									<span className="icon-close"></span>
								</div>
							</div>
						</div>
						<div className="details-block with-content">
							<Loader msg={t("Common.Loading")} />
						</div>
					</div>
				</div>
			);
		}
		if (open != null && !isOpening && !edit && !pwdChange && !showAdd) {
			const userRole = getUserRole(open);
			const editProps = { onClick: handleEditClick };
			return (
				<div className="item-details">
					<div className="details-container">
						<div className="details-title">
							<div>
								<div className="title-text">
									<span>{t("AdminUsers.UserInfo")}</span>
								</div>
								<div {...editProps}>
									<span className="icon-edit"></span>
								</div>
								<div onClick={handleCloseClick}>
									<span className="icon-close"></span>
								</div>
							</div>
						</div>
						<div className="details-block with-content">
							<div className="details-subtitle">
								<div>{t("Common.Details")}</div>
							</div>
							<div className="flex-table single-line">
								<div>
									<div>
										<div>{t("Fields.Name")}</div>
										<div>{open.name}</div>
									</div>
									<div>
										<div>{t("Common.Email")}</div>
										<div>
											<a href={`mailto:${open.email}`}>{open.email}</a>
										</div>
									</div>
									<div>
										<div>{t("Fields.Username")}</div>
										<div>{open.username}</div>
									</div>
									<div>
										<div>{t("Fields.Language")}</div>
										<div>{t(LANGUAGES.properties[open.lang ?? 1].name)}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="details-block with-content">
							<div className="flex-table single-line">
								<div>
									<div>
										<div>{t("Fields.Role")}</div>
										<div>{t(R.properties[userRole].name, {count: 2})}</div>
									</div>
									{open.carrier != null && (
										<div>
											<div>{t("Fields.Carrier")}</div>
											<div>{open.carrier.name}</div>
										</div>
									)}
									{open.carrier != null && (
										<div>
											<div>{t("Fields.MdgNumber")}</div>
											<div>{open.carrier.mdg_number ?? "n/a"}</div>
										</div>
									) }
									{open.company != null && (
										<div>
											<div>{t("Fields.Company")}</div>
											<div>{open.company.name}</div>
										</div>
									)}
									{open.company != null && (
										<div>
											<div>{t("Fields.CompanyCode")}</div>
											<div>{open.company.code}</div>
										</div>
									)}
									{userRole === R.OPERATOR && (
										<div>
											<div>
												{t("Fields.Branch", { count: open.branches.length })}
											</div>
											<div>{formatBranches()}</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="details-block with-content">
							<div className="action-buttons">
								<div>
									<div
										className="button action main"
										onClick={() => handlePwdChangeClick()}
									>
										<span className="icon-password-reset inverted"></span>
										{t("Buttons.ResetPassword")}
									</div>
									<div className="button action alternate" {...editProps}>
										<span className="icon-edit inverted"></span>
										{t("Buttons.EditUser")}
									</div>
									<div
										className="button action alert"
										onClick={() => handleDeleteUser()}
									>
										<span className="icon-cancel inverted"></span>
										{t("Buttons.DeleteUser")}
									</div>
									<div
										className="button action other"
										onClick={() => handleEnableUser()}
									>
										<span className="icon-other inverted"></span>
										{open.disabled_at
											? t("Buttons.EnableUser")
											: t("Buttons.DisableUser")}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderNewButton = () => {
		if (!showDetails && user?.user != null) {
			const userRole = getUserRole(user.user);
			if (userRole === PROFILES.ADMIN && tab === R.ADMIN) {
				return null;
			}
			return (
				<div onClick={() => handleAddOpen()}>
					<span className="icon-new"></span>
				</div>
			);
		}
	};
	const cssClass = getCssClass();
	if (user?.user == null) {
		return null;
	}
	return (
		<div id="default">
			<div className={cssClass}>
				<div className="item-list">
					<div className="content-container">
						<div className="dsv-title">
							<div>
								<h1>{t("AdminUsers.Title")}</h1>
							</div>
							{renderNewButton()}
						</div>
						<UserTabs />
						<div className="tab-content">
							{renderLoader()}
							{renderCarriers()}
							{renderOperators()}
							{renderAdmins()}
							{renderSuperAdmins()}
						</div>
					</div>
				</div>
				{renderDetails()}
				{renderEdit()}
				{renderPwdChange()}
				{renderNew()}
			</div>
		</div>
	);
}
