import client from "./networking";

/**
 * Gets quote list with filters
 * @param {object} data
 * @param {int} page
 * @returns AxiosResponse
 */
export const postQuoteList = (filters = {}, page = 1) => {
	return client.post(`api/quotes?page=${page}`, { filters: filters });
};

/**
 * Gets a quote by id
 * @param {int} id 
 * @returns AxiosResponse
 */
export const getQuote = (id) => {
	return client.get(`api/quotes/${id}`);
};

/**
 * Calculates rates for a quote
 * @param {object} data
 * @returns AxiosResponse
 */
export const postQuoteCalculation = (data) => {
	return client.post("/api/quotes/calculate", data);
};

/**
 * Creates a new quote
 * @param {object} data 
 * @returns AxiosResponse
 */
export const postQuoteCreation = (data) => {
	return client.post("/api/quotes/create", data);
};
