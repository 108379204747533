import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddCurrentCarrierZone,
  fetchAddMultipleCurrentCarrierZones,
  fetchCurrentCarrierZones,
  fetchSaveCurrentCarrierZone,
  setCurrentCarrierZone,
  setCurrentCarrierZoneAddEdit,
  setCurrentCarrierZoneExclusionEdit,
  clearZoneErrors,
  fetchSaveCurrentCarrierZoneExclusions,
} from "features/carrier/carrierProfileSlice";
import Loader from "components/Loader";
import Filters from "components/common/tables/filters/Filters";
import { useFilterContext } from "components/common/tables/filters/FilterContextProvider";
import FilterContext from "components/common/tables/filters/FilterContext";
import TableHead from "components/common/tables/TableHead";
import { filterTransformToData } from "utils/filtersUtils";
import admin_default from "settings/admin_default.json";
import { useForm } from "react-hook-form";
import CarrierZonesAdd from "components/common/carrier/CarrierZonesAdd";
import CarrierZoneExclusionsEdit from "components/common/carrier/CarrierZoneExclusionsEdit";

const tables = admin_default.settings.carrier_zones;
export default function CarrierProfileZones() {
  const dispatch = useDispatch();
  // Translate
  const { t } = useTranslation();
  // Selectors
  const {
    zones,
    isLoadingZones,
    zone,
    zoneAdd,
    zoneEdit,
    zoneExclusionEdit,
    isLoadingZoneExclusions,
    saveCarzoneExclusionsErrors,
    isSavingZone,
    saveCarrierZoneErrors,
    saveCarrierMultipleZoneErrors,
  } = useSelector((state) => state.carrierProfile);

  // Form initializer
  const {
    // control,
    register,
    handleSubmit,
    reset,
    setValue,
    // getValues,
    // formState: { errors },
  } = useForm();

  const { filterContext } = useFilterContext();

  useEffect(() => {
    if (zones === null && !isLoadingZones) {
      dispatch(fetchCurrentCarrierZones({ filters: {} }));
    }
  }, [dispatch, isLoadingZones, zones]);

  const handleZoneAddClick = () => {
    dispatch(setCurrentCarrierZone());
    dispatch(setCurrentCarrierZoneAddEdit({ zoneAdd: true }));
  };
  const handleZoneEditClick = (item) => {
    if (item.id === 0) {
      dispatch(setCurrentCarrierZone({ zone: item }));
      dispatch(setCurrentCarrierZoneExclusionEdit({ zoneExclusionEdit: true }));
    } else {
      dispatch(setCurrentCarrierZone({ zone: item }));
      dispatch(setCurrentCarrierZoneAddEdit({ zoneEdit: true }));
    }
  };

  const filterZipCodeClick = (data, e) => {
    e.preventDefault();
    filterContext.setFilters({});
    dispatch(
      fetchCurrentCarrierZones({
        filters: data,
      })
    );
  };

  const clearZipCodeFilter = (e) => {
    setValue("postal_code", null);
    e.preventDefault();
    filterContext.setFilters({});
    dispatch(
      fetchCurrentCarrierZones({
        filters: {},
      })
    );
  };

  const onFiltersChanged = (data, e) => {
    e.preventDefault();
    reset();
    dispatch(
      fetchCurrentCarrierZones({
        filters: filterTransformToData(data, tables),
      })
    );
  };

  if (isLoadingZones) {
    return <Loader msg={t("Common.Loading")} />;
  }

  if (zoneAdd || zoneEdit) {
    return (
      <CarrierZonesAdd
        // carrier={open}
        zone={zoneEdit ? zone : null}
        isSaving={isSavingZone}
        saveZoneErrors={saveCarrierZoneErrors}
        saveMultipleZoneErrors={saveCarrierMultipleZoneErrors}
        onSave={(data) => {
          dispatch(
            fetchSaveCurrentCarrierZone({
              zoneId: zone.id,
              data,
            })
          );
        }}
        onAdd={(data) => {
          dispatch(
            fetchAddCurrentCarrierZone({
              data,
            })
          );
        }}
        onSaveMultiple={(data) => {
          dispatch(
            fetchAddMultipleCurrentCarrierZones({
              data,
            })
          );
        }}
        onClose={() => {
          dispatch(setCurrentCarrierZoneAddEdit());
        }}
        onClearErrors={() => {
          dispatch(clearZoneErrors());
        }}
      />
    );
  } else if (zoneExclusionEdit) {
    return (
      <CarrierZoneExclusionsEdit
        zone={zone}
        isLoading={isLoadingZoneExclusions}
        saveErrors={saveCarzoneExclusionsErrors}
        onSave={(data) => {
          dispatch(
            fetchSaveCurrentCarrierZoneExclusions({
              data,
            })
          );
        }}
        onClose={() => {
          dispatch(setCurrentCarrierZoneExclusionEdit());
        }}
        onClearErrors={() => {
          dispatch(clearZoneErrors());
        }}
      />
    );
  }

  const styles = { minWidth: "160px" };

  return (
    <div className="tab-content details">
      <div className="details-block">
        <div className="details-subtitle">
          <div>
            <div className="dsv-form full single">
              <div className="d-1 field">
                <label>{t("Fields.PostalCode")}</label>
                <input
                  type="text"
                  placeholder={t("Fields.PostalCode")}
                  {...register("postal_code", { required: true })}
                />
              </div>
              <div className="d-2 align-bottom" onClick={clearZipCodeFilter}>
                <div className="button">{t("Buttons.Clear")}</div>
              </div>
              <div
                className="d-3 align-bottom"
                onClick={handleSubmit(filterZipCodeClick)}
              >
                <div className="button main">{t("Buttons.Search")}</div>
              </div>
            </div>
          </div>
          <div onClick={handleZoneAddClick}>
            <span className="icon-new"></span>
          </div>
        </div>
        <div className="dsv-table with-check dsv-zones">
          <FilterContext.Provider value={filterContext}>
            <Filters onFiltersChanged={onFiltersChanged} />
            <table cellSpacing="0" cellPadding="0" style={styles}>
              <TableHead tables={tables} skipFirst={true} />
              <tbody>
                {zones?.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span
                          className="icon-edit"
                          onClick={() => handleZoneEditClick(el)}
                        ></span>
                      </td>
                      <td>{index === 0 ? t("Common.Exclusions") : el.name}</td>
                      <td>
                        {el.zone_details?.length > 0
                          ? el?.zone_details?.map((detail) => {
                              return (
                                <>
                                  {detail.name}
                                  <br />
                                </>
                              );
                            })
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </FilterContext.Provider>
        </div>
      </div>
    </div>
  );
}
