import client from "./networking";

/**
 * * Carrier Order services
 */
/**
 * Gets carrier's booked orders list
 * @returns Instance `AxiosResponse`.
 */
export function postCarrierOrders(filters, page = 1) {
  const body = JSON.stringify({
    filters,
  });

  return client.post(`/api/booked_orders?page=${page}`, body);
}

/**
 * Gets carrier's booked order details.
 * @param {*} id Order id.
 * @returns Instance `AxiosResponse`.
 */
export function getCarrierOrderDetails(id) {
  return client.get("/api/booked_orders/" + id);
}

/**
 * Gets carrier's booked order status log
 * @param {*} id Order id.
 * @returns Instance `AxiosResponse`.
 */
export function getCarrierOrderLog(id) {
  return client.get(`/api/booked_orders/${id}/status_log`);
}

/**
 * Gets carrier's offer requests list
 * @returns Instance `AxiosResponse`.
 */
export function getCarrierOfferRequests() {
  return client.get("/api/spot_prices");
}

/**
 * Gets carrier's offer details
 * @param {*} id spot price id
 * @returns Instance of `AxiosResponse`.
 */
export function getCarrierOfferDetails(id) {
  return client.get(`/api/spot_prices/${id}`);
}

/**
 * Posts carrier spot price acceptance
 * @param {*} id
 * @returns Instance of `AxiosResponse`.
 */
export function postCarrierOfferAccept(id, notes) {
  const body = { notes };
  return client.post(`/api/spot_prices/${id}/accept`, body);
}

/**
 * Posts carrier spot price rejection
 * @param {*} id
 * @param {*} data
 * @returns Instance of `AxiosResponse`.
 */
export function postCarrierOfferReject(id, data) {
  return client.post(`/api/spot_prices/${id}/reject`, data);
}

/**
 * Posts carrier price check quote
 * @param {*} id Booked order Id
 * @param {*} payload Quote data
 * @returns
 */
export const postCarrierSubmitQuote = (id, payload) => {
  const body = JSON.stringify(payload);
  return client.post(`/api/booked_orders/${id}/submit_quote`, body);
};

export const postCarrierPriceCheckReject = (id, payload) => {
  const body = JSON.stringify(payload);
  return client.post(`/api/booked_orders/${id}/reject_offer`, body);
};
/**
 * Gets carrier's rate list
 * @returns Instance `AxiosResponse`.
 */
export const getCurrentCarrierRates = (page = 1, filters = {}) => {
  return client.post(`/api/carriers/current/rates?page=${page}`, {
    filters,
  });
};

/**
 * Adds a carrier rate
 * @param {*} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addCurrentCarrierRate = (data) => {
  const body = JSON.stringify(data);
  return client.post(`/api/carrierscurrent/rates/create`, body);
};

/**
 * Updates a carrier rate.
 * @param {*} rateId int - rate id.
 * @param {*} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const saveCurrentCarrierRate = (rateId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/current/rates/${rateId}`, body);
};


/**
 * Gets carrier's zone list
 * @param {*} filters Object.
 * @returns Instance `AxiosResponse`.
 */
export const getCurrentCarrierZones = (filters = {}) => {
  return client.post(`/api/carriers/current/zones`, { filters });
};


/**
 * Creates a carrier zone.
 * @param {*} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addCurrentCarrierZone = (data) => {
  return client.post(`/api/carriers/current/zones/create`, data);
};

/**
 * Updates a carrier zone.
 * @param {*} zoneId int - zone id.
 * @param {*} data object - form data.
 * @returns
 */
export const saveCurrentCarrierZone = (zoneId, data) => {
  return client.put(`/api/carriers/current/zones/${zoneId}`, data);
};

/**
 * Creates nultiple carrier zone.
 * @param {*} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addMultipleCurrentCarrierZones = (data) => {
  return client.post(`/api/carriers/current/zones/create_multiple`, data);
};


/**
 * Updates a carrier zone.
 * @param {*} data object - form data.
 * @returns
 */
export const saveCurrentCarrierZoneExclusions = (data) => {
  return client.post(`/api/carriers/current/zones_exception`, data);
};


/**
 * Gets carrier zones as catalog key value pairs
 * @returns Instance `AxiosResponse`.
 */
export const getCurrentCarrierZoneCatalog = () => {
  return client.get(`/api/carriers/current/zones/catalog`);
};

/**
 * Gets a carrier's lane list
 * @returns Instance `AxiosResponse`.
 */
export const getCurrentCarrierLanes = (page = 1) => {
  const body = { filters: {} };
  return client.post(
    `/api/carriers/current/carrier_lanes?page=${page}`,
    body
  );
};

/**
 * Creates a carrier lane.
 * @param {*} data object - form data.
 * @returns
 */
export const addCurrentCarrierLane = (data) => {
  const body = JSON.stringify(data);
  return client.post(`/api/carriers/current/carrier_lanes/create`, body);
};


/**
 * Updates a carrier lane.
 * @param {*} laneId int - lane id.
 * @param {*} data object - form data.
 * @returns
 */
export const saveCurrentCarrierLane = (laneId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/current/carrier_lanes/${laneId}`, body);
};

/**
 * Gets carrier lanes as catalog key value pairs
 * @returns
 */
export const getCurrentCarrierLanesCatalog = () => {
  return client.get(`/api/carriers/current/carrier_lanes/catalog`);
};



/**
 * Gets carrier's customers list as catalog key value pairs
 * @returns Instance `AxiosResponse`.
 */
export const getCurrentCarrierCustomersCatalog = () => {
	return client.get(`/api/carriers/current/customers/catalog`);
  };