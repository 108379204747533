import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CARRIER_TAB, USER_ROLE } from "features/constants";
import { fetchCarrierUsers } from "features/admin/carriersSlice";
import { useFilterContext } from "components/common/tables/filters/FilterContextProvider";
import { filterTransformToData } from "utils/filtersUtils";
import Pagination from "components/common/pagination/Pagination";
import admin_default from "settings/admin_default.json";
import Loader from "components/Loader";
import UserAdd from "../users/UserAdd";
import { setUsersShowAdd } from "features/admin/usersSlice";

const tables = admin_default.settings.carrier_users;

const CarrierDetailsUsers = () => {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { tab, open, users, user, isLoadingUsers } = useSelector(
		(state) => state.carriers
	);
	const { showAdd } = useSelector((state) => state.users);
	// Filters
	const { filterContext } = useFilterContext({
		options: {},
	});
	// Handlers
	const handleUserAddClick = () => {
		dispatch(setUsersShowAdd({ showAdd: true }));
	};
	const handleUserClick = (user) => {
		// dispatch(setCarrierUser({ user: user }));
		// dispatch(setCarrierUserAddEdit({ userEdit: true }));
	};
	const handleAddClose = () => {
		dispatch(setUsersShowAdd({ showAdd: false }));
	};
	const handlePageClick = (page) => {
		dispatch(
			fetchCarrierUsers({
				carrierId: open.id,
				page,
				filters: filterTransformToData(filterContext.filters, tables),
			})
		);
	};

	if (tab !== CARRIER_TAB.USERS) return null;
	if (isLoadingUsers) {
		return <Loader msg={t("Common.Loading")} />;
	}
	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={users?.current_page ?? 1}
				last={users?.last_page ?? 1}
				onRefresh={() => handlePageClick(users?.current_page ?? 1)}
			/>
		);
	};

	if (showAdd) {
		return (
			<UserAdd
				onClose={handleAddClose}
				role={USER_ROLE.CARRIER}
				carrier={open}
			/>
		);
	}

	return (
		<div className="tab-content details">
			<div className="details-subtitle">
				<div>{t("AdminCarriers.Users")}</div>
				<div onClick={handleUserAddClick}>
					<span className="icon-add"></span>
				</div>
			</div>
			{renderPagination()}
			<div className="dsv-table">
				<table cellSpacing="0" cellPadding="0">
					<thead>
						<tr>
							<th>{t("Fields.Name")}</th>
							<th>{t("Fields.Username")}</th>
							<th>{t("Common.Email")}</th>
						</tr>
					</thead>
					<tbody>
						{users?.data?.map((el, index) => {
							const activeProps =
								user?.id === el.id ? { className: "active" } : {};
							return (
								<tr
									{...activeProps}
									key={index}
									onClick={() => handleUserClick(el)}
								>
									<td>{el.name}</td>
									<td>{el.username}</td>
									<td>{el.email}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{renderPagination()}
		</div>
	);
};
export default CarrierDetailsUsers;
