/**
 *
 * @param name
 * @param errors
 * @returns
 */
export function getError(
  name,
  errors
) {
	var keys = name.split(".");
	if (keys == null || keys.length === 0) return undefined;
	var error =
		errors[keys.shift()];

	// Get property value.
	while (keys.length > 0 && error) {
		error = (error)?.[keys.shift()];
	}

	return error;
}
