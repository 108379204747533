import {
	CARRIER_FILTERS,
	CARTAGE_PORT,
	CARGO_TYPE,
	CARRIER_MODE,
	WEIGHT_UNIT,
	RATE_TYPE,
	VOLUME_UNIT,
	ZONE_EXCLUSION_TYPE,
	CALC_TYPE,
} from "features/constants";
import { toSelectorValue } from "utils/constantsUtils";
import { convertKgToLb } from "utils/weightUtils";
import { t } from "i18next";
import { convertCbmToCbf } from "./volumeUtils";
/**
 * Gets the carrier filters for filtered fetching methods
 * @param {*} type Int CARRIER_FILTER
 * @returns filters object
 */
export const getCarrierFilters = (type) => {
	return CARRIER_FILTERS.properties[type].filters;
};

export const toCarrierForm = (carrier) => {
	return {
		name: carrier?.name,
		short_name: carrier?.short_name,
		dimensional_factor: carrier?.dimensional_factor,
		weight_unit: carrier?.weight_unit,
		mdg_number: carrier?.mdg_number,
		email_send_list:
			carrier?.email_send_list?.map((item) => {
				return {
					email: item.email,
					name: item.name,
					lang: item.lang,
				};
			}) ?? [],
		currency: carrier?.currency,
		dim_factor_weight_brackets: carrier?.dim_factor_weight_brackets,
		disclaimer: carrier?.disclaimer,
	};
};

export const toZoneForm = (zone, states) => {
	return zone == null
		? {}
		: {
				id: zone.id,
				name: zone.name,
				type: "1",
				zone_details:
					zone?.zone_details?.map((item, index) => {
						return {
							state: {
								value: item.state_number,
								label: states?.find((state) => {
									return state.state_number === item.state_number;
								})?.name,
							},
							city: {
								value: item.city_number != null ? item.city_number : null,
								label: "",
							},
						};
					}) ?? [],
		  };
};

export const toZoneExclusionForm = (zone, states, cities) => {
	// origin_zone_id: lane.origin_zone_id
	// ? {
	//     value: lane.origin_zone_id,
	//     label: zones?.find((item) => item.id === lane.origin_zone_id)?.name,
	//   }
	// : lane.origin_zone_id,

	return zone?.zone_details.length > 0
		? {
				zone_details:
					zone?.zone_details?.map((item, index) => {
						const type =
							item.zipcode != null
								? {
										value: ZONE_EXCLUSION_TYPE.POSTAL_CODE,
										label: t(
											ZONE_EXCLUSION_TYPE.properties[
												ZONE_EXCLUSION_TYPE.POSTAL_CODE
											].name
										),
								  }
								: {
										value: ZONE_EXCLUSION_TYPE.CITY,
										label: t(
											ZONE_EXCLUSION_TYPE.properties[ZONE_EXCLUSION_TYPE.CITY]
												.name
										),
								  };
						return type.value === ZONE_EXCLUSION_TYPE.POSTAL_CODE
							? {
									type: type,
									postal_data: { zipcode: item.zipcode, name: item.name },
									state: "",
									city: "",
							  }
							: {
									type: type,
									postal_data: null,
									state: {
										value: item.state_number,
										label: states?.find((state) => {
											return state.state_number === item.state_number;
										})?.name,
									},
									city: {
										value: item.city_number,
										label: cities[`${item.state_number}`]?.find((city) => {
											return city.city_number === item.city_number;
										})?.name,
									},
							  };
					}) ?? [],
		  }
		: {
				zone_details: [
					{
						type: {
							value: ZONE_EXCLUSION_TYPE.POSTAL_CODE,
							label: t(
								ZONE_EXCLUSION_TYPE.properties[ZONE_EXCLUSION_TYPE.POSTAL_CODE]
									.name
							),
						},
						state: "",
						city: "",
						postal_data: null,
					},
				],
		  };
};

export const toLaneForm = (lane, zones) => {
	var cartage_port = CARTAGE_PORT.ORIGIN;

	if (lane.destination_port !== null) {
		cartage_port = CARTAGE_PORT.DESTINATION;
	}

	return {
		carrier_mode: lane.carrier_mode
			? {
					value: lane.carrier_mode,
					label: t(CARRIER_MODE.properties[`${lane.carrier_mode}`].name),
			  }
			: undefined,
		cartage_port: {
			value: cartage_port,
			label: t(CARTAGE_PORT.properties[`${cartage_port}`].name),
		},
		origin_port: lane.origin_port,
		destination_port: lane.destination_port,
		origin_zone_id: lane.origin_zone_id
			? {
					value: lane.origin_zone_id,
					label: zones?.find((item) => item.id === lane.origin_zone_id)?.name,
			  }
			: lane.origin_zone_id,
		destination_zone_id: lane.destination_zone_id
			? {
					value: lane.destination_zone_id,
					label: zones?.find((item) => item.id === lane.destination_zone_id)
						?.name,
			  }
			: lane.destination_zone_id,
		w_m_rate: lane.w_m_rate,
		transit_time: lane.transit_time,
	};
};

export const toRateForm = (
	rate,
	packagings,
	lanes,
	equipments,
	customers
) => {
	let packaging = packagings?.find((e) => e.id === rate?.piece_unit);
	let carrier_lane = lanes?.find((e) => e.id === rate?.carrier_lane_id);
	let equipment = equipments?.find((e) => e.id === rate?.equipment_type_id);
	let customer = customers?.find((e) => e.id === rate?.customer_id);

	return {
		cargo_type: toSelectorValue(CARGO_TYPE.properties, rate?.cargo_type),
		carrier_mode: toSelectorValue(CARRIER_MODE.properties, rate?.carrier_mode),
		per_weight: rate?.per_weight ?? true,
		per_volume: rate?.per_volume ?? false,
		per_piece: rate?.per_piece ?? false,
		carrier_lane_id: carrier_lane
			? { value: carrier_lane.id, label: carrier_lane.name }
			: undefined,
		equipment_type_id: equipment
			? { value: equipment.id, label: equipment.name }
			: undefined,
		// TODO: Customer
		// :
		customer_id: customer
			? { value: customer.id, label: customer.name }
			: undefined,
		rate_type: toSelectorValue(RATE_TYPE.properties, rate?.rate_type),
		weight_unit: toSelectorValue(WEIGHT_UNIT.properties, rate?.weight_unit),
		weight_min:
			rate?.weight_min != null
				? rate?.weight_unit === WEIGHT_UNIT.LBS
					? convertKgToLb(rate?.weight_min, 2)
					: rate?.weight_min
				: undefined,
		weight_max:
			rate?.weight_max != null
				? rate?.weight_unit === WEIGHT_UNIT.LBS
					? convertKgToLb(rate?.weight_max, 2)
					: rate?.weight_max
				: undefined,

		volume_unit: toSelectorValue(VOLUME_UNIT.properties, rate?.volume_unit),
		volume_min:
			rate?.volume_min != null
				? rate?.volume_unit === VOLUME_UNIT.CBF
					? convertCbmToCbf(rate?.volume_min, 2)
					: rate?.volume_min
				: undefined,
		volume_max:
			rate?.volume_max != null
				? rate?.volume_unit === VOLUME_UNIT.CBF
					? convertCbmToCbf(rate?.volume_max, 2)
					: rate?.volume_max
				: undefined,

		piece_unit: packaging
			? { value: packaging.id, label: packaging.name }
			: undefined,
		piece_min: rate?.piece_min,
		piece_max: rate?.piece_max,
		equipment_type: rate?.equipment_type,
		rate: parseFloat((rate?.rate * rate?.exchange_rate ?? 1).toFixed(2)),
		minimum_rate:
			rate?.minimum_rate != null
				? parseFloat((rate?.minimum_rate * rate?.exchange_rate ?? 1).toFixed(2))
				: null,
		fee_time: rate?.fee_time,
		hazmat_calc_type: toSelectorValue(
			CALC_TYPE.properties,
			rate?.hazmat_calc_type
		),
		hazmat_calc_value: rate?.hazmat_calc_value,
		hazmat_minium: rate?.hazmat_minium,
	};
};

/**
 * Converts email_send_list to selector options
 * @param {*} list
 * @returns
 */
export const emailSendListToSelector = (list) => {
	if (list == null) return [];
	const options = list.map((el) => {
		return { label: el.email, value: el.email };
	});
	return options;
};
