import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderContainerForm from "components/common/order/OrderContainerForm";
import { fetchUpdateOrderContainer, setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";

const OperatorOrderDetailsContainerInfoEdit = ({collapse}) => {
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, isSaving } = useSelector(
		(state) => state.operatorOrders
	);
	// Handlers
	const handleSaveData = (data) => {
		dispatch(fetchUpdateOrderContainer({orderId: openOrder.id, formData: data}));
	};
	const handleCancelEdit = () => {
		dispatch(setOperatorOrderEdit(null));
	};
	// Render
	return (
		<OrderContainerForm
			order={openOrder}
			collapse={collapse}
			isSaving={isSaving}
			edit={orderEdit}
			onSave={handleSaveData}
			onCancel={handleCancelEdit}
		/>
	);
};

export default OperatorOrderDetailsContainerInfoEdit;
