import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchLogin, setLoginErrorMessage } from "features/account/loginSlice";
import Loader from "components/Loader";
import LanguageDropdown from "components/header/LanguageDropdown";
import Footer from "components/footer/Footer";
import { validatePwd } from "utils/stringUtils";

export default function Login() {
	//
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { errorMsg, isLoading } = useSelector((state) => state.login);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrName, setUsrName] = useState("");
	const [pwd, setPwd] = useState("");
	// Refs
	const usrNameRef = useRef();
	const pwdRef = useRef();

	// Effect

	/**
	 *
	 */
	useEffect(() => {
		let prevAuthenticated;
		if (errorMsg != null && errorMsg.error != null) {
			usrNameRef.current.focus();
		}
		if (checked && authenticated && !prevAuthenticated) {
			setUsrName("");
		}
		return () => {
			prevAuthenticated = authenticated;
		};
	}, [authenticated, checked, errorMsg]);

	// Handlers

	/**
	 *
	 * @param {*} e
	 */
	const handleUsrNameChange = (e) => {
		if (
			errorMsg != null &&
			(errorMsg.usrName != null || errorMsg.error != null)
		) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, usrName: null, error: null },
				})
			);
		}
		setUsrName(e.target.value);
	};
	/**
	 *
	 * @param {*} e
	 */
	const handleUsrNameKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			pwdRef.current.focus();
		}
	};
	/**
	 *
	 * @param {*} e
	 */
	const handleFocus = (e) => {
		e.target.select();
	};
	/**
	 *
	 * @param {*} e
	 */
	const handlePwdChange = (e) => {
		if (errorMsg != null && (errorMsg.pwd != null || errorMsg.error != null)) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, pwd: null, error: null },
				})
			);
		}
		setPwd(e.target.value);
	};
	/**
	 *
	 * @param {*} submit
	 * @returns
	 */
	const validateUsrName = (submit = false) => {
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrName.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrName: "Login.ErrorUsernameEmpty",
			};
			valid = false;
		}
		errorMessages = {
			...errorMessages,
			error: null,
		};
		if (!submit) {
			dispatch(setLoginErrorMessage({ errorMsg: errorMessages }));
		}
		return { valid, errorMessages };
	};
	const handleUsrNameBlur = (e) => {
		validateUsrName();
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		const validName = validateUsrName(true);
		valid = validName.valid;
		errorMessages = { ...errorMessages, ...validName.errorMessages };
		if (pwd.trim() === "") {
			errorMessages = {
				...errorMessages,
				pwd: "Login.ErrorPwdEmpty",
			};
			valid = false;
		} else if (!validatePwd(pwd)) {
			errorMessages = {
				...errorMessages,
				pwd: "Login.ErrorPwd",
			};
			valid = false;
		}
		if (valid) {
			dispatch(
				fetchLogin({
					username: usrName,
					password: pwd,
				})
			);
			setPwd("");
		} else {
			dispatch(setLoginErrorMessage({ errorMsg: errorMessages }));
		}
	};

	/**
	 *
	 * @param {*} property
	 * @param {*} original
	 * @returns
	 */
	const getFieldProps = (property, original) => {
		if (errorMsg === null) {
			return { className: original };
		}
		if (
			(property in errorMsg && errorMsg[property] != null) ||
			errorMsg["error"] != null
		) {
			return { className: original + " error" };
		} else {
			return { className: original };
		}
	};

	/**
	 *
	 * @param {*} error
	 * @returns
	 */
	const renderError = (error) => {
		if (error != null) {
			if (error !== "") {
				return <li>{t(error)}</li>;
			} else return null;
		} else return null;
	};
	const renderErrorList = () => {
		if (errorMsg != null) {
			return (
				<div className="a error-list">
					<ul>
						{renderError(errorMsg.usrName)}
						{renderError(errorMsg.pwd)}
						{renderError(errorMsg.error)}
					</ul>
				</div>
			);
		} else {
			return null;
		}
	};

	/**
	 *
	 * @returns
	 */
	const renderForm = () => {
		if (!isLoading) {
			return (
				<form className="dsv-form login" id="login-form">
					<div className="a">
						<h1>{t("Login.Title")}</h1>
					</div>
					<div {...getFieldProps("usrName", "a field")}>
						<label>{t("Fields.Username")}</label>
						<input
							type="text"
							tabIndex={1}
							value={usrName}
							ref={usrNameRef}
							autoComplete="username"
							onChange={handleUsrNameChange}
							onKeyDown={handleUsrNameKeyPress} // Replace 'onKeyPress' with 'onKeyDown'
							onFocus={handleFocus}
							onBlur={handleUsrNameBlur}
							placeholder={t("Fields.Username")}
						/>
					</div>
					<div {...getFieldProps("pwd", "a field")}>
						<label>{t("Fields.Password")}</label>
						<input
							type="password"
							tabIndex={2}
							placeholder={t("Fields.Password")}
							ref={pwdRef}
							value={pwd}
							autoComplete="current-password"
							onChange={handlePwdChange}
							onFocus={handleFocus}
						/>
					</div>
					<div className="a">
						<button
							type="submit"
							className="button main"
							onClick={handleSubmit}
						>
							{t("Buttons.Login")}
						</button>
					</div>
					{renderErrorList()}
					<div className="a login-links">
						<div className="link">{t("Login.Forgot")}</div>
					</div>
				</form>
			);
		}
		return <Loader msg={t("Common.LoggingIn")} />;
	};

	// const linkProps = { className: "link secondary" };

	return (
		<div id="login">
			<header>
				<div className="logo">
					<span className="dsv-logo"></span>
				</div>
				<LanguageDropdown />
			</header>
			<div className="login-content">
				<div>
					<div className="login-logo">
						<span className="roadlink-logo"></span>
					</div>
					{renderForm()}
				</div>
				<Footer />
			</div>
		</div>
	);
}
