import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_EDIT } from "features/constants";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import { isOrderReadyToBook } from "utils/orderUtils";
import OrderAddress from "components/common/order/OrderAddress";

export default function OperatorOrderAddress() {
	const dispatch = useDispatch();
	// Selectors
	const {
		openOrder,
		orderEdit,
		isLoadingOpen,
		bookedOrder,
		bookedTab,
		isLoadingBooked,
	} = useSelector((state) => state.operatorOrders);
	// Handlers
	const handleEditClick = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.ADDRESS }));
	};
	// Render
	if (openOrder == null) return null;
	if (openOrder.booked_orders?.length > 0) {
		const order =
			bookedOrder != null
				? bookedTab === 0
					? bookedOrder?.order_requests[bookedTab]
					: bookedOrder?.order_requests[bookedTab - 1] ?? null
				: null;
		return (
			<OrderAddress
				order={order}
				edit={orderEdit}
				isLoading={isLoadingOpen === true || isLoadingBooked === true}
				onEdit={handleEditClick}
				errors={{}}
				booked={true}
				bookedOrder={bookedOrder}
			/>
		);
	}
	const validateReady =
		openOrder?.booked_orders?.length === 0 ? isOrderReadyToBook(openOrder) : {};
	const errors = validateReady.address;

	return (
		<OrderAddress
			order={openOrder}
			edit={orderEdit}
			isLoading={isLoadingOpen}
			onEdit={handleEditClick}
			errors={errors}
			booked={false}
			bookedOrder={null}
		/>
	);
}
