import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CARRIER_TAB } from "features/constants";
import {
	clearCarrierEdit,
	fetchCarrierLanes,
	fetchCarrierRates,
	fetchCarrierUsers,
	fetchCarrierZones,
	setCarrierTab,
} from "features/admin/carriersSlice";
import { clearCarrierTemplateData } from "features/admin/carrierTemplateSlice";

export default function CarrierTabs() {
	const dispatch = useDispatch();
	// Translations
	const { t } = useTranslation();
	// Selectors
	const { tab, open } = useSelector((state) => state.carriers);
	// Handlers
	const handleClick = (tab) => {
		dispatch(setCarrierTab({ tab }));
		dispatch(clearCarrierEdit());
		dispatch(clearCarrierTemplateData());
		if (open != null && tab === CARRIER_TAB.USERS)
			dispatch(fetchCarrierUsers({ carrierId: open.id }));
		if (open != null && tab === CARRIER_TAB.RATES)
			dispatch(fetchCarrierRates({ carrierId: open.id }));
		if (open != null && tab === CARRIER_TAB.ZONES)
			dispatch(fetchCarrierZones({ carrierId: open.id }));
		if (open != null && tab === CARRIER_TAB.LANES)
			dispatch(fetchCarrierLanes({ carrierId: open.id }));
	};
	const tabsProps = {
		details:
			tab === CARRIER_TAB.DETAILS
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.DETAILS) },
		users:
			tab === CARRIER_TAB.USERS
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.USERS) },
		rates:
			tab === CARRIER_TAB.RATES
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.RATES) },
		zones:
			tab === CARRIER_TAB.ZONES
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.ZONES) },
		lanes:
			tab === CARRIER_TAB.LANES
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.LANES) },
		accessorials:
			tab === CARRIER_TAB.ACCESSORIALS
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.ACCESSORIALS) },
		qualifs:
			tab === CARRIER_TAB.QUALIFICATIONS
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.QUALIFICATIONS) },
		fuel:
			tab === CARRIER_TAB.FUEL
				? { className: "active" }
				: { onClick: () => handleClick(CARRIER_TAB.FUEL) },
	};
	return (
		<div className="dsv-tabs">
			<div {...tabsProps.details}>{t("AdminCarriers.Details")}</div>
			<div {...tabsProps.users}>{t("AdminCarriers.Users")}</div>
			<div {...tabsProps.rates}>{t("AdminCarriers.Rates")}</div>
			<div {...tabsProps.zones}>{t("AdminCarriers.Zones")}</div>
			<div {...tabsProps.lanes}>{t("AdminCarriers.Lanes")}</div>
			<div {...tabsProps.accessorials}>{t("AdminCarriers.Accessorials")}</div>
			<div {...tabsProps.qualifs}>{t("AdminCarriers.Qualifications")}</div>
			<div {...tabsProps.fuel}>{t("AdminCarriers.Fuel")}</div>
		</div>
	);
}
