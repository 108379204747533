import React from "react";
import { useSelector } from "react-redux";
import OperatorOrderActions from "./OperatorOrderDetailsActions";
import OperatorOrderAddress from "./OperatorOrderDetailsAddress";
import OperatorOrderAddressEdit from "./OperatorOrderDetailsAddressEdit";
import OperatorOrderHeaderEdit from "./OperatorOrderDetailsHeaderEdit";
import OperatorOrderCargo from "./OperatorOrderDetailsCargo";
import OperatorOrderCargoEdit from "./OperatorOrderDetailsCargoEdit";
import OperatorOrderHeader from "./OperatorOrderDetailsHeader";
import OperatorOrderConsolidationList from "./OperatorOrderDetailsConsolidationList";
import OperatorOrderConsolidation from "./OperatorOrderDetailsConsolidation";
import OperatorConsolidateDetails from "./consolidate/OperatorConsolidateDetails";
import OperatorTruckerInfo from "./OperatorOrderDetailsTruckerInfo";
import OperatorTruckerInfoEdit from "./OperatorOrderDetailsTruckerInfoEdit";
import OperatorNotifyParties from "./OperatorOrderDetailsNotifyParties";
import OperatorNotifyPartiesEdit from "./OperatorOrderDetailsNotifyPartiesEdit";
import OperatorOrderContainerInfo from "./OperatorOrderDetailsContainerInfo";
import OperatorOrderBroker from "./OperatorOrderDetailsBroker";
import OperatorOrderBrokerEdit from "./OperatorOrderDetailsBrokerEdit";
import OperatorOrderContainerInfoEdit from "./OperatorOrderDetailsContainerInfoEdit";

export default function OperatorOrderRequestDetails({
	onClose,
	onCollapse,
	onExpand,
	collapse,
	onConsolidate,
}) {
	// Selectors
	const { consolidate } = useSelector((state) => state.operatorOrders);
	const itemProps = {
		onClose: onClose,
		onCollapse: onCollapse,
		onExpand: onExpand,
		collapse: collapse,
		onConsolidate: onConsolidate,
	};
	if (consolidate) {
		return (
			<div className="order-details">
				<OperatorConsolidateDetails {...itemProps} />
				<OperatorOrderConsolidation />
				<OperatorOrderConsolidationList onConsolidate={onConsolidate} />
				<OperatorOrderActions {...itemProps} />
			</div>
		);
	}
	return (
		<div className="order-details">
			<div className="order-info">
				<OperatorOrderHeader {...itemProps} />
				<OperatorOrderHeaderEdit {...itemProps} />
				<OperatorTruckerInfo />
				<OperatorTruckerInfoEdit />
				<OperatorNotifyParties />
				<OperatorNotifyPartiesEdit />
				<OperatorOrderAddress />
				<OperatorOrderAddressEdit />
				<OperatorOrderCargo />
				<OperatorOrderCargoEdit collapse={collapse} />
				<OperatorOrderBroker />
				<OperatorOrderBrokerEdit collapse={collapse} />
				<OperatorOrderContainerInfo />
				<OperatorOrderContainerInfoEdit collapse={collapse} />
			</div>
			<OperatorOrderActions {...itemProps} />
		</div>
	);
}
