import OrderCargo from "components/common/order/OrderCargo";
import { ORDER_EDIT } from "features/constants";
import { setOperatorConsolidationTabEdit } from "features/operator/operatorOrderConsolidationSlice";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function OperatorConsolidateDetailsCargo() {
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, isLoadingOpen } = useSelector(
		(state) => state.operatorOrders
	);
	const { isLoading, selectedOrder, edit, activeTab } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	const { packagings } = useSelector((state) => state.packagings);
	// Handlers
	const handleEditClick = () => {
		if (activeTab === 0) {
			dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.CARGO }));
		}
		if (activeTab > 0) {
			dispatch(setOperatorConsolidationTabEdit({ edit: ORDER_EDIT.CARGO }));
		}
	};
	// Render
	if (activeTab === 0) {
		return (
			<OrderCargo
				order={openOrder}
				edit={orderEdit}
				isLoading={isLoadingOpen}
				packagings={packagings}
				onEdit={handleEditClick}
			/>
		);
	}
	if (activeTab > 0) {
		return (
			<OrderCargo
				order={selectedOrder}
				edit={edit}
				isLoading={isLoading}
				packagings={packagings}
				onEdit={handleEditClick}
			/>
		);
	}
	return null;
}
