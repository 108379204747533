import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPostalCode,
  getStates,
  getAllCities,
} from "services/postalCodeServices";

const initialState = {
  isLoading: false,
  postalCode: null,
  source: null,
  isLoadingStates: false,
  states: null,
  isLoadingCities: false,
  cities: null,
};

/* Async reducer function to fetch postal code search. */
export const fetchPostalCode = createAsyncThunk(
  "postalCodes/fetchPostalCode",
  async (payload) => {
    const { postalCode, source } = payload;
    const result = await getPostalCode(postalCode);
    return { postalCode: result.data, source: source };
  }
);

export const fetchStates = createAsyncThunk(
  "postalCodes/fetchStates",
  async () => {
    const response = await getStates();
    const { data } = response;
    return data;
  }
);

export const fetchCities = createAsyncThunk(
  "postalCodes/fetchCities",
  async () => {
    const response = await getAllCities();
    const { data } = response;
    return data;
  }
);

const postalCodesSlice = createSlice({
  name: "postalCodes",
  initialState,
  reducers: {
    clearPostalCodesData: () => initialState,
  },
  /* Use extra reducers for async executions. */
  extraReducers: (builder) => {
    builder
      /* fetchPostalCode */
      .addCase(fetchPostalCode.pending, (state) => {
        state.isLoading = true;
        state.postalCode = null;
        state.source = null;
      })
      .addCase(fetchPostalCode.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.postalCode = payload.postalCode;
        state.source = payload.source;
      })
      .addCase(fetchPostalCode.rejected, (state) => {
        state.isLoading = false;
        state.postalCode = null;
        state.source = null;
      })
      /* fetchStates */
      .addCase(fetchStates.pending, (state) => {
        state.isLoadingStates = true;
        state.states = null;
      })
      .addCase(fetchStates.fulfilled, (state, { payload }) => {
        state.isLoadingStates = false;
        state.states = payload;
      })
      .addCase(fetchStates.rejected, (state) => {
        state.isLoadingStates = false;
        state.states = null;
      })

      /* fetchCities */
      .addCase(fetchCities.pending, (state) => {
        state.isLoadingCities = true;
        state.cities = null;
      })
      .addCase(fetchCities.fulfilled, (state, { payload }) => {
        state.isLoadingCities = false;
        var cities = {};
        payload.forEach((element) => {
          cities[`${element.state_number}`] = element.cities;
        });
        state.cities = cities;
      })
      .addCase(fetchCities.rejected, (state) => {
        state.isLoadingCities = false;
        state.cities = null;
      });
  },
});

export const { clearPostalCodesData } = postalCodesSlice.actions;

export default postalCodesSlice.reducer;
