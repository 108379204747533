import classNames from "classnames";
import { LANGUAGES } from "features/constants";
import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toSelector } from "utils/constantsUtils";
import { removeEmptyProps } from "utils/formUtils";
import { toEmailListEditForm } from "utils/orderUtils";
import { emailRegEx } from "utils/regExs";

const EmailListForm = ({ emailList, onSave, onCancel, title }) => {
	// Translation
	const { t } = useTranslation();
	// Form
	const {
		control,
		register,
		// setValue,
		// getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "email_send_list",
	});
	// Effect
	useEffect(() => {
		reset(toEmailListEditForm(emailList));
	}, [reset, emailList]);
	// Handlers
	const handleSave = (data, event) => {
		event.preventDefault();
		const sendList = {
			email_send_list:
				data.email_send_list?.map((item) => {
					return {
						email: item.email,
						name: item.name,
						lang: item.lang.value,
					};
				}) ?? [],
		};

		onSave(removeEmptyProps(sendList), event);
	};
	const handleCancelEdit = () => {
		reset();
		onCancel();
	};
	// Render
	return (
		<>
			<div className="a order-subtitle">
				<div>{t(title)}</div>
				<div>
					<span className="icon-edit-close" onClick={handleCancelEdit}></span>
				</div>
				<div>
					<span
						className="icon-edit-save"
						onClick={handleSubmit(handleSave)}
					></span>
				</div>
			</div>
			<div className="b-1 top-separation">
				<div className="details-subtitle">{t("Fields.EmailList")}</div>
			</div>
			<div className="b-2 top-separation">
				<div className="right">
					<span
						className="icon-add"
						onClick={() =>
							append({
								email: "",
								name: "",
								lang: {
									value: LANGUAGES.EN,
									label: t(LANGUAGES.properties[LANGUAGES.EN].name),
								},
							})
						}
					/>
				</div>
			</div>

			{fields.map((item, index) => {
				return (
					<>
						<div
							className={classNames("d-1 field", {
								error: errors.email_send_list?.[`${index}`]?.email,
							})}
						>
							<label>
								{errors.email_send_list?.[`${index}`]?.email
									? errors.email_send_list?.[`${index}`]?.email.message
									: t("Common.Email")}
							</label>
							<input
								type="text"
								placeholder={t("Common.Email")}
								{...register(`email_send_list.${index}.email`, {
									required: t("FieldErrors.EmailMissing"),
									pattern: {
										value: emailRegEx,
										message: t("FieldErrors.EmailInvalid"),
									},
								})}
							/>
						</div>
						<div
							className={classNames("d-2 field", {
								error: errors.email_send_list?.[`${index}`]?.name,
							})}
						>
							<label>
								{" "}
								{errors.email_send_list?.[`${index}`]?.name
									? errors.email_send_list?.[`${index}`]?.name.message
									: t("Fields.FullName")}
							</label>
							<input
								type="text"
								placeholder={t("Fields.FullName")}
								{...register(`email_send_list.${index}.name`, {
									required: t("FieldErrors.FullNameMissing"),
								})}
							/>
						</div>
						<div className="d-311 field">
							<label>{t("Fields.Language")}</label>
							<Controller
								name={`email_send_list.${index}.lang`}
								control={control}
								render={({ field }) => (
									<Select
										className="dsv-select"
										classNamePrefix="dsv-select"
										isClearable={false}
										{...field}
										options={toSelector(LANGUAGES.properties, false, true)}
									/>
								)}
							/>
						</div>
						<div className="d-312 field">
							<label>&nbsp;</label>
							<span
								className="icon-remove"
								onClick={() => remove(index)}
							></span>
						</div>
					</>
				);
			})}

			{errors.email_send_list ? (
				<>
					<div className="a field error">
						<label>{t("FieldErrors.EmailListEmpty")}</label>
					</div>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default EmailListForm;
