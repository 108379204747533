import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFloating, autoUpdate, offset, shift } from "@floating-ui/react-dom";
import { useForm } from "react-hook-form";
import {
	CARGO_TYPE,
	FREIGHT_CLASS,
	SHIPMENT_TYPE,
	VOLUME_UNIT,
	WEIGHT_UNIT,
} from "features/constants";
import { numberFormat, stringNumberFormat } from "utils/numberUtils";
import { formatDate } from "utils/dateUtils";
import {
	fetchSaveUserSettings,
	setAccountShowSettings,
} from "features/account/accountSlice";
import Loader from "components/Loader";
import TableSettings from "components/common/tableSettings/TableSettings";
import operator_default from "settings/operator_default.json";
import Pagination from "components/common/pagination/Pagination";
import QuoteForm from "./QuoteForm";
import { fetchAccesorials } from "features/catalogs/accesorialsSlice";
import { fetchLocationTypes } from "features/catalogs/locationTypeSlice";
import { fetchPackagings } from "features/catalogs/packagingsSlice";
import {
	clearPostalCodesData,
	fetchPostalCode,
} from "features/catalogs/postalCodesSlice";
import {
	clearOperatorQuoteRates,
	fetchQuoteCalculation,
	fetchQuoteDetails,
	fetchQuotes,
	setOperatorShowNewQuote,
} from "features/operator/operatorQuotesSlice";
import QuoteRates from "./QuoteRates";
import QuoteDetails from "./QuoteDetails";

export default function Quotes() {
	// Form initializer
	const {
		handleSubmit,
		// formState: { errors },
	} = useForm();
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { isLoading, list, showNewQuote, openQuote, isLoadingQuote, refresh } = useSelector(
		(state) => state.operatorQuotes
	);
	const { isLoadingSettings, isSavingSettings, settings, showSettings } =
		useSelector((state) => state.account);
	const { accesorials } = useSelector((state) => state.accesorials);
	const isLoadingAccessorials = useSelector(
		(state) => state.accesorials.isLoading
	);
	const { locationTypes } = useSelector((state) => state.locationTypes);
	const isLoadinglocationTypes = useSelector(
		(state) => state.locationTypes.isLoading
	);
	const { packagings } = useSelector((state) => state.packagings);
	const isLoadingPackagings = useSelector(
		(state) => state.packagings.isLoading
	);
	const { postalCode, source } = useSelector((state) => state.postalCodes);
	const isLoadingPostalCode = useSelector(
		(state) => state.postalCodes.isLoading
	);

	// State
	const [showDetails, setShowDetails] = useState(false);
	const [collapse, setCollapse] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [activeFilter, setActiveFilter] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [filters, setFilters] = useState({});
	// Filter Form positioning.
	const { refs } = useFloating({
		placement: "bottom-start",
		open: showFilter,
		onOpenChange: setShowFilter,
		middleware: [
			offset(0),
			//flip({ fallbackAxisSideDirection: "end" }),
			shift(),
		],
		whileElementsMounted: autoUpdate,
	});

	const handleCloseFilter = () => {
		setShowFilter(false);
		setActiveFilter(null);
	};
	const handlePageClick = (page) => {
		//
	};
	const handleFilterRefresh = () => {
		dispatch(
			fetchQuotes({
				page: list?.current_page ?? 1,
				filters: { ...filters },
			})
		);
		handleCloseFilter();
	};

	// Effect
	useEffect(() => {
		if (!isLoading && list == null) {
			dispatch(fetchQuotes({ page: 1 }));
		}
		if (openQuote == null && !isLoadingQuote) {
			setShowDetails(false);
		}
		if (openQuote != null) {
			setShowDetails(true);
			dispatch(setOperatorShowNewQuote({showNewQuote: false}));
		}
		if (!isLoading && refresh) {
			handleSubmit(handleFilterRefresh());
		}
		if (accesorials == null && !isLoadingAccessorials) {
			dispatch(fetchAccesorials());
		}
		if (locationTypes == null && !isLoadinglocationTypes) {
			dispatch(fetchLocationTypes());
		}
		if (packagings == null && !isLoadingPackagings) {
			dispatch(fetchPackagings());
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, [
		dispatch,
		isLoading,
		list,
		openQuote,
		isLoadingQuote,
		accesorials,
		isLoadingAccessorials,
		locationTypes,
		isLoadinglocationTypes,
		handleSubmit,
		isLoadingPackagings,
		packagings,
		refresh,
	]);
	// Table Settings
	const userSettings = settings
		? settings.settings.version !== operator_default.settings.version
			? operator_default
			: settings
		: operator_default;
	const tableSettings = userSettings.settings.quotes;
	// Handlers
	const handleShowSettings = () => {
		setShowDetails(false);
		dispatch(setOperatorShowNewQuote({showNewQuote: false}));
		dispatch(setAccountShowSettings({ showSettings: true }));
	};
	const handleCloseSettings = () => {
		setShowDetails(false);
		dispatch(setOperatorShowNewQuote({showNewQuote: false}));
		dispatch(setAccountShowSettings({ showSettings: false }));
	};
	const handleSaveSettings = (data) => {
		const newUserSettings = {
			...userSettings.settings,
			quotes: data,
		};
		dispatch(fetchSaveUserSettings({ data: newUserSettings }));
	};
	const handleQuoteClick = (id, index) => {
		dispatch(setOperatorShowNewQuote({showNewQuote: false}));
		setShowDetails(true);
		dispatch(setAccountShowSettings({ showSettings: false }));
		dispatch(fetchQuoteDetails({ id }));
	};
	const handleNewQuoteClick = () => {
		setShowDetails(false);
		dispatch(setOperatorShowNewQuote({showNewQuote: true}));
		dispatch(setAccountShowSettings({ showSettings: false }));
	};
	const handleQuoteCalculate = (data) => {
		dispatch(fetchQuoteCalculation({ data }));
	};
	const handleCollapse = () => {
		setCollapse(true);
	};
	const handleExpand = () => {
		setCollapse(false);
	};
	const handleClose = () => {
		setShowDetails(false);
		dispatch(setOperatorShowNewQuote({showNewQuote: false}));
		dispatch(clearOperatorQuoteRates());
	};
	const handlePostalCodeBlur = (postalCode, source) => {
		if (postalCode !== "" && postalCode.length === 5) {
			dispatch(fetchPostalCode({ postalCode, source }));
		} else {
			dispatch(clearPostalCodesData());
		}
	};
	const handleClearPostalCodeData = () => {
		dispatch(clearPostalCodesData());
	};
	// Functions
	const getPackaging = (id) => {
		const packaging = packagings?.find((el) => el.id === id);
		return packaging != null ? packaging.name : "";
	};
	const getCssClass = () => {
		if (showDetails || showNewQuote) {
			if (collapse) {
				return "order-container show-details collapse";
			}
			return "order-container show-details";
		}
		if (showSettings) {
			return "order-container show-settings";
		}
		return "order-container";
	};

	// Render
	const renderLoader = () => {
		if (isLoading) {
			return (
				<div>
					<Loader />
				</div>
			);
		}
	};
	const renderSettings = () => {
		if (showSettings) {
			return (
				<TableSettings
					settings={JSON.parse(JSON.stringify(tableSettings))}
					onSave={handleSaveSettings}
					onClose={handleCloseSettings}
					isLoading={isLoadingSettings}
					isSaving={isSavingSettings}
				/>
			);
		}
		return null;
	};
	const renderPagination = () => {
		return (
			<Pagination
				onClick={handlePageClick}
				current={list?.current_page ?? 1}
				last={list?.last_page ?? 1}
				onRefresh={handleFilterRefresh}
			/>
		);
	};
	const renderQuoteForm = () => {
		if (showNewQuote) {
			return (
				<QuoteForm
					quote={null}
					onCalculate={handleQuoteCalculate}
					onClose={handleClose}
					onCollapse={handleCollapse}
					onExpand={handleExpand}
					onBlurPostalCode={handlePostalCodeBlur}
					collapse={collapse}
					accesorials={accesorials}
					locationTypes={locationTypes}
					packagings={packagings}
					postalCodeData={{
						postalCode,
						isLoading: isLoadingPostalCode,
						source,
					}}
					onClearPostalCode={handleClearPostalCodeData}
				/>
			);
		}
	};
	const renderQuoteRates = () => {
		if (showNewQuote) {
			return <QuoteRates onClose={handleClose} />;
		}
	};
	const renderTable = () => {
		if (!isLoading && list != null) {
			const firstActiveClass = filters.quote_number != null ? " active" : "";
			return (
				<div>
					{renderPagination()}
					<div className="dsv-table with-check">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th key={"check_all"}>
										<input type="checkbox" id="select-all" name="select-all" />
									</th>
									<th
										ref={activeFilter === 1 ? refs.setReference : undefined}
										className="no-padding"
										key={"file_number_header"}
									>
										<div className={`table-filter ${firstActiveClass}`}>
											<div>{t("Tables.QuoteNumber")}</div>
											<div className="filter-button"></div>
										</div>
									</th>
									{tableSettings.map((el, index) => {
										if (!el.show) {
											return null;
										}
										const fieldClass = el.filter
											? el.class != null
												? { className: `${el.class} no-padding` }
												: { className: "no-padding" }
											: el.class != null
											? { className: el.class }
											: {};
										const activeClass =
											el.filter_type === "date"
												? filters[`${el.field}_start`] &&
												  filters[`${el.field}_end`] != null
													? "active"
													: ""
												: filters[el.field] != null
												? "active"
												: "";
										if (el.filter) {
											return (
												<th
													{...fieldClass}
													key={index}
													ref={
														activeFilter === el.id
															? refs.setReference
															: undefined
													}
												>
													<div className={`table-filter ${activeClass}`}>
														<div>{t(el.name)}</div>
														<div
															className="filter-button"
															onClick={() => {
																setShowFilter(true);
																setActiveFilter(el.id);
															}}
														>
															<span
																className={
																	filters[el.field] != null ||
																	filters[`${el.field}_start`] != null
																		? "icon-chevron expanded"
																		: "icon-chevron expanded inverted"
																}
															></span>
														</div>
													</div>
												</th>
											);
										}
										return (
											<th {...fieldClass} key={index}>
												{t(el.name)}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody>
								{list?.data?.map((el, index) => {
									const rowProps = {
										onClick: () => handleQuoteClick(el.id, index),
									};
									const activeProps =
										el.id === openQuote?.id ? { className: "active" } : {};
									return (
										<tr {...activeProps} key={index}>
											<td key={`check_box_${index}`}>
												<input
													type="checkbox"
													id={`check-${el.file_number}`}
													name={`check-${el.file_number}`}
												/>
											</td>
											<td {...rowProps} key={`quote_number_${index}`}>
												{stringNumberFormat(el.id, 8)}
											</td>
											{tableSettings.map((field, idx) => {
												if (!field.show) {
													return null;
												}
												const fieldClass =
													field.class != null ? { className: field.class } : {};
												switch (field.field) {
													case "shipment_type":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{t(
																	SHIPMENT_TYPE.properties[el.shipment_type]
																		.name
																)}
															</td>
														);
													case "cargo_type":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{t(CARGO_TYPE.properties[el.cargo_type].name)}
															</td>
														);
													case "packaging_id":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{getPackaging(el[field.field])}
															</td>
														);
													case "pieces":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{numberFormat(el[field.field])}
															</td>
														);
													case "weight":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{numberFormat(el.weight * 1, 2)}{" "}
																{WEIGHT_UNIT.properties[el.weight_unit].code}
															</td>
														);
													case "freight_class":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{el.freight_class != null ? (
																	FREIGHT_CLASS.properties[el.freight_class]
																		.name
																) : (
																	<>&nbsp;</>
																)}
															</td>
														);
													case "cargo_volume":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{el.volume != null ? (
																	`${numberFormat(el.volume * 1, 2)} ${
																		VOLUME_UNIT.properties[el.volume_unit].code
																	}`
																) : (
																	<>&nbsp;</>
																)}
															</td>
														);
													case "created_at":
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{formatDate(
																	el[field.field],
																	t("Dates.DateTime")
																)}
															</td>
														);
													default:
														return (
															<td
																{...rowProps}
																{...fieldClass}
																key={`${index}-${idx}`}
															>
																{el[field.field] ?? <>&nbsp;</>}
															</td>
														);
												}
											})}
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="3">
										{t("Pagination.PageInfo", {
											from: list.from,
											to: list.to,
											total: list.total,
										})}
									</td>
									<td colSpan={tableSettings.length - 2}>&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
					{renderPagination()}
				</div>
			);
		}
	};
	const renderQuoteDetails = () => {
		if (showDetails) {
			return <QuoteDetails onClose={handleClose} />;
		}
	};
	const cssClass = getCssClass();
	return (
		<div id="default">
			<div className={cssClass}>
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("OperatorQuotes.Title")}</h1>
							</div>
							<div
								onClick={handleShowSettings}
								className="table-settings-button"
							>
								<div>
									<span className="icon-settings"></span>
								</div>
								<div>{t("Common.TableSettings")}</div>
							</div>
							<div>
								<span onClick={handleNewQuoteClick} className="icon-new"></span>
							</div>
						</div>
						{renderLoader()}
						{renderTable()}
					</section>
				</div>
				{renderSettings()}
				<div className="order-details">
					{renderQuoteForm()}
					{renderQuoteRates()}
					{renderQuoteDetails()}
				</div>
			</div>
		</div>
	);
}
