import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  downloadOrderDocument,
} from "services/documentServices";

const initialState = {
  isLoading: false,
};

/* Download an order document */
export const fetchOrderDoc = createAsyncThunk(
  "documents/fetchOrderDoc",
  async (payload) => {
    const { id, attachableId, type } = payload;
    const response = await downloadOrderDocument(
      id,
			attachableId,
			type
    );
    const { file_name, content_base64 } = response.data.data;
    const link = document.createElement("a");
    const url = `data:application/octet-stream;charset=windows-1252;base64,${content_base64}`;
    link.href = url;
    link.download = file_name;
    link.click();
    return response;
  }
);


const documents = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentIsLoading: (state, { payload }) => {
      state.isLoading = payload.isLoading ?? false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDoc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderDoc.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchOrderDoc.rejected, (state) => {
        state.isLoading = false;
      })
	  ;
  },
});

export const { setDocumentIsLoading } = documents.actions;

export default documents.reducer;
