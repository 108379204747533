import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PROFILES } from "features/constants";
import { fetchUser } from "features/account/accountSlice";
import MainHeader from "./header/MainHeader";
import SidebarAdmin from "./sidebar/SidebarAdmin";
import Footer from "./footer/Footer";

export default function LoggedInAdmin() {
	const dispatch = useDispatch();
	// Router
	const navigate = useNavigate();
	// Selectors
	const { authenticated, user, checked } = useSelector(
		(state) => state.session
	);
	const { usrChecked, isLoading } = useSelector((state) => state.account);
	// Effect
	useEffect(() => {
		if (checked) {
			if (!authenticated) {
				navigate("/login");
			} else {
				if (!usrChecked && !isLoading) {
					dispatch(fetchUser());
				} else {
					const isAdmin =
						user?.user?.roles?.includes(PROFILES.ADMIN) ||
						user?.user?.roles?.includes(PROFILES.SUPERADMIN);
					if (!isAdmin) {
						navigate("/");
					}
				}
			}
		}
	}, [checked, authenticated, usrChecked, user, isLoading, dispatch, navigate]);
	return (
		<div id="app" className="main-container">
			<MainHeader />
			<SidebarAdmin />
			<div className="content">
				<Outlet />
			</div>
			<Footer className="footer" />
		</div>
	);
}
