import client from "./networking";

/**
 * Gets the carriers list
 * @returns Instance `AxiosResponse`.
 */
export const getCarriers = (page = 1, filters = {}) => {
  return client.post(`/api/carriers?page=${page}`, { filters });
};

/**
 * Gets carrier list as catalog key value pairs
 * @returns Instance `AxiosResponse`.
 */
export const getCarriersCatalog = () => {
  return client.get(`/api/carriers/catalog`);
};



/**
 * Gets a carrier
 * @param {int} carrierId int - carrier id
 * @returns Instance `AxiosResponse`.
 */
export const getCarrier = (carrierId) => {
  return client.get(`/api/carriers/${carrierId}`);
};

/**
 * Creates a carrier
 * @param {object} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addCarrier = (data) => {
  const body = JSON.stringify(data);
  return client.post("/api/carriers/create", body);
};

/**
 * Updates a carrier
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const saveCarrier = (carrierId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/${carrierId}`, body);
};

/**
 * Changes the status of a carrier (active/inactive)
 * @param {int} carrierId
 * @param {boolean} status
 * @returns
 */
export const changeCarrierStatus = (carrierId, data) => {
  return client.put(`/api/carriers/${carrierId}/set_status`, data);
};

/**
 * Gets carrier's users list
 * @param {int} carrierId
 * @param {int} page
 * @param {object} filters
 * @returns
 */
export const getCarrierUsers = (carrierId, page = 1, filters = {}) => {
  return client.post(`/api/carriers/${carrierId}/users?page=${page}`, {
    filters,
  });
};

/**
 * Gets carrier's rate list
 * @param {int} carrierId int - carrier id
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierRates = (carrierId, page = 1, filters = {}) => {
  return client.post(`/api/carriers/${carrierId}/rates?page=${page}`, {
    filters,
  });
};

/**
 * Adds a carrier rate
 * @param {int} carrierId int - carrier id
 * @param {object} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addCarrierRate = (carrierId, data) => {
  const body = JSON.stringify(data);
  return client.post(`/api/carriers/${carrierId}/rates/create`, body);
};

/**
 * Updates a carrier rate.
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const saveCarrierRate = (carrierId, rateId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/${carrierId}/rates/${rateId}`, body);
};

/**
 * Gets carrier's zone list
 * @param {int} carrierId int - carrier id
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierZones = (carrierId, filters = {}) => {
  return client.post(`/api/carriers/${carrierId}/zones`, { filters });
};

/**
 * Gets a carrier zone by zone id
 * @param {int} carrierId 
 * @param {int} zoneId 
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierZone = (carrierId, zoneId) => {
	return client.get(`/api/carriers/${carrierId}/zones/${zoneId}`);
}

/**
 * Gets carrier's zone list
 * @param {int} carrierId int - carrier id
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierZoneExclusions = (carrierId) => {
  return client.get(`/api/carriers/${carrierId}/zones_exception`);
};

/**
 * Gets carrier zones as catalog key value pairs
 * @param {int} carrierId int - carrier id
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierZoneCatalog = (carrierId) => {
  return client.get(`/api/carriers/${carrierId}/zones/catalog`);
};

/**
 * Creates a carrier zone.
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addCarrierZone = (carrierId, data) => {
  return client.post(`/api/carriers/${carrierId}/zones/create`, data);
};

/**
 * Updates a carrier zone.
 * @param {int} carrierId int - carrier id.
 * @param {int} zoneId int - zone id.
 * @param {object} data object - form data.
 * @returns
 */
export const saveCarrierZone = (carrierId, zoneId, data) => {
  return client.put(`/api/carriers/${carrierId}/zones/${zoneId}`, data);
};

/**
 * Creates nultiple carrier zone.
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns Instance `AxiosResponse`.
 */
export const addMultipleCarrierZones = (carrierId, data) => {
  return client.post(`/api/carriers/${carrierId}/zones/create_multiple`, data);
};

/**
 * Updates a carrier zone.
 * @param {int} carrierId int - carrier id.
 * @param {int} zoneId int - zone id.
 * @param {object} data object - form data.
 * @returns
 */
export const saveCarrierZoneExclusions = (carrierId, data) => {
  return client.post(`/api/carriers/${carrierId}/zones_exception`, data);
};

/**
 * Gets a carrier's lane list
 * @param {int} carrierId int - carrier id.
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierLanes = (carrierId, page = 1) => {
  const body = { filters: {} };
  return client.post(
    `/api/carriers/${carrierId}/carrier_lanes?page=${page}`,
    body
  );
};

/**
 * Gets carrier lanes as catalog key value pairs
 * @param {int} carrierId int - carrier id.
 * @returns
 */
export const getCarrierLanesCatalog = (carrierId) => {
  return client.get(`/api/carriers/${carrierId}/carrier_lanes/catalog`);
};

/**
 * Creates a carrier lane.
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns
 */
export const addCarrierLane = (carrierId, data) => {
  const body = JSON.stringify(data);
  return client.post(`/api/carriers/${carrierId}/carrier_lanes/create`, body);
};

/**
 * Updates a carrier lane.
 * @param {int} carrierId int - carrier id.
 * @param {int} laneId int - lane id.
 * @param {object} data object - form data.
 * @returns
 */
export const saveCarrierLane = (carrierId, laneId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/${carrierId}/carrier_lanes/${laneId}`, body);
};

/**
 * Set carrier's accesorials
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns
 */
export const setCarrierAccesorials = (carrierId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/${carrierId}/accesorials`, body);
};

/**
 * Gets carrier's qualifications
 * @param {int} carrierId int - carrier id.
 * @returns
 */
export const getCarrierQualifications = (carrierId) => {
  return client.get(`/api/carriers/${carrierId}/qualifications`);
};

/**
 * Updates carrier's qualifications.
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns
 */
export const setCarrierQualifications = (carrierId, data) => {
  const body = JSON.stringify(data);
  return client.put(`/api/carriers/${carrierId}/qualifications`, body);
};

/**
 * Gets carrier's fuel surcharges
 * @param {int} carrierId int - carrier id.
 * @returns
 */
export const getCarrierFuel = (carrierId) => {
  return client.get(`/api/carriers/${carrierId}/fuel_surcharges`);
};

/**
 * Adds a carrier fuel surcharge
 * @param {int} carrierId int - carrier id.
 * @param {object} data object - form data.
 * @returns
 */
export const addCarrierFuel = (carrierId, data) => {
  const body = JSON.stringify(data);
  return client.post(`/api/carriers/${carrierId}/fuel_surcharges`, body);
};

/**
 * Updates a carrier fuel surcharge
 * @param {int} carrierId int - carrier id.
 * @param {int} fuelId int - fuel surcharge id.
 * @param {object} data object - form data.
 * @returns
 */
export const saveCarrierFuel = (carrierId, fuelId, data) => {
  const body = JSON.stringify(data);
  return client.put(
    `/api/carriers/${carrierId}/fuel_surcharges/${fuelId}`,
    body
  );
};


/**
 * Gets carrier's customers list as catalog key value pairs
 * @returns Instance `AxiosResponse`.
 */
export const getCarrierCustomersCatalog = (carrierId) => {
	return client.get(`/api/carriers/${carrierId}/customers/catalog`);
  };