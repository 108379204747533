import React from "react";
import { useDispatch } from "react-redux";
import { fetchSaveCarrier } from "features/admin/carriersSlice";
import CarrierForm from "./CarrierForm";

export default function CarrierEdit({ onCancel, carrier }) {
	// Dispatch
	const dispatch = useDispatch();

	// Handlers
	const handleSave = (data) => {
		dispatch(fetchSaveCarrier({ carrierId: carrier.id, data }));
	};

	return (
		<>
			<CarrierForm
				onSumbit={handleSave}
				onCancel={onCancel}
				carrier={carrier}
			/>
		</>
	);
}
