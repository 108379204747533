import client from "./networking";

/**
 * Get booked order documents
 * @param {int} id
 * @returns Instance `AxiosResponse`.
 */
export const getBookedOrderDocuments = (id) => {
	return client.get(`/api/booked_orders/${id}/documents`);
};

/**
 * Get order request documents
 * @param {int} id
 * @returns Instance `AxiosResponse`.
 */
export const getOrderRequestDocuments = (id) => {
	return client.get(`/api/order_requests/${id}/documents`);
};

/**
 * Download a booked order document
 * @param {int} id
 * @param {int} attachableId
 * @param {string} type
 * @returns Instance `AxiosResponse`.
 */
export const downloadOrderDocument = (id, attachableId, type) => {
	const endPoint = type.toLowerCase();
	return client.get(`/api/${endPoint}s/${attachableId}/documents/${id}`);
};

/**
 * Upload a document to a booked order
 * @param {int} orderId
 * @param {int} type
 * @param {file} file
 * @param {function} onUploadProgress
 * @returns void
 */
export const uploadBookedOrderDocument = (
	orderId,
	type,
	file,
	file_number,
	onUploadProgress
) => {
	var formData = new FormData();
	formData.append("type", type);
	formData.append("file", file);
	if (file_number != null) {
		formData.append("file_number", file_number);
	}
	return client.post(`api/booked_orders/${orderId}/documents`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress,
	});
};

/**
 * Delete a booked order document
 * @param {int} id
 * @param {int} bookedOrderId
 * @returns
 */
export const deleteBookedOrderDocument = (id, bookedOrderId) => {
	return client.delete(`api/booked_orders/${bookedOrderId}/documents/${id}`);
};

/**
 * Delete an order request document
 * @param {int} id
 * @param {int} orderRequestId
 * @returns
 */
export const deleteOrderRequestDocument = (id, orderRequestId) => {
	return client.delete(`api/order_requests/${orderRequestId}/documents/${id}`);
};

/**
 * Upload a document to an order request
 * @param {int} orderId
 * @param {int} type
 * @param {file} file
 * @param {function} onUploadProgress
 * @returns
 */
export const uploadOrderRequestDocument = (
	orderId,
	type,
	file,
	onUploadProgress
) => {
	var formData = new FormData();
	formData.append("type", type);
	formData.append("file", file);
	return client.post(`api/order_requests/${orderId}/documents`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress,
	});
};
