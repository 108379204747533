import React from "react";
import CarrierOfferRequestDetailsActions from "./CarrierOfferRequestDetailsActions";
import CarrierOfferRequestDetailsAddress from "./CarrierOfferRequestDetailsAddress";
import CarrierOfferRequestDetailsCargo from "./CarrierOfferRequestDetailsCargo";
import CarrierOfferRequestDetailsHeader from "./CarrierOfferRequestDetailsHeader";

export default function CarrierOfferRequestDetails({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const itemProps = {
		onClose: onClose,
		onCollapse: onCollapse,
		onExpand: onExpand,
		collapse: collapse,
	};
	return (
		<div className="order-details">
			<div className="order-info">
				<CarrierOfferRequestDetailsHeader {...itemProps} />
				<CarrierOfferRequestDetailsAddress />
				<CarrierOfferRequestDetailsCargo />
			</div>
			<CarrierOfferRequestDetailsActions {...itemProps} />
		</div>
	);
}
