import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { decimalRegEx, emailRegEx } from "utils/regExs";
import Loader from "components/Loader";
import { WEIGHT_UNIT, LANGUAGES, CURRENCY } from "features/constants";
import { toSelector } from "utils/constantsUtils";
import { toCarrierForm } from "utils/carrierUtils";
import { removeEmptyProps } from "utils/formUtils";
import TextField from "components/form/textField";
import Form from "components/form/form";
import SelectorField from "components/form/selectorField";

export default function CarrierForm({
	onSumbit,
	onCancel,
	onInvalid,
	carrier,
}) {
	const reactForm = useForm();

	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = reactForm;

	const {
		fields: fieldsEmail,
		append: appendEmail,
		remove: removeEmail,
	} = useFieldArray({
		control,
		name: "email_send_list",
		rules: {
			validate: function () {
				const list = getValues(`email_send_list`);
				return list.length > 0;
			},
		},
	});

	const {
		fields: fieldsDimFactorWeightBrackets,
		append: appendDimFactorWeightBrackets,
		remove: removeDimFactorWeightBrackets,
	} = useFieldArray({
		control,
		name: "dim_factor_weight_brackets",
	});

	//   const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { isSaving } = useSelector((state) => state.carriers);
	const { user } = useSelector((state) => state.session);
	// Handlers
	const errorHandler = (data) => {
		if (onInvalid != null) {
			onInvalid(data);
		}
	};
	const handleSave = (data, event) => {
		event.preventDefault();
		if (data.dimensional_factor !== "") {
			data.dimensional_factor = parseFloat(data.dimensional_factor);
		}

		onSumbit(removeEmptyProps(data), event);
	};

	// Effect runs once on display.
	useEffect(() => {
		if (carrier) {
			reset(toCarrierForm(carrier));
		} else {
			setValue("email_send_list", [
				{
					email: "",
					name: "",
					lang: user?.user?.lang ?? LANGUAGES.EN
				},
			]);
		}
	}, [carrier, user?.user?.lang, reset, setValue, t]);

	// Render

	if (isSaving) {
		return (
			<div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}
	return (
		<Form
			reactForm={reactForm}
			onSubmit={handleSave}
			onError={errorHandler}
			layout={"full"}
		>
			<TextField
				className="b-1"
				fieldName="name"
				autoComplete="off"
				label={t("Common.Name")}
				placeholder={t("Common.Name")}
				validations={{
					required: t("FieldErrors.NameMissing"),
				}}
			/>
			<TextField
				className="b-21"
				fieldName={"short_name"}
				label={t("Fields.ShortName")}
				placeholder={t("Fields.ShortName")}
				autoComplete="off"
				validations={{
					required: t("FieldErrors.ShortNameMissing"),
					onChange: (e) => {
						setValue(
							"short_name",
							e.target.value.toLowerCase().replace(/[^a-zA-Z0-9._-]/, "")
						).substring(0, 30);
					},
				}}
			/>
			<TextField
				className="b-22"
				fieldName={"mdg_number"}
				label={t("Fields.MdgNumber")}
				placeholder={t("Fields.MdgNumber")}
				autoComplete="off"
			/>
			<TextField
				className="b-11"
				fieldName={"dimensional_factor"}
				label={t("Fields.DimensionalFactor")}
				placeholder={t("Fields.DimensionalFactor")}
				validations={{
					required: t("FieldErrors.DimFactorMissing"),
					pattern: decimalRegEx,
					valueAsNumber: true,
					onChange: (e) => {
						setValue(
							"dimensional_factor",
							e.target.value.replace(/[^0-9.]/, "")
						);
					},
				}}
			/>
			<SelectorField
				className="b-12"
				fieldName="weight_unit"
				label={t("WeightUnits.Title")}
				options={toSelector(WEIGHT_UNIT.properties, true)}
				isClearable={false}
				required={t("WeightUnits.Title")}
			/>
			<SelectorField
				className="b-2"
				fieldName="currency"
				label={t("Fields.Currency")}
				options={toSelector(CURRENCY.properties)}
				isClearable={false}
				required={t("Fields.Currency")}
			/>

			<div className="b-1 top-separation">
				<div className="details-subtitle">{t("Fields.EmailList")}</div>
			</div>
			<div className="b-2 top-separation">
				<div className="right">
					<span
						className="icon-add"
						onClick={() =>
							appendEmail({
								email: "",
								name: "",
								lang: {
									value: LANGUAGES.EN,
									label: t(LANGUAGES.properties[LANGUAGES.EN].name),
								},
							})
						}
					/>
				</div>
			</div>

			{fieldsEmail.map((item, index) => {
				return (
					<>
						<TextField
							className="d-1"
							fieldName={`email_send_list.${index}.email`}
							label={t("Common.Email")}
							placeholder={t("Common.Email")}
							validations={{
								required: t("FieldErrors.EmailMissing"),
								pattern: {
									value: emailRegEx,
									message: t("FieldErrors.EmailInvalid"),
								},
							}}
						/>
						<TextField
							className="d-2"
							fieldName={`email_send_list.${index}.name`}
							label={t("Fields.FullName")}
							placeholder={t("Fields.FullName")}
							validations={{
								required: t("FieldErrors.FullNameMissing"),
							}}
						/>
						<SelectorField
							className="d-311"
							fieldName={`email_send_list.${index}.lang`}
							label={t("Fields.Language")}
							options={toSelector(LANGUAGES.properties)}
							isClearable={false}
							required={t("Fields.Language")}
						/>
						<div className="d-312 field">
							<label>&nbsp;</label>
							<span
								className="icon-remove"
								onClick={() => removeEmail(index)}
							></span>
						</div>
					</>
				);
			})}

			{errors.email_send_list ? (
				<>
					<div className="a field error">
						<label>{t("FieldErrors.EmailListEmpty")}</label>
					</div>
				</>
			) : (
				<></>
			)}

			<div className="b-1 top-separation">
				<div className="details-subtitle">
					{t("AdminCarriers.DimFactorBrackets")}
				</div>
			</div>
			<div className="b-2 top-separation">
				<div className="right">
					<span
						className="icon-add"
						onClick={() =>
							appendDimFactorWeightBrackets({
								weight_limit: "",
								dim_factor: "",
							})
						}
					/>
				</div>
			</div>

			{fieldsDimFactorWeightBrackets.map((item, index) => {
				return (
					<>
						<TextField
							className="d-1"
							fieldName={`dim_factor_weight_brackets.${index}.weight_limit`}
							label={t("Fields.FromWeight")}
							placeholder={t("Fields.FromWeight")}
							validations={{
								required: t("FieldErrors.DimFactorWeightLimitMissing"),
								pattern: {
									value: decimalRegEx(5),
									valueAsNumber: true,
									message: "Value must be a a number.",
								},
								validate: function (value) {
									return (parseInt(value) ?? 0) > 0;
								},
							}}
						/>
						<TextField
							className="d-2"
							fieldName={`dim_factor_weight_brackets.${index}.dim_factor`}
							label={t("Fields.DimensionalFactor")}
							placeholder={t("Fields.DimensionalFactor")}
							validations={{
								required: t("FieldErrors.DimFactorRequired"),
								valueAsNumber: true,
								pattern: {
									value: decimalRegEx(5),
									message: "Value must be a a number.",
								},
							}}
						/>
						<div className="d-312 field">
							<label>&nbsp;</label>
							<span
								className="icon-remove"
								onClick={() => removeDimFactorWeightBrackets(index)}
							></span>
						</div>
					</>
				);
			})}

			<div
				className={classNames("a field top-separation", {
					error: errors.origin_trucker_notes,
				})}
			>
				<label>{t("Fields.Disclaimer")}</label>
				<textarea
					rows="5"
					placeholder={t("Fields.Disclaimer")}
					{...register("disclaimer", {
						required: false,
					})}
				></textarea>
			</div>

			<div className="b-21 field">
				<label>&nbsp;</label>
				<div className="button" onClick={() => onCancel()}>
					{t("Buttons.Cancel")}
				</div>
			</div>
			<div className="b-22 field">
				<label>&nbsp;</label>
				<div className="button main" onClick={handleSubmit(handleSave)}>
					{t("Buttons.Save")}
				</div>
			</div>
		</Form>
	);
}
