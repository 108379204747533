import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLocationTypes } from "services/catalogsServices";

/**
 * Define the intial state.
 */
const initialState = { isLoading: false, locationTypes: null };

/**
 * Async reducer function to fetch accesorials data.
 */
export const fetchLocationTypes = createAsyncThunk(
  "catalogs/location_types",
  async () => {
    return await getLocationTypes();
  }
);

/**
 * Create and configure slice.
 */
const locationTypesSlice = createSlice({
  name: "location_types",
  initialState,
  reducers: {
		clearLocationTypesData: () => initialState,
	},
  /**
   * Use extra reducers for async executions.
   * @param {*} builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLocationTypes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.locationTypes = payload.data.data;
      })
      .addCase(fetchLocationTypes.rejected, (state) => {
        state.isLoading = false;
				state.locationTypes = [];
      });
  },
});

export const {clearLocationTypesData} = locationTypesSlice.actions;

/**
 * Export slice.
 */
export default locationTypesSlice.reducer;
