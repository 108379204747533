import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setOperatorOrderEdit,
	fetchUpdateOrderAddress,
} from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";
import { fetchAccesorials } from "features/catalogs/accesorialsSlice";
import { fetchLocationTypes } from "features/catalogs/locationTypeSlice";
import OrderAddressForm from "components/common/order/OrderAddressForm";

export default function OperatorOrderDetailsAddressEdit() {
	// Redux dispatch
	const dispatch = useDispatch();
	// Selectors
	const { openOrder, orderEdit, isLoadingOpen, isSaving } = useSelector(
		(state) => state.operatorOrders
	);
	const { accesorials } = useSelector((state) => state.accesorials);
	const isLoadingAccessorials = useSelector(
		(state) => state.accesorials.isLoading
	);
	const { locationTypes } = useSelector((state) => state.locationTypes);
	const isLoadinglocationTypes = useSelector(
		(state) => state.locationTypes.isLoading
	);

	// Effect
	useEffect(() => {
		if (accesorials == null && !isLoadingAccessorials) {
			dispatch(fetchAccesorials());
		}
		if (locationTypes == null && !isLoadinglocationTypes) {
			dispatch(fetchLocationTypes());
		}
	}, [
		dispatch,
		accesorials,
		isLoadingAccessorials,
		locationTypes,
		isLoadinglocationTypes,
	]);

	// Función que ejecuta.
	const handleSaveAddressData = (data) => {
		// Call Service.
		if (openOrder != null) {
			dispatch(
				fetchUpdateOrderAddress({ orderId: openOrder.id, formData: data })
			);
		}
	};

	const handleCancelEdit = () => {
		// Udate redux's state.
		dispatch(setOperatorOrderEdit(null));
	};

	// Render
	if (
		orderEdit != null &&
		orderEdit === ORDER_EDIT.ADDRESS &&
		openOrder != null
	) {
		return (
			<OrderAddressForm
				order={openOrder}
				edit={orderEdit}
				onSave={handleSaveAddressData}
				onCancel={handleCancelEdit}
				isSaving={isSaving}
				isLoading={isLoadingOpen}
				accesorials={accesorials}
				locationTypes={locationTypes}
			/>
		);
	}

	return null;
}
