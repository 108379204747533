import client from "./networking";
import { USER_ROLE } from "features/constants";

/**
 * Gets list of users by type of user
 * @param {*} role int (USER_ROLE)
 * @returns Instance `AxiosResponse`.
 */
export const getUsers = (role, page = 1) => {
  const body = JSON.stringify({
    role_id: role,
  });
  return client.post(`/api/users?page=${page}`, body);
};

/**
 * Gets details of a single user
 * @param {*} id User identifier.
 * @returns Instance `AxiosResponse`.
 */
export const getUser = (id) => {
  return client.get(`/api/users/${id}`);
};

/**
 * Creates user.
 *
 * @param {*} role .
 * @param {*} carrierData form data to crate user.
 * @returns Instance `AxiosResponse`.
 */
export function addUser(role, data) {
  var path = "";

  switch (role) {
    case USER_ROLE.CARRIER:
      path = "carrier";
      break;
    case USER_ROLE.OPERATOR:
      path = "operator";
      break;
    case USER_ROLE.ADMIN:
      path = "admin";
      break;
    default:
  }

  if (path === "") throw new Error("Invalid role");

	const body = JSON.stringify(data);

  // Send request.
  return client.post(`/api/users/${path}`, body);
}

/**
 * Updates user info.
 *
 * @param {*} id user identifier.
 * @param {*} data form data to update.
 * @returns Instance `AxiosResponse`.
 */
export function saveUser(id, data) {
  // Send request.
	const body = JSON.stringify(data);
  return client.put(`/api/users/${id}/update-info`, body);
}

/**
 * Delete user
 *
 * @param {*} id User id
 * @returns Instance `AxiosResponse`.
 */
export const deleteUser = (id) => {
  return client.delete(`/api/users/delete/${id}`);
};

/**
 * Updates enabled/disabled state of a user.
 *
 * @param {*} id User id
 * @param {*} value New value
 * @returns Instance `AxiosResponse`.
 */
export const setUserEnable = (id, value) => {
  const body = JSON.stringify({
    enable: value,
  });
  return client.put(`/api/users/status/${id}`, body);
};

/**
 * Changes user password.
 *
 * @param {*} id user identifier.
 * @param {*} data form data to update.
 * @returns Instance `AxiosResponse`.
 */
export function changeUserPassword(id, data) {
  // Send request.
  return client.put(`/api/users/${id}/change-password`, data);
}

/**
 * Gets the granches list
 * @returns Instance `AxiosResponse`.
 */
export const getBranches = () => {
  return client.get("/api/branches");
};
