import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PROFILES, USER_ROLE } from "features/constants";
import {
	fetchUsers,
	setUserEdit,
	setUserOpen,
	setUserPwdChange,
	setUsersShowAdd,
	setUsersShowDetails,
	setUsersTab,
} from "features/admin/usersSlice";
import { useNavigate } from "react-router-dom";

export default function UserTabs() {
	const dispatch = useDispatch();
	// Translations
	const { t } = useTranslation();
	// Navigate
	const navigate = useNavigate();
	// Selectors
	const { tab } = useSelector((state) => state.users);
	const { user } = useSelector((state) => state.session);
	// Handlers
	const handleClick = (tab) => {
		dispatch(fetchUsers({ role: tab }));
		dispatch(setUsersTab({ tab }));
		dispatch(setUserOpen({ open: null }));
		dispatch(setUsersShowAdd({ showAdd: false }));
		dispatch(setUserEdit({ edit: false }));
		dispatch(setUserPwdChange({ pwdChange: false }));
		dispatch(setUsersShowDetails({ showDetails: false }));
		navigate(USER_ROLE.properties[tab].url);
	};
	const tabsProps = {
		carriers:
			tab === USER_ROLE.CARRIER
				? { className: "active" }
				: { onClick: () => handleClick(USER_ROLE.CARRIER) },
		operators:
			tab === USER_ROLE.OPERATOR
				? { className: "active" }
				: { onClick: () => handleClick(USER_ROLE.OPERATOR) },
		admins:
			tab === USER_ROLE.ADMIN
				? { className: "active" }
				: { onClick: () => handleClick(USER_ROLE.ADMIN) },
		superAdmins:
			tab === USER_ROLE.SUPERADMIN
				? { className: "active" }
				: { onClick: () => handleClick(USER_ROLE.SUPERADMIN) },
	};
	return (
		<div className="dsv-tabs no-margin">
			<div {...tabsProps.carriers}>{t("UserRoles.Carrier", { count: 2 })}</div>
			<div {...tabsProps.operators}>
				{t("UserRoles.Operator", { count: 2 })}
			</div>
			<div {...tabsProps.admins}>{t("UserRoles.Admin", { count: 2 })}</div>
			{user?.user?.role === PROFILES.SUPERADMIN ? (
				<div {...tabsProps.superAdmins}>
					{t("UserRoles.SuperAdmin", { count: 2 })}
				</div>
			) : null}
		</div>
	);
}
