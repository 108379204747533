import OrderHeader from "components/common/order/OrderHeader";
import { setCarrierOfferActiveTab } from "features/carrier/carrierOffersSlice";
import { PROFILES } from "features/constants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function CarrierOfferRequestDetailsHeader({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const dispatch = useDispatch();
	// Selectors
	const { isOpening, opened, tabs, activeTab } = useSelector(
		(state) => state.carrierOffers
	);
	// Handlers
	const handleTabClick = (tab) => {
		dispatch(setCarrierOfferActiveTab({ activeTab: tab }));
	};
	// Render
	if (opened != null) {
		const bookedOrder = opened?.booked_order;
		const order =
			bookedOrder != null
				? activeTab === 0
					? bookedOrder?.order_requests[activeTab] ?? null
					: bookedOrder?.order_requests[activeTab - 1] ?? null
				: null;
		return (
			<OrderHeader
				order={order}
				edit={null}
				isLoading={isOpening}
				consolidate={false}
				tabs={bookedOrder?.order_count > 1 ? tabs : null}
				activeTab={activeTab}
				onClose={onClose}
				onCollapse={onCollapse}
				onExpand={onExpand}
				onEdit={() => {}}
				onTabClick={handleTabClick}
				collapse={collapse}
				profile={PROFILES.CARRIER}
				booked={true}
				bookedOrder={bookedOrder}
				file_number={bookedOrder?.file_number}
			/>
		);
	}
	// 		const collapseProps = collapse
	// 	? { className: "icon-expand", onClick: handleExpandClick }
	// 	: { className: "icon-collapse", onClick: handleCollapseClick };
	// const renderLoader = () => {
	// 	if (isOpening) {
	// 		return (
	// 			<>
	// 				<div className="order-block">
	// 					<Loader msg={t("Common.Loading")} />
	// 				</div>
	// 				<div className="order-refs">&nbsp;</div>
	// 			</>
	// 		);
	// 	}
	// 	return null;
	// };
	// const renderOrderFile = () => {
	// 	if (!isOpening && opened != null) {
	// 		return (
	// 			<div className="order-file">
	// 				<div>
	// 					<div>
	// 						<span {...collapseProps}></span>
	// 					</div>
	// 					<div className="file-number">
	// 						<div>{opened.file_number}</div>
	// 						<div>
	// 							<em>{t("Tables.Transport")}</em>{" "}
	// 							{SHIPMENT_TYPE.properties[opened.transport_shipment_type].code}{" "}
	// 							/ {t(CARGO_TYPE.properties[opened.transport_cargo_type].name)}
	// 						</div>
	// 					</div>
	// 					<div>
	// 						<span className="icon-close" onClick={handleCloseClick}></span>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		);
	// 	}
	// 	return null;
	// };
	// const renderOrderRefs = () => {
	// 	if (!isOpening && opened != null) {
	// 		const { booked_order } = opened;
	// 		const order_request = booked_order.order_requests[0]
	// 		return <OrderRefs order={order_request} />;
	// 	}
	// 	return null;
	// };
	// return (
	// 	<div>
	// 		{renderLoader()}
	// 		{renderOrderFile()}
	// 		{renderOrderRefs()}
	// 	</div>
	// );
}
