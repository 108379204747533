export const removeEmptyProps = (obj) => {
  for (let propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      Number.isNaN(obj[propName])
    ) {
      delete obj[propName];
    } else {
      const isArray = Array.isArray(obj);
      const isObject = typeof obj[propName] === "object";
      if (isArray || isObject) {
        const cleanProp = removeEmptyProps(obj[propName]);
        if (cleanProp.length === 0 || Object.keys(cleanProp).length === 0) {
          delete obj[propName];
        } else {
          obj[propName] = cleanProp;
        }
      }
    }
  }
  return obj;
};

export const removeNullProps = (obj) => {
  for (let propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      Number.isNaN(obj[propName])
    ) {
      delete obj[propName];
    } else {
      const isObject = typeof obj[propName] === "object";
      if (isObject) {
        const cleanProp = removeNullProps(obj[propName]);
        obj[propName] = cleanProp;
      }
    }
  }
  return obj;
};

export const removeEmptyStrings = (obj) => {
	for (let propName in obj) {
		if (
			obj[propName] !== null && (
			obj[propName] === undefined ||
			obj[propName] === "" ||
			Number.isNaN(obj[propName]))
		) {
			delete obj[propName];
		} else {
			if (obj[propName] == null) {
				obj[propName] = null;
			} 
			else {
			const isArray = Array.isArray(obj);
			const isObject = typeof obj[propName] === "object";
			if (isArray || isObject) {
				const cleanProp = removeEmptyProps(obj[propName]);
				if (cleanProp.length === 0 || Object.keys(cleanProp).length === 0) {
					delete obj[propName];
				} else {
					obj[propName] = cleanProp;
				}
			}}
		}
	}
return obj;
};
