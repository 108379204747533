import CarrierOrders from "components/common/order/CarrierOrders";
import {
	fetchCarrierOrderDetails,
	fetchCarrierOrders,
	setCarrierOpenedOrder,
	setCarrierOrderActiveTab,
	setCarrierOrderDocs,
	setCarrierOrderLog,
	setCarrierOrderShowUpload,
	setCarrierOrderStatusError,
	setCarrierOrderTabs,
} from "features/carrier/carrierOrdersSlice";
import { BOOKED_STATUS as BS, CARRIER_FILTERS } from "features/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCarrierFilters } from "utils/carrierUtils";
import carrier_default from "settings/carrier_default.json";
import CarrierBookedOrderDetails from "./CarrierBookedOrderDetails";
import {
	fetchSaveUserSettings,
	setAccountShowSettings,
} from "features/account/accountSlice";
import TableSettings from "components/common/tableSettings/TableSettings";

export default function CarrierBookedOrders() {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { list, isLoading, opened, isOpening, refresh } = useSelector(
		(state) => state.carrierOrders
	);
	const { isLoadingSettings, isSavingSettings, settings, showSettings } =
		useSelector((state) => state.account);
	const { user } = useSelector((state) => state.session);

	// State
	const [showDetails, setShowDetails] = useState(false);
	const [collapse, setCollapse] = useState(false);
	// Effect
	useEffect(() => {
		if (!isLoading && list == null) {
			const filters = getCarrierFilters(CARRIER_FILTERS.BOOKED);
			dispatch(fetchCarrierOrders({ filters, page: 1 }));
		}
		if (opened == null && !isOpening) {
			setShowDetails(false);
		}
	}, [isLoading, list, opened, isOpening, dispatch]);

	// Table Settings
	const userSettings = settings
		? settings.settings.version !== carrier_default.settings.version
			? carrier_default
			: settings
		: carrier_default;
	const tableSettings = userSettings.settings.carrier_orders;

	// Handlers
	const handleOrderClick = (id) => {
		dispatch(setCarrierOrderActiveTab({ activeTab: 0 }));
		dispatch(fetchCarrierOrderDetails({ id }));
		dispatch(setCarrierOrderStatusError({ changeStatusError: null }));
		dispatch(setCarrierOrderShowUpload({ showUpload: false }));
		setShowDetails(true);
	};
	const handleOrderClose = () => {
		dispatch(setCarrierOpenedOrder({ opened: null }));
		dispatch(setCarrierOrderTabs({ tabs: null }));
		dispatch(setCarrierOrderActiveTab({ activeTab: 0 }));
		dispatch(setCarrierOrderDocs({ docs: null }));
		dispatch(setCarrierOrderLog({ log: null }));
		dispatch(setCarrierOrderShowUpload({ showUpload: false }));
		dispatch(setCarrierOrderStatusError({ changeStatusError: null }));
		setShowDetails(false);
		setCollapse(false);
		// setActiveRow(null);
	};
	const handleOrderCollapse = () => {
		setCollapse(true);
	};
	const handleOrderExpand = () => {
		setCollapse(false);
	};
	const handleFilterRefresh = (filters) => {
		dispatch(fetchCarrierOrders({ filters, page: list?.current_page ?? 1 }));
	};
	const handleFilterSubmit = (filters) => {
		dispatch(fetchCarrierOrders({ page: 1, filters }));
	};
	const handlePageClick = (filters, page) => {
		dispatch(fetchCarrierOrders({ filters, page }));
	};
	const handleShowSettings = () => {
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: true }));
	};
	const handleCloseSettings = () => {
		setShowDetails(false);
		dispatch(setAccountShowSettings({ showSettings: false }));
	};
	const handleSaveSettings = (data) => {
		const userSettings = {
			...carrier_default.settings,
			carrier_orders: data,
		};
		dispatch(fetchSaveUserSettings({ data: userSettings }));
	};

	// Render
	const getCssClass = () => {
		if (showDetails) {
			if (collapse) {
				return "order-container show-details collapse";
			}
			return "order-container show-details";
		}
		if (showSettings) {
			return "order-container show-settings";
		}
		return "order-container";
	};
	const renderSettings = () => {
		if (showSettings) {
			return (
				<TableSettings
					settings={JSON.parse(JSON.stringify(tableSettings))}
					onSave={handleSaveSettings}
					onClose={handleCloseSettings}
					isLoading={isLoadingSettings}
					isSaving={isSavingSettings}
				/>
			);
		}
		return null;
	};
	const renderTable = () => {
		const statusOptions = [
			{ value: BS.PENDING, label: t(BS.properties[BS.PENDING].name) },
			{ value: BS.SUBMITTED, label: t(BS.properties[BS.SUBMITTED].name) },
			{ value: BS.BOOKED, label: t(BS.properties[BS.BOOKED].name) },
			{
				value: BS.ARRIVED_PICKUP,
				label: t(BS.properties[BS.ARRIVED_PICKUP].name),
			},
			{ value: BS.PICKED_UP, label: t(BS.properties[BS.PICKED_UP].name) },
			{ value: BS.IN_TRANSIT, label: t(BS.properties[BS.IN_TRANSIT].name) },
			{
				value: BS.ARRIVED_DELIVERY,
				label: t(BS.properties[BS.ARRIVED_DELIVERY].name),
			},
		];
		const defaultFilters = CARRIER_FILTERS.properties[CARRIER_FILTERS.BOOKED].filters
		return (
			<CarrierOrders
				list={list}
				onClick={handleOrderClick}
				activeRow={opened}
				settings={tableSettings}
				user={user}
				statusOptions={statusOptions}
				isLoading={isLoading}
				refresh={refresh}
				onRefresh={handleFilterRefresh}
				onFilterSubmit={handleFilterSubmit}
				onPageClick={handlePageClick}
				defaultFilters={defaultFilters}
			/>
		);
	};
	const cssClass = getCssClass();
	return (
		<div id="default">
			<div className={cssClass}>
				<div className="order-list">
					<section id="order-requests">
						<div className="dsv-title">
							<div>
								<h1>{t("CarrierBookedOrders.Title")}</h1>
							</div>
							<div
								className="table-settings-button"
								onClick={() => handleShowSettings()}
							>
								<div>
									<span className="icon-settings"></span>
								</div>
								<div>{t("Common.TableSettings")}</div>
							</div>
						</div>
						{renderTable()}
					</section>
				</div>
				{renderSettings()}
				<CarrierBookedOrderDetails
					onClose={handleOrderClose}
					onCollapse={handleOrderCollapse}
					onExpand={handleOrderExpand}
					collapse={collapse}
				/>
			</div>
		</div>
	);
}
