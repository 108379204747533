import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches, setUserEdit } from "features/admin/usersSlice";
import { fetchSaveUser, setEditErrorNull } from "features/admin/usersSlice";
import { useTranslation } from "react-i18next";
import { USER_ROLE } from "features/constants";
import Loader from "components/Loader";
import UserForm from "./UserForm";

export default function UserEdit() {
	// Translation
	const { t } = useTranslation();
	// Dispatch
	const dispatch = useDispatch();
	// Selectors
	const { tab, open, isSaving, isLoadingBranches, branches } =
		useSelector((state) => state.users);
	const { isLoading } = useSelector((state) => state.carriers);
	const { user } = useSelector((state) => state.session);

	useEffect(() => {
		// Fetch branch list
		if (tab === USER_ROLE.OPERATOR && branches == null && !isLoadingBranches) {
			dispatch(fetchBranches());
		}
	}, [
		dispatch,
		tab,
		branches,
		isLoading,
		isLoadingBranches,
	]);

	// Handlers
	const handleSetError = (error) => {
		dispatch(setEditErrorNull(error));
	}
	const handleClose = () => {
		dispatch(setUserEdit({ edit: false }))
	};
	const handleSave = (data) => {
		dispatch(fetchSaveUser({ id: open.id, data }));
	};

	if (isSaving || isLoadingBranches || isLoading) {
		return (
			<div className="details-block edit">
				<div className="order-subtitle">
					<div>{t("AdminUsers.UserInfo")}</div>
				</div>
				<Loader msg={t("Common.Saving")} />
			</div>
		);
	}
	const formProps = {
		open,
		user,
		branches,
		setError: handleSetError,
		onClose: handleClose,
		onSubmit: handleSave,
	};
	return <UserForm {...formProps}/>
}
