import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TruckerInfo from "components/common/order/TruckerInfo";
import { BOOKED_ORDER_EDIT } from "features/constants";
import { setCarrierOrderEdit } from "features/carrier/carrierOrdersSlice";

const CarrierBookedOrderDetailsTruckerInfo = () => {
	const dispatch = useDispatch();
	// Selectors
	const { opened, edit, isOpening } = useSelector((state) => state.carrierOrders);
	// Handlers
	const handleEdit = () => {
		dispatch(setCarrierOrderEdit({edit: BOOKED_ORDER_EDIT.TRUCKER_INFO}));
	};
	if (edit === BOOKED_ORDER_EDIT.TRUCKER_INFO) return null;
	return (
		<TruckerInfo order={opened} edit={edit} onEdit={handleEdit} isLoading={isOpening} />
	);
};

export default CarrierBookedOrderDetailsTruckerInfo;