import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import Select from "react-select";

export default function ListFilter({ fieldItem, options, control }) {
  const { t } = useTranslation();

  // Render
  return (
    // TODO: Display errors.
    <div className="a field">
      <label>{t(fieldItem.name)}</label>
      <Controller
        name={fieldItem.field}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <Select
            className="dsv-select"
            classNamePrefix="dsv-select"
            placeholder={t(fieldItem.name)}
            isClearable={false}
            closeMenuOnSelect={!((options?.length ?? 0) > 2)}
            value={value}
            onChange={onChange}
            isMulti={true}
            options={options}
          />
        )}
      />
    </div>
  );
}
