import React from "react";
import OrderContainer from "components/common/order/OrderContainer";
import { useDispatch, useSelector } from "react-redux";
import { setOperatorOrderEdit } from "features/operator/operatorOrdersSlice";
import { ORDER_EDIT } from "features/constants";

const OperatorOrderDetailsContainerInfo = () => {
	const dispatch = useDispatch();
	// Selector
	const { openOrder, isLoadingOpen, orderEdit } = useSelector(
		(state) => state.operatorOrders
	);
	// Handlers
	const handleEditClick = () => {
		dispatch(setOperatorOrderEdit({ orderEdit: ORDER_EDIT.CONTAINER }));
	};
	if (openOrder == null) return null;
	const booked = openOrder?.booked_orders?.length > 0;
	return (
		<OrderContainer
			order={openOrder}
			isLoading={isLoadingOpen}
			onEdit={handleEditClick}
			booked={booked}
			edit={orderEdit}
		/>
	);
};

export default OperatorOrderDetailsContainerInfo;
