import Loader from "components/Loader";
import { FREIGHT_CLASS, ORDER_EDIT, SUBSTANCE_GROUP } from "features/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDimension } from "utils/dimensionUtils";
import { numberFormat } from "utils/numberUtils";
import { getBookedOrderCargoSummary } from "utils/orderUtils";
import { formatTemperature } from "utils/temperatureUtils";
import { formatVolume } from "utils/volumeUtils";
import { formatWeight } from "utils/weightUtils";

export default function OrderCargo({
	order,
	edit,
	isLoading,
	packagings,
	onEdit,
	booked,
	bookedOrder,
	activeTab,
}) {
	// Translation
	const { t } = useTranslation();
	// Handlers
	const handleEditClick = () => {
		onEdit();
	};
	// Render
	const getPackaging = (id) => {
		if (id == null) return <>&nbsp;</>;
		const packaging = packagings?.find((el) => el.id === id);
		return packaging != null ? packaging.name : "";
	};
	const renderLoader = () => {
		return (
			<div className="order-block top-border">
				<Loader msg={t("Common.Loading")} />
			</div>
		);
	};
	const renderHazMat = () => {
		const {
			special_handlings_hazmat,
			special_handlings_hazmat_substance,
			special_handlings_hazmat_group,
			special_handlings_hazmat_class_value,
			special_handlings_hazmat_flashpoint,
			special_handlings_hazmat_flashpoint_unit,
		} = order;
		if (special_handlings_hazmat) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("SpecialHandlings.Hazmat")}</div>
						</div>
						<div>
							<div>{t("Fields.Substance")}</div>
							<div>{special_handlings_hazmat_substance ?? <>&nbsp;</>}</div>
						</div>
						<div>
							<div>{t("Fields.SubstanceGroup")}</div>
							<div>
								{special_handlings_hazmat_group != null ? (
									SUBSTANCE_GROUP.properties[special_handlings_hazmat_group]
										.name
								) : (
									<>&nbsp;</>
								)}
							</div>
						</div>
						<div>
							<div>{t("Fields.SubstanceClass")}</div>
							<div>{special_handlings_hazmat_class_value ?? <>&nbsp;</>}</div>
						</div>
						<div>
							<div>{t("Fields.FlashPoint")}</div>
							{/* TODO: add unit.*/}
							<div>
								{formatTemperature(
									special_handlings_hazmat_flashpoint,
									special_handlings_hazmat_flashpoint_unit??2
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderSummaryHazMat = (show) => {
		if (show) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("SpecialHandlings.Hazmat")}</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderTemperatureControl = () => {
		const {
			special_handlings_tempctl,
			special_handlings_tempctl_min,
			special_handlings_tempctl_max,
			special_handlings_tempctl_unit,
		} = order;
		if (special_handlings_tempctl) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("SpecialHandlings.Tempctl")}</div>
						</div>
						<div>
							<div>{t("Common.Min")}</div>
							<div>
								{formatTemperature(
									special_handlings_tempctl_min,
									special_handlings_tempctl_unit??2
								)}
							</div>
						</div>
						<div>
							<div>{t("Common.Max")}</div>
							<div>
								{formatTemperature(
									special_handlings_tempctl_max,
									special_handlings_tempctl_unit??2
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderSummaryTempCtl = (show) => {
		if (show) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("SpecialHandlings.Tempctl")}</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderEmptySpecialHandlings = () => {
		if (!order.special_handlings_hazmat && !order.special_handlings_tempctl) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("OperatorOrders.NoSpecialHandlings")}</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const renderSummaryEmptySpecialHandlings = (hazmat, tempctl) => {
		if (!hazmat && !tempctl) {
			return (
				<div className="flex-table single-line">
					<div>
						<div>
							<div>{t("OperatorOrders.NoSpecialHandlings")}</div>
						</div>
					</div>
				</div>
			);
		}
	};
	const renderEdit = () => {
		if (!booked && edit == null) {
			return (
				<div>
					<span className="icon-edit" onClick={handleEditClick}></span>
				</div>
			);
		}
		return null;
	};
	if (edit === ORDER_EDIT.CARGO) {
		return null;
	}
	if (isLoading) {
		return renderLoader();
	}
	if (booked && bookedOrder != null) {
		if (bookedOrder.order_requests.length > 1 && activeTab === 0) {
			const summary = getBookedOrderCargoSummary(bookedOrder);
			return (
				<>
					<div className="order-block top-border">
						<div className="order-subtitle">
							<div>{t("Common.CargoSummary")}</div>
						</div>
						<div className="flex-table remove-bottom-space">
							<div>
								<div>
									<div>{t("Tables.Pieces")}</div>
									<div>{numberFormat(summary.pieces) ?? <>&nbsp;</>}</div>
								</div>
								<div>
									<div>{t("Tables.Weight")}</div>
									<div>{formatWeight(summary.weight, summary.weight_unit)}</div>
								</div>
								<div>
									<div>{t("Tables.Volume")}</div>
									<div>{formatVolume(summary.volume, summary.volume_unit)}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cargo-grid">
						<div className="cargo-details">
							<div className="order-subtitle no-margin">
								<div>{t("Common.Details")}</div>
							</div>
							<div className="details-table">
								<table cellSpacing="0" cellPadding="0">
									<thead>
										<tr>
											<th>{t("Tables.Qty")}</th>
											<th>{t("Tables.Description")}</th>
											<th>{t("Tables.Packaging")}</th>
											<th>{t("Tables.Weight")}</th>
											<th>{t("Tables.Dimensions")}</th>
										</tr>
									</thead>
									<tbody>
										{summary.details.map((el, index) => {
											const {
												pieces,
												description,
												packaging,
												total_weight,
												weight_unit,
												length,
												width,
												height,
												unit,
											} = el;
											return (
												<tr key={index}>
													<td>{Number(pieces) ?? <>&nbsp;</>}</td>
													<td>{description ?? <>&nbsp;</>}</td>
													<td>{getPackaging(packaging)}</td>
													<td className="right">{formatWeight(total_weight, weight_unit)}</td>
													<td>
														{formatDimension(length, width, height, unit)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
						<div className="order-block remove-top-space">
							<div className="order-subtitle no-margin">
								<div>{t("SpecialHandlings.Title")}</div>
							</div>
							{renderSummaryHazMat(summary.hazmat)}
							{renderSummaryTempCtl(summary.tempctl)}
							{renderSummaryEmptySpecialHandlings(
								summary.hazmat,
								summary.tempctl
							)}
						</div>
					</div>
				</>
			);
		}
	}
	if (order != null) {
		const {
			cargo_pieces,
			cargo_packaging_id,
			cargo_actual_weight,
			cargo_actual_weight_unit,
			cargo_volume,
			cargo_volume_unit,
			cargo_description,
			cargo_details,
			cargo_freight_class,
		} = order;
		return (
			<>
				<div className="order-block top-border">
					<div className="order-subtitle">
						<div>{t("Common.Cargo")}</div>
						{renderEdit()}
					</div>
					<div className="flex-table remove-bottom-space">
						<div>
							<div>
								<div>{t("Tables.Pieces")}</div>
								<div>{cargo_pieces ?? <>&nbsp;</>}</div>
							</div>
							<div>
								<div>{t("Tables.Packaging")}</div>
								<div>{getPackaging(cargo_packaging_id)}</div>
							</div>
							<div>
								<div>{t("Tables.Weight")}</div>
								<div>
									{formatWeight(cargo_actual_weight, cargo_actual_weight_unit)}
								</div>
							</div>
							<div>
								<div>{t("Tables.Volume")}</div>
								<div>{formatVolume(cargo_volume, cargo_volume_unit)}</div>
							</div>
							<div>
								<div>{t("Tables.FreightClass")}</div>
								<div>
									{cargo_freight_class != null ? (
										FREIGHT_CLASS.properties[cargo_freight_class].name
									) : (
										<>&nbsp;</>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="flex-table single-line remove-bottom-space">
						<div>
							<div>
								<div>{t("Tables.CargoDescription")}</div>
								<div>{cargo_description ?? <>&nbsp;</>}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="cargo-grid">
					<div className="cargo-details">
						<div className="order-subtitle no-margin">
							<div>{t("Common.Details")}</div>
						</div>
						<div className="details-table">
							<table cellSpacing="0" cellPadding="0">
								<thead>
									<tr>
										<th>{t("Tables.Qty")}</th>
										<th>{t("Tables.Description")}</th>
										<th>{t("Tables.Packaging")}</th>
										<th>{t("Tables.Weight")}</th>
										<th>{t("Tables.Dimensions")}</th>
									</tr>
								</thead>
								<tbody>
									{cargo_details.map((el, index) => {
										const {
											pieces,
											description,
											packaging,
											total_weight,
											weight_unit,
											length,
											width,
											height,
											unit,
										} = el;
										return (
											<tr key={index}>
												<td>{Number(pieces) ?? <>&nbsp;</>}</td>
												<td>{description ?? <>&nbsp;</>}</td>
												<td>{getPackaging(packaging)}</td>
												<td>{formatWeight(total_weight, weight_unit)}</td>
												<td>{formatDimension(length, width, height, unit)}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
					<div className="order-block remove-top-space">
						<div className="order-subtitle no-margin">
							<div>{t("SpecialHandlings.Title")}</div>
						</div>
						{renderHazMat()}
						{renderTemperatureControl()}
						{renderEmptySpecialHandlings()}
					</div>
				</div>
			</>
		);
	}
	return null;
}
