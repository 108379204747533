import React from "react";
import CarrierOrderHistoryDetailsAddress from "./CarrierOrderHistoryDetailsAddress";
import CarrierOrderHistoryDetailsActions from "./CarrierOrderHistoryDetailsActions";
import CarrierOrderHistoryDetailsCargo from "./CarrierOrderHistoryDetailsCargo";
import CarrierOrderHistoryDetailsHeader from "./CarrierOrderHistoryDetailsHeader";

export default function CarrierOrderHistoryDetails({
	onClose,
	onCollapse,
	onExpand,
	collapse,
}) {
	const itemProps = {
		onClose: onClose,
		onCollapse: onCollapse,
		onExpand: onExpand,
		collapse: collapse,
	};
	return (
		<div className="order-details">
			<div className="order-info">
				<CarrierOrderHistoryDetailsHeader {...itemProps} />
				<CarrierOrderHistoryDetailsAddress />
				<CarrierOrderHistoryDetailsCargo />
			</div>
			<CarrierOrderHistoryDetailsActions {...itemProps} />
		</div>
	);
}
