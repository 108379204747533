import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import {
  setCarrierFuelEdit,
  fetchCarrierFuel,
} from "features/admin/carriersSlice";
import { CARRIER_TAB } from "features/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CarrierDetailsFuelEdit from "./CarrierDetailsFuelEdit";

export default function CarrierDetailsFuel() {
  const dispatch = useDispatch();
  // Translate
  const { t } = useTranslation();
  // Selectors
  const { tab, open, fuels, fuelEdit, isLoadingFuel } = useSelector(
    (state) => state.carriers
  );

  useEffect(() => {
    if (fuels === null && !isLoadingFuel) {
      dispatch(fetchCarrierFuel({ carrierId: open.id }));
    }
  }, [dispatch, fuels, isLoadingFuel, open]);

  // Handlers
  const handleEditClick = () => {
    dispatch(setCarrierFuelEdit(true));
  };
  const [activeRow, setActiveRow] = useState(null);

  const handleFuelClick = (item, index) => {
    setActiveRow(index);
    dispatch(setCarrierFuelEdit(true));
  };

  // Render

  if (tab !== CARRIER_TAB.FUEL) return null;
  if (isLoadingFuel) {
    return <Loader msg={t("Common.Loading")} />;
  }
  if (fuelEdit) {
    return <CarrierDetailsFuelEdit carrier={open} fuel={fuels[activeRow]} />;
  }
  const styles = { minWidth: "160px" };
  return (
    <div className="tab-content details cascade">
      <div className="dsv-table fuel-list">
        <div className="details-subtitle">
          <div>{t("AdminCarriers.Fuel")}</div>
          {(fuels?.length ?? 0) === 0 && (
            <div onClick={handleEditClick}>
              <span className="icon-add"></span>
            </div>
          )}
        </div>

        <table cellSpacing="0" cellPadding="0" style={styles}>
          <thead>
            <tr>
              <th>{t("Fields.Surcharge")}</th>
            </tr>
          </thead>
          <tbody>
            {fuels?.map((el, index) => {
              return (
                <tr key={index} onClick={() => handleFuelClick(el, index)}>
                  <td>{el.surcharge}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
