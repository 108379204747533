import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils/dateUtils";
import { numberFormat } from "utils/numberUtils";
import { getAddressProps, getContactProps } from "utils/orderUtils";
import {
	ADDRESS_TYPE,
	CARGO_TYPE,
	CONTACT_TYPE,
	SHIPMENT_TYPE,
	VOLUME_UNIT,
	WEIGHT_UNIT,
} from "features/constants";
import {
	fetchOperatorSimilarOrders,
	setOperatorConsolidationOrders,
	setOperatorConsolidationTabs,
} from "features/operator/operatorOrderConsolidationSlice";
import { fetchOperatorOrderCart } from "features/operator/operatorOrdersSlice";

export default function OperatorOrderDetailsConsolidationList({
	onConsolidate,
}) {
	const dispatch = useDispatch();
	// Translation
	const { t } = useTranslation();
	// Selectors
	const { openOrder, consolidate } = useSelector(
		(state) => state.operatorOrders
	);
	const { list, isLoading, orders, loaded, tabs } = useSelector(
		(state) => state.operatorOrderConsolidation
	);
	// State
	const [selectedOrders, setSelectedOrders] = useState([]);
	// Effect
	useEffect(() => {
		if (list == null && !isLoading && !loaded && openOrder != null) {
			dispatch(fetchOperatorSimilarOrders({ orderId: openOrder.id }));
		}
	}, [dispatch, openOrder, list, isLoading, loaded]);
	// Handlers
	const handlerMaximizeClick = () => {
		if (selectedOrders.length > 0) {
			const includedOrders = [...orders, ...selectedOrders];
			dispatch(setOperatorConsolidationOrders({ orders: includedOrders }));
			dispatch(
				fetchOperatorOrderCart({
					primary: openOrder.id,
					ids: [openOrder.id, ...includedOrders],
				})
			);
			const filteredList = list.filter(
				(el) => selectedOrders.indexOf(el.id) !== -1
			);
			const newTabs = filteredList.map((el) => {
				return { id: el.id, label: el.file_number, active: false };
			});
			dispatch(
				setOperatorConsolidationTabs({
					tabs: [
						{ id: openOrder.id, label: openOrder.file_number, active: true },
						...newTabs,
					],
				})
			);
			setSelectedOrders([]);
		} else {
			dispatch(
				setOperatorConsolidationTabs({
					tabs: [
						{ id: openOrder.id, label: openOrder.file_number, active: true },
					],
				})
			);
		}
		onConsolidate();
	};
	const handleAddClick = () => {
		const includedOrders = [...orders, ...selectedOrders];
		dispatch(setOperatorConsolidationOrders({ orders: includedOrders }));
		dispatch(
			fetchOperatorOrderCart({
				primary: openOrder.id,
				ids: [openOrder.id, ...includedOrders],
			})
		);
		const filteredList = list.filter(
			(el) => selectedOrders.indexOf(el.id) !== -1
		);
		const newTabs = filteredList.map((el) => {
			return { id: el.id, label: el.file_number, active: false };
		});
		dispatch(
			setOperatorConsolidationTabs({
				tabs: [...tabs, ...newTabs],
			})
		);
		setSelectedOrders([]);
	};
	const handleOrderClick = (id) => {
		const idx = selectedOrders.indexOf(id);
		let updtSelectedOrders = [...selectedOrders];
		if (idx !== -1) {
			updtSelectedOrders.splice(idx, 1);
			setSelectedOrders(updtSelectedOrders);
		} else {
			updtSelectedOrders.push(id);
			setSelectedOrders(updtSelectedOrders);
		}
	};
	// Render
	const filteredList =
		list != null ? list.filter((el) => orders.indexOf(el.id) === -1) : [];
	const renderList = () => {
		if (list != null) {
			return (
				<>
					{filteredList.map((el, index) => {
						const forwarderContact = getContactProps(
							el.contacts,
							CONTACT_TYPE.FORWARDER
						);
						const originAddress = getAddressProps(
							el.addresses,
							ADDRESS_TYPE.ORIGIN
						);
						const destAddress = getAddressProps(
							el.addresses,
							ADDRESS_TYPE.DESTINATION
						);
						return (
							<tr key={index}>
								<td>
									<input
										type="checkbox"
										id={`check-${el.file_name}`}
										name={`check-${el.file_name}`}
										checked={selectedOrders.indexOf(el.id) !== -1}
										onChange={() => handleOrderClick(el.id, "B")}
									/>
								</td>
								<td>{el.file_number}</td>
								<td>{el.alternate_reference_number}</td>
								<td>{el.master_bill_number}</td>
								<td>{el.house_bill_number}</td>
								<td className="center">
									{t(SHIPMENT_TYPE.properties[el.transport_shipment_type].name)}
								</td>
								<td>
									{t(CARGO_TYPE.properties[el.transport_cargo_type].name)}
								</td>
								<td>{el.forwarder_name}</td>
								<td>{forwarderContact.name}</td>
								<td className="right">{el.cargo_pieces}</td>
								<td>{el.cargo_packaging}</td>
								<td className="right">
									{numberFormat(el.cargo_actual_weight * 1, 2)}{" "}
									{WEIGHT_UNIT.properties[el.cargo_actual_weight_unit].code}
								</td>
								<td>&nbsp;</td>
								<td>
									{el.cargo_volume != null ? (
										`${numberFormat(el.cargo_volume * 1, 2)} ${
											VOLUME_UNIT.properties[el.cargo_volume_unit].code
										}`
									) : (
										<>&nbsp;</>
									)}
								</td>
								<td>{el.cargo_description}</td>
								<td>{el.origin_name}</td>
								<td className="center">{el.origin_rating_port_code ?? ""}</td>
								<td className="center">{originAddress.zip}</td>
								<td className="center">{originAddress.state}</td>
								<td className="center">
									{formatDate(
										el.origin_estimated_ready_date_time,
										t("Dates.DateTime")
									)}
								</td>
								<td className="center">
									{formatDate(
										el.origin_estimated_close_date_time,
										t("Dates.DateTime")
									)}
								</td>
								<td>{el.destination_name}</td>
								<td className="center">
									{el.destination_rating_port_code ?? ""}
								</td>
								<td className="center">{destAddress.zip}</td>
								<td className="center">{destAddress.state}</td>
								<td className="center">
									{formatDate(
										el.destination_requested_delivery_date_time,
										t("Dates.DateTime"),
										false
									)}
								</td>
							</tr>
						);
					})}
				</>
			);
		}
	};
	const renderMaximize = () => {
		if (!consolidate && list?.length > 0) {
			const maximizeProps =
				list?.length > 0 ? { onClick: handlerMaximizeClick } : {};
			return (
				<div {...maximizeProps}>
					<span className="icon-maximize"></span>
				</div>
			);
		}
	};
	const renderAddButton = () => {
		if (consolidate) {
			return (
				<div onClick={handleAddClick}>
					<div className="button main">{t("Buttons.Add")}</div>
				</div>
			);
		}
	};
	const cssClass = consolidate
		? "consolidate-list"
		: "consolidate-list no-border";
	return (
		<div className={cssClass}>
			<div className="order-actions-title">
				<div>
					<div></div>
					<div className="order-subtitle">
						{t("OperatorOrders.SimilarOrdersTitle")}
					</div>
					{renderMaximize()}
					{renderAddButton()}
				</div>
			</div>
			<div className="order-block">
				<div>
					<div className="dsv-table with-check">
						<table cellSpacing="0" cellPadding="0">
							<thead>
								<tr>
									<th>&nbsp;</th>
									<th>{t("Tables.FileNumber")}</th>
									<th>{t("Tables.ReferenceNumber")}</th>
									<th>{t("Tables.MasterBill")}</th>
									<th>{t("Tables.HouseBill")}</th>
									<th className="center">{t("ShipmentTypes.Title")}</th>
									<th>{t("CargoTypes.Title")}</th>
									<th>{t("Tables.Forwarder")}</th>
									<th>{t("Tables.Operator")}</th>
									<th className="right">{t("Tables.Pieces")}</th>
									<th>{t("Tables.Packaging")}</th>
									<th className="right">{t("Tables.Weight")}</th>
									<th className="center">{t("Tables.FreightClass")}</th>
									<th className="right">{t("Tables.Volume")}</th>
									<th>{t("Tables.CargoDescription")}</th>
									<th>{t("Tables.OriginName")}</th>
									<th className="center">{t("Tables.OriginPort")}</th>
									<th className="center">{t("Tables.OriginZip")}</th>
									<th className="center">{t("Tables.OriginState")}</th>
									<th className="center">{t("Tables.EstimatedReady")}</th>
									<th className="center">{t("Tables.EstimatedClose")}</th>
									<th>{t("Tables.DestinationName")}</th>
									<th className="center">{t("Tables.DestinationPort")}</th>
									<th className="center">{t("Tables.DestinationZip")}</th>
									<th className="center">{t("Tables.DestinationState")}</th>
									<th className="center">{t("Tables.RequestedDelivery")}</th>
								</tr>
							</thead>
							<tbody>{renderList()}</tbody>
							<tfoot>
								<tr>
									<td colSpan="3">
										{t("Pagination.Found", {
											count: filteredList?.length,
										})}
									</td>
									<td colSpan="23">&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
